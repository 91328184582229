define('dashboard/components/modals/hidden-report-modal', ['exports', 'dashboard/components/modals/modal-confirm'], function (exports, _modalConfirm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _modalConfirm.default.extend({

    fiProperty: Ember.computed('engine', 'selectedModal.fiProperty', function () {
      var engine = this.get('engine'),
          fiProp = this.get('selectedModal.fiProperty');

      if (!fiProp) {
        return {};
      }
      return engine.get(fiProp);
    })

  });
});