define('dashboard/components/buttons/multi-action-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['multi-action-button'],

    expanded: false,

    showOnExpanded: Ember.computed('expanded', function () {
      return this.get('expanded') ? "show" : "hide";
    }),

    // clicking outside the button will collapse options
    mouseUp: function mouseUp(el) {
      if (Ember.$(el.target).hasClass('invisible-overlay')) {
        this.send('collapse');
      }
    },


    actions: {
      toggleExpanded: function toggleExpanded() {
        this.set('expanded', !this.get('expanded'));
      },
      expand: function expand() {
        this.set('expanded', true);
      },
      collapse: function collapse() {
        this.set('expanded', false);
      },
      clickOption: function clickOption(option) {
        this.sendAction('optionActionHandler', option);
        this.send('collapse');
      }
    }
  });
});