define('dashboard/instance-initializers/google', ['exports', 'ember-place-autocomplete/instance-initializers/google'], function (exports, _google) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _google.default;
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function () {
      return _google.initialize;
    }
  });
});