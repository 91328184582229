define("dashboard/utils/ratio-each", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  /**
   * RatioEach
   *
   * Modifies each element of an array by the ratio of numerator and denominator.
   * If denominator is zero, changes it to one.
   *
   * @param  {Array} inputArray  array of values
   * @param  {Number} numerator
   * @param  {Number} denominator
   * @return {Array}
   *
   * @method ratioEach
   */
  var ratioEach = function ratioEach(inputArray, numerator, denominator) {

    denominator = denominator !== 0 ? denominator : 1;

    return inputArray.map(function (input) {

      return Math.round(input * (numerator / denominator));
    });
  };

  exports.default = ratioEach;
});