define('dashboard/mixins/sorting', ['exports', 'ember-cli-pagination/computed/paged-array'], function (exports, _pagedArray) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({

    sortField: null,
    sortDir: null,

    /**
     * Return an array for the sort criteria
     *
     * @property sortedValue
     * @return {array}
     */
    sortedValue: Ember.computed('sortField', 'sortDir', function () {
      var sortField = this.get('sortField');
      var sortDir = this.get('sortDir');
      var result = !sortField ? '' : sortDir ? sortField + ':' + sortDir : sortField;
      return [result];
    }),

    sortedList: Ember.computed.sort('model', 'sortedValue'),
    pagedContent: (0, _pagedArray.default)('filteredList', { infinite: "unpaged", perPage: 50 }),
    searchText: Ember.computed.reads('search'),

    /**
     * Compares the length of the paged content and filtered list
     *
     * @property isLastPage
     * @return {boolean}
     */
    isLastPage: Ember.computed('pagedContent.length', function () {
      return this.get('pagedContent.content.length') === this.get('pagedContent.all.content.length');
    }),

    /**
     * Gathers fields to sort by
     *
     * @property sortFields
     * @return {array}
     */
    sortFields: Ember.computed('sortColumns', function () {
      return this.get('sortColumns').filter(function (column) {
        return !!column.sortField;
      }).map(function (column) {
        return column.sortField;
      });
    }),

    testableSortColumns: Ember.computed('sortColumns.[]', function () {
      return this.get('sortColumns').map(function (column) {
        var sortField = column.sortField;
        Ember.set(column, 'testSelector', sortField && sortField.split('.').join('-')); // eslint-disable-line ember/no-side-effects
        return column;
      });
    }),
    /**
     * Watches for a change in the sort field and debounces the filter update
     *
     * @observer sortFields
     * @return {null}
     */
    searchTextObserver: Ember.observer('searchText', function () {
      Ember.run.debounce(this, this.updateDebouncedSearchText, 300);
    }),

    /**
     * Sets the debouncedSearchText to the updated searchText
     *
     * @method updateDebouncedSearchText
     * @return {null}
     */
    updateDebouncedSearchText: function updateDebouncedSearchText() {
      this.set('debouncedSearchText', this.get("searchText"));
    },


    /**
     * Filters the sortedList by the searchText in the sortFields
     *
     * @property filteredList
     * @return {array}
     */
    filteredList: Ember.computed('sortedList', 'debouncedSearchText', function () {
      var sorted = this.get('sortedList');
      var search = this.get('searchText');
      var sortFields = this.get('sortFields');
      if (!search) {
        return sorted;
      } else {
        return sorted.filter(function (item) {
          var values = Object.values(item.getProperties(sortFields));
          return values.any(function (element) {
            return element && element.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1;
          });
        });
      }
    }),

    actions: {

      loadNext: function loadNext() {
        this.get('pagedContent').loadNextPage();
      }

    }
  });
});