define('dashboard/components/tooltip-button', ['exports', 'dashboard/mixins/hover'], function (exports, _hover) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_hover.default, {

    tagName: 'button',

    click: function click() {
      this.sendAction();
    }
  });
});