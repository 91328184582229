define("dashboard/utils/appreciation-schedule", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  /**
   * Appreciation Schedule
   *
   * @param  {Array} array      Array of values
   * @param  {Number} adjustment Appreciation adjustment as a percentage
   * @return {Array}            Array of appreciated values
   *
   * @method  appreciationSchedule
   */
  var appreciationSchedule = function appreciationSchedule(array, adjustment) {

    return array.map(function (value, index, array) {

      var year = index;

      value = parseFloat(value);

      while (year > 0) {
        value += parseFloat(array[year - 1]) * Math.pow(1 + parseFloat(adjustment), index - year + 1);
        year -= 1;
      }

      return value;
    });
  };

  exports.default = appreciationSchedule;
});