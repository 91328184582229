define("dashboard/utils/add-each", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (arrays) {

    if (!arrays.length) {
      return;
    }

    var result = [];

    for (var i = 0; i < arrays[0].length; i++) {
      result[i] = arrays.reduce(function (total, array) {
        return total + array[i];
      }, 0);
    }

    return result;
  };
});