define('dashboard/controllers/raw-data-preview', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    client: Ember.computed.alias('model.client'),
    project: Ember.computed.alias('model.project'),
    scenario: Ember.computed.alias('model.scenario'),
    requestTemplate: Ember.computed.alias('model.requestTemplate'),
    wizardSections: Ember.computed.alias('scenario.sectionsToDisplay'),

    scenarioController: Ember.inject.controller('scenario'),
    multiplierSetId: Ember.computed.alias('scenarioController.primaryDistrict.multiplierSet.id'),

    breadcrumbItems: Ember.computed('scenario.{project.id,project.title,project.bucket,activeBreadcrumbText}', function () {
      var items = [{
        label: 'Projects',
        params: ['projects.' + this.get('scenario.project.bucket')]
      }, {
        label: this.get('scenario.project.title') || 'Project',
        params: ['project', this.get('scenario.project.id')]
      }];

      items.push({ label: this.get('scenario.activeBreadcrumbText'), active: true });

      return items;
    }),

    revisionTitle: Ember.computed('scenario.revisionOfScenario.{bestGuessEmail,submittedOn}', function () {
      var originalScenario = this.get('scenario.revisionOfScenario');
      return originalScenario.get('bestGuessEmail') + ' submitted on ' + moment(originalScenario.get('submittedOn')).format('ll');
    }),

    actions: {
      // @TODO add save conditon so we can't save read only scenarios
      save: function save() {
        this.get('model.scenario').save();
      },
      print: function print() {
        window.print();
      }
    }
  });
});