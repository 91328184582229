define('dashboard/routes/scenario/settings', ['exports', 'dashboard/mixins/reset-scroll-position'], function (exports, _resetScrollPosition) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_resetScrollPosition.default, {

    titleToken: 'Assumptions',

    controllerName: 'scenario',

    model: function model() {
      return this.modelFor('scenario').scenario;
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var scenario = this.modelFor('scenario').scenario;
      controller.set('selectedSchedule', scenario.getPrimarySchedule());
    }
  });
});