define('dashboard/components/modals/tif-enable-modal', ['exports', 'dashboard/components/modals/modal-confirm'], function (exports, _modalConfirm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _modalConfirm.default.extend({

    actions: {
      useNewTif: function useNewTif() {
        this.onNew();
        this.set('isHidden', true);
      },
      usePresets: function usePresets() {
        this.onPreset();
        this.set('isHidden', true);
      }
    }

  });
});