define('dashboard/components/forms/client-form', ['exports', 'dashboard/fixtures/states'], function (exports, _states) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  exports.default = Ember.Component.extend({
    tagName: '',

    session: Ember.inject.service(),
    store: Ember.inject.service(),
    notify: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      var notificationId = 'client-form-errors';
      var stateOptions = _states.default;
      var salesTaxLocalOnlyOptions = [{ value: true, label: 'Local Only' }, { value: false, label: 'State Included' }];
      var dateInputOptions = { minDate: new Date(2000, 1, 1) };

      this.setProperties({ notificationId: notificationId, stateOptions: stateOptions, salesTaxLocalOnlyOptions: salesTaxLocalOnlyOptions, dateInputOptions: dateInputOptions });
    },


    currentUser: Ember.computed.alias('session.currentUser'),

    assumption: Ember.computed.alias('client.assumption'),

    subscription: Ember.computed.alias('client.subscription'),

    errorMessage: Ember.computed('notify.target.messages.[]', function () {
      return this.getWithDefault('notify.target.messages', []).findBy('id', this.get('notificationId'));
    }),

    showUserGuideRow: Ember.computed('client.userGuideUrl', 'currentUser.isSuperAdmin', function () {
      return this.get('currentUser.isSuperAdmin') || Boolean(this.get('client.userGuideUrl'));
    }),

    selectedSalesTaxLocalOnly: Ember.computed('assumption.salesTaxLocalOnly', function () {
      return this.get('salesTaxLocalOnlyOptions').findBy('value', this.get('assumption.salesTaxLocalOnly'));
    }),

    _clearFormErrors: function _clearFormErrors() {
      if (this.get('errorMessage')) this.set('errorMessage.visible', this.get('client.validations.isInvalid'));
    },

    actions: {
      removeUserGuide: function removeUserGuide() {
        var _this = this;

        var client = this.get('client');

        client.set('userGuide', null);
        client.save().then(function () {
          return _this.get('notify').info('User guide removed successfully');
        });
      },
      createSubscription: function createSubscription(subscriptionData) {
        var subscription = this.get('client.subscription');

        if (subscription.get('id')) return subscription.save();

        var client = this.get('client');
        subscriptionData.client = client;

        this.get('store').createRecord('subscription', subscriptionData).save().then(function (subscription) {
          client.set('subscription', subscription);
          client.save();
        });
      },
      saveSalesTaxLocalOnly: function saveSalesTaxLocalOnly(selected) {
        this.get('assumption').then(function (assumption) {
          assumption.set('salesTaxLocalOnly', selected.value);
          assumption.save();
        });
      },
      updateSubscription: function updateSubscription() {
        var _this2 = this;

        var propertyName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
        var value = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

        if (!propertyName) throw 'The updated subscription property name must be specified';

        this.get('subscription').then(function (subscription) {
          if (subscription && subscription.get('id')) {
            subscription.set(propertyName, value);
            subscription.save();
          } else {
            _this2.send('createSubscription', _defineProperty({}, propertyName, value));
          }
        });
      },
      updateState: function updateState(state) {
        this.set('client.state', state);
        this.send('submit');
      },
      updateStatus: function updateStatus(statusOption) {
        var client = this.get('client');

        client.updateClientStatus(statusOption.value);
        this.send('submit');
      },
      submit: function submit() {
        if (this.get('client.validations.isInvalid')) return this.send('showFormErrors');

        this.send('clearFormErrors');
        this.sendAction();
      },
      showFormErrors: function showFormErrors() {
        var id = this.get('notificationId');
        var text = this.get('client.validations.messages').join('<br />');

        var errorMessage = this.get('errorMessage');
        if (errorMessage) {
          errorMessage.set('text', text);
        } else {
          errorMessage = this.get('notify').error(text, { id: id, closeAfter: null });
        }

        Ember.run.debounce(this, this._clearFormErrors, 3000);
      },
      clearFormErrors: function clearFormErrors() {
        this._clearFormErrors();
      }
    }
  });
});