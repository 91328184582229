define("dashboard/utils/sum-array", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  /**
   * Sum Array
   *
   * Accepts an array and returns its summative reduction
   *
   * @param  {Array} inputArray Array of values
   * @return {Number}            Summative reduction of the input
   *
   * @method  sumArray
   */
  var sumArray = function sumArray(inputArray) {

    return inputArray.reduce(function (sum, num) {
      return sum + (parseFloat(num) || 0);
    }, 0);
  };

  exports.default = sumArray;
});