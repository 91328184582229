define('dashboard/controllers/projects/all', ['exports', 'dashboard/mixins/tagging'], function (exports, _tagging) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_tagging.default, {
    projectsController: Ember.inject.controller('projects'),
    queryParams: ['selectedTags'],
    selectedTags: Ember.computed(function () {
      return [];
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('moveProjectOptions', ['Active', 'Won', 'Lost', 'Archived']);
    }
  });
});