define('dashboard/components/collapsible-nav-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    collapsed: false,

    // visitedProjectPage: false,
    // watchProjectPage: observer('isProjectPage', function() {
    //   const isProjectPage = this.get('isProjectPage');
    //   if (isProjectPage) {
    //     // never unset after visit
    //     // this.set('visitedProjectPage', true);
    //     this.set('collapsed', false);
    //   }
    // }),

    // collapsed: computed.not('isProjectPage'),

    openClass: Ember.computed('collapsed', function () {
      return this.get('collapsed') ? '' : 'is-open';
    }),

    actions: {
      toggleNav: function toggleNav() {
        var collapsed = this.get('collapsed');
        var items = Ember.$('.hidden-nav-link');

        if (collapsed) {
          Ember.$(items).slideDown();
        } else {
          Ember.$(items).slideUp();
        }

        this.set('collapsed', !collapsed);
      }
    }
  });
});