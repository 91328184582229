define('dashboard/components/modals/tif-district-create-modal', ['exports', 'dashboard/components/modals/modal-confirm'], function (exports, _modalConfirm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _modalConfirm.default.extend({
    notify: Ember.inject.service(),

    tifDistrictName: '',

    isCopying: Ember.computed('selectedTif', function () {
      return !!this.get('selectedTif');
    }),

    setName: Ember.observer('isCopying', 'isHidden', function () {
      var newName = '';
      if (this.get('isCopying')) {
        newName = 'Copy of ' + this.get('selectedTif.name');
      }
      this.set('tifDistrictName', newName);
    }),

    keyPress: function keyPress(e) {
      // Return / Enter key.
      if (e.keyCode === 13) {
        this.send('create');
      }
    },

    keyUp: function keyUp(e) {
      // Esc Key
      if (e.keyCode === 27) {
        this.send('cancel');
      }
    },

    actions: {
      create: function create() {
        var _this = this;

        var name = this.get('tifDistrictName').trim(),
            tifProfiles = this.get('tifDistricts');

        if (!name) {
          this.get('notify').alert('TIF District must have a name');
          this.$('input').focus();
          return;
        }

        if (tifProfiles.findBy('name', name)) {
          this.get('notify').alert('TIF District name must be unique');
          this.$('input').focus();
          return;
        }

        this.onSubmit(this.get('tifDistrictName'), this.get('selectedTif')).then(function () /*results*/{
          _this.setProperties({
            tifDistrictName: '',
            isHidden: true
          });
        });
      }
    }

  });
});