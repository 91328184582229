define('dashboard/components/modals/essentials-upgrade-modal', ['exports', 'dashboard/components/modals/modal-confirm'], function (exports, _modalConfirm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _modalConfirm.default.extend({

    requestSent: false,
    requestNotSent: Ember.computed.not('requestSent'),

    title: Ember.computed('requestNotSent', function () {
      var requestingText = 'Request Full Impact Analysis';
      var requestedText = 'Your request has been received!';
      return this.get('requestNotSent') ? requestingText : requestedText;
    }),

    actions: {
      submit: function submit() {
        var _this = this;

        this.onSubmit().then(function () {
          _this.onSuccess();
        }).catch(function (err) {
          _this.onFailure(err);
        });
      }
    }
  });
});