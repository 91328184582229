define('dashboard/components/hidden-when', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: ['hideWhen:custom-hidden'],

    hideWhen: Ember.computed('hiddenValue.val', 'doesntEqual', function () {
      if (!this.get('hiddenValue.val') || !this.get('doesntEqual')) {
        return false;
      }

      return this.get('hiddenValue.val') !== this.get('doesntEqual');
    })

  });
});