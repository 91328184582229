define('dashboard/components/scenario-table-row', ['exports', 'dashboard/mixins/hover'], function (exports, _hover) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_hover.default, {
    tagName: 'tr',
    classNames: ['cursor', 'clickable', 'scenario-table-row', 'd-block'],
    classNameBindings: ['active', 'active:bg-light-accent'],

    notify: Ember.inject.service(),

    hover: false,
    isEditing: false,

    active: Ember.computed('currentlyActiveScenario.id', 'scenario.id', function () {
      return this.get('currentlyActiveScenario.id') === this.get('scenario.id');
    }),

    activeVisible: Ember.computed('active', 'hover', function () {
      return this.get('active') || this.get('hover') ? 'visible' : 'invisible';
    }),

    scenarioTitle: Ember.computed('scenario.subtitle', function () {
      var title = this.get('scenario.subtitle');
      var prefix = this.get('scenario.isRequest') ? 'Request ' : 'Scenario ';

      return title || prefix + (this.get('scenario.index') || '');
    }),

    // show essentials tag if user is pro but has essentials scenarios
    // show pro tag if user is essentials but has pro scenarios
    essentialsTag: Ember.computed('essentialsClient', 'scenario.essentialsScenario', function () {
      var isEssentailsClient = this.get('essentialsClient');
      var isEssentialsScenario = this.get('scenario.essentialsScenario');
      if (isEssentailsClient && !isEssentialsScenario) {
        return 'Pro';
      }

      if (!isEssentailsClient && isEssentialsScenario) {
        return 'Essentials';
      }
    }),

    click: function click() {
      this.sendAction('transitionAction', this.get('scenario'));
    },
    focusOut: function focusOut() {
      this.send('save');
      return false;
    },
    keyPress: function keyPress(e) {
      if (e.keyCode === 13) {
        this.send('save');
      }
    },


    actions: {
      makeActive: function makeActive() {
        this.sendAction('makeActive', this.get('scenario'));
      },
      deleteScenarioHandler: function deleteScenarioHandler() {
        this.sendAction('deleteScenarioHandler', this.get('scenario'));
      },
      copyScenarioHandler: function copyScenarioHandler() {
        this.sendAction('copyScenarioHandler', this.get('scenario'));
      },
      editScenarioHandler: function editScenarioHandler() {
        if (this.get('readOnlyAccess')) {
          return this.get('notify').alert('You currently have read only access.');
        }

        this.set('isEditing', true);
      },
      save: function save() {
        this.sendAction('saveScenarioName', this.get('scenario'));
        this.set('isEditing', false);
      }
    }

  });
});