define('dashboard/controllers/insights', ['exports', 'dashboard/fixtures/mock-project-types', 'dashboard/mixins/project-actions'], function (exports, _mockProjectTypes, _projectActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_projectActions.default, {
    router: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('mockProjectTypes', _mockProjectTypes.default);
      this.set('swiperBreakpoints', {
        768: { slidesPerView: 3 }
      });
    },


    hideCreateProjectModal: true,

    selectedBucket: Ember.computed(function () {
      return this.get('projectBuckets.firstObject');
    }),

    // TODO: add project count binding
    projectBuckets: Ember.computed('projects', 'projects.length', function () {
      var projects = this.get('projects');
      return [{
        type: 'index',
        label: 'Active',
        icon: 'fa-bolt',
        route: 'projects.index',
        count: this.getCount(projects, 'active')
      }, {
        type: 'won',
        label: 'Won',
        icon: 'fa-trophy',
        route: 'projects.won',
        count: this.getCount(projects, 'won')
      }, {
        type: 'lost',
        label: 'Lost',
        icon: 'fa-times-circle',
        route: 'projects.lost',
        count: this.getCount(projects, 'lost')
      }, {
        type: 'archived',
        label: 'Archived',
        route: 'projects.archived',
        icon: 'fa-ghost',
        count: this.getCount(projects, 'archived')
      }];
    }),

    getCount: function getCount(array, property) {
      return array.filterBy(property).length;
    },


    selectedBucketProjects: Ember.computed('selectedBucket', 'projects.@each.bucket', function () {
      var _this = this;

      return this.get('projects').filter(function (project) {
        return project.get('bucket') === _this.get('selectedBucket.type');
      });
    }),

    filteredProjectTypeBucket: Ember.computed('selectedBucketProjects.@each.projectType', 'includeNotSelected', function () {
      if (this.get('includeNotSelected')) {
        return this.get('selectedBucketProjects');
      }
      return this.get('selectedBucketProjects').filter(function (p) {
        return p.get('projectType.name') !== undefined;
      });
    }),

    helpProjectType: 'Edit or add Project Type choices in <a href="/account/customization" class="link underline nowrap">My Account.</a>',
    helpIndustry: 'Edit or add Industry choices in <a href="/account/customization" class="link underline nowrap">My Account.</a>',

    // projects from selected bucket without a projectType property
    projectsWithoutType: Ember.computed('selectedBucketProjects.@each.projectType', function () {
      return this.get('selectedBucketProjects').filterBy('projectType.name', undefined);
    }),

    // number of projects without projectType property
    projectsWithoutTypeCount: Ember.computed.alias('projectsWithoutType.length'),

    filteredIndustryBucket: Ember.computed('selectedBucketProjects.@each.industry', 'includeNotSelected', function () {
      if (this.get('includeNotSelected')) {
        return this.get('selectedBucketProjects');
      }
      return this.get('selectedBucketProjects').filter(function (p) {
        return p.get('industry.name') !== undefined;
      });
    }),

    // projects from selected bucket without an industry property
    projectsWithoutIndustry: Ember.computed('selectedBucketProjects.@each.industry', function () {
      return this.get('selectedBucketProjects').filterBy('industry.name', undefined);
    }),

    // number of projects where industry property is undefined
    projectsWithoutIndustryCount: Ember.computed.alias('projectsWithoutIndustry.length'),

    includeNotSelectedThreshold: 0.5,

    // automatically toggle this property based on proportion of projects with or without projectType or industry selected
    includeNotSelected: Ember.computed('includeNotSelectedThreshold', 'selectedBucket', 'selectedBucketProjects.length', function () {
      var totalProjectsLength = this.get('selectedBucketProjects.length') || 0;
      var includeNotSelectedThreshold = this.get('includeNotSelectedThreshold');
      var projectsWithoutTypeCount = this.get('projectsWithoutTypeCount') || 0;
      var projectsWithoutIndustryCount = this.get('projectsWithoutIndustryCount');
      var noProjectTypeThresholdExceeded = projectsWithoutTypeCount / totalProjectsLength < includeNotSelectedThreshold;
      var noIndustryThresholdExceeded = projectsWithoutIndustryCount / totalProjectsLength < includeNotSelectedThreshold;
      return !totalProjectsLength || noProjectTypeThresholdExceeded || noIndustryThresholdExceeded;
    }),

    projectLinkText: Ember.computed.alias('selectedBucket.label'),
    projectLinkRoute: Ember.computed.alias('selectedBucket.route'),

    blurBGClass: Ember.computed('selectedBucketProjects.length', function () {
      return this.get('selectedBucketProjects.length') > 0 ? '' : 'blurredBG';
    }),

    recentProjects: Ember.computed('projects.@each.{title,indexedScenariosOnly,lastUpdatedScenario}', function () {
      return this.get('projects').sortBy('lastUpdatedScenario').reverse().slice(0, 3);
    }),

    unreadDataRequests: Ember.computed('dataRequests.@each.displayAsUnread', function () {
      return this.get('dataRequests').filterBy('displayAsUnread').sortBy('submittedOn').reverse();
    }),

    filteredUnreadDataRequests: Ember.computed('unreadDataRequests', function () {
      return this.get('unreadDataRequests').slice(0, 3);
    }),

    unreadDataRequestsCount: Ember.computed.alias('unreadDataRequests.length'),

    /* Inbox Requests*/
    allInboxRequests: Ember.computed('dataRequests.@each.{inboxScreen,submittedOn}', function () {
      return this.get('dataRequests').filterBy('inboxScreen', 'inbox').sortBy('submittedOn').reverse();
    }),

    inboxRequests: Ember.computed('allInboxRequests.length', function () {
      return this.get('allInboxRequests').slice(0, 3);
    }),

    extraInboxRequests: Ember.computed('allInboxRequests.length', 'filteredUnreadDataRequests', function () {
      var allRequests = this.get('allInboxRequests');
      var unreadDataRequestsCount = this.get('filteredUnreadDataRequests.length') || 0;
      return allRequests.slice(unreadDataRequestsCount, allRequests.length);
    }),

    // if there are more than 4 unread requests, only 3 will be shown on this page, so when refering to others,
    // say "+5 More" instead of "+5 Read" since the extras will be a mix of read and unread
    extraRequestTerm: Ember.computed('unreadDataRequestsCount', function () {
      return this.get('unreadDataRequestsCount') > 3 ? " More" : " Read";
    }),

    /* In Progress Requests*/
    allInProgressRequests: Ember.computed('dataRequests.@each.{inboxScreen,created,bestGuessEmail}', function () {
      return this.get('dataRequests').filter(function (dr) {
        return dr.get('inboxScreen') === 'in-progress' && dr.get('bestGuessEmail');
      }).sortBy('created').reverse();
    }),

    inProgressRequests: Ember.computed('allInProgressRequests.length', function () {
      return this.get('allInProgressRequests').slice(0, 3);
    }),

    extraInProgrogressRequests: Ember.computed('allInProgressRequests.length', function () {
      var allRequests = this.get('allInProgressRequests');
      return allRequests.slice(3, allRequests.length);
    }),

    actions: {
      goToProject: function goToProject(project) {
        this.get('router').transitionTo('project', this.get('client.id'), project.get('id'));
      },
      goToRequest: function goToRequest(request) {
        this.get('router').transitionTo('data-forms.inbox.data-request', this.get('client.id'), request.get('id'));
      },


      // overwriting action in project-actions mixin
      createProject: function createProject(newProjectTitle) {
        var _this2 = this;

        this.createProject(newProjectTitle).then(function (project) {
          _this2.send('transitionToProject', project.get('id'));
        }, this.get('adapterErrorNotifier').createErrorsAlerter());
      }
    }
  });
});