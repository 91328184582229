define('dashboard/components/scroll/scroll-measurer', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    tagName: '',

    didInsertElement: function didInsertElement() {
      this.bindScrolling();
    },
    willDestroyElement: function willDestroyElement() {
      this.unbindScrolling();
    },
    bindScrolling: function bindScrolling() {
      var _this = this;

      var trackScroll = function trackScroll() {
        return _this.scrolled();
      };
      var onScroll = function onScroll() {
        Ember.run.debounce(_this, trackScroll, 50);
      };

      Ember.$('.inner-content').bind('touchmove', onScroll);
      Ember.$('.inner-content').bind('scroll', onScroll);
    },
    unbindScrolling: function unbindScrolling() {
      Ember.$('.inner-content').unbind('touchmove');
      Ember.$('.inner-content').unbind('scroll');
    },
    scrolled: function scrolled() {
      var innerContent = document.getElementsByClassName('inner-content')[0];
      var offsetTop = Math.floor(innerContent.scrollTop);

      this.sendAction('setCurrentScrollPosition', offsetTop);
    }
  });
});