define('dashboard/services/ajax', ['exports', 'ember-ajax/services/ajax', 'dashboard/config/environment'], function (exports, _ajax, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ajax.default.extend({
    host: _environment.default.APP.api_host,
    namespace: '/api',
    session: Ember.inject.service(),
    headers: Ember.computed('session.data.authenticated.token', {
      get: function get() {
        var headers = {};
        var authToken = this.get('session.data.authenticated.token');
        if (authToken) {
          headers['token'] = authToken;
        }
        return headers;
      }
    })
  });
});