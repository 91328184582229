define('dashboard/utils/ajax-error-mapper', ['exports', 'lodash/collection', 'lodash/lang'], function (exports, _collection2, _lang2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (result) {
    var errors = [];
    if (result) {
      //loop on objects/arrays
      if (result.errors) {
        _collection2.default.forEach(result.errors, function (val) {
          if (_lang2.default.isString(val.message) || _lang2.default.isString(val.error)) {
            errors.push(val.message || val.error);
          }
        });
      } else if (_lang2.default.isString(result.message) || _lang2.default.isString(result.error)) {
        errors.push(result.error || result.message);
      } else if (result.error && _lang2.default.isString(result.error.message)) {
        if (result.error.errors) {
          var allErrors = result.error.errors;
          for (var key in allErrors) {
            errors.push(allErrors[key].properties);
          }
        } else {
          errors.push(result.error.message);
        }
      }
    }

    return errors;
  };
});