define('dashboard/components/hidden-action-trigger', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNameBindings: ['disabled::cursor'],

    // tagName: '',

    init: function init() {
      this._super.apply(this, arguments);
      this.set('tagName', this.get('tag') || 'span');
    },


    setTagName: Ember.observer('tag', function () {
      this.set('tagName', this.get('tag') || 'span');
    }),

    click: function click() {
      this.sendAction('onClick', this.get('section'));
    }
  });
});