define('dashboard/routes/reset', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {

    title: 'Set Password | Impact Dashboard',

    notify: Ember.inject.service(),
    session: Ember.inject.service(),

    beforeModel: function beforeModel() {
      // if user tries to visit this page while logged in, invalidate session
      var session = this.get('session');
      if (this.get('session.isAuthenticated')) {
        session.set('skipRedirectOnInvalidation', true);
        session.invalidate();
      }
    },
    model: function model(params) {
      var token = params.token;
      return this.store.queryRecord('resetPassword', { token: token });
    },
    afterModel: function afterModel(model) {
      // if user already used this link, redirect to login page
      if (model.get('isComplete')) {
        this.get('notify').alert('Link expired. Click "Forgot Your Password" link to reset password.', { closeAfter: 20000 });
        this.transitionTo('login');
      }
    },


    actions: {
      error: function error(_error) {
        if (_error) {
          console.log('error ', _error);
          this.get('notify').alert('Reset attempt invalid');
          this.transitionTo('login');
        }
      }
    }

  });
});