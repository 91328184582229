define('dashboard/helpers/format-by', ['exports', 'dashboard/helpers/format-number', 'dashboard/helpers/format-money'], function (exports, _formatNumber, _formatMoney) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatBy = formatBy;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function formatBy(_ref) /*, hash*/{
    var _ref2 = _slicedToArray(_ref, 2),
        format = _ref2[0],
        data = _ref2[1];

    if (format == 'number') {
      return (0, _formatNumber.formatNumber)([data]);
    } else if (format == 'money') {
      return (0, _formatMoney.formatMoney)([data]);
    } else if (format == 'whole') {
      return (0, _formatNumber.formatNumber)([data], { type: 'whole' });
    } else if (format == 'array') {
      return data.join(', ');
    } else {
      return data;
    }
  }

  exports.default = Ember.Helper.helper(formatBy);
});