define('dashboard/controllers/projects/lost', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.set('moveProjectOptions', ['Active', 'Won', 'Archived']);
    },


    projectsController: Ember.inject.controller('projects')
  });
});