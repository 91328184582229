define('dashboard/controllers/data-requests/edit', ['exports', 'ember-local-storage', 'dashboard/utils/create-new-request', 'dashboard/utils/copy-data-request', 'dashboard/mixins/color-scheme'], function (exports, _emberLocalStorage, _createNewRequest2, _copyDataRequest, _colorScheme) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_colorScheme.default, {
    queryParams: ['currentSectionIndex', 'dataFormListModalIsHidden'],
    currentSectionIndex: 0,
    dataFormListModalIsHidden: true,
    notify: Ember.inject.service(),
    store: Ember.inject.service(),

    dataRequests: (0, _emberLocalStorage.storageFor)('data-requests'),
    scenario: Ember.computed.alias('model'),
    client: Ember.computed.alias('scenario.client'),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('availableDataRequests', []);
      this.findAvailableDataRequests();
    },


    updateAvailableRequests: Ember.observer('dataRequests.content', function () {
      this.findAvailableDataRequests();
    }),

    // commenting out invalidation checks here since session data is getting wiped when running automated tests
    // we'll just keep all the requests in session for now and deal with them in another story
    findAvailableDataRequests: function findAvailableDataRequests() {
      var _this = this;

      var sessionRequests = this.get('dataRequests');
      var scenarioIds = sessionRequests.get('content').filter(function (x) {
        return x;
      });

      // get rid of non id strings in session-data here until we can find where they're getting added
      // let validIdCheck = new RegExp("^[0-9a-fA-F]{24}$");
      // let invalidIds = scenarioIds.filter(id => validIdCheck.test(id) === false);
      // sessionRequests.removeObjects(invalidIds);

      // scenarioIds = scenarioIds.filter(id => validIdCheck.test(id));
      var scenarioPromises = scenarioIds.map(function (id) {
        return _this.store.findRecord('scenario', id);
      });
      var availableDataRequests = [];
      Ember.RSVP.allSettled(scenarioPromises).then(function (promises) {
        promises.forEach(function (promise /*, index*/) {
          // remove invalid scenarios from session data
          // if (promise.state === 'rejected') {
          //   console.log('rejected promise here');
          //   sessionRequests.removeObject(scenarioIds[index]);
          //   return;
          // }
          // keep valid scenarios - is there another state besides rejected or fulfilled?
          if (promise.state === 'fulfilled') {
            availableDataRequests.push(promise.value);
          }
        });

        availableDataRequests = availableDataRequests.filter(function (sdr) {
          var notSubmitted = !sdr.get('submittedOn');
          var belongsToClient = sdr.get('client.id') === _this.get('client.id');
          return notSubmitted && belongsToClient;
        }).sortBy('lastUpdated').reverse();
        _this.set('availableDataRequests', availableDataRequests);
      });
    },


    transitionToPastWelcomeIfComplete: Ember.observer('scenario', function () {
      var firstNonWelcomeIndex = 3;
      var welcomeComplete = this.get('scenario.validatedDivisions.welcome.isValid');
      var currentSectionIndex = this.get('currentSectionIndex') || 0;
      var inWelcome = currentSectionIndex < firstNonWelcomeIndex;
      if (welcomeComplete && inWelcome) {
        this.set('currentSectionIndex', firstNonWelcomeIndex);
      }
    }),

    actions: {
      prospectRevisionRequest: function prospectRevisionRequest() {
        var _this2 = this;

        // copy the data request we're on right now, including assumptions then transition to that request
        (0, _copyDataRequest.default)(this.get('scenario'), this.get('store')).then(function (newDataRequest) {
          _this2.transitionToRoute('data-requests.edit', newDataRequest.get('id'), newDataRequest.get('token'));
        });
        this.get("notify").info("You are now editing a copy of the previous data form");
      },
      createNewRequest: function createNewRequest() {
        var _this3 = this;

        var scenario = this.get('scenario');

        var _scenario$getProperti = scenario.getProperties('assumption', 'client', 'requestTemplate'),
            assumption = _scenario$getProperti.assumption,
            client = _scenario$getProperti.client,
            requestTemplate = _scenario$getProperti.requestTemplate;

        Ember.RSVP.hash({
          assumption: assumption,
          client: client,
          requestTemplate: requestTemplate
        }).then(function (_ref) {
          var assumption = _ref.assumption,
              client = _ref.client,
              requestTemplate = _ref.requestTemplate;

          return (0, _createNewRequest2.default)(assumption, client, requestTemplate, _this3.get('store'));
        }).then(function (dataRequest) {
          var dataRequests = _this3.get('dataRequests');
          // leaving this here to hopefully catch when bad scenario references are added
          console.log('adding object to data requests ', Ember.get(dataRequest, 'id'));
          dataRequests.addObject(Ember.get(dataRequest, 'id'));
          _this3.get("notify").info("New data request form created.");

          _this3.transitionToRoute('data-requests.edit', Ember.get(dataRequest, "id"), Ember.get(dataRequest, "token"));
        });
      },
      goToDataRequest: function goToDataRequest(scenario) {
        this.set('dataFormListModalIsHidden', true);
        this.transitionToRoute('data-requests.edit', Ember.get(scenario, 'id'), Ember.get(scenario, 'token'));
      }
    }
  });
});