define('dashboard/routes/guests', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    titleToken: 'Guests',

    model: function model() {
      return this.store.query('guest', { limit: 50, skip: 0 });
    }
  });
});