define('dashboard/objects/ei-students', ['exports', 'dashboard/objects/array-compute', 'dashboard/utils/multiply-each'], function (exports, _arrayCompute, _multiplyEach) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var EiStudentsObject = _arrayCompute.default.extend({

    direct: Ember.computed('directWorkers', 'multiplier', function () {

      var workers = this.get('directWorkers') || [],
          multiplier = this.get('multiplier') || 0;

      return (0, _multiplyEach.default)(workers, multiplier);
    }),

    indirect: Ember.computed('indirectWorkers', 'multiplier', function () {

      var workers = this.get('indirectWorkers') || [],
          multiplier = this.get('multiplier') || 0;

      return (0, _multiplyEach.default)(workers, multiplier);
    })

  });

  exports.default = EiStudentsObject;
});