define('dashboard/controllers/district-area-sets', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    breadcrumbItems: Ember.computed('currentDistrictAreaSet.name', function () {
      var items = [{ label: 'District Area Sets', params: ['district-area-sets'] }];

      var currentDistrictAreaSet = this.get('currentDistrictAreaSet');
      if (currentDistrictAreaSet) {
        items.push({ label: currentDistrictAreaSet.get('name'), active: true });
      }

      return items;
    })
  });
});