define('dashboard/components/scenario-section', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: [':component-section', 'isTogglable:togglable', 'isHidden:hidden-content:shown-content', ':js-component-section'],
    attributeBindings: ['data-test', 'style'],

    isTogglable: Ember.computed.reads('togglable'),

    headerHidden: Ember.computed('isTogglable', 'title', function () {
      return !this.get('isTogglable') && !this.get('title');
    }),

    primaryObserver: Ember.observer('primaryGroup', 'primaryDistrict.name', function () {
      var primaryGroup = this.get('primaryGroup'),
          primaryDistrict = this.get('primaryDistrict.name');
      if (primaryGroup) {
        this.set('help', "<p>Non-Tax Incentives are treated as investments in the Project and are compared to the return on the Project which is the Project’s net benefits to the " + primaryGroup.get('label') + ".</p>");
      }
      if (primaryDistrict) {
        this.set('help', "<p>New direct workers may be hired from the local community or from neighboring communities within commuting distance. A portion of the new direct jobs may be filled by workers who will relocate to " + primaryDistrict + ".</p>");
      }
    }),

    actions: {
      toggleHidden: function toggleHidden() {
        if (!this.get('isTogglable')) {
          return;
        }
        this.toggleProperty('isHidden');
      },
      nextSection: function nextSection() {
        var sortedProp = this.get('sortedProp') || 'sortedSections';
        this.sendAction('nextSectionHandler', this.get('nextId'), sortedProp);
        Ember.$('html, body').animate({
          scrollTop: this.$().offset().top - 30
        }, 300);
      }
    }
  });
});