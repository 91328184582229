define('dashboard/components/graphs/pie-placeholder-no-projects', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.set('emptyOptions', [{
        type: 'index',
        header: 'No Active Projects',
        sub: 'Get in the game.',
        subsub: 'Click Create Project to get started.',
        createButton: true
      }, {
        type: 'won',
        header: 'No Won Projects',
        sub: 'Winning isn\'t everything...',
        subIsQuote: true,
        subsub: Ember.String.htmlSafe('But if you categorize the projects you\'ve won, you\'ll see the details here.')
      }, {
        type: 'lost',
        header: 'No Lost Projects',
        sub: Ember.String.htmlSafe('`Tis better to have modeled and lost,<br></br>Than to have never modeled at all'),
        subIsQuote: true,
        subsub: 'Categorize your projects - even the ones you lost - and you\'ll see details here.'
      }, {
        type: 'archived',
        header: 'No Archived Projects',
        sub: 'Consider this your junk drawer.',
        subsub: 'If you categorize your Archived projects, you\'ll see the details here.'
      }]);
    },


    selectedType: Ember.computed('type', function () {
      return this.get('emptyOptions').findBy('type', this.get('type'));
    })

  });
});