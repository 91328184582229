define('dashboard/components/tabbable-input', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.TextField.extend({

    classNames: ['form-control'],

    keyDown: function keyDown(event) {
      if (event.keyCode === 9 && this.get('tabber') && !event.shiftKey) {
        this.get('tabber').send('nextSectionHandler');
      }
    }
  });
});