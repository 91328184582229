define('dashboard/mixins/alias-properties', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    ignoreAliasDefaults: false,
    aliasedClass: null,
    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('aliasedClass') && !this.get("ignoreAliasDefaults")) {
        this.defineAliases({ aliasedProperties: this.get('aliasedProperties'), aliasedClass: this.get('aliasedClass') });
      }
    },
    defineAliases: function defineAliases(_ref) {
      var _this = this;

      var aliasedProperties = _ref.aliasedProperties,
          aliasedClass = _ref.aliasedClass;

      aliasedProperties.forEach(function (property) {
        return Ember.defineProperty(_this, property, Ember.computed.alias(aliasedClass + '.' + property));
      });
    }
  });
});