define('dashboard/components/sortable-table-header', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    tagName: 'thead',
    classNames: ['sortableHeaders'],

    actions: {
      sortBy: function sortBy(sortField, sortDir) {
        this.setProperties({
          sortField: sortField,
          sortDir: sortDir
        });
      }
    }

  });
});