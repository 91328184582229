define('dashboard/utils/float-map', ['exports', 'lodash/collection'], function (exports, _collection2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var floatMap = function floatMap(array) {

    return _collection2.default.map(array, function (item) {
      return parseFloat(item);
    });
  };

  exports.default = floatMap;
});