define('dashboard/utils/conjugate-incentive-term', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (term) {
    switch (term) {
      case 'Exemption':
        return Ember.Object.create({
          singular: 'Exemption',
          plural: 'Exemptions',
          past: 'Exempted',
          present: 'exempt'
        });
      case 'Rebate':
        return Ember.Object.create({
          singular: 'Rebate',
          plural: 'Rebates',
          past: 'Rebated',
          present: 'rebate'
        });
      case 'Reimbursement':
        return Ember.Object.create({
          singular: 'Reimbursement',
          plural: 'Reimbursements',
          past: 'Reimbursed',
          present: 'reimburse'
        });
      default:
        return Ember.Object.create({
          singular: 'Abatement',
          plural: 'Abatements',
          past: 'Abated',
          present: 'abate'
        });
    }
  };
});