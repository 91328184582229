define('dashboard/components/contact-info-card', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: ["hideContactInfoBG"],

    init: function init() {
      this._super();

      if (!this.get('user') && this.get('users.length')) {
        this.set('user', this.get('users.firstObject'));
      }

      if (this.get('user')) {
        var user = this.get('user');
        var fullName = '<b>' + user.get('fullName') + '<b/>';
        var clientName = user.get('client.name');
        var jobTitle = user.get('jobTitle');
        var formattedPhone = user.get('formattedPhone');
        var email = user.get('email');
        this.set('value', [fullName, clientName, jobTitle, formattedPhone, email].filter(Boolean).join('\n'));
      }
    },


    showLabel: false,
    showFooter: false,
    hideContactInfoBG: false
  });
});