define('dashboard/routes/data-requests/new', ['exports', 'ember-local-storage', 'dashboard/utils/create-new-request'], function (exports, _emberLocalStorage, _createNewRequest) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    dataRequests: (0, _emberLocalStorage.storageFor)('data-requests'),
    ajax: Ember.inject.service(),

    model: function model(params) {
      return this.get('ajax').request('/requestTemplates/' + params.request_template_id);
    },
    afterModel: function afterModel(_ref) {
      var _this = this;

      var requestTemplate = _ref.requestTemplate,
          client = _ref.client,
          scenarios = _ref.scenarios,
          assumption = _ref.assumption;

      var dataRequests = scenarios.filter(function (sdr) {
        // only show other in progress data forms from same client
        var belongsToClient = sdr.client === client.id;
        // find if any existing data requests are in session data (in progress)
        var inProgress = _this.get('dataRequests.content').includes(sdr.id);
        return belongsToClient && inProgress;
      }).sortBy('lastUpdated');

      if (dataRequests.length > 0) {
        this.transitionToDataRequest(dataRequests[0], { dataFormListModalIsHidden: false });
      } else {
        this.createDataRequest(requestTemplate, assumption, client);
      }
    },
    createDataRequest: function createDataRequest(requestTemplate, assumption, client) {
      var _this2 = this;

      // ajax request returns plain JSON, so this is required in order to put them in the scenario
      requestTemplate = this.store.push(this.store.normalize('request-template', requestTemplate));
      assumption = this.store.push(this.store.normalize('assumption', assumption));
      client = this.store.push(this.store.normalize('client', client));

      (0, _createNewRequest.default)(assumption, client, requestTemplate, this.store).then(function (newDataRequest) {
        _this2.get('dataRequests').addObject(Ember.get(newDataRequest, 'id'));
        _this2.transitionToDataRequest(newDataRequest);
      });
    },
    transitionToDataRequest: function transitionToDataRequest(dataRequest) {
      var queryParams = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      this.transitionTo('data-requests.edit', Ember.get(dataRequest, "id"), Ember.get(dataRequest, "token"), { queryParams: queryParams });
    }
  });
});