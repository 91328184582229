define('dashboard/components/raw-data', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didRender: function didRender() {

      // clear out tab indices for all inputs on this page - this prevents conflicts between
      // different columns of inputs having the same indices and causing weird behavior
      Ember.$('input').attr('tabindex', null);
    }
  });
});