define('dashboard/components/changed-form-input', ['exports', 'dashboard/components/form-input'], function (exports, _formInput) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = _formInput.default.extend({

    layoutName: 'components/form-input',

    isModified: Ember.computed('modified.[]', function () {

      var modified = this.get('modified') || [],
          original = modified[this.get('index')],
          input = this.get('inputValue'),
          isTrue = false;

      switch (typeof input === 'undefined' ? 'undefined' : _typeof(input)) {
        case 'object':
          break;
        case 'number':
          original = parseFloat(original);
          break;
        case 'string':
          original = original ? original.toString().trim() : '';
          input = input.trim();
          break;
      }

      if (original && input !== original) {
        isTrue = true;
      }

      return Ember.Object.create({
        isTrue: isTrue,
        original: original
      });
    })

  });
});