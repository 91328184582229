define('dashboard/routes/data-forms/inbox/data-request', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    title: function title(tokens) {
      tokens.push('Impact DashBoard');
      return tokens.join(' | ');
    },
    titleToken: function titleToken(_ref) {
      var scenario = _ref.scenario;

      var bucket = this.getInboxName(scenario.get('inboxScreen'));
      return 'Data Form | ' + bucket;
    },
    model: function model(params) {
      return Ember.RSVP.hash({
        client: this.modelFor("data-forms").client,
        scenario: this.store.findRecord("scenario", params.id, { reload: true }),
        assumption: this.store.query("assumption", { scenario: params.id }).then(function (arr) {
          return arr.get('firstObject');
        })
      });
    },
    afterModel: function afterModel(model) {
      // Force redirect if user attempts to access a data-request they should not have access to.
      if (!this.userCanAccessDataRequest(model.scenario)) {
        this.transitionTo('data-forms', this.modelFor('authenticated').get('client.id'));
      }

      // mark data form as visited if it has been submitted and not viewing as super admin
      var currentUser = this.modelFor('authenticated');
      if (model.scenario.get('visitedDataForm') === false && model.scenario.get('submittedOn') && !currentUser.get('isSuperAdmin')) {
        model.scenario.set('visitedDataForm', true);
        model.scenario.save();
      }
    },
    setupController: function setupController(controller, models) {
      controller.setProperties({
        client: models.client,
        scenario: models.scenario,
        assumption: models.assumption
      });

      this._super(controller, models.scenario);
    },
    userCanAccessDataRequest: function userCanAccessDataRequest(scenario) {
      var user = this.modelFor('authenticated');
      if (user.get('isSuperAdmin')) return true;

      var scenarioClientId = scenario.get('client.id');
      var userClientId = user.get('client.id');
      return scenarioClientId === userClientId;
    },
    getInboxName: function getInboxName(name) {
      var options = {
        assigned: 'Assigned',
        inbox: 'Inbox',
        'in-progress': 'In Progress',
        archived: 'Archived'
      };
      return options[name] || 'Inbox';
    }
  });
});