define('dashboard/controllers/scenario/report', ['exports', 'dashboard/objects/impact-engine', 'dashboard/objects/impact-engine-current', 'dashboard/objects/impact-engine-total', 'dashboard/utils/array-of-length', 'dashboard/utils/float-map', 'dashboard/utils/get-aggregate-engine-prop', 'dashboard/utils/hidden-modal-properties', 'dashboard/utils/sum-array', 'dashboard/mixins/community-report', 'dashboard/mixins/community-incentives', 'dashboard/mixins/alias-properties', 'dashboard/mixins/scenario-wizard-sections', 'dashboard/mixins/read-only-restrictions'], function (exports, _impactEngine, _impactEngineCurrent, _impactEngineTotal, _arrayOfLength, _floatMap, _getAggregateEngineProp, _hiddenModalProperties, _sumArray, _communityReport, _communityIncentives, _aliasProperties, _scenarioWizardSections, _readOnlyRestrictions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = Ember.Controller.extend(_communityReport.default, _communityIncentives.default, _aliasProperties.default, _scenarioWizardSections.default, _readOnlyRestrictions.default, {

    scenarioController: Ember.inject.controller('scenario'),

    init: function init() {
      // this giant list of aliases is a bit clunky, but it's worth it to separate out the report-only properties and actions
      // as we pull apart the scenario controller this list might get smaller, or some of it might get put into a service
      this.set('aliasedProperties', ['project', 'client', 'primaryEngine', 'primaryEngines', 'years', 'distributionOfNetBenefits', 'publicSupportTotals', 'tifIncentives', 'tifDistrict', 'tifTotals', 'tifAnnual', 'tifCumulativeAnnual', 'displayedMultipliers', 'multipliers', 'firstThreeEngines', 'otherEngines', 'tifOtherEnginesAnnual', 'tifOtherEnginesTotal', 'scenarioSubtitle', 'displayedCapitalInvestmentTotal', 'summaryDistributionGraphData', 'sortedDistricts', 'idbLogoUrl', 'sortedDistrictGroups', 'engines', 'tifImpactEngine', 'currentEngines', 'expansionEngines', 'totalEngines', 'totals', 'reportHasPublicSupport', 'lastYearWithJobsAdded', 'isScenarioLengthLessThanSix', 'canRequestEssentialsUpgrade']);

      this.set('selectedHiddenModal', _hiddenModalProperties.default.default);
      this._super.apply(this, arguments);
    },

    aliasedClass: 'scenarioController',
    scenario: Ember.computed.alias('model'),
    multiplierSetId: Ember.computed.alias('scenarioController.primaryDistrict.multiplierSet.id'),

    isConfigureCommunityReportHidden: true,
    showFactSheetEssentialsPrompt: true,

    isFactSheet: Ember.computed.equal('scenarioController.target.currentRouteName', 'scenario.report.fact-sheet'),
    isSummaryReport: Ember.computed.equal('scenarioController.target.currentRouteName', 'scenario.report.summary'),
    isDetailedReport: Ember.computed.equal('scenarioController.target.currentRouteName', 'scenario.report.detailed'),
    isCommunityReport: Ember.computed.equal('scenarioController.target.currentRouteName', 'scenario.report.community'),

    propertyTaxIncentivePhrases: Ember.computed.alias('engineForSelectedSchedule.propertyTaxIncentivePhrases'),
    salesTaxIncentivePhrases: Ember.computed.alias('engineForSelectedSchedule.salesTaxIncentivePhrases'),
    materialsEquipmentIncentivePhrases: Ember.computed.alias('engineForSelectedSchedule.materialsEquipmentIncentivePhrases'),
    hotelTaxIncentivePhrases: Ember.computed.alias('engineForSelectedSchedule.hotelTaxIncentivePhrases'),

    summaryIncentivesAnalysisPhrase: Ember.computed('displayedIncentiveType', function () {
      var incentiveType = this.get('displayedIncentiveType');

      if (incentiveType === 'propertyTax') {
        return this.get('propertyTaxIncentivePhrases');
      } else if (incentiveType === 'salesTax') {
        return this.get('salesTaxIncentivePhrases');
      } else if (incentiveType === 'materialsTax') {
        return this.get('materialsEquipmentIncentivePhrases');
      } else if (incentiveType === 'hotelTax') {
        return this.get('hotelTaxIncentivePhrases');
      }
    }),

    displayedIncentiveType: Ember.computed('engineForSelectedSchedule.{propertyTaxAbated.total,salesTaxRebates.total,materialsAndEquipmentRebates.total,totalHotelTaxRebates}', function () {
      var engine = this.get('engineForSelectedSchedule');
      var propertyTaxAbated = engine.get('propertyTaxAbated.total');
      var salesTaxRebates = engine.get('salesTaxRebates.total');
      var materialsTaxRebates = engine.get('materialsAndEquipmentRebates.total');
      var hotelTaxRebates = engine.get('totalHotelTaxRebates');

      var totals = [{ incentiveType: 'propertyTax', amount: propertyTaxAbated }, { incentiveType: 'salesTax', amount: salesTaxRebates }, { incentiveType: 'materialsTax', amount: materialsTaxRebates }, { incentiveType: 'hotelTax', amount: hotelTaxRebates }];

      var sortedTotals = totals.sort(function (a, b) {
        return b.amount - a.amount;
      });
      var greatestIncentiveType = sortedTotals[0].incentiveType;

      return sortedTotals[0].amount > 0 ? greatestIncentiveType : false;
    }),

    formattedMethodologyText: Ember.computed('scenarioController.primaryDistrict', function () {
      var text = this.get('scenarioController.primaryDistrict.methodologyText') || '';

      return text.split('\n');
    }),

    startsWithVowel: Ember.computed('years', function () {
      var years = this.get('years');
      if (years === '8' || years === '11' || years === '18') {
        return true;
      }
    }),

    // array of community districts mapped by name
    communityDistrictNames: Ember.computed('selectedCommunityDistricts.@each.name', function () {
      return this.get('selectedCommunityDistricts').mapBy('name').join(', ');
    }),

    logoUrl: Ember.computed.alias('client.logoUrl'),

    showAppendix: Ember.computed('tifDistrict', 'model.usesTifDistrict', 'scenarioHasAbatements', 'scenarioController.{scenarioHasAbatements,scenarioHasRebates,scenarioHasHotelRebates}', function () {
      // show if there are ANY abatements, rebates, or active TIF district
      return !!this.get('tifDistrict') && this.get('model.usesTifDistrict') && this.get('tifTotals.net') > 0 || this.get('scenarioController.scenarioHasAbatements') || this.get('scenarioController.scenarioHasRebates') || this.get('scenarioController.scenarioHasHotelRebates');
    }),

    summaryExcludesValues: Ember.computed('model.assumption.abatementsAsCosts', 'engineForSelectedSchedule.engineHasPublicSupport', function () {
      return !this.get('model.assumption.abatementsAsCosts') && this.get('engineForSelectedSchedule.engineHasPublicSupport');
    }),

    detailedReportSections: Ember.computed('sortedDistricts.[]', 'reportHasPublicSupport', 'model.isExistingBusiness', 'scenarioController.tifDistrict', 'scenarioController.model.usesTifDistrict', 'showAppendix', function () {

      var overviewSections = [];

      if (this.get('model.isExistingBusiness')) {
        overviewSections.pushObject({
          id: 'current-vs-expansion',
          name: 'Existing & Expanded Operations'
        });
      }

      overviewSections.pushObjects([{
        id: 'economic-impact-overview',
        name: 'Economic Impact Overview'
      }, {
        id: 'fiscal-impact-overview',
        name: 'Fiscal Impact Overview'
      }]);

      if (this.get('reportHasPublicSupport')) {
        overviewSections.pushObject({
          id: 'public-support-overview',
          name: 'Public Support Overview'
        });
      }

      this.get('sortedDistricts').map(function (district) {
        overviewSections.pushObject({
          id: district.get('id'),
          name: district.get('name')
        });
      });

      if (this.get('scenarioController.tifDistrict') && this.get('scenarioController.model.usesTifDistrict')) {
        var tifDistrict = this.get('scenarioController.tifDistrict');
        overviewSections.pushObject({
          id: tifDistrict.get('id'),
          name: tifDistrict.get('name')
        });
      }

      overviewSections.pushObject({
        id: 'methodology',
        name: 'Methodology'
      });

      if (this.get('showAppendix')) {
        overviewSections.push({
          id: 'appendix',
          name: 'Appendix'
        });
      }

      return overviewSections;
    }),

    communityConfigureHoverContent: Ember.computed('selectedCommunityReportDistricts', function () {
      var districts = this.get('selectedCommunityReportDistricts') || [];
      return districts.get('length') ? districts.mapBy('name').join('<br />').htmlSafe() : '';
    }),

    tifAggregateData: Ember.computed('engines.@each.{tifContributionTotal,tifContributionAnnual}', 'tifAnnual', 'firstThreeEngines.[]', 'years', 'model.assumption.{startingYear,calendarYearDisplay}', function () {

      var annualTotals = this.get('tifAnnual'),
          years = this.get('years'),
          otherEnginesTifs = this.get('tifOtherEnginesAnnual'),
          calendarYearDisplay = this.get('model.assumption.calendarYearDisplay'),
          startingYear = calendarYearDisplay ? parseInt(this.get('model.assumption.startingYear')) : 1,
          tableData = [];

      tableData = annualTotals.map(function (total, index) {
        return Ember.Object.create({
          year: startingYear + index,
          total: annualTotals[index]
        });
      });

      this.get('firstThreeEngines').forEach(function (engine) {
        for (var i = 0; i < years; i++) {
          tableData[i][engine.get('district.id')] = engine.get('tifContributionAnnual').objectAt(i).get('total');
        }
      });

      if (otherEnginesTifs.length) {
        for (var i = 0; i < years; i++) {
          tableData[i]['otherEngines'] = otherEnginesTifs[i] || 0;
        }
      }

      return tableData;
    }),

    annualTifContributionsGraphData: Ember.computed('tifAnnual', function () {

      var benefits = this.get('tifAnnual').map(function (benefit, index) {
        return {
          year: index + 1,
          type: 'benefit',
          value: isNaN(benefit) ? 0 : parseFloat(benefit),
          origValue: isNaN(benefit) ? 0 : parseFloat(benefit)
        };
      });

      // array of 0's make sure y axis starts at 0
      var costs = (0, _arrayOfLength.default)(benefits.get('length'), 0).map(function (cost, index) {
        return {
          year: index + 1,
          type: 'cost',
          value: isNaN(cost) ? 0 : cost,
          origValue: isNaN(cost) ? 0 : cost
        };
      });

      return benefits.concat(costs);
    }),

    tifContributionsLessIncentivesGraphData: Ember.computed('tifAnnual', 'model.assumption.abatementsAsCosts', 'tifIncentivesGraphData', function () {
      var graphData = [],
          benefits = this.get('tifAnnual'),
          incentives = this.get('tifIncentivesGraphData').map(function (incentive) {
        return incentive.value === 0 ? 0 : Math.abs(incentive.value) * -1;
      });

      graphData = benefits.map(function (benefit, index) {
        return {
          year: index + 1,
          type: 'benefit',
          value: isNaN(benefit) ? 0 : parseFloat(benefit),
          origValue: isNaN(benefit) ? 0 : parseFloat(benefit)
        };
      });

      // need this because this prop is always being fed to summary report graph
      if (this.get('model.assumption.abatementsAsCosts') && this.get('tifIncentives.totalIncentiveSummaryReport') !== 0) {
        // incentivesData = sumArrays(costs, incentives);
        incentives.forEach(function (incentive, index) {
          graphData.push({
            year: index + 1,
            type: 'incentive',
            value: isNaN(incentive) ? 0 : Math.abs(incentive) * -1,
            origValue: incentives[index]
          });
        });
      }

      // array of 0's make sure y axis starts at 0
      var costs = (0, _arrayOfLength.default)(benefits.get('length'), 0).map(function (cost, index) {
        return {
          year: index + 1,
          type: 'cost',
          value: isNaN(cost) ? 0 : cost,
          origValue: isNaN(cost) ? 0 : cost
        };
      });

      return graphData.concat(costs);
    }),

    tifIncentivesGraphData: Ember.computed('model.tifIncentive.incentives', function () {
      var incentives = this.get('model.tifIncentive.incentives') || [];
      return incentives.map(function (value, index) {
        return {
          year: index + 1,
          value: parseFloat(value)
        };
      });
    }),

    currentAndExpansionNetBenefitsTotals: Ember.computed('currentEngines.@each.netBenefitsTotal', 'expansionEngines.@each.netBenefitsTotal', 'totalEngines.@each.netBenefitsTotal', 'model.assumption.abatementsAsCosts', function () {

      var netBenefitsProp = this.get('model.assumption.abatementsAsCosts') ? 'netBenefitsLessIncentivesTotal' : 'netBenefitsTotal';
      var current = (0, _getAggregateEngineProp.default)(this.get('currentEngines'), netBenefitsProp);
      var expansion = (0, _getAggregateEngineProp.default)(this.get('expansionEngines'), netBenefitsProp);
      var total = (0, _getAggregateEngineProp.default)(this.get('totalEngines'), netBenefitsProp);

      return { current: current, expansion: expansion, total: total };
    }),

    /* Fact Sheet Properties */

    // doesn't actually matter what engine is used since this is just based on jobs
    factSheetJobs: Ember.computed.alias('factSheetEngine.eiJobs.lastDirectCumulative'),
    factSheetExpansionJobs: Ember.computed.alias('expansionEngine.eiJobs.lastDirectCumulative'),
    factSheetCurrentJobs: Ember.computed.alias('currentEngine.eiJobs.lastDirectCumulative'),

    jobsPhasedIn: Ember.computed.gt('lastYearWithJobsAdded', 1),

    annualPayroll: Ember.computed('lastYearWithJobsAdded', 'model.averageAnnualSalaries', 'factSheetJobs', function () {
      var lastYearWithJobsAdded = this.get('lastYearWithJobsAdded'),
          averageAnnualSalaries = this.get('model.averageAnnualSalaries'),
          directJobs = this.get('factSheetJobs');

      return averageAnnualSalaries[lastYearWithJobsAdded - 1] * directJobs;
    }),

    capitalInvestmentTitles: Ember.computed('client.stateTaxProfile', 'model.{capitalInvestmentBuildings,capitalInvestmentFurniture}', 'model.assumption.buildingAndImprovementsAssociatedWithConstruction', 'years', function () {

      var stateTaxProfile = this.get('client.stateTaxProfile');
      var buildings = (0, _floatMap.default)(this.get('model.capitalInvestmentBuildings')).slice(0, this.get('years'));
      var ffe = (0, _floatMap.default)(this.get('model.capitalInvestmentFurniture')).slice(0, this.get('years'));
      var buildingsAssociatedWithConstruction = this.get('model.assumption.buildingAndImprovementsAssociatedWithConstruction');

      var output = [];

      if (stateTaxProfile.buildings && buildingsAssociatedWithConstruction) {
        output.push(Ember.Object.create({
          value: (0, _sumArray.default)(buildings),
          label: 'Buildings & Improvements'
        }));
      }

      if (stateTaxProfile.ffe) {
        output.push(Ember.Object.create({
          value: (0, _sumArray.default)(ffe),
          label: 'Furniture, Fixtures, & Equipment'
        }));
      }

      return output;
    }),

    sectionToEdit: null,

    factSheetDisabled: Ember.computed.alias('isReadOnly'),

    // don't show this button if there are no sections to add to the fact-sheet
    missingSections: Ember.computed('hasDescription', 'hasCommunityFit', 'hasRecommendations', 'hasCompanyNotes', function () {
      return !this.get('hasDescription') || !this.get('hasCommunityFit') || !this.get('hasRecommendations') || !this.get('hasCompanyNotes');
    }),

    showAddSectionButton: Ember.computed('missingSections', 'factSheetDisabled', 'isFactSheet', function () {
      return this.get('missingSections') && !this.get('factSheetDisabled') && this.get('isFactSheet');
    }),

    hasDescription: Ember.computed.bool('model.description'),
    hasCommunityFit: Ember.computed.bool('model.communityFit'),
    hasRecommendations: Ember.computed.bool('model.recommendations'),
    hasCompanyNotes: Ember.computed.bool('model.companyNotes'),

    factSheetAddSectionOptions: Ember.computed('hasDescription', 'hasCommunityFit', 'hasRecommendations', 'hasCompanyNotes', function () {
      return [{ text: "Add Description", icon: "fa fa-plus", action: "setSectionToEdit", actionParams: 'basics.description', condition: this.get('hasDescription') }, { text: "Add Community Fit", icon: "fa fa-plus", action: "setSectionToEdit", actionParams: 'basics.communityFit', condition: this.get('hasCommunityFit') }, { text: "Add Recommendations", icon: "fa fa-plus", action: "setSectionToEdit", actionParams: 'basics.recommendations', condition: this.get('hasRecommendations') }, { text: "Add Company Notes", icon: "fa fa-plus", action: "setSectionToEdit", actionParams: 'company.companyNotes', condition: this.get('hasCompanyNotes') }];
    }),

    // selectively toggle between current or expansion engines depending on scenario property selection
    factSheetEngine: Ember.computed('scenario.currentVsExpansion', 'expansionEngine', 'currentEngine', function () {
      var currentVsExpansion = this.get('scenario.currentVsExpansion') || 'expansion';
      return this.get(currentVsExpansion + 'Engine');
    }),

    createImpactEngine: function createImpactEngine(type, options) {
      return type.create(options);
    },


    defaultEngineProperties: Ember.computed('scenario', 'model.assumption', 'scenarioController.primaryDistrict', 'years', function () {
      var scenario = this.get('scenario');
      var district = this.get('scenarioController.primaryDistrict') || Ember.Object.create({ id: '' });
      var group = district.get('parentGroup') || Ember.Object.create({ id: '' });

      return {
        isPrimary: true,
        scenario: this.get('scenarioController'),
        years: this.get('years'),
        district: district,
        group: group,
        schedule: scenario ? scenario.getScheduleForDistrict(group) : Ember.Object.create({}),
        assumption: this.get('model.assumption'),
        className: 'index-' + (district ? district.get('id') : ''),
        multipliers: this.get('multipliers')
      };
    }),

    expansionEngine: Ember.computed('defaultEngineProperties', function () {
      return this.createImpactEngine(_impactEngine.default, this.get('defaultEngineProperties'));
    }),

    currentEngine: Ember.computed('defaultEngineProperties', function () {
      return this.createImpactEngine(_impactEngineCurrent.default, this.get('defaultEngineProperties'));
    }),

    totalEngine: Ember.computed('defaultEngineProperties', function () {
      var defaults = _extends({}, this.get('defaultEngineProperties'), {
        currentEngine: this.get('currentEngine'),
        expansionEngine: this.get('expansionEngine')
      });
      return this.createImpactEngine(_impactEngineTotal.default, defaults);
    }),

    actions: {
      factSheetAddSection: function factSheetAddSection(option) {
        this.send(option.action, option.actionParams);
      },


      // triggered when clicking on section of fact sheet that will open modal to edit selected fields
      setSectionToEdit: function setSectionToEdit(sectionPath) {

        if (this.get('factSheetDisabled')) {
          return;
        }

        // division names change depending on the scenario being a new business or existing, so those divisions will either be 'expansion' or 'plans'
        var sectionParts = sectionPath.split('.');
        var division = sectionParts[0];
        var sectionBoolean = sectionParts[1];
        if (division === 'expansion' && this.get('scenario.isNewBusiness')) {
          division = 'plans';
        }

        var section = this.get('allWizardSections').find(function (section) {
          // identify section by division + sectionBoolean ie. "expansion.productsAndIndustry"
          return division === section.division && sectionBoolean === section.sectionBoolean;
        });

        if (!section) return;
        this.set('sectionToEdit', section);
      },
      save: function save(saveFunction) {
        var _this = this;

        if (this.get('scenarioController.isSavable')) {
          this.get('scenario').save().catch(function (adapterError) {
            if (adapterError && adapterError.errors.length) {
              var error = adapterError.errors[0];
              var badProp = error.path;
              // clear out bad value so we can still save other properties
              _this.get('scenario').set(badProp, null);
              _this.get('notify').alert(error.message);
            }
          });

          if (saveFunction == 'plusProject') {
            this.get('project').save();
          }
        }
      },
      updateSummarySelectedDistrict: function updateSummarySelectedDistrict(district) {
        var groups = this.get('sortedDistrictGroups');
        var scenario = this.get('model');

        // Need this if we turn this select into a power-select
        scenario.set('summarySelectedDistrict', district.get('id'));
        this.set('selectedSchedule', scenario.getSelectedSchedule(groups));
        this.get('scenarioController').send('save');
        this.send('resetScrollPosition');
        this.transitionToRoute('scenario.report.summary');
      },
      resetScrollPosition: function resetScrollPosition() {
        var innerContent = document.getElementsByClassName('inner-content')[0];
        var body = document.getElementsByTagName('body')[0];

        body.style.margin = 0;
        innerContent.scroll(0, 0);
      },
      showHiddenModal: function showHiddenModal(modal) {
        var modalProps = _hiddenModalProperties.default[modal];
        this.set('selectedHiddenModal', modalProps);
        this.set('selectedHiddenModal.isHidden', false);
      },
      essentialsUpgradeFromFactSheet: function essentialsUpgradeFromFactSheet() {
        this.get('scenarioController').send('openRequestScenarioUpgradeModal', this.get('model'));
      }
    }
  });
});