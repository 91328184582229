define("dashboard/utils/scenario-inputs/support", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var companyBackgroundField = {
    name: "notes",
    inputType: "textarea",
    dataProperty: 'note',
    label: "Additional Background",
    customLabelProperty: 'customBackgroundText',
    customLabelLabel: 'Instructions for users to provide background'
  };

  var attachmentField = {
    name: "attachments",
    inputType: "attachments",
    dataProperty: 'fileUploads',
    customLabelProperty: 'customAttachmentText',
    label: "ALLOW DOCUMENTS TO BE UPLOADED",
    customLabelLabel: "Instructions for users to attach documents"
  };

  exports.default = {
    reviews: {
      projectBackground: {
        if: 'requestTemplate.projectBackground',
        editScreenPath: 'support.projectBackground',
        fragments: [{ type: 'text', text: 'Additional background' }, { type: 'text', text: '<strong>was</strong>', if: 'note' }, { type: 'text', text: '<strong>was not</strong>', if: 'noNotes' }, { type: 'text', text: 'provided.  ' }]
      },
      attachDocuments: {
        if: 'requestTemplate.attachDocuments',
        editScreenPath: 'support.attachDocuments',
        fragments: [{ type: 'var', dataProperty: 'fileUploads.length' }, { type: 'text', text: ' document(s) uploaded.' }]
      }
    },
    projectBackground: {
      sectionBoolean: 'projectBackground',
      sidebarName: 'Project Background',
      name: 'Additional Project Background',
      wizardText: "",
      content: [companyBackgroundField]
    },
    attachDocuments: {
      sectionBoolean: "attachDocuments",
      sidebarName: "Attach Documents",
      name: "Attach Documents",
      content: [attachmentField]
    }
  };
});