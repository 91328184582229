define('dashboard/mirage/factories/user', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    client: (0, _emberCliMirage.association)(),
    firstName: _faker.default.name.firstName,
    lastName: _faker.default.name.lastName,
    email: _faker.default.internet.email,
    superAdminTrait: (0, _emberCliMirage.trait)({
      role: 'super_admin'
    }),
    adminTrait: (0, _emberCliMirage.trait)({
      role: 'admin'
    }),
    clientTrait: (0, _emberCliMirage.trait)({
      role: 'client'
    })
  });
});