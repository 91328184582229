define('dashboard/fixtures/basic-data-form-hash', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    title: 'Legacy Data Form',

    lockedToScenario: true,

    localService: false,
    mainService: false,

    projectPlans: false,
    leaseTermInYears: false,
    beginRenovationsDate: false,
    beginLocalOperationsDate: false,

    specialSiteRequirements: false,
    squareFootage: false,

    employmentDetails: false,

    benefitTypes: false,
    benefitsAvgAnnualValue: false,
    benefitsAdditionalNotes: false,

    totalBuildingPermitsAndFees: false,

    annualTaxablePurchases: false,
    annualLocalTaxableSales: false,

    hotelNights: false,
    hotelDailyRate: false,
    spendingPerHotelNight: false,

    yearEstablished: false,
    stateOfFormation: false,
    businessStructure: false,
    companyOwnership: false,

    localEmployees: false,
    stateEmployees: false,
    usaEmployees: false,
    worldEmployees: false,

    contactTitle: false
  };
});