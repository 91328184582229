define('dashboard/mixins/color-scheme', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({

    colorOptions: ['Blue', 'Green', 'Red'],

    Blue: Ember.Object.create({
      mainLightest: 'rgba(157,203,216,0.1)', // use RGBA since IE11 does not support 8-character hex code notation
      mainLight: '#CEDEE4', // accent color (scenario table row active)
      main: '#005A7E', // benefits
      mainHover: '#004D71', // main+5%
      secondaryLighter: '#ADCBDC', // incentives / inventories
      secondaryLighterHover: '#A0BECF', // +5%
      secondaryLight: '#82AFC7', // FF&E
      secondaryLightHover: '#75A2BA', // +5%
      secondary: '#5793B0', // Costs/ buildings
      secondaryHover: '#4A86A3', // + 5%
      secondaryDark: '#2B7798', // Land
      secondaryDarkHover: '#1E6A8B', // +5%
      tertiaryLight: '#E4B339', // net benefits less incentives
      tertiaryLightHover: '#D7A62C',
      tertiaryLightDark: '#B18006', // net benefits less incentives NEGATIVE // tertiaryLight darkened 20%
      tertiaryLightDarkHover: '#A47300',
      tertiary: '#D37228', // net benefits
      tertiaryHover: '#C6651B',
      tertiaryDark: '#A03F00', // tertiary darkened 20%
      tertiaryDarkHover: '#933200',
      scale: ['#005a7e', '#276a8d', '#3f7b9d', '#558bac', '#699dbc', '#7eaecd', '#92c0dd', '#a6d2ee', '#bbe5ff']
    }),

    Green: Ember.Object.create({
      mainLightest: 'rgba(167,179,66,0.1)', // use RGBA since IE11 does not support 8-character hex code notation
      mainLight: '#D8E296',
      main: '#4B7436', // benefits/liability
      mainHover: '#3E6729',
      secondaryLighter: '#D8E296', // incentives / inventories
      secondaryLighterHover: '#CBD589',
      secondaryLight: '#C8D575', //FF&E
      secondaryLightHover: '#BBC868',
      secondary: '#B8C656', // Costs/Buildings
      secondaryHover: '#ABB949',
      secondaryDark: '#A7B538', // Land
      secondaryDarkHover: '#9AA82B',
      tertiaryLight: '#7C3B85', //Net Benefits Less Incentives
      tertiaryLightHover: '#6F2E78',
      tertiaryLightDark: '#490852', // net benefits less incentives NEGATIVE // tertiaryLight darkened 20%
      tertiaryLightDarkHover: '#3C0045',
      tertiary: '#D37228', // Net Benefits
      tertiaryHover: '#C6651B',
      tertiaryDark: '#A03F00', // tertiary darkened 20%
      tertiaryDarkHover: '#933200',
      scale: ['#4b7436', '#5f844a', '#73955d', '#87a672', '#9bb787', '#b0c99c', '#c5dbb2', '#daedc9', '#efffe0']
    }),

    Red: Ember.Object.create({
      mainLightest: 'rgba(77,125,188,0.1)', // use RGBA since IE11 does not support 8-character hex code notation
      mainLight: '#CD9B9B',
      main: '#BD2A42', // benefits
      mainHover: '#971032',
      secondaryLighter: '#ADCBDC', // incentives / inventories
      secondaryLighterHover: '#A0BECF',
      secondaryLight: '#82AFC7', // FF&E
      secondaryLightHover: '#A0BECF',
      secondary: '#5793B0', // Buildings / Costs
      secondaryHover: '#4A86A3',
      secondaryDark: '#2B7798', // Land
      secondaryDarkHover: '#1E6A8B',
      tertiaryLight: '#E4B339', //Net Benefits Less Incentives
      tertiaryLightHover: '#D7A62C',
      tertiaryLightDark: '#B18006', // net benefits less incentives NEGATIVE // tertiaryLight darkened 20%
      tertiaryLightDarkHover: '#A47300',
      tertiary: '#B8C656', //Net Benefits
      tertiaryHover: '#ABB949',
      tertiaryDark: '#D37228', // tertiary darkened 20%
      tertiaryDarkHover: '#C6651B',
      scale: ['#bd2a42', '#c94956', '#d3636a', '#dd7b80', '#e69395', '#eeaaac', '#f5c2c2', '#fad9d9', '#fff1f1']
    }),

    // override this property in controller if client is not `client` property on controller already
    mainColorSetter: Ember.observer('client.colorTheme', function () {
      var colorTheme = this.get('client.colorTheme') || 'Blue';
      this.send('setThemeColors', colorTheme);
    }),

    actions: {
      setThemeColors: function setThemeColors(theme) {
        var bodyStyles = document.body.style;
        var Theme = this.get(theme);

        var mainBrandColorLightest = Theme.get('mainLightest');
        var mainBrandColorLight = Theme.get('mainLight');
        var mainBrandColor = Theme.get('main');
        var mainBrandColorHover = Theme.get('mainHover');
        var secondaryBrandColorLighter = Theme.get('secondaryLighter');
        var secondaryBrandColorLighterHover = Theme.get('secondaryLighterHover');
        var secondaryBrandColorLight = Theme.get('secondaryLight');
        var secondaryBrandColorLightHover = Theme.get('secondaryLightHover');
        var secondaryBrandColor = Theme.get('secondary');
        var secondaryBrandColorHover = Theme.get('secondaryHover');
        var secondaryBrandColorDark = Theme.get('secondaryDark');
        var secondaryBrandColorDarkHover = Theme.get('secondaryDarkHover');
        var tertiaryBrandColorLight = Theme.get('tertiaryLight');
        var tertiaryBrandColorLightHover = Theme.get('tertiaryLightHover');
        var tertiaryBrandColor = Theme.get('tertiary');
        var tertiaryBrandColorHover = Theme.get('tertiaryHover');
        var tertiaryBrandColorLightDark = Theme.get('tertiaryLightDark');
        var tertiaryBrandColorLightDarkHover = Theme.get('tertiaryLightDarkHover');
        var tertiaryBrandColorDark = Theme.get('tertiaryDark');
        var tertiaryBrandColorDarkHover = Theme.get('tertiaryDarkHover');

        bodyStyles.setProperty('--main-brand-color-lightest', mainBrandColorLightest);
        bodyStyles.setProperty('--main-brand-color-light', mainBrandColorLight);
        bodyStyles.setProperty('--main-brand-color', mainBrandColor);
        bodyStyles.setProperty('--main-brand-color-hover', mainBrandColorHover);
        bodyStyles.setProperty('--secondary-brand-color-lighter', secondaryBrandColorLighter);
        bodyStyles.setProperty('--secondary-brand-color-lighter-hover', secondaryBrandColorLighterHover);
        bodyStyles.setProperty('--secondary-brand-color-light', secondaryBrandColorLight);
        bodyStyles.setProperty('--secondary-brand-color-light-hover', secondaryBrandColorLightHover);
        bodyStyles.setProperty('--secondary-brand-color', secondaryBrandColor);
        bodyStyles.setProperty('--secondary-brand-color-hover', secondaryBrandColorHover);
        bodyStyles.setProperty('--secondary-brand-color-dark', secondaryBrandColorDark);
        bodyStyles.setProperty('--secondary-brand-color-dark-hover', secondaryBrandColorDarkHover);
        bodyStyles.setProperty('--tertiary-brand-color-light', tertiaryBrandColorLight);
        bodyStyles.setProperty('--tertiary-brand-color-light-hover', tertiaryBrandColorLightHover);
        bodyStyles.setProperty('--tertiary-brand-color', tertiaryBrandColor);
        bodyStyles.setProperty('--tertiary-brand-color-hover', tertiaryBrandColorHover);
        bodyStyles.setProperty('--tertiary-brand-color-light-dark', tertiaryBrandColorLightDark);
        bodyStyles.setProperty('--tertiary-brand-color-light-dark-hover', tertiaryBrandColorLightDarkHover);
        bodyStyles.setProperty('--tertiary-brand-color-dark', tertiaryBrandColorDark);
        bodyStyles.setProperty('--tertiary-brand-color-dark-hover', tertiaryBrandColorDarkHover);
      }
    }

  });
});