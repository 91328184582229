define('dashboard/components/graphs/d3-chart', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    attributeBindings: ['width', 'height', 'style'],

    yearsToolTip: Ember.computed(function () {
      return 'Year: ';
    }),

    style: Ember.computed('years', function () {
      var years = this.get('years'),
          margin = void 0,
          style = void 0;

      if (years >= 10) {
        style = 'margin-left: 0px';
      } else {
        margin = (10 - years) * 3;
        style = 'margin-left:' + margin + '%;';
      }
      return Ember.String.htmlSafe(style);
    }),

    canvasMarginLeft: 75,
    canvasMarginRight: 0,
    canvasMarginTop: 0,
    canvasMarginBottom: 20,
    numTicks: 8,
    tickPadding: 4,
    yRangeSafety: 15,
    xRangePadding: 0.2,

    yTick: Ember.computed('canvasHeight', 'canvasMarginBottom', function () {
      return this.get('canvasHeight') - this.get('canvasMarginBottom');
    }),

    xMargin: Ember.computed('canvasMarginLeft', 'canvasMarginRight', function () {
      return this.get('canvasMarginLeft') + this.get('canvasMarginRight');
    }),

    yMargin: Ember.computed('canvasMarginTop', 'canvasMarginBottom', function () {
      return this.get('canvasMarginTop') + this.get('canvasMarginBottom');
    }),

    yMax: Ember.computed('data', function () {
      return d3.max(this.get('data'), function (d) {
        return d.value;
      });
    }),

    yMin: Ember.computed('data', function () {
      return d3.min(this.get('data'), function (d) {
        return d.value;
      });
    }),

    xDomain: Ember.computed('years', function () {
      var output = [],
          years = this.get('years'),
          i = void 0;

      for (i = 0; i < years; i++) {
        output[i] = i + 1;
      }

      return output;
    }),

    tickValues: Ember.computed('years', function () {
      var output = [1],
          years = this.get('years'),
          i = void 0;

      if (years > 20 && years < 40) {
        for (i = 1; i < years / 2; i++) {
          output[i] = output[i - 1] + 2;
        }
      } else if (years >= 40) {
        for (i = 1; i <= years / 3 - 1; i++) {
          output[i] = output[i - 1] + 3;
        }
      } else {
        for (i = 1; i < years; i++) {
          output[i] = i + 1;
        }
      }
      output.push(years);
      return output;
    }),

    yRangeMin: Ember.computed.alias('yMargin'),

    yRangeMax: Ember.computed('canvasHeight', 'yMargin', 'yRangeSafety', function () {
      return this.get('canvasHeight') - this.get('yMargin') - this.get('yRangeSafety');
    }),

    xMax: Ember.computed('canvasWidth', 'canvasMarginLeft', 'canvasMarginRight', 'years', function () {
      var years = this.get('years'),
          defaultMarginLeft = 70,
          defaultMax = this.get('canvasWidth') - (defaultMarginLeft + this.get('canvasMarginRight'));

      if (years >= 10) {
        return defaultMax;
      } else {
        return defaultMax - (10 - years) * 35;
      }
    }),

    x: Ember.computed('xMax', 'xRangePadding', 'xDomain', function () {
      var xMax = this.get('xMax'),
          xRangePadding = this.get('xRangePadding'),
          xDomain = this.get('xDomain');

      return d3.scaleBand().rangeRound([0, xMax]).padding(xRangePadding).domain(xDomain);
    }),

    yScale: Ember.computed('yMin', 'yMax', 'yRangeMin', 'yRangeMax', function () {
      var yMin = this.get('yMin'),
          yMax = this.get('yMax'),
          yRangeMax = this.get('yRangeMax'),
          yRangeMin = this.get('yRangeMin');

      return d3.scaleLinear().domain([yMin, yMax]).range([yRangeMax, yRangeMin]);
    }),

    yDomain: Ember.computed(function () {}),

    svg: Ember.computed(function () {
      return d3.select('svg', this.$());
    }),

    dataExists: Ember.computed.bool('data'),

    reDraw: Ember.observer('data', 'dataExists', function () {
      this.get('data');

      if (this.get('dataExists')) {
        Ember.run.once(this, 'draw');
      }
    }),

    draw: function draw() {
      this.drawAxis();
      this.drawChart();
    },

    drawAxis: function drawAxis() {

      var x = this.get('x'),
          yScale = this.get('yScale'),
          tickPadding = this.get('tickPadding'),
          numTicks = this.get('numTicks'),
          xMax = this.get('xMax'),
          canvasMarginLeft = this.get('canvasMarginLeft'),
          canvasMarginTop = this.get('canvasMarginTop'),
          yTick = this.get('yTick'),
          elId = this.get('elementId'),
          xAxisEl = d3.select('.' + elId + '.axis.x'),
          // These need to be unique or multiple instances don't work
      yAxisEl = d3.select('.' + elId + '.axis.y'),
          xAxis = d3.axisBottom().scale(x).tickValues(this.get('tickValues')).tickSize(0, 0).tickPadding(tickPadding),
          yAxis = d3.axisLeft().scale(yScale).ticks(numTicks).tickSize(-xMax, 0).tickPadding(10).tickFormat(function (d) {
        return numeral(d).format('($000.0a)');
      }),
          svg = this.get('svg');

      if (xAxisEl.empty()) {
        svg.insert("g", ":nth-child(2)").attr('class', 'x axis ' + elId).attr('transform', 'translate(' + canvasMarginLeft + ',' + yTick + ')').call(xAxis);
      } else {
        xAxisEl.transition().duration(1000).attr('transform', 'translate(' + canvasMarginLeft + ',' + yTick + ')').call(xAxis);
      }

      if (yAxisEl.empty()) {
        svg.insert("g", ":nth-child(2)").attr('class', 'y axis ' + elId).attr('transform', 'translate(' + canvasMarginLeft + ',' + canvasMarginTop + ')').call(yAxis);
      } else {
        yAxisEl.transition().duration(1000).attr('transform', 'translate(' + canvasMarginLeft + ',' + canvasMarginTop + ')').call(yAxis);
      }
    },

    init: function init() {

      this._super.apply(this, arguments);

      if (this.get('dataExists')) {
        Ember.run.once(this, 'draw');
      }

      Ember.$(function () {
        Ember.$('a[rel=tipsy]').tipsy({ fade: true, gravity: 'n' });
      });
    },

    // Override this method in subclasses of this component
    drawChart: function drawChart() {}

  });
});