define('dashboard/fixtures/state-tax-profiles', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    'Alabama': {
      code: 'AL',
      land: true,
      buildings: true,
      ffe: true,
      inventory: false,
      salesTaxForLocalClient: true,
      salesTaxForStateClient: true
    },
    'Alaska': {
      code: 'AK',
      land: true,
      buildings: true,
      ffe: true,
      inventory: true,
      salesTaxForLocalClient: false,
      salesTaxForStateClient: false
    },
    'Arizona': {
      code: 'AZ',
      land: true,
      buildings: true,
      ffe: true,
      inventory: false,
      salesTaxForLocalClient: true,
      salesTaxForStateClient: true
    },
    'Arkansas': {
      code: 'AR',
      land: true,
      buildings: true,
      ffe: true,
      inventory: true,
      salesTaxForLocalClient: true,
      salesTaxForStateClient: true
    },
    'California': {
      code: 'CA',
      land: true,
      buildings: true,
      ffe: true,
      inventory: false,
      salesTaxForLocalClient: true,
      salesTaxForStateClient: true
    },
    'Colorado': {
      code: 'CO',
      land: true,
      buildings: true,
      ffe: true,
      inventory: false,
      salesTaxForLocalClient: true,
      salesTaxForStateClient: true
    },
    'Connecticut': {
      code: 'CT',
      land: true,
      buildings: true,
      ffe: true,
      inventory: false,
      salesTaxForLocalClient: false,
      salesTaxForStateClient: true
    },
    'Delaware': {
      code: 'DE',
      land: true,
      buildings: true,
      ffe: true,
      inventory: false,
      salesTaxForLocalClient: false,
      salesTaxForStateClient: false
    },
    'Florida': {
      code: 'FL',
      land: true,
      buildings: true,
      ffe: true,
      inventory: false,
      salesTaxForLocalClient: true,
      salesTaxForStateClient: true
    },
    'Georgia': {
      code: 'GA',
      land: true,
      buildings: true,
      ffe: true,
      inventory: true,
      salesTaxForLocalClient: true,
      salesTaxForStateClient: true
    },
    'Hawaii': {
      code: 'HI',
      land: true,
      buildings: true,
      ffe: true,
      inventory: false,
      salesTaxForLocalClient: true,
      salesTaxForStateClient: true
    },
    'Idaho': {
      code: 'ID',
      land: true,
      buildings: true,
      ffe: true,
      inventory: false,
      salesTaxForLocalClient: true,
      salesTaxForStateClient: true
    },
    'Illinois': {
      code: 'IL',
      land: true,
      buildings: true,
      ffe: true,
      inventory: false,
      salesTaxForLocalClient: true,
      salesTaxForStateClient: true
    },
    'Indiana': {
      code: 'IN',
      land: true,
      buildings: true,
      ffe: true,
      inventory: false,
      salesTaxForLocalClient: false,
      salesTaxForStateClient: true
    },
    'Iowa': {
      code: 'IA',
      land: true,
      buildings: true,
      ffe: true,
      inventory: false,
      salesTaxForLocalClient: true,
      salesTaxForStateClient: true
    },
    'Kansas': {
      code: 'KS',
      land: true,
      buildings: true,
      ffe: true,
      inventory: false,
      salesTaxForLocalClient: true,
      salesTaxForStateClient: true
    },
    'Kentucky': {
      code: 'KY',
      land: true,
      buildings: true,
      ffe: true,
      inventory: true,
      salesTaxForLocalClient: false,
      salesTaxForStateClient: true
    },
    'Louisiana': {
      code: 'LA',
      land: true,
      buildings: true,
      ffe: true,
      inventory: true,
      salesTaxForLocalClient: true,
      salesTaxForStateClient: true
    },
    'Maine': {
      code: 'ME',
      land: true,
      buildings: true,
      ffe: true,
      inventory: false,
      salesTaxForLocalClient: false,
      salesTaxForStateClient: true
    },
    'Maryland': {
      code: 'MD',
      land: true,
      buildings: true,
      ffe: true,
      inventory: true,
      salesTaxForLocalClient: false,
      salesTaxForStateClient: true
    },
    'Massachusetts': {
      code: 'MA',
      land: true,
      buildings: true,
      ffe: true,
      inventory: true,
      salesTaxForLocalClient: false,
      salesTaxForStateClient: true
    },
    'Michigan': {
      code: 'MI',
      land: true,
      buildings: true,
      ffe: true,
      inventory: true,
      salesTaxForLocalClient: false,
      salesTaxForStateClient: true
    },
    'Minnesota': {
      code: 'MN',
      land: true,
      buildings: true,
      ffe: true,
      inventory: false,
      salesTaxForLocalClient: true,
      salesTaxForStateClient: true
    },
    'Mississippi': {
      code: 'MS',
      land: true,
      buildings: true,
      ffe: true,
      inventory: true,
      salesTaxForLocalClient: true,
      salesTaxForStateClient: true
    },
    'Missouri': {
      code: 'MO',
      land: true,
      buildings: true,
      ffe: true,
      inventory: false,
      salesTaxForLocalClient: true,
      salesTaxForStateClient: true
    },
    'Montana': {
      code: 'MT',
      land: true,
      buildings: true,
      ffe: true,
      inventory: false,
      salesTaxForLocalClient: false,
      salesTaxForStateClient: false
    },
    'Nebraska': {
      code: 'NE',
      land: true,
      buildings: true,
      ffe: true,
      inventory: false,
      salesTaxForLocalClient: true,
      salesTaxForStateClient: true
    },
    'Nevada': {
      code: 'NV',
      land: true,
      buildings: true,
      ffe: true,
      inventory: false,
      salesTaxForLocalClient: true,
      salesTaxForStateClient: true
    },
    'New Hampshire': {
      code: 'NH',
      land: true,
      buildings: true,
      ffe: true,
      inventory: false,
      salesTaxForLocalClient: false,
      salesTaxForStateClient: false
    },
    'New Jersey': {
      code: 'NJ',
      land: true,
      buildings: true,
      ffe: true,
      inventory: false,
      salesTaxForLocalClient: true,
      salesTaxForStateClient: true
    },
    'New Mexico': {
      code: 'NM',
      land: true,
      buildings: true,
      ffe: true,
      inventory: false,
      salesTaxForLocalClient: true,
      salesTaxForStateClient: true
    },
    'New York': {
      code: 'NY',
      land: true,
      buildings: true,
      ffe: true,
      inventory: false,
      salesTaxForLocalClient: true,
      salesTaxForStateClient: true
    },
    'North Carolina': {
      code: 'NC',
      land: true,
      buildings: true,
      ffe: true,
      inventory: false,
      salesTaxForLocalClient: true,
      salesTaxForStateClient: true
    },
    'North Dakota': {
      code: 'ND',
      land: true,
      buildings: true,
      ffe: true,
      inventory: false,
      salesTaxForLocalClient: true,
      salesTaxForStateClient: true
    },
    'Ohio': {
      code: 'OH',
      land: true,
      buildings: true,
      ffe: true,
      inventory: false,
      salesTaxForLocalClient: true,
      salesTaxForStateClient: true
    },
    'Oklahoma': {
      code: 'OK',
      land: true,
      buildings: true,
      ffe: true,
      inventory: true,
      salesTaxForLocalClient: true,
      salesTaxForStateClient: true
    },
    'Oregon': {
      code: 'OR',
      land: true,
      buildings: true,
      ffe: true,
      inventory: false,
      salesTaxForLocalClient: false,
      salesTaxForStateClient: false
    },
    'Pennsylvania': {
      code: 'PA',
      land: true,
      buildings: true,
      ffe: true,
      inventory: false,
      salesTaxForLocalClient: true,
      salesTaxForStateClient: true
    },
    'Rhode Island': {
      code: 'RI',
      land: true,
      buildings: true,
      ffe: true,
      inventory: false,
      salesTaxForLocalClient: false,
      salesTaxForStateClient: true
    },
    'South Carolina': {
      code: 'SC',
      land: true,
      buildings: true,
      ffe: true,
      inventory: false,
      salesTaxForLocalClient: true,
      salesTaxForStateClient: true
    },
    'South Dakota': {
      code: 'SD',
      land: true,
      buildings: true,
      ffe: true,
      inventory: false,
      salesTaxForLocalClient: true,
      salesTaxForStateClient: true
    },
    'Tennessee': {
      code: 'TN',
      land: true,
      buildings: true,
      ffe: true,
      inventory: false,
      salesTaxForLocalClient: true,
      salesTaxForStateClient: true
    },
    'Texas': {
      code: 'TX',
      land: true,
      buildings: true,
      ffe: true,
      inventory: true,
      salesTaxForLocalClient: true,
      salesTaxForStateClient: true
    },
    'Utah': {
      code: 'UT',
      land: true,
      buildings: true,
      ffe: true,
      inventory: false,
      salesTaxForLocalClient: true,
      salesTaxForStateClient: true
    },
    'Vermont': {
      code: 'VT',
      land: true,
      buildings: true,
      ffe: true,
      inventory: true,
      salesTaxForLocalClient: true,
      salesTaxForStateClient: true
    },
    'Virginia': {
      code: 'VA',
      land: true,
      buildings: true,
      ffe: true,
      inventory: true,
      salesTaxForLocalClient: true,
      salesTaxForStateClient: true
    },
    'Washington': {
      code: 'WA',
      land: true,
      buildings: true,
      ffe: true,
      inventory: false,
      salesTaxForLocalClient: true,
      salesTaxForStateClient: true
    },
    'West Virginia': {
      code: 'WV',
      land: true,
      buildings: true,
      ffe: true,
      inventory: true,
      salesTaxForLocalClient: true,
      salesTaxForStateClient: true
    },
    'Wisconsin': {
      code: 'WI',
      land: true,
      buildings: true,
      ffe: true,
      inventory: false,
      salesTaxForLocalClient: true,
      salesTaxForStateClient: true
    },
    'Wyoming': {
      code: 'WY',
      land: true,
      buildings: true,
      ffe: true,
      inventory: false,
      salesTaxForLocalClient: true,
      salesTaxForStateClient: true
    },
    'District of Columbia': {
      code: 'DC',
      land: true,
      buildings: true,
      ffe: true,
      inventory: false,
      salesTaxForLocalClient: false,
      salesTaxForStateClient: true
    }
  };
});