define('dashboard/controllers/data-forms/request-templates/request-template/pdf', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    wizardSections: Ember.computed('model.wizardSections', function () {
      return this.get('model.wizardSections').rejectBy('division', 'plans');
    }),

    actions: {
      noop: function noop() {}
    }
  });
});