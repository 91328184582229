define('dashboard/controllers/tax-district', ['exports', 'dashboard/fixtures/states'], function (exports, _states) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    stateOptions: _states.default,

    customRevenues: Ember.computed.filterBy('model.customCostRevenues', 'type', 'revenue'),
    customCosts: Ember.computed.filterBy('model.customCostRevenues', 'type', 'cost'),

    directRevenues: Ember.computed.filterBy('model.directCostRevenues', 'type', 'revenue'),
    directCosts: Ember.computed.filterBy('model.directCostRevenues', 'type', 'cost'),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('directCostRevTypes', [Ember.Object.create({ label: 'No Percent Increase', value: 'advanced' }), Ember.Object.create({ label: 'Percent Increase', value: 'cascading' })]);
    },


    multiplierSetOptions: Ember.computed('allMultiplierSets.@each', 'model.multiplierSet', function () {

      var multiplierSets = this.get('allMultiplierSets').sortBy('name'),
          options = [],
          defaultOption = void 0,
          filteredOptions = void 0;

      if (this.get('model.multiplierSet.content')) {
        defaultOption = Ember.Object.create({
          name: 'No Multiplier Set'
        });
        options.push(defaultOption);
      }

      filteredOptions = multiplierSets.filter(function (multiplierSet) {
        return multiplierSet.get('archived') === false;
      });

      return options.concat(filteredOptions);
    }),

    currentParentDistrict: Ember.computed('model.parentDistrict', 'parentDistrict', 'parentDistrictOptions', function () {

      var parentDistrict = this.get('parentDistrict'),
          currentParentDistrictId = this.get('model.parentDistrict'),
          parentOptions = this.get('parentDistrictOptions');

      if (parentDistrict) {
        return parentDistrict;
      }
      return parentOptions.findBy('id', currentParentDistrictId);
    }),

    selectableParentDistrictOptions: Ember.computed('parentDistrictOptions.@each', 'currentParentDistrict', function () {
      var _this = this;

      var parentDistrictOptions = this.get('parentDistrictOptions').sortBy('name'),
          currentParentDistrict = this.get('currentParentDistrict'),
          uniqueOptions = [],
          options = [],
          defaultOption = void 0;

      if (currentParentDistrict) {
        defaultOption = Ember.Object.create({
          name: 'No Parent District'
        });
        options = [defaultOption];
      }
      options = options.concat(parentDistrictOptions);

      options.filter(function (option) {
        if (!uniqueOptions.isAny('name', option.get('name')) && option.get('name') !== _this.get('model.name')) {
          uniqueOptions.push(option);
          return true;
        }

        return false;
      });

      return uniqueOptions;
    }),

    // parentDistrictOptionsChanged: observer('model.state', function() {

    //   if (this.get('model.hasDirtyAttributes')) {

    //     this.set('model.parentDistrict', null);

    //     this.get('model').save()
    //     .then((district) => {
    //       this.store.query('taxDistrict', {parentDistrictOptionsFor: district.get('id')})
    //       .then((districtOptions) => {
    //         this.set('parentDistrictOptions', districtOptions);
    //       });
    //     });
    //   }

    // }),

    actions: {

      // probably unnecessary
      setState: function setState(state) {
        this.set('model.state', state);
        this.send('updateTaxDistrict');
      },
      selectMultiplierSet: function selectMultiplierSet(multiplierSet) {
        var district = this.get('model');
        district.set('multiplierSet', multiplierSet);
        district.save().then(function () {
          Ember.$('.ember-power-select-trigger').blur();
        });
      },
      selectParentDistrict: function selectParentDistrict(parentDistrict) {
        var district = this.get('model'),
            parentId = parentDistrict.get('id');
        district.set('parentDistrict', parentId);
        district.save().then(function () {
          Ember.$('.ember-power-select-trigger').blur();
        });
      },
      updateTaxDistrict: function updateTaxDistrict() {
        this.get('model').save();
      },
      updateCustomCostRev: function updateCustomCostRev() {
        var customCostRevenues = this.get('model.customCostRevenues') || [];
        customCostRevenues.forEach(function (costRev) {
          if (costRev.get('hasDirtyAttributes')) {
            costRev.save();
          }
        });
      },
      updateDirectCostRev: function updateDirectCostRev() {
        var directCostRevenues = this.get('model.directCostRevenues') || [];
        directCostRevenues.forEach(function (costRev) {
          if (costRev.get('hasDirtyAttributes')) {
            costRev.save();
          }
        });
      },
      createCustomCostRevenue: function createCustomCostRevenue(type) {
        var district = this.get('model');

        this.store.createRecord('customCostRevenue', { type: type }).save().then(function (customCostRev) {
          district.get('customCostRevenues').pushObject(customCostRev);
          district.save();
        });
      },
      createDirectCostRevenue: function createDirectCostRevenue(type) {
        var district = this.get('model');

        this.store.createRecord('directCostRevenue', { type: type }).save().then(function (directCostRev) {
          district.get('directCostRevenues').pushObject(directCostRev);
          district.save();
        });
      },
      deleteCustomCostRevenue: function deleteCustomCostRevenue(costRev) {
        var district = this.get('model');

        district.get('customCostRevenues').removeObject(costRev);
        district.save();

        costRev.deleteRecord();
        costRev.save();
      },
      deleteDirectCostRevenue: function deleteDirectCostRevenue(costRev) {
        var district = this.get('model');

        district.get('directCostRevenues').removeObject(costRev);
        district.save();

        costRev.deleteRecord();
        costRev.save();
      }
    }
  });
});