define('dashboard/mixins/projects-page', ['exports', 'ember-local-storage'], function (exports, _emberLocalStorage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    sessionData: (0, _emberLocalStorage.storageFor)('session-data'),

    afterModel: function afterModel() {
      this.setProjectBucket('index');
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        model: model.projects,
        client: model.client,
        user: model.user,
        currentUser: this.modelFor('authenticated')
      });
    },
    setProjectBucket: function setProjectBucket(bucket) {
      this.set('sessionData.projectBucket', bucket);
    }
  });
});