define('dashboard/routes/scenario/settings/district', ['exports', 'ember-local-storage', 'dashboard/mixins/reset-scroll-position'], function (exports, _emberLocalStorage, _resetScrollPosition) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_resetScrollPosition.default, {

    sessionData: (0, _emberLocalStorage.storageFor)('session-data'),
    currentDistrict: '',

    model: function model(parameters) {
      this.set('currentDistrict', parameters.tax_district_id);
      return this.store.findRecord('taxDistrict', parameters.tax_district_id);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('scenario', this.controllerFor('scenario'));
    },


    actions: {
      willTransition: function willTransition() {
        var districtId = this.get('currentDistrict');
        this.set('sessionData.settingsSelection', 'district');
        this.set('sessionData.districtId', districtId);
      }
    }

  });
});