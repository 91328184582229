define('dashboard/mirage/serializers/project', ['exports', 'dashboard/mirage/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    include: ['scenarios', 'client'],

    serialize: function serialize() {
      var json = _application.default.prototype.serialize.apply(this, arguments);
      delete json.clients; // we actually just want the client to be added to the project payload but we do not sideload the whole record
      return json;
    },


    // WORKAROUND FOR EMBER CLI MIRAGE ISSUE: https://github.com/samselikoff/ember-cli-mirage/issues/972#issuecomment-315217050
    normalize: function normalize() {
      var response = _application.default.prototype.normalize.apply(this, arguments);

      response.data.attributes['client-id'] = response.data.attributes.client;
      delete response.data.attributes.client;

      return response;
    }
  });
});