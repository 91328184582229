define('dashboard/models/custom-cost-revenue', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    name: (0, _attr.default)('string'),
    type: (0, _attr.default)('string'),
    costRevenuePerHousehold: (0, _attr.default)('string', { defaultValue: '0' }),
    costRevenuePerWorker: (0, _attr.default)('string', { defaultValue: '0' }),
    annualPercentIncrease: (0, _attr.default)('string', { defaultValue: '0.02' }),
    doesApplyCustomCostRevenue: (0, _attr.default)('boolean', { defaultValue: true }),
    modifiedProperties: (0, _attr.default)({ defaultValue: function defaultValue() {
        return {};
      } }),
    adminCreated: (0, _attr.default)('boolean', { defaultValue: true }),
    deleted: (0, _attr.default)('boolean', { defaultValue: false })

  });
});