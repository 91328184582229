define('dashboard/routes/data-forms', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    titleToken: 'Data Forms',

    beforeModel: function beforeModel(transition) {
      var urlClientId = transition.params['data-forms'].client_id;
      if (!this.userCanAccessDataForms(urlClientId)) {
        this.transitionTo('data-forms', this.modelFor('authenticated').get('client.id'));
      }
    },
    model: function model(params) {
      var clientPromise = this.store.findRecord('client', params.client_id);

      return Ember.RSVP.hash({
        client: clientPromise,
        user: this.modelFor('authenticated')
      });
    },
    userCanAccessDataForms: function userCanAccessDataForms(paramsClientId) {
      var user = this.modelFor('authenticated');
      if (user.get('isSuperAdmin')) return true;

      var userClientId = user.get('client.id');
      return userClientId === paramsClientId;
    }
  });
});