define('dashboard/components/scenario-input-question', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    ajax: Ember.inject.service(),
    router: Ember.inject.service(),

    editingDataRequest: Ember.computed.equal('router.currentRouteName', 'data-requests.edit'),

    renderDropdownsInPlace: false,

    selectedMultiplier: Ember.computed('scenario.{currentMultiplierCode,multiplierCode}', 'question.isCurrent', function () {
      var currentCode = this.get('scenario.currentMultiplierCode');
      var futureCode = this.get('scenario.multiplierCode');
      var code = this.get('question.isCurrent') ? currentCode : futureCode;

      if (!code) {
        return null;
      }

      return { code: code };
    }),

    value: Ember.computed('scenario', 'question.dataProperty', 'isSimplifiedVersion', function () {
      var dataProperty = this.get('question.dataProperty');
      var data = this.get('scenario.' + dataProperty);
      if (this.get('isSimplifiedVersion') && Array.isArray(data)) {
        return data[0];
      } else {
        return data;
      }
    }),

    properties: Ember.computed('question.isCurrent', function () {
      var currentProperties = {
        usesCustomMultipliers: 'scenario.currentUsesCustomMultipliers',
        multiplierCode: 'scenario.currentMultiplierCode',
        earningsMultiplier: 'scenario.currentEarningsMultiplier',
        employmentMultiplier: 'scenario.currentEmploymentMultiplier',
        multiplier: 'scenario.currentMultiplier'
      };
      var futureProperties = {
        usesCustomMultipliers: 'scenario.usesCustomMultipliers',
        multiplierCode: 'scenario.multiplierCode',
        earningsMultiplier: 'scenario.earningsMultiplier',
        employmentMultiplier: 'scenario.employmentMultiplier',
        multiplier: 'scenario.multiplier'
      };

      return this.get('question.isCurrent') ? currentProperties : futureProperties;
    }),

    selectMultipliers: function selectMultipliers(multiplier, properties) {
      this.set(properties.usesCustomMultipliers, false);
      this.set(properties.multiplierCode, Ember.get(multiplier, 'code'));
      this.set(properties.earningsMultiplier, Ember.get(multiplier, 'earnings'));
      this.set(properties.employmentMultiplier, Ember.get(multiplier, 'employment'));
      this.set(properties.multiplier, multiplier);
      this.save();
    },


    actions: {
      updateNaicsSearch: function updateNaicsSearch(selectedMultiplier) {
        console.log('updateNaicsSearch', 'selectedMultiplier:', selectedMultiplier);
        // this action has a lot more explicit knowledge of the scenario data model than the other two actions
        // thus it may not be generalizeable to all naics searches
        var properties = this.get('properties');

        this.selectMultipliers(selectedMultiplier, properties);
      },
      onAddressSelect: function onAddressSelect(dataProperty, address) {
        var scenario = this.get('scenario');
        address = address ? address : this.get('value');
        scenario.set(dataProperty, address.formatted_address || address.name || address);
        this.save();
      },
      updateScenario: function updateScenario(value, property) {
        var dataProperty = property && typeof property === 'string' ? property : this.get('question.dataProperty');
        var scenario = this.get('scenario');
        scenario.set(dataProperty, value);

        this.save();
        this.notifyPropertyChange('value');
      }
    }
  });
});