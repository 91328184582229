define('dashboard/utils/observers/property-dependency', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (dependentProperty, parentProperty) {
    return Ember.observer(dependentProperty, parentProperty, function (obj, propertyChanged) {
      if (propertyChanged == parentProperty) {
        if (!this.get(parentProperty)) {
          this.set(dependentProperty, false);
        }
      } else {
        if (this.get(dependentProperty)) {
          this.set(parentProperty, true);
        }
      }
    });
  };
});