define('dashboard/objects/ei-taxable-sales-construction-worker-spending', ['exports', 'dashboard/utils/percent-each'], function (exports, _percentEach) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({

    associated: true,

    output: Ember.computed('investment', 'percentOne', 'percentTwo', 'percentThree', function () {

      var investment = this.get('investment'),
          associated = this.get('associated'),
          percentOne = this.get('percentOne'),
          percentTwo = this.get('percentTwo'),
          percentThree = this.get('percentThree'),
          output = void 0;

      if (associated) {
        output = (0, _percentEach.default)(investment, [percentOne, percentTwo, percentThree]);
      } else {
        output = investment.map(function () {
          return 0;
        });
      }

      return output;
    })

  });
});