define('dashboard/objects/ei-workers', ['exports', 'dashboard/objects/array-compute', 'dashboard/utils/multiply-each'], function (exports, _arrayCompute, _multiplyEach) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var EiWorkersObject = _arrayCompute.default.extend({

    direct: Ember.computed('jobsDirect', 'percentOne', function () {
      var jobs = this.get('jobsDirect') || [],
          percent = this.get('percentOne') || 0;

      return (0, _multiplyEach.default)(jobs, percent);
    }),

    indirect: Ember.computed('jobsIndirect', 'percentTwo', function () {
      var jobs = this.get('jobsIndirect') || [],
          percent = this.get('percentTwo') || 0;

      return (0, _multiplyEach.default)(jobs, percent);
    })

  });

  exports.default = EiWorkersObject;
});