define('dashboard/routes/insights', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    titleToken: 'Insights',

    model: function model(parameters) {
      var clientPromise = this.store.findRecord('client', parameters.client_id);
      var projectsPromise = this.store.query('project', { client: parameters.client_id });
      var requestTemplatesPromise = this.store.query('request-template', { client: parameters.client_id });
      var scenariosPromise = this.store.query('scenario', { client: parameters.client_id });
      var tagsPromise = this.store.query('tag', { client: parameters.client_id });

      return Ember.RSVP.hash({
        client: clientPromise,
        projects: projectsPromise,
        requestTemplates: requestTemplatesPromise,
        scenarios: scenariosPromise,
        tags: tagsPromise
      });
    },
    setupController: function setupController(controller, models) {
      controller.setProperties({
        client: models.client,
        projects: models.projects,
        requestTemplates: models.requestTemplates.filterBy('lockedToScenario', false).filterBy('deleted', false),
        dataRequests: models.scenarios.filterBy('isRequest'),
        tags: models.tags
      });

      this._super(controller, models.client);
    }
  });
});