define('dashboard/components/modals/modal-confirm', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: ['isHidden:custom-hidden'],
    classNames: ['modal-overlay'],
    attributeBindings: ['data-test'],

    keyPress: function keyPress(e) {
      // Return / Enter key.
      if (e.keyCode === 13 && this.get('submitOnEnter') && !this.get('disableButton') && !this.get('disabled') && !this.get('isWalkthrough')) {
        this.send('submit');
      }
    },


    keyUp: function keyUp(e) {
      // Esc Key
      if (e.keyCode === 27) {
        this.send('cancel');
      }
    },

    animation: Ember.computed('animationOut', function () {
      return this.get('animationOut') ? 'modal-slide-down' : 'modal-slide-up';
    }),

    animationOut: false,

    focusFirst: Ember.observer('isHidden', function () {
      var _this = this;

      if (this.get('isHidden') === false && this.get('focus') !== false) {
        Ember.run.later(function () {
          _this.$('input').first().focus();
        }, 100);
      }
    }),

    // clicking on the overlay will close the modal
    mouseUp: function mouseUp(el) {
      if (Ember.$(el.target).hasClass('modal-overlay') && !this.get('isWalkthrough')) {
        this.send('cancel');
      }
    },


    actions: {

      // override this action in custom modals if more close action is needed
      cancel: function cancel() {
        if (this.get('cancelAction')) {
          this.sendAction('cancelAction');
        }
        this.send('slideOut');
      },


      // override this action in custom modals if more submit action is needed
      submit: function submit() {
        this.sendAction();
        if (!this.get('isDestroyed')) {
          this.send('slideOut');
        }
      },
      slideOut: function slideOut() {
        var _this2 = this;

        if (this.get('isDestroyed')) {
          return;
        }

        this.set('animationOut', true);
        Ember.run.later(function () {
          // there's a slight chance we closed the modal elsewhere or transitioned to another page
          // if so, we get an error if we try to call set on a destroyed object
          if (_this2.get('isDestroyed')) {
            return;
          }
          _this2.set('isHidden', true);
          _this2.set('animationOut', false);
        }, 200);
      }
    }
  });
});