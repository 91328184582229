define('dashboard/mirage/serializers/client', ['exports', 'dashboard/mirage/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    include: ['assumption', 'districtGroups', 'users', 'requestTemplates', 'projects'],

    normalize: function normalize(_ref) {
      var _ApplicationSerialize;

      var client = _ref.client;

      // TODO this might turn out hacky when we actually need to set the user using POST or PUT
      // client.userId = client.user;
      delete client.users;
      delete client.districtGroups;
      delete client.requestTemplates;

      // this might turn out hacky when we actually need to set the asuumption using POST or PUT
      delete client.assumption;

      return (_ApplicationSerialize = _application.default.prototype).normalize.apply(_ApplicationSerialize, arguments);
    }
  });
});