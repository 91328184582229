define('dashboard/components/tag-row', ['exports', 'dashboard/mixins/hover'], function (exports, _hover) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_hover.default, {

    tagName: 'tr',
    classNames: ['clickable'],

    click: function click() {
      this.sendAction('transitionToFilteredProjects', this.get('tag'));
    },


    actions: {
      openDeleteTag: function openDeleteTag() {
        var tag = this.get('tag');
        this.sendAction('openDeleteTag', tag);
      },
      update: function update() {
        var tag = this.get('tag.tag');
        tag.save();
      }
    }
  });
});