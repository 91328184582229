define('dashboard/components/tables/clickable-row', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'tr',
    classNames: ['clickable'],

    click: function click() {
      this.clickAction();
    }
  });
});