define('dashboard/mixins/read-only-restrictions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    notify: Ember.inject.service(),
    session: Ember.inject.service(),

    isReadOnly: Ember.computed('client.readOnlyAccess', 'session.currentUser.isSuperAdmin', function () {
      return this.checkReadOnly();
    }),

    checkReadOnly: function checkReadOnly() {
      var client = this.get('client');
      var isSuperAdmin = this.get('session.currentUser.isSuperAdmin') || false;

      if (isSuperAdmin || !client) {
        return false;
      }

      if (client.get('readOnlyAccess')) {
        this.get('notify').alert('You currently have read only access.');
        return true;
      }
    }
  });
});