define('dashboard/models/tif-district', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    client: (0, _relationships.belongsTo)('client'),
    tifSchedules: (0, _relationships.hasMany)('tifSchedule'),

    created: (0, _attr.default)('date'),
    name: (0, _attr.default)('string'),
    enterAsDecimal: (0, _attr.default)('boolean', { defaultValue: false }),
    adminCreated: (0, _attr.default)('boolean', { defaultValue: true }),
    forScenario: (0, _attr.default)('boolean', { defaultValue: false }),
    deleted: (0, _attr.default)('boolean', { defaultValue: false }),

    sortedSchedules: Ember.computed('tifSchedules.@each.order', function () {
      return this.get('tifSchedules').sortBy('order');
    })
  });
});