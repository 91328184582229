define('dashboard/objects/tif-impact-engine', ['exports', 'dashboard/utils/float-map', 'dashboard/utils/sum-array', 'dashboard/utils/sum-many-arrays', 'dashboard/objects/impact-engine', 'dashboard/objects/tif-incentive-engine'], function (exports, _floatMap, _sumArray, _sumManyArrays, _impactEngine, _tifIncentiveEngine) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // Objects
  var tifImpactEngine = _impactEngine.default.extend({

    aggregateTifContributionTotals: Ember.computed.alias('scenario.aggregateTifContributionTotals'),

    fiPropertyTaxLand: Ember.computed.alias('aggregateTifContributionTotals.land'),
    fiPropertyTaxBuildings: Ember.computed.alias('aggregateTifContributionTotals.buildings'),

    fiPropertyTaxesFFE: Ember.computed('aggregateTifContributionTotals.furniture', function () {
      var annual = this.get('aggregateTifContributionTotals.furniture') || [],
          total = (0, _sumArray.default)(annual);

      return Ember.Object.create({
        project: total,
        workers: 0,
        total: total,
        annual: annual
      });
    }),

    fiSalesTaxes: Ember.computed('aggregateTifContributionTotals.taxableSales', function () {
      var annual = this.get('aggregateTifContributionTotals.taxableSales') || [],
          total = (0, _sumArray.default)(annual);

      return Ember.Object.create({
        project: total,
        workers: 0,
        total: total,
        annual: annual
      });
    }),

    fiPropertyTaxesInventories: Ember.computed('aggregateTifContributionTotals.inventory', function () {
      var annual = this.get('aggregateTifContributionTotals.inventory') || [],
          total = (0, _sumArray.default)(annual);

      return Ember.Object.create({
        inventoryPropertyTax: annual,
        project: total,
        workers: 0,
        total: total
      });
    }),

    annualBenefits: Ember.computed('fiRealPropertyTaxes.annual', 'fiPropertyTaxesFFE.annual', 'fiPropertyTaxesInventories.inventoryPropertyTax', 'fiSalesTaxes.annual', function () {
      var sales = (0, _floatMap.default)(this.get('fiSalesTaxes.annual')),
          propertyReal = this.get('fiRealPropertyTaxes.annual'),
          propertyFFE = this.get('fiPropertyTaxesFFE.annual'),
          propertyInventories = this.get('fiPropertyTaxesInventories.inventoryPropertyTax');

      return (0, _sumManyArrays.default)([sales, propertyReal, propertyFFE, propertyInventories]);
    }),

    incentives: Ember.computed('schedule.incentives', 'assumption.scenarioLength', function () {
      return this.get('schedule.incentives').slice(0, this.get('assumption.scenarioLength'));
    }),

    nonTaxIncentiveEngine: Ember.computed('assumption', 'scenario', 'schedule.incentives', 'eiJobs.lastDirectCumulative', 'annualNetBenefits.direct', function () {

      return _tifIncentiveEngine.default.create({
        assumption: this.get('assumption'),
        schedule: this.get('schedule'),
        jobs: this.get('eiJobs.lastDirectCumulative'),
        annualNetBenefits: this.get('annualNetBenefits.direct'),
        incentiveComparison: 'nonTax',
        scenario: this.get('scenario')
      });
    })

  });

  // Utils
  exports.default = tifImpactEngine;
});