define('dashboard/components/graphs/incentives-graph', ['exports', 'dashboard/components/graphs/d3-chart', 'dashboard/utils/sum-array'], function (exports, _d3Chart, _sumArray) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = _d3Chart.default.extend({
    classNameBindings: ['graphColumnCountClass'],
    yRangeSafety: 8,

    graphColumnCountClass: Ember.computed('data.length', function () {
      return 'column-count-' + this.get('data.length');
    }),

    dataExists: Ember.computed('data', 'incentives', function () {
      return this.get('data') && this.get('incentives');
    }),

    svg: Ember.computed(function () {
      return d3.select(this.$().children('svg')[0]);
    }),

    cumulativeIncentive: Ember.computed('incentives', function () {
      var incentives = this.get('incentives').map(function (incentive) {
        return parseFloat(incentive.value);
      });

      var cumulativeIncentive = (0, _sumArray.default)(incentives);

      return cumulativeIncentive;
    }),

    incentiveValues: Ember.computed('incentives', function () {
      return this.get('incentives').map(function (incentive) {
        return parseFloat(incentive.value);
      });
    }),

    cumulativeIncentiveData: Ember.computed('cumulativeIncentive', function () {
      var xDomain = this.get('xDomain'),
          cumulativeIncentive = this.get('cumulativeIncentive');

      return xDomain.map(function (x, index) {
        return { year: index + 1, value: cumulativeIncentive };
      });
    }),

    benefitsData: Ember.computed('data', function () {
      var data = this.get('data');
      return this.get('xDomain').map(function (x, index) {
        var value = data[index] ? data[index] : 0;
        return { year: index + 1, value: value };
      });
    }),

    yMax: Ember.computed('data', 'incentives', function () {
      var maxData = d3.max(this.get('data')),
          maxIncentive = this.get('cumulativeIncentive');

      return maxData > maxIncentive ? maxData : maxIncentive;
    }),

    yMin: Ember.computed('data', function () {
      var minData = d3.min(this.get('data'));
      return minData > 0 ? 0 : minData;
    }),

    xMax: Ember.computed('canvasWidth', 'canvasMarginLeft', 'canvasMarginRight', 'years', function () {
      var years = this.get('years'),
          defaultMarginLeft = 70,
          defaultMax = this.get('canvasWidth') - (defaultMarginLeft + this.get('canvasMarginRight'));

      if (years >= 10) {
        return defaultMax;
      } else {
        return defaultMax - (10 - years) * 30;
      }
    }),

    // draw 2 tick marks for y axis on summary graph, total incentive and max cumulative net benefits
    yTickValues: Ember.computed('cumulativeIncentiveData', 'benefitsData', 'netBenefitsTotal', function () {
      var intersectionPoint = {},
          cumulativeIncentiveData = this.get('cumulativeIncentiveData'),
          benefitsData = this.get('benefitsData'),
          yMax = parseInt(this.get('netBenefitsTotal')),
          yScale = this.get('yScale');

      benefitsData.forEach(function (benefitsDatum, index) {
        var cumulativeIncentiveDatum = cumulativeIncentiveData[index];
        if (!intersectionPoint.y && benefitsDatum.value > cumulativeIncentiveDatum.value) {
          intersectionPoint = {
            x: benefitsDatum.value,
            y: cumulativeIncentiveDatum.value
          };
        }
      });

      // make sure 2 y axis ticks are a decent distance away from each other
      if (yScale(intersectionPoint.y) - yScale(yMax) > 12) {
        return [intersectionPoint.y, yMax];
      }
      return [yMax];
    }),

    drawAxis: function drawAxis() {
      if (this.get('class') !== 'summary-report-incentives-graph') {
        return this._super();
      } else {

        var x = this.get('x'),
            yScale = this.get('yScale'),
            canvasMarginLeft = this.get('canvasMarginLeft'),
            canvasMarginTop = this.get('canvasMarginTop'),
            yTick = 172,
            elId = this.get('elementId'),
            years = this.get('years'),
            xAxisEl = d3.select('.' + elId + '.axis.x'),
            // These need to be unique or multiple instances don't work
        yAxisEl = d3.select('.' + elId + '.axis.y'),
            xAxis = d3.axisBottom().scale(x),
            yAxis = d3.axisLeft().scale(yScale).tickValues(this.get('yTickValues')).tickFormat(function (d) {
          return numeral(d).format('($000.0a)');
        }),
            svg = this.get('svg');

        if (xAxisEl.empty()) {
          svg.insert('g', ':nth-child(2)').attr('class', 'x axis ' + elId).attr('transform', 'translate(' + canvasMarginLeft + ',' + yTick + ')').call(xAxis);
        } else {
          xAxisEl.transition().duration(1000).attr('transform', 'translate(' + canvasMarginLeft + ',' + yTick + ')').call(xAxis);
        }

        if (yAxisEl.empty()) {
          svg.insert('g', ':nth-child(2)').attr('class', 'y axis ' + elId).attr('transform', 'translate(' + canvasMarginLeft + ',' + canvasMarginTop + ')').call(yAxis);
        } else {
          yAxisEl.transition().duration(1000).attr('transform', 'translate(' + canvasMarginLeft + ',' + canvasMarginTop + ')').call(yAxis);
        }

        // if summary graph, include payback period tick
        if (this.get('class') === 'summary-report-incentives-graph') {

          // we're not able to map a decimal on our xDomain, so we have to fake it by finding the point between 2 x axis values
          var paybackPeriod = this.get('paybackPeriod'),
              _x = this.get('x'),
              wholePart = void 0,
              partialPart = void 0,
              customX = void 0;
          wholePart = parseInt(paybackPeriod);
          partialPart = paybackPeriod % 1;
          var xWhole = typeof _x(wholePart) === "undefined" ? 0 : _x(wholePart); // x(0) is undefined for some reason
          customX = (_x(wholePart + 1) - xWhole) * partialPart + xWhole + _x.bandwidth() / 2;

          svg.selectAll('.payback-period').remove();

          if (paybackPeriod + 0.5 < years) {
            var paybackTick = svg.select('.x.axis').append('g').lower().attr('class', 'tick payback-period').attr('transform', 'translate(' + customX + ',' + 0 + ')');

            paybackTick.append('line').attr('y2', '6');

            paybackTick.append('text').attr('x', "0.5").attr('dy', "0.71em").attr('y', '9').text(paybackPeriod.toFixed(1));
          }
        }
      }
    },


    drawChart: function drawChart() {

      var data = this.get('data'),
          incentives = this.get('incentives'),
          incentiveValues = this.get('incentiveValues'),
          cumulativeIncentiveData = this.get('cumulativeIncentiveData'),
          canvasMarginLeft = this.get('canvasMarginLeft'),
          x = this.get('x'),
          yScale = this.get('yScale'),
          canvasMarginTop = this.get('canvasMarginTop'),
          zeroOffset = yScale(0) + canvasMarginTop,
          y = function y(d) {
        return yScale(d.value);
      },
          h = function h(d) {
        return d.value < 0 ? yScale(d.value) - zeroOffset : zeroOffset - yScale(d.value);
      },
          svg = this.get('svg'),
          line = d3.line().x(function (d, index) {
        return canvasMarginLeft + x(index + 1) + x.bandwidth() / 2;
      }).y(function (d) {
        return y(d);
      }),
          bars = svg.selectAll('.incentive-bar').data(incentives),
          incentiveLine = svg.selectAll('.incentive-line').data([incentiveValues]),
          benefitsLine = svg.selectAll('.line').data([data]),
          yearsToolTip = this.get('yearsToolTip'),
          benefitsData = this.get('benefitsData'),
          calendarYearDisplay = this.get('calendarYearDisplay'),
          startingYear = calendarYearDisplay ? parseInt(this.get('startingCalendarYear')) : 1;

      // Bars Enter
      bars.enter().append('rect').attr('class', 'incentive-bar').attr('x', function (d, index) {
        return canvasMarginLeft + x(index + 1);
      }).attr('width', x.bandwidth()).attr('y', function (d) {
        return d.value < 0 ? zeroOffset + canvasMarginTop : y(d);
      }).attr('height', h);

      // Bars Change
      bars.transition().duration(1000).attr('y', function (d) {
        return d.value < 0 ? zeroOffset + canvasMarginTop : y(d);
      }).attr('height', h);

      // Incentives Line Enter
      incentiveLine.enter().append('path').attr('class', 'incentive-line').style('stroke-dasharray', '8, 10').attr('d', line(cumulativeIncentiveData));

      // Incentives Line Change
      incentiveLine.transition().duration(1000).attr('d', line(cumulativeIncentiveData));

      // Benefits Line Enter
      benefitsLine.enter().append('path').attr('class', 'line').attr('d', line(benefitsData));

      // Benefits Line Change
      benefitsLine.transition().duration(1000).attr('d', line(benefitsData));

      // prevents duplicate focus elements when drawChart gets called multiple times
      svg.selectAll('.focus').remove();

      var incentiveFocus = svg.append('g').attr('class', 'incentive-focus focus').style('display', 'none');

      incentiveFocus.append('rect').attr('class', 'circle-text-bg').style('display', 'none');

      incentiveFocus.append('circle').attr('r', 7.5).style('fill', 'none').style('stroke-width', '2px');

      incentiveFocus.append('text').attr('x', 15).attr('dy', '.31em').attr('class', 'circle-text');

      // Circle over closest year's cumulative net benefits line
      var benefitsFocus = svg.append('g').attr('class', 'benefits-focus focus').style('display', 'none');

      benefitsFocus.append('rect').attr('class', 'circle-text-bg').style('display', 'none');

      benefitsFocus.append('circle').attr('r', 7.5).style('fill', 'none').style('stroke-width', '2px');

      benefitsFocus.append('text').attr('x', 15).attr('dy', '.31em').attr('class', 'circle-text');

      var hoverFocus = svg.append('g').attr('class', 'hover-focus focus').style('display', 'none');

      hoverFocus.append('rect').attr('class', 'hover-text-bg').attr('x', 15).style('fill', '#303030').style('fill-opacity', '.92').style('width', '160px').style('height', '4.0em').style('pointer-events', 'none');

      hoverFocus.append('text').attr('x', 20).attr('dy', '1.3em').attr('class', 'year-text');

      hoverFocus.append('text').attr('x', 20).attr('dy', '2.6em').attr('class', 'text-1');

      hoverFocus.append('text').attr('x', 20).attr('dy', '3.9em').attr('class', 'text-2');

      var visibleOnHover = [benefitsFocus, incentiveFocus, hoverFocus];

      var mouseOver = function mouseOver() {
        return visibleOnHover.map(function (f) {
          return f.style('display', null);
        });
      };

      var mouseOut = function mouseOut() {
        return visibleOnHover.map(function (f) {
          return f.style('display', 'none');
        });
      };

      var updateHoverValues = function updateHoverValues(xPos, yPos, nearestPoint) {
        var xVal = cumulativeIncentiveData[0].value,
            yVal = nearestPoint.value,
            formattedIncentive = numeral(xVal).format(xVal >= 1000000 ? '($0.00a)' : '($0,0)'),
            formattedBenefit = numeral(yVal).format(yVal >= 1000000 ? '($0.00a)' : '($0,0)');

        hoverFocus.attr('transform', 'translate(' + (xPos + canvasMarginLeft) + ',' + yPos + ')');
        hoverFocus.selectAll('.year-text').text('Year: ' + (nearestPoint.year + startingYear - 1));

        var flipTextFields = y(cumulativeIncentiveData[0]) >= y(nearestPoint);
        var text1Content = flipTextFields ? 'Cumulative NB: ' + formattedBenefit : 'Total Incentive: ' + formattedIncentive;

        var text2Content = flipTextFields ? 'Total Incentive: ' + formattedIncentive : 'Cumulative NB: ' + formattedBenefit;

        hoverFocus.selectAll('.text-1').text(text1Content);
        hoverFocus.selectAll('.text-2').text(text2Content);
      };

      var focusChange = function focusChange(nearestPoint) {
        var benefitTranslateX = x(nearestPoint.year) + canvasMarginLeft + x.bandwidth() / 2,
            benefitTranslateY = y(nearestPoint),
            incentiveTranslateX = x(nearestPoint.year) + canvasMarginLeft + x.bandwidth() / 2,
            incentiveTranslateY = y(cumulativeIncentiveData[0]);

        // move circle and text to nearest point on net benefits and incentive lines
        benefitsFocus.attr('transform', 'translate(' + benefitTranslateX + ',' + benefitTranslateY + ')');
        incentiveFocus.attr('transform', 'translate(' + incentiveTranslateX + ',' + incentiveTranslateY + ')');
      };

      var mouseMove = function mouseMove() {
        var _d3$mouse = d3.mouse(this),
            _d3$mouse2 = _slicedToArray(_d3$mouse, 2),
            mouseXPos = _d3$mouse2[0],
            mouseYPos = _d3$mouse2[1];

        var lastElem = benefitsData[benefitsData.length - 1];
        var needsMargin = _typeof(arguments.length <= 0 ? undefined : arguments[0]) === "object" ? true : false; // happens when hovering over incentive and net benefits lines
        var nearestPoint = benefitsData.find(function (elem, idx, arr) {
          var nextElem = idx + 1 !== arr.length ? arr[idx + 1] : elem;
          return x(nextElem.year) >= (needsMargin ? mouseXPos - canvasMarginLeft : mouseXPos);
        }) || lastElem;
        focusChange(nearestPoint);

        // if we're hovering over the second half of the graph, show tooltip on left of mouse
        if (nearestPoint.year >= data.length * 0.6) {
          mouseXPos = mouseXPos - 190 - (needsMargin ? canvasMarginLeft : 0);
        }
        updateHoverValues(mouseXPos, mouseYPos, nearestPoint);
      };

      // mouse over graph bg or axis lines - normal circle behavior
      svg.selectAll('.graph-bg, line, .line, .incentive-line, .incentive-bar').on('mouseover', mouseOver).on('mouseout', mouseOut).on('mousemove', mouseMove);

      // mouse over circle or text itself - just don't make it disappear as if it's mouseout(ing) other elements
      svg.selectAll('circle, .circle-text, .circle-text-bg').on('mouseover', mouseOver);

      // bar tooltips
      this.$('rect.incentive-bar').tipsy({
        gravity: 's',
        html: true,
        title: function title() {
          var d = this.__data__,
              year = d.year + startingYear - 1,
              format = d.value >= 1000000 || d.value <= -1000000 ? '($0.00a)' : '($0,0)';
          return '' + yearsToolTip + year + '<br />Incentive: ' + numeral(d.value).format(format);
        },
        opacity: 1
      });
    }

  });
});