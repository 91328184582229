define('dashboard/routes/district-area-set', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    titleToken: function titleToken(models) {
      return ['District Area Sets', models.districtAreaSet.get('name')];
    },

    model: function model(params) {
      var districtAreaSetPromise = this.store.findRecord('districtAreaSet', params.district_area_set_id),
          districtAreasPromise = this.store.query('districtArea', { districtAreaSet: params.district_area_set_id, limit: 100, skip: 0 });

      return Ember.RSVP.hash({
        districtAreaSet: districtAreaSetPromise,
        districtAreas: districtAreasPromise
      });
    },
    setupController: function setupController(controller, models) {
      controller.set('districtAreaSet', models.districtAreaSet);
      this._super(controller, models.districtAreas);
    },
    afterModel: function afterModel(models) {
      this.controllerFor('districtAreaSets').set('currentDistrictAreaSet', models.districtAreaSet);
    }
  });
});