define('dashboard/components/buttons/create-project-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['pr-0'],
    classNameBindings: ['creating:col-sm-4'],
    creating: false,
    title: '',

    keyPress: function keyPress(e) {
      // Return / Enter key.
      if (e.keyCode === 13 && !Ember.$('.create-project').is(':focus')) {
        this.send('create');
      }
    },

    today: moment(),

    actions: {
      toggleCreating: function toggleCreating() {
        this.set('title', '');
        this.toggleProperty('creating');
      },
      create: function create() {
        this.sendAction('action', this.get('title'));
        this.set('creating', false);
        this.set('title', '');
      }
    }
  });
});