define('dashboard/mixins/scenario-collapsible-sections', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    init: function init() {
      this._super.apply(this, arguments);

      this.set('sortedSections', []);
      this.set('collapsibleSections', Ember.Object.create());
    },


    showFirstSectionOnly: function showFirstSectionOnly() {
      this.get('collapsibleSections').setProperties({
        jobCreationIsHidden: true,
        industryInfoIsHidden: true,
        directWorkersIsHidden: true,
        salariesIsHidden: true,
        capitalInvestmentIsHidden: true,
        taxableInventoriesIsHidden: true,
        taxablePurchasesIsHidden: true,
        taxableSalesIsHidden: true,
        hotelNightsIsHidden: true,
        currentOperationsIsHidden: true,
        primaryDistrictIsHidden: true,
        requestContactInfoIsHidden: true,
        requestCompanyInfoIsHidden: true,
        requestBackground: true,
        description: true,
        internalNotes: true
      });

      if (this.get('model.isExistingBusiness')) {
        this.set('collapsibleSections.currentOperationsIsHidden', false);
      } else {
        this.set('collapsibleSections.jobCreationIsHidden', false);
      }
    },

    showRequestSections: function showRequestSections() {
      this.get('collapsibleSections').setProperties({
        requestContactInfoIsHidden: false,
        requestCompanyInfoIsHidden: false,
        industryInfoIsHidden: false,
        jobCreationIsHidden: false,
        directWorkersIsHidden: false,
        salariesIsHidden: false,
        capitalInvestmentIsHidden: false,
        taxableInventoriesIsHidden: false,
        taxablePurchasesIsHidden: false,
        taxableSalesIsHidden: false,
        requestBackground: false,
        primaryDistrictIsHidden: false
      });
    },

    sortedSectionsForScenario: Ember.computed('primaryDistrict', 'hasCapitalInvestments', 'hasDirectRevenues', 'hasDirectCosts', 'primaryDistrict.{doesApplyPropertyTaxRateInventories,doesApplySalesTax}', 'activeDirectCostRevenues.@each', function () {
      var primaryDistrict = this.get('primaryDistrict'),
          hasCapitalInvestments = this.get('hasCapitalInvestments'),
          hasDirectRevenues = this.get('hasDirectRevenues'),
          hasDirectCosts = this.get('hasDirectCosts'),
          doesApplyTaxRate = this.get('primaryDistrict.doesApplyPropertyTaxRateInventories'),
          doesApplySalesTax = this.get('primaryDistrict.doesApplySalesTax'),
          isExistingBusiness = this.get('model.isExistingBusiness');
      var result = [];

      if (isExistingBusiness) {
        result.push('collapsibleSections.currentOperationsIsHidden');
      }
      result.push('collapsibleSections.jobCreationIsHidden');

      if (primaryDistrict) {
        result.push('collapsibleSections.primaryDistrictIsHidden');
      }
      result.push('collapsibleSections.salariesIsHidden');
      if (hasCapitalInvestments) {
        result.push('collapsibleSections.capitalInvestmentIsHidden');
      }
      this.get('buildingPermitsFeesDistricts').forEach(function (district) {
        result.push('collapsibleSections.bpf' + district.get('id') + 'isHidden');
      });
      if (hasDirectRevenues) {
        this.get('activeDirectCostRevenues').filterBy('type', 'revenue').forEach(function (costRev) {
          result.push('collapsibleSections.' + costRev.get('id') + 'isHidden');
        });
      }
      if (doesApplyTaxRate) {
        result.push('collapsibleSections.taxableInventoriesIsHidden');
      }
      if (doesApplySalesTax) {
        result.push('collapsibleSections.taxablePurchasesIsHidden');
        result.push('collapsibleSections.taxableSalesIsHidden');
      }
      result.push('collapsibleSections.hotelNightsIsHidden');
      if (hasDirectCosts) {
        this.get('activeDirectCostRevenues').filterBy('type', 'cost').forEach(function (costRev) {
          result.push('collapsibleSections.' + costRev.get('id') + 'isHidden');
        });
      }
      result.push('collapsibleSections.description');
      result.push('collapsibleSections.internalNotes');
      return result;
    }),

    sortedSectionsForRequest: Ember.computed('hasCapitalInvestments', 'primaryDistrict.{doesApplyPropertyTaxRateInventories,doesApplySalesTax}', 'hasDirectRevenues', 'hasDirectCosts', function () {
      var hasCapitalInvestments = this.get('hasCapitalInvestments'),
          doesApplyTaxRate = this.get('primaryDistrict.doesApplyPropertyTaxRateInventories'),
          doesApplySalesTax = this.get('primaryDistrict.doesApplySalesTax'),
          hasDirectRevenues = this.get('hasDirectRevenues'),
          hasDirectCosts = this.get('hasDirectCosts');
      var result = ['collapsibleSections.requestContactInfoIsHidden', 'collapsibleSections.requestCompanyInfoIsHidden', 'collapsibleSections.industryInfoIsHidden', 'collapsibleSections.jobCreationIsHidden', 'collapsibleSections.salariesIsHidden'];
      if (hasCapitalInvestments) {
        result.push('collapsibleSections.capitalInvestmentIsHidden');
      }
      this.get('buildingPermitsFeesDistricts').forEach(function (district) {
        result.push('collapsibleSections.bpf' + district.get('id') + 'isHidden');
      });
      if (hasDirectRevenues) {
        this.get('activeDirectCostRevenues').filterBy('type', 'revenue').forEach(function (costRev) {
          result.push('collapsibleSections.' + costRev.get('id') + 'isHidden');
        });
      }
      if (doesApplyTaxRate) {
        result.push('collapsibleSections.taxableInventoriesIsHidden');
      }
      if (doesApplySalesTax) {
        result.push('collapsibleSections.taxablePurchasesIsHidden');
        result.push('collapsibleSections.taxableSalesIsHidden');
      }
      if (hasDirectCosts) {
        this.get('activeDirectCostRevenues').filterBy('type', 'cost').forEach(function (costRev) {
          result.push('collapsibleSections.' + costRev.get('id') + 'isHidden');
        });
      }
      result.push('collapsibleSections.requestBackground');
      return result;
    }),

    actions: {
      nextSection: function nextSection(currentSection, sortedProp) {
        // nextId, sortedSectionsForScenario
        var sections = this.get(sortedProp),
            nextSectionIndex = sections.indexOf(currentSection) + 1;

        this.set(currentSection, true);
        this.set(sections.objectAt(nextSectionIndex), false);
      }
    }
  });
});