define('dashboard/components/modals/pre-transition-confirm-modal', ['exports', 'dashboard/components/modals/modal-confirm'], function (exports, _modalConfirm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _modalConfirm.default.extend({

    actions: {
      submit: function submit() {
        this.sendAction();
      }
    }
  });
});