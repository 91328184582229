define('dashboard/controllers/data-requests/preview', ['exports', 'dashboard/mixins/color-scheme'], function (exports, _colorScheme) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_colorScheme.default, {
    queryParams: ['currentSectionIndex'],
    currentSectionIndex: 0
  });
});