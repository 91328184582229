define('dashboard/mirage/factories/project', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    title: _faker.default.lorem.word,
    client: (0, _emberCliMirage.association)()
  });
});