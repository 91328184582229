define('dashboard/controllers/projects', ['exports', 'dashboard/mixins/color-scheme'], function (exports, _colorScheme) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_colorScheme.default, {
    init: function init() {
      this._super.apply(this, arguments);
      this.set('sortColumns', [{
        title: ''
      }, {
        title: 'Project Title',
        sortField: 'title'
        // width: '20%'
      }, {
        title: 'Scenarios',
        sortField: 'indexedScenariosOnly.length'
        // width: '20%'
      }, {
        title: 'Project Type',
        sortField: 'projectType'
      }, {
        title: 'Industry',
        sortField: 'industry'
      }, {
        title: 'Tags',
        sortField: 'tag'
        // width: '20%'
      }, {
        title: 'Last Updated',
        sortField: 'lastUpdatedScenario',
        textAlign: 'right'
      }]);
    },


    faSearch: '\uF002',

    application: Ember.inject.controller(),

    mainColorSetter: Ember.observer('model.client.colorTheme', function () {
      var colorTheme = this.get('model.client.colorTheme') || 'Blue';
      this.send('setThemeColors', colorTheme);
    })

  });
});