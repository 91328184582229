define('dashboard/components/checkbox-component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['form-group'],

    // this component is used in places other than advanced inputs so we don't always have columns...
    change: function change() {
      var column = this.get('column');
      this.sendAction('action', column); // "updateDoesApply", column
    },


    computedId: Ember.computed(function () {
      return (this.passedId || this.elementId) + 'input';
    }),
    computedIdUnique: Ember.computed('computedId', function () {
      return this.get('computedId') + Math.random();
    })

  });
});