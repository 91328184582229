define('dashboard/routes/tax-districts/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    titleToken: 'Tax Districts',

    ajax: Ember.inject.service(),

    model: function model() {
      var _this = this;

      this.store.findAll('customCostRevenue');
      // @TODO load directCostRevenue too?
      return this.get('ajax').request('/taxDistrictsList', {
        data: { adminCreated: true, isActive: true, createdInTransition: { $ne: true } },
        contentType: 'application/json; charset=utf-8',
        dataType: 'json'
      }).then(function (districts) {
        _this.store.pushPayload(districts);
      }).then(function () {
        return _this.store.peekAll('taxDistrict');
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.controllerFor('tax-districts').set('currentDistrict', null);
      this.controllerFor('tax-districts').set('activeGroup', true);
    },


    actions: {
      editDistrict: function editDistrict(district) {
        this.controllerFor('tax-districts').set('currentDistrict', district);
        this.transitionTo('tax-district', district.get('id'));
      }
    }
  });
});