define('dashboard/routes/scenario/public-support/overview', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    controllerName: 'scenario',

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      var innerContent = document.getElementsByClassName('inner-content')[0];
      var body = document.getElementsByTagName('body')[0];

      body.style.margin = 0;
      innerContent.scroll(0, 0);
    },
    model: function model() {
      return this.modelFor('scenario').scenario;
    },
    afterModel: function afterModel(model) {
      if (!model) {
        this.controllerFor('scenario').set('lastVisitedGroup', null);
        return this.transitionTo('scenario.public-support');
      }
    },
    setupController: function setupController(controller, model) {
      this.controllerFor('scenario').set('lastVisitedGroup', 'overview');
      controller.set('scenario', model);
      controller.notifyPropertyChange('selectedSchedule');
    }
  });
});