define('dashboard/objects/ei-residential-properties', ['exports', 'dashboard/objects/array-compute', 'dashboard/utils/percent-each'], function (exports, _arrayCompute, _percentEach) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var EiResidentialPropertiesObject = _arrayCompute.default.extend({

    direct: Ember.computed('directWorkers', 'percent', function () {
      var workers = this.get('directWorkers'),
          percent = this.get('percent');

      return (0, _percentEach.default)(workers, percent);
    }),

    indirect: Ember.computed('indirectWorkers', 'percent', function () {
      var workers = this.get('indirectWorkers'),
          percent = this.get('percent');

      return (0, _percentEach.default)(workers, percent);
    })

  });

  exports.default = EiResidentialPropertiesObject;
});