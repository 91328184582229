define('dashboard/mixins/current-user-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    session: Ember.inject.service(),

    currentUser: Ember.computed.alias('session.currentUser'),

    // returns logged in user or first user belonging to client if logged in as super admin
    currentUserNotSuperAdmin: Ember.computed('currentUser', function () {
      if (this.get('currentUser.isSuperAdmin')) {
        if (this.get('client.id')) {
          return this.get('client.users.firstObject');
        } else {
          return null;
        }
      }

      return this.get('currentUser');
    })

  });
});