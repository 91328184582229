define('dashboard/mirage/factories/tax-district', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    name: _faker.default.lorem.word,
    doesApplyBuildingPermitsAndFees: true
  });
});