define('dashboard/components/modals/user-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: ['isHidden:custom-hidden'],
    classNames: ['modal-overlay'],
    attributeBindings: ['data-test'],

    store: Ember.inject.service(),
    notify: Ember.inject.service(),
    session: Ember.inject.service(),

    keyPress: function keyPress(e) {
      // Return / Enter key.
      if (e.keyCode === 13 && this.get('submitOnEnter') && !this.get('disableButton') && !this.get('disabled') && !this.get('isWalkthrough')) {
        this.send('submit');
      }
    },


    keyUp: function keyUp(e) {
      if (e.keyCode === 27) this.send('cancel');
    },

    currentUser: Ember.computed.alias('session.currentUser'),

    canManageUsers: Ember.computed.alias('currentUser.canManageUsers'),

    modalLabel: Ember.computed('disabled', 'user.{isNew,fullName}', function () {
      if (this.get('disabled')) return 'User Information';
      if (this.get('user.isNew')) return 'Invite User';
      var name = this.get('user.fullName') || 'User';
      return 'Editing ' + name;
    }),

    isCurrentUser: Ember.computed('currentUser', 'user', function () {
      return this.get('currentUser.id') === this.get('user.id');
    }),

    disabled: Ember.computed('canManageUsers', 'isCurrentUser', function () {
      if (this.get('isCurrentUser')) return false;
      if (this.get('canManageUsers')) return false;
      return true;
    }),

    canSubmit: Ember.computed.bool('user.validations.isValid'),

    submitLabel: Ember.computed('user.isNew', function () {
      return this.get('user.isNew') ? 'Send Invitation' : 'Save';
    }),

    animation: Ember.computed('animationOut', function () {
      return this.get('animationOut') ? 'modal-slide-down' : 'modal-slide-up';
    }),

    notificationLevelDescription: Ember.computed('user.notificationLevel', function () {
      switch (this.get('user.notificationLevel')) {
        case "none":
          return "You'll NOT be notified when a data form is started or submitted.";

        case "all_data_forms":
          return "You'll be notified when a data form is submitted.";

        case "my_data_forms":
          return "You'll be notified when a data form is started or submitted and you are identified as the point of contact on the data form template.";

        case "all_notifications":
        default:
          return "You'll be notified when a data form is started or submitted.";
      }
    }),

    animationOut: false,

    focusFirst: Ember.observer('isHidden', function () {
      var _this = this;

      if (this.get('isHidden') === false && this.get('focus') !== false) {
        Ember.run.later(function () {
          return _this.$('input').first().focus();
        }, 100);
      }
    }),

    // clicking on the overlay will close the modal
    mouseUp: function mouseUp(el) {
      if (Ember.$(el.target).hasClass('modal-overlay') && !this.get('isWalkthrough')) {
        this.send('cancel');
      }
    },


    actions: {
      changeRole: function changeRole(opt) {
        this.set('user.role', opt.value);
      },
      cancel: function cancel() {
        // Clear out any notifications, which likely contain persistent form-errors
        if (this.get('cancelAction')) this.sendAction('cancelAction');
        this.send('slideOut');
      },


      // override this action in custom modals if more submit action is needed
      submit: function submit() {
        if (this.get('canSubmit')) this.sendAction();
        if (!this.get('isDestroyed')) this.send('slideOut');
      },
      checkErrors: function checkErrors() {
        // this catches attempts to submit the user-form modal when the user data is invalid
        var errors = this.get('userFormErrors');
        if (errors) {
          this.get('notify').error(errors);
        }
      },
      slideOut: function slideOut() {
        var _this2 = this;

        if (this.get('isDestroyed')) return;

        this.set('animationOut', true);
        Ember.run.later(function () {
          // there's a slight chance we closed the modal elsewhere or transitioned to another page
          // if so, we get an error if we try to call set on a destroyed object
          if (_this2.get('isDestroyed')) return;
          _this2.setProperties({ isHidden: true, animationOut: false });
        }, 200);
      }
    }
  });
});