define('dashboard/components/tag-list-card', ['exports', 'dashboard/components/customizable-list-card'], function (exports, _customizableListCard) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _customizableListCard.default.extend({
    router: Ember.inject.service(),
    store: Ember.inject.service(),

    tags: Ember.computed('allTags', 'category', function () {
      var _this = this;

      var allTags = this.get('allTags') || [];
      return allTags.filter(function (t) {
        return t.get('category') == _this.get('category');
      });
    }),

    tagManagementList: Ember.computed('tags', 'tags.[]', 'projects', function () {
      var allClientTags = this.get('tags');
      var projects = this.get('projects');
      var category = this.get('category');

      var output = allClientTags.map(function (tag) {
        return Ember.Object.create({
          tag: tag,
          projects: projects.filter(function (project) {
            if (category == 'tag') {
              return project.get('tags').mapBy('name').includes(tag.get('name'));
            } else {
              return project.get(category + '.name') == tag.get('name');
            }
          })
        });
      });

      return output.sortBy('tag.name');
    }),

    actions: {
      createNew: function createNew(newName) {
        var _this2 = this;

        var client = this.get('client');
        var tags = this.get('tags');
        var category = this.get('category');

        this.get('store').createRecord('tag', {
          name: newName,
          client: client,
          category: category
        }).save().then(function (tag) {
          _this2.set('isCreateModalHidden', true);
          _this2.set('newName', '');

          tags.pushObject(tag);
        });
      },
      openDeleteModal: function openDeleteModal(tag) {
        if (this.get('readOnlyAccess')) {
          return this.get('notify').alert('You currently have read only access.');
        }

        this.setProperties({
          toDelete: tag.get('tag'),
          toDeleteName: tag.get('tag.name'),
          projectsToUpdate: tag.get('projects'),
          isDeleteModalHidden: false
        });
      },
      delete: function _delete() {
        var _this3 = this;

        var tag = this.get('toDelete');
        var projectsToUpdate = this.get('projectsToUpdate') || [];
        var tags = this.get('tags') || [];

        if (!tag) {
          return;
        }

        tag.destroyRecord().then(function (tag) {
          projectsToUpdate.forEach(function (project) {
            project.get('tags').removeObject(tag);
            project.save();
          });
          tags.removeObject(tag);
          _this3.setProperties({
            isDeleteModalHidden: true,
            toDelete: null,
            toDeleteName: '',
            projectsToUpdate: []
          });
        });
      },
      transitionToFilteredProjects: function transitionToFilteredProjects(tag) {
        var clientId = this.get('client.id');
        var tagName = tag.get('tag.name');

        this.get('router').transitionTo('projects.all', clientId, { queryParams: { selectedTags: [tagName] } });
      }
    }
  });
});