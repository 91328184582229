define('dashboard/models/scenario-data-request', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    requestTemplate: (0, _relationships.belongsTo)(),
    scenario: (0, _relationships.belongsTo)('scenario'),
    project: (0, _relationships.belongsTo)('project'),
    client: (0, _relationships.belongsTo)('client'),
    contact: (0, _relationships.belongsTo)('user'),
    cc: (0, _relationships.hasMany)("user"),

    submitterEmail: (0, _attr.default)('string'),
    submittedOn: (0, _attr.default)('date'),
    note: (0, _attr.default)('string'),

    fromAnonymousLink: (0, _attr.default)('boolean'),
    token: (0, _attr.default)('string'),

    companyName: (0, _attr.default)('string'),
    confidentialIdentity: (0, _attr.default)('boolean'),
    companyAddress: (0, _attr.default)('string'),
    companyCity: (0, _attr.default)('string'),
    companyState: (0, _attr.default)('string'),
    companyZip: (0, _attr.default)('string'),
    companyWebsite: (0, _attr.default)('string'),
    yearEstablished: (0, _attr.default)('string'),
    ownershipType: (0, _attr.default)('string'),
    businessStructure: (0, _attr.default)('string'),
    parentCompanyName: (0, _attr.default)('string'),

    projectLocation: (0, _attr.default)('string'),
    background: (0, _attr.default)('string'),

    created: (0, _attr.default)('date'),
    lastUpdated: (0, _attr.default)('date')
  });
});