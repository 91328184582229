define('dashboard/controllers/scenario/settings/general', ['exports', 'lodash/lang', 'dashboard/utils/array-compare', 'dashboard/mixins/alias-properties'], function (exports, _lang2, _arrayCompare, _aliasProperties) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Controller.extend(_aliasProperties.default, {
    scenarioController: Ember.inject.controller('scenario'),
    notify: Ember.inject.service(),

    assumptionConfirmModalHidden: true,

    init: function init() {
      this.set("aliasedProperties", ['currentUser', 'unboundMarketValueFFE', 'startingCalendarYearDisabled', 'assumptionInputsDisabled', 'years', 'client', 'capitalInvestmentInputs', 'isSavable']);
      this.set('defaultMarketValueFFE', ['1.0', '0.9', '0.8', '0.7', '0.6', '0.5', '0.4', '0.3', '0.2', '0.2']);
      this._super.apply(this, arguments);
    },


    aliasedClass: 'scenarioController',

    actions: {
      enableAssumptionInputs: function enableAssumptionInputs() {
        var assumption = this.get('model.assumption.content');

        assumption.set('inputsDisabled', false);
        this.get('notify').info('You may now edit the default assumptions.');
        assumption.save();
      },
      assumptionConfirmModal: function assumptionConfirmModal() {
        this.set('assumptionConfirmModalHidden', false);
      },
      updateScenarioLength: function updateScenarioLength() {
        var assumption = this.get('model.assumption'),
            years = assumption.get('scenarioLength') || '10',
            output = assumption.get('percentMarketValueFFE'),
            currentLength = output.get('length'),
            defaultValues = this.get('client.assumption.percentMarketValueFFE'),
            defaultLength = defaultValues.get('length'),
            defaultMarketValueFFE = this.get('defaultMarketValueFFE'),
            before = output;

        var modifiedProperties = assumption.get('modifiedProperties');

        if (modifiedProperties['percentMarketValueFFE']) {
          before = modifiedProperties['percentMarketValueFFE'].toArray();
        }

        // fill out years when increasing number of inputs
        for (var i = currentLength; i < years; i++) {
          if (i < defaultLength) {
            output[i] = defaultValues[i];
            before[i] = defaultValues[i];
          } else {
            output[i] = defaultMarketValueFFE[i] ? defaultMarketValueFFE[i] : '0.2';
            before[i] = defaultMarketValueFFE[i] ? defaultMarketValueFFE[i] : '0.2';
          }
        }

        output = output.slice(0, years);
        before = before.slice(0, years);
        assumption.set('percentMarketValueFFE', output);

        // Add copy of original percentMarketValueFFE values to modifiedProperties to compare against later
        modifiedProperties['percentMarketValueFFE'] = before;

        var changedAttributes = assumption.get('content').changedAttributes();
        // DELETE IF years increasing BUT not increasing higher than default length
        if (years > currentLength && years > defaultLength) {
          // If property is deleted here, we will potentially remove percentMarketValueFFE
          // from modifiedProperties in updateAssumption action
          delete changedAttributes['percentMarketValueFFE'];
        }

        this.get('scenarioController').notifyPropertyChange('unboundMarketValueFFE');

        this.send('updateCapitalInvestmentInputs', currentLength);
        this.send('updateAssumption', changedAttributes);
      },
      updateCapitalInvestmentInputs: function updateCapitalInvestmentInputs(originalYears) {
        var _this = this;

        var years = this.get('years');
        var capitalInvestmentInputs = this.get('capitalInvestmentInputs');

        // if years is greater than it previously was
        if (parseInt(years) > parseInt(originalYears)) {
          // now adjust length of each cap investment input
          capitalInvestmentInputs.forEach(function (input) {
            var modelProp = _this.get('model.' + input.get('columnName'));
            for (var i = 0; i < years; i++) {
              modelProp[i] = modelProp[i] || 0;
            }
          });
        }
      },
      updateFFE: function updateFFE() {
        var unboundMarketValueFFE = this.get('unboundMarketValueFFE').map(function (item) {
          return item.get('val');
        }),
            assumption = this.get('model.assumption');

        // Probably convert ffe values to strings

        // This is necessary for when individual values are changed within marketvalueffe
        if (!(0, _arrayCompare.default)(unboundMarketValueFFE, assumption.get('percentMarketValueFFE'))) {
          assumption.set('percentMarketValueFFE', unboundMarketValueFFE);
        }

        this.send('updateAssumption');
      },
      updateAssumption: function updateAssumption(changed) {
        var assumption = this.get('model.assumption.content'),
            modifiedProperties = void 0,
            changedAttributes = changed || assumption.changedAttributes();

        modifiedProperties = assumption.get('modifiedProperties');

        for (var attr in changedAttributes) {
          if (!modifiedProperties.hasOwnProperty(attr)) {
            addValueToModifiedList(attr);
          } else {
            removeValueFromModifiedList(attr);
          }
        }

        this.set('model.usesModifiedAssumptions', !_lang2.default.isEqual(modifiedProperties, {}));
        this.get('scenarioController').send('save');

        if (!this.get('currentUser.isDemo') && this.get('isSavable')) {
          assumption.save();
        }

        // Save original attribute value
        function addValueToModifiedList(attr) {
          var initial = changedAttributes[attr][0],
              changedTo = changedAttributes[attr][1];

          switch (typeof changedTo === 'undefined' ? 'undefined' : _typeof(changedTo)) {
            case 'object':
              break;
            case 'number':
              initial = parseFloat(initial);
              break;
            case 'string':
              initial = initial ? initial.toString().trim() : '';
              changedTo = changedTo.trim();
              break;
          }

          if (initial instanceof Array && !(0, _arrayCompare.default)(initial, changedTo) || // Check if it is an array and if arrays are not equal
          !(initial instanceof Array) && initial !== changedTo // If it's not an array, compare converted values
          ) {
              modifiedProperties[attr] = initial;
            }
        }

        // remove values matching original values
        function removeValueFromModifiedList(attr) {
          var modifiedAttr = modifiedProperties[attr];
          var changedAttr = changedAttributes[attr][1];

          var sameArray = Array.isArray(changedAttr) && (0, _arrayCompare.default)(modifiedAttr, changedAttr);
          var sameString = modifiedAttr === changedAttr.toString();
          var sameFloat = parseFloat(modifiedAttr) === parseFloat(changedAttr);
          var emptyString = changedAttr.toString() === '' && !modifiedAttr;

          if (sameArray) {
            delete modifiedProperties[attr];
          } else if ((typeof changedAttr === 'undefined' ? 'undefined' : _typeof(changedAttr)) !== 'object' && (modifiedAttr === changedAttr || sameString || sameFloat || emptyString)) {
            delete modifiedProperties[attr];
          }
        }
      },
      confirmResetAssumptions: function confirmResetAssumptions() {
        var _this2 = this;

        var defaultAssumption = this.get('client.assumption.content'),
            assumption = this.get('model.assumption.content'),
            assumptionProperties = assumption.toJSON(),
            defaultStartingYear = this.get('model.created').getFullYear(),
            years = void 0;

        // @TODO Add an extra condition here to set scenarioLength to 10 if empty
        for (var prop in assumptionProperties) {
          var defaultAssumptionValue = defaultAssumption.get(prop);
          var hasDefaultValue = typeof defaultAssumptionValue !== "undefined";

          if (hasDefaultValue && assumption.get(prop) !== defaultAssumptionValue) {
            assumption.set(prop, defaultAssumptionValue);
            if (prop === 'scenarioLength') {
              years = parseInt(defaultAssumptionValue);
              this.set('years', years);
            }
          }
        }

        assumption.set('startingYear', defaultStartingYear);

        assumption.set('modifiedProperties', {});
        assumption.set('inputsDisabled', true);
        assumption.save().then(function () {
          _this2.set('model.usesModifiedAssumptions', false);
          _this2.set('assumptionConfirmModalHidden', true);
          _this2.get('scenarioController').notifyPropertyChange('unboundMarketValueFFE');
          _this2.get('scenarioController').send('save');
          _this2.get('notify').info('Assumptions successfully reset.');
        }).catch(function () {
          _this2.get('notify').alert('There was a problem resetting your assumptions.');
        });
      }
    }
  });
});