define('dashboard/models/dependent-district-group-link', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    client: (0, _relationships.belongsTo)('client', { inverse: null }),
    dependentTaxDistrictSet: (0, _relationships.belongsTo)('dependentTaxDistrictSet', { inverse: null }),
    districtGroups: (0, _relationships.hasMany)('districtGroup', { inverse: null }),

    matchingText: (0, _attr.default)('string', { defaultValue: '' }),
    lastUpdated: (0, _attr.default)('date'),
    created: (0, _attr.default)('date')
  });
});