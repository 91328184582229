define('dashboard/components/checkboxes-with-other', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    otherDisplayValue: Ember.computed('selectedOther', function () {
      var other = this.get('selectedOther');
      return other && other.slice(7, other.length).trim();
    }),

    selectedOther: Ember.computed('selected.[]', function () {
      var selected = this.get('selected');
      if (!selected) {
        return false;
      }

      return selected.compact().find(function (option) {
        return option.slice(0, 6) === 'Other:';
      });
    }),

    otherIsSelected: Ember.computed.bool('selectedOther'),

    trimAndSave: function trimAndSave(selected) {
      var propertyPath = this.get('propertyPath');
      var scenario = this.get('scenario'); //this hardcoding makes it less flexible, but is required for proper updates, and it's only used in scenario-input-questions so far
      scenario.set(propertyPath, selected.compact());
      this.save();
    },


    actions: {
      toggleCheckbox: function toggleCheckbox(selected, option) {
        selected = selected || [];
        if (selected.includes(option)) {
          selected.removeObject(option);
        } else {
          selected.addObject(option);
        }
        this.trimAndSave(selected);
      },
      toggleOther: function toggleOther(selected) {
        if (!this.get('otherIsSelected')) {
          selected.removeObject(this.get('selectedOther'));
        } else {
          selected.addObject('Other:');
        }
        this.trimAndSave(selected);
      },
      updateOther: function updateOther(selected, otherValue) {
        selected.removeObject(this.get('selectedOther'));
        selected.addObject('Other: ' + otherValue);
        this.trimAndSave(selected);
      }
    }
  });
});