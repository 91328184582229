define('dashboard/models/file-upload', ['exports', 'ember-data', 'ember-data/attr'], function (exports, _emberData, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    fileName: (0, _attr.default)('string'), //how it's looked up on s3
    displayName: (0, _attr.default)('string'),
    size: (0, _attr.default)('number'),
    fileType: (0, _attr.default)('string'),
    url: (0, _attr.default)('string'),
    polymorphicId: (0, _attr.default)('string'),
    polymorphicType: (0, _attr.default)('string'),
    created: (0, _attr.default)('date'),
    lastUpdated: (0, _attr.default)('date')
  });
});