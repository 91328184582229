define('dashboard/components/scenario-mini-report', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    scenarioGraphView: true,

    selectedEngine: Ember.computed('primaryEngine', function () {
      return this.get('primaryEngine');
    }),

    // @TODO: this is firing when switchin from current -> expansion -> total operations because engines changes
    recoverAfterRemovalOfSelectedEngine: Ember.observer('engines.[]', function () {
      var engines = this.get('engines'),
          selectedEngine = this.get('selectedEngine');

      if (!engines.includes(selectedEngine)) {
        this.set('selectedEngine', engines.get('firstObject'));
      }
    }),

    actions: {
      selectEngine: function selectEngine(engine) {
        this.set('selectedEngine', engine);
      }
    }
  });
});