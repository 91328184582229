define('dashboard/components/buttons/request-scenario-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['inline-block', 'text-left'],

    isCreateShown: Ember.computed.not('project.scenario.id'),

    isPendingShown: Ember.computed('project.scenario', 'project.scenario.submittedOn', function () {
      var scenario = this.get('project.scenario');
      return scenario && !scenario.get('submittedOn');
    }),

    isDoneShown: Ember.computed.bool('project.scenario.submittedOn'),

    actions: {
      createRequestHandler: function createRequestHandler() {
        this.sendAction('createRequest', this.get('project'));
      },
      removeRequestHandler: function removeRequestHandler(isPending) {
        this.sendAction('removeRequest', this.get('project'), isPending);
      },
      resendRequestHandler: function resendRequestHandler() {
        this.sendAction('resendRequest', this.get('project'));
      },
      copyRequestHandler: function copyRequestHandler() {
        var _this = this;

        this.get('project.scenario').then(function (scenario) {
          _this.sendAction('copyRequest', scenario);
        });
      }
    }
  });
});