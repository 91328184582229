define('dashboard/routes/scenario-emailed', ['exports', 'dashboard/mixins/reset-scroll-position'], function (exports, _resetScrollPosition) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_resetScrollPosition.default, {

    title: 'Request | Impact Dashboard',

    controllerName: 'scenario',

    ajax: Ember.inject.service(),
    adapterErrorNotifier: Ember.inject.service(),

    model: function model(params) {
      var _this = this;

      var models = {};

      return new Ember.RSVP.Promise(function (resolve, reject) {

        _this.get('ajax').request('scenarioDataRequests/token/' + params.token_id, {
          contentType: 'application/json; charset=utf-8',
          dataType: 'json'
        }).then(function (data) {
          _this.store.pushPayload(data);
        }).then(function () {
          var scenario = _this.store.peekAll('scenario').get('firstObject');
          models.scenario = scenario;
          models.scenarioDataRequest = _this.store.peekAll('scenarioDataRequest').get('firstObject');
          models.directCostRevenues = _this.store.findAll('directCostRevenue');

          var scenarioId = scenario.get('id');
          models.project = scenario.get('project');
          models.multiplier = _this.store.query('multiplier', { scenario: scenarioId });

          var clientId = models.project.get('client.id');
          var client = _this.store.findRecord('client', clientId);

          // Get first district in first district area as promise to be resolve in RSVP.all
          var districtAreasPromise = _this.store.query('districtArea', { client: clientId });
          var firstDistrictAreaDistrict = districtAreasPromise.then(function (districtAreas) {
            if (districtAreas.get('length')) {
              return _this.store.findRecord('taxDistrict', districtAreas.get('firstObject.map.firstObject.district'));
            }
            return [];
          });

          // Get multiplier as promise to be resolve in RSVP.all
          models.multiplierSets = _this.store.query('multiplierSet', { scenario: scenarioId });
          var multipliers = new Ember.RSVP.Promise(function (resolveMultipliers) {
            var multiplierPromises = [];

            models.multiplierSets.then(function (multiplierSets) {

              multiplierPromises = multiplierSets.map(function (set) {
                return _this.store.query('multiplier', { multiplierSet: set.get('id') });
              });

              Ember.RSVP.all(multiplierPromises).then(function (multipliersArray) {
                multipliersArray = multipliersArray.map(function (multipliers) {
                  return multipliers.toArray();
                });
                resolveMultipliers(multipliersArray);
              });
            });
          });

          return Ember.RSVP.all([client, firstDistrictAreaDistrict, multipliers]).then(function (results) {
            models.client = results[0];
            models.firstDistrictAreaDistrict = results[1];
            models.multipliers = results[2];
            resolve(models);
          });
        }, function (err) {
          console.log('rejected model promise');
          reject(err);
        });
      });
    },
    setupController: function setupController(controller, models) {
      var allMultipliers = [];
      models.multipliers.forEach(function (set) {
        allMultipliers.pushObjects(set);
      });

      controller.setProperties({
        project: models.project,
        client: models.client,
        scenarioMultiplier: models.multiplier.get('firstObject'),
        multipliers: allMultipliers,
        multiplierSets: models.multiplierSets,
        scenarioDataRequest: models.scenarioDataRequest,
        firstDistrictAreaDistrict: models.firstDistrictAreaDistrict
      });

      this._super(controller, models.scenario);

      if (models.scenario.get('isRequest')) {
        controller.showRequestSections();
      }

      controller.send('updateUnboundInputs');
    },


    //if all district groups has no districts selected on them then assign the first district for each district group
    afterModel: function afterModel(models) {

      var scenario = models.scenario;

      var districts = scenario.get('districts'),
          districtGroups = models.client.get('districtGroups');

      var districtsLength = districts.get('length'),
          districtGroupsLength = districtGroups.get('length');

      var clientDistrictConfig = models.client.get('districtConfigType'),
          clientUsesStandardConfig = clientDistrictConfig === 'standard' || !clientDistrictConfig;

      if (districtsLength > 0 || districtGroupsLength === 0 || !clientUsesStandardConfig) {
        return;
      }

      districtGroups.forEach(function (districtGroup) {
        var districtToAdd = districtGroup.get('filteredDistricts').sortBy('name').get('firstObject');
        if (districtToAdd) {
          districtToAdd.set('parentGroup', districtGroup);
          districts.pushObject(districtToAdd);
        }
      });

      scenario.set('summarySelectedDistrict', districts.get('firstObject.id'));

      return scenario.save();
    },


    actions: {
      error: function error(err) {
        console.log('scenario-emailed.error ERROR ', err);
        this.transitionTo('scenario-emailed.error');
      },
      confirmSubmitEmailScenario: function confirmSubmitEmailScenario() {
        this.controller.set('scenarioRequestFormConfirmHidden', false);
      },
      submitEmailScenario: function submitEmailScenario() {
        var _this2 = this;

        var scenarioRequest = this.controller.get('scenarioDataRequest');

        // Add save action here!
        this.controller.get('model').save().then(function () {
          scenarioRequest.set('submittedOn', new Date());
          scenarioRequest.save().then(function () {
            _this2.transitionTo('scenario-emailed.success');
          }, _this2.get('adapterErrorNotifier').createErrorsAlerter());
        });
      },
      willTransition: function willTransition() {
        window.scrollTop = 0;
      }
    }

  });
});