define('dashboard/services/adapter-error-notifier', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    notify: Ember.inject.service(),

    createErrorsAlerter: function createErrorsAlerter() {
      var _this = this;

      return function (adapterError) {
        return _this.alertAllErrors(adapterError);
      };
    },
    alertAllErrors: function alertAllErrors(adapterError) {
      var _this2 = this;

      adapterError.errors.forEach(function (error) {
        _this2.get('notify').alert(error, {
          closeAfter: 4000
        });
      });
    }
  });
});