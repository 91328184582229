define('dashboard/services/session', ['exports', 'ember-simple-auth/services/session'], function (exports, _session) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _session.default.extend({
    store: Ember.inject.service(),

    currentUser: Ember.computed('data.authenticated.token', function () {
      var token = this.get('data.authenticated.token');
      return this.get('store').queryRecord('user', { token: token });
    })
  });
});