define("dashboard/utils/stringify", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  /**
   * Stringify
   *
   * @param  {Number or Array} number or array of numbers
   * @return {Number or Array}            Original number or array as string
   *
   * @method  stringify
   */
  var stringify = function stringify(values) {
    return values instanceof Array ? values.map(function (value) {
      return value.toString();
    }) : values.toString();
  };

  exports.default = stringify;
});