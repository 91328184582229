define('dashboard/routes/clients', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    notify: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),

    actions: {
      activateClient: function activateClient(client) {
        var _this = this;

        this.get('ajax').post('/clients/' + client.get('id') + '/activate').then(function (_ref) {
          var userId = _ref.userId,
              url = _ref.url;
          return _this.controller.setProperties({
            isActivateClientModalHidden: false,
            activationUser: _this.get('store').peekRecord('user', userId),
            activationUrl: url
          });
        }).then(function () {
          return client.reload();
        }).catch(function () {
          return _this.get('notify').alert('An error occurred while trying to activate this client');
        });
      },
      cloneClient: function cloneClient(oldClient) {
        var self = this,
            notify = this.get('notify'),
            store = this.get('store'),
            assumption = void 0,
            newClient = void 0;

        function getAssumption(oldClient) {
          return oldClient.get('assumption');
        }

        function copyAssumption(oldAssumption) {
          var oldAssumptionProperties = oldAssumption.toJSON();
          var newAssumption = store.createRecord('defaultAssumption', oldAssumptionProperties);

          return newAssumption.save();
        }

        function getDistrictGroups(newAssumption) {
          assumption = newAssumption;
          return oldClient.get('districtGroups');
        }

        function copyDistrictGroups(oldDistrictGroups) {
          var newDistrictGroups;

          newDistrictGroups = oldDistrictGroups.map(function (oldDistrictGroup) {
            var districtGroupToCreate = oldDistrictGroup.toJSON();

            delete districtGroupToCreate.districts;
            delete districtGroupToCreate.previousDistricts;

            var newDistrictGroup = store.createRecord('districtGroup', districtGroupToCreate);

            newDistrictGroup.get('districts').pushObjects(oldDistrictGroup.get('districts'));
            newDistrictGroup.get('previousDistricts').pushObjects(oldDistrictGroup.get('previousDistricts'));

            return newDistrictGroup.save();
          });

          return Ember.RSVP.all(newDistrictGroups);
        }

        function copyClient(newDistrictGroups) {
          newClient = store.createRecord('client', {
            name: 'Copy of ' + oldClient.get('name'),
            districtConfigType: oldClient.get('districtConfigType'),
            assumption: assumption
          });

          newClient.get('districtGroups').pushObjects(newDistrictGroups);
          return newClient.save();
        }

        function updateAssumption(client) {
          assumption.set('client', client.get('id'));
          assumption.save();
        }

        function createNewSubscription() {
          var newSubscription = store.createRecord('subscription', { client: newClient });
          return newSubscription.save();
        }

        function transitionToClient() {
          self.transitionTo('client', newClient.get('id')).then(function () {
            notify.info('Client copied successfully');
            oldClient.set('cloningInProgress', false);
          });
        }

        function error(err) {
          console.log('err ', err);
          oldClient.set('cloningInProgress', false);
          notify.alert('Something went wrong when cloning this client');
        }

        oldClient.set('cloningInProgress', true);

        getAssumption(oldClient).then(copyAssumption).then(getDistrictGroups).then(copyDistrictGroups).then(copyClient).then(updateAssumption).then(createNewSubscription).then(transitionToClient).catch(error);
      }
    }
  });
});