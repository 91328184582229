define('dashboard/components/graphs/abatements-graph-summary', ['exports', 'dashboard/components/graphs/abatements-graph'], function (exports, _abatementsGraph) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _abatementsGraph.default.extend({

    numTicks: 1,

    drawAxis: function drawAxis() {

      var yScale = this.get('yScale'),
          tickPadding = this.get('tickPadding'),
          numTicks = this.get('numTicks'),
          xMax = this.get('xMax'),
          canvasMarginLeft = this.get('canvasMarginLeft'),
          canvasMarginTop = this.get('canvasMarginTop'),
          elId = this.get('elementId'),
          yAxisEl = d3.select('.' + elId + '.axis.y'),
          yAxis = d3.axisLeft().scale(yScale).ticks(numTicks).tickSize(-xMax, 0).tickPadding(tickPadding).tickFormat(function (d) {
        return numeral(d).format('($000.0a)');
      }),
          svg = this.get('svg');

      if (yAxisEl.empty()) {
        svg.insert("g", ":nth-child(2)").attr('class', 'y axis ' + elId).attr('transform', 'translate(' + canvasMarginLeft + ',' + canvasMarginTop + ')').call(yAxis);
      } else {
        yAxisEl.transition().duration(1000).attr('transform', 'translate(' + canvasMarginLeft + ',' + canvasMarginTop + ')').call(yAxis);
      }
    },
    drawChart: function drawChart() {

      var data = this.get('data'),
          sumData = this.get('sumData'),
          x = this.get('x'),
          yScale = this.get('yScale'),
          canvasMarginTop = this.get('canvasMarginTop'),
          canvasMarginLeft = this.get('canvasMarginLeft'),
          zeroOffset = yScale(0) + canvasMarginTop,
          y = function y(d) {
        return yScale(d.value) + canvasMarginTop;
      },
          h = function h(d) {
        return d.value < 0 ? yScale(d.value) - zeroOffset : zeroOffset - yScale(d.value);
      },
          svg = this.get('svg'),
          line = d3.line().x(function (d) {
        return canvasMarginLeft + x(d.year) + x.bandwidth() / 2;
      }).y(function (d) {
        return y(d);
      }),
          bars = svg.selectAll('.abatements-bar').data(data),
          path = svg.selectAll('.line').data([data]),
          yearsToolTip = this.get('yearsToolTip'),
          years = this.get('years'),
          incentivePhrase = this.get('incentivePhrase');

      // Bars Enter
      bars.enter().append('rect').attr('class', function (d) {
        var classes = d.value < 0 ? 'abatements-bar abated' : 'abatements-bar liability';
        return classes + ' ' + d.type;
      }).attr('title', function (d) {
        var type = d.value < 0 ? incentivePhrase : 'Taxes';
        return '' + yearsToolTip + d.year + '<br />' + type + ': ' + numeral(d.value).format('($0a)');
      }).attr('x', function (d) {
        return canvasMarginLeft + x(d.year);
      }).attr('width', x.bandwidth()).attr('y', function (d) {
        return d.value < 0 ? zeroOffset + canvasMarginTop : y(d);
      }).attr('height', h);

      // Bars Change
      bars.transition().duration(1000).attr('y', function (d) {
        return d.value < 0 ? zeroOffset + canvasMarginTop : y(d);
      }).attr('height', h);

      if (years > 1) {
        // Path Enter
        path.enter().append('path').attr('class', 'line').attr('d', line(sumData));

        // Path Change
        path.transition().duration(1000).attr('d', line(sumData));
      }

      this.$('rect.abatements-bar').tipsy({
        gravity: 's',
        html: true,
        title: function title() {
          var d = this.__data__,
              type = d.value < 0 ? incentivePhrase : 'Taxes',
              value;
          value = d.value;
          if (d.value >= 1000000 || d.value <= -1000000) {
            return '' + yearsToolTip + d.year + '<br />' + type + ': ' + numeral(value).format('($0.00a)');
          }
          return '' + yearsToolTip + d.year + '<br />' + type + ': ' + numeral(value).format('($0,0)');
        },
        opacity: 1
      });

      this.$('circle').tipsy({
        gravity: 's',
        html: true,
        title: function title() {
          var d = this.__data__;
          if (d.value >= 1000000 || d.value <= -1000000) {
            return '' + yearsToolTip + d.year + '<br />Net: ' + numeral(d.value).format('($0.00a)');
          }
          return '' + yearsToolTip + d.year + '<br />Net: ' + numeral(d.value).format('($0,0)');
        },
        opacity: 1
      });
    }
  });
});