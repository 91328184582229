define('dashboard/objects/fi-tax/ffe-property-taxes', ['exports', 'dashboard/objects/fi-tax/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    ffePropertyTaxes: Ember.computed('ffeValues', 'ffeTaxRate', 'ffeTaxRateApplied', 'doesApplyTax', function () {
      var values = this.get('ffeValues'),
          taxRate = this.get('ffeTaxRate'),
          taxRateApplied = this.get('ffeTaxRateApplied') || 100,
          doesApply = this.get('doesApplyTax');

      return values.map(function (value, index) {
        var rate = Ember.isArray(taxRate) ? parseFloat(taxRate[index]) : parseFloat(taxRate);
        return doesApply ? parseFloat(value) * rate / parseFloat(taxRateApplied) : 0;
      });
    }),

    projectTaxes: Ember.computed.sum('ffePropertyTaxes')
  });
});