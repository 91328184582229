define('dashboard/utils/get-aggregate-engine-prop', ['exports', 'dashboard/utils/sum-array', 'dashboard/utils/sum-many-arrays'], function (exports, _sumArray, _sumManyArrays) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getAggregateArrayProperty = exports.getAggregateProperty = undefined;


  /**
   * Get Aggregate Property
   *
   * Takes array of engines and single impact-engine property and returns sum of individual property across all engines
   *
   * @param  {Array} engine Array of impact-engine objects
   * @param  {String} prop Name of property in engine
   * @return {Number} Sum of single property across passed engines
   *
   * @method  getAggregateProperty
   */
  var getAggregateProperty = function getAggregateProperty(engines, prop) {
    return engines.reduce(function (total, engine) {
      return total + engine.get(prop);
    }, 0);
  };

  /**
   * Get Aggregate Property
   *
   * Takes array of engines and single impact-engine property of type array and returns an object with a summed array and a total
   *
   * @param  {Array} engine Array of impact-engine objects
   * @param  {String} prop Name of property in engine
   * @param  {Object} options Right now just option to return object with annual and total properties
   * @return {Object} object with annual and total properties representing sum of passed property across all engines
   *
   * @method  getAggregateArrayProperty
   */
  var getAggregateArrayProperty = function getAggregateArrayProperty(engines, prop) {
    var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : { annual: false };

    prop = options.annual ? prop + '.annual' : prop;
    var annual = (0, _sumManyArrays.default)(engines.mapBy(prop) || []);
    var total = (0, _sumArray.default)(annual);

    if (options.annual) {
      return Ember.Object.create({ annual: annual, total: total });
    }

    return annual;
  };

  exports.getAggregateProperty = getAggregateProperty;
  exports.getAggregateArrayProperty = getAggregateArrayProperty;
  exports.default = getAggregateProperty;
});