define('dashboard/components/tif-incentives', ['exports', 'dashboard/objects/column', 'dashboard/utils/make-inputs', 'dashboard/utils/array-of-length', 'dashboard/components/district-incentives'], function (exports, _column, _makeInputs, _arrayOfLength, _districtIncentives) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _districtIncentives.default.extend({

    incentiveEngine: Ember.computed.alias('tifIncentives'),
    tifDuration: Ember.computed.alias('tifIncentives.tifDuration'),

    incentiveSelectOptions: Ember.computed('perJobLabel', function () {
      return [Ember.Object.create({
        val: 'amount',
        text: 'Incentive Amount'
      }), Ember.Object.create({
        val: 'perJob',
        text: this.get('perJobLabel')
      }), Ember.Object.create({
        val: 'rateOfReturn',
        text: 'Rate of Return'
      }), Ember.Object.create({
        val: 'paybackPeriod',
        text: 'Payback Period (years)'
      }), Ember.Object.create({
        val: 'percentOfTif',
        text: 'Percent of TIF Revenue'
      })];
    }),

    // No incentives for current engine which is used to calculate Totals for scenarios w/ existing business
    // so we just return an empty array the same length as the scenario length
    incentivesToUse: Ember.computed('currentVsExpansion', 'schedule.incentives', 'years', function () {
      if (this.get('currentVsExpansion') === 'current') {
        return (0, _arrayOfLength.default)(this.get('years'));
      }
      return this.get('schedule.incentives');
    }),

    // Incentive inputs to enter yearly incentives by raw amount
    incentiveInputs: Ember.computed('incentivesToUse', 'tifDuration', 'years', function () {
      var incentives = this.get('incentivesToUse'),
          years = parseInt(this.get('tifDuration')) || this.get('years'),
          incentivesColumn = void 0;

      incentivesColumn = _column.default.create({
        title: 'Incentives Per Year',
        columnName: 'incentives',
        inputType: 'dollars',
        content: (0, _makeInputs.default)(incentives, years)
      });

      return [incentivesColumn];
    }),

    percentOfTifToUse: Ember.computed('currentVsExpansion', 'incentiveEngine.{percentOfTif,incentives}', 'years', function () {
      if (this.get('currentVsExpansion') === 'current') {
        return (0, _arrayOfLength.default)(this.get('years'));
      }
      return this.get('incentiveEngine.percentOfTif');
    }),

    // create column of percent inputs that will be used to calculate incentive based on percent of 
    // total tif contributions each year
    percentOfTifInputs: Ember.computed('percentOfTifToUse', 'tifDuration', 'years', function () {
      var percents = this.get('percentOfTifToUse'),
          years = parseInt(this.get('tifDuration')) || this.get('years'),
          percentColumn = void 0;

      percentColumn = _column.default.create({
        title: 'Percent',
        columnName: 'percentOfTif',
        inputType: 'percent',
        content: (0, _makeInputs.default)(percents, years)
      });

      return [percentColumn];
    }),

    unboundPercentOfTifInputs: Ember.computed('schedule.id', 'incentiveInputMethod', 'years', 'incentivesUpdated', 'engine.name', 'currentVsExpansion', function () {
      return this.get('percentOfTifInputs');
    }),

    // duplicated from scenario controller
    tifIncentivesGraphData: Ember.computed('schedule.incentives', 'years', function () {
      var incentives = this.get('schedule.incentives') || [];
      incentives = incentives.slice(0, this.get('years'));
      return incentives.map(function (value, index) {
        return {
          year: index + 1,
          value: parseFloat(value) || 0
        };
      });
    }),

    actions: {
      updatePercentOfTif: function updatePercentOfTif(columns) {
        var percentArray = columns[0].get('cellValues');
        this.set('incentiveEngine.percentOfTif', percentArray);
        this.sendAction('updateTifIncentivesHandler', percentArray);
      }
    }

  });
});