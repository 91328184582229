define('dashboard/routes/user/tifs', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    controllerName: 'user',

    model: function model() {
      var user = this.modelFor('authenticated');
      var client = user.get('client');

      return Ember.RSVP.hash({
        user: user,
        client: client,
        tifDistricts: this.store.query('tifDistrict', { client: Ember.get(client, 'id') }).then(function (tifs) {
          return tifs.toArray();
        })
      });
    }
  });
});