define('dashboard/routes/data-forms/request-templates/request-template', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    titleToken: function titleToken(model) {
      return model.get('title') + ' | Templates';
    },
    model: function model(params) {
      return this.store.findRecord('request-template', params.template_id);
    },
    afterModel: function afterModel(model) {
      /**
       * Force redirect if user attempts to access a data-request
       * template they should not have access to.
       */
      if (!this.userCanAccessRequestTemplate(model)) {
        this.transitionTo('request-templates', this.modelFor('authenticated').get('client.id'));
      }

      // automatically set contact (user model) if there isn't one
      if (!model.contact) {
        var firstUser = model.client.users[0];
        model.contact = firstUser;
      }
    },
    userCanAccessRequestTemplate: function userCanAccessRequestTemplate(requestTemplate) {
      var user = this.modelFor('authenticated');
      if (user.get('isSuperAdmin')) return true;

      var requestTemplateClientId = requestTemplate.get('client.id');
      var userClientId = user.get('client.id');
      return requestTemplateClientId === userClientId;
    }
  });
});