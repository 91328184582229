define('dashboard/components/sort-arrow', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    alwaysShowActive: false,

    isAsc: Ember.computed('sortDir', function () {
      var sortDir = this.get('sortDir');
      return sortDir === 'asc' || !sortDir;
    }),

    isActive: Ember.computed('sortField', 'sortFieldName', function () {
      var sortField = this.get('sortField'),
          sortFieldName = this.get('sortFieldName');
      return sortField === sortFieldName;
    }),

    actions: {
      toggle: function toggle() {
        var sortFieldName = this.get('sortFieldName'),
            sortField = this.get('sortField'),
            sortDir = this.get('sortDir') === 'asc' ? 'desc' : 'asc';

        //If i am clicking on a new column to sort, reset dir to asc
        if (sortFieldName !== sortField) {
          sortDir = 'asc';
        }
        this.sendAction('action', sortFieldName, sortDir);
      }
    }
  });
});