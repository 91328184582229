define('dashboard/routes/layout', ['exports', 'lodash', 'ember-local-storage'], function (exports, _lodash, _emberLocalStorage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    sessionData: (0, _emberLocalStorage.storageFor)('session-data'),

    actions: {

      // gets rid of header and sidebar when in scenario page
      didTransition: function didTransition() {
        var scenarioRoute = false;
        if (_lodash.default.includes(this.get('routeName'), 'scenario')) {
          scenarioRoute = true;
        }
        this.set('sessionData.scenarioRoute', scenarioRoute);
      }
    }

  });
});