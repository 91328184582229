define('dashboard/objects/fi-tax/real-property-taxes', ['exports', 'dashboard/objects/fi-tax/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    propertyTaxesLand: Ember.computed('assessedValueLand', 'taxRateLand', 'taxRateLandApplied', function () {
      var assessedValueLand = this.get('assessedValueLand'),
          taxRateLand = this.get('taxRateLand'),
          taxRateLandApplied = this.get('taxRateLandApplied');

      return assessedValueLand.map(function (value, index) {
        var rate = Ember.isArray(taxRateLand) ? taxRateLand[index] : taxRateLand;
        return value * rate / taxRateLandApplied;
      });
    }),

    propertyTaxesBuildingsAndImprovements: Ember.computed('assessedValueBuildings', 'taxRateBuildingsAndImprovements', 'taxRateBuildingsAndImprovementsApplied', function () {
      var assessedValueBuildings = this.get('assessedValueBuildings'),
          taxRateBuildings = this.get('taxRateBuildingsAndImprovements'),
          taxRateBuildingsAndImprovementsApplied = this.get('taxRateBuildingsAndImprovementsApplied');

      return assessedValueBuildings.map(function (value, index) {
        var rate = Ember.isArray(taxRateBuildings) ? taxRateBuildings[index] : taxRateBuildings;
        return value * rate / taxRateBuildingsAndImprovementsApplied;
      });
    })
  });
});