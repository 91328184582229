define('dashboard/components/modals/share-data-request-modal', ['exports', 'dashboard/components/modals/modal-confirm', 'dashboard/utils/copy-assumption', 'dashboard/utils/copy-to-clipboard', 'dashboard/utils/copy-data-request', 'dashboard/mixins/read-only-restrictions', 'dashboard/mixins/current-user-mixin'], function (exports, _modalConfirm, _copyAssumption, _copyToClipboard, _copyDataRequest, _readOnlyRestrictions, _currentUserMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _modalConfirm.default.extend(_readOnlyRestrictions.default, _currentUserMixin.default, {
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    notify: Ember.inject.service(),
    tabShown: 'email',
    copied: false,
    recipientEmail: '',
    contact: null,
    requestTemplate: null, //required param
    scenario: null, //optional param.  Including it will set the link and email send to go to that specific scenario.

    client: Ember.computed.alias('requestTemplate.client'),
    users: Ember.computed.alias('client.activeUsers'),
    enableButton: Ember.computed.and('recipientEmail', 'message'),

    init: function init() {
      this._super.apply(this, arguments);
      this.setDefaults();
    },
    cleanupModal: function cleanupModal() {
      if (!this.get('scenario')) {
        this.set('recipientEmail', '');
      }
      this.setDefaults();
    },
    setDefaults: function setDefaults() {
      this.setRecipientEmail();
      var templateContact = this.get('requestTemplate.contact.content');
      var contact = templateContact ? templateContact : this.get('currentUserNotSuperAdmin');
      this.set('contact', contact);
      this.set("ccUsers", []);
      this.set("selectedCCUsers", [contact]);
    },
    setRecipientEmail: function setRecipientEmail() {
      var scenario = this.get('scenario');
      if (!scenario) {
        return;
      }
      var recipientEmail = scenario.get('contactEmail') || scenario.get('prospectEmail') || scenario.get('submitterEmail');
      this.set('recipientEmail', recipientEmail);
    },


    modalTitle: Ember.computed('revising', function () {
      return this.get('revising') ? 'Request Revisions via' : 'Share via';
    }),

    subject: Ember.computed('revising', 'client.name', function () {
      if (this.get('revising')) {
        return 'Revise your Data Form from ' + this.get('client.name');
      }
      return 'Complete your Data Request from ' + this.get('client.name');
    }),

    message: Ember.computed('revising', function () {
      if (this.get('revising')) {
        return 'Greetings!\nYou may make revisions to your submitted data form by clicking the button below.';
      }
      return 'Greetings!\nPlease provide information on your project using the data form below and contact me if you have any questions.';
    }),

    copyableText: Ember.computed('revising', 'scenario.dataRequestLink', 'requestTemplate.anonymousLink', function () {
      var link = this.get('scenario.dataRequestLink') || this.get('requestTemplate.anonymousLink');
      return this.get('revising') ? link + '?revising=true' : link;
    }),

    linkDescription: Ember.computed('revising', 'scenario.dataRequestLink', function () {
      var newDescription = 'This URL creates a fresh iteration of the Data Form each time it\'s accessed. It\'s ideal for posting on a website to allow prospects to start a new Data Form submission.';
      var inProgressDescription = 'This URL provides access to the prospect\'s Data Form currently in progress. Once the prospect submits the Data Form, it will appear in your Inbox.';
      var revisionDescription = 'This URL allows the prospect to revise inputs on the submitted Data Form without altering the original. Once the prospect submits the revised Data Form, it will appear in your Inbox.';

      if (this.get('revising')) {
        return revisionDescription;
      } else if (this.get('scenario.dataRequestLink')) {
        return inProgressDescription;
      }

      return newDescription;
    }),

    // This never gets called? Is signature still used?
    setSignature: function setSignature() {
      var client = this.get('client');
      if (!client) {
        return;
      }

      var _client$getProperties = client.getProperties('name', 'firstName', 'lastName', 'phone', 'user'),
          name = _client$getProperties.name,
          firstName = _client$getProperties.firstName,
          lastName = _client$getProperties.lastName,
          phone = _client$getProperties.phone,
          user = _client$getProperties.user;

      var sigArray = [];

      if (firstName || lastName) {
        sigArray.addObject(firstName + ' ' + lastName);
      }
      if (name) {
        sigArray.addObject(name);
      }
      if (phone) {
        sigArray.addObject(phone);
      }
      if (user && user.get('email')) {
        sigArray.addObject(user.get('email'));
      }

      this.set('signature', sigArray.join('\n'));
    },


    clientLoads: Ember.observer('client.name', function () {
      this.setDefaults();
    }),

    createAndSendNewDataRequest: function createAndSendNewDataRequest() {
      var _this = this;

      var store = this.get('store');
      var client = this.get('client');
      client.get('assumption').then(function (assumption) {
        return (0, _copyAssumption.default)(assumption, store);
      }).then(function (newAssumption) {
        var newScenario = store.createRecord('scenario', {
          client: client,
          cc: _this.get('selectedCCUsers'),
          assumption: newAssumption,
          fromAnonymousLink: false,
          requestTemplate: _this.get('requestTemplate'),
          isRequest: true,
          message: _this.get('message'),
          submitterEmail: _this.get('recipientEmail'),
          contact: _this.get('contact'),
          subject: _this.get('subject'),
          createdBy: _this.get('currentUserNotSuperAdmin')
        });
        return Ember.RSVP.hash({
          scenario: newScenario.save(),
          newAssumption: newAssumption
        });
      }).then(function (_ref) {
        var scenario = _ref.scenario,
            newAssumption = _ref.newAssumption;

        newAssumption.set('scenario', scenario);
        newAssumption.set('scenarioLength', _this.get('requestTemplate.dataFormLength') || 10);
        newAssumption.save();
        _this.get('notify').info('Data form sent');
        _this.send('slideOut');
      });
    },
    sendDataRequest: function sendDataRequest(scenario) {
      var _this2 = this;

      this.get('ajax').post('/scenario/' + scenario.get('id') + '/sendEmail', {
        dataType: 'json',
        contentType: 'application/json; charset=utf-8',
        processData: false,
        data: JSON.stringify({
          to: this.get('recipientEmail'),
          subject: this.get('subject'),
          message: this.get('message'),
          signature: this.get('signature'),
          contact: this.get('contact.email') || '',
          cc: this.get("selectedCCUsers").map(function (_ref2) {
            var id = _ref2.id;
            return id;
          })
        })
      }).then(function () {
        var client = _this2.get('client.content');
        client.save();
        _this2.get('notify').info('Data form sent');
        _this2.send('slideOut');
      });
    },
    sendRevisionRequest: function sendRevisionRequest() {
      var _this3 = this;

      // copies assumptions, scenario (with request appropriate properties), and handles assumption relationship
      (0, _copyDataRequest.default)(this.get('scenario'), this.get('store')).then(function (newDataRequest) {
        _this3.sendDataRequest(newDataRequest);
      });
    },


    actions: {
      submit: function submit() {
        if (this.checkReadOnly()) {
          return;
        }

        if (this.get('scenario') && this.get('revising')) {
          this.sendRevisionRequest();
        } else if (this.get('scenario') && !this.get('revising')) {
          this.sendDataRequest(this.get('scenario'));
        } else {
          this.createAndSendNewDataRequest();
        }
      },
      cancel: function cancel() {
        this.cleanupModal();
        this.send('slideOut');
      },
      goToProjects: function goToProjects() {
        this.cleanupModal();
        this.get('router').transitionTo('projects', this.get('requestTemplate.client.id'));
      },
      copyToClipboard: function copyToClipboard() {
        (0, _copyToClipboard.default)(this.get('copyableText'));
        this.send('closeModalTimeout', 'Link copied to clipboard');
      },
      closeModalTimeout: function closeModalTimeout(message) {
        var _this4 = this;

        this.get('notify').info(message);
        setTimeout(function () {
          _this4.send('slideOut');
        }, 1500);
      },
      highlightText: function highlightText(id) {
        window.getSelection().selectAllChildren(document.getElementById(id));
      },
      updateContact: function updateContact(user) {
        this.set('contact', user);
      }
    }
  });
});