define('dashboard/utils/make-inputs', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  /**
   * Make Inputs
   *
   * Creates an array of value/index objects to be used as inputs
   *
   * @param  {Array}   array Array of values
   * @param  {Number}  years Number of years in scenario
   * @return {Array}         Array of objects to be used as inputs
   *
   * @method  makeInputs
   */
  var makeInputs = function makeInputs() {
    var array = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var years = arguments[1];

    var i = 0,
        value,
        inputs = [];

    for (i; i < years; i++) {
      value = parseFloat(array[i]) || 0;

      inputs.pushObject(Ember.Object.create({
        value: value,
        index: i
      }));
    }

    return inputs;
  };

  exports.default = makeInputs;
});