define('dashboard/mirage/factories/guest', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    email: function email(i) {
      return 'email' + i + '@test.com';
    }
  });
});