define('dashboard/mixins/scenario-wizard-sections', ['exports', 'dashboard/utils/scenario-inputs/current'], function (exports, _current) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = Ember.Mixin.create({

    /* NEEDS */
    /*
    client
    scenario,
    scenarioController,
    requestTemplate
    */
    basicsSection: Ember.computed('client.{industries,projectTypes}', 'scenario.{businessType,willExpand,subtitle,isNewBusiness,isExistingBusiness}', 'scenarioController.scenarioSubtitle', function () {

      var subtitle = { name: 'subtitle', inputType: 'text', dataProperty: 'subtitle', label: "Scenario Name", placeholder: this.get('scenarioController.scenarioSubtitle') },
          businessType = {
        name: 'businessType',
        dataProperty: 'businessType',
        inputType: 'button-panel',
        label: "Business Type",
        tooltip: 'Choose "New Business" if the company does not currently operate in your community. Choose "Existing Business" if the company has an existing presence in the community.',
        panels: [{ value: "new", label: "New Business" }, { value: "existing", label: "Existing Business" }]
      },
          expansionPlans = {
        name: 'expansionPlans',
        inputType: 'button-panel',
        dataProperty: 'willExpand',
        disabledProperty: 'isNewBusiness',
        hiddenProperty: 'isNewBusiness',
        label: "Expansion Plans",
        panels: [{ value: true, label: "Expansion" }, { value: false, label: "No Expansion" }]
      },
          projectType = {
        name: 'projectType', inputType: 'single-tag-select', modelProperty: 'project', dataProperty: 'projectType',
        label: "Project Type", options: this.get('scenarioController.tags').filterBy('category', 'projectType').sortBy('name'),
        tooltip: 'Edit or add Project Type choices in <a href="/account/customization" class="link underline nowrap">My Account.</a>'
      },
          industry = {
        name: 'industry', inputType: 'single-tag-select', modelProperty: 'project', dataProperty: 'industry',
        label: "Industry", options: this.get('scenarioController.tags').filterBy('category', 'industry').sortBy('name'),
        tooltip: 'Edit or add Industry choices in <a href="/account/customization" class="link underline nowrap">My Account.</a>'
      };

      var content = [subtitle, businessType, expansionPlans];
      content = content.concat([projectType, industry]);

      return {
        sectionBoolean: 'basics',
        name: "Scenario Basics",
        sidebarName: "Basics",
        saveFunction: 'plusProject',
        content: content
      };
    }),

    deleteToRemoveText: '*Delete all text to remove section from report.',

    descriptionSection: Ember.computed(function () {
      return {
        sectionBoolean: 'description',
        sidebarName: "Description",
        name: "Description",
        content: [{
          name: 'description', inputType: 'markdown', dataProperty: 'description', placeholder: "Enter a narrative to provide context to the Project.",
          helpText: "This description will be shown on the Project Fact Sheet and Detailed Report.",
          footnote: this.get('deleteToRemoveText')
        }]
      };
    }),

    internalNotesSection: Ember.computed(function () {
      return {
        sectionBoolean: 'internalNotes',
        sidebarName: "Internal Notes",
        name: "Internal Notes",
        content: [{
          name: 'internalNotes', inputType: 'textarea', dataProperty: 'internalNotes', label: "These are internal notes and are not visible in any of the reports."
        }]
      };
    }),

    dueDiligenceChecklistUrl: 'https://ids-public-assets.s3.amazonaws.com/documents/ED+Due+Diligence+Checklist.pdf',

    communityFitContent: Ember.computed(function () {
      return {
        name: 'communityFit',
        inputType: 'editable-label-textarea',
        dataProperty: 'communityFit',
        defaultLabel: 'Community Fit',
        labelProperty: 'communityFitLabel',
        helpText: "Use this text area to describe how the Project advances the community's ED strategy or address other aspects of Community Fit. You may rename this section for a different use if desired. The information entered here will be included on the Project Fact Sheet.",
        footnote: this.get('deleteToRemoveText')
      };
    }),

    communityFit: Ember.computed(function () {
      return {
        division: 'basics',
        sectionBoolean: 'communityFit',
        content: [this.get('communityFitContent')]
      };
    }),

    recommendationsContent: Ember.computed(function () {
      return {
        name: 'recommendations',
        inputType: 'editable-label-textarea',
        dataProperty: 'recommendations',
        defaultLabel: 'Recommendations',
        labelProperty: 'recommendationsLabel',
        helpText: "Use this text area to make recommendations regarding this Project or rename the section for a different use. The information entered here will be included on the Project Fact Sheet.",
        footnote: this.get('deleteToRemoveText')
      };
    }),

    recommendations: Ember.computed(function () {
      return {
        division: 'basics',
        sectionBoolean: 'recommendations',
        content: [this.get('recommendationsContent')]
      };
    }),

    companyNotesContent: Ember.computed(function () {
      return {
        name: 'companyNotes',
        inputType: 'editable-label-textarea',
        dataProperty: 'companyNotes',
        defaultLabel: 'Company Notes',
        labelProperty: 'companyNotesLabel',
        helpText: "Use this text area to provide more information on the company or its principals. You may rename this section for a different use if desired. The information entered here will be included on the Project Fact Sheet.",
        footnote: this.get('deleteToRemoveText')
      };
    }),

    companyNotes: Ember.computed(function () {
      return {
        division: 'company',
        sectionBoolean: 'companyNotes',
        content: [this.get('companyNotesContent')]
      };
    }),

    dueDiligenceSection: Ember.computed(function () {
      return {
        sectionBoolean: 'dueDiligence',
        sidebarName: 'Due Diligence',
        name: 'Due Diligence',
        wizardText: Ember.String.htmlSafe('This section allows you to summarize some of the additional due diligence you\'ve conducted on the Project. <a href=' + this.get('dueDiligenceChecklistUrl') + ' target=\'_blank\' class=\'link-blue\'>Click here to review our Economic Development Due Diligence Checklist.</a>'),
        content: [this.get('communityFitContent'), this.get('recommendationsContent'), this.get('companyNotesContent')]
      };
    }),

    basicsDivision: Ember.computed('basicsSection', 'descriptionSection', 'internalNotesSection', 'dueDiligenceSection', function () {
      return {
        navbarTitle: "Basics",
        sectionList: [this.get("basicsSection"), this.get('descriptionSection'), this.get('internalNotesSection'), this.get('dueDiligenceSection')]
      };
    }),

    // duplicate of imported propertyContent with different conditions per row based on primary district properties
    scenarioPropertyContent: Ember.computed('client.state', 'scenarioController.stateTaxes.{land,buildings,ffe}', 'scenarioController.primaryDistrict', 'scenarioController.{primaryAppliesLand,primaryAppliesBuildings,primaryAppliesFFE,primaryAppliesInventories}', function () {
      // we have to make copies of objects/arrays to preserve the original rows
      var scenarioController = this.get('scenarioController');
      var primaryDistrict = scenarioController.get('primaryDistrict');
      var scenarioPropertyContent = _current.propertyContent.toArray()[0];
      var output = _extends({}, scenarioPropertyContent);
      var rows = output.rows.toArray();
      var filteredRows = rows.filter(function (row) {
        // determine which conditions to use based on primary district
        // row.scenarioIf uses primary district properties
        // row.if uses state-level condition from fixtures/state-tax-profiles
        return primaryDistrict ? scenarioController.get(row.scenarioIf) : scenarioController.get(row.if);
      });
      // eslint-disable-next-line ember/no-side-effects
      Ember.set(output, 'rows', filteredRows);
      this.notifyPropertyChange('currentOperationsInvestmentSection');
      return [output];
    }),

    scenarioTaxablePurchasesAndSalesContent: Ember.computed('client.state', 'scenarioController.{showSalesTax,primaryDistrict}', 'scenarioController.primaryDistrict.{doesApplySalesTax,doesApplyTaxablePurchasesSalesTax,doesApplyTaxableSalesTax}', function () {
      var scenarioController = this.get('scenarioController');
      var primaryDistrict = scenarioController.get('primaryDistrict');

      return _current.taxablePurchasesAndSalesContent.filter(function (section) {
        // OK if section does not have scenarioIf property
        if (!section.scenarioIf) {
          return true;
        }

        // without primary district on scenario page, try to use state-level conditions, defined as stateIf
        if (!primaryDistrict && section.stateIf) {
          return scenarioController.get(section.stateIf);
        }

        // assuming there is a primary district, use primary district props
        return scenarioController.get(section.scenarioIf) && //  primaryDistrict.doesApplyTaxablePurchasesSalesTax or primaryDistrict.doesApplyTaxableSalesTax
        scenarioController.get('primaryDistrict.doesApplySalesTax'); // entire district applies any sales tax
      });
    }),

    currentOperationsInvestmentSection: Ember.computed('scenarioPropertyContent.@each.rows', 'scenarioTaxablePurchasesAndSalesContent', function () {
      return {
        sectionBoolean: 'currentOperationsInvestment',
        name: "Current Operations - Activity",
        content: this.get('scenarioPropertyContent').concat(_current.jobsAndSalaryContent).concat(this.get('scenarioTaxablePurchasesAndSalesContent'))
      };
    }),

    capitalInvestmentColumns: Ember.computed('client.state', 'scenarioController.primaryDistrict', 'scenarioController.stateTaxes.{land,buildings,ffe}', 'scenarioController.{primaryAppliesLand,primaryAppliesBuildings,primaryAppliesFFE}', function () {
      var scenarioController = this.get('scenarioController');
      var primaryDistrict = scenarioController.get('primaryDistrict');
      var columns = [{ name: "landExpenditures", dataProperty: "capitalInvestmentLand", label: "Land", if: 'primaryAppliesLand', stateIf: 'stateTaxes.land' }, { name: "buildingExpenditures", dataProperty: "capitalInvestmentBuildings", label: "Buildings & Other Real Property Improvements", longLabel: 'BUILDINGS & OTHER REAL PROPERTY IMPROVEMENTS', if: 'primaryAppliesBuildings', stateIf: 'stateTaxes.buildings' }, { name: "equipmentExpenditures", dataProperty: "capitalInvestmentFurniture", label: "Furniture, Fixutres & Equipment", longLabel: 'FURNITURE, FIXTURES, & EQUIPMENT', if: 'primaryAppliesFFE', stateIf: 'stateTaxes.ffe' }];
      return columns.filter(function (c) {
        return primaryDistrict ? scenarioController.get(c.if) : scenarioController.get(c.stateIf);
      });
    }),

    capitalInvestmentSection: Ember.computed('capitalInvestmentColumns.[]', function () {
      if (!this.get('capitalInvestmentColumns.length')) {
        return [];
      }

      return [{
        sectionBoolean: "capitalInvestment",
        name: "Capital Investment",
        wizardText: "How much will be invested in the community?",
        if: 'capitalInvestmentAllowed',
        tooltip: 'Enter the value of land and the Project’s capital investment each year.',
        content: [{
          name: 'capitalInvestment', inputType: 'complex-advanced-input', maskType: 'dollars', linkText: 'Specify phase-in over time',
          tooltip: "Enter the value of land and the Project’s capital investment each year. These values will be used as the basis for taxable property.",
          columns: this.get('capitalInvestmentColumns')
        }]
      }];
    }),

    inventoriesSection: Ember.computed('client.state', 'scenarioController.primaryDistrict', 'scenarioController.stateTaxes.inventory', 'scenarioController.primaryAppliesInventories', function () {
      var scenarioController = this.get('scenarioController');
      var primaryDistrict = scenarioController.get('primaryDistrict');
      if (primaryDistrict && !scenarioController.get('primaryAppliesInventories')) {
        return [];
      }

      if (!primaryDistrict && !scenarioController.get('stateTaxes.inventory')) {
        return [];
      }

      return [{
        sectionBoolean: 'inventories',
        name: "Inventories",
        if: 'stateTaxes.inventory',
        wizardText: "Will the Project include taxable business inventories?",
        tooltip: 'Enter a value in Year 1 and the percent of annual increase, or enter appropriate values for each year.',
        content: [{
          name: 'annualTaxableInventories', inputType: 'cascading-input', dataProperty: "taxableInventories", dataIncreaseProperty: "taxableInventoriesAnnualIncrease",
          label: "Inventories", tableHeader: "Inventories", linkText: "Specify increases over time"
        }]
      }];
    }),

    taxablePurchasesAndSalesSections: Ember.computed('scenarioController.{primaryDistrict,showSalesTax}', 'scenarioController.primaryDistrict.doesApplySalesTax', function () {
      var scenarioController = this.get('scenarioController');
      var primaryDistrict = scenarioController.get('primaryDistrict');
      var sections = [];

      // conditionally hide taxable sales and purchases if primary district doesnt apply sales tax
      // or if primary district doesnt apply taxable sales or taxable purcahses sales tax
      if (primaryDistrict && !primaryDistrict.get('doesApplySalesTax')) {
        return sections;
      }

      // use state tax profile properties if no primary district
      if (!primaryDistrict && !scenarioController.get('showSalesTax')) {
        return sections;
      }

      var taxablePurchasesSection = {
        sectionBoolean: 'annualTaxablePurchases',
        name: "Taxable Purchases",
        content: [{
          name: 'taxablePurchasesAndSales',
          inputType: 'cascading-input', dataProperty: 'taxablePurchases', dataIncreaseProperty: 'taxablePurchasesPercentIncrease',
          maskType: 'dollars', tableHeader: "AMOUNT IN THE CITY", label: 'Taxable Purchases',
          tooltip: "Estimate the project’s taxable purchases of materials, supplies, and services. Do not include purchases of FF&E identified in Capital Investment section."
        }]
      };

      var taxableSalesSection = {
        sectionBoolean: 'annualTaxableSales',
        name: "Taxable Sales",
        content: [{
          name: 'annualLocalTaxableSales', maskType: 'dollars', tableHeader: "AMOUNT IN THE CITY",
          inputType: 'cascading-input', dataProperty: 'taxableSales', dataIncreaseProperty: 'taxableSalesPercentIncrease',
          label: 'Taxable Sales', tooltip: "Enter a value in Year 1 and the percent of annual increase, or enter appropriate values for each year."
        }]
      };

      if (scenarioController.get('primaryDistrict.doesApplyTaxablePurchasesSalesTax') || scenarioController.get('showSalesTax')) {
        sections.push(taxablePurchasesSection);
      }

      if (scenarioController.get('primaryDistrict.doesApplyTaxableSalesTax') || scenarioController.get('showSalesTax')) {
        sections.push(taxableSalesSection);
      }

      return sections;
    }),

    replaceSection: function replaceSection(sectionList, sectionTitle, newSections) {
      var indexOf = sectionList.findIndex(function (s) {
        return s.sectionBoolean == sectionTitle;
      });
      if (indexOf == -1) return;

      sectionList.splice.apply(sectionList, [indexOf, 1].concat(_toConsumableArray(newSections)));
    },


    divisions: Ember.computed('requestTemplate.divisions.[]', 'scenario.isNewBusiness', 'currentOperationsInvestmentSection.content.@each', 'capitalInvestmentSection.@each.content', 'inventoriesSection.@each.content', 'taxablePurchasesAndSalesSections.@each.content', function () {

      var basics = this.get('basicsDivision');
      var divisions = Object.assign({ basics: basics }, this.get('requestTemplate.divisions'));

      delete divisions.welcome;

      if (this.get('scenario.isNewBusiness')) {
        delete divisions.current;
        delete divisions.expansion;

        this.replaceSection(divisions.plans.sectionList, 'taxablePurchasesAndSales', this.get('taxablePurchasesAndSalesSections'));
        this.replaceSection(divisions.plans.sectionList, 'capitalInvestment', this.get('capitalInvestmentSection'));
        this.replaceSection(divisions.plans.sectionList, 'inventories', this.get('inventoriesSection'));
      } else {
        delete divisions.plans;

        // @TODO replacing properties this way does not always work correctly with bindings - ie page refresh is needed when some properties change
        // jury-rigging the 'current operations' division so it resembles Scenario Info
        this.replaceSection(divisions.current.sectionList, 'currentJobsAndSalaries', [this.get('currentOperationsInvestmentSection')]);
        // eslint-disable-next-line ember/no-side-effects
        Ember.set(divisions.current, 'sectionList', divisions.current.sectionList.reject(function (s) {
          return ['capitalInvestment', 'currentProjectOther'].includes(s.sectionBoolean);
        }));

        this.replaceSection(divisions.expansion.sectionList, 'taxablePurchasesAndSales', this.get('taxablePurchasesAndSalesSections'));
        this.replaceSection(divisions.expansion.sectionList, 'capitalInvestment', this.get('capitalInvestmentSection'));
        this.replaceSection(divisions.expansion.sectionList, 'inventories', this.get('inventoriesSection'));
        this.replaceSection(divisions.current.sectionList, 'expansionPlans', []);
      }

      return divisions;
    }),

    wizardSections: Ember.computed('divisions.[]', function () {

      var divisions = this.get('divisions');
      var keys = Object.keys(divisions);
      return keys.flatMap(function (k) {
        return divisions[k].sectionList.map(function (section, index) {
          return _extends({}, section, { division: k, index: index });
        });
      });
    }),

    // larger list of wizardSections, also including some sections which we need to be editable on the fact sheet
    // that we don't want showing on the raw data page
    allWizardSections: Ember.computed('wizardSections.[]', function () {
      var wizardSections = this.get('wizardSections');
      var allSections = wizardSections.concat(this.get('communityFit'), this.get('recommendations'), this.get('companyNotes'));
      return allSections;
    }),

    actions: {
      save: function save(saveFunction) {
        var _this = this;

        if (this.get('scenarioController.isSavable')) {
          this.get('scenario').save().catch(function (adapterError) {
            if (adapterError && adapterError.errors.length) {
              var error = adapterError.errors[0];
              var badProp = error.path;
              // clear out bad value so we can still save other properties
              _this.get('scenario').set(badProp, null);
              _this.get('notify').alert(error.message);
            }
          });

          if (saveFunction == 'plusProject') {
            this.get('project').save();
          }
        }
      }
    }

  });
});