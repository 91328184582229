define('dashboard/mixins/community-incentives', ['exports', 'dashboard/objects/incentive-engine', 'lodash/array', 'dashboard/utils/sum-many-arrays', 'dashboard/utils/array-of-length', 'dashboard/utils/cumulative-sum'], function (exports, _incentiveEngine, _array2, _sumManyArrays, _arrayOfLength, _cumulativeSum) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({

    // boolean to include TIF district in participating community incentives
    participatingTifDistrict: Ember.computed.and('tifDistrict', 'model.usesTifDistrict', 'model.communityIncentiveIncludesTif'),

    // array of tax districts participating in community incentive
    // mixin + support + report
    selectedCommunityDistricts: Ember.computed('model.communityIncentiveGroupIds.[]', 'model.districts.[]', 'participatingTifDistrict', function () {
      var _this = this;

      var taxDistricts = _array2.default.compact(this.get('model.communityIncentiveGroupIds').map(function (groupId) {
        return _this.get('model.districts').findBy('parentGroup.id', groupId);
      })).sortBy('parentGroup.groupOrder');

      if (this.get('participatingTifDistrict')) {
        return taxDistricts.concat([this.get('tifDistrict')]);
      }
      return taxDistricts;
    }),

    // array of impact engines for districts participating in community incentive
    // mixin + support + report
    communityImpactEngines: Ember.computed('engines.@each.{district,totalNonTaxIncentive}', 'model.communityIncentiveGroupIds.[]', 'participatingTifDistrict', function () {
      var _this2 = this;

      var groupIds = this.get('model.communityIncentiveGroupIds') || [];
      var districtEngines = _array2.default.compact(groupIds.map(function (groupId) {
        return _this2.get('engines').find(function (engine) {
          return engine.get('group.id') === groupId;
        });
      }));

      if (this.get('participatingTifDistrict')) {
        return districtEngines.concat([this.get('tifImpactEngine')]);
      }

      return districtEngines;
    }),

    // array of incentive-schedule models for each district participating in community incentive
    selectedCommunitySchedules: Ember.computed('selectedCommunityGroups.[]', 'participatingTifDistrict', 'model.tifIncentive', function () {
      var _this3 = this;

      var districtSchedules = this.get('selectedCommunityGroups').map(function (group) {
        return _this3.get('model').getScheduleForDistrict(group);
      });
      if (this.get('participatingTifDistrict')) {
        return districtSchedules.concat([this.get('model.tifIncentive')]);
      }
      return districtSchedules;
    }),

    // sum of all net benefits arrays for all participating community districts
    // mixin
    communityNetBenefits: Ember.computed('communityImpactEngines.@each.{length,annualNetBenefitsDirect}', function () {
      if (!this.get('communityImpactEngines.length')) {
        return (0, _arrayOfLength.default)(this.get('years'));
      }
      var netBenefitsArrays = this.get('communityImpactEngines').mapBy('annualNetBenefitsDirect');
      return (0, _sumManyArrays.default)(netBenefitsArrays);
    }),

    // cumulative sum of community net benefits for graph
    // support + report
    cumulativeCommunityNetBenefits: Ember.computed('communityNetBenefits', function () {
      return (0, _cumulativeSum.default)(this.get('communityNetBenefits'));
    }),

    // nonTaxIncentives: computed.alias('scenario.communityIncentiveSchedule.incentives'),

    engineIncentives: Ember.computed('scenario.communityIncentiveSchedule.incentives', 'scenario.currentVsExpansion', 'years', function () {
      if (this.get('scenario.currentVsExpansion') === 'current') {
        return (0, _arrayOfLength.default)(this.get('years'));
      }
      return this.get('scenario.communityIncentiveSchedule.incentives');
    }),

    // support + report + mixin
    communityIncentive: Ember.computed('model.{assumption,communityIncentiveSchedule.incentives}', 'communityNetBenefits', 'primaryEngine.eiJobs.lastDirectCumulative', 'engineIncentives', function () {

      var schedule = this.get('model.communityIncentiveSchedule'),
          communityNetBenefits = this.get('communityNetBenefits'),
          directJobs = this.get('primaryEngine.eiJobs.lastDirectCumulative');

      return _incentiveEngine.default.create({
        assumption: this.get('model.assumption'),
        schedule: schedule,
        engineIncentives: this.get('engineIncentives'),
        jobs: directJobs,
        annualNetBenefits: communityNetBenefits,
        incentiveComparison: 'nonTax',
        scenario: this.get('scenarioController')
      });
    }),

    // support + mixin
    attributionTotals: Ember.computed('communityImpactEngines.@each.{totalIncentivesTotal,scheduleAttributionPercent,totalNonTaxIncentive}', function () {
      var engines = this.get('communityImpactEngines');

      var percent = engines.reduce(function (total, engine) {
        return total + parseFloat(engine.get('scheduleAttributionPercent'));
      }, 0);

      var amount = engines.reduce(function (total, engine) {
        return total + parseFloat(engine.get('totalNonTaxIncentive'));
      }, 0);

      return Ember.Object.create({ percent: percent, amount: amount });
    }),

    // based on actual total attribution percent, so could be different from communityIncentives
    // support + report
    communityActualTotals: Ember.computed('model.{assumption,communityIncentiveSchedule}', 'communityNetBenefits', 'primaryEngine.eiJobs.lastDirectCumulative', 'attributionTotals.amount', function () {

      var incentive = this.get('attributionTotals.amount'),
          schedule = Ember.Object.create({ incentives: [incentive] });

      return _incentiveEngine.default.create({
        assumption: this.get('model.assumption'),
        schedule: schedule,
        jobs: this.get('primaryEngine.eiJobs.lastDirectCumulative'),
        annualNetBenefits: this.get('communityNetBenefits'),
        incentiveComparison: 'nonTax',
        scenario: this.get('scenarioController')
      });
    }),

    noAllocationCommunityIncentive: Ember.computed('communityIncentive.totalIncentive', 'attributionTotals.percent', function () {
      var communityIncentiveTotal = this.get('communityIncentive.totalIncentive'),
          totalAllocationPercent = this.get('attributionTotals.percent');

      return !!(communityIncentiveTotal && !totalAllocationPercent);
    }),

    // array of districts + tif selectable for participation in community incentive or community report
    communityIncentiveOptions: Ember.computed('sortedDistricts', 'tifDistrict', function () {
      var sortedDistricts = this.get('sortedDistricts') || [],
          tifDistrict = this.get('tifDistrict'),
          usesTifDistrict = this.get('scenario.usesTifDistrict');

      if (tifDistrict && usesTifDistrict) {
        return sortedDistricts.concat([tifDistrict]);
      }

      return sortedDistricts;
    }),

    findEngineForSchedule: function findEngineForSchedule(schedule, engines) {
      if (!schedule) {
        return;
      }
      return engines.find(function (engine) {
        return engine.get('group.id') === schedule.get('districtGroup.id');
      });
    },


    // not 100% sure this is correct place for this property, but community-incentives is in both places that need it
    // feel free to split off into a different mixin in the future
    engineForSelectedSchedule: Ember.computed('selectedSchedule', 'engines.@each.group', function () {
      return this.findEngineForSchedule(this.get('selectedSchedule'), this.get('engines'));
    }),

    // same as above but instead of letting the `engines` property in the scenario tell us if we're using currentEngines
    // or expansionEngines, we want to specifically look at current
    currentEngineForSelectedSchedule: Ember.computed('selectedSchedule', 'currentEngines.@each.group', function () {
      return this.findEngineForSchedule(this.get('selectedSchedule'), this.get('currentEngines'));
    }),

    // same as above but for expansionEngines
    expansionEngineForSelectedSchedule: Ember.computed('selectedSchedule', 'expansionEngines.@each.group', function () {
      return this.findEngineForSchedule(this.get('selectedSchedule'), this.get('expansionEngines'));
    })

  });
});