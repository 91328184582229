define('dashboard/components/graphs/incentives-graph-header', ['exports', 'dashboard/helpers/format-money', 'dashboard/helpers/format-percent'], function (exports, _formatMoney, _formatPercent) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    // incentives only apply to new jobs, so if viewing a scenario as Total Combined, we want to clarify this
    perJobLabel: Ember.computed('currentVsExpansion', function () {
      var currentVsExpansion = this.get('currentVsExpansion') || 'expansion';
      return currentVsExpansion === 'total' ? 'Per New Job' : 'Per Job';
    }),

    perJobHelpContent: Ember.computed('perJobLabel', function () {
      return '<p class="mb-0">The <b>' + this.get('perJobLabel') + '</b> incentive amount is the incentive amount divided by the number of direct Jobs.</p>';
    }),

    paybackPeriodHelpContent: '<p class="mb-0">The <b>Payback Period</b> identifies the point at which the project will generate enough net benefits to pay back the value of the incentive.</p>',

    rateOfReturnHelpContent: Ember.computed('years', 'incentiveEngine.{netBenefits,totalIncentive,incentiveRateOfReturn}', function () {
      var netBenefits = (0, _formatMoney.formatMoney)([this.get('incentiveEngine.netBenefits')]),
          years = this.get('years'),
          totalIncentive = (0, _formatMoney.formatMoney)([this.get("incentiveEngine.totalIncentive")]),
          rateOfReturn = (0, _formatPercent.formatPercent)([this.get('incentiveEngine.incentiveRateOfReturn')]);

      return '<p class="mb-0">The <b>Rate of Return</b> is the average annual net benefit divided by the incentive amount. <br>' + ('<span class="nowrap">(' + netBenefits + ' NB / ' + years + ' yrs) / ' + totalIncentive + ' = ' + rateOfReturn + '</span></p>');
    })

  });
});