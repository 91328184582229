define('dashboard/models/tif-schedule', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    group: (0, _relationships.belongsTo)('districtGroup'),

    created: (0, _attr.default)('date'),
    landContributed: (0, _attr.default)('string', { defaultValue: '0.0' }),
    buildingsContributed: (0, _attr.default)('string', { defaultValue: '0.0' }),
    ffeContributed: (0, _attr.default)('string', { defaultValue: '0.0' }),
    inventoriesContributed: (0, _attr.default)('string', { defaultValue: '0.0' }),
    taxableSalesContributed: (0, _attr.default)('string', { defaultValue: '0.0' }),

    landContributedDecimal: (0, _attr.default)('string', { defaultValue: '0.0' }),
    buildingsContributedDecimal: (0, _attr.default)('string', { defaultValue: '0.0' }),
    ffeContributedDecimal: (0, _attr.default)('string', { defaultValue: '0.0' }),
    inventoriesContributedDecimal: (0, _attr.default)('string', { defaultValue: '0.0' }),
    taxableSalesContributedDecimal: (0, _attr.default)('string', { defaultValue: '0' }),

    order: Ember.computed.alias('group.groupOrder')
  });
});