define('dashboard/components/editable-label-textarea', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    changingLabel: false,

    actions: {
      save: function save() {
        if (this.get('save')) {
          this.get('save')();
        }
      },
      updateLabel: function updateLabel(newLabel) {
        this.sendAction('updateLabel', newLabel, this.get('labelProperty'));
      }
    }
  });
});