define('dashboard/mirage/models/scenario', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Model.extend({
    project: (0, _emberCliMirage.belongsTo)(),
    assumption: (0, _emberCliMirage.belongsTo)(),
    requestTemplate: (0, _emberCliMirage.belongsTo)(),
    districts: (0, _emberCliMirage.hasMany)('taxDistrict')
  });
});