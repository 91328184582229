define('dashboard/objects/fi-tax/revenue-per-student', ['exports', 'dashboard/utils/percent-each', 'dashboard/objects/fi-tax/base'], function (exports, _percentEach, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    revenuePerStudent: Ember.computed('revenue', 'revenueIncrease', 'cumulativeNewStudents', 'doesApply', function () {
      var revenue = this.get('revenue') || 0,
          revenueIncrease = (0, _percentEach.percentify)(this.get('revenueIncrease')) || 0,
          cumulativeNewStudents = this.get('cumulativeNewStudents') || [],
          doesApply = this.get('doesApply');

      if (!doesApply) {
        return cumulativeNewStudents.map(function () {
          return 0;
        });
      }

      return cumulativeNewStudents.map(function (students, index) {
        return students * revenue * Math.pow(1 + revenueIncrease, index);
      });
    }),

    workers: Ember.computed.sum('revenuePerStudent')
  });
});