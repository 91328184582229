define('dashboard/controllers/tax-districts', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    breadcrumbItems: Ember.computed('currentDistrict.{name,isActive}', function () {
      var currentDistrict = this.get('currentDistrict');

      var route = 'tax-districts';
      if (!currentDistrict.get('isActive')) {
        route = 'tax-districts.inactive';
      }

      var items = [{ label: 'Tax Districts', params: [route] }];

      if (currentDistrict) {
        items.push({ label: currentDistrict.get('name'), active: true });
      }

      return items;
    })

  });
});