define("dashboard/utils/scenario-inputs/future", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var jobsContent = [{
    name: "jobsAdded", label: "Multi-Year Phase-in Input", hideLabel: true,
    inputType: "advanced-input", dataProperty: 'jobsAddedEachYear',
    tableHeader: "New Jobs Added", longTableHeader: 'NEW JOBS ADDED', linkText: "Specify phase-in over time"
  }];

  var salariesContent = [{
    name: "avgAnnualSalary", label: "Multi-Year Phase-in Input",
    inputType: "cascading-input", dataProperty: "averageAnnualSalaries", dataIncreaseProperty: "averageAnnualSalariesIncrease",
    tableHeader: "Avg Annual Salary", longTableHeader: "AVG ANNUAL SALARY", linkText: "Specify increases over time"
  }];

  exports.default = {
    reviews: {
      plans: {
        if: 'requestTemplate.plans',
        editScreenPath: 'future.plans',
        testSelector: "future-plans",
        fragments: [{ type: 'text', text: "The Project's Plans include " }, { type: 'list-display', dataProperty: 'projectPlans' }, { if: 'leaseTermInYears', alwaysDisplayInRequestTemplate: true, fragments: [{ type: 'text', text: ' with an anticipated lease length of ' }, { type: 'var', dataProperty: 'leaseTermInYears' }, { type: 'text', text: ' years' }] }]
      },
      timeline: {
        if: 'requestTemplate.timeline',
        editScreenPath: 'future.timeline',
        fragments: [{ type: 'text', text: "The Project will break ground on " }, { type: 'var', dataProperty: "beginRenovationsDate" }, { type: 'text', text: " and begin operation on " }, { type: 'var', dataProperty: "beginLocalOperationsDate" }]
      },
      site: {
        if: 'requestTemplate.site',
        editScreenPath: 'future.site',
        fragments: [{ type: 'text', text: "The Project is considering a site at " }, { type: 'var', dataProperty: "projectLocation" }, { if: 'siteRequirements', fragments: [{ type: 'text', text: " and requires the following site requirements: " }, { type: 'var', dataProperty: "siteRequirements" }] }]
      },
      squareFootageScreen: {
        if: 'requestTemplate.squareFootageScreen',
        editScreenPath: 'future.squareFootageScreen',
        fragments: [{ type: 'text', text: "The Project desires " }, { type: 'hash-total', dataProperty: "siteSquareFootage", format: 'whole' }, { type: 'text', text: " total square feet of space with uses to include " }, { type: 'hash-keys', dataProperty: "siteSquareFootage" }]
      },
      productsAndIndustry: {
        if: 'requestTemplate.productsAndIndustry',
        editScreenPath: 'future.productsAndIndustry',
        fragments: [{ type: 'text', text: "The primary NAICS code associated with this Project is " }, { type: 'var', dataProperty: 'multiplierCode' }]
      },
      jobsAndSalaries: {
        if: 'requestTemplate.showAnyJobsOrSalaries',
        // TODO: figure out how to make this display the correct fields based on requestTemplate
        editScreenPath: 'future.jobsAndSalariesReview',
        fragments: [{ type: 'text', text: "The Project will create " }, { type: 'array-total', dataProperty: 'jobsAddedEachYear', format: 'whole' }, { type: 'text', text: ' jobs in the community' }, { if: 'averageAnnualSalaries', fragments: [{ type: 'text', text: ' and pay salaries/earnings of ' }, { type: 'array-first', dataProperty: 'averageAnnualSalaries', format: 'money' }] }]
      },
      benefits: {
        if: 'requestTemplate.benefits',
        editScreenPath: 'future.benefits',
        fragments: [{ type: 'text', text: 'The Company ' }, { if: 'hasBenefits', fragments: [{ type: 'text', text: '<strong>will</strong>' }] }, { if: 'notHasBenefits', fragments: [{ type: 'text', text: '<strong>will not</strong>' }] }, { type: 'text', text: 'provide benefits for its workers.' }, { if: 'requestTemplate.benefitsAvgAnnualValue', fragments: [{ type: 'text', text: 'Benefits will be valued at ' }, { type: 'var', dataProperty: 'benefitsAvgAnnualValue', format: 'money' }, { type: 'text', text: ' per year' }] }, { if: 'benefitsAdditionalNotes', fragments: [{ type: 'text', text: ' Eligibility requirements for benefits include ' }, { type: 'var', dataProperty: 'benefitsAdditionalNotes' }] }]
      },
      capitalInvestment: {
        if: 'requestTemplate.capitalInvestment',
        editScreenPath: 'future.capitalInvestment',
        fragments: [{ type: 'text', text: "Land acquisition and capital investment will total " }, { type: 'var', dataProperty: 'capitalInvestmentTotal', format: 'money' }]
      },
      inventories: {
        if: 'requestTemplate.hasCapitalInvestmentInventories',
        editScreenPath: 'future.inventories',
        fragments: [{ type: 'text', text: 'This Project will support ' }, { type: 'array-first', dataProperty: 'taxableInventories', format: 'money' }, { type: 'text', text: ' in annual Inventories' }]
      },
      taxablePurchasesAndSales: {
        if: 'requestTemplate.salesTaxOnReview',
        editScreenPath: 'future.taxablePurchasesAndSales',
        fragments: [{ type: 'text', text: 'This Project will support ' }, { type: 'var', dataProperty: 'firstYearTaxableSalesAndPurchases', format: 'money' }, { type: 'text', text: ' in annual Taxable Purchases or Sales' }]
      },
      hotelNightsVisitors: {
        if: 'requestTemplate.hotelNights',
        editScreenPath: 'future.hotelNightsVisitors',
        fragments: [{ type: 'text', text: 'This Project will support ' }, { type: 'array-first', dataProperty: 'hotelNights', format: 'whole' }, { type: 'text', text: ' Local Hotel Nights' }]
      }
    },
    plans: {
      sectionBoolean: "plans",
      name: "Plans",
      wizardText: "Does the Project involve leasing or owning property?",
      content: [{
        name: "projectPlans", inputType: "checkboxes", dataProperty: 'projectPlans', label: "Project Plans",
        other: true, clientExplanation: { message: "(Lease Space, Build New, Purchase Existing Building, etc.)" },
        options: ["Lease space with renovations", "Lease space - build to suit", "Land purchase and construction of a new building", "Purchase of existing building(s) with renovations", "Addition to existing building(s) already owned"]
      }, {
        name: "leaseTermInYears", inputType: "text", dataProperty: 'leaseTermInYears', label: "Lease Term in Years (if applicable)",
        notRequired: true, if: 'hasLeasePlans', alwaysDisplayInRequestTemplate: true
      }]
    },
    timeline: {
      sectionBoolean: "timeline",
      name: "Timeline",
      wizardText: "Help us understand your timeline.",
      content: [{ name: "beginRenovationsDate", inputType: "text", dataProperty: 'beginRenovationsDate', label: "Date you plan to break ground or begin renovations" }, { name: "beginLocalOperationsDate", inputType: "text", dataProperty: 'beginLocalOperationsDate', label: "Date you plan to begin local operations" }]
    },
    site: {
      sectionBoolean: "site",
      name: "Site",
      wizardText: "Tell us about the site under consideration.",
      content: [{ name: "siteAddress", inputType: "address", dataProperty: 'projectLocation', label: "Site Address, if known" }, { name: "specialSiteRequirements", inputType: "textarea", numRows: 4, dataProperty: 'siteRequirements', label: "Describe any special site requirements" }]
    },
    squareFootageScreen: {
      sectionBoolean: "squareFootageScreen",
      name: "Square Footage",
      wizardText: "Help us understand the Project's space requirements.",
      content: [{
        name: "squareFootage", inputType: "hash", dataProperty: 'siteSquareFootage', label: "Square Footage", labelLeft: "Use", labelRight: "Square Footage",
        requestTemplateLink: { text: "Manage square footage use types", route: 'user.customization' },
        clientExplanation: { listProperty: 'client.squareFootageTypes' },
        dynamicRows: { dataProperty: 'client.squareFootageTypes', maskType: "whole", showTotal: true }
      }]
    },
    productsAndIndustry: {
      sectionBoolean: "productsAndIndustry",
      name: "Products & Industry",
      wizardText: "Tell us what the Company produces.",
      content: [{ name: 'localService', inputType: 'text', dataProperty: 'localService', label: "Service or product to be produced at local site" }, { name: 'mainService', inputType: 'text', dataProperty: 'mainService', label: "Main service or product produced companywide" }, {
        name: "primaryNAICSCode",
        inputType: "naics-search",
        dataProperty: 'multiplier',
        multiplierPath: 'multiplier',
        multiplierCodePath: 'multiplierCode',
        label: "Primary NAICS Code",
        tooltip: 'Indicate the NAICS corresponding to the activities to be conducted in the community. If an auto manufacturer is locating its headquarters, select the code for headquarters operations instead of auto manufacturing. Check out the <a target="_blank" href="https://www.census.gov/naics/" class="underline">US Census Bureau</a> if you aren’t sure which NAICS code to use.'
      }]
    },
    jobs: {
      sectionBoolean: 'jobs',
      name: "Jobs",
      wizardText: "How many jobs will the Project create?",
      tooltip: "Enter only the additional jobs created each year in the community. If the company is relocating from outside of the area, the Year 1 value should include jobs transferred to the community in Year 1 and any new jobs created, if any.",
      content: jobsContent
    },
    salaries: {
      sectionBoolean: 'salaries',
      name: "Salaries",
      wizardText: "How much will workers earn?",
      tooltip: "Enter the average salary paid to workers excluding the value of benefits.",
      content: salariesContent
    },
    jobsAndSalaries: {
      sectionBoolean: "jobsAndSalaries",
      name: "Job & Salary Detail",
      wizardText: "Provide details about the positions you will add and the salaries to be paid.",
      content: [{ name: "employmentDetails", inputType: "employment-details", dataProperty: 'employmentDetails', label: "Employment Detail" }]
    },
    benefits: {
      sectionBoolean: "benefits",
      name: "Benefits",
      wizardText: "Tell us about the benefits offered by the Company.",
      content: [{ name: "benefitTypes", inputType: "checkboxes", dataProperty: 'benefitTypes', label: "Indicate which benefits are offered by the Company.",
        other: true, options: ["Paid Time Off", "Health Insurance", "Other Insurance (Dental, Vision, Life, or Disability)", "Retirement", "Tuition Reimbursement"] }, { name: "benefitsAvgAnnualValue", inputType: "dollars", dataProperty: 'benefitsAvgAnnualValue', label: "Average annual value of employer-provided benefits" }, { name: "benefitsAdditionalNotes", inputType: "textarea", numRows: 4, dataProperty: 'benefitsAdditionalNotes', label: "Describe eligibility requirements for benefits" }]
    },
    capitalInvestment: {
      sectionBoolean: "capitalInvestment",
      name: "Capital Investment",
      wizardText: "How much will be invested in the community?",
      if: 'capitalInvestmentAllowed',
      tooltip: 'Enter the land acquisition cost by year and Project’s other capital investment by year. Enter the investment value only one time. If all of the investment happens in Year 1 then rows 2-5 should be left blank.',
      content: [{
        name: 'capitalInvestment', inputType: 'complex-advanced-input', maskType: 'dollars', linkText: 'Specify phase-in over time',
        tooltip: "Enter the value of land and the Project’s capital investment each year. These values will be used as the basis for taxable property.",
        columns: [{ name: "landExpenditures", dataProperty: "capitalInvestmentLand", label: "Land", if: 'stateTaxes.land' }, { name: "buildingExpenditures", dataProperty: "capitalInvestmentBuildings", label: "BUILDINGS & OTHER REAL PROPERTY IMPROVEMENTS", longLabel: 'BUILDINGS & OTHER REAL PROPERTY IMPROVEMENTS', if: 'stateTaxes.buildings' }, { name: "equipmentExpenditures", dataProperty: "capitalInvestmentFurniture", label: "FURNITURE, FIXTURES, & EQUIPMENT", longLabel: 'FURNITURE, FIXTURES, & EQUIPMENT', if: 'stateTaxes.ffe' }]
      }]
    },
    inventories: {
      sectionBoolean: 'inventories',
      name: "Inventories",
      if: 'stateTaxes.inventory',
      wizardText: "Will the Project include taxable business inventories?",
      tooltip: 'Enter a value in Year 1 and the percent of annual increase, or enter appropriate values for each year.',
      content: [{
        name: 'annualTaxableInventories', inputType: 'cascading-input', dataProperty: "taxableInventories", dataIncreaseProperty: "taxableInventoriesAnnualIncrease",
        label: "Inventories", tableHeader: "Inventories", linkText: "Specify increases over time"
      }]
    },
    taxablePurchasesAndSales: {
      sectionBoolean: 'taxablePurchasesAndSales',
      name: "Taxable Purchases or Sales",
      if: 'showSalesTax',
      wizardText: "Indicate the amount of taxable purchases or sales that will be subject to local sales tax, if any.",
      content: [{
        name: 'taxablePurchasesAndSales',
        if: 'showSalesTax',
        inputType: 'complex-cascading-input',
        maskType: 'dollars',
        linkText: 'Specify increases over time',
        columns: [{
          name: 'annualTaxablePurchases',
          dataProperty: 'taxablePurchases',
          dataIncreaseProperty: 'taxablePurchasesPercentIncrease',
          label: 'Taxable Purchases',
          tooltip: 'Estimate the Project’s taxable purchases of materials, supplies, and services. Do not include purchases of FF&E identified in Capital Investment section.'
        }, {
          name: 'annualLocalTaxableSales',
          dataProperty: 'taxableSales',
          dataIncreaseProperty: 'taxableSalesPercentIncrease',
          label: 'Taxable Sales',
          tooltip: 'Enter a value in Year 1 and the percent of annual increase, or enter appropriate values for each year.'
        }]
      }]
    },
    hotelNightsVisitors: {
      sectionBoolean: "hotelNightsVisitors",
      name: "Hotel Nights & Visitors",
      wizardText: "Will the Project have an impact on local hotels?",
      content: [{
        name: 'hotelNightsVisitors',
        linkText: 'Specify detailed visitor information',
        inputType: 'complex-cascading-input',
        columns: [{ name: "hotelNights", dataProperty: "hotelNights", dataIncreaseProperty: 'hotelNightsIncrease', label: "Hotel Nights" }, { name: "hotelDailyRate", maskType: "dollars", dataProperty: "hotelDailyRate", dataIncreaseProperty: 'hotelDailyRateIncrease', label: "Hotel Daily Rate", assumptionProperty: 'hotelRoomRate' }, { name: "spendingPerHotelNight", maskType: "dollars", dataProperty: "spendingPerHotelNight", dataIncreaseProperty: 'spendingPerHotelNightIncrease', label: "General Visitor Spending per Hotel Night", assumptionProperty: 'dailyTaxableVisitorSpending', if: 'showSalesTax' }]
      }]
    },

    jobsAndSalariesReview: {
      name: "Jobs and Salaries",
      // TODO: calculate whether to show this or the detailed jobsAndSalaries table
      content: [].concat(jobsContent, salariesContent)
    }
  };
});