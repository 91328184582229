define('dashboard/components/color-scheme-select', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.set('colorOptions', ['Blue', 'Green', 'Red']);
    },


    actions: {
      updateColorTheme: function updateColorTheme(color) {
        var client = this.get('client');

        client.set('colorTheme', color);
        client.save();
      }
    }
  });
});