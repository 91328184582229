define("dashboard/utils/first-non-zero-index", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var firstNonZeroIndex = function firstNonZeroIndex(array) {

    var first;
    array.forEach(function (item) {
      if (parseFloat(item) > 0 && undefined === first) {
        first = parseFloat(item);
      }
    });

    return first;
  };

  exports.default = firstNonZeroIndex;
});