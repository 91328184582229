define('dashboard/mirage/factories/client', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    assumption: (0, _emberCliMirage.association)(),
    name: _faker.default.lorem.word,
    afterCreate: function afterCreate(client, server) {
      for (var i = 0; i < 5; i++) {
        server.create('district-group', {
          client: client,
          label: _faker.default.lorem.word,
          groupOrder: i,
          withAdminDistrict: true
        });
      }
    }
  });
});