define("dashboard/helpers/format-encodeURI", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.emberEncodeUri = emberEncodeUri;
  function emberEncodeUri(params) {
    var text = params[0] || "";
    return encodeURIComponent(text);
  }

  exports.default = Ember.Helper.helper(emberEncodeUri);
});