define('dashboard/components/district-select-for-areas', ['exports', 'dashboard/components/district-select'], function (exports, _districtSelect) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _districtSelect.default.extend({

    layoutName: 'components/district-select',

    options: Ember.computed('group.districts.[]', 'selectedDistricts.{@each.districtGroups,[]}', function () {
      var _this = this;

      var scenarioDistricts = this.get('selectedDistricts'),
          groupDistricts = this.get('group.districts') || [],
          previousDistricts = this.get('group.previousDistricts') || [],
          defaultOption = Ember.Object.create({
        id: '0',
        name: 'N/A'
      }),
          activeDistricts = [],
          inactiveDistricts = [],
          inactivesToAdd = [];

      activeDistricts.push(defaultOption);

      groupDistricts.sortBy('name').forEach(function (district) {
        var isActive = district.get('isActive'),
            modifiedForScenario = district.get('modifiedForScenario');

        // Active if isActive is true OR the district was created for this scenario
        // Modified for scenario if it was edited in settings OR it was created in transition
        if ((isActive === undefined || isActive) && (!modifiedForScenario || modifiedForScenario === _this.get('scenario.id'))) {
          activeDistricts.push(district);
        } else if (scenarioDistricts.indexOf(district) > -1) {
          activeDistricts.push(district);
        } else {
          inactiveDistricts.push(district);
        }
      });

      // There is a chance a district being used by this scenario is not referenced in the districtGroups districts or previousDistricts arrays
      // This probably means this scenario is using transitioned districts or modified districts
      var foundDistrict = scenarioDistricts.find(function (district) {
        return district.get('districtGroups').findBy('id', _this.get('group.id'));
      });
      if (foundDistrict && activeDistricts.indexOf(foundDistrict) === -1) {
        activeDistricts.push(foundDistrict);
      }

      // At this point, we assume all districts in activeDistricts are available for selection OR are already selected for this scenario.
      // We now need to check if any of our inactive districts are districts which were copied and put in activeDistricts.
      // This is so that we can remove duplicate districts and include districts only if they're selected for this scenario.
      activeDistricts.forEach(function (district) {
        var inactive = inactiveDistricts.findBy('id', district.get('copyOfDistrict'));
        if (district.get('copyOfDistrict') && inactive) {
          inactivesToAdd.push(inactive);
        }
      });

      // Push all copies of districts into activeDistricts, at which time we assume there are duplicates in our options
      activeDistricts.pushObjects(inactivesToAdd);

      // This case will be relavent if districts were removed from a district group but are currently selected for this scenario.
      // This will later trigger the flag that this scenario usesInactiveDistricts
      previousDistricts.forEach(function (district) {
        if (scenarioDistricts.indexOf(district) > -1) {
          activeDistricts.push(district);
        }
      });

      // Find all modified districts within activeDistricts
      // This is partially why we pushed certain inactive districts into activeDistricts
      var modifiedDistricts = activeDistricts.filter(function (district) {
        return district.get('adminCreated') === false || district.get('createdInTransition');
      });

      // Hide original districts if scenario is using modified version of that district
      modifiedDistricts.forEach(function (modifiedDistrict) {
        var districtToRemove = activeDistricts.findBy('id', modifiedDistrict.get('copyOfDistrict'));
        if (scenarioDistricts.indexOf(districtToRemove) < 0) {
          // only remove if district isn't currently selected
          activeDistricts.removeObject(districtToRemove);
        }
      });

      return activeDistricts;
    }),

    selectedDistrict: Ember.computed('selectedDistricts.[]', function () {

      var selectedDistricts = this.get('selectedDistricts'),
          options = this.get('options'),
          defaultOption = Ember.Object.create({
        id: null,
        name: 'N/A'
      }),
          selected = void 0;

      if (selectedDistricts) {
        selected = selectedDistricts.find(function (district) {
          return options.findBy('id', district.get('id'));
        });
      }

      return selected ? selected : defaultOption;
    })

  });
});