define('dashboard/utils/pdf-generate', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var pdfGenerate = function pdfGenerate(selector, pdfName) {
    var targetElem = Ember.$(selector);
    var nodesToRecover = [];
    var nodesToRemove = [];

    var svgElem = targetElem.find('svg');
    var imgElem = targetElem.find('.client-logo');

    //replace each svg with a canvas
    svgElem.each(function (index, node) {
      var parentNode = node.parentNode;
      var svg = parentNode.innerHTML;
      var canvas = document.createElement('canvas');

      canvg(canvas, svg);

      nodesToRecover.push({
        parent: parentNode,
        child: node
      });
      parentNode.removeChild(node);

      nodesToRemove.push({
        parent: parentNode,
        child: canvas
      });

      parentNode.appendChild(canvas);
    });

    imgElem.each(function (index, node) {
      var url = getBase64Image(node);
      Ember.$(node).attr('src', url);
    });

    return new Ember.RSVP.Promise(function (resolve, reject) {
      var pdf = new jsPDF();
      pdf.addHTML(targetElem[0], function (x) {
        if (x === null) {
          return reject();
        }

        pdf.save(pdfName + '.pdf');

        //revert back to svg and remove all the canvases that were added
        nodesToRemove.forEach(function (item) {
          item.parent.removeChild(item.child);
        });
        nodesToRecover.forEach(function (item) {
          item.parent.appendChild(item.child);
        });

        resolve();
      });
    });
  };

  function getBase64Image(img) {
    // Create an empty canvas element
    var canvas = document.createElement("canvas");

    // Copy the image contents to the canvas
    var ctx = canvas.getContext("2d");
    console.log(img.width);
    ctx.drawImage(img, 0, 0, img.width, img.height);

    // Get the data-URL formatted image
    // Firefox supports PNG and JPEG. You could check img.src to guess the
    // original format, but be aware the using "image/jpg" will re-encode the image.
    return canvas.toDataURL("image/png");
  }

  exports.default = pdfGenerate;
});