define('dashboard/routes/scenario/report/detailed', ['exports', 'ember-local-storage'], function (exports, _emberLocalStorage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    titleToken: 'Detailed Report',

    controllerName: 'scenario.report',

    sessionData: (0, _emberLocalStorage.storageFor)('session-data'),

    model: function model() {
      return this.modelFor('scenario').scenario;
    },


    actions: {
      didTransition: function didTransition() {
        var innerContent = document.getElementsByClassName('inner-content')[0];
        var scrollY = this.get('sessionData.scrollY') || 0;
        var scenarioCtrl = this.controllerFor('scenario');

        Ember.run.scheduleOnce('afterRender', this, function () {
          innerContent.scroll(0, scrollY);
          scenarioCtrl.set('currentScrollPosition', scrollY);
        });
        this.set('sessionData.reportSelection', 'detailed');
      },
      willTransition: function willTransition() {
        var innerContent = document.getElementsByClassName('inner-content')[0];
        var scrollY = Math.round(innerContent.scrollTop);

        this.set('sessionData.scrollY', scrollY);
      }
    }
  });
});