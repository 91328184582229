define('dashboard/components/tag-label', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    tagName: 'p',

    classNames: ['tag-label'],

    click: function click(e) {
      var tag = this.get('tag');
      this.sendAction('onClick', tag);
      e.stopPropagation();
    }
  });
});