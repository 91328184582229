define('dashboard/routes/authenticated', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {

    session: Ember.inject.service(),

    title: function title(tokens) {
      tokens = Ember.makeArray(tokens);
      tokens.unshift('Impact DashBoard');
      return tokens.reverse().join(' | ');
    },

    model: function model() {
      var token = this.get('session.data.authenticated.token');
      return this.store.queryRecord('user', { token: token });
    },


    // redirect back to login if there is a session but no model found
    afterModel: function afterModel(model) {
      var isAuthenticated = this.get('session.isAuthenticated');

      if (!model && isAuthenticated) {
        this.get('session').invalidate();
        this.transitionTo('login');
      } else {
        this.set('session.currentUser', model);
      }
    },


    actions: {
      error: function error(_error, transition) {
        console.log('error', 'error:', _error, 'transition:', transition);
        if (_error && _error.errors && _error.errors[0] === "Not Authenticated") {
          transition.abort();
          this.transitionTo('logout');
        }
      }
    }

  });
});