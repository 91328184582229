define('dashboard/components/file-upload-table-row', ['exports', 'dashboard/mixins/hover'], function (exports, _hover) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_hover.default, {
    tagName: 'tr',
    classNames: ['cursor', 'project-document-row', 'clickable'],
    notify: Ember.inject.service(),

    canModifyFiles: true,
    editing: false,

    click: function click() {
      this.sendAction('onClick', this.get('document'));
    },


    actions: {
      deleteDocumentHandler: function deleteDocumentHandler() {
        this.sendAction('deleteDocumentHandler', this.get('document'));
      },
      updateName: function updateName() {
        var _this = this;

        var document = this.get('document');
        document.save().then(function () {
          _this.set('editing', false);
        }).catch(function () {
          _this.set('editing', false);
          document.rollbackAttributes();
          _this.get('notify').alert('There was an error.  Double-check the new name for issues or try again later.');
        });
      }
    }

  });
});