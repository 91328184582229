define('dashboard/components/graphs/tif-benefits-graph', ['exports', 'dashboard/components/graphs/net-benefits-graph'], function (exports, _netBenefitsGraph) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _netBenefitsGraph.default.extend({
    drawChart: function drawChart() {

      var data = this.get('data'),
          dataWithoutIncentives = this.get('dataWithoutIncentives'),
          sumData = this.get('sumData'),
          sumDataWithoutIncentives = this.get('sumDataWithoutIncentives'),
          x = this.get('x'),
          yScale = this.get('yScale'),
          canvasMarginTop = this.get('canvasMarginTop'),
          canvasMarginLeft = this.get('canvasMarginLeft'),
          zeroOffset = yScale(0) + canvasMarginTop,
          y = function y(d) {
        return yScale(d.value) + canvasMarginTop;
      },
          h = function h(d) {
        return d.value < 0 ? yScale(d.value) - zeroOffset : zeroOffset - yScale(d.value);
      },
          svg = this.get('svg'),
          line = d3.line().x(function (d) {
        return canvasMarginLeft + x(d.year) + x.bandwidth() / 2;
      }).y(function (d) {
        return y(d);
      }),
          bars = svg.select('g.bars').selectAll('.bar').data(data),
          path = svg.select('g.lines').selectAll('.line').data([dataWithoutIncentives]),
          pathNoIncentives = svg.select('g.lines').selectAll('.lineNoIncentives').data([data]),
          pointsNoIncentives = svg.select('g.points.diamonds').selectAll('.diamond').data(sumData),
          points = svg.select('g.points.circles').selectAll('circle').data(sumDataWithoutIncentives),
          yearsToolTip = this.get('yearsToolTip'),
          calendarYearDisplay = this.get('calendarYearDisplay'),
          startingYear = calendarYearDisplay ? parseInt(this.get('startingCalendarYear')) : 1;

      // Bars Enter
      bars.enter().append('rect').attr('class', function (d) {
        if (d.value < 0 && d.type === 'cost') {
          return 'bar cost';
        }
        if (d.value < 0 && d.type === 'incentive') {
          return 'bar cost incentive';
        }
        return 'bar benefit';
      }).attr('title', function (d) {
        var type = d.value < 0 ? 'Cost' : 'Contribution';
        return type + ': ' + numeral(d.value).format('($0a)');
      }).attr('x', function (d) {
        return canvasMarginLeft + x(d.year);
      }).attr('width', x.bandwidth()).attr('y', function (d) {
        return d.value < 0 ? zeroOffset + canvasMarginTop : y(d);
      }).attr('height', h);

      // Bars Change
      bars.transition().duration(1000).attr('class', function (d) {
        if (d.value < 0 && d.type === 'cost') {
          return 'bar cost';
        }
        if (d.value < 0 && d.type === 'incentive') {
          return 'bar cost incentive';
        }
        return 'bar benefit';
      }).attr('y', function (d) {
        return d.value < 0 ? zeroOffset + canvasMarginTop : y(d);
      }).attr('height', h);

      if (this.get('abatementsAsCosts') && this.get('hasIncentives')) {

        // pointsNoIncentives.remove(); // do this in order for circles to be painted on top of diamonds

        // Benefits less incentives | yellow line
        pathNoIncentives.enter().append('path').attr('class', 'lineNoIncentives').attr('d', line(sumData));

        // Path Change
        pathNoIncentives.transition().duration(1000).attr('d', line(sumData));

        // Benefit less incentive | yellow diamond
        pointsNoIncentives.enter().append("path").attr("class", "diamond").attr("d", d3.symbol().size(85).type(d3.symbolDiamond)).attr("transform", function (d) {
          var translateX = canvasMarginLeft + x(d.year) + x.bandwidth() / 2;
          return "translate(" + translateX + "," + y(d) + ")";
        });

        // Points Change
        pointsNoIncentives.transition().duration(1000).attr("transform", function (d) {
          var translateX = canvasMarginLeft + x(d.year) + x.bandwidth() / 2;
          return "translate(" + translateX + "," + y(d) + ")";
        });

        this.$('.diamond').tipsy({
          gravity: 's',
          html: true,
          title: function title() {
            var d = this.__data__;
            if (d.value >= 1000000 || d.value <= -1000000) {
              return '' + yearsToolTip + d.year + '<br />Net: ' + numeral(d.value).format('($0.00a)');
            }
            return '' + yearsToolTip + d.year + '<br />Net: ' + numeral(d.value).format('($0,0)');
          },
          opacity: 1
        });
      } else {
        pointsNoIncentives.remove();
        pathNoIncentives.remove();
        bars.exit().remove();
      }

      // only draw orange path and points when abatements as costs
      if (this.get('abatementsAsCosts')) {

        // Path with incentives Enter | orange line
        path.enter().append('path').attr('class', 'line').attr('d', line(sumDataWithoutIncentives));

        // Path Change | orange line
        path.transition().duration(1000).attr('d', line(sumDataWithoutIncentives));

        // Points with incentive Enter | orange points
        points.enter().append('circle').attr('class', 'point').attr('r', 5).attr('cx', function (d) {
          return canvasMarginLeft + x(d.year) + x.bandwidth() / 2;
        }).attr('cy', y);

        // Points Change | orange points
        points.transition().duration(1000).attr('cy', y);
      } else {
        path.exit().remove();
        points.exit().remove();
      }

      this.$('rect.bar').tipsy({
        gravity: 's',
        html: true,
        title: function title() {
          var d = this.__data__,
              type = d.value < 0 ? d.type.capitalize() : 'Contribution',
              value = d.value < 0 ? d.origValue : d.value,
              year = d.year + startingYear - 1;
          if (d.value >= 1000000 || d.value <= -1000000) {
            return '' + yearsToolTip + year + '<br />' + type + ': ' + numeral(value).format('($0.00a)');
          }
          return '' + yearsToolTip + year + '<br />' + type + ': ' + numeral(value).format('($0,0)');
        },
        opacity: 1
      });

      this.$('circle').tipsy({
        gravity: 's',
        html: true,
        title: function title() {
          var d = this.__data__,
              year = d.year + startingYear - 1;
          if (d.value >= 1000000 || d.value <= -1000000) {
            return '' + yearsToolTip + year + '<br />Net: ' + numeral(d.value).format('($0.00a)');
          }
          return '' + yearsToolTip + year + '<br />Net: ' + numeral(d.value).format('($0,0)');
        },
        opacity: 1
      });
    }
  });
});