define('dashboard/routes/scenario/index', ['exports', 'dashboard/mixins/reset-scroll-position'], function (exports, _resetScrollPosition) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_resetScrollPosition.default, {
    controllerName: 'scenario',

    model: function model() {

      // get primary & (maybe) other dependent tax districts (not always needed) so these can be selected in dependent district using scenarios
      var scenario = this.modelFor('scenario').scenario;
      var dependentTaxDistrictSetId = this.modelFor('scenario').dependentTaxDistrictSet.get('firstObject.id');
      var primaryDependentTaxDistrictsPromise = this.store.query('taxDistrict', { dependentTaxDistrictSet: dependentTaxDistrictSetId, isPrimary: true });
      var dependentTaxDistrictsPromise = void 0;

      if (scenario.get('primaryDependentDistrict.id')) {
        dependentTaxDistrictsPromise = this.store.query('taxDistrict', { primaryDependency: scenario.getRightPrimaryDependentDistrictId(), dependentTaxDistrictSet: dependentTaxDistrictSetId, isPrimary: false });
      }

      return Ember.RSVP.hash({
        scenario: scenario,
        primaryDependentTaxDistricts: primaryDependentTaxDistrictsPromise,
        dependentTaxDistricts: dependentTaxDistrictsPromise
      });
    },
    setupController: function setupController(controller, models) {

      var scenario = models.scenario;

      this._super(controller, scenario);

      controller.setProperties({
        selectedSchedule: scenario.getPrimarySchedule(),
        primaryDependentTaxDistricts: models.primaryDependentTaxDistricts,
        dependentTaxDistricts: models.dependentTaxDistricts
      });

      controller.send('updateUnboundInputs');

      this.setDefaultDistricts(scenario);
    },


    //if all district groups has no districts selected on them then assign the first district for each district group
    setDefaultDistricts: function setDefaultDistricts(scenario) {
      var _this = this;

      var parentModel = this.modelFor('scenario'),
          user = this.modelFor('authenticated'),
          scenarioController = this.controllerFor('scenario');

      var districts = scenario.get('districts'),
          districtGroups = parentModel.client.get('districtGroups');

      var districtsLength = districts.get('length'),
          districtGroupsLength = districtGroups.get('length');

      var clientDistrictConfig = parentModel.client.get('districtConfigType'),
          clientUsesStandardConfig = clientDistrictConfig === 'standard' || !clientDistrictConfig;

      // don't automatically assign districts if we have any districts
      if (districtsLength > 0 || districtGroupsLength === 0 || user.get('isDemo') || !clientUsesStandardConfig) {
        return;
      }

      // loop through sorted district-groups and assign first district from each group for this scenario
      var promises = [];
      districtGroups = districtGroups.sortBy('groupOrder');
      districtGroups.forEach(function (districtGroup, index) {
        var districtToAdd = districtGroup.get('filteredDistricts').sortBy('name').get('firstObject');
        if (districtToAdd) {
          // do extra for primary district
          if (index === 0) {
            // set summary selected district while we're here
            scenario.set('summarySelectedDistrict', districtToAdd.get('id'));

            _this.assignMultipliers(scenario, districtToAdd);
          }
          districtToAdd.set('parentGroup', districtGroup);
          promises.push(scenarioController.updateScenarioDistrict(districtToAdd, districtGroup));
        }
      });

      return Ember.RSVP.all(promises).then(function () {
        // return scenario.save(); // Sending a PUT to scenario too soon after districts are assigned breaks things
      });
    },


    // internal - finds corresponding multiplier models to associate with scenario if we have multiplier codes
    assignMultipliers: function assignMultipliers(scenario, district) {
      // associate multiplier if scenario has code but no multiplier associated (ie came from data request)
      if (scenario.get('multiplierCode') && !scenario.get('multiplier.content')) {
        var multipliers = this.store.peekAll('multiplier');
        var multiplier = multipliers.find(function (multiplier) {
          return district.get('multiplierSet.id') === multiplier.get('multiplierSet') && scenario.get('multiplierCode') === multiplier.get('code');
        });
        if (multiplier) {
          scenario.setProperties({
            multiplier: multiplier,
            employmentMultiplier: multiplier.get('employment'),
            earningsMultiplier: multiplier.get('earnings')
          });
        }
      }

      // do the same as above for current operations multiplier
      if (scenario.get('currentMultiplierCode') && !scenario.get('currentMultiplier.content')) {
        var _multipliers = this.store.peekAll('multiplier');
        var currentMultiplier = _multipliers.find(function (multiplier) {
          return district.get('multiplierSet.id') === multiplier.get('multiplierSet') && scenario.get('currentMultiplierCode') === multiplier.get('code');
        });
        if (currentMultiplier) {
          scenario.setProperties({
            currentMultiplier: currentMultiplier,
            currentEmploymentMultiplier: currentMultiplier.get('employment'),
            currentEarningsMultiplier: currentMultiplier.get('earnings')
          });
        }
      }
    }
  });
});