define('dashboard/objects/fi-tax/direct-cost-revenue', ['exports', 'dashboard/objects/fi-tax/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    project: Ember.computed('directCostRevenue', 'doesApply', 'type', function () {
      var directCostRevenue = this.get('directCostRevenue') || [],
          doesApply = this.get('doesApply');

      if (!doesApply) {
        return 0;
      }

      return directCostRevenue.reduce(function (sum, num) {
        return sum + num;
      }, 0);
    })
  });
});