define("dashboard/utils/appreciated-annual-array", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = appreciatedAnnualArray;
  function appreciatedAnnualArray(sources, revenue, annualIncrease, doesApply) {
    if (!doesApply) {
      return sources.map(function () {
        return 0;
      });
    }

    return sources.map(function (source, index) {
      return source * revenue * Math.pow(1 + annualIncrease, index);
    });
  }
});