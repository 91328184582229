define('dashboard/components/modals/tif-prefill-modal', ['exports', 'dashboard/components/modals/modal-confirm', 'dashboard/mixins/tif-district-actions'], function (exports, _modalConfirm, _tifDistrictActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _modalConfirm.default.extend(_tifDistrictActions.default, {

    tifInputsDisabled: true,

    // we're filering in addition to what already happens in tif-district-actions mixin, which filters out deleted tifs
    filteredTifDistricts: Ember.computed.filterBy('tifDistricts', 'forScenario', false),

    actions: {

      // override modal-confirm action
      cancel: function cancel() {
        var _this = this;

        if (this.get('cancelAction')) {
          this.cancelAction();
        }

        this.set('animationOut', true);
        setTimeout(function () {
          _this.setProperties({
            isHidden: true,
            animationOut: false,
            selectedTifDistrict: null,
            selectedTifDistrictId: null
          });
        }, 200);
      },
      submit: function submit() {
        var _this2 = this;

        var selected = this.get('selectedTifDistrict');
        this.usePrefills(selected.get('name'), selected).then(function () /*results*/{
          _this2.setProperties({
            isHidden: true,
            selectedTifDistrict: null,
            selectedTifDistrictId: null
          });
        }).catch(function (error) {
          console.log('TIF Prefill error: ', error);
        });
      }
    }
  });
});