define('dashboard/routes/scenario/public-support', ['exports', 'dashboard/mixins/reset-scroll-position'], function (exports, _resetScrollPosition) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_resetScrollPosition.default, {

    titleToken: 'Public Support',

    model: function model() {
      return this.modelFor('scenario').scenario;
    },
    beforeModel: function beforeModel() {
      var _this = this;

      var currentUser = this.modelFor('layout'),
          scenarioModel = this.modelFor('scenario'),
          client = scenarioModel.client,
          scenario = scenarioModel.scenario,
          groups = client.get('districtGroups'),
          schedules = scenario.get('incentiveSchedules');

      if (currentUser.get('isDemo')) {
        return true;
      }

      var savedSchedulesPromises = [];
      groups.forEach(function (group) {
        var schedule = void 0;

        schedule = schedules.find(function (schedule) {
          return schedule.get('districtGroup.id') === group.get('id');
        });

        if (!schedule) {
          savedSchedulesPromises.pushObject(_this.createMissingSchedule.apply(_this, [scenario, group]));
        } else if (_this.scheduleHasDifferentStatus(schedule, scenario)) {
          schedule.toggleProperty('isActive');
          savedSchedulesPromises.pushObject(schedule.save());
        }
      });

      return Ember.RSVP.all(savedSchedulesPromises);
    },
    createMissingSchedule: function createMissingSchedule(scenario, group) {
      var district = scenario.getDistrictForGroup(group);
      var schedule = this.store.createRecord('incentiveSchedule', {
        districtGroup: group,
        isActive: !!district
      });
      scenario.get('incentiveSchedules').pushObject(schedule);
      return schedule.save();
    },
    scheduleHasDifferentStatus: function scheduleHasDifferentStatus(schedule, scenario) {
      var hasDistrict = !!scenario.getDistrictForSchedule(schedule);
      return schedule.get('isActive') !== hasDistrict;
    },


    actions: {
      goToCommunityIncentive: function goToCommunityIncentive() {
        var schedule_id = this.model().get('communityIncentiveSchedule.id');
        this.transitionTo('scenario.public-support.group', schedule_id);
      }
    }
  });
});