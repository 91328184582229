define('dashboard/objects/ei-av-buildings', ['exports', 'dashboard/utils/multiply-each', 'dashboard/utils/appreciation-schedule', 'dashboard/utils/percent-each'], function (exports, _multiplyEach, _appreciationSchedule, _percentEach) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var EiAvBuildingsObject = Ember.Object.extend({

    valueAddedToTaxRoll: Ember.computed('input', 'multiplier', function () {
      var input = this.get('input'),
          multiplier = this.get('multiplier');

      return (0, _multiplyEach.default)(input, multiplier);
    }),

    output: Ember.computed('valueAddedToTaxRoll', 'appreciation', function () {
      var input = this.get('valueAddedToTaxRoll'),
          appreciation = (0, _percentEach.percentify)(this.get('appreciation'));

      return (0, _appreciationSchedule.default)(input, appreciation);
    })

  });

  exports.default = EiAvBuildingsObject;
});