define('dashboard/components/multiplier-power-select', ['exports', 'ember-power-select/components/power-select', 'dashboard/fixtures/multiplier-list'], function (exports, _powerSelect, _multiplierList) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _powerSelect.default.extend({
    store: Ember.inject.service(),
    renderInPlace: true,
    minimumInputLength: 3,
    attributeBindings: ['tabindex'],
    tagName: 'span',
    searchMessage: "Identify the Project's primary North American Industry Classification System (NAICS) Code",

    isSearchTermToShort: Ember.computed.lt('searchTerm.length', 3),

    noMatchesMessage: Ember.computed('isSearchTermToShort', function () {
      return this.get('isSearchTermToShort') ? this.get('searchMessage') : 'No industry matching that name...';
    }),

    placeholder: Ember.computed('usesCustomMultipliers', function () {
      return this.get('usesCustomMultipliers') ? 'Select Industry (Custom Multipliers Chosen)' : 'Select Industry';
    }),

    init: function init() {
      this._super.apply(this, arguments);

      function search(code) {
        this.set('code', code); // why?
        if (this.get('isSearchTermToShort')) {
          return [];
        }

        // use generalized multiplier list when no Multiplier Set
        if (!this.get('multiplierSetId')) {
          var output = _multiplierList.default.filter(function (multiplier) {
            return multiplier.code.match(new RegExp(code, "i"));
          });
          return output;
        }

        return this.get('store').query('multiplier', {
          multiplierSet: this.get('multiplierSetId'),
          code: code
        });
      }

      this.set('search', search.bind(this));
    }
  });
});