define("dashboard/utils/validations", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var emailValidation = exports.emailValidation = function emailValidation(email) {
    var regex = /^\w+([.-\w+])*@\w+([.-\w+])*(\.\w{2,24})+$/;
    return regex.test(email);
  };

  // TODO: if we expand on this validation system
  // Make it be able to return and display error messages
});