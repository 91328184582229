define('dashboard/utils/aggregate-props', ['exports', 'dashboard/utils/sum-many-arrays'], function (exports, _sumManyArrays) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getAggregateArrayProperty = exports.getAggregateProperty = undefined;


  /**
   * Aggregate Props
   *
   * Takes array of engines and single impact-engine property and returns sum of all properties in passed engines
   *
   * @param  {Array} engines Array of Impact Engine objects
   * @param  {String} prop Property belonging to Impact Engine
   * @return {Number} Sum of passed property from all passed engines
   *
   * @method  getAggregateProperty
   */
  var getAggregateProperty = function getAggregateProperty(engines, prop) {
    return engines.reduce(function (total, engine) {
      return total + engine.get(prop);
    }, 0);
  };

  /**
   * Aggregate Array Props
   *
   * Takes array of engines and single impact-engine property of type array and returns an array of summed properties from each engine
   *
   * @param  {Array} engines Array of Impact Engine objects
   * @param  {String} prop Property belonging to Impact Engine of type Array
   * @return {Array} annual: New array of summed prop values
   *
   * @method  getAggregateProperty
   */
  var getAggregateArrayProperty = function getAggregateArrayProperty(engines, prop) {
    return (0, _sumManyArrays.default)(engines.mapBy(prop) || []);
  };

  exports.getAggregateProperty = getAggregateProperty;
  exports.getAggregateArrayProperty = getAggregateArrayProperty;
  exports.default = getAggregateProperty;
});