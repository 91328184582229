define('dashboard/routes/dependent-tax-district-set', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    titleToken: function titleToken(models) {
      return [models.dependentTaxDistrictSet.get('name')];
    },

    model: function model(params) {

      // @TODO get counts of parent and dependent districts to display
      return Ember.RSVP.hash({
        dependentTaxDistrictSet: this.store.findRecord('dependentTaxDistrictSet', params.dependent_tax_district_set_id),
        primaryTaxDistricts: this.store.query('taxDistrict', { dependentTaxDistrictSet: params.dependent_tax_district_set_id, isPrimary: true, limit: 100, skip: 0 }).then(function (districts) {
          return districts.toArray();
        }),
        dependentTaxDistricts: this.store.query('taxDistrict', { dependentTaxDistrictSet: params.dependent_tax_district_set_id, isPrimary: false, limit: 100, skip: 0 }).then(function (districts) {
          return districts.toArray();
        })
      });
    },
    setupController: function setupController(controller, models) {
      controller.setProperties({
        dependentTaxDistrictSet: models.dependentTaxDistrictSet,
        primaryTaxDistricts: models.primaryTaxDistricts,
        dependentTaxDistricts: models.dependentTaxDistricts
      });

      // this is done for now to use the sorting mixin but can change when we separate the primary and dependent district tables into individual components which can each use the sorting mixin independently
      this._super(controller, models.primaryTaxDistricts);
    }
  });
});