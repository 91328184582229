define('dashboard/components/data-form-table-row', ['exports', 'dashboard/mixins/hover'], function (exports, _hover) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_hover.default, {
    tagName: 'tr',
    classNames: ['cursor', 'clickable', 'data-form-table-row', 'd-block'],
    classNameBindings: ['active', 'active:bg-light-accent'],

    init: function init() {
      this._super.apply(this, arguments);
      this.set('dataRequestMoveOptions', [Ember.Object.create({
        label: 'Move to Inbox',
        action: 'disassociateFromProject'
      }), Ember.Object.create({
        label: 'Create New Project',
        action: 'openCreateProjectModal'
      }), Ember.Object.create({
        label: 'Assign to Existing Project',
        action: 'openMoveProjectsModal'
      })]);
    },


    active: Ember.computed('currentlyActiveScenario.id', function () {
      return this.get('currentlyActiveScenario.id') == this.get('scenario.id');
    }),

    click: function click() {
      this.transitionToPreview();
    },
    mouseEnter: function mouseEnter() {
      this.set("hover", true);
    },
    mouseLeave: function mouseLeave() {
      this.set("hover", false);
    },


    actions: {
      makeActive: function makeActive() {
        this.makeActive();
      },
      createScenario: function createScenario() {
        this.createScenario();
      },
      disassociateFromProject: function disassociateFromProject() {
        this.disassociateFromProject();
      },
      openMoveProjectsModal: function openMoveProjectsModal() {
        this.openMoveProjectsModal();
      },
      openCreateProjectModal: function openCreateProjectModal() {
        this.openCreateProjectModal();
      },
      openReviseRequestModal: function openReviseRequestModal() {
        this.openReviseDataFormModal();
      },
      popoverActionHandler: function popoverActionHandler(action) {
        this.send(action);
      }
    }
  });
});