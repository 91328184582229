define('dashboard/objects/fi-tax/building-fees', ['exports', 'dashboard/objects/fi-tax/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    project: Ember.computed('directRevenue', 'doesApply', function () {
      var directRevenue = this.get('directRevenue') || [],
          doesApply = this.get('doesApply');

      if (doesApply) {
        return directRevenue.reduce(function (sum, num) {
          return sum + num;
        }, 0);
      } else {
        return 0;
      }
    })
  });
});