define('dashboard/components/scenario-input-complex-cascading', ['exports', 'dashboard/components/scenario-input-base', 'dashboard/objects/column', 'dashboard/utils/make-inputs', 'dashboard/utils/make-increase', 'dashboard/utils/not-null', 'dashboard/utils/percent-increase'], function (exports, _scenarioInputBase, _column, _makeInputs, _makeIncrease, _notNull, _percentIncrease) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _scenarioInputBase.default.extend({

    // the computed key needs one specific property from assumption, otherwise it won't recalculate after loading
    // it also must have one with a default, otherwise those without it set will not see it reload
    // update to above - this binding is silly and yearlyInputs are not updating everytime they should
    // update cont - added a notifyPropertyChange to updateData below so that yearly data always updates when increase percent does
    // this behavior was specifically discovered while looking at taxable sales and purchases which use this component
    yearlyInputs: Ember.computed('scenario.client.assumption.studentsPerHousehold', 'years', function () {
      var _this = this;

      var columnData = this.get('columns'),
          years = this.get('years'),
          columns = [];

      columnData.forEach(function (data) {
        if (_this.get('requestTemplate.' + data.name) || _this.get('showAll')) {
          var rows = _this.get('scenario.' + data.dataProperty);
          var isNull = !(0, _notNull.default)(rows);

          if (isNull && data.assumptionProperty) {
            var assumption = _this.get('scenario.client.assumption.' + data.assumptionProperty);
            if (assumption) {
              rows = (0, _percentIncrease.default)(assumption, _this.get('scenario.' + data.dataIncreaseProperty), _this.get('years'));
            }
          }

          var column = _column.default.create({
            title: data.label,
            columnName: 'scenario.' + data.dataProperty,
            name: data.name,
            content: (0, _makeInputs.default)(rows, years),
            increase: (0, _makeIncrease.default)('scenario.' + data.dataIncreaseProperty, 'Percent Annual Increase', _this.get('scenario.' + data.dataIncreaseProperty), 'salaryIncreaseDisabled'),
            isCascading: true,
            inputType: data.maskType || _this.get('maskType'),
            tooltip: data.tooltip
          });
          columns.push(column);
        }
      });

      return columns;
    }),

    unboundYearlyInputs: Ember.computed(function () {
      return this.get('yearlyInputs');
    }),

    actions: {
      updateData: function updateData(column) {
        var columnName = column.get('columnName');
        var data = column.content.mapBy('value');
        this.set(columnName, data);
        this.notifyPropertyChange('yearlyInputs');
        this.save();
      },
      saveIncrease: function saveIncrease(column) {
        var columnPath = column.increase.name;
        var value = column.increase.value;

        this.set(columnPath, value);
        this.save();
      },
      nextSectionHandler: function nextSectionHandler() {
        var sortedProp = this.get('sortedProp') || 'sortedSections';
        this.sendAction('nextSectionHandler', this.get('nextId'), sortedProp);
        Ember.$('html, body').animate({
          scrollTop: this.$().offset().top - 300
        }, 300);
      }
    }
  });
});