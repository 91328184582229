define('dashboard/models/dependent-tax-district-set', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    name: (0, _attr.default)('string'),
    primaryGroupName: (0, _attr.default)('string'),
    state: (0, _attr.default)('string'),
    created: (0, _attr.default)('date')
  });
});