define('dashboard/components/tool-tipster', ['exports', 'ember-cli-tooltipster/components/tool-tipster'], function (exports, _toolTipster) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _toolTipster.default.extend({
    theme: 'tooltipster-borderless',
    content: '',
    delay: 0
  });
});