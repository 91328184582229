define('dashboard/objects/fi-tax/municipal-service-cost', ['exports', 'dashboard/utils/percent-each', 'dashboard/utils/sum-arrays', 'dashboard/utils/appreciated-annual-array', 'dashboard/objects/fi-tax/base'], function (exports, _percentEach, _sumArrays, _appreciatedAnnualArray, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    costPerNewWorker: Ember.computed('eiDirectJobsCumulative', 'workerCost', 'annualIncreaseInCost', 'doesApply', function () {
      var eiDirectJobsCumulative = this.get('eiDirectJobsCumulative') || [],
          workerCost = this.get('workerCost') || 0,
          annualIncreaseInCost = (0, _percentEach.percentify)(this.get('annualIncreaseInCost')) || 0,
          doesApply = this.get('doesApply');

      workerCost = workerCost < 0 ? workerCost : workerCost * -1;

      return (0, _appreciatedAnnualArray.default)(eiDirectJobsCumulative, workerCost, annualIncreaseInCost, doesApply);
    }),

    project: Ember.computed.sum('costPerNewWorker'),

    costPerNewHousehold: Ember.computed('eiTotalWorkersMovingCumulative', 'costPerHousehold', 'annualIncreaseInCost', 'doesApply', function () {
      var eiTotalWorkersMovingCumulative = this.get('eiTotalWorkersMovingCumulative') || [],
          costPerHousehold = this.get('costPerHousehold') || 0,
          annualIncreaseInHouseholdCost = (0, _percentEach.percentify)(this.get('annualIncreaseInCost')) || 0,
          doesApply = this.get('doesApply');

      costPerHousehold = costPerHousehold < 0 ? costPerHousehold : costPerHousehold * -1;

      return (0, _appreciatedAnnualArray.default)(eiTotalWorkersMovingCumulative, costPerHousehold, annualIncreaseInHouseholdCost, doesApply);
    }),

    workers: Ember.computed.sum('costPerNewHousehold'),

    totalAnnual: Ember.computed('costPerNewWorker', 'costPerNewHousehold', function () {
      return (0, _sumArrays.default)(this.get('costPerNewWorker'), this.get('costPerNewHousehold'));
    })
  });
});