define('dashboard/routes/logout', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    session: Ember.inject.service(),

    beforeModel: function beforeModel() {

      var session = this.get('session');

      if (session.get('isAuthenticated')) {
        this.get('session').invalidate();
      } else {
        this.transitionTo('login');
      }
    }
  });
});