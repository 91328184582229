define('dashboard/routes/projects', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    notify: Ember.inject.service(),
    adapterErrorNotifier: Ember.inject.service(),

    beforeModel: function beforeModel(transition) {
      var urlClientId = transition.params.projects.client_id;
      if (!this.userCanAccessProjects(urlClientId)) {
        this.transitionTo('projects', this.modelFor('authenticated').get('client.id'));
      }
    },
    model: function model(params) {
      var clientId = params.client_id;
      var clientPromise = this.store.findRecord('client', clientId);
      var projectsPromise = this.store.query('project', { client: clientId }).then(function (projects) {
        return projects.toArray();
      }); // we need to do projects.toArray(), otherwise we won't be able to `pushObject`
      var tagsPromise = this.store.query('tag', { client: clientId }).then(function (tags) {
        return tags.toArray();
      });

      return Ember.RSVP.hash({
        projects: projectsPromise,
        client: clientPromise,
        tags: tagsPromise,
        user: this.modelFor('authenticated')
      });
    },
    userCanAccessProjects: function userCanAccessProjects(paramsClientId) {
      var user = this.modelFor('authenticated');
      if (user.get('isSuperAdmin')) return true;

      var userClientId = user.get('client.id');
      return userClientId === paramsClientId;
    }
  });
});