define('dashboard/components/hide-after-nth-if', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    attributeBindings: ['data-test'],

    classNameBindings: ['isShown::hide'],
    classNames: ['table-row'],

    isShown: Ember.computed('index', 'condition', 'n', function () {
      var index = this.get('index'),
          condition = this.get('condition'),
          n = this.get('n') || 5; // this is where data form defaults were coming from

      return index < n || condition;
    })

  });
});