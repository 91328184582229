define('dashboard/components/graphs/types-count-pie-chart', ['exports', 'dashboard/components/graphs/pie-chart', 'lodash/array', 'dashboard/mixins/color-scheme'], function (exports, _pieChart, _array2, _colorScheme) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _pieChart.default.extend(_colorScheme.default, {

    types: Ember.computed('objects.@each', 'objects', function () {
      var property = this.get('subProperty') ? this.get('property') + '.' + this.get('subProperty') : this.get('property');
      return this.get('objects').mapBy(property);
    }),

    uniqueTypes: Ember.computed('types', function () {
      return _array2.default.uniq(this.get('types')).without(undefined, null, '', false);
    }),

    uniqueTypesLength: Ember.computed.alias('uniqueTypes.length'),

    typeCounts: Ember.computed('types', function () {

      var counts = {};
      this.get('types').forEach(function (type) {
        counts[type] = (counts[type] || 0) + 1;
      });

      // we want undefined to be last so we can set specific color
      var notSelectedCount = counts['undefined'];
      if (notSelectedCount) {
        delete counts.undefined;
        counts['undefined'] = notSelectedCount;
      }

      return counts;
    }),

    chartData: Ember.computed('typeCounts', 'staticColors', function () {
      var typeCounts = this.get('typeCounts');
      var labels = Object.keys(typeCounts);
      var colors = this.get('staticColors');
      return {
        labels: labels,
        datasets: [{
          /* TODO: Make colors dynamic range*/
          backgroundColor: colors,
          data: labels.map(function (l) {
            return typeCounts[l];
          }),
          title: labels.map(function (l, i) {
            return labels[i] === "undefined" ? "Not Selected" : labels[i];
          }),
          borderWidth: 1
        }]
      };
    }),

    themeColorScale: Ember.computed('color', function () {
      var selectedColor = this.get('color') || 'Blue';
      // get all this color's variable hex codes from ColorSchemeMixin
      return this.get(selectedColor).scale;
    }),

    staticColors: Ember.computed('themeColorScale', 'uniqueTypesLength', function () {
      var themeColorScale = this.get('themeColorScale');
      var uniqueTypesLength = this.get('uniqueTypesLength');
      var colorsLength = themeColorScale.length;
      var colors = [];
      var j = 0;
      for (var i = 0; i < uniqueTypesLength; i++) {
        // reset color array if we reach the end
        if (j === colorsLength - 1) {
          colors.push(themeColorScale[j]);
          j = 0;
          continue;
        }
        colors.push(themeColorScale[j]);
        j++;
      }
      // insert gray color for "Not Selected" (should be last index)
      colors.splice(this.get('uniqueTypesLength'), 0, "#DDDDDD");
      return colors;
    }),

    // Placeholder properties
    placeHolderWhenEmpty: false,

    showPlaceholder: Ember.computed('placeHolderWhenEmpty', 'objects', function () {
      return this.get('placeHolderWhenEmpty') && !this.get('objects.length');
    })

  });
});