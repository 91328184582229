define('dashboard/components/modals/tif-edit-modal', ['exports', 'dashboard/components/modals/modal-confirm', 'dashboard/mixins/tif-district-actions'], function (exports, _modalConfirm, _tifDistrictActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _modalConfirm.default.extend(_tifDistrictActions.default, {

    actions: {
      cancel: function cancel() {
        var _this = this;

        this.onClose().then(function (result) {
          if (!result.error) {
            _this.send('slideOut');
          }
        });
      }
    }
  });
});