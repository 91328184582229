define('dashboard/controllers/user', ['exports', 'dashboard/fixtures/states', 'dashboard/mixins/sorting', 'dashboard/mixins/tif-district-actions', 'dashboard/mixins/tagging', 'dashboard/mixins/color-scheme', 'ember-local-storage', 'lodash/collection', 'dashboard/utils/ordered-search'], function (exports, _states, _sorting, _tifDistrictActions, _tagging, _colorScheme, _emberLocalStorage, _collection2, _orderedSearch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_sorting.default, _tifDistrictActions.default, _tagging.default, _colorScheme.default, {
    init: function init() {
      this._super.apply(this, arguments);

      this.set('tifActionOptions', { adminCreated: false });
      this.set('s3UserFiles', []);
    },


    sessionData: (0, _emberLocalStorage.storageFor)('session-data'),

    ajax: Ember.inject.service(),
    notify: Ember.inject.service(),

    stateOptions: _states.default,
    stateSearch: _orderedSearch.default,

    client: Ember.computed.alias('model.client'),
    user: Ember.computed.alias('model.user'),
    tifDistricts: Ember.computed.alias('model.tifDistricts'),

    emailRequestModalHidden: true,
    editing: false,
    imgIsLoaded: false,
    newEmail: '',
    reasonForChange: '',

    selectedTifDistrict: null,
    editingTifModalHidden: true,

    dropdown: null,

    previousTransition: null,

    isUnsavedChangesModalHidden: true,

    postPolicyRoute: Ember.computed('user.id', function () {
      return '/users/' + this.get('user.id') + '/misc';
    }),

    clientName: Ember.computed('client.name', function () {
      var name = this.get('client.name');

      if (this.get('user.isSuperAdmin')) return 'Admin';
      if (name) return name;
      return 'My Account';
    }),

    readOnlyAccess: Ember.computed('client.readOnlyAccess', 'user.isSuperAdmin', function () {
      return this.get('client.readOnlyAccess') && !this.get('user.isSuperAdmin');
    }),

    filteredTifDistricts: Ember.computed.filterBy('tifDistricts', 'forScenario', false),

    lastVisited: Ember.computed.alias('sessionData.lastVisited'),

    previewImage: function previewImage(file) {
      var reader = new FileReader();

      reader.onload = function (e) {
        Ember.$('#preview-image').attr('src', e.target.result);
      };

      reader.readAsDataURL(file);
    },


    actions: {

      // setClientState(state) {
      //   this.set('client.state', state);
      //   this.send('updateClient');
      // },

      notifyInfo: function notifyInfo() {
        this.get('notify').info('This is an Info notification.', {
          closeAfter: null
        });
      },
      notifyWarning: function notifyWarning() {
        this.get('notify').alert('This is an Alert notification.', {
          closeAfter: null
        });
      },
      setDropdown: function setDropdown(dropdown) {
        this.set('dropdown', dropdown);
      },
      receiveFile: function receiveFile(file) {
        this.previewImage(file);
      },
      imgLoaded: function imgLoaded(evt) {
        var imgWrapper = evt.currentTarget.parentNode;

        imgWrapper.className += imgWrapper.className ? ' loaded' : 'loaded';
        this.set('imgIsLoaded', true);
      },
      resetPassword: function resetPassword() {
        var _this = this;

        var email = this.get('user.email');

        this.get('ajax').post('resetPassword', {
          data: JSON.stringify({ email: email, reason: 'passwordReset' }),
          dataType: 'json',
          contentType: "application/json; charset=utf-8",
          processData: false
        }).then(function () {
          _this.get('notify').info('Password Reset Notification sent. Please check your email.');
        }).catch(function (reason) {
          console.log('resetPassword error ', reason);
          _this.get('notify').alert('Something went wrong. Please try again later.');
        });
      },
      showUpdateEmailModal: function showUpdateEmailModal() {
        this.set('emailRequestModalHidden', false);
      },
      notifyUploadError: function notifyUploadError() {
        this.get('notify').alert('An error occured during the upload');
      },
      updateClient: function updateClient() {
        this.get('client').save();
      },
      saveState: function saveState(state) {
        this.set('client.state', state);
        this.send('updateClient');
      },
      updateUser: function updateUser() {
        var user = this.get('user');
        user.save().catch(function (error) {
          userUpdateErrorHandler(error, user);
        });
      },
      sendEmailToAdmin: function sendEmailToAdmin() {
        var _this2 = this;

        var newEmail = this.get('newEmail'),
            reason = this.get('reasonForChange'),
            name = this.get('client.name'),
            clientId = this.get('client.id');

        if (!newEmail) {
          this.get('notify').alert('Email Required');
          return;
        }

        if (!reason) {
          this.get('notify').alert('Reason For Change Requrired');
          return;
        }

        this.get('ajax').post('/users/updateEmail', {
          data: JSON.stringify({ newEmail: newEmail, reason: reason, name: name, clientId: clientId }),
          contentType: 'application/json'
        }).then(function () /*response*/{
          _this2.get('notify').info('Request sent.');
          _this2.set('emailRequestModalHidden', true);
          _this2.setProperties({
            newEmail: '',
            reasonForChange: ''
          });
        }).catch(function (error) {
          console.log('Email update error', error);
          _this2.get('notify').alert('Something went wrong with this request.');
        });
      },


      // triggered after creation or copying of TIF
      // overwrites action in mixin
      selectTifDistrict: function selectTifDistrict(selectedTifDistrictId) {
        var tifDistrict = this.get('filteredTifDistricts').findBy('id', selectedTifDistrictId);
        this.setProperties({
          selectedTifDistrict: tifDistrict,
          editingTifModalHidden: false
        });
      },
      updateAssumption: function updateAssumption(propertyName, newValue) {
        var assumption = this.get('client.assumption.content');
        assumption.save();

        this.get('ajax').post('/clients/' + this.get('client.id') + '/update-scenario-assumptions', {
          data: JSON.stringify({ propertyName: propertyName, newValue: newValue }),
          dataType: 'json',
          contentType: "application/json; charset=utf-8"
        });
      },
      rollbackUserChanges: function rollbackUserChanges() {
        var transition = this.get('previousTransition');

        this.get('user').rollbackAttributes();
        this.send('hideUnsavedChangesModal');

        transition.retry();
      },
      hideUnsavedChangesModal: function hideUnsavedChangesModal() {
        this.setProperties({ previousTransition: null, isUnsavedChangesModalHidden: true });
      }
    }
  });


  // Check this
  function userUpdateErrorHandler(e, user) {
    var DUP_KEY = 11000,
        err = e.responseJSON.error;

    if (DUP_KEY === err.code) {
      if (_collection2.default.includes(err.err, 'username')) {
        this.get('notify').alert('The username provided is in use by another client.');
      } else {
        this.get('notify').alert('The email address provided is in use by another client.');
      }
      user.rollback();
    } else {
      this.get('notify').alert('An error occurred while trying to save the user.');
    }
  }
});