define('dashboard/models/tag', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    client: (0, _relationships.belongsTo)('client'),

    name: (0, _attr.default)('string'),
    category: (0, _attr.default)('string', { defaultValue: 'tag' }) // other possibilities: projectType and industry
  });
});