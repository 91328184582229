define('dashboard/components/one-way-email', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    error: '',
    actions: {
      update: function update(value) {
        this.set('value', value);
      },
      save: function save() {
        var regex = /^\w+([.-\w+])*@\w+([.-\w+])*(\.\w{2,24})+$/;
        var value = this.get('value');
        if (regex.test(value)) {
          this.set('error', '');
          this.update(value);
        } else {
          this.set('error', 'Please use a valid email address.');
        }
      }
    }
  });
});