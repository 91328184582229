define('dashboard/routes/project', ['exports', 'ember-local-storage'], function (exports, _emberLocalStorage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    sessionData: (0, _emberLocalStorage.storageFor)('session-data'),

    titleToken: function titleToken(model) {
      return model.project.get('title');
    },

    model: function model(params) {
      var clientId = this.modelFor('projects').client.get('id');
      var tagsPromise = this.store.query('tag', { client: clientId }).then(function (tags) {
        return tags.toArray();
      });
      var projectPromise = this.store.findRecord('project', params.project_id);

      return Ember.RSVP.hash({
        tags: tagsPromise,
        project: projectPromise
      });
    },
    afterModel: function afterModel(model) {
      /**
       * Force redirect if user attempts to access a
       * project they should not have access to.
       */
      if (!this.userCanAccessProject(model.project)) {
        this.transitionTo('projects', this.modelFor('authenticated').get('client.id'));
      }

      this.trackRecentProjects(model.project);
    },
    userCanAccessProject: function userCanAccessProject(project) {
      var user = this.modelFor('authenticated');
      if (user.get('isSuperAdmin')) return true;

      var projectClientId = project.get('client.id');
      var userClientId = user.get('client.id');
      return projectClientId === userClientId;
    },
    trackRecentProjects: function trackRecentProjects(project) {
      var recentProjectIds = this.get('sessionData.recentProjects'),
          projectId = project.get('id');

      // @TODO don't worry about this for admins? will only affect single session
      // plus no users will be able to log in as admins then back in as users

      // set recentProjects if not already
      if (!recentProjectIds) {
        recentProjectIds = [];
      }

      // no need to check any of this if there aren't any recents yet
      if (recentProjectIds[0]) {
        // don't do anything if project is already most recent
        if (recentProjectIds[0] === project.get('id')) {
          return;
        }

        // reorder if project is in list but isn't first
        var projectIndex = recentProjectIds.indexOf(projectId);
        if (projectIndex > -1) {
          recentProjectIds.splice(projectIndex, 1);
        } else if (recentProjectIds.get('length') > 2) {
          recentProjectIds = recentProjectIds.slice(0, 3);
        }
      }

      // push current project into recents
      recentProjectIds.unshift(projectId);

      this.set('sessionData.recentProjects', recentProjectIds);
      // get template to update in real time since layout is parent to project
      this.controllerFor('layout').notifyPropertyChange('clientProjects');
    }
  });
});