define('dashboard/utils/scenario-inputs/welcome', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    reviews: {
      projectType: {
        noEdit: true,
        fragments: [{ type: 'text', text: "This Project represents " }, { type: 'var', dataProperty: 'businessTypeLong' }]
      }
    },
    welcome: {
      sectionBoolean: 'welcome',
      name: "Welcome",
      isRequired: true,
      nextStepText: "Get Started",
      content: []
    },

    acknowledgement: {
      sectionBoolean: 'acknowledgement',
      name: 'Include Required Acknowledgement',
      content: []
    },

    summary: {
      sectionBoolean: 'summary',
      name: 'Summary',
      content: []
    },
    basics: {
      sectionBoolean: 'basics',
      name: "Let's get started with the basics",
      sidebarName: "Basics",
      isRequired: true,
      mustBeValidToContinue: true,
      validationFailedMessage: "All fields are required, and email must be valid.",
      nextStepAction: 'sendLinkEmail',
      content: [{
        inputType: 'natural-language-input', preInputText: 'My name is ', dataProperty: 'prospectName',
        name: 'prospectName', prefillProperty: 'contactName', inputName: 'firstName'
      }, {
        inputType: 'natural-language-input', preInputText: 'My email address is ', dataProperty: 'prospectEmail',
        name: 'prospectEmail', prefillProperty: 'contactEmail', validations: { isEmail: true }, inputName: 'email'
      }, {
        inputType: 'natural-language-select', preInputText: 'I am ', dataProperty: 'prospectTitle', name: 'prospectTitle',
        options: [{ groupName: "My role is:", options: ['a business owner', "an employee of the business", "a developer", "a site consultant", "just curious"] }]
      }]
    },
    projectType: {
      sectionBoolean: 'projectType',
      name: "Help us understand your project",
      wizardText: "My project represents...",
      sidebarName: "Project Type",
      isRequired: true,
      mustBeValidToContinue: true,
      content: [{
        name: 'businessType',
        dataProperty: 'businessType',
        inputType: 'panel-selection',
        panels: [{
          value: "new",
          label: "A <strong>NEW</strong> Business or Development to your Community"
        }, {
          value: "existing",
          label: "An <strong>EXISTING</strong> Business Currently Operating in your Community"
        }]
      }]
    }
  };
});