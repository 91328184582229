define('dashboard/objects/fi-tax/base', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var FiTax = Ember.Object.extend({
    project: 0,
    workers: 0,

    total: Ember.computed('project', 'workers', function () {
      return this.get('project') + this.get('workers');
    })
  });

  exports.default = FiTax;
});