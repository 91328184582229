define('dashboard/routes/tax-districts/inactive', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      this.store.findAll('customCostRevenue');
      // @TODO need to load directCostRevenues too?
      return this.store.query('taxDistrict', { adminCreated: true, isActive: false, createdInTransition: { $ne: true } });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.controllerFor('tax-districts').set('currentDistrict', null);
      this.controllerFor('tax-districts').set('activeGroup', false);
    },


    actions: {
      editDistrict: function editDistrict(district) {
        this.controllerFor('tax-districts').set('currentDistrict', district);
        this.transitionTo('tax-district', district.get('id'));
      }
    }
  });
});