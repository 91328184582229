define('dashboard/components/request-template-edit-question', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    questionCancelled: Ember.computed('question.if', function () {
      var ifStatement = this.get('question.if');
      return ifStatement && !this.get('requestTemplate.' + ifStatement) && !this.get('question.alwaysDisplayInRequestTemplate');
    }),
    actions: {
      saveRequestTemplate: function saveRequestTemplate() {
        this.get('requestTemplate').save();
      }
    }
  });
});