define('dashboard/routes/scenario/report/index', ['exports', 'ember-local-storage', 'dashboard/mixins/reset-scroll-position'], function (exports, _emberLocalStorage, _resetScrollPosition) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_resetScrollPosition.default, {
    sessionData: (0, _emberLocalStorage.storageFor)('session-data'),

    redirect: function redirect() {
      var reportSelection = this.get('sessionData.reportSelection') || 'fact-sheet';
      this.transitionTo('scenario.report.' + reportSelection);
    }
  });
});