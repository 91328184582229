define('dashboard/controllers/multiplier-sets/index', ['exports', 'dashboard/mixins/sorting', 'dashboard/mixins/multiplier-set-actions', 'ember-cli-pagination/computed/paged-array'], function (exports, _sorting, _multiplierSetActions, _pagedArray) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_sorting.default, _multiplierSetActions.default, {

    multiplierSetsController: Ember.inject.controller('multiplierSets'),

    notify: Ember.inject.service(),

    creatingModalHidden: true,
    multiplierSetUpload: null,
    newMultiplierSetName: '',

    activeList: Ember.computed('sortedList.@each.archived', 'filteredList', function () {
      return this.get('filteredList').filter(function (set) {
        return !set.get('archived');
      });
    }),

    pagedContent: (0, _pagedArray.default)('activeList', { infinite: "unpaged", perPage: 50 }),

    actions: {
      toggleCreating: function toggleCreating() {
        this.toggleProperty('creatingModalHidden');
      },
      createMultiplierSet: function createMultiplierSet() {
        var file = this.get('multiplierSetUpload'),
            name = this.get('newMultiplierSetName');

        if (!file) {
          this.get('notify').alert('File cannot be empty');
          return;
        }

        var createdMultiplier = this.store.createRecord('multiplierSet', { file: file, name: name });

        // callback will be handled in modal component
        return createdMultiplier.save();
      }
    }

  });
});