define('dashboard/services/scroll-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    activeSectionId: '',

    init: function init() {
      this._super.apply(this, arguments);
      this.set('scrollPositions', []);
    }
  });
});