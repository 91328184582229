define('dashboard/components/single-tag-select-box', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      updateValue: function updateValue(newValue) {
        var model = this.get('valueModel');
        var key = this.get('valueKey');

        if (this.get('disabled')) {
          return;
        }

        model.set(key, newValue);
        if (model.save) {
          model.save();
        } else {
          model.content.save();
        }
      }
    }
  });
});