define('dashboard/components/data-request-wizard', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    ajax: Ember.inject.service(),

    scenario: Ember.computed(function () {
      return {};
    }), //TODO: Input this from outside the component
    divisions: Ember.computed.alias('scenario.divisions'),
    sectionsToDisplay: Ember.computed.alias('scenario.sectionsToDisplay'),

    goingForward: true,
    isContactModalHidden: true,

    currentSection: Ember.computed('currentSectionIndex', function () {
      return this.get('sectionsToDisplay.' + this.get('currentSectionIndex'));
    }),

    inWelcomeDivision: Ember.computed.equal('currentSection.division', 'welcome'),
    inReviewDivision: Ember.computed.equal('currentSection.division', 'review'),

    inReviewDivisionLength: Ember.computed('sectionsToDisplay.length', 'currentSectionIndex', function () {
      return this.get('currentSectionIndex') === this.get('sectionsToDisplay.length') - 2;
    }),

    contact: Ember.computed('scenario.requestTemplate.contact', 'scenario.contact', function () {
      var scenarioContact = this.get('scenario.contact');
      return scenarioContact ? scenarioContact : this.get('scenario.requestTemplate.contact');
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.selectExpansions();
    },


    scenarioLoaded: Ember.observer('scenario.created', function () {
      this.updateVisited();
    }),

    sectionsToDisplayChanged: Ember.observer('sectionsToDisplay.[]', function () {
      if (!this.get('inReviewDivision')) {
        this.notifyPropertyChange('currentSectionIndex');
      }
    }),

    currentSectionChanged: Ember.observer('currentSection', function () {
      var currentSection = this.get('currentSection') || {};

      if (this.get('previousSection.name') != currentSection.name) {
        this.selectAnimation();
        this.updateVisited();
        this.selectExpansions();
        this.set('previousSection', currentSection);
      }
    }),

    initialEntry: true,
    animation: null,

    selectAnimation: function selectAnimation() {
      if (this.get("initialEntry")) {
        this.set("initialEntry", false);
      } else {
        var animation = this.get('goingForward') ? 'toLeft' : 'toRight';
        this.set('animation', animation);
      }
    },
    updateVisited: function updateVisited() {
      var scenario = this.get('scenario');
      var visitedSections = scenario.get('visitedSections');
      var sectionBoolean = this.get('currentSection.sectionBoolean') || '';
      var oldValue = void 0;
      if (sectionBoolean) {
        oldValue = Ember.get(visitedSections, sectionBoolean);
      }
      Ember.set(visitedSections, sectionBoolean, true);
      if (!oldValue && !this.get('isPreview')) {
        scenario.save();
      }
    },
    selectExpansions: function selectExpansions() {
      var expandedDivisions = {};
      var selected = this.get('currentSection.name');
      var divisions = this.get("divisions");

      for (var divisionKey in divisions) {
        Ember.set(expandedDivisions, divisionKey, {});
        var sectionList = Ember.get(divisions, divisionKey + '.sectionList');

        sectionList && sectionList.forEach(function (section) {
          if (section.name == selected) {
            Ember.set(expandedDivisions, divisionKey + '.isOpen', true);
          }
        });
      }

      this.set('expandedDivisions', expandedDivisions);
    },
    save: function save() {
      if (this.get('savable')) {
        this.get('scenario').save();
      }
    },


    actions: {
      navigateToScreen: function navigateToScreen(sectionName) {
        var currentIndex = this.get('currentSectionIndex');
        var fullArray = this.get('sectionsToDisplay');
        var index = fullArray.indexOf(fullArray.find(function (s) {
          return s.name == sectionName;
        }));
        if (index < currentIndex) {
          this.set('goingForward', false);
        } else if (index > currentIndex) {
          this.set('goingForward', true);
        } else {
          return;
        }
        this.set('currentSectionIndex', index);
      },
      save: function save() {
        this.save();
      },


      // expands/collapses sidebar section when clicking on header
      toggleDivision: function toggleDivision(key) {
        var expandedDivisions = this.get('expandedDivisions');
        var divisions = this.get('divisions');
        if (!Ember.get(expandedDivisions, key + '.locked')) {
          var isOpen = Ember.get(expandedDivisions, key + '.isOpen');
          Ember.set(expandedDivisions, key + '.isOpen', !isOpen);

          // automatically navigate to first slide in new section
          var inCurrentSection = this.get('currentSection.division') == key;
          var section = Ember.get(divisions, key);
          var sectionName = section.sectionList[0].name;
          if (sectionName && !isOpen && !inCurrentSection) {
            this.send('navigateToScreen', sectionName);
          }
        }
      }
    }
  });
});