define('dashboard/mirage/serializers/scenario', ['exports', 'dashboard/mirage/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    include: ['assumption', 'project', 'districts', 'requestTemplate'],

    normalize: function normalize(_ref) {
      var _ApplicationSerialize;

      var scenario = _ref.scenario;

      delete scenario.project;
      delete scenario.assumption;
      delete scenario.districts;
      return (_ApplicationSerialize = _application.default.prototype).normalize.apply(_ApplicationSerialize, arguments);
    }
  });
});