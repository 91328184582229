define('dashboard/components/expandable-input-remote-trigger', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    focusInWhenRenaming: Ember.observer('isRenaming', function () {
      var _this = this;

      if (this.get("isRenaming")) {
        Ember.run.next(function () {
          _this.$('.scenario-name-input').focus();
        });
      }
    }),

    keyPress: function keyPress(e) {
      // On enter/return
      if (e.keyCode === 13) {
        e.preventDefault();
        this.exitAndSave();
      }
    },
    exitAndSave: function exitAndSave() {
      var pEl = this.$('.scenario-name-input');
      var title = pEl.text().trim();
      this.set("isRenaming", false);
      this.updateTitle(title);
      pEl.scrollLeft(0);
    },
    focusOut: function focusOut() {
      this.exitAndSave();
    }
  });
});