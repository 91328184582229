define('dashboard/components/buttons/continue-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'button',
    classNames: ['btn', 'btn-link', 'pr-0', 'pb-0'],
    attributeBindings: ['tabindex', 'data-test'],

    keyDown: function keyDown(e) {

      if ((e.keyCode === 13 || e.keyCode === 9) && !e.shiftKey) {
        this.sendAction();
      }
    },

    click: function click() {
      this.sendAction();
    }

  });
});