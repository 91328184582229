define('dashboard/components/request-template-additional-info', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    hasAdditionalInfo: Ember.computed('section.content.[]', function () {
      var content = this.get('section.content');
      return content && content.any(function (question) {
        return question.dynamicRows;
      });
    })
  });
});