define('dashboard/components/modals/submit-data-form-modal', ['exports', 'dashboard/components/modals/modal-confirm'], function (exports, _modalConfirm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _modalConfirm.default.extend({

    disableButton: Ember.computed.bool('progress'),

    actions: {
      submit: function submit() {
        var _this = this;

        if (this.get('isPreview')) {
          return;
        }

        this.set('progress', true);
        this.onSubmit().then(function (scenario) {
          scenario.save().then(function () {
            _this.set('progress', false);
            _this.send('afterSubmit');
          });
        });
      },
      afterSubmit: function afterSubmit() {
        this.sendAction('afterSubmit');
        this.send('slideOut');
      }
    }
  });
});