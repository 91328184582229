define('dashboard/components/modals/tif-save-for-later-modal', ['exports', 'dashboard/components/modals/modal-confirm', 'dashboard/mixins/tif-district-actions'], function (exports, _modalConfirm, _tifDistrictActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _modalConfirm.default.extend(_tifDistrictActions.default, {

    tifInputsDisabled: true,
    nameInputDisabled: false,

    actions: {
      save: function save() {
        var _this = this;

        this.saveForLater().then(function () /*results*/{
          _this.set('isHidden', true);
        }, function (reason) {
          if (reason.error) {
            _this.get('notify').alert(reason.error);
          }
        });
      }
    }
  });
});