define('dashboard/routes/client', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    titleToken: function titleToken(models) {
      return ['Clients', models.client.get('name')];
    },

    notify: Ember.inject.service(),

    model: function model(parameters) {
      var clientPromise = this.store.findRecord('client', parameters.client_id);
      var usersPromise = this.store.query('user', { client: parameters.client_id }).then(function (arr) {
        return arr.get('firstObject');
      });

      return Ember.RSVP.hash({
        client: clientPromise,
        user: usersPromise
      });
    },
    setupController: function setupController(controller, models) {
      controller.setProperties({
        client: models.client,
        user: models.user
      });

      this._super(controller, models.client);
    },
    afterModel: function afterModel(models) {
      this.controllerFor('clients').set('currentClient', models.client);
    },


    isUsersPage: Ember.computed.equal('router.currentRouteName', 'client.users'),

    actions: {
      activateClient: function activateClient() {
        return true;
      },
      willTransition: function willTransition(transition) {
        var client = this.controller.get('client');
        var clientValid = client.get('isValid');
        var usersInvalid = client.get('hasInvalidUsers') && this.get('isUsersPage');
        var wasNotified = this.controller.get('wasNotifiedOfErrors');

        if (clientValid && !usersInvalid) {
          this.getWithDefault('notify.target.messages', []).forEach(function (message) {
            return message.set('visible', false);
          });
          this.controller.setProperties({
            isUnsavedClientModalHidden: true,
            isUnsavedUserModalHidden: true,
            previousTransition: null,
            wasNotifiedOfErrors: false
          });
          return true;
        } else if (wasNotified) {
          return true;
        } else if (!clientValid) {
          this.controller.set('isUnsavedClientModalHidden', clientValid);
        } else if (usersInvalid) {
          this.controller.set('isUnsavedUserModalHidden', !usersInvalid);
          this.get('notify').error('Please fix form errors before trying to navigate away from this page.');
        }

        this.controller.setProperties({
          previousTransition: transition,
          wasNotifiedOfErrors: false
        });

        transition.abort();
      },
      save: function save() {
        this.transitionTo('clients');
      }
    }
  });
});