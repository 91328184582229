define('dashboard/controllers/dependent-tax-district-sets/index', ['exports', 'dashboard/mixins/sorting', 'dashboard/fixtures/states'], function (exports, _sorting, _states) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_sorting.default, {

    hideCreateDependentTaxDistrictSetModal: true,
    stateOptions: _states.default,
    name: '',

    notify: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('sortColumns', [{
        title: '',
        width: '50'
      }, {
        title: 'State',
        sortField: 'state'
      }, {
        title: 'Set Name',
        sortField: 'name'
      }, {
        title: 'Primary Group Name',
        sortField: 'primaryGroupName'
      }, {
        title: 'Created',
        sortField: 'created'
      }]);
      this.set('newDependentTaxDistrictSet', { name: '', state: '' });
    },


    sortField: 'created',
    sortDir: 'asc',

    disableButton: Ember.computed('newDependentTaxDistrictSet.{name,state}', function () {
      return this.get('newDependentTaxDistrictSet.name').trim().length <= 0 || !this.get('newDependentTaxDistrictSet.state');
    }),

    actions: {
      toggleCreating: function toggleCreating() {
        this.send('resetDependentTaxDistrictForm');
        this.set('hideCreateDependentTaxDistrictSetModal', false);
      },
      create: function create() {
        var _this = this;

        var setToCreate = this.get('newDependentTaxDistrictSet');

        if (!setToCreate.name) {
          return this.get('notify').alert('District Area Set must have a name.');
        }

        if (!setToCreate.state) {
          return this.get('notify').alert('District Area Set must have a state.');
        }

        this.store.createRecord('dependentTaxDistrictSet', setToCreate).save().then(function (dependentTaxDistrictSet) {
          if (!dependentTaxDistrictSet) {
            return _this.get('notify').alert('Something went wrong creating this dependent tax district set.');
          }
          _this.send('resetDependentTaxDistrictForm');
          _this.set('hideCreateDependentTaxDistrictSetModal', true);
          _this.send('transitionToDependentTaxDistrictSet', dependentTaxDistrictSet);
        });
      },
      resetDependentTaxDistrictForm: function resetDependentTaxDistrictForm() {
        this.set('newDependentTaxDistrictSet', { name: '', state: '', primaryGroupName: '' });
      },
      transitionToDependentTaxDistrictSet: function transitionToDependentTaxDistrictSet(dependentTaxDistrictSet) {
        this.transitionToRoute('dependent-tax-district-set', dependentTaxDistrictSet.get('id'));
      }
    }
  });
});