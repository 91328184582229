define('dashboard/objects/ei-av-furniture', ['exports', 'dashboard/utils/depreciation-schedule'], function (exports, _depreciationSchedule) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var EiAvFurnitureObject = Ember.Object.extend({

    output: Ember.computed('input.[]', 'schedule', function () {
      var input = this.get('input') || [],
          schedule = this.get('schedule') || [];

      return (0, _depreciationSchedule.default)(input, schedule);
    })

  });

  exports.default = EiAvFurnitureObject;
});