define('dashboard/objects/project-engine', ['exports', 'dashboard/objects/impact-engine'], function (exports, _impactEngine) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ProjectEngine = _impactEngine.default.extend({

    // basically duplicated from scenario controller, but modified to use currentlyActiveScenario
    engineMultipliers: Ember.computed('scenario.model.multiplier.{earnings,employment}', 'district.multiplierSet', 'impliedPrimaryDistrictMultiplier.{impliedEmployment,impliedEarnings}', function () {
      var regionPercent = parseFloat(this.get('district.employmentPercentOfRegion')) || 1,
          employment = this.get('scenario.model.employmentMultiplier') || 1,
          earnings = this.get('scenario.model.earningsMultiplier') || 1,
          impliedEmployment = this.get('scenario.impliedPrimaryDistrictMultiplier.impliedEmployment'),
          impliedEarnings = this.get('scenario.impliedPrimaryDistrictMultiplier.impliedEarnings'),
          adjustedEmployment = void 0,
          adjustedEarnings = void 0;

      var foundMultiplier = this.get('scenario.model.multiplier');
      if (foundMultiplier && foundMultiplier.get('content')) {
        employment = parseFloat(foundMultiplier.get('employment'));
        earnings = parseFloat(foundMultiplier.get('earnings'));

        adjustedEmployment = (employment - 1) * 0.5 * (regionPercent + 1) + 1;
        adjustedEarnings = (earnings - 1) * 0.5 * (regionPercent + 1) + 1;
      } else {
        adjustedEmployment = getAdjusted(impliedEmployment, regionPercent);
        adjustedEarnings = getAdjusted(impliedEarnings, regionPercent);
      }

      return Ember.Object.create({
        employment: employment,
        earnings: earnings,
        adjustedEmployment: adjustedEmployment,
        adjustedEarnings: adjustedEarnings
      });

      function getAdjusted(implied, regionPercent) {
        var impliedFactor = (implied - 1) * 0.5;
        return impliedFactor + impliedFactor * regionPercent + 1;
      }
    })

  });

  exports.default = ProjectEngine;
});