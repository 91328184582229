define('dashboard/controllers/layout', ['exports', 'ember-local-storage', 'lodash/array', 'dashboard/mixins/color-scheme', 'dashboard/mixins/read-only-restrictions'], function (exports, _emberLocalStorage, _array2, _colorScheme, _readOnlyRestrictions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_readOnlyRestrictions.default, _colorScheme.default, {

    application: Ember.inject.controller(),
    insights: Ember.inject.controller(),
    projects: Ember.inject.controller(),
    dataForms: Ember.inject.controller(),

    // these routes are parallel but should reference the same client
    insightsClient: Ember.computed.alias('insights.model'),
    projectsClient: Ember.computed.alias('projects.model.client'),
    dataFormClient: Ember.computed.alias('dataForms.model.client'),

    isSuperAdmin: Ember.computed.alias('model.isSuperAdmin'),

    sessionData: (0, _emberLocalStorage.storageFor)('session-data'),

    open: false,

    // for users, this should be the logged in client
    // for admins, this should be the client we're viewing data for
    client: Ember.computed('model.client.id', 'insightsClient', 'projectsClient', 'dataFormClient', 'isInsightsRoute', 'isDataFormRoute', 'isProjectsRoute', 'application.currentPath', function () {

      var loggedInClient = this.get('model.client');
      if (loggedInClient && loggedInClient.get('id')) {
        return loggedInClient;
      }

      // need to return the client from the route we're at or it might not be the right client
      if (this.get('isInsightsRoute')) {
        return this.get('insightsClient');
      }
      if (this.get('isDataFormRoute')) {
        return this.get('dataFormClient');
      }
      if (this.get('isProjectsRoute')) {
        return this.get('projectsClient');
      }
    }),

    projectBucket: Ember.computed('sessionData.projectBucket', 'isSuperAdmin', function () {
      var bucket = this.get('sessionData.projectBucket') || 'index';

      // for sidebar for logged in client
      if (bucket === 'index' && !this.get('isSuperAdmin')) {
        return 'projects.all';
      }

      return 'projects.' + bucket;
    }),

    projectLinkProps: Ember.computed('projectBucket', function () {
      switch (this.get('projectBucket')) {
        case 'projects.index':
          return { label: 'Active', icon: 'fa-bolt' };
        case 'projects.won':
          return { label: 'Won', icon: 'fa-trophy' };
        case 'projects.lost':
          return { label: 'Lost', icon: 'fa-times-circle' };
        case 'projects.archived':
          return { label: 'Archived', icon: 'fa-ghost' };
        default:
          return { label: 'All', icon: 'fa-folder-open' };
      }
    }),

    logoUrl: Ember.computed('projects.model.client.colorTheme', function () {
      var colorTheme = this.get('projects.model.client.colorTheme');
      var fileName = colorTheme === 'Blue' ? 'logo' : 'logo_grey_new';
      return '/assets/images/' + fileName + '.png';
    }),

    isInsightsRoute: Ember.computed('application.currentPath', function () {
      var currentPath = this.get('application.currentPath') || '';
      return currentPath.split('.').includes('insights');
    }),

    isProjectsRoute: Ember.computed('application.currentPath', function () {
      var currentRoute = this.get('application.currentPath') || '';
      return !!_array2.default.intersection(currentRoute.split('.'), ['projects', 'project']).length;
    }),

    isDataFormRoute: Ember.computed('application.currentPath', function () {
      var currentRoute = this.get('application.currentPath') || '';
      return currentRoute.split('.').includes('data-forms');
    }),

    currentClient: Ember.computed.alias('projects.model.client.name'),

    showAdminBanner: Ember.computed('application.currentPath', 'isSuperAdmin', function () {
      var currentRoute = this.get('application.currentPath').split('.'); // ex. ["authenticated", "layout", "projects", "project", "index"]
      var routesToShow = ['insights', 'projects', 'project', 'data-forms'];
      var routesToHide = ['loading', 'scenario'];

      if (!this.get('isSuperAdmin')) return false;
      if (_array2.default.intersection(currentRoute, routesToHide).length) return false;

      return !!_array2.default.intersection(currentRoute, routesToShow).length;
    }),

    showReadOnlyBanner: Ember.computed('application.currentRouteName', 'isSuperAdmin', 'client.readOnlyAccess', function () {
      var currentRoute = this.get('application.currentRouteName');
      var bannerRoutes = ['insights', 'project', 'data-forms'];
      return !this.get('isSuperAdmin') && bannerRoutes.any(function (route) {
        return currentRoute.includes(route);
      }) && !currentRoute.includes('loading') && this.get('client.readOnlyAccess');
    }),

    readOnlyEmail: Ember.computed(function () {
      return 'mailto:paul@impactdatasource.com?subject=' + this.get('client.name') + ' Read Only Access - Impact DashBoard';
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('adminNavItems', [{
        text: 'Clients',
        params: ['clients'],
        icon: 'fa-home',
        type: 'super'
      }, {
        text: 'Tax Districts',
        params: ['tax-districts'],
        icon: 'fa-usd-square',
        type: 'super'
      }, {
        text: 'Multiplier Sets',
        params: ['multiplier-sets'],
        icon: 'fa-folder-open',
        type: 'super'
      }, {
        text: 'District Area Sets',
        params: ['district-area-sets'],
        icon: 'fa-book',
        type: 'super'
      }, {
        text: 'Demo Users',
        params: ['guests'],
        icon: 'fa-users',
        type: 'super'
      }]);
    },


    recentProjects: null,

    clientProjects: Ember.computed.alias('client.projects'),

    projectsLoaded: Ember.observer('clientProjects', 'clientProjects.@each.{title,isDeleted,lastUpdatedScenario}', function () {
      var clientProjects = this.get('clientProjects') || [];
      var recents = [];

      recents = clientProjects.sortBy('lastUpdatedScenario').reverse().slice(0, 3);

      this.set('recentProjects', recents.without(undefined));
    }),

    clientNavItems: Ember.computed('client.id', 'recentProjects.[]', 'projectBucket', function () {
      var clientId = this.get('client.id');
      var recentProjects = this.get('recentProjects') || [],
          projectBucket = this.get('projectBucket'),
          projectLinkProps = this.get('projectLinkProps');

      var staticNav = [{
        text: 'Insights',
        params: ['insights', clientId],
        icon: 'fa-chart-bar',
        type: 'super'
      }, {
        text: 'Projects',
        type: 'header'
        // icon: 'fa-tachometer-alt'
      }, {
        text: 'Active',
        params: ['projects.index', clientId],
        icon: 'fa-bolt',
        type: 'sub'
      }, {
        text: projectLinkProps.label,
        params: [projectBucket, clientId],
        icon: projectLinkProps.icon,
        type: 'sub'
      }];

      if (recentProjects.length) {
        staticNav.push({
          text: 'Recent',
          icon: 'fa-clock',
          type: 'collapsible',
          subItems: recentProjects.map(function (proj) {
            var title = proj.get('title') || 'Untitled Project';
            return {
              text: title,
              params: ['project', clientId, proj.get('id')],
              iconFrom: title.slice(0, 2).toUpperCase(),
              type: 'sub-sub'
            };
          })
        });
      }

      var dataForms = [{
        text: 'Data Forms',
        type: 'header'
        // icon: 'fa-cabinet-filing'
      }, {
        text: 'Inbox',
        notificationProperty: 'unreadDataRequestsCount',
        params: ['data-forms.inbox', clientId],
        icon: 'fa-inbox',
        type: 'sub'
      }, {
        text: 'Templates',
        params: ['data-forms.request-templates', clientId],
        icon: 'fa-sliders-v',
        type: 'sub'
      }];

      return staticNav.concat(dataForms);
    }),

    // possibly just used to show new data request notification count on side nav
    dataRequests: Ember.computed('model.client.id', 'model.client.requestTemplates.@each.scenarios', function () {
      var clientId = this.get('model.client.id');

      // admins have no client
      if (!clientId) return [];

      return this.store.query('scenario', { client: clientId });
    }),

    unreadDataRequests: Ember.computed('dataRequests.[]', 'dataRequests.@each.displayAsUnread', function () {
      return this.get('dataRequests').filterBy('displayAsUnread');
    }),

    unreadDataRequestsCount: Ember.computed.alias('unreadDataRequests.length'),

    navItems: Ember.computed('model.isSuperAdmin', 'clientNavItems', function () {
      return this.get('model.isSuperAdmin') ? this.get('adminNavItems') : this.get('clientNavItems');
    }),

    isProjectPage: Ember.computed('application.currentRouteName', function () {
      return this.get('application.currentRouteName') === 'project.index';
    }),

    year: new Date().getFullYear(),

    actions: {
      invalidateSession: function invalidateSession() {
        this.get('session').invalidate();
      },
      updateProjectTitle: function updateProjectTitle(newTitle) {
        var project = this.get('project');
        if (!project || !newTitle) {
          return;
        }
        project.set('title', newTitle);
        project.save();
      }
    }
  });
});