define('dashboard/components/selectable-engine', ['exports', 'ember-cli-swiper/components/swiper-slide'], function (exports, _swiperSlide) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _swiperSlide.default.extend({
    tagName: 'a',
    classNames: ['swipable-tab'],
    classNameBindings: ['isActive:active'],
    attributeBindings: ['title'],

    click: function click() {
      this.sendAction('action', this.get('engine'));
    },

    title: Ember.computed.reads('engine.group.label'),

    isActive: Ember.computed('engine', 'selectedEngine', function () {
      var currentEngine = this.get('engine'),
          selectedEngine = this.get('selectedEngine');

      if (selectedEngine && currentEngine) {
        return currentEngine.get('district.id') === selectedEngine.get('district.id');
      }
    })
  });
});