define('dashboard/models/request-template', ['exports', 'ember-data/model', 'ember-data/relationships', 'ember-data/attr', 'ember-data-copyable', 'dashboard/utils/computed/section-boolean', 'dashboard/utils/observers/property-dependency', 'dashboard/utils/scenario-inputs/index', 'dashboard/mixins/state-taxes'], function (exports, _model, _relationships, _attr, _emberDataCopyable, _sectionBoolean, _propertyDependency, _index, _stateTaxes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = _model.default.extend(_emberDataCopyable.default, _stateTaxes.default, {
    client: (0, _relationships.belongsTo)(),
    scenarios: (0, _relationships.hasMany)(),
    contact: (0, _relationships.belongsTo)('user'),

    lockedToScenario: (0, _attr.default)('boolean', { defaultValue: false }),
    title: (0, _attr.default)('string'),
    created: (0, _attr.default)('date'),
    lastUpdated: (0, _attr.default)('date'),
    deleted: (0, _attr.default)('boolean', { defaultValue: false }),

    router: Ember.inject.service(),
    anonymousLink: Ember.computed('id', function () {
      var _window$location = window.location,
          protocol = _window$location.protocol,
          host = _window$location.host;

      var route = this.get('router').urlFor('data-requests.new', this.get('id'));
      return protocol + '//' + host + route;
    }),

    phaseInLength: true,
    dataFormLength: (0, _attr.default)('string', { defaultValue: '5' }),
    welcome: true,
    acknowledgement: (0, _attr.default)('boolean', { defaultValue: false }),
    metaInfo: true,
    customWelcomeText: (0, _attr.default)('string'),
    customAcknowledgementText: (0, _attr.default)('string'),
    customThanksText: (0, _attr.default)('string'),
    // customContactInfo: attr('string'),
    customDisplayTitle: (0, _attr.default)('string'),

    basics: true,
    summary: true,
    projectType: true,
    expansionPlans: true,
    reviewAndSubmit: true,
    thanks: true,
    businessType: true,
    prospectName: true,
    prospectEmail: true,
    prospectTitle: true,

    contactInfo: Ember.computed.alias('contact.contactInfo'),

    productsAndIndustry: (0, _sectionBoolean.default)(['localService', 'mainService', 'primaryNAICSCode']),
    localService: (0, _attr.default)('boolean', { defaultValue: true }),
    mainService: (0, _attr.default)('boolean', { defaultValue: true }),
    primaryNAICSCode: (0, _attr.default)('boolean', { defaultValue: true }),

    onlyIfProjectPlans: (0, _propertyDependency.default)('leaseTermInYears', 'projectPlans'),
    plans: (0, _sectionBoolean.default)(['projectPlans', 'leaseTermInYears']),
    projectPlans: (0, _attr.default)('boolean', { defaultValue: true }),
    leaseTermInYears: (0, _attr.default)('boolean', { defaultValue: true }),

    timeline: (0, _sectionBoolean.default)(['beginRenovationsDate', 'beginLocalOperationsDate']),
    beginRenovationsDate: (0, _attr.default)('boolean', { defaultValue: true }),
    beginLocalOperationsDate: (0, _attr.default)('boolean', { defaultValue: true }),

    site: (0, _sectionBoolean.default)(['siteAddress', 'specialSiteRequirements']),
    siteAddress: (0, _attr.default)('boolean', { defaultValue: true }),
    specialSiteRequirements: (0, _attr.default)('boolean', { defaultValue: true }),

    currentFacility: Ember.computed.or('site', 'plans'),

    squareFootageScreen: (0, _sectionBoolean.default)(['squareFootage']),
    squareFootage: (0, _attr.default)('boolean', { defaultValue: true }),

    jobsAndSalaries: (0, _sectionBoolean.default)(['employmentDetails']),
    employmentDetails: (0, _attr.default)('boolean', { defaultValue: true }),
    jobs: (0, _sectionBoolean.default)(['jobsAdded']),
    jobsAdded: (0, _attr.default)('boolean', { defaultValue: true }),
    currentJobsAdded: true,
    salaries: (0, _sectionBoolean.default)(['avgAnnualSalary']),
    avgAnnualSalary: (0, _attr.default)('boolean', { defaultValue: true }),
    currentAvgAnnualSalary: true,
    showAnyJobsOrSalaries: Ember.computed.or('jobsAndSalaries', 'jobsAdded', 'avgAnnualSalary'),
    currentJobsAndSalaries: Ember.computed.or('jobs', 'salaries', 'jobsAndSalaries'),

    benefits: (0, _sectionBoolean.default)(['benefitTypes', 'benefitsAvgAnnualValue', 'benefitsAdditionalNotes']),
    benefitTypes: (0, _attr.default)('boolean', { defaultValue: true }),
    benefitsAvgAnnualValue: (0, _attr.default)('boolean', { defaultValue: true }),
    benefitsAdditionalNotes: (0, _attr.default)('boolean', { defaultValue: true }),

    capitalInvestment: (0, _sectionBoolean.default)(['landExpenditures', 'buildingExpenditures', 'equipmentExpenditures']),
    landExpenditures: (0, _attr.default)('boolean', { defaultValue: true }),
    buildingExpenditures: (0, _attr.default)('boolean', { defaultValue: true }),
    equipmentExpenditures: (0, _attr.default)('boolean', { defaultValue: true }),
    inventoryExpenditures: (0, _attr.default)('boolean', { defaultValue: true }), // where is this used? is this different from annual taxable inventori es?
    capitalInvestmentAllowed: Ember.computed.or('stateTaxes.land', 'stateTaxes.buildings', 'stateTaxes.ffe'),
    anyStateTaxes: Ember.computed.or('stateTaxes.land', 'stateTaxes.buildings', 'stateTaxes.ffe', 'stateTaxes.inventory'),
    anyCapitalInvestment: Ember.computed.or('hasCapitalInvestmentLand', 'hasCapitalInvestmentBuildings', 'hasCapitalInvestmentEquipment', 'hasCapitalInvestmentInventories'),
    currentPropertyAllowed: Ember.computed.and('anyStateTaxes', 'anyCapitalInvestment'),
    hasCapitalInvestmentLand: Ember.computed.and('stateTaxes.land', 'landExpenditures'),
    hasCapitalInvestmentBuildings: Ember.computed.and('stateTaxes.buildings', 'buildingExpenditures'),
    hasCapitalInvestmentEquipment: Ember.computed.and('stateTaxes.ffe', 'equipmentExpenditures'),
    hasCapitalInvestmentInventories: Ember.computed.and('stateTaxes.inventory', 'annualTaxableInventories'),

    inventories: (0, _sectionBoolean.default)(['annualTaxableInventories']),
    annualTaxableInventories: (0, _attr.default)('boolean', { defaultValue: true }),

    taxablePurchasesAndSales: (0, _sectionBoolean.default)(['annualLocalTaxableSales', 'annualTaxablePurchases']),
    annualTaxablePurchases: (0, _attr.default)('boolean', { defaultValue: true }),
    annualLocalTaxableSales: (0, _attr.default)('boolean', { defaultValue: true }),
    // showSalesTax moved to sales-taxes mixin
    showTaxableSalesOrPurchases: Ember.computed.or('annualTaxablePurchases', 'annualLocalTaxableSales'),
    salesTaxOnReview: Ember.computed.and('showSalesTax', 'showTaxableSalesOrPurchases'),

    hotelNightsVisitors: (0, _sectionBoolean.default)(['hotelNights', 'hotelDailyRate', 'spendingPerHotelNight']),
    hotelNights: (0, _attr.default)('boolean', { defaultValue: true }),
    hotelDailyRate: (0, _attr.default)('boolean', { defaultValue: true }),
    spendingPerHotelNight: (0, _attr.default)('boolean', { defaultValue: true }),

    currentProjectOther: Ember.computed.or('taxablePurchasesAndSales', 'hotelNightsVisitors'),
    hasNoBackground: Ember.computed.not('projectBackground'),

    projectBackground: (0, _sectionBoolean.default)(['notes']),
    notes: (0, _attr.default)('boolean', { defaultValue: true }),

    attachDocuments: (0, _sectionBoolean.default)(['attachments']),
    attachments: (0, _attr.default)('boolean', { defaultValue: true }),
    customAttachmentText: (0, _attr.default)('string', { defaultValue: "Please attach supporting documentation that may be helpful in reviewing the Project." }),
    customBackgroundText: (0, _attr.default)('string', { defaultValue: 'Please provide any additional background that would be helpful in reviewing the Project.' }),

    companyInfo: (0, _sectionBoolean.default)(['companyName', 'companyAddress', 'parentCompanyName', 'companyWebsite', 'confidentialIdentity']),
    onlyIfCompanyName: (0, _propertyDependency.default)('confidentialIdentity', 'companyName'),
    companyName: (0, _attr.default)('boolean', { defaultValue: true }),
    confidentialIdentity: (0, _attr.default)('boolean', { defaultValue: true }),
    companyAddress: (0, _attr.default)('boolean', { defaultValue: true }),
    parentCompanyName: (0, _attr.default)('boolean', { defaultValue: true }),
    companyWebsite: (0, _attr.default)('boolean', { defaultValue: true }),

    companyBackground: (0, _sectionBoolean.default)(['yearEstablished', 'stateOfFormation', 'businessStructure', 'companyOwnership']),
    yearEstablished: (0, _attr.default)('boolean', { defaultValue: true }),
    stateOfFormation: (0, _attr.default)('boolean', { defaultValue: true }),
    businessStructure: (0, _attr.default)('boolean', { defaultValue: true }),
    companyOwnership: (0, _attr.default)('boolean', { defaultValue: true }),

    employmentDistribution: (0, _sectionBoolean.default)(['stateEmployees', 'usaEmployees', 'worldEmployees']),
    stateEmployees: (0, _attr.default)('boolean', { defaultValue: true }),
    usaEmployees: (0, _attr.default)('boolean', { defaultValue: true }),
    worldEmployees: (0, _attr.default)('boolean', { defaultValue: true }),

    pointOfContact: (0, _sectionBoolean.default)(['contactName', 'contactTitle', 'contactPhoneNumber', 'contactEmail']),
    contactName: (0, _attr.default)('boolean', { defaultValue: true }),
    contactTitle: (0, _attr.default)('boolean', { defaultValue: true }),
    contactPhoneNumber: (0, _attr.default)('boolean', { defaultValue: true }),
    contactEmail: (0, _attr.default)('boolean', { defaultValue: true }),

    //marking sectionsToDisplay and numberFieldsRequested as volatile because I don't want to have them rely on ~40 properties...
    sectionsToDisplay: Ember.computed('wizardSections.[]', function () {
      var divisions = this.get('rawDivisions');
      return this.get('wizardSections').map(function (s) {
        return s.index != 0 ? s : _extends({}, s, { openingText: divisions[s.division].openingText });
      });
    }).volatile(),

    fieldsRequested: Ember.computed(function () {
      var _this = this;

      var fieldsDisplayed = [];
      this.get('wizardSections').forEach(function (section) {
        section.content.forEach(function (item) {
          if (_this.get(item.name)) {
            fieldsDisplayed.pushObject(item);
          }
        });
      });
      return fieldsDisplayed;
    }).volatile(),

    numberFieldsRequested: Ember.computed.alias('fieldsRequested.length'),

    displayTitle: Ember.computed('customDisplayTitle', 'defaultDisplayTitle', function () {
      return this.get('customDisplayTitle') || this.get('defaultDisplayTitle');
    }),

    defaultDisplayTitle: Ember.computed(function () {
      return this.get('client.name') + ' Data Sheet';
    }),

    welcomeText: Ember.computed('customWelcomeText', 'defaultWelcomeText', function () {
      var customWelcomeText = this.getWithDefault('customWelcomeText', '');
      var customContactInfo = this.getWithDefault('customContactInfo', '');
      return customWelcomeText ? customWelcomeText.replace(customContactInfo, '').trim() : this.get('defaultWelcomeText');
    }),

    defaultWelcomeText: Ember.computed('client.name', function () {
      return 'On behalf of ' + this.get('client.name') + ' we appreciate your interest in our community and we\'re here to assist you with your project. Please provide some information about the project so that we can understand your plans and evaluate its impact on our community. If you have any questions feel free to contact me directly.';
    }),

    acknowledgementText: Ember.computed('customAcknowledgementText', 'defaultAcknowledgementText', function () {
      var customAcknowledgementText = this.getWithDefault('customAcknowledgementText', '');
      return customAcknowledgementText || this.get('defaultAcknowledgementText');
    }),

    defaultAcknowledgementText: 'I certify that the information provided in this survey, including future business plans for hiring and investment, \
is accurate and true to the best of my knowledge and belief based on the current projections and plans.',

    thanksText: Ember.computed('customThanksText', function () {
      return this.get('customThanksText') || "We will reach out to you shortly.";
    }),

    wizardSections: Ember.computed('divisions.[]', 'lastUpdated', function () {
      var _this2 = this;

      var divisions = this.get('divisions');
      divisions = JSON.parse(JSON.stringify(divisions));
      var keys = Object.keys(divisions);
      return keys.flatMap(function (k) {
        return divisions[k].sectionList.filter(function (s) {
          if (s.sectionBoolean === 'acknowledgement') return false;
          return s.sectionBoolean ? _this2.get(s.sectionBoolean) : false;
        }).map(function (section, index) {
          return _extends({}, section, { division: k, index: index });
        });
      });
    }),

    // These will be shown to the prospect in the wizard
    prospectDivisions: Ember.computed('divisions.[]', function () {
      var _this3 = this;

      var divisions = this.get('divisions');
      divisions = JSON.parse(JSON.stringify(divisions));
      var keys = Object.keys(divisions);
      keys.forEach(function (k) {
        var division = divisions[k];

        // eslint-disable-next-line ember/no-side-effects
        Ember.set(division, 'sectionList', division.sectionList.filter(function (s) {
          if (s.sectionBoolean === 'acknowledgement') return false;
          return s.sectionBoolean ? _this3.get(s.sectionBoolean) : false;
        }));

        if (division.sectionList.length > 0) {
          divisions[k] = division;
        } else {
          delete divisions[k];
        }
      });

      return divisions;
    }),

    // These will be shown to the client in the configuration screen
    divisions: Ember.computed('rawDivisions', 'stateTaxes', function () {
      var _this4 = this;

      var rawDivisions = this.get('rawDivisions');
      var divisions = JSON.parse(JSON.stringify(rawDivisions));
      var keys = Object.keys(divisions);
      keys.forEach(function (k) {
        var division = divisions[k];
        division.sectionList = division.sectionList.reject(function (s) {
          return s.if && !_this4.get(s.if);
        });
        divisions[k] = division;
      });
      return divisions;
    }),

    rawDivisions: Ember.computed(function () {
      return {
        welcome: {
          navbarTitle: "Welcome",
          reviews: _index.default.welcome.reviews,
          sectionList: [_index.default.welcome.welcome, _index.default.welcome.acknowledgement, _index.default.welcome.basics, _index.default.welcome.projectType]
        },
        // `plans` and `expansion` are currently identical in their sectionList, and only done separately to get the different navbarTitle
        // However, I expect that to change as we fill out `current`, which will take stuff away from `expansion`
        plans: {
          navbarTitle: "Project",
          reviews: _index.default.future.reviews,

          sectionList: [_index.default.future.plans, _index.default.future.timeline, _index.default.future.site, _index.default.future.squareFootageScreen, _index.default.future.productsAndIndustry, _index.default.future.jobs, _index.default.future.salaries, _index.default.future.jobsAndSalaries, _index.default.future.benefits, _index.default.future.capitalInvestment, _index.default.future.inventories, _index.default.future.taxablePurchasesAndSales, _index.default.future.hotelNightsVisitors]
        },
        current: {
          navbarTitle: "Current Operations",
          hideOnRequestTemplate: true,
          reviews: _index.default.current.reviews,
          openingText: "We'd like to know about the current operations in our community to distinguish this from any possible expansion plans. Please enter the following information about your current operations.",
          sectionList: [
          // add new sections, but make the `name` and `sectionBoolean` the same as the corresponding expansion section
          _index.default.current.currentFacility, _index.default.current.currentSquareFootage, _index.default.current.currentProductAndIndustry, _index.default.current.currentJobsAndSalaries, _index.default.current.currentProperty, _index.default.current.currentTaxablePurchasesAndSales, _index.default.current.expansionPlans]
        },
        expansion: {
          navbarTitle: "Expansion", // TODO: make this change based on whether it's expansion or a completely new business
          reviews: _index.default.future.reviews,
          openingText: "Now, we'd like to know about the expansion plans. Please indicate the incremental or additional activity that you plan above and beyond the current operations activity already entered.",
          sectionList: [_index.default.future.plans, _index.default.future.timeline, _index.default.future.site, _index.default.future.squareFootageScreen, _index.default.future.productsAndIndustry, _index.default.future.jobs, _index.default.future.salaries, _index.default.future.jobsAndSalaries, _index.default.future.benefits, _index.default.future.capitalInvestment, _index.default.future.inventories, _index.default.future.taxablePurchasesAndSales, _index.default.future.hotelNightsVisitors]
        },
        support: {
          navbarTitle: "Supporting Details",
          reviews: _index.default.support.reviews,
          sectionList: [_index.default.support.projectBackground, _index.default.support.attachDocuments]
        },
        company: {
          navbarTitle: "Company",
          reviews: _index.default.company.reviews,
          sectionList: [_index.default.company.companyInfo, _index.default.company.companyBackground, _index.default.company.employmentDistribution, _index.default.company.pointOfContact]
        },
        review: {
          navbarTitle: "Review",
          sectionList: [{
            sectionBoolean: "reviewAndSubmit",
            name: "Review & Submit",
            printButtonBesideHeader: true,
            wizardText: "Please review everything and edit anything that isn't right directly from this page.",
            nextStepText: "Submit & Send",
            nextStepAction: "openSubmitModal",
            isRequired: true,
            printable: true,
            content: []
          }, {
            sectionBoolean: "thanks",
            name: "Thank you!",
            sidebarName: "Thanks",
            isRequired: true,
            content: []
          }]
        }
      };
    })
  });
});