define('dashboard/components/contact-info-select', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    session: Ember.inject.service(),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (!this.client) {
        throw new Error('"contact-info-selector" must have a client attribute');
      }

      var initialSelectedOption = this.value && this.value.get('id') ? this.value : this.client.get('firstUser');

      this.set('selectedOption', initialSelectedOption);
    },


    label: 'Contact Info',
    selectedOption: null,
    hideContactInfoBG: false,

    options: Ember.computed.alias('client.activeUsers'),

    actions: {
      select: function select(value) {
        this.set('selectedOption', value);
        this.sendAction('updateAction', this.get('selectedOption'));
      }
    }
  });
});