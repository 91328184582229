define('dashboard/objects/ei-jobs', ['exports', 'dashboard/objects/array-compute', 'dashboard/utils/multiply-each'], function (exports, _arrayCompute, _multiplyEach) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var EiJobs = _arrayCompute.default.extend({

    indirect: Ember.computed('direct', 'employmentMultiplier', function () {
      var directJobs = this.get('direct');
      var employmentMultiplier = parseFloat(this.get('employmentMultiplier')) || 1;

      return (0, _multiplyEach.default)(directJobs, employmentMultiplier - 1);
    })

  });

  exports.default = EiJobs;
});