define('dashboard/components/data-request-one-page-preview', ['exports', 'dashboard/utils/scenario-inputs/index'], function (exports, _index) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    sectionToEdit: null,
    tagName: '',
    actions: {
      setSectionToEdit: function setSectionToEdit(sectionPath) {
        var section = Ember.get(_index.default, sectionPath);
        this.set('sectionToEdit', section);
      }
    }
  });
});