define('dashboard/routes/scenario/settings/tif-districts', ['exports', 'ember-local-storage', 'dashboard/mixins/reset-scroll-position'], function (exports, _emberLocalStorage, _resetScrollPosition) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_resetScrollPosition.default, {

    sessionData: (0, _emberLocalStorage.storageFor)('session-data'),

    model: function model() {
      return this.modelFor('scenario');
    },
    setupController: function setupController(controller, models) {
      this._super(controller, models);
      controller.setProperties({
        client: models.client,
        scenario: models.scenario,
        tifDistricts: models.tifDistricts,
        selectedTifDistrict: models.tifDistrict,
        scenarioController: this.controllerFor('scenario')
      });

      var scenarioTif = controller.get('filteredTifDistricts').findBy('id', models.scenario.get('tifDistrict.id'));
      if (scenarioTif) {
        controller.set('selectedTifDistrict', scenarioTif);
      }
    },


    actions: {
      willTransition: function willTransition() {
        this.set('sessionData.settingsSelection', 'tif-districts');
      }
    }

  });
});