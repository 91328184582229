define('dashboard/controllers/data-forms/request-templates/request-template/edit', ['exports', 'dashboard/mixins/read-only-restrictions'], function (exports, _readOnlyRestrictions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Controller.extend(_readOnlyRestrictions.default, {
    notify: Ember.inject.service(),

    client: Ember.computed.alias('model.client'),

    // dont want to actually add a section to wizard - only show on config
    divisionsCopy: Ember.computed('model.rawDivisions', function () {
      var divisions = this.get('model.rawDivisions');
      return JSON.parse(JSON.stringify(divisions));
    }),

    // assign an extra Info section to welcome division
    editableDivisions: Ember.computed('divisionsCopy', function () {
      var divisions = this.get('divisionsCopy');

      // Multi-Year Phase-In Input Length (Years)
      var phaseInLengthSection = {
        sectionBoolean: 'phaseInLength',
        isRequired: true,
        name: 'Length',
        content: []
      };

      var metaSection = {
        sectionBoolean: 'metaInfo',
        isRequired: true,
        name: 'Info',
        content: []
      };

      var welcomeDivision = divisions.welcome;
      welcomeDivision.sectionList = [phaseInLengthSection, metaSection].concat(_toConsumableArray(divisions.welcome.sectionList));
      divisions.welcome = welcomeDivision;
      return divisions;
    }),

    requestTemplateDisabled: Ember.computed.alias('isReadOnly'),

    actions: {
      save: function save() {
        this.get('model').save();
      },
      checkSection: function checkSection(section) {
        if (section.isRequired) {
          return;
        }
        var model = this.get('model');
        var sectionBoolean = section.sectionBoolean;
        model.set(sectionBoolean, !model.get(sectionBoolean));
        this.send('save');
      },
      saveCustomInfo: function saveCustomInfo(customField, field) {
        var model = this.get('model');
        model.set(customField, model.get(field));
        model.save();
      },
      updateContactInfo: function updateContactInfo(selectedOption) {
        var model = this.get('model');
        model.set('contact', selectedOption);
        model.save();
      }
    }
  });
});