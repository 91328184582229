define('dashboard/components/tables/sortable-tax-district-table', ['exports', 'dashboard/mixins/sorting'], function (exports, _sorting) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_sorting.default, {
    tagName: '',

    sortFields: Ember.computed('sortColumns.@each.sortField', function () {
      return this.get('sortColumns').mapBy('sortField');
    })

  });
});