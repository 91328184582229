define('dashboard/models/district-group', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    districts: (0, _relationships.hasMany)('taxDistrict', { inverse: null }),
    previousDistricts: (0, _relationships.hasMany)('taxDistrict', { inverse: null }),

    label: (0, _attr.default)('string'),
    groupOrder: (0, _attr.default)('number'),

    isPrimary: Ember.computed('groupOrder', function () {
      var order = this.get('groupOrder');
      return parseInt(order, 10) === 0;
    }),

    getDistricts: function getDistricts(districts) {
      var previousDistricts = this.get('previousDistricts') || [],
          result = [];

      this.get('districts').forEach(function (district) {
        var isActive = district.get('isActive');

        if (isActive === undefined || isActive) {
          result.push(district);
        } else if (districts.indexOf(district) > -1) {
          result.push(district);
        }
      });

      previousDistricts.forEach(function (district) {
        if (districts.indexOf(district) > -1) {
          result.push(district);
        }
      });

      return result;
    },

    filteredDistricts: Ember.computed('districts.[]', function () {
      var districts = this.get('districts');

      return districts.filter(function (district) {
        if (!district) return false;
        return district.get('isActive') && district.get('adminCreated') && !district.get('createdInTransition');
      }).sortBy('name');
    }),

    hasDistricts: Ember.computed.gt('districts.length', 0)

  });
});