define('dashboard/components/customizable-list-row', ['exports', 'dashboard/mixins/hover'], function (exports, _hover) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_hover.default, {
    tagName: 'tr',
    classNames: ['clickable'],

    isEditing: false,

    updateClient: function updateClient() {
      var newValue = this.get('newValue');
      var client = this.get('client');
      var index = this.get('index');
      var propertyName = this.get('propertyName');

      var propertyList = client.get(propertyName);
      propertyList[index] = newValue;
      propertyList.sort();
      client.set(propertyName, propertyList);
      client.save();

      this.set("isEditing", false);
    },

    actions: {
      update: function update(newValue) {
        this.set('newValue', newValue);
        Ember.run.debounce(this, this.updateClient, 300);
      },
      openDeleteModal: function openDeleteModal() {
        this.openDeleteModal(this.get('index'));
      }
    }
  });
});