define('dashboard/utils/hidden-modal-properties', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {

    default: {
      isHidden: true
    },

    salesTax: {
      title: 'Sales Taxes',
      isHidden: true,
      dataProp: 'salesTaxBreakdownTableData',
      fiProperty: 'fiSalesTaxes'
    },

    realProperty: {
      title: 'Real Property',
      isHidden: true,
      dataProp: 'realPropertyTaxBreakdownTableData',
      fiProperty: 'fiRealPropertyTaxes'
    },

    incomeTax: {
      title: 'Income Taxes',
      isHidden: true,
      dataProp: 'incomeTaxBreakdownTableData',
      fiProperty: 'fiLocalIncomeTaxes'
    }

  };
});