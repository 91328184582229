define('dashboard/routes/tax-district', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    titleToken: function titleToken(models) {
      return ['Tax Districts', models.district.get('name')];
    },

    notify: Ember.inject.service(),

    model: function model(params) {

      // @TODO deal with parent districts later - right now they slow the page too much by loading large data sets
      var districtPromise = this.store.findRecord('taxDistrict', params.tax_district_id),
          parentDistrictPromise = this.store.queryRecord('taxDistrict', { childDistrict: params.tax_district_id }),

      // parentDistrictOptionsPromise = this.store.query('taxDistrict', { parentDistrictOptionsFor: params.tax_district_id }),
      multiplierSetsPromise = this.store.findAll('multiplierSet');

      return Ember.RSVP.hash({
        district: districtPromise,
        multiplierSets: multiplierSetsPromise,
        parentDistrict: parentDistrictPromise
        // parentDistrictOptions: parentDistrictOptionsPromise
      });
    },
    setupController: function setupController(controller, models) {
      controller.setProperties({
        model: models.district,
        allMultiplierSets: models.multiplierSets,
        parentDistrict: models.parentDistrict,
        parentDistrictOptions: []
      });

      this.controllerFor('tax-districts').set('currentDistrict', models.district);
    },
    afterModel: function afterModel(models) {
      this.controllerFor('tax-districts').set('currentdistrict', models.model);
    },


    actions: {

      // This is a duplicate of the action in the tax-districts/index controller
      // This should be removed once we add sorting and search functionality for
      // list views
      cloneDistrict: function cloneDistrict(oldDistrict) {
        var newDistrict = this.store.createRecord('taxDistrict'),
            customCostRevenues = oldDistrict.get('customCostRevenues'),
            directCostRevenues = oldDistrict.get('directCostRevenues'),
            oldDistrictProperties = oldDistrict.toJSON(),
            globalCustomCostRevs = void 0,
            self = this;

        // Remove read only properties that can not be set on new district
        delete oldDistrictProperties.customCostRevenues;
        delete oldDistrictProperties.directCostRevenues;
        delete oldDistrictProperties.scenarios;
        delete oldDistrictProperties.created;
        delete oldDistrictProperties.districtGroups;

        for (var property in oldDistrictProperties) {
          newDistrict.set(property, oldDistrict.get(property));
        }

        newDistrict.set('name', 'Copy of ' + oldDistrict.get('name'));

        function copyCustomCostRevs() {
          return Ember.RSVP.all(customCostRevenues.map(function (oldCostRev) {
            var costRevToCreate = oldCostRev.toJSON();
            delete costRevToCreate.created;
            return self.store.createRecord('customCostRevenue', costRevToCreate).save();
          }));
        }

        function copyDirectCostRevs(copiedCustomCostRevs) {
          globalCustomCostRevs = copiedCustomCostRevs;
          return Ember.RSVP.all(directCostRevenues.map(function (oldCostRev) {
            var costRevToCreate = oldCostRev.toJSON();
            delete costRevToCreate.created;
            return self.store.createRecord('directCostRevenue', costRevToCreate).save();
          }));
        }

        function associateCostRevs(copiedDirectCostRevs) {
          newDistrict.get('customCostRevenues').pushObjects(globalCustomCostRevs);
          newDistrict.get('directCostRevenues').pushObjects(copiedDirectCostRevs);
          return newDistrict.save();
        }

        function goToClonedDistrict(district) {
          self.transitionTo('tax-district', district.get('id'));
          self.get('notify').info('District successfully cloned. You are now editing the cloned district.');
        }

        function onError(error) {
          console.log('error ', error);
          self.get('notify').alert('There was a problem saving the cloned district.');
        }

        copyCustomCostRevs().then(copyDirectCostRevs).then(associateCostRevs).then(goToClonedDistrict).catch(onError);
      },
      willTransition: function willTransition(transition) {
        var taxDistrict = this.controller,
            name = taxDistrict.get('model.name');

        if (name === '' || name === null) {
          this.get('notify').alert('Tax district must have a name');
          transition.abort();
        }
      }
    }
  });
});