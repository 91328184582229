define('dashboard/controllers/request-password', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    ajax: Ember.inject.service(),
    notify: Ember.inject.service(),

    actions: {
      requestPassword: function requestPassword() {
        var _this = this;

        if (!this.get('email')) {
          return this.get('notify').alert('Email can not be blank');
        }

        this.get('ajax').post('/resetPassword', {
          data: JSON.stringify({
            email: this.get('email'),
            reason: 'passwordReset'
          }),
          contentType: 'application/json'
        }).then(function (response) {
          console.log('reset password response ', response);
          _this.get('notify').info('Email sent');
          Ember.run.later(_this, function () {
            _this.transitionToRoute('login');
          }, 1500);
        }).catch(function (error) {
          var errorMessage = error.payload.error;
          console.log('reset password error ', error);
          _this.get('notify').alert(errorMessage);
        });
      }
    }

  });
});