define('dashboard/components/scenario-input-advanced', ['exports', 'dashboard/components/scenario-input-base', 'dashboard/objects/column', 'dashboard/utils/make-inputs'], function (exports, _scenarioInputBase, _column, _makeInputs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _scenarioInputBase.default.extend({

    yearlyInputs: Ember.computed('years', 'scenario', function () {
      var dataPropertyName = this.get('dataPropertyName'),
          scenario = this.get('scenario'),
          rows = scenario.get(dataPropertyName),
          years = this.get('years');

      var column = _column.default.create({
        title: this.get('useLongLabel') && this.get('question.longTableHeader') || this.get('question.tableHeader'),
        columnName: 'scenario.' + dataPropertyName,
        content: (0, _makeInputs.default)(rows, years)
      });

      return [column];
    }),

    actions: {
      updateData: function updateData(value) {
        var data = value[0].content.mapBy('value');
        var scenario = this.get('scenario');
        scenario.set(this.get('dataPropertyName'), data);
        this.save();
      }
    }
  });
});