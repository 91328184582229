define('dashboard/mixins/multiplier-set-actions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({

    isArchiveMultiplierSetHidden: true,
    confirmSet: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('sortColumns', [{
        title: '',
        width: '50'
      }, {
        title: 'Multiplier Set Name',
        sortField: 'name'
      }, {
        title: 'Created',
        sortField: 'created'
      }]);
    },


    sortField: 'created',
    sortDir: 'asc',

    archiveMultiplierText: Ember.computed('confirmSet.archived', function () {
      return this.get('confirmSet.archived') ? 'Restore' : 'Archive';
    }),

    actions: {
      openArchiveConfirmModal: function openArchiveConfirmModal(multiplierSet) {
        this.set('isArchiveMultiplierSetHidden', false);
        this.set('confirmSet', multiplierSet);
      },
      archiveMultiplierSet: function archiveMultiplierSet() {
        var _this = this;

        var multiplierSet = this.get('confirmSet');

        multiplierSet.setProperties({
          archived: !multiplierSet.get('archived'),
          name: !multiplierSet.get('archived') ? multiplierSet.get('name') + ' (Archived)' : multiplierSet.get('name').replace(' (Archived)', '')
        });

        multiplierSet.save().then(function () {
          _this.set('isArchiveMultiplierSetHidden', true);
        });
      },
      transitionToSet: function transitionToSet(set) {
        this.get('multiplierSetsController').set('currentMultiplierSet', set);
        this.transitionToRoute('multiplier-set', set.get('id'));
      }
    }

  });
});