define('dashboard/mixins/essentials-actions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({

    ajax: Ember.inject.service(),

    // modal confirming user wants to request full impact analysis for essentials scenario
    requestScenarioUpgradeModalHidden: true,

    // modal confirming request to upgrade to IDB Pro email was sent
    proUpgradeStatusModalHidden: true,

    // tracks progress when sending upgrade request email
    requestingFullImpactAnalysis: false,

    // tracks whether or not the actual upgrade request has been sent
    requestSent: false,

    // tracks progress when sending IDB Pro upgrade request email
    requestingIDBProUpgrade: false,

    // randomly cycle through different upgrade text to encourage clicks
    requestUpgradeText: Ember.computed(function () {
      var choices = ['Request Tax Impact', 'Request Incentive ROI', 'Request Economic Impact'];
      return choices[Math.floor(Math.random() * choices.length)];
    }),

    bestGuessUserId: Ember.computed('currentUser.{id,isSuperAdmin}', 'client.firstUser', function () {
      return this.get('currentUser.isSuperAdmin') ? this.get('client.firstUser.id') : this.get('currentUser.id');
    }),

    actions: {

      // action possible by admins or pro users to change one scenario from essentials to full impact
      upgradeEssentialsScenario: function upgradeEssentialsScenario() {
        if (!this.get('canUpgradeEssentialsScenario')) {
          return;
        }

        if (this.get('currentUser.isSuperAdmin') && !this.get('model.isAdminUnlocked')) {
          this.get('notify').alert('Scenario is Locked');
          return;
        }

        // mostly scenario controller properties so action will not be very reusable without aliases in other controllers
        if (this.get('currentUser.isSuperAdmin') && (!this.get('districtGroups.length') || !this.get('anySelectableDistricts'))) {
          this.get('notify').alert('Client is missing District Groups or Tax Districts');
          return;
        }

        // handle this action in individual controller
        this.send('essentialsUpgradeAction');
      },
      openRequestScenarioUpgradeModal: function openRequestScenarioUpgradeModal(scenario) {
        this.set('scenarioRequestingUpgrade', scenario);
        this.set('requestScenarioUpgradeModalHidden', false);
      },
      sendEssentialsUpgradeRequest: function sendEssentialsUpgradeRequest() {
        // set progress spinner
        this.set('requestingFullImpactAnalysis', true);

        var scenario = this.get('scenarioRequestingUpgrade');

        // send request
        return this.get('ajax').post('/scenario/' + scenario.id + '/requestEssentialsUpgrade', {
          data: JSON.stringify({
            userId: this.get('bestGuessUserId'),
            scenarioTitle: scenario.get('scenarioTitle')
          }),
          contentType: 'application/json'
        });
      },
      requestIDBProUpgrade: function requestIDBProUpgrade(project) {
        var _this = this;

        this.set('requestingIDBProUpgrade', true);
        this.get('ajax').post('/projects/' + project.id + '/requestIDBProUpgrade', {
          data: JSON.stringify({
            userId: this.get('bestGuessUserId')
          }),
          contentType: 'application/json'
        }).then(function () {
          return _this.send('proUpgradeRequestSuccess');
        }).catch(function () {
          return _this.send('proUpgradeRequestFailure');
        });
      },
      onRequestUpgradeSuccess: function onRequestUpgradeSuccess() {
        this.set('requestingFullImpactAnalysis', false);
        this.set('requestSent', true);
      },
      onRequestUpgradeFailure: function onRequestUpgradeFailure(error) {
        console.log('Request Essentials Upgrade Failure ', error);
        this.set('requestingFullImpactAnalysis', false);
        this.get('notify').alert('Something went wrong trying to send your request.');
      },
      proUpgradeRequestSuccess: function proUpgradeRequestSuccess() {
        this.set('requestingIDBProUpgrade', false);
        this.set('proUpgradeStatusModalHidden', false);
      },
      proUpgradeRequestFailure: function proUpgradeRequestFailure(error) {
        console.log('Pro Upgrade Request Failure ', error);
        this.set('requestingIDBProUpgrade', false);
        this.get('notify').alert('Something went wrong trying to send your request.');
      }
    }

  });
});