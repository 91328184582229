define('dashboard/components/naics-and-multiplier-selector', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.set('currentProperties', {
        usesCustomMultipliers: 'scenario.currentUsesCustomMultipliers',
        multiplierCode: 'scenario.currentMultiplierCode',
        earningsMultiplier: 'scenario.currentEarningsMultiplier',
        employmentMultiplier: 'scenario.currentEmploymentMultiplier',
        multiplier: 'scenario.currentMultiplier',
        displayedMultipliers: 'scenarioController.displayedCurrentMultipliers'
      });

      this.set('futureProperties', {
        usesCustomMultipliers: 'scenario.usesCustomMultipliers',
        multiplierCode: 'scenario.multiplierCode',
        earningsMultiplier: 'scenario.earningsMultiplier',
        employmentMultiplier: 'scenario.employmentMultiplier',
        multiplier: 'scenario.multiplier',
        displayedMultipliers: 'scenarioController.displayedMultipliers'
      });
    },


    properties: Ember.computed('current', function () {
      return this.get('current') ? this.get('currentProperties') : this.get('futureProperties');
    }),

    noSpinOffIncluded: Ember.computed.not('scenario.includeSpinoffImpacts'),
    hideCustomOption: Ember.computed.or('disabled', 'noSpinOffIncluded'),

    selectedMultiplier: Ember.computed('properties', 'scenario.{multiplier.code,multiplierCode}', 'scenario.{currentMultiplier.code,currentMultiplierCode}', function () {
      var properties = this.get('properties');
      var multiplierModel = this.get(properties.multiplier);
      var selected = multiplierModel;

      var hasMultiplierCode = !!this.get(properties.multiplierCode);
      if (!multiplierModel.get('code') && hasMultiplierCode) {
        selected = Ember.Object.create({
          code: this.get(properties.multiplierCode)
        });
      }

      return selected;
    }),

    actions: {
      selectMultipliers: function selectMultipliers(multiplier) {
        var properties = this.get('properties');
        this.set(properties.usesCustomMultipliers, false);

        if (!multiplier.get) {
          multiplier = Ember.Object.create(multiplier);
        }

        this.set(properties.multiplierCode, multiplier.get('code'));
        this.set(properties.multiplier, multiplier);

        if (this.get('scenario.includeSpinoffImpacts')) {
          this.set(properties.earningsMultiplier, multiplier.get('earnings'));
          this.set(properties.employmentMultiplier, multiplier.get('employment'));
        }

        this.save();
      },
      useCustomMultipliers: function useCustomMultipliers() {
        var properties = this.get('properties');

        var regionPercent = parseFloat(this.get('scenarioController.primaryDistrict.employmentPercentOfRegion')) || 1,
            employmentMultiplier = parseFloat(this.get(properties.employmentMultiplier)) || 1,
            earningsMultiplier = parseFloat(this.get(properties.earningsMultiplier)) || 1,
            adjustedEmployment = (employmentMultiplier - 1) * 0.5 * (regionPercent + 1) + 1,
            adjustedEarnings = (earningsMultiplier - 1) * 0.5 * (regionPercent + 1) + 1;

        this.set(properties.employmentMultiplier, adjustedEmployment);
        this.set(properties.earningsMultiplier, adjustedEarnings);
        this.set(properties.usesCustomMultipliers, true);
        setTimeout(function () {
          this.$('.employment-input input').focus();
        }, 100);
        this.save();
      },


      // set to previous multiplier value if available from multiplier model
      useStandardMultipliers: function useStandardMultipliers() {
        var _get = this.get('properties'),
            employmentMultiplier = _get.employmentMultiplier,
            earningsMultiplier = _get.earningsMultiplier,
            usesCustomMultipliers = _get.usesCustomMultipliers,
            multiplier = _get.multiplier;

        var multiplierModel = this.get(multiplier),
            employment = '1',
            earnings = '1';

        if (multiplierModel) {
          employment = multiplierModel.get('employment');
          earnings = multiplierModel.get('earnings');
        }

        // Make sure this shows adjusted multiplier in NAICS model instead of showing raw mults
        this.set(employmentMultiplier, employment);
        this.set(earningsMultiplier, earnings);
        this.set(usesCustomMultipliers, false);
        this.save();
      },
      updateMultiplier: function updateMultiplier() {
        var properties = this.get('properties');

        var multiplierModel = this.get(properties.multiplier);
        var currentEarnings = this.get(properties.earningsMultiplier),
            currentEmployment = this.get(properties.employmentMultiplier);

        if (multiplierModel) {
          if (currentEarnings.toString() !== multiplierModel.get('earnings') || currentEmployment.toString() !== multiplierModel.get('employment')) {
            this.save();
          }
        }
      }
    }
  });
});