define('dashboard/routes/scenario/report/community', ['exports', 'ember-local-storage', 'dashboard/mixins/reset-scroll-position'], function (exports, _emberLocalStorage, _resetScrollPosition) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_resetScrollPosition.default, {

    controllerName: 'scenario.report',

    sessionData: (0, _emberLocalStorage.storageFor)('session-data'),

    beforeModel: function beforeModel() {
      this.set('sessionData.reportSelection', 'community');
    },
    model: function model() {
      return this.modelFor('scenario').scenario;
    },
    setupController: function setupController(controller, model) {
      // if this is the user's first time visiting the community report, open the config modal
      if (!model.get('visitedCommunityReport')) {
        setTimeout(function () {
          controller.set('isConfigureCommunityReportHidden', false);
        }, 500);
      }
    }
  });
});