define('dashboard/objects/column', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({

    total: Ember.computed('content.@each.value', function () {
      return this.get('content').reduce(function (sum, current) {
        var value = current.get('value');
        value = demask(value);
        if (Number.isNaN(value)) {
          value = 0;
        }
        return sum + parseFloat(value);
      }, 0);
    }),

    isDollars: Ember.computed.equal('inputType', 'dollars'),

    cellValues: Ember.computed('content.@each.value', function () {
      return this.get('content').map(function (cell) {
        return demask(cell.get('value'));
      });
    })

  });


  function demask(number) {
    if (typeof number === 'string') {
      number = parseFloat(number.replace(/[$,%]/, ''));
    }

    return number;
  }
});