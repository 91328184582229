define('dashboard/objects/fi-tax/cost-per-student', ['exports', 'dashboard/utils/percent-each', 'dashboard/utils/appreciated-annual-array', 'dashboard/objects/fi-tax/base'], function (exports, _percentEach, _appreciatedAnnualArray, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    costPerStudent: Ember.computed('cost', 'costIncrease', 'cumulativeNewStudents', 'doesApply', function () {
      var cost = -1 * this.get('cost') || 0,
          costIncrease = (0, _percentEach.percentify)(this.get('costIncrease')) || 0,
          cumulativeNewStudents = this.get('cumulativeNewStudents') || [],
          doesApply = this.get('doesApply');

      return (0, _appreciatedAnnualArray.default)(cumulativeNewStudents, cost, costIncrease, doesApply);
    }),

    workers: Ember.computed.sum('costPerStudent')
  });
});