define('dashboard/models/multiplier', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    multiplierSet: (0, _attr.default)('string'),
    code: (0, _attr.default)('string'),
    employment: (0, _attr.default)('string'),
    earnings: (0, _attr.default)('string')
  });
});