define('dashboard/controllers/client', ['exports', 'dashboard/config/environment', 'dashboard/fixtures/states', 'dashboard/mixins/sorting', 'dashboard/mixins/tif-district-actions', 'dashboard/mixins/tagging', 'dashboard/mixins/color-scheme', 'dashboard/utils/ordered-search'], function (exports, _environment, _states, _sorting, _tifDistrictActions, _tagging, _colorScheme, _orderedSearch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_sorting.default, _tifDistrictActions.default, _tagging.default, _colorScheme.default, {

    ajax: Ember.inject.service(),
    notify: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('transitionResults', []);
      this.set('dateInputOptions', {
        minDate: new Date(2000, 1, 1)
      });
      this.set('taxDistricts', []);
      // this.set('assumption', {});
      this.set('s3UserFiles', []);
      this.set('tifActionOptions', { adminCreated: true });

      this.set('defaultMarketValueFFE', ['1.0', '0.9', '0.8', '0.7', '0.6', '0.5', '0.4', '0.3', '0.2', '0.2']);
      this.set('sortColumns', [{
        title: 'File Name',
        sortField: 'name'
      }, {
        title: 'Last Modified',
        sortField: 'date'
      }]);

      this.set('incentiveTerms', ['Abatement', 'Rebate', 'Exemption', 'Reimbursement']);
      this.set('rankedGroupsSort', ['groupOrder:asc']);
      this.set('salesTaxLocalOnlyOptions', [{ value: true, label: 'Local Only' }, { value: false, label: 'State Included' }]);
    },


    isTransitionClientHidden: true,
    transitionStatusHidden: true,
    transitioning: false,
    preTransitionProgress: false,
    scenariosTransitioned: 0,
    transitionSuccessModalHidden: true,
    textTensesModalHidden: true,
    isDeleteDependentGroupLinkModalHidden: true,

    stateOptions: _states.default,
    stateSearch: _orderedSearch.default,

    groupingLabel: '',

    resetDistrictSelect: false,
    imgIsLoaded: false,

    editingTifModalHidden: true,

    previousTransition: null,

    isUnsavedUserModalHidden: true,
    isUnsavedClientModalHidden: true,
    wasNotifiedOfErrors: false,

    dependentDistrictGroupLinkToDelete: null,

    creatingDependentDistrictGroupLink: false,
    editingOptionalGroups: false,

    subscription: Ember.computed.alias('client.subscription'),

    cloningDisabled: Ember.computed.alias('model.cloningInProgress'),

    filteredTifDistricts: Ember.computed.filterBy('tifDistricts', 'forScenario', false),

    userGuideFileName: Ember.computed('client.userGuideUrl', function () {
      return this.get('client.userGuideUrl').split('guides/').pop();
    }),

    scenarioLength: Ember.computed('assumption.scenarioLength', function () {
      return parseInt(this.get('assumption.scenarioLength'));
    }),

    rankedGroups: Ember.computed.sort('model.districtGroups', 'rankedGroupsSort'),

    marketValueFFE: Ember.computed('assumption.percentMarketValueFFE.@each', function () {

      var values = this.get('assumption.percentMarketValueFFE') || [];

      return values.map(function (value, index) {
        return Ember.Object.create({
          val: value,
          label: 'Year ' + (index + 1)
        });
      });
    }),

    unboundMarketValueFFE: Ember.computed(function () {
      return this.get('marketValueFFE');
    }),

    allGroupDistricts: Ember.computed('rankedGroups.@each.districts', function () {
      var groups = this.get('rankedGroups'),
          districts = [];

      groups.forEach(function (group) {
        group.get('districts').forEach(function (district) {
          districts.push(district);
        });
      });

      return districts;
    }),

    // will be null if different config is used so prop could be used as bool too
    selectedDistrictAreaConfig: Ember.computed('model.districtConfigType', 'districtAreaSets', function () {
      return this.get('districtAreaSets').findBy('id', this.get('model.districtConfigType'));
    }),

    selectedDependentDistrictsConfig: Ember.computed('model.districtConfigType', 'dependentTaxDistrictSets', function () {
      return this.get('dependentTaxDistrictSets').findBy('id', this.get('model.districtConfigType'));
    }),

    selectedDistrictConfig: Ember.computed('model.districtConfigType', 'selectedDistrictAreaConfig', 'selectedDependentDistrictsConfig', function () {
      if (!this.get('model.districtConfigType')) {
        return Ember.Object.create({ id: null, name: 'Standard' });
      }

      return this.get('selectedDistrictAreaConfig') || this.get('selectedDependentDistrictsConfig');
    }),

    selectedSalesTaxLocalOnly: Ember.computed('assumption.salesTaxLocalOnly', function () {
      return this.get('salesTaxLocalOnlyOptions').findBy('value', this.get('assumption.salesTaxLocalOnly'));
    }),

    districtConfigOptions: Ember.computed('districtAreaSets.length', 'dependentTaxDistrictSets.length', function () {
      return [{
        groupName: 'Default',
        options: [Ember.Object.create({ id: null, name: 'Standard' })]
      }, {
        groupName: 'District Areas',
        options: this.get('districtAreaSets').sortBy('name').toArray()
      }, {
        groupName: 'Dependent Districts',
        options: this.get('dependentTaxDistrictSets').sortBy('name').toArray()
      }];
    }),

    canAttachDistrict: Ember.computed('selectedDistrict', 'selectedGroup', function () {
      return !this.get('selectedDistrict') || !this.get('selectedGroup');
    }),

    updateIncentiveTerm: Ember.observer('assumption.{propertyTaxIncentiveTerm,salesTaxIncentiveTerm,materialsEquipmentTaxIncentiveTerm,hotelTaxIncentiveTerm}', function () {
      var defaultAssumption = this.get('assumption');
      if (defaultAssumption) defaultAssumption.save();
    }),

    filteredDependentDistrictGroupLinks: Ember.computed('dependentDistrictGroupLinks', 'dependentDistrictGroupLinks.{[],length,each}', 'dependentTaxDistrictSet', 'selectedDependentDistrictsConfig', function () {
      return this.get('dependentDistrictGroupLinks').filterBy('dependentTaxDistrictSet.id', this.get('selectedDependentDistrictsConfig.id'));
    }),

    showOptionalGroups: Ember.computed('model.optionalGroups.{length,[].@each}', 'editingOptionalGroups', function () {
      return !this.get('editingOptionalGroups') && this.get('model.optionalGroups.length');
    }),

    actions: {
      activate: function activate(client) {
        this.send('activateClient', client);
      },
      openTransitionClientModal: function openTransitionClientModal() {
        this.set('isTransitionClientHidden', false);
      },


      // action sent from first transition confirmation modal
      preTransitionDistricts: function preTransitionDistricts() {
        var type = 'Standard';
        if (this.get('model.districtConfigType') && this.get('model.districtConfigType') !== 'standard') {
          type = 'DistrictAreas';
        }
        this.send('getScenariosToTransition', type);
      },


      // action sent from client-transition-status-modal component
      transitionDistricts: function transitionDistricts(scenarioIdsToTransition) {
        var type = 'Standard';
        if (this.get('model.districtConfigType') && this.get('model.districtConfigType') !== 'standard') {
          type = 'DistrictAreas';
        }
        this.send('transition', scenarioIdsToTransition, type);
      },


      // pre transition step that retrieves scenarios, projects, and clients which will be affected by transition
      getScenariosToTransition: function getScenariosToTransition(type) {
        var _this = this;

        var clientId = this.get('model.id');

        this.set('preTransitionProgress', true);

        this.get('ajax').request('/clients/' + clientId + '/scenariosToTransition' + type).then(function (response) {

          _this.setProperties({
            preTransitionProgress: false,
            isTransitionClientHidden: true
          });

          if (response.scenarios && response.scenarios.length) {
            _this.setProperties({
              transitionResults: response,
              isTransitionClientHidden: true,
              transitionStatusHidden: false
            });
          } else {
            _this.get('notify').info('No scenarios to update');
          }
        }).catch(function (reason) {
          _this.set('preTransitionProgress', false);
          console.log('pre transition failure ', reason);
          _this.get('notify').alert('There was a problem retrieving this client\'s scenarios');
        });
      },


      // actual transition action which will send request to make copies of tax districts for each scenario
      // to disconnect client's tax districts from any existing scenarios
      transition: function transition(scenarioIds, type) {
        var _this2 = this;

        var clientId = this.get('model.id');
        var route = type === 'DistrictAreas' ? 'yearlyDistrictAreaTransition' : 'yearlyDistrictTransition';

        this.setProperties({
          transitioning: true,
          scenariosTransitioned: 0
        });

        this.get('ajax').post('/clients/' + clientId + '/' + route, {
          data: JSON.stringify({ scenarioIds: scenarioIds }),
          dataType: 'json',
          contentType: "application/json; charset=utf-8"
        }).then(function (response) {
          _this2.get('model').reload();

          _this2.set('transitioning', false);

          if (response.scenarios && response.scenarios.length) {
            _this2.setProperties({
              scenariosTransitioned: response.scenarios,
              transitionSuccessModalHidden: false,
              transitionStatusHidden: true,
              transitionResults: []
            });
          } else {
            _this2.get('notify').info('No scenarios to update');
          }
        }).catch(function (reason) {
          _this2.set('transitioning', false);
          console.log('transition failure ', reason);
          _this2.get('notify').alert('There was a problem updating this client\'s scenarios');
        });
      },
      imgLoaded: function imgLoaded(evt) {
        var imgWrapper = evt.currentTarget.parentNode;

        imgWrapper.className += imgWrapper.className ? ' loaded' : 'loaded';
        this.set('imgIsLoaded', true);
      },
      updateScenarioLength: function updateScenarioLength(years) {
        var marketValueFFE = this.get('assumption.percentMarketValueFFE'),
            currentLength = marketValueFFE.get('length'),
            assumption = this.get('assumption'),
            defaultFFE = this.get('defaultMarketValueFFE'),
            i = void 0;

        if (years > currentLength) {
          for (i = currentLength; i < years; i++) {
            marketValueFFE[i] = defaultFFE[i] ? defaultFFE[i] : '0.2';
          }
        }

        marketValueFFE = marketValueFFE.slice(0, years);
        this.set('assumption.percentMarketValueFFE', marketValueFFE);
        this.notifyPropertyChange('unboundMarketValueFFE');
        assumption.save();
      },
      updateClient: function updateClient() {
        var client = this.get('model');
        Ember.run.debounce(client, 'save', 100);
      },
      saveState: function saveState(state) {
        this.set('model.state', state);
        this.send('updateClient');
      },
      saveDistrictConfig: function saveDistrictConfig(config) {
        this.set('model.districtConfigType', config.get('id'));
        this.send('updateClient');
      },
      saveSalesTaxLocalOnly: function saveSalesTaxLocalOnly(selected) {
        var assumption = this.get('assumption');
        assumption.set('salesTaxLocalOnly', selected.value);
        assumption.save();
      },
      updateClientStatus: function updateClientStatus(selected) {
        var model = this.get('model');

        if (selected.value === 'full') {
          this.get('notify').info('Make sure this client has District Groups and Tax Districts', { closeAfter: 5000 });
        }

        model.updateClientStatus(selected.value);
        model.save();
      },
      updateUser: function updateUser() {
        // User is an async property of the client model
        var user = this.get('user'),
            notify = this.get('notify');

        function error(reason) {
          var errorMessage = reason.errors[0];
          console.log('reason ', reason);

          if (errorMessage.includes('email')) {
            notify.alert('Email must be unique.');
            user.rollbackAttributes();
          } else if (errorMessage.includes('username')) {
            notify.alert('Username must be unique.');
            user.rollbackAttributes();
          } else {
            notify.alert('An error occurred while trying to save the user.');
          }
        }

        user.save().then().catch(error);
      },
      removeUserGuide: function removeUserGuide() {
        var _this3 = this;

        var client = this.get('client');

        client.set('userGuide', null);
        client.save().then(function () {
          return _this3.get('notify').info('User guide removed successfully');
        });
      },
      updateFFE: function updateFFE() {
        var unboundMarketValueFFE = this.get('unboundMarketValueFFE').map(function (item) {
          return item.get('val').toString();
        }),
            assumption = this.get('assumption');

        // values = values.map(item => {
        //   return item.val.toString();
        // });

        assumption.set('percentMarketValueFFE', unboundMarketValueFFE);
        assumption.save();
      },
      createGroup: function createGroup() {
        var _this4 = this;

        var label = this.get('groupingLabel'),
            client = this.get('model'),
            groups = client.get('districtGroups');

        if (!Ember.isEmpty(label)) {
          this.store.createRecord('districtGroup', {
            label: label,
            groupOrder: groups.get('length')
          }).save().then(function (group) {
            client.get('districtGroups').pushObject(group);
            _this4.set('groupingLabel', '');
            client.save();
          });
        }
      },
      removeGroup: function removeGroup(group) {
        var _this5 = this;

        var client = this.get('model'),
            projects = client.get('projects'),
            scenarios = this.store.peekAll('scenario'),
            groupDistricts = group.get('districts'),
            groupPreviousDistricts = group.get('previousDistricts'),
            districtUsed = false;

        projects.forEach(function (project) {
          var projectScenarios = scenarios.filter(function (scenario) {
            return scenario.get('project.content') === project;
          });

          projectScenarios.forEach(function (scenario) {
            scenario.get('districts').forEach(function (district) {

              if (groupDistricts.findBy('id', district.get('id')) || groupPreviousDistricts.findBy('id', district.get('id'))) {
                districtUsed = true;
              }
            });
          });
        });

        if (districtUsed === false) {

          // Remove tif schedules referencing this group
          var promiseArray = [];
          var scheduleWithGroup = void 0;
          this.get('filteredTifDistricts').forEach(function (tif) {
            scheduleWithGroup = tif.get('tifSchedules').find(function (schedule) {
              return schedule.get('group.id') === group.get('id');
            });
            if (scheduleWithGroup) {
              tif.get('tifSchedules').removeObject(scheduleWithGroup);
              promiseArray.push(tif.save());
            }
          });
          Ember.RSVP.all(promiseArray);

          // remove district group reference from client
          client.get('districtGroups').removeObject(group);

          // destroy district group
          client.save().then(function () {
            group.destroyRecord().then(function () {
              _this5.send('reorderGroups');
            });
          });
        } else {
          this.get('notify').alert('Group contains districts being used in this client\'s scenarios.');
        }
      },
      reorderGroups: function reorderGroups() {
        var groups = this.get('model.districtGroups'),
            promiseArray = [];

        groups.forEach(function (group, index) {
          group.set('groupOrder', index);
          promiseArray.push(group.save());
        });

        Ember.RSVP.all(promiseArray);
      },
      promote: function promote(group) {
        var currentOrder = group.get('groupOrder'),
            previousOrder = currentOrder - 1,
            groups = this.get('model.districtGroups'),
            previousGroup = groups.findBy('groupOrder', previousOrder);

        if (previousOrder >= 0) {
          group.set('groupOrder', previousOrder);
          group.save();
          previousGroup.set('groupOrder', currentOrder);
          previousGroup.save();
        }
      },
      demote: function demote(group) {
        var currentOrder = group.get('groupOrder'),
            nextOrder = currentOrder + 1,
            groups = this.get('model.districtGroups'),
            nextGroup = groups.findBy('groupOrder', nextOrder);

        if (nextGroup) {
          group.set('groupOrder', nextOrder);
          group.save();
          nextGroup.set('groupOrder', currentOrder);
          nextGroup.save();
        }
      },
      searchTaxDistricts: function searchTaxDistricts(searchPhrase) {
        var _this6 = this;

        if (searchPhrase.length < 3) {
          return;
        }

        return this.store.query('taxDistrict', {
          name: searchPhrase,
          adminCreated: true,
          isActive: true,
          createdInTransition: { $ne: true }
        }).then(function (districts) {
          var filteredDistricts = districts.removeObjects(_this6.get('allGroupDistricts'));
          return filteredDistricts.sortBy('name');
        });
      },
      attachDistrict: function attachDistrict() {
        var _this7 = this;

        var groups = this.get('model.districtGroups'),
            selectedDistrict = this.get('selectedDistrict'),
            group = this.get('selectedGroup');

        if (!selectedDistrict || !group) {
          return;
        }

        this.setProperties({
          selectedDistrict: null,
          selectedGroup: null,
          resetDistrictSelect: true
        });

        groups.forEach(function (group) {
          var previousDistricts = group.get('previousDistricts') || [];
          if (previousDistricts.indexOf(selectedDistrict) > -1) {
            previousDistricts.removeObject(selectedDistrict);

            group.save().then(function () {
              _this7.set('resetDistrictSelect', false);
            });
          }
        });

        selectedDistrict.get('districtGroups').pushObject(group);
        selectedDistrict.save();

        group.get('districts').pushObject(selectedDistrict);
        group.save().then(function () {
          _this7.get('model').reload();
          _this7.set('resetDistrictSelect', false);
        });
      },
      detachDistrict: function detachDistrict(group, district) {
        var _this8 = this;

        var previousDistricts = group.get('previousDistricts') || [];

        previousDistricts.pushObject(district);
        group.get('districts').removeObject(district);
        group.save().then(function () {
          _this8.notifyPropertyChange('allGroupDistricts');
        });
      },
      downloadClientsDistricts: function downloadClientsDistricts() {
        window.location.href = _environment.default.APP.api_host + '/api/taxDistricts/downloads/?client=' + this.get('model.id');
      },


      // triggered after creation or copying of TIF
      // overwrites action in mixin
      selectTifDistrict: function selectTifDistrict(selectedTifDistrictId) {
        var tifDistrict = this.get('filteredTifDistricts').findBy('id', selectedTifDistrictId);
        this.setProperties({
          selectedTifDistrict: tifDistrict,
          editingTifModalHidden: false
        });
      },
      createTaxDistrict: function createTaxDistrict() {
        var _this9 = this;

        this.get('store').createRecord('taxDistrict').save().then(function (taxDistrict) {
          _this9.get('router').transitionTo('tax-district', taxDistrict.id);
        });
      },
      transitionToClientPage: function transitionToClientPage(route, clientID) {
        this.get('router').transitionTo(route, clientID);
      },
      transitionToClientProjects: function transitionToClientProjects(clientID) {
        this.send('transitionToClientPage', 'projects', clientID);
      },
      transitionToClientInbox: function transitionToClientInbox(clientID) {
        this.send('transitionToClientPage', 'data-forms.inbox', clientID);
      },
      clone: function clone(client) {
        this.send('cloneClient', client);
      },
      rollbackUserChanges: function rollbackUserChanges() {
        var transition = this.get('previousTransition');

        this.get('user').rollbackAttributes();
        this.send('hideUnsavedUserModal');
        this.set('wasNotifiedOfErrors', true);
        transition.retry();
      },
      hideUnsavedUserModal: function hideUnsavedUserModal() {
        this.setProperties({ previousTransition: null, isUnsavedUserModalHidden: true });
      },
      rollbackClientChanges: function rollbackClientChanges() {
        var transition = this.get('previousTransition');

        this.get('client').rollbackAttributes();
        this.send('hideUnsavedClientModal');
        this.set('wasNotifiedOfErrors', true);
        transition.retry();
      },
      hideUnsavedClientModal: function hideUnsavedClientModal() {
        this.setProperties({ previousTransition: null, isUnsavedClientModalHidden: true });
      },
      createDependentDistrictGroupLink: function createDependentDistrictGroupLink() {
        this.set('creatingDependentDistrictGroupLink', true);

        var newLink = this.store.createRecord('dependentDistrictGroupLink', {
          matchingText: '',
          districtGroups: [],
          client: this.get('client'),
          dependentTaxDistrictSet: this.get('selectedDependentDistrictsConfig')
        });
        this.set('newDependentDistrictGroupLink', newLink);
      },
      cancelCreateDependentDistrictGroupLink: function cancelCreateDependentDistrictGroupLink() {
        this.set('creatingDependentDistrictGroupLink', false);
        this.get('newDependentDistrictGroupLink').deleteRecord(); // wasn't saved yet anyway so this will clear out fields
      },
      cancelEditingDependentDistrictGroupLink: function cancelEditingDependentDistrictGroupLink(link) {
        link.set('editing', false);
        link.rollbackAttributes();
        link.reload();
      },
      saveNewDependentDistrictGroupLink: function saveNewDependentDistrictGroupLink() {
        var _this10 = this;

        this.get('newDependentDistrictGroupLink').save().then(function (savedLink) {
          _this10.get('dependentDistrictGroupLinks').pushObject(savedLink);
          _this10.set('creatingDependentDistrictGroupLink', false);
        }).catch(function (error) {
          console.log('dependentDistrictGroupLink create error ', error);
          _this10.get('notify').alert('Something went wrong saving this link');
        });
      },
      saveDependentGroupLink: function saveDependentGroupLink(link) {
        var _this11 = this;

        if (!link) return;

        link.save().then(function () /*savedLink*/{
          link.set('editing', false);
        }).catch(function (error) {
          console.log('dependentDistrictGroupLink edit error ', error);
          _this11.get('notify').alert('Something went wrong saving this link');
        });
      },
      confirmDeleteDependentGroupLink: function confirmDeleteDependentGroupLink(groupLink) {
        this.set('isDeleteDependentGroupLinkModalHidden', false);
        this.set('dependentDistrictGroupLinkToDelete', groupLink);
      },
      cancelDeleteDependentDistrictGroupLink: function cancelDeleteDependentDistrictGroupLink() {
        this.set('dependentDistrictGroupLinkToDelete', null);
        this.set('isDeleteDependentGroupLinkModalHidden', true);
      },
      deleteDependentDistrictGroupLink: function deleteDependentDistrictGroupLink(link) {
        if (!link) return;
        this.get('dependentDistrictGroupLinks').removeObject(link);
        link.destroyRecord();
      },
      saveOptionalGroups: function saveOptionalGroups() {
        var _this12 = this;

        this.get('client').save().then(function () {
          _this12.set('editingOptionalGroups', false);
        });
      },
      cancelEditingOptionalGroups: function cancelEditingOptionalGroups() {
        var _this13 = this;

        this.get('client').rollbackAttributes();
        this.get('client').reload();
        setTimeout(function () {
          _this13.set('editingOptionalGroups', false);
        }, 200);
      },
      removeOptionalGroups: function removeOptionalGroups() {
        this.set('client.optionalGroups', []);
        this.send('saveOptionalGroups');
      }
    }
  });
});