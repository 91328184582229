define('dashboard/components/modals/client-transition-status-modal', ['exports', 'dashboard/components/modals/modal-confirm', 'lodash/array'], function (exports, _modalConfirm, _array2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _modalConfirm.default.extend({

    indexedResults: Ember.computed('results.{clients,projects,scenarios}', function () {
      var _this = this;

      var results = this.get('results'),
          clients = results.clients || [],
          projects = results.projects || [],
          scenarios = results.scenarios || [];

      if (!clients || !scenarios || !projects) {
        return [];
      }

      return clients.map(function (client) {

        // aggregate scenarios under each project
        var filteredProjects = projects.filterBy('client', client.id);
        filteredProjects.forEach(function (project) {
          var projectScenarios = scenarios.filterBy('project', project.id).sortBy('created').toArray();
          var index = 1;
          projectScenarios.forEach(function (scenario) {
            scenario.displayName = _this.getScenarioDisplayName(scenario, project, index);
          });
          project.scenarios = projectScenarios;
        });
        client.projects = filteredProjects;

        // deal with data requests which aren't necessarily associated with a project yet
        client.dataRequests = scenarios.filter(function (scenario) {
          return scenario.client === client.id && scenario.isRequest && scenario.project === null;
        });

        return client;
      });
    }),

    getScenarioDisplayName: function getScenarioDisplayName(scenario, project, index) {
      var displayName = '';
      if (scenario.subtitle) {
        displayName = scenario.subtitle;
      } else if (scenario.isDeleted) {
        displayName = 'Deleted Scenario';
      } else if (scenario.isRequest) {
        displayName = 'Scenario Data Request';
      } else {
        displayName = 'Scenario ' + index;
        index++;
      }

      return displayName;
    },


    transitionCounts: Ember.computed('indexedResults.@each.{scenarios.length,projects.length,client.length}', function () {
      var results = this.get('results'),
          scenarioCount = _array2.default.flatten(results.scenarios).length,
          projectCount = _array2.default.flatten(results.projects).length,
          clientCount = _array2.default.flatten(results.clients).length,
          pluralScenarios = scenarioCount > 1 ? 'Scenarios' : 'Scenario',
          pluralProjects = projectCount > 1 ? 'Projects' : 'Project',
          pluralClients = clientCount > 1 ? 'Clients' : 'Client';

      return { scenarioCount: scenarioCount, projectCount: projectCount, clientCount: clientCount, pluralScenarios: pluralScenarios, pluralProjects: pluralProjects, pluralClients: pluralClients };
    }),

    transitionMessage: Ember.computed('transitionCounts', function () {
      var _get = this.get('transitionCounts'),
          scenarioCount = _get.scenarioCount,
          projectCount = _get.projectCount,
          clientCount = _get.clientCount,
          pluralScenarios = _get.pluralScenarios,
          pluralProjects = _get.pluralProjects,
          pluralClients = _get.pluralClients,
          message = '';

      if (scenarioCount && clientCount) {
        message = 'Preparing to transition ' + scenarioCount + ' ' + pluralScenarios + ' in ' + projectCount + ' ' + pluralProjects + ' for ' + clientCount + ' ' + pluralClients;
      }

      return message;
    }),

    actions: {
      submitTransition: function submitTransition() {
        var scenarioIds = [];
        var scenarios = this.get('results.scenarios');

        if (scenarios.length) {
          scenarioIds = scenarios.mapBy('id');
        }

        this.sendAction('transitionAction', scenarioIds);
      }
    }

  });
});