define('dashboard/helpers/format-longnum', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatLongnum = formatLongnum;
  function formatLongnum(params) {
    var number = params[0];

    if (number === undefined || isNaN(number)) {
      number = 0;
    }

    number = Math.round(number * 10000) / 10000;
    return numeral(number).format('0,0.0[00000000]');
  }

  exports.default = Ember.Helper.helper(formatLongnum);
});