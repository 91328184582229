define('dashboard/routes/data-forms/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    redirect: function redirect() /* model, transition*/{
      this.transitionTo('data-forms.inbox', { queryParams: { selectedTab: 'inbox' } });
    }
  });
});