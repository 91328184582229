define("dashboard/fixtures/accepted-mime-types", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var PREFIX = "application/vnd";
  var MACRO = "macroEnabled.12";
  var TEXT_TYPES = ["document", "template"];
  var SHEET_TYPES = ["sheet", "template", "sheet.binary", "addin"];
  var SLIDE_TYPES = ["presentation", "slideshow", "template", "addin", "slide"];

  var msMimeType = function msMimeType(appType, subType) {
    return PREFIX + ".ms-" + appType + "." + subType + "." + MACRO;
  };

  var openMimeType = function openMimeType(appType, subType) {
    return PREFIX + ".openxmlformats-officedocument." + appType + "ml." + subType;
  };

  var textMimeTypes = [].concat(_toConsumableArray(TEXT_TYPES.map(function (subType) {
    return msMimeType("word", subType);
  })), _toConsumableArray(TEXT_TYPES.map(function (subType) {
    return openMimeType("wordprocessing", subType);
  })));

  var sheetMimeTypes = [].concat(_toConsumableArray(SHEET_TYPES.map(function (subType) {
    return msMimeType("excel", subType);
  })), _toConsumableArray(SHEET_TYPES.map(function (subType) {
    return openMimeType("spreadsheet", subType);
  })));

  var slideMimeTypes = [].concat(_toConsumableArray(SLIDE_TYPES.map(function (subType) {
    return msMimeType("powerpoint", subType);
  })), _toConsumableArray(SLIDE_TYPES.map(function (subType) {
    return openMimeType("presentation", subType);
  })));

  var documentMimeTypes = ["application/pdf", "text/csv", "application/msonenote", "application/msword"].concat(_toConsumableArray(textMimeTypes), _toConsumableArray(sheetMimeTypes), _toConsumableArray(slideMimeTypes));

  exports.default = ["audio/*", "image/*", "text/*", "video/*"].concat(_toConsumableArray(documentMimeTypes));
});