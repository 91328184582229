define('dashboard/components/graphs/abatements-graph', ['exports', 'dashboard/components/graphs/d3-chart'], function (exports, _d3Chart) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _d3Chart.default.extend({

    attributeBindings: ['style'],

    years2x: Ember.computed('years', function () {
      return this.get('years') * 2;
    }),

    sumData: Ember.computed('data.@each.value', function () {
      var data = this.get('data').slice(0, this.get('years2x')),
          years = this.get('years'),
          sumData = [],
          values = [],
          sum = void 0,
          i = void 0;

      function _sumValues(sum, item) {
        return sum + item.value;
      }

      if (data) {
        for (i = 1; i <= years; i++) {
          values = data.filterBy('year', i);

          sum = values.reduce(_sumValues, 0);

          sumData.push({
            year: i,
            value: sum
          });
        }
      }

      return sumData;
    }),

    dataExists: Ember.computed('data', 'sumData', function () {
      return this.get('data') && this.get('sumData.length') > 0;
    }),

    svg: Ember.computed(function () {
      return d3.select('.' + this.get('className'));
    }),

    xMax: Ember.computed('canvasWidth', 'canvasMarginLeft', 'canvasMarginRight', 'years', function () {
      var years = this.get('years'),
          defaultMarginLeft = 70,
          defaultMax = this.get('canvasWidth') - (defaultMarginLeft + this.get('canvasMarginRight'));

      if (years >= 10) {
        return defaultMax;
      } else {
        return defaultMax - (10 - years) * 30;
      }
    }),

    drawChart: function drawChart() {

      var data = this.get('data'),
          sumData = this.get('sumData'),
          x = this.get('x'),
          yScale = this.get('yScale'),
          canvasMarginTop = this.get('canvasMarginTop'),
          canvasMarginLeft = this.get('canvasMarginLeft'),
          zeroOffset = yScale(0) + canvasMarginTop,
          y = function y(d) {
        return yScale(d.value) + canvasMarginTop;
      },
          h = function h(d) {
        return d.value < 0 ? yScale(d.value) - zeroOffset : zeroOffset - yScale(d.value);
      },
          svg = this.get('svg'),
          line = d3.line().x(function (d) {
        return canvasMarginLeft + x(d.year) + x.bandwidth() / 2;
      }).y(function (d) {
        return y(d);
      }),
          bars = svg.selectAll('.abatements-bar').data(data),
          path = svg.selectAll('.line').data([data]),
          points = svg.selectAll('circle').data(sumData),
          yearsToolTip = this.get('yearsToolTip'),
          years = this.get('years'),
          incentivePhrase = this.get('incentivePhrase'),
          taxType = this.get('taxType'),
          calendarYearDisplay = this.get('calendarYearDisplay'),
          startingYear = calendarYearDisplay ? parseInt(this.get('startingCalendarYear')) : 1;

      // Bars Enter
      bars.enter().append('rect').attr('class', function (d) {
        var classes = d.value < 0 ? 'abatements-bar abated' : 'abatements-bar liability';
        return classes + ' ' + d.type;
      }).attr('title', function (d) {
        var type = d.value < 0 ? incentivePhrase : taxType + ' Taxes';
        return '' + yearsToolTip + d.year + '<br />' + type + ': ' + numeral(d.value).format('($0a)');
      }).attr('x', function (d) {
        return canvasMarginLeft + x(d.year);
      }).attr('width', x.bandwidth()).attr('y', function (d) {
        return d.value < 0 ? zeroOffset + canvasMarginTop : y(d);
      }).attr('height', h);

      // Bars Change
      bars.transition().duration(1000).attr('y', function (d) {
        return d.value < 0 ? zeroOffset + canvasMarginTop : y(d);
      }).attr('height', h);

      if (years > 1) {
        // Path Enter
        path.enter().append('path').attr('class', 'line').attr('d', line(sumData));

        // Path Change
        path.transition().duration(1000).attr('d', line(sumData));
      }

      // Points Enter
      points.enter().append('circle').attr('class', 'point').attr('r', 5).attr('cx', function (d) {
        return canvasMarginLeft + x(d.year) + x.bandwidth() / 2;
      }).attr('cy', y);

      // Points Change
      points.transition().duration(1000).attr('cy', y);

      this.$('rect.abatements-bar').tipsy({
        gravity: 's',
        html: true,
        title: function title() {
          var d = this.__data__,
              type = d.value < 0 ? d.phrase.capitalize() : taxType + ' Taxes',
              value = void 0,
              year = d.year + startingYear - 1;
          value = d.value < 0 ? -d.origValue : d.value;
          if (d.value >= 1000000 || d.value <= -1000000) {
            return '' + yearsToolTip + year + '<br />' + type + ': ' + numeral(value).format('($0.00a)');
          }
          return '' + yearsToolTip + year + '<br />' + type + ': ' + numeral(value).format('($0,0)');
        },
        opacity: 1
      });

      this.$('circle').tipsy({
        gravity: 's',
        html: true,
        title: function title() {
          var d = this.__data__,
              year = d.year + startingYear - 1;
          if (d.value >= 1000000 || d.value <= -1000000) {
            return '' + yearsToolTip + year + '<br />Net: ' + numeral(d.value).format('($0.00a)');
          }
          return '' + yearsToolTip + year + '<br />Net: ' + numeral(d.value).format('($0,0)');
        },
        opacity: 1
      });
    }

  });
});