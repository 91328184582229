define('dashboard/components/file-dropzone-custom', ['exports', 'dashboard/mixins/s3', 'dashboard/mixins/hover'], function (exports, _s, _hover) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_s.default, _hover.default, {

    classNames: ['file-dropzone'],

    dragOver: function dragOver(e) {
      e.preventDefault();
    },
    drop: function drop(e) {
      e.preventDefault();
      var files = e.dataTransfer.files;
      this.send('handleFiles', files);
    },


    actions: {
      openFinder: function openFinder() {
        this.$('#fileInput').click();
      }
    }
  });
});