define('dashboard/components/tabs-swiper-container', ['exports', 'ember-cli-swiper/components/swiper-container'], function (exports, _swiperContainer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _swiperContainer.default.extend({
    classNames: ['nav-tabs', 'w-100', 'swiper-no-swiping'],

    // Parameters:
    // http://idangero.us/swiper/api/#parameters
    init: function init() {
      this._super.apply(this, arguments);
      this.set('options', {
        grabCursor: true,
        slidesPerView: this.get('slidesPerView') || 'auto',
        navigation: {
          prevEl: '.swipe-button-prev',
          nextEl: '.swipe-button-next'
        },
        breakpoints: this.get('breakpoints'), // change slide options depending on window size
        watchOverflow: true // hide nav when all slides visible
      });
    },


    currentSlide: 0,

    watchEngineChange: Ember.observer('selectedEngine', function () {
      var engines = this.get('updateFor'),
          // engine array we're watching
      selectedEngine = this.get('selectedEngine');

      var engineIndex = engines.indexOf(selectedEngine),
          currentSlide = this.get('currentSlide');

      if (engineIndex > currentSlide + 2) {
        // swipe forward if user clicks slide half-off screen
        currentSlide += 1;
        this.set('currentSlide', currentSlide);
      } else if (engineIndex < currentSlide && currentSlide !== 0) {
        // slide back if user clicks first slide when at the end of slider
        currentSlide -= 1;
        this.set('currentSlide', currentSlide);
      }
    }),

    navHidden: 'disappear',

    mouseEnter: function mouseEnter() {
      this.set('navHidden', 'appear');
    },
    mouseLeave: function mouseLeave() {
      this.set('navHidden', 'disappear');
    }
  });
});