define('dashboard/mixins/hover', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    hover: false,
    classNameBindings: ['hover'],

    mouseEnter: function mouseEnter() {
      this.set('hover', true);
    },
    mouseLeave: function mouseLeave() {
      this.set('hover', false);
    },


    visible: Ember.computed('hover', function () {
      return this.get('hover') ? 'visible' : 'invisible';
    })

  });
});