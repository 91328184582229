define('dashboard/controllers/clients/index', ['exports', 'dashboard/config/environment', 'dashboard/mixins/sorting'], function (exports, _environment, _sorting) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_sorting.default, {

    notify: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('sortColumns', [{
        title: ''
      }, {
        title: 'Name',
        sortField: 'name'
      }, {
        title: 'State',
        sortField: 'state'
      }, {
        title: 'Last Login',
        sortField: 'lastActive'
      },
      // {
      //   title: 'Subscription Ends',
      //   sortField: 'subscriptionDate',
      // },
      {
        title: 'Created',
        sortField: 'created'
      }, {
        title: 'Projects',
        sortField: 'projectCount'
      }, {
        title: 'Scenarios',
        sortField: 'scenarioCount'
      }, {
        title: 'Activated',
        sortField: 'isActivatable'
      }, {
        title: 'Clone',
        sortField: ''
      }, {
        title: 'Status',
        sortField: ''
      }]);
    },


    sortField: 'created',
    sortDir: 'asc',

    newClientName: null,
    isCreateClientModalHidden: true,

    actions: {
      showCreateClientModal: function showCreateClientModal() {
        this.set('isCreateClientModalHidden', false);
      },
      exportUsers: function exportUsers() {
        window.open(_environment.default.APP.api_host + '/api/users/export');
      },
      createClient: function createClient(newClientName) {
        this.send('createClientOnRoute', newClientName);
      },
      clearNewClientName: function clearNewClientName() {
        this.set('newClientName', null);
      },
      cloneClient: function cloneClient() {
        return true;
      }
    }

  });
});