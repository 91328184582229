define('dashboard/components/form-input', ['exports', 'dashboard/mixins/input-sizes'], function (exports, _inputSizes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_inputSizes.default, {

    classNameBindings: ['isDefault:form-group-default:row', 'isDefault::align-items-center', 'required', 'alignment'],
    classNames: ['form-group'],

    type: 'text',
    numRows: 10,
    resizable: true,
    liveUpdate: false,

    computedId: Ember.computed(function () {
      return this.elementId + 'input';
    }),

    computedIdUnique: Ember.computed('computedId', function () {
      return this.computedId + Math.random();
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (this.get('focus')) {
        this.$('input').focus();
      }
    },


    sizeClass: Ember.computed.or('labelSize', 'inputSize'),
    isDefault: Ember.computed.not('sizeClass'),

    isField: Ember.computed.equal('type', 'text'),

    focusOut: function focusOut() {
      // Trigger a recalculation of "modified"-dependent computed properties,
      // as an empty object will not cause the computed properties to recompute.
      this.notifyPropertyChange('modified');

      this.sendAction('action', this.get('toSend'));
    },

    isModified: Ember.computed('modified', 'modified.[]', function () {
      var modifiedProperties = this.get('modified') || {},
          inputKey = this.get('modifiedKey');

      return Ember.Object.create({
        isTrue: modifiedProperties.hasOwnProperty(inputKey),
        original: modifiedProperties[inputKey]
      });
    }),

    showDefaults: Ember.computed('isModified.isTrue', 'defaults', function () {
      return this.get('isModified.isTrue') && this.get('defaults') !== false;
    }),

    maskedModified: Ember.computed('isModified.original', 'maskType', function () {
      var rawModified = this.get('isModified.original'),
          maskType = this.get('maskType');

      switch (maskType) {
        case 'percent':
          return percentMask(rawModified);
        case 'negativePercent':
          return percentMask(rawModified);
        case 'medPercent':
          return percentMask(rawModified, true);
        case 'dollars':
          return numeral(rawModified).format('($0,0)');
        default:
          return rawModified ? rawModified : 'Empty';
      }
    }),

    actions: {
      nextSectionHandler: function nextSectionHandler() {
        var sortedProp = this.get('sortedProp') || 'sortedSections';
        this.sendAction('nextSectionHandler', this.get('nextId'), sortedProp);
        Ember.$('html, body').animate({
          scrollTop: this.$().offset().top - 300
        }, 300);
      }
    }
  });


  function percentMask(maskedValue, medium) {
    var format = medium ? '0.00[00]' : '0.00';
    //multiply by 100
    maskedValue = isNaN(maskedValue) ? 0 : maskedValue * 100;
    var isWhole = maskedValue % 1 === 0;
    return isWhole ? maskedValue + '.0%' : numeral(maskedValue).format(format) + '%';
  }
});