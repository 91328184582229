define('dashboard/components/scenario-input-complex-advanced', ['exports', 'dashboard/components/scenario-input-base', 'dashboard/objects/column', 'dashboard/utils/make-inputs'], function (exports, _scenarioInputBase, _column, _makeInputs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _scenarioInputBase.default.extend({

    yearlyInputs: Ember.computed('years', function () {
      var _this = this;

      var columnData = this.get('columns'),
          years = this.get('years'),
          columns = [];

      columnData.forEach(function (data) {
        if (_this.get('requestTemplate.' + data.name) || _this.get('showAll')) {
          var rows = _this.get('scenario.' + data.dataProperty);
          var column = _column.default.create({
            title: _this.get('useLongLabel') && data.longLabel || data.label,
            columnName: 'scenario.' + data.dataProperty,
            content: (0, _makeInputs.default)(rows, years),
            inputType: _this.get("maskType")
          });
          columns.push(column);
        }
      });

      return columns;
    }),

    actions: {
      updateData: function updateData(columns) {
        var _this2 = this;

        columns.forEach(function (column) {
          var columnName = column.get('columnName');
          var data = column.content.mapBy('value');
          _this2.set(columnName, data);
        });
        this.save();
      }
    }
  });
});