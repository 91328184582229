define('dashboard/components/expandable-input', ['exports', 'dashboard/mixins/hover'], function (exports, _hover) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_hover.default, {
    focused: false,

    // didInsertElement() {
    //   this._super(...arguments);
    //   const input = this.$('.scenario-name-input');
    //   const text = input.value;
    //   const font = 'normal 12.25pt Montserrat';
    //   const width = getTextWidth(text, font);
    //
    //   this.$('.scenario-name-input').css('width', Math.min(width, 300));
    //   this.$('.scenario-name-input').on('click', () => {
    //     this.set('focused', true);
    //   });
    // },

    showIcon: Ember.computed('hover', 'focused', function () {
      var hover = this.get('hover');
      var focused = this.get('focused');
      var disabled = this.get('disabled');

      if (hover && !focused && !disabled) {
        return 'visible';
      } else {
        return 'invisible';
      }
    }),

    // keyUp(e) {
    //   const input = this.$('.scenario-name-input')[0];
    //   const text = e.target.value || this.get('placeholderTitle');
    //   const font = 'normal 12.25pt Montserrat';
    //   const startingWidth = this.$('.scenario-name-input').width();
    //   const width = getTextWidth(text, font);
    //
    //   console.log('text', text);
    //
    //   this.$('.scenario-name-input').css('width', Math.min(width, 300));
    // },

    keyPress: function keyPress(e) {
      // On enter/return
      if (e.keyCode === 13) {
        e.preventDefault();
        this.$('.scenario-name-input').blur();
      }
    },
    focusOut: function focusOut() {
      var pEl = this.$('.scenario-name-input');
      var newTitle = pEl.text().trim();
      var placeholderTitle = this.get('placeholderTitle');

      this.set('focused', false);

      if (!newTitle) {
        pEl.text(placeholderTitle);
      } else {
        this.send('updateTitle', newTitle);
      }

      // this is important
      pEl.scrollLeft(0);
    },


    actions: {
      toggleEditDisabled: function toggleEditDisabled() {
        this.$('.scenario-name-input').focus();
        this.set('focused', true);
      },
      updateTitle: function updateTitle(newTitle) {
        this.sendAction('updateTitle', newTitle);
      }
    }

  });
});