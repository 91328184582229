define("dashboard/utils/format-chart-label", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    exports.default = function (str, maxwidth) {
        var sections = [];
        var words = str.split(" ");
        var temp = "";

        words.forEach(function (item, index) {
            if (temp.length > 0) {
                var concat = temp + ' ' + item;

                if (concat.length > maxwidth) {
                    sections.push(temp);
                    temp = "";
                } else {
                    if (index == words.length - 1) {
                        sections.push(concat);
                        return;
                    } else {
                        temp = concat;
                        return;
                    }
                }
            }

            if (index == words.length - 1) {
                sections.push(item);
                return;
            }

            if (item.length < maxwidth) {
                temp = item;
            } else {
                sections.push(item);
            }
        });

        return sections;
    };
});