define('dashboard/mirage/serializers/request-template', ['exports', 'dashboard/mirage/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    include: ['client'],

    normalize: function normalize(_ref) {
      var _ApplicationSerialize;

      var requestTemplate = _ref.requestTemplate;

      // TODO this might turn out hacky when we actually need to set the user using POST or PUT
      // client.userId = client.user;
      delete requestTemplate.client;

      return (_ApplicationSerialize = _application.default.prototype).normalize.apply(_ApplicationSerialize, arguments);
    }
  });
});