define("dashboard/utils/cumulative-sum", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  /**
   * Utility - Cumulative Sum
   *
   * Returns the cumulative sums of a given array of numbers
   *
   * @param {Array} items array of numbers
   * @return {Array} array of summated numbers
   *
   * @method cumulativeSum
   */
  var cumulativeSum = function cumulativeSum(items) {

    var sum = 0;

    return items.map(function (item) {
      sum += item;
      return sum;
    });
  };

  exports.default = cumulativeSum;
});