define('dashboard/components/changed-textarea', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['form-group'],

    init: function init() {
      this._super.apply(this, arguments);
      var action = this.get('action');

      if (action) {
        this.on('change', this, this.sendHookup);
      }
    },


    sendHookup: function sendHookup() {
      this.notifyPropertyChange('modified');
      this.sendAction();
    },

    isModified: Ember.computed('modified.[]', function () {
      var modifiedProperties = this.get('modified') || {},
          inputKey = this.get('modifiedKey');

      return Ember.Object.create({
        isTrue: modifiedProperties.hasOwnProperty(inputKey),
        original: modifiedProperties[inputKey]
      });
    }),

    originalValue: Ember.computed('isModified.original', function () {
      var original = this.get('isModified.original');
      return original ? original : 'Empty';
    }),

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.off('change', this, this.sendHookup);
    }
  });
});