define('dashboard/components/modals/data-request-settings-modal', ['exports', 'dashboard/components/modals/modal-confirm'], function (exports, _modalConfirm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _modalConfirm.default.extend({

    actions: {
      saveScenarioLength: function saveScenarioLength() {
        this.sendAction();
      }
    }
  });
});