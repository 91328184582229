define('dashboard/components/editable-label', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNameBindings: ['isEditing:editable-label'],
    attributeBindings: ['title', 'style'],
    defaultValue: "Untitled Project",

    // on Chrome, this value will be formatted to a max of 2 lines and automatically truncated nicely
    // so just set max characters high enough to break beyond the second line for firefox
    formattedValue: Ember.computed('value', function () {
      var value = this.get('value'),
          valueLength = value && value.length || 0,
          maxCharacters = this.get('maxCharacters') || 50;
      if (valueLength < 1) {
        return this.get("defaultValue");
      } else if (valueLength > maxCharacters && !this.get('noTruncate')) {
        return value.substr(0, maxCharacters) + "...";
      }
      return value;
    }),

    focusOut: function focusOut() {
      this.send('save');
      return false;
    },

    keyPress: function keyPress(e) {
      if (e.keyCode === 13) {
        this.send('save');
      }
    },

    title: Ember.computed.alias('value'),

    style: Ember.computed(function () {
      return Ember.String.htmlSafe('-webkit-box-orient: vertical');
    }),

    save: function save() {
      this.sendAction('action', this.get('value'));
    },


    actions: {
      save: function save() {
        Ember.run.debounce(this, 'save', 200);
        this.set('isEditing', false);
      }
    }
  });
});