define("dashboard/utils/periodic-increase", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  /**
   * Periodic Increase
   *
   * Multiplies each element of the input by the multipler and
   * (1 + multiplerIncrease)^(index)
   *
   * @param {Array} inputArray Array of values
   * @param {Number} multiplier Value to multiply
   * @param {Number} increase Value to increase the multiplier
   *                          each iteration
   *
   * @method  PerodicIncrease
   */
  var periodicIncrease = function periodicIncrease(inputArray, multiplier, increase) {

    var base = increase + 1;

    return inputArray.map(function (num, index) {

      var value = num * multiplier * Math.pow(base, index);
      return Math.round(value);
    });
  };

  exports.default = periodicIncrease;
});