define('dashboard/controllers/multiplier-set', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.set('districts', []);
      this.set('multipliers', []);
    },


    firstTenMultipliers: Ember.computed('multipliers', function () {
      var multipliers = this.get('multipliers');
      return multipliers.slice(0, 10);
    }),

    actions: {
      transitionToDistrict: function transitionToDistrict(districtId) {
        this.transitionToRoute('tax-district', districtId);
      },
      updateMultiplierSet: function updateMultiplierSet() {
        var multiplierSet = this.get('model');
        Ember.run.debounce(multiplierSet, 'save', 100);
      }
    }

  });
});