define('dashboard/components/scenario-input-base', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    years: Ember.computed('scenario.assumption.scenarioLength', 'scenario.{revisionOfScenario,dataFormLengthChanged}', 'requestTemplate.dataFormLength', function () {
      var templateLength = this.get('requestTemplate.dataFormLength');
      var scenarioLength = this.get('scenario.assumption.scenarioLength');

      // if we're revising a previously submitted data form, we always want to see length of that scenario
      // always show inputs at scenario length after submitted to detach it from template
      var revising = this.get('scenario.revisionOfScenario') && this.get('scenario.revisionOfScenario.content');
      var submitted = this.get('scenario.submittedOn');
      var dataFormLengthChanged = this.get('scenario.dataFormLengthChanged'); // manually changed length of this data form
      if (revising || submitted || dataFormLengthChanged) {
        return scenarioLength || templateLength || 10;
      }
      // all other cases we want the data form length to be directly linked to the template length
      return templateLength || scenarioLength || 10;
    })

  });
});