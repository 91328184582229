define('dashboard/routes/application', ['exports', 'ember-simple-auth/mixins/application-route-mixin', 'ember-body-class/mixins/body-class'], function (exports, _applicationRouteMixin, _bodyClass) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_applicationRouteMixin.default, _bodyClass.default, {
    session: Ember.inject.service(),
    routeAfterAuthentication: '/',

    // most of the time we want to redirect back to index after invalidation, if not, set the below property
    // overriding method on ApplicationRouteMixin
    sessionInvalidated: function sessionInvalidated() {
      if (this.get('session.skipRedirectOnInvalidation')) {
        this.set('session.skipRedirectOnInvalidation', false);
      } else {
        this._super.apply(this, arguments);
      }
    }
  });
});