define('dashboard/components/tool-tip', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',
    isShown: false,
    classNames: ['tooltip-help', 'iconi', 'dk'],

    iconClassProp: Ember.computed('iconClass', function () {
      var iconClass = this.get('iconClass') || 'fa-question-circle fa-lg';
      return 'fa help-icon ' + iconClass;
    }),

    hideTooltip: function hideTooltip(e, parent) {
      var self = this,
          el = Ember.$(e.target);
      if (!el.is(parent) && !el.closest(parent).length) {
        Ember.run(function () {
          self.set('isShown', false);
        });
      }
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var self = this,
          el = this.$(),
          event = 'click.' + this.elementId;
      Ember.$('body').on(event, function (e) {
        if (self.get('isDestroyed')) {
          return;
        }
        self.hideTooltip.apply(self, [e, el]);
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      var event = 'click.' + this.elementId;
      Ember.$('body').off(event);
    },


    actions: {
      toggleTip: function toggleTip() {
        this.toggleProperty('isShown');
      }
    }
  });
});