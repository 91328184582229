define('dashboard/components/scroll/nav-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    extraOffset: 0,

    scrollService: Ember.inject.service(),
    scrollPositions: Ember.computed.alias('scrollService.scrollPositions'),
    activeSectionId: Ember.computed.alias('scrollService.activeSectionId'),

    actions: {
      scrollToSection: function scrollToSection(sectionId) {
        var scrollPositions = this.get('scrollPositions');
        var targetSection = scrollPositions.findBy('id', sectionId);
        var innerContent = document.getElementsByClassName('inner-content')[0];
        var offsetTop = targetSection.offsetTop + this.get('extraOffset');

        if (sectionId && offsetTop !== null && offsetTop !== undefined) {
          Ember.$(innerContent).animate({ scrollTop: offsetTop }, 200);
        }
      }
    }
  });
});