define('dashboard/components/date-picker', ['exports', 'ember-pikaday/components/pikaday-input'], function (exports, _pikadayInput) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _pikadayInput.default.extend({
    format: 'MM/DD/YYYY',

    focusOut: function focusOut() {
      var val = this.$().val();
      // if the value is not valid (for example a word like 'John') we will wipe it out
      // then this scenario will be handled by ember-pikaday as if the user actually removed the whole value
      if (val && !moment(val, this.get('format')).isValid()) {
        this.$().val(null);
      }
    }
  });
});