define('dashboard/objects/ei-taxable-sales-construction-materials', ['exports', 'dashboard/utils/percent-each'], function (exports, _percentEach) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({

    investment: 0,
    associated: true,
    percentOne: 0,
    percentTwo: 0,

    output: Ember.computed('investment', 'percentOne', 'percentTwo', 'associated', function () {

      var investment = this.get('investment') || [],
          associated = this.get('associated'),
          percentOne = this.get('percentOne') || 0,
          percentTwo = this.get('percentTwo') || 0,
          output = void 0;

      if (associated) {
        output = (0, _percentEach.default)(investment, [percentOne, percentTwo]);
      } else {
        output = investment.map(function () {
          return 0;
        });
      }

      return output;
    })

  });
});