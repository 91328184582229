define('dashboard/components/markdown-input', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    editing: true,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('textareaClass', Math.random().toString().split('.')[1]);
    },


    actions: {
      toggleActive: function toggleActive() {
        this.set('editing', true);
        var textareaClass = this.get('textareaClass');

        setTimeout(function () {
          this.$('.' + textareaClass + ' textarea').focus();
        }, 0);
      },
      save: function save() {
        if (this.get('save')) {
          this.get('save')();
        }
      },
      nextSectionHandler: function nextSectionHandler() {
        var sortedProp = this.get('sortedProp') || 'sortedSections';
        this.sendAction('nextSectionHandler', this.get('nextId'), sortedProp);
        Ember.$('html, body').animate({
          scrollTop: this.$().offset().top - 300
        }, 300);
      }
    }
  });
});