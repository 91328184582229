define('dashboard/controllers/tax-districts/index', ['exports', 'dashboard/mixins/sorting', 'dashboard/fixtures/states', 'dashboard/config/environment'], function (exports, _sorting, _states, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_sorting.default, {

    isDownloadTaxDistrictModalHidden: true,
    isUploadTaxDistrictModalHidden: true,

    notify: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('sortColumns', [{
        title: '',
        width: '50'
      }, {
        title: 'District Name',
        sortField: 'name'
      }, {
        title: 'Tax Year or Note',
        sortField: 'note'
      }, {
        title: 'Parent District',
        sortField: 'parentDistrictName'
      }, {
        title: 'State',
        sortField: 'state'
      }, {
        title: 'Created',
        sortField: 'created'
      }]);
      this.set('confirmDistrict', {});
      this.set('uploadResults', {});
    },


    sortField: 'created',
    sortDir: 'asc',

    districtConfirmHidden: true,

    taxDistrictsUpload: null,
    uploadingProgress: false,
    districtUploadStatusHidden: true,

    stateOptions: _states.default,

    // Overrides filteredList in Sorting mixin
    filteredList: Ember.computed('sortedList', 'debouncedSearchText', function () {
      var sorted = this.get('sortedList');
      var search = this.get('searchText');
      var sortFields = this.get('sortFields');
      if (!search) {
        return sorted;
      } else {
        return sorted.filter(function (item) {
          var values = Object.values(item.getProperties(sortFields));
          return item.get('isActive') && item.get('adminCreated') && item.get('createdInTransition') !== true && values.any(function (element) {
            return element && element.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1;
          });
        });
      }
    }),

    // @TODO Set or show parent districts faster
    // activeList: computed('sortedList.@each.isActive', function() {
    //   let allDistricts = this.get('sortedList');

    //   return this.get('sortedList').filter(function(district) {
    //     let parentDistrict = allDistricts.findBy('id', district.get('parentDistrict'));
    //     district.set('parentDistrictName', parentDistrict ? parentDistrict.get('name') : '');
    //     return district.get('isActive') && !district.get('createdInTransition');
    //   });
    // }),

    disableUploadButton: Ember.computed('uploadingProgress', 'taxDistrictsUpload', function () {

      var uploadingProgress = this.get('uploadingProgress'),
          fileSelected = this.get('taxDistrictsUpload'),
          disableButton = false;

      if (uploadingProgress || fileSelected === null) {
        disableButton = true;
      }

      return disableButton;
    }),

    selectedState: 'All States',
    statesForDownload: Ember.computed('stateOptions', 'model.[]', function () {
      var districts = this.get('model');
      var uniqueStates = this.get('stateOptions').filter(function (state) {
        return districts.any(function (district) {
          return district.get('state') === state;
        });
      });
      return ["All States"].concat(uniqueStates);
    }),

    downloadLink: Ember.computed('selectedState', function () {
      if (this.get('selectedState') !== "All States") {
        return '/api/taxDistricts/downloads/?state=' + this.get('selectedState');
      }
      return '/api/taxDistricts/downloads';
    }),

    actions: {
      setState: function setState(state) {
        this.set('selectedState', state);
      },
      openDistrictConfirmModal: function openDistrictConfirmModal(district) {
        this.set('districtConfirmHidden', false);
        this.set('confirmDistrict', district);
      },
      cloneDistrict: function cloneDistrict(oldDistrict) {
        var self = this;
        var originalDistrict = void 0,
            createdCustomCostRevs = void 0;

        function findDistrict(district) {
          return district.reload();
        }

        function duplicateCustomCostRevs(oldDistrict) {
          originalDistrict = oldDistrict;
          return Ember.RSVP.all(oldDistrict.get('customCostRevenues').map(function (oldCostRev) {
            var costRevToCreate = oldCostRev.toJSON();
            delete costRevToCreate.created;
            return self.store.createRecord('customCostRevenue', costRevToCreate).save();
          }));
        }

        function duplicateDirectCostRevs(createdCostRevs) {
          createdCustomCostRevs = createdCostRevs;
          return Ember.RSVP.all(oldDistrict.get('directCostRevenues').map(function (oldCostRev) {
            var costRevToCreate = oldCostRev.toJSON();
            delete costRevToCreate.created;
            return self.store.createRecord('directCostRevenue', costRevToCreate).save();
          }));
        }

        function createNewDistrict(createdDirectCostRevs) {
          var oldDistrictProperties = originalDistrict.toJSON();

          // Remove read only properties that can not be set on new district
          delete oldDistrictProperties.customCostRevenues;
          delete oldDistrictProperties.directCostRevenues;
          delete oldDistrictProperties.scenarios;
          delete oldDistrictProperties.created;
          delete oldDistrictProperties.districtGroups;

          var newDistrict = self.store.createRecord('taxDistrict', oldDistrictProperties);
          newDistrict.set('name', 'Copy of ' + originalDistrict.get('name'));
          newDistrict.get('customCostRevenues').pushObjects(createdCustomCostRevs);
          newDistrict.get('directCostRevenues').pushObjects(createdDirectCostRevs);
          return newDistrict.save();
        }

        function transitionToDistrict(createdDistrict) {
          self.transitionToRoute('tax-district', createdDistrict.get('id'));
          self.get('notify').info('District successfully cloned. You are now editing the cloned district.');
        }

        function handleErrors(error) {
          console.log('handleErrors', 'error:', error);
          self.get('notify').alert('There was a problem saving the cloned district.');
        }

        findDistrict(oldDistrict).then(duplicateCustomCostRevs).then(duplicateDirectCostRevs).then(createNewDistrict).then(transitionToDistrict).catch(handleErrors);
      },
      toggleCreating: function toggleCreating() {
        resetUploadField();
        this.setProperties({
          'uploadingProgress': false
        });
        this.set('isUploadTaxDistrictModalHidden', false);
      },
      cancelCreating: function cancelCreating() {
        resetUploadField();
        this.setProperties({
          'creating': false,
          'uploadingProgress': false
        });
      },


      toggleDownloading: function toggleDownloading() {
        this.setProperties({
          'creating': false,
          'uploadingProgress': false
        });
        this.toggleProperty('isDownloadTaxDistrictModalHidden');
      },

      cancelDownloading: function cancelDownloading() {
        this.set('downloading', false);
      },

      downloadDistrict: function downloadDistrict() {
        window.location.href = _environment.default.APP.api_host + this.get('downloadLink');
      },

      createTaxDistrict: function createTaxDistrict() {
        var _this = this;

        this.get('store').createRecord('taxDistrict').save().then(function (taxDistrict) {
          _this.get('router').transitionTo('tax-district', taxDistrict.id);
        });
      },
      createTaxDistrictUpload: function createTaxDistrictUpload() {
        var _this2 = this;

        var fileToUpload = this.get('taxDistrictsUpload');

        if (!fileToUpload) {
          return;
        }

        this.set('uploadingProgress', true);
        var taxDistrictsUpload = this.store.createRecord('taxDistrictsUpload', { file: fileToUpload });

        taxDistrictsUpload.save().then(function (uploadObject) {
          resetUploadField();
          _this2.setProperties({
            'creating': false,
            'uploadingProgress': false,
            'uploadedTaxDistricts': uploadObject.get('taxDistricts'),
            'districtUploadStatusHidden': false
          });
        }).catch(function (fail) {
          console.log('catch', 'fail:', fail);
          _this2.set('uploadingProgress', false);
          _this2.get('notify').alert('Something went wrong when creating new districts');
        });
      }
    }
  });


  function resetUploadField() {
    Ember.$('.upload-component').wrap('<form>').closest('form').get(0).reset();
    Ember.$('.upload-component').unwrap();
  }
});