define('dashboard/routes/scenario', ['exports', 'lodash', 'ember-local-storage', 'dashboard/mixins/essentials-redirect'], function (exports, _lodash, _emberLocalStorage, _essentialsRedirect) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_essentialsRedirect.default, {

    sessionData: (0, _emberLocalStorage.storageFor)('session-data'),

    title: function title(tokens) {
      tokens = Ember.makeArray(tokens);
      if (tokens.length > 2) {
        tokens.shift();
      }
      tokens.unshift('Impact DashBoard');
      return tokens.reverse().join(' | ');
    },

    titleToken: function titleToken() /*tokens*/{
      var project = this.get('controller.model.project.title');
      var scenario = this.get('controller.scenarioSubtitle');

      return [project, scenario];
    },

    model: function model(params) {
      var models = {};
      var projectId = this.modelFor('project').project.get('id');

      var loadedMultipliersPromises = this.loadAllMultipliers(params.scenario_id).then(function (result) {
        models.multiplier = result.multiplier;
        models.multiplierSets = result.multiplierSets;
        models.multipliers = result.multipliers;
        return true;
      });

      var loadedProjectDependenciesPromises = this.loadProjectAndItsDependencies(projectId, params.scenario_id).then(function (result) {
        models.project = result.project;
        models.scenario = result.scenario;
        models.client = result.client;
        models.districtAreaSet = result.districtAreaSet;
        models.uniqueDistrictAreas = result.uniqueDistrictAreas;
        models.districtAreaOptions = result.districtAreaOptions;
        models.districtArea = result.districtArea;
        models.tifDistricts = result.tifDistricts;
        models.tifDistrict = result.tifDistrict;
        models.tags = result.tags;
        models.dependentTaxDistrictSet = result.dependentTaxDistrictSet;
        models.dependentDistrictGroupLinks = result.dependentDistrictGroupLinks;
        return true;
      });

      return Ember.RSVP.all([loadedMultipliersPromises, loadedProjectDependenciesPromises]).then(function () {
        return models;
      });
    },
    afterModel: function afterModel(models, transition) {
      this._super(models, transition); // passes necessary params to mixin
      // this is used to hide global header. we don't want to hide global header if scenario is request (View Data from request)
      if (!models.scenario.get('isRequest')) {
        this.set('sessionData.scenarioRoute', true);
        this.set('sessionData.lastVisited', null);
      }
    },
    loadProjectAndItsDependencies: function loadProjectAndItsDependencies(projectId, scenarioId) {
      var self = this,
          projectPromise = this.store.findRecord('project', projectId);

      return projectPromise.then(function (project) {
        var scenario = project.get('scenarios').findBy('id', scenarioId);
        var clientId = project.get('client.id');

        return Ember.RSVP.hash({
          project: project,
          scenario: scenario,
          client: self.store.findRecord('client', clientId),
          assumption: self.store.query('assumption', { scenario: scenarioId }),
          districtAreaSet: self.store.query('districtAreaSet', { client: clientId }),
          uniqueDistrictAreas: self.store.query('districtArea', { client: clientId }), // Returns the first district area from each unique primary group
          districtAreaOptions: self.store.query('districtArea', { scenario: scenarioId, client: clientId }), // Returns all district areas belonging to a specific primary group within this set
          districtArea: self.store.query('districtArea', { scenario: scenarioId }),
          customCostRevs: self.store.findAll('customCostRevenue'), // @TODO properly side-load these with districts
          directCostRevs: self.store.findAll('directCostRevenue'), // @TODO properly side-load these with districts
          tifDistricts: self.store.query('tifDistrict', { client: clientId }),
          tifDistrict: scenario.get('tifDistrict'),
          tags: self.store.query('tag', { client: clientId }).then(function (tags) {
            return tags.toArray();
          }),
          dependentTaxDistrictSet: self.store.query('dependentTaxDistrictSet', { client: clientId }), // might be null, that should be ok
          dependentDistrictGroupLinks: self.store.query('dependentDistrictGroupLink', { client: clientId })
        });
      });
    },
    loadAllMultipliers: function loadAllMultipliers(scenarioId) {
      var self = this,
          multiplierPromise = this.store.query('multiplier', { scenario: scenarioId }),
          multiplierSetsPromise = this.store.query('multiplierSet', { scenario: scenarioId });

      return multiplierSetsPromise.then(function (multiplierSets) {
        var multiplierPromises = [],
            allMultipliers = [];

        multiplierSets.forEach(function (set) {
          multiplierPromises.push(self.store.query('multiplier', { multiplierSet: set.get('id') }));
        });

        return Ember.RSVP.all(multiplierPromises).then(function (multiplierSets) {
          multiplierSets.forEach(function (multiplierSet) {
            multiplierSet.forEach(function (multiplier) {
              allMultipliers.push(multiplier);
            });
          });

          return Ember.RSVP.hash({
            multiplier: multiplierPromise,
            multiplierSets: multiplierSets,
            multipliers: allMultipliers
          });
        });
      });
    },
    setupController: function setupController(controller, models) {
      controller.setProperties({
        project: models.project,
        scenarios: models.project.get('scenarios'),
        client: models.client,
        scenarioMultiplier: models.multiplier.get('firstObject'),
        multiplierSets: models.multiplierSets,
        multipliers: models.multipliers,
        uniqueDistrictAreas: models.uniqueDistrictAreas,
        districtAreaSet: models.districtAreaSet.get('firstObject'),
        districtAreaOptions: models.districtAreaOptions,
        districtArea: models.districtArea.get('firstObject'),
        tifDistrict: models.tifDistrict,
        scenarioController: controller,
        dependentTaxDistrictSet: models.dependentTaxDistrictSet.get('firstObject'),
        dependentDistrictGroupLinks: models.dependentDistrictGroupLinks,
        tags: models.tags
      });

      this._super(controller, models.scenario);

      if (models.scenario.get('isRequest')) {
        controller.showRequestSections();
      } else {
        controller.showFirstSectionOnly();
      }

      controller.send('updateUnboundInputs');
      this.createCommunityIncentive(models.scenario);
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('isAdminLocked', false);
        controller.get('model').rollbackAttributes(); //for admins with locked scenarios
        controller.set('lastVisitedGroup', null);
        this.set('sessionData.reportSelection', 'fact-sheet');
        this.set('sessionData.settingsSelection', 'general');
      }
    },


    // activate(param) {
    //   console.log('get model ', this.get('model'));
    //   console.log('this.model ', this.model);
    //   let indexedScenarios = this.modelFor('project').get('indexedScenariosOnly');
    //   console.log('indexedScenarios', indexedScenarios);
    //   // set this scenairo to currently active
    //   // set current currentlyActive to false
    // },

    createCommunityIncentive: function createCommunityIncentive(scenario) {
      // only create new community incentive schedule if none existing for this scenario
      var communitySchedule = scenario.get('incentiveSchedules').findBy('forCommunityIncentive');

      if (communitySchedule) {
        return;
      }

      communitySchedule = this.store.createRecord('incentiveSchedule', { forCommunityIncentive: true });
      scenario.get('incentiveSchedules').pushObject(communitySchedule);

      return communitySchedule.save();
    },


    actions: {
      didTransition: function didTransition() {
        window.scrollTop = 0;
        // puts back header and sidebar when leaving scenario page
        if (!_lodash.default.includes(this.get('routeName'), 'scenario')) {
          this.set('sessionData.scenarioRoute', false);
        }
      }
    }

  });
});