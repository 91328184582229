define('dashboard/components/scroll/scroll-to', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      var _this = this;

      setTimeout(function () {
        var id = _this.get('id');
        var element = document.getElementById(id);

        var offset = element.getBoundingClientRect();
        var offsetTop = element ? offset.top - 50 : 0;

        if (id && offsetTop !== null && offsetTop !== undefined) {
          _this.action(offsetTop, id);
        }
      }, 300);
    }
  });
});