define('dashboard/components/forms/user-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    session: Ember.inject.service(),
    store: Ember.inject.service(),
    notify: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('notificationId', 'user-form-errors');
      this.set('roleOptions', [{ label: 'Admin', value: 'admin' }, { label: 'Standard User', value: 'user' }]);
      this.set('notificationLevelOptions', [{ label: 'All Notifications', value: 'all_notifications' }, { label: 'All Submitted Data Forms', value: 'all_data_forms' }, { label: 'My Data Forms Only', value: 'my_data_forms' }, { label: 'No Notifications', value: 'none' }]);
    },


    didSubmit: false,

    autoSave: Ember.computed.not('user.isNew'),

    showUsernameField: Ember.computed('user.{username,hasDirtyAttributes}', function () {
      var user = this.get('user');
      if (!user) return false;

      var _user$changedAttribut = user.changedAttributes(),
          usernameChanged = _user$changedAttribut.username;

      return Boolean(user.get('username') || usernameChanged);
    }),

    errorMessage: Ember.computed('notify.target.messages.[]', function () {
      return this.getWithDefault('notify.target.messages', []).findBy('id', this.get('notificationId'));
    }),

    currentUser: Ember.computed.alias('session.currentUser'),

    canManageUsers: Ember.computed.alias('currentUser.canManageUsers'),

    isCurrentUser: Ember.computed('currentUser.id', 'user.id', function () {
      return this.get('currentUser.id') === this.get('user.id');
    }),

    disabled: Ember.computed('canManageUsers', 'isCurrentUser', function () {
      if (this.get('isCurrentUser')) return false;
      if (this.get('canManageUsers')) return false;
      return true;
    }),

    canChangeRole: Ember.computed('currentUser.{id,canManageUsers}', 'isCurrentUser', 'user.id', function () {
      if (this.get('disabled')) return false;
      if (this.get('isCurrentUser')) return false;
      return this.get('canManageUsers');
    }),

    canChangeNotificationLevel: Ember.computed('currentUser.{id,canManageUsers}', 'isCurrentUser', 'user.id', function () {
      if (this.get('isCurrentUser')) return true;
      if (this.get('currentUser.canManageUsers')) return true;
      return false;
    }),

    selectedRole: Ember.computed('user.role', function () {
      return this.getWithDefault('roleOptions', []).findBy('value', this.get('user.role'));
    }),

    selectedNotificationLevel: Ember.computed('user.notificationLevel', function () {
      return this.getWithDefault('notificationLevelOptions', []).findBy('value', this.get('user.notificationLevel'));
    }),

    isExistingUserWithErrors: Ember.computed('user.{isNew,isInvalid}', function () {
      var _get$getProperties = this.get('user').getProperties('isNew', 'isInvalid'),
          isNew = _get$getProperties.isNew,
          isInvalid = _get$getProperties.isInvalid;

      return !isNew && isInvalid;
    }),

    _clearFormErrors: function _clearFormErrors() {
      if (this.get('errorMessage')) this.set('errorMessage.visible', this.get('user.validations.isInvalid'));
      this.set('didSubmit', false);
    },

    actions: {
      changeName: function changeName(nameProp) {
        // To get around some validation issues, the user's default values for first/last name is " ".
        // If there is a real value in this field, then we'll trim the value and set the property before saving.
        // If its empty, then we will leave it as the " ".
        var user = this.get('user');
        var trimmedValue = user.get(nameProp).trim();
        if (trimmedValue.length > 0) user.set(nameProp, trimmedValue);
        this.send('submit');
      },
      changeRole: function changeRole(opt) {
        this.set('user.role', opt.value);
        this.send('submit');
      },
      changeNotificationLevel: function changeNotificationLevel(opt) {
        this.set('user.notificationLevel', opt.value);
        this.send('submit');
      },
      submit: function submit() {
        var _getProperties = this.getProperties('user', 'autoSave', 'didSubmit', 'isExistingUserWithErrors'),
            user = _getProperties.user,
            autoSave = _getProperties.autoSave,
            didSubmit = _getProperties.didSubmit,
            isExistingUserWithErrors = _getProperties.isExistingUserWithErrors;

        if (!user) return;

        if (isExistingUserWithErrors) return this.send('setFormErrors');

        if (autoSave || didSubmit) this.sendAction();
      },
      setFormErrors: function setFormErrors() {
        var text = this.get('user.validations.messages').join('<br />');

        var errorMessage = this.get('errorMessage');

        if (errorMessage) {
          errorMessage.set('text', text);
        } else {
          // sets errors on parent componet in case user tries to submit invalid form
          this.set('formErrors', text);
        }

        Ember.run.debounce(this, this._clearFormErrors, 3000);
      },
      clearFormErrors: function clearFormErrors() {
        this._clearFormErrors();
      }
    }
  });
});