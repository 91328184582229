define('dashboard/controllers/projects/archived', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    projectsController: Ember.inject.controller('projects'),

    init: function init() {
      this._super.apply(this, arguments);

      this.set('moveProjectOptions', ['Active', 'Won', 'Lost']);
    }
  });
});