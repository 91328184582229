define('dashboard/components/tables/economic-impact', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    years: Ember.computed.alias('engine.assumption.scenarioLength'),

    phaseIn: Ember.computed.gt('lastYearWithJobsAdded', 1),

    annualSalariesLabel: Ember.computed('phaseIn', 'lastYearWithJobsAdded', function () {
      if (!this.get('phaseIn')) {
        return "Annual Salaries/Wages";
      }
      return 'Annual Salaries/Wages at Full Ops (Yr ' + this.get('lastYearWithJobsAdded') + ')';
    })

  });
});