define('dashboard/components/client-row', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    tagName: 'tr',

    classNames: ['clickable'],

    click: function click(event) {
      if (Ember.$(event.target).hasClass('ember-power-select-trigger') || Ember.$(event.target).hasClass('ember-power-select-selected-item') || Ember.$(event.target).hasClass('ember-power-select-status-icon')) {
        return false;
      }
      this.sendAction('transitionToClientAction', this.get('client'));
    },


    actions: {
      updateClientStatus: function updateClientStatus(statusOption) {
        var client = this.get('client');

        client.updateClientStatus(statusOption.value);
        client.save();
      }
    }

  });
});