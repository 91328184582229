define('dashboard/components/interactive-map', ['exports', 'dashboard/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['w-100', 'h-100', 'google-map-element'],
    classNameBindings: ['widgetClass'],

    apiKey: _environment.default.APP.google_maps_key,

    locationString: Ember.computed('location', 'clientAddress', function () {
      var location = this.get('location');
      var clientAddress = this.get('clientAddress');

      if (location) {
        return location;
      }

      if (clientAddress) {
        return clientAddress;
      }

      return 'United States';
    }),

    clientAddress: Ember.computed('client.{city,state}', function () {
      var city = this.get('client.city');
      var state = this.get('client.state');

      if (!city || !state) {
        return null;
      }

      return city + ', ' + state;
    }),

    srcUrl: Ember.computed('apiKey', 'location', function () {
      var key = this.get('apiKey');
      var location = this.get('locationString');
      return "https://www.google.com/maps/embed/v1/place?key=" + key + "&q=" + location;
    })

  });
});