define('dashboard/routes/user/customization', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var client = this.modelFor('user').client;
      var tagsPromise = this.store.query('tag', { client: client.get('id') }).then(function (tags) {
        return tags.toArray();
      });
      var projectsPromise = this.store.query('project', { client: client.get('id') }).then(function (projects) {
        return projects.toArray();
      });

      return Ember.RSVP.hash({
        client: client,
        tags: tagsPromise,
        projects: projectsPromise
      });
    },
    setupController: function setupController(controller, models) {
      controller.setProperties({
        tags: models.tags,
        projects: models.projects,
        client: models.client
      });
    }
  });
});