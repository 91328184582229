define('dashboard/components/changed-simple-select', ['exports', 'lodash/string'], function (exports, _string2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['form-group', 'simple-select', 'no-border', 'm-0'],
    classNameBindings: ['isModified.isTrue:yellow-input'],

    computedId: Ember.computed(function () {
      return this.elementId + 'select';
    }),

    isModified: Ember.computed('modified.[]', function () {
      var modifiedProperties = this.get('modified') || {},
          inputKey = this.get('modifiedKey');

      return Ember.Object.create({
        isTrue: modifiedProperties.hasOwnProperty(inputKey),
        original: _string2.default.upperFirst(modifiedProperties[inputKey])
      });
    }),

    showDefaults: Ember.computed('isModified.isTrue', 'defaults', function () {
      return this.get('isModified.isTrue') && this.get('defaults') !== false;
    }),

    actions: {

      // inputValue will be updated so you can send to regular save action or
      // custom action if more needs to be done
      updateValue: function updateValue(value) {
        this.notifyPropertyChange('modified');
        this.set('inputValue', value);
        this.sendAction('action', this.get('toSend'));
      }
    }
  });
});