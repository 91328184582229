define('dashboard/controllers/district-area-set', ['exports', 'dashboard/mixins/sorting', 'dashboard/fixtures/states', 'dashboard/config/environment'], function (exports, _sorting, _states, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_sorting.default, {

    notify: Ember.inject.service(),
    ajax: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('uploadResults', {});
      this.set('withIdsOptions', [{
        name: 'no',
        value: false,
        label: 'No',
        computedId: 'no'
      }, {
        name: 'yes',
        value: true,
        label: 'Yes',
        computedId: 'yes'
      }]);
    },


    areasLoaded: 100,

    sortColumns: Ember.computed('districtAreaSet.primaryGroupName', 'model', function () {

      var primaryGroupName = this.get('districtAreaSet.primaryGroupName'),
          initialHeaders = void 0,
          dynamicHeaders = void 0;

      initialHeaders = [{
        title: primaryGroupName || 'Primary Group',
        sortField: 'primaryGroup'
      }, {
        title: 'Name',
        sortField: 'name'
      }];

      var mappedSet = this.get('model').find(function (districtArea) {
        return districtArea.get('map.length') > 0;
      });

      if (mappedSet) {
        dynamicHeaders = mappedSet.get('map').map(function (district) {
          return { title: district.type };
        });
      }

      return initialHeaders.concat(dynamicHeaders);
    }),

    sortField: 'name',
    sortDir: 'asc',

    stateOptions: _states.default,

    districtAreasUpload: null,

    isDownloadModalHidden: true,
    downloadWithIds: false,

    isUploadModalHidden: true,
    uploadingProgress: false,
    areaUploadStatusHidden: true,

    disableButton: Ember.computed('districtAreaSet', 'uploadingProgress', 'districtAreasUpload', function () {
      var districtAreaSet = !this.get('districtAreaSet'),
          uploadingProgress = this.get('uploadingProgress'),
          fileSelected = this.get('districtAreasUpload');

      return districtAreaSet || uploadingProgress || fileSelected === null;
    }),

    actions: {
      setState: function setState(state) {
        this.set('districtAreaSet.state', state);
        this.send('updateDistrictAreaSet');
      },
      loadMore: function loadMore() {
        var _this = this;

        var set = this.get('districtAreaSet'),
            areasLoaded = this.get('areasLoaded');

        this.store.query('districtArea', { districtAreaSet: set.id, limit: 100, skip: areasLoaded }).then(function (areas) {
          _this.get('model').pushObjects(areas.get('content'));
        });

        this.set('areasLoaded', areasLoaded + 100);
      },
      cancelCreating: function cancelCreating() {
        resetUploadField();
        this.setProperties({
          'isUploadModalHidden': true,
          'districtAreasUpload': null,
          'uploadingProgress': false
        });
      },
      cancelDownloading: function cancelDownloading() {
        this.setProperties({
          'isDownloadModalHidden': true,
          'downloadWithIds': false
        });
      },
      updateDistrictAreaSet: function updateDistrictAreaSet() {
        var districtAreaSet = this.get('districtAreaSet');
        Ember.run.debounce(districtAreaSet, 'save', 100);
      },


      downloadDistrictAreas: function downloadDistrictAreas() {
        var _this2 = this;

        var downloadWithIds = this.get('downloadWithIds') ? '?withIds=true' : '';
        window.location.href = _environment.default.APP.api_host + '/api/districtAreas/downloads/' + this.get('districtAreaSet.id') + downloadWithIds;
        setTimeout(function () {
          _this2.send('cancelDownloading');
        }, 1000);
      },

      createDistrictAreasUpload: function createDistrictAreasUpload() {
        var _this3 = this;

        var fileToUpload = this.get('districtAreasUpload'),
            districtAreaSetId = this.get('districtAreaSet.id');

        this.set('uploadingProgress', true);

        if (!fileToUpload) {
          return this.get('notify').alert('No file found');
        }

        var formData = new FormData();
        formData.append('districtAreasUpload[file]', fileToUpload);
        formData.append('districtAreaSetId', districtAreaSetId);

        this.get('ajax').post('/districtAreasUpload', {
          data: formData,
          dataType: 'json',
          contentType: false,
          processData: false
        }).then(function (newDistrictAreas) {
          _this3.send('cancelCreating');

          _this3.set('uploadResults', newDistrictAreas);
          _this3.set('areaUploadStatusHidden', false);

          _this3.store.query('districtArea', { districtAreaSet: _this3.get('districtAreaSet.id') }).then(function (districtAreas) {
            _this3.set('model', districtAreas);
          });
        }).catch(function (err) {
          console.log('err ', err);
          _this3.setProperties({
            'districtAreasUpload': null,
            'uploadingProgress': false
          });
          _this3.get('notify').alert('Something went wrong when creating new district areas');
        });
      }
    }

  });


  function resetUploadField() {
    Ember.$('.upload-component').wrap('<form>').closest('form').get(0).reset();
    Ember.$('.upload-component').unwrap();
  }
});