define('dashboard/objects/fi-tax/hotel-taxes', ['exports', 'dashboard/objects/fi-tax/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({

    annual: Ember.computed('doesApply', 'taxRate', 'hotelSpending', function () {
      var doesApply = this.get('doesApply'),
          taxRate = this.get('taxRate'),
          hotelSpending = this.get('hotelSpending');

      return hotelSpending.map(function (spent) {
        return spent * (doesApply ? taxRate : 0);
      });
    }),

    project: Ember.computed.sum('annual')

  });
});