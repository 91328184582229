define('dashboard/routes/scenario/public-support/group', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      var innerContent = document.getElementsByClassName('inner-content')[0];
      var body = document.getElementsByTagName('body')[0];

      body.style.margin = 0;
      innerContent.scroll(0, 0);
    },
    model: function model(params) {
      var scenario = this.modelFor('scenario').scenario;
      var selectedSchedule = scenario.get('incentiveSchedules').findBy('id', params.schedule_id);

      return Ember.RSVP.hash({
        scenario: scenario,
        selectedSchedule: selectedSchedule
      });
    },
    afterModel: function afterModel(models) {
      if (!(models && models.selectedSchedule)) {
        this.controllerFor('scenario').set('lastVisitedGroup', null);
        this.transitionTo('scenario.public-support');
      }
    },
    setupController: function setupController(controller, models) {
      this._super.apply(this, arguments);
      controller.setProperties({
        selectedSchedule: models.selectedSchedule,
        model: models.scenario
      });
      this.controllerFor('scenario').set('lastVisitedGroup', models.selectedSchedule);
    },
    renderTemplate: function renderTemplate(controller, models) {
      // necessary in case incentive schedule is removed and we try to transition back to public-support
      if (models.scenario.get('forTifDistrict')) {
        if (!this.modelFor('scenario').scenario.get('usesTifDistrict')) {
          this.controllerFor('scenario').set('lastVisitedGroup', null);
          this.transitionTo('scenario.public-support.index');
        }
      }
      this.render('scenario.public-support.group');
    }
  });
});