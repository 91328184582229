define('dashboard/components/modals/scenario-location-modal', ['exports', 'dashboard/components/modals/modal-confirm'], function (exports, _modalConfirm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _modalConfirm.default.extend({

    'data-test-selector': 'scenario-location-modal',

    isWalkthrough: Ember.computed.equal('scenario.walkthroughCompleted', false),

    locationValue: Ember.computed('scenario.projectLocation', 'scenario.siteAddress', function () {
      return this.get('scenario.projectLocation') || this.get('scenario.siteAddress');
    }),

    usesStandardDistrictConfig: Ember.computed.alias('scenarioController.usesStandardDistrictConfig'),
    usesDependentDistricts: Ember.computed.alias('scenarioController.dependentTaxDistrictSet.id'),

    actions: {
      showPreviousModal: function showPreviousModal() {
        this.set('isHidden', true);
        this.sendAction('showPreviousModal');
      },
      toIndustryModal: function toIndustryModal() {
        this.set('isHidden', true);
        this.sendAction('showIndustryModal');
      },
      onAddressSelect: function onAddressSelect(address) {
        this.sendAction('onAddressSelect', address);
      },
      updateScenarioDistrictHandler: function updateScenarioDistrictHandler(newDistrict, group) {
        this.sendAction('updateScenarioDistrictAction', newDistrict, group);
      },
      submit: function submit() {
        if (this.get('isWalkthrough')) {
          this.sendAction('showIndustryModal');
        }
        this.set('isHidden', true);
      },
      save: function save(name, values, forceSave) {
        this.sendAction('save', name, values, forceSave);
      }
    }

  });
});