define("dashboard/utils/scenario-inputs/current", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var jobsAndSalaryContent = exports.jobsAndSalaryContent = [{ name: "currentJobsAdded", inputType: "whole", dataProperty: 'currentJobs', label: "Number of existing employees working at the Company's local facility" }, { name: "currentAvgAnnualSalary", inputType: "dollars", dataProperty: 'currentAverageSalary', label: "Average annual salaries paid to existing employees" }];

  var propertyContent = exports.propertyContent = [{ name: 'capitalInvestment', inputType: 'multi-property-column', maskType: 'dollars', labelLeft: "Property Type", labelRight: "Market Value", rows: [{ name: "landExpenditures", dataProperty: "currentCapitalInvestmentLand", label: "Land", if: 'stateTaxes.land', scenarioIf: 'primaryDistrict.doesApplyPropertyTaxRateLand' }, { name: "buildingExpenditures", dataProperty: "currentCapitalInvestmentBuildings", label: "Buildings & Other Real Property Improvements", if: 'stateTaxes.buildings', scenarioIf: 'primaryDistrict.doesApplyPropertyTaxRateBuildings' }, { name: "equipmentExpenditures", dataProperty: "currentCapitalInvestmentFurniture", label: "Furniture, Fixtures, & Equipment", if: 'stateTaxes.ffe', scenarioIf: 'primaryDistrict.doesApplyPropertyTaxRateFFE' }, { name: "inventoryExpenditures", dataProperty: "currentCapitalInvestmentInventory", label: "Taxable Inventories", if: 'stateTaxes.inventory', scenarioIf: 'primaryDistrict.doesApplyPropertyTaxRateInventories' }] }];

  var taxablePurchasesAndSalesContent = exports.taxablePurchasesAndSalesContent = [{
    inputType: 'dollars',
    name: 'annualTaxablePurchases',
    dataProperty: 'currentTaxablePurchases',
    label: 'Current annual Taxable Purchases',
    if: 'requestTemplate.salesTaxOnReview',
    scenarioIf: 'primaryDistrict.doesApplyTaxablePurchasesSalesTax',
    stateIf: 'showSalesTax',
    tooltip: 'Only enter purchases that are subject to sales tax in the community.'
  }, {
    inputType: 'dollars',
    name: 'annualLocalTaxableSales',
    dataProperty: 'currentTaxableSales',
    label: 'Current annual Taxable Sales',
    if: 'requestTemplate.salesTaxOnReview',
    scenarioIf: 'primaryDistrict.doesApplyTaxableSalesTax',
    stateIf: 'showSalesTax',
    tooltip: 'Only enter sales that are subject to sales tax in the community.'
  }, {
    inputType: 'whole',
    name: 'hotelNightsVisitors',
    dataProperty: 'currentHotelNights',
    label: "Current Annual Hotel Nights Supported"
  }];

  exports.default = {
    reviews: {
      currentFacility: {
        if: "requestTemplate.currentFacility",
        editScreenPath: "current.currentFacility",
        fragments: [{ type: 'text', text: "The Project has a site " }, { if: 'currentSiteAddress', fragments: [{ type: 'text', text: 'at ' }, { type: 'var', dataProperty: 'currentSiteAddress' }] }, { if: 'currentSiteOwnership', fragments: [{ type: 'text', text: ' that we ' }, { type: 'var', dataProperty: 'currentSiteOwnership', format: 'array' }, { if: 'hasCurrentLease', fragments: [{ type: 'text', text: '. Current lease ends at ' }, { type: 'var', dataProperty: 'currentLeaseEnds' }] }] }]
      },
      currentSquareFootage: {
        if: "requestTemplate.squareFootageScreen",
        editScreenPath: "current.currentSquareFootage",
        // BUG TODO: This isn't updating more than once.
        fragments: [{ type: 'text', text: "The Project occupies " }, { type: 'hash-total', dataProperty: 'currentSiteSquareFootage', format: 'whole' }, { type: 'text', text: ' square feet with uses including ' }, { type: 'hash-keys', dataProperty: 'currentSiteSquareFootage' }]
      },
      currentIndustry: {
        if: "requestTemplate.productsAndIndustry",
        editScreenPath: "current.currentProductAndIndustry",
        fragments: [{ type: 'text', text: "The primary NAICS code associated with the current operation is " }, { type: 'var', dataProperty: 'currentMultiplierCode' }]
      },
      currentJobsAndSalaries: {
        if: "requestTemplate.showAnyJobsOrSalaries",
        editScreenPath: "current.currentJobsAndSalaries",
        fragments: [{ type: 'text', text: "We currently employ " }, { type: 'var', dataProperty: 'currentJobs', format: 'whole' }, { type: 'text', text: ' people in the community' }, { if: 'requestTemplate.currentAvgAnnualSalary', fragments: [{ type: 'text', text: 'with an average salary of ' }, { type: 'var', dataProperty: 'currentAverageSalary', format: 'money' }] }]
      },
      currentProperty: {
        if: "requestTemplate.currentPropertyAllowed",
        editScreenPath: "current.currentProperty",
        fragments: [{ type: 'text', text: "The market value of the Project's current property is:" }, { type: 'list-items', items: [{ type: 'var', dataProperty: 'currentCapitalInvestmentLand', label: 'Land: ', format: 'money', if: 'requestTemplate.hasCapitalInvestmentLand' }, { type: 'var', dataProperty: 'currentCapitalInvestmentBuildings', label: 'Buildings: ', format: 'money', if: 'requestTemplate.hasCapitalInvestmentBuildings' }, { type: 'var', dataProperty: 'currentCapitalInvestmentFurniture', label: 'FF&E: ', format: 'money', if: 'requestTemplate.hasCapitalInvestmentEquipment' }, { type: 'var', dataProperty: 'currentCapitalInvestmentInventory', label: 'Inventories: ', format: 'money', if: 'requestTemplate.hasCapitalInvestmentInventories' }] }]
      },
      currentRevenue: {
        if: 'requestTemplate.currentProjectOther',
        editScreenPath: 'current.currentTaxablePurchasesAndSales',
        fragments: [{ type: 'text', text: 'The Project currently supports: ' }, { type: 'list-items', items: [{ if: 'requestTemplate.salesTaxOnReview', fragments: [{ type: 'var', dataProperty: 'currentTaxablePurchases', format: 'money' }, { type: 'text', text: ' in annual Taxable Purchases' }] }, { if: 'requestTemplate.salesTaxOnReview', fragments: [{ type: 'var', dataProperty: 'currentTaxableSales', format: 'money' }, { type: 'text', text: ' in annual Taxable Sales' }] }, { if: 'requestTemplate.hotelNights', fragments: [{ type: 'var', dataProperty: 'currentHotelNights', format: 'whole' }, { type: 'text', text: ' Local Hotel Nights' }] }] }]
      }
    },
    currentFacility: {
      sectionBoolean: 'currentFacility',
      name: "Current Operations - Site",
      content: [{ name: "siteAddress", inputType: "address", dataProperty: 'currentSiteAddress', label: "Current Site Address" }, { name: "projectPlans", inputType: "checkboxes", dataProperty: 'currentSiteOwnership', minSize: "200", label: "", other: true, options: ["Own", "Lease"] }, { name: "leaseTermInYears", inputType: "text", dataProperty: 'currentLeaseEnds', label: "Date current Lease ends",
        notRequired: true, if: 'hasCurrentLease', alwaysDisplayInRequestTemplate: true }]
    },
    currentSquareFootage: {
      sectionBoolean: 'squareFootageScreen',
      name: "Current Operations - Square Footage",
      wizardText: "How much space do you currently occupy?",
      content: [{ name: "squareFootage", inputType: "hash", dataProperty: 'currentSiteSquareFootage', labelLeft: "Use", labelRight: "Current SF",
        requestTemplateLink: { text: "Manage square footage use types", route: 'user.customization' },
        clientExplanation: { listProperty: 'client.squareFootageTypes' },
        dynamicRows: { dataProperty: 'client.squareFootageTypes', maskType: "whole", showTotal: true } }]
    },
    currentProductAndIndustry: {
      sectionBoolean: "productsAndIndustry",
      name: "Current Operations - Product & Industry",
      wizardText: "Tell us what the Company produces.",
      nextStepAction: 'nextAndUpdateMultipliers',
      previousStepAction: 'previousAndUpdateMultipliers',
      content: [{ name: 'localService', inputType: 'text', dataProperty: 'currentLocalService', label: "Service or product produced at local site" }, {
        name: "primaryNAICSCode",
        inputType: "naics-search",
        isCurrent: true,
        dataProperty: 'currentMultiplierCode',
        multiplierPath: 'currentMultiplier',
        multiplierCodePath: 'currentMultiplierCode',
        label: 'Primary NAICS Code',
        tooltip: 'Indicate the NAICS corresponding to the activities to be conducted in the community. If an auto manufacturer is locating its headquarters, select the code for headquarters operations instead of auto manufacturing. Check out the <a target="_blank" href="https://www.census.gov/naics/" class="underline">US Census Bureau</a> if you aren’t sure which NAICS code to use.'
      }]
    },
    currentJobsAndSalaries: {
      sectionBoolean: 'currentJobsAndSalaries',
      name: "Current Operations - Jobs & Salaries",
      wizardText: "Tell us about your current employment level",
      content: jobsAndSalaryContent
    },
    currentProperty: {
      sectionBoolean: 'capitalInvestment',
      name: "Current Operations - Property",
      if: 'currentPropertyAllowed',
      wizardText: "Identify the market value of the Company's taxable property currently on the tax rolls.",
      content: propertyContent
    },
    currentTaxablePurchasesAndSales: {
      sectionBoolean: 'currentProjectOther',
      name: "Current Operations - Other Activity",
      wizardText: "Tell us about other levels of current activity",
      content: taxablePurchasesAndSalesContent
    },
    expansionPlans: {
      sectionBoolean: 'expansionPlans',
      name: "Expansion Plans",
      wizardText: "Do you plan to hire more workers or make additional capital investments?",
      sidebarName: "Expansion Plans",
      isRequired: true,
      mustBeValidToContinue: true,
      content: [{
        name: 'expansionPlans', inputType: 'panel-selection', dataProperty: 'willExpand',
        panels: [{
          value: true,
          label: "<strong>YES</strong>, an Expansion is planned"
        }, {
          value: false,
          label: "<strong>NO</strong>, an Expansion is not planned"
        }]
      }]
    }
  };
});