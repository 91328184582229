define('dashboard/components/graphs/distribution-graph', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Component.extend({

    barHeight: Ember.computed('size', function () {
      var size = this.get('size');
      return size === 'sm' ? 28 : 42;
    }),

    xMax: Ember.computed('data', function () {
      return d3.max(this.get('data'), function (d) {
        return d.value;
      });
    }),

    xMin: Ember.computed('data', function () {
      var min = d3.min(this.get('data'), function (d) {
        return d.value;
      });
      return min > 0 ? 0 : min;
    }),

    names: Ember.computed.mapBy('data', 'name'),

    dataExists: Ember.computed.bool('data'),

    reDraw: Ember.observer('data', 'dataExists', function () {
      this.get('data');

      if (this.get('dataExists')) {
        Ember.run.once(this, 'drawChart');
      }
    }),

    init: function init() {
      this._super.apply(this, arguments);

      this.set('margin', { top: 20, right: 5, bottom: 20, left: 5 });
      this.set('padding', { right: 30, bottom: 20, left: 170 });

      if (this.get('dataExists')) {
        Ember.run.once(this, 'drawChart');
      }

      Ember.$(function () {
        Ember.$('a[rel=tipsy]').tipsy({ fade: true, gravity: 'n' });
      });
    },


    drawChart: function drawChart() {
      var _this = this;

      var svg = d3.select('.horizontal-graph'),
          margin = this.get('margin'),
          padding = this.get('padding'),
          barHeight = this.get('barHeight'),
          height = this.get('data').length * barHeight,
          data = this.get('data'),
          names = this.get('names'),
          yRangePadding = 0.38,
          xMax = this.get('xMax'),
          xMin = this.get('xMin'),
          yMax = height,
          abatementsAsCosts = this.get('abatementsAsCosts');

      var width = initScale();

      var x = d3.scaleLinear().domain([xMin, xMax]).range([0, width - padding.right - padding.left]);

      var y = d3.scaleBand().rangeRound([0, yMax]).paddingInner(yRangePadding).domain(names);

      var xAxis = d3.axisBottom().scale(x).ticks(getXTicks(width)).tickSize(-yMax - 10, 0).tickPadding(10).tickFormat(function (d) {
        return numeral(d).format('($000.[0]a)');
      });

      var yAxis = d3.axisLeft().scale(y).tickSize(0, 0).tickPadding(10);

      var graph_bg = svg.select('.horizontal.graph-bg'),
          bar = svg.select('.horizontal-bars').selectAll('.distribution-bar').data(data),
          xAxisElement = svg.select('.horizontal.x.axis'),
          yAxisElement = svg.select('.horizontal.y.axis');

      svg.selectAll('.distribution-bar').remove();
      svg.selectAll('.value-text').remove();

      svg.attr('width', width).attr('height', height + margin.top + margin.bottom + padding.bottom);

      graph_bg.attr('width', width - padding.left).attr('height', height + 10).attr('transform', 'translate(' + padding.left + ', ' + (margin.top - 10) + ' )');

      xAxisElement.attr('transform', 'translate(' + padding.left + ',' + (height + margin.bottom) + ')').call(xAxis);

      yAxisElement.attr('transform', 'translate(' + padding.left + ', ' + (margin.top - 5) + ')').call(yAxis).selectAll(".tick text").call(wrap, 200);

      bar.enter().append('rect').attr('class', function (d) {
        var valueClass = d.value < 0 ? 'distribution-bar cost' : 'distribution-bar benefit';
        var typeClass = abatementsAsCosts ? 'net-less-incentives' : 'net';
        return valueClass + ' ' + typeClass;
      })
      // .attr('class', function() { return abatementsAsCosts ? 'net-less-incentives' : 'net'; })
      .attr('x', function (d) {
        return x(Math.min(0, d.value));
      }).attr('y', '0').attr('width', function (d) {
        return Math.abs(x(d.value) - x(0));
      }).attr('height', y.bandwidth()).attr('transform', function (d, i) {
        return 'translate(' + padding.left + ',' + (i * barHeight + margin.top) + ')';
      });

      // bar
      //   .enter()
      //   .append('text')
      //   .attr('class', 'value-text')
      //   .attr('x', function(d) { return x(Math.min(0, d)); })
      //   .attr('y', '0')
      //   .attr('dy', '.35em')
      //   .text(function(d) { return numeral(d).format('($000a)'); })
      //   .attr('transform', function(d, i) {
      //     let width = Math.abs(x(d) - x(0));
      //     return 'translate('+ (padding.left + width + 5) + ',' + ((i * barHeight) + 34) + ')';
      //   })
      //   .style("text-anchor", 'start');

      // bar tooltips
      this.$('.distribution-bar').tipsy({
        gravity: 's',
        html: true,
        title: function title() {
          var d = this.__data__;
          // type = d.value < 0 ? d.type.capitalize() : 'Benefit',
          if (d.value >= 1000000 || d.value <= -1000000) {
            return d.name + '<br />' + numeral(d.value).format('($0.00a)');
          }
          return d.name + '<br />' + numeral(d.value).format('($0,0)');
        },
        opacity: 1
      });

      function wrap(text) {
        text.each(function () {
          var text = d3.select(this),
              words = text.text().split(/\s+/),
              y = text.attr("y"),
              dy = parseFloat(text.attr("dy")),
              tspan = text.text('').append("tspan").attr("x", -10).attr("y", y).attr("dy", dy + "em"),
              label = void 0;

          label = words.join(' ');
          if (label.length > 20) {
            tspan.text(label.substring(0, 19) + '...');
          } else {
            tspan.text(label);
          }
        });
      }

      function initScale() {
        var containerEl = d3.select('#distribution-graph');

        if (!containerEl.empty()) {
          return parseInt(containerEl.style('width')) - margin.left - margin.right - padding.right;
        }
      }

      window.onresize = resize;

      function resize(width) {
        var containerEl = d3.select('#distribution-graph');
        if (containerEl) {
          if ((typeof width === 'undefined' ? 'undefined' : _typeof(width)) === 'object') {
            width = initScale();
          }

          x.range([0, width - padding.right - padding.left]);

          svg.attr('width', width);
          graph_bg.attr('width', width - padding.left);
          svg.selectAll('.distribution-bar').attr('width', function (d) {
            return Math.abs(x(d.value) - x(0));
          });
          svg.selectAll('.distribution-bar').attr('x', function (d) {
            return x(Math.min(0, d.value));
          });
          // bar.select('text').attr('x', function(d) { return x(d) + 5; });

          // add more x-axis ticks for wider graphs
          xAxis.ticks(getXTicks(width));
          svg.select('.x.axis').call(xAxis);
        }
      }

      function getXTicks(width) {
        return width / 133.333;
      }

      window.onbeforeprint = function () {
        resize(780);
      };

      (function () {
        var beforePrint = function beforePrint() {
          if (_this.get('size') === 'sm') {
            resize(409);
          } else {
            resize(780);
          }
        };

        if (window.matchMedia) {
          var mediaQueryList = window.matchMedia('print');
          mediaQueryList.addListener(function (mql) {
            if (mql.matches) {
              beforePrint();
            }
          });
        }

        window.onbeforeprint = beforePrint;
      })();
    }

  });
});