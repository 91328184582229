define('dashboard/components/subtle-select-box', ['exports', 'dashboard/mixins/hover'], function (exports, _hover) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_hover.default, {
    tagName: 'span',
    classNames: ['subtle-select-box', 'cursor', 'inline'],
    minWidth: '180',
    click: function click(e) {
      e.stopPropagation();
    },

    actions: {
      updateSelection: function updateSelection(selection) {
        this.updateSelection(selection);
      }
    }
  });
});