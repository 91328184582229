define('dashboard/components/modals/multiple-district-select-modal', ['exports', 'dashboard/components/modals/modal-confirm'], function (exports, _modalConfirm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _modalConfirm.default.extend({

    actions: {
      selectDistricts: function selectDistricts(selected) {
        this.sendAction('action', selected);
      }
    }

  });
});