define('dashboard/mixins/essentials-redirect', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    afterModel: function afterModel(model, transition) {
      var scenario = model.scenario || model;
      if (!scenario) return;

      var isEssentialsScenario = scenario.get('essentialsScenario');
      var allowedRoutes = ['scenario.report.fact-sheet', 'scenario.raw-data'];
      var routeIsOk = !allowedRoutes.includes(transition.targetName);
      if (isEssentialsScenario && routeIsOk) {
        this.transitionTo('scenario.report.fact-sheet');
      }
    }
  });
});