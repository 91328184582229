define('dashboard/objects/tif-duration-schedule', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({

    originalRate: Ember.computed('taxRate', 'tifRate', function () {
      return this.get('taxRate') + this.get('tifRate');
    }),

    taxRateSchedule: Ember.computed('taxRate', 'duration', 'scenarioLength', 'originalRate', function () {
      var rate = this.get('taxRate'),
          duration = parseInt(this.get('duration')),
          scenarioLength = this.get('scenarioLength'),
          originalRate = this.get('originalRate'),
          rateArray = [];

      for (var i = 0; i < scenarioLength; i++) {
        rateArray[i] = i < duration ? rate : originalRate;
      }

      return rateArray;
    }),

    tifRateSchedule: Ember.computed('tifRate', 'duration', 'scenarioLength', function () {
      var rate = this.get('tifRate'),
          duration = parseInt(this.get('duration')),
          scenarioLength = this.get('scenarioLength'),
          rateArray = [];

      for (var i = 0; i < scenarioLength; i++) {
        rateArray[i] = i < duration ? rate : 0;
      }

      return rateArray;
    }),

    tifAnnualSchedule: Ember.computed('schedule', 'tifDistrict.enterAsDecimal', 'duration', 'scenarioLength', 'key', 'tifRate', function () {
      var schedule = this.get('schedule');

      if (!schedule) {
        return [];
      }

      var enterAsDecimal = this.get('tifDistrict.enterAsDecimal'),
          key = this.get('key'),
          value = enterAsDecimal ? this.get('tifRate') : schedule.get(key + 'Contributed'),
          scenarioLength = this.get('scenarioLength'),
          duration = parseInt(this.get('duration')) || scenarioLength,
          output = [];

      for (var i = 0; i < scenarioLength; i++) {
        if (i < duration) {
          output[i] = value ? value : 0;
        } else {
          output[i] = 0;
        }
      }

      return output;
    })

  });
});