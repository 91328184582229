define('dashboard/components/expandable-menu-icon', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['position-relative'],

    staticIconText: null,
    staticIcon: null,
    isShown: false,
    faSize: 'lg',

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      var event = 'click.' + this.elementId;
      var selectList = this.$();
      //for handling the case when bubbles false and hide this dropdown when clicking on others
      Ember.$('body').on(event, '.icon-circle', function (e) {
        Ember.run(function () {
          _this.hideDropdownIfNotselectList.apply(_this, [e, selectList]);
        });
      });

      Ember.$('body').on(event, '.dropdown-menu li p', function (e) {
        Ember.run(function () {
          _this.hideDropdownIfNotselectList.apply(_this, [e, selectList]);
        });
      });

      Ember.$('body').click(function (e) {
        Ember.run(function () {
          _this.hideDropdownIfNotselectList.apply(_this, [e, selectList]);
        });
      });
    },
    hideDropdownIfNotselectList: function hideDropdownIfNotselectList(e, selectList) {
      var _this2 = this;

      var el = Ember.$(e.target);

      if (el.is(selectList) || !el.closest(selectList).length) {
        Ember.run(function () {
          if (_this2.get('isShown') && !_this2.get('isDestroyed')) {
            _this2.set('isShown', false);
          }
        });
      }
    },


    actions: {
      showSelect: function showSelect() {
        this.toggleProperty('isShown');
      },
      closeMenu: function closeMenu() {
        this.set('isShown', false);
      }
    }
  });
});