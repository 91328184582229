define('dashboard/controllers/login', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    session: Ember.inject.service(),
    notify: Ember.inject.service(),

    actions: {
      authenticate: function authenticate() {
        var _getProperties = this.getProperties('identification', 'password', 'notify'),
            identification = _getProperties.identification,
            password = _getProperties.password,
            notify = _getProperties.notify;

        if (!identification) {
          return notify.alert('Email/Username can not be blank');
        }

        if (!password) {
          return notify.alert('Password can not be blank');
        }

        this.get('session').authenticate('authenticator:custom', {
          identification: identification,
          password: password
        }).catch(function (err) {
          console.log('LOGIN FAILURE', err);
          notify.alert(err.payload.error || 'Incorrect email or password');
        });
      }
    }
  });
});