define('dashboard/routes/client/tifs', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    controllerName: 'client',
    templateName: 'user/tifs',

    model: function model() {
      var clientId = this.modelFor('client').client.get('id');
      return this.store.query('tifDistrict', { client: clientId, deleted: false });
    },
    setupController: function setupController(controller, model) {
      controller.set('tifDistricts', model);
    }
  });
});