define('dashboard/objects/tif-incentive-engine', ['exports', 'dashboard/objects/incentive-engine', 'dashboard/utils/stringify', 'dashboard/utils/float-map'], function (exports, _incentiveEngine, _stringify, _floatMap) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var tifIncentiveEngine = _incentiveEngine.default.extend({

    tifDuration: Ember.computed.alias('scenario.model.tifDuration'),
    tifContributionsAnnual: Ember.computed.alias('annualNetBenefits'),

    incentivesDetailedReportTable: Ember.computed.gt('indexOfLastYearWithIncentive', 0),

    incentivePerJob: Ember.computed('totalIncentive', 'jobs', {
      get: function get() {
        var totalIncentive = this.get('totalIncentive'),
            totalJobs = this.get('jobs');

        return totalJobs > 0 ? totalIncentive / totalJobs : 0;
      },
      set: function set(key, value) {
        var jobs = this.get('scenario.model.jobsAddedEachYear'),
            incentives = [];

        for (var i = 0; i < this.get('assumption.scenarioLength'); i++) {
          incentives[i] = jobs[i] ? jobs[i] * value : 0;
        }

        this.set('schedule.incentives', (0, _stringify.default)(incentives));

        if (this.get('tifDuration') && this.get('totalIncentive') > this.get('netBenefits') + 1) {
          this.get('notify').alert('Incentive amount should not be greater than total TIF revenue.', { closeAfter: 4000 });
        }

        return value;
      }
    }),

    incentiveRateOfReturn: Ember.computed('totalIncentive', 'netBenefits', {
      get: function get() {
        var totalIncentive = this.get('totalIncentive'),
            totalBenefits = this.get('netBenefits'),
            // for tifs: this is total contribution
        years = this.get('assumption.scenarioLength'),
            averageNetBenefitsPerYear = totalBenefits / years,
            tifDuration = this.get('tifDuration'),
            output = void 0;

        // for tifs, we can't give more incentives than what's being contributed to tif
        if (tifDuration && totalIncentive > totalBenefits + 1) {
          // add one to account for rounding
          return "N/A";
        }
        output = parseInt(totalIncentive.toFixed(3)) > 0 ? Math.round(averageNetBenefitsPerYear / totalIncentive * 1000) / 1000 : 0;
        return output.toString();
      },
      set: function set(key, value) {

        var totalIncentive = this.get('totalIncentive'),
            totalBenefits = this.get('netBenefits'),
            years = this.get('assumption.scenarioLength'),
            averageNetBenefitsPerYear = totalBenefits / years,
            output = void 0;

        output = this.get('incentiveRateOfReturn');

        if (value.toFixed(3) !== output) {

          totalIncentive = value !== 0 ? averageNetBenefitsPerYear / value : 0;

          // for tifs, this acts as a maximum value we can set incentives
          // this will end up changing rate of return since the getter for this property is bound to totalIncentive
          if (this.get('tifDuration') && totalIncentive > totalBenefits) {
            this.get('notify').alert('Rate of Return is too low to pay back incentive before TIF duration is reached.', { closeAfter: 5000 });
            totalIncentive = totalBenefits;
          }

          this.set('totalIncentive', totalIncentive);
          return value;
        }
      }
    }),

    incentivePaybackPeriod: Ember.computed('totalIncentive', 'extendedNetBenefits', 'extendedCumulativeNetBenefits', {
      get: function get() {
        var incentive = parseFloat(this.get('totalIncentive')),
            netBenefits = this.get('extendedNetBenefits'),
            cumulativeNetBenefits = this.get('extendedCumulativeNetBenefits'),
            tifDuration = this.get('tifDuration'),
            initialBenefit = void 0,
            lastCumulativeBenefit = void 0;

        if (netBenefits && cumulativeNetBenefits) {
          initialBenefit = netBenefits.get('firstObject');
          lastCumulativeBenefit = cumulativeNetBenefits.get('lastObject');
        }
        if (!netBenefits || incentive === 0) {
          return 0;
        }
        if (incentive < initialBenefit) {
          return incentive / initialBenefit;
        }
        if (tifDuration && incentive > lastCumulativeBenefit + 1) {
          // add one to account for rounding
          // since this is an incentive, we will never pay it back if it's greater than our total tif contributions
          return "N/A";
        }
        if (incentive > lastCumulativeBenefit) {
          this.extendCumulativeBenefits();
        }
        return this.paybackPeriodGivenIncentive(incentive);
      },
      set: function set(key, value) {
        var output = this.get('incentivePaybackPeriod'),
            tifDuration = this.get('tifDuration');

        if (value !== Math.round(output * 10) / 10 || output <= 0) {
          var newIncentiveTotal = this.incentiveGivenPaybackPeriod(value);
          this.set('totalIncentive', newIncentiveTotal);

          if (tifDuration && value > tifDuration) {
            this.get('notify').alert('Payback Period cannot exceed TIF duration.', { closeAfter: 5000 });
          }

          return value;
        }
      }
    }),

    tifPercentsFromIncentives: function tifPercentsFromIncentives(incentives, tifContributionsAnnual) {
      return incentives.map(function (incentive, i) {
        var tifAnnual = tifContributionsAnnual[i] || 0;
        return incentive / tifAnnual;
      });
    },


    percentOfTif: Ember.computed('schedule.{incentives,percentOfTif}', 'tifContributionsAnnual', {
      get: function get() {
        // probably just need to get this property based on tifContributionsAnnual too to make sure it's correct
        // ie, calculate percents based on proportion of scehedule.incentives / TIF contributions per year
        var incentives = (0, _floatMap.default)(this.get('schedule.incentives'));
        var tifContributionsAnnual = this.get('tifContributionsAnnual');
        return this.tifPercentsFromIncentives(incentives, tifContributionsAnnual);
      },
      set: function set(key, value) {
        var percents = value;
        var tifContributionsAnnual = this.get('tifContributionsAnnual');
        var newTifIncentives = [];

        // when we set this property, also set schedule.incentives based on percent values
        newTifIncentives = tifContributionsAnnual.map(function (yearlyTif, i) {
          console.log('yearlyTif:', yearlyTif, 'percents[i]:', percents[i]);
          var yearly = yearlyTif || 0;
          var percent = percents[i] || 0;
          return yearly * percent;
        });

        // this.set('schedule.percentOfTif', percents)
        this.set('schedule.incentives', (0, _stringify.default)(newTifIncentives));

        return value;
      }
    })

  });

  exports.default = tifIncentiveEngine;
});