define('dashboard/objects/ei-av-land', ['exports', 'dashboard/utils/appreciation-schedule', 'dashboard/utils/percent-each'], function (exports, _appreciationSchedule, _percentEach) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var EiAvLandObject = Ember.Object.extend({

    output: Ember.computed('inputArray', 'appreciation', function () {
      var inputArray = this.get('inputArray'),
          appreciation = (0, _percentEach.percentify)(this.get('appreciation'));

      return (0, _appreciationSchedule.default)(inputArray, appreciation);
    })

  });

  exports.default = EiAvLandObject;
});