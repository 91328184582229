define('dashboard/components/side-nav', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    open: false,

    session: Ember.inject.service(),
    classNames: ['side-nav'],

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      var handlerIn = function handlerIn() {
        _this.$('.bm-menu').mousemove(function () {
          _this.set('open', true);
          _this.$('.bm-menu').off('mousemove');
          // $('.nav-header-hidden').animate({ height: '44px' });
        });
      };
      var handlerOut = function handlerOut() {
        _this.set('open', false);
        // $('.nav-header-hidden').animate({ height: 0 });
      };

      this.$('.bm-menu').hover(handlerIn, handlerOut);
    },


    // mouseMove() {
    //   // wait for animation to finish(400ms) then clear queue
    //   // prevents menu bouncing when mousing over and off quickly
    //   setTimeout(function() {
    //     $('.nav-header-hidden').clearQueue();
    //   }, 500);
    // },

    actions: {
      invalidateSession: function invalidateSession() {
        this.get('session').invalidate();
      }
    }

  });
});