define('dashboard/components/horizontal-table-display', ['exports', 'dashboard/utils/not-null'], function (exports, _notNull) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    hasData: Ember.computed('data.[]', function () {
      return (0, _notNull.default)(this.get('data'));
    }),

    total: Ember.computed('data.[]', function () {
      return this.get('data').reduce(function (prev, val) {
        return parseInt(prev) + parseInt(val);
      }, 0);
    })
  });
});