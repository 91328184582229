define('dashboard/mixins/scenario-actions', ['exports', 'lodash/object', 'dashboard/utils/copy-assumption'], function (exports, _object2, _copyAssumption) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Mixin.create({

    ajax: Ember.inject.service(),
    notify: Ember.inject.service(),
    adapterErrorNotifier: Ember.inject.service(),

    scenarioDeleteConfirmModalHidden: true,
    isRemoveScenarioModalHidden: true,

    modalTitleDeleteScenario: Ember.computed('scenarioToRemove', 'scenarioToRemove.{subtitle,index}', function () {
      var scenarioToRemove = this.get('scenarioToRemove');
      if (!scenarioToRemove) {
        return '';
      }
      var title = scenarioToRemove.get('subtitle') || 'Scenario ' + scenarioToRemove.get('index');
      return 'Delete ' + title;
    }),

    actions: {
      createScenario: function createScenario(project) {
        var _this = this;

        var client = this.get('client'),
            notify = this.get('notify'),
            self = this;

        if (this.get('readOnlyAccess')) {
          return notify.alert('You currently have read only access.');
        }

        client.get('assumption').then(function (assumption) {
          return (0, _copyAssumption.default)(assumption, _this.get('store'));
        }).then(function (newAssumption) {
          var newScenario = self.store.createRecord('scenario', {
            assumption: newAssumption,
            created: new Date(),
            project: project,
            essentialsScenario: client.get('essentialsClient')
          });

          newScenario.setDefaultHotelProperties(newScenario, newAssumption);

          return Ember.RSVP.hash({
            scenario: newScenario.save(),
            assumption: newAssumption
          });
        }).then(function (_ref) {
          var scenario = _ref.scenario,
              assumption = _ref.assumption;

          assumption.set('scenario', scenario);
          assumption.save();
          project.get('scenarios').pushObject(scenario);
          project.notifyPropertyChange('indexedScenariosOnly'); // makes things update a little better in real time
          scenario.set('walkthroughCompleted', false);
          self.send('redirectToScenario', client.get('id'), project.get('id'), scenario.get('id'), scenario);
        }).catch(function (err) {
          console.log('err ', err);
          notify.alert('There was an error creating your new scenario.');
        });
      },
      copyScenario: function copyScenario(oldScenario, withDistricts) {
        var project = oldScenario.get('project'),
            notify = this.get('notify'),
            globalCreatedAssumption,
            globalCreatedTifDistrict,
            self = this;

        function getAssumption() {
          // We give the option to also copy districts along with a scenario,
          // this tells us whether we want to also copy the scenario assumptions OR
          // if we just want to copy the current default assumptions for this client
          if (!withDistricts) {
            return self.get('client.assumption');
          }
          return oldScenario.get('assumption');
        }

        // Copy assumption model for this scenario
        function cloneAssumption(oldAssumption) {
          // Set scenario length to max of oldScenario's length or client's default scenario length
          var scenarioLength = Math.max(oldScenario.get('assumption.scenarioLength'), self.get('client.assumption.scenarioLength'));
          return (0, _copyAssumption.default)(oldAssumption, self.store, {
            scenarioLength: scenarioLength,
            startingYear: oldScenario.get('assumption.startingYear')
          });
        }

        // Get TIF District for old scenario
        function getTifDistrict(createdAssumption) {
          globalCreatedAssumption = createdAssumption;
          return oldScenario.get('tifDistrict');
        }

        // copy TIF schedules and TIF district if present
        function copyTifDistrict(tifDistrict) {
          if (!tifDistrict) {
            return Ember.RSVP.resolve();
          }

          return tifDistrict.get('tifSchedules').then(copyTifSchedules).then(copyTifDistrict);

          function copyTifSchedules(oldSchedules) {
            return Ember.RSVP.all(oldSchedules.map(function (oldSchedule) {

              var oldScheduleProperties = oldSchedule.toJSON(),
                  newSchedule = void 0;

              delete oldScheduleProperties.created;
              delete oldScheduleProperties.group;

              newSchedule = self.store.createRecord('tifSchedule', oldScheduleProperties);
              newSchedule.set('group', oldSchedule.get('group'));
              return newSchedule.save();
            }));
          }

          function copyTifDistrict(tifSchedules) {
            var client = self.get('client');
            var options = tifDistrict.toJSON();

            delete options.created;
            delete options.client;
            delete options.tifSchedules;

            return self.store.createRecord('tifDistrict', _object2.default.assign(options, { client: client, tifSchedules: tifSchedules })).save();
          }
        }

        // Copy scenario model
        function copyScenario(createdTifDistrict) {
          globalCreatedTifDistrict = createdTifDistrict;
          var newScenario = oldScenario.toJSON();

          for (var key in newScenario) {
            if (newScenario[key] !== oldScenario.get(key)) {
              newScenario[key] = oldScenario.get(key);
            }
          }

          newScenario.id = null;
          delete newScenario.districts;
          delete newScenario.created;
          newScenario.created = new Date();
          delete newScenario.finalized;
          delete newScenario.isAdminUnlocked;
          if (!withDistricts) {
            newScenario.usesModifiedAssumptions = false;
            delete newScenario.districtsTransitioned;
          }

          newScenario.subtitle = newScenario.subtitle ? 'Copy of ' + newScenario.subtitle : 'Copy of Scenario ' + oldScenario.get('index');
          newScenario.walkthroughCompleted = true;
          newScenario.isCopyingScenario = true;

          if (oldScenario.get('isRequest')) {
            newScenario.subtitle = oldScenario.get('subtitle') || 'Scenario ' + (project.get('indexedScenariosOnly.length') + 1) + ' with Client Data';
            newScenario.createdFromId = oldScenario.get('id');
            newScenario.walkthroughCompleted = false;
            newScenario.isRequest = false;
          }

          if (newScenario.multiplierCode !== newScenario.currentMultiplierCode) {
            newScenario.multipliersAreMatched = false;
          }

          if (newScenario.siteAddress) {
            newScenario.projectLocation = newScenario.siteAddress;
          }

          // if scenario was a "Pro" scenario but client is an Essentials client, make copied scenario an essentials scenario
          if (!newScenario.essentialsScenario && self.get('client.essentialsClient')) {
            newScenario.essentialsScenario = true;
          }

          newScenario.project = oldScenario.get('project');
          return self.store.createRecord('scenario', newScenario).save();
        }

        // Save scenario (we now have id)
        var afterScenarioCreate = function afterScenarioCreate(createdScenario) {
          var getIncentiveSchedules = function getIncentiveSchedules() {
            return oldScenario.get('incentiveSchedules');
          };

          // Make the incentive schedules for the newly created scenario look just like those from the copied scenario
          var copyIncentiveSchedules = function copyIncentiveSchedules(oldIncentiveSchedules) {

            // Return array of promises
            var createNewIncentiveSchedules = function createNewIncentiveSchedules(oldSchedule) {
              var oldScheduleProperties = oldSchedule.toJSON(),
                  newSchedule = self.store.createRecord('incentiveSchedule', { scenario: createdScenario, districtGroup: oldSchedule.get('districtGroup') });

              delete oldScheduleProperties.id;
              delete oldScheduleProperties.lastUpdated;
              delete oldScheduleProperties.created;
              delete oldScheduleProperties.scenario;
              delete oldScheduleProperties.districtGroup;

              for (var property in oldScheduleProperties) {
                newSchedule.set(property, oldSchedule.get(property));
              }

              return newSchedule.save();
            };

            return Ember.RSVP.all(oldIncentiveSchedules.map(createNewIncentiveSchedules));
          };

          // Associate new scenario and new assumption
          var associateScenario = function associateScenario() {

            var promiseArray = [];

            // Preserve district associations from old scenario
            oldScenario.get('districts').forEach(function (district) {
              if (withDistricts && district.get('adminCreated') && !district.get('createdInTransition')) {
                createdScenario.get('districts').pushObject(district);
              }
            });

            // Associate new assumption with new scenario
            globalCreatedAssumption.set('scenario', createdScenario);
            createdScenario.set('assumption', globalCreatedAssumption);
            createdScenario.set('tifDistrict', globalCreatedTifDistrict);

            promiseArray.push(globalCreatedAssumption.save());
            promiseArray.push(createdScenario.save());

            return Ember.RSVP.all(promiseArray);
          };

          var setHotelDefaults = function setHotelDefaults(_ref2) {
            var _ref3 = _slicedToArray(_ref2, 2),
                newAssumption = _ref3[0],
                newScenario = _ref3[1];

            if (!oldScenario.get('isRequest')) {
              return Ember.RSVP.Promise.resolve();
            }
            newScenario.setDefaultHotelProperties(newScenario, newAssumption);
            return newScenario.save();
          };

          // Create and resolve array of new district promises
          var createNewDistricts = function createNewDistricts() {

            // Find any Districts that need to be duplicated (if withDistricts param is true)
            var findModifiedDistricts = function findModifiedDistricts() {
              return oldScenario.get('districts.content').filter(function (district) {
                return withDistricts && (district.get('adminCreated') === false || district.get('createdInTransition'));
              });
            };

            // Copy each district
            var makeNewDistrictPromiseArray = function makeNewDistrictPromiseArray(districtsToCopy) {
              return districtsToCopy.map(function (oldDistrict) {
                var districtToCreate = oldDistrict.toJSON();
                // Remove read only properties that can not be set on new district
                delete districtToCreate.scenarios;
                delete districtToCreate.customCostRevenues;
                delete districtToCreate.directCostRevenues;
                delete districtToCreate.created;

                // Copy oldDistrict properties
                for (var key in districtToCreate) {
                  if (districtToCreate[key] !== oldDistrict.get(key)) {
                    districtToCreate[key] = oldDistrict.get(key);
                  }
                }

                districtToCreate.id = null;
                districtToCreate.modifiedForScenario = createdScenario.get('id');
                districtToCreate.customDistrictCopied = oldDistrict.get('id');

                districtToCreate = self.store.createRecord('taxDistrict', districtToCreate);
                return districtToCreate.save();
              });
            };

            return Ember.RSVP.all(makeNewDistrictPromiseArray(findModifiedDistricts()));
          };

          var associateNewDistricts = function associateNewDistricts(createdDistricts) {
            // Add district ids to scenario.districts array
            createdScenario.get('districts').pushObjects(createdDistricts);
            // return createdScenario.save();
            return Ember.RSVP.Promise.resolve(createdDistricts);
          };

          var afterDistrictsCreate = function afterDistrictsCreate(createdDistricts) {

            // Returns single saved district promise
            var handleEachCreatedDistrict = function handleEachCreatedDistrict(createdDistrict) {

              var copiedDistrict = oldScenario.get('districts.content').findBy('id', createdDistrict.get('customDistrictCopied'));

              var handleBuildingPermitsFees = function handleBuildingPermitsFees() /* group */{
                // find any building permits and fees which were associated with original versions of copied districts and make new BPF for new district
                var buildingPermitsFees = createdScenario.get('buildingPermitsFees'),
                    originalDistrictId = createdDistrict.get('customDistrictCopied');

                if (!buildingPermitsFees || !buildingPermitsFees.hasOwnProperty(originalDistrictId)) {
                  return Ember.RSVP.resolve();
                }

                // @TODO add undefined condition for BPF
                var originalBpf = buildingPermitsFees[originalDistrictId],
                    newDistrictId = createdDistrict.get('id');
                // set new bpf referencing new district instead of old
                buildingPermitsFees[newDistrictId] = {};
                buildingPermitsFees[newDistrictId].annual = originalBpf.annual;
                if (originalBpf.hasOwnProperty('copyOfDistrict')) {
                  buildingPermitsFees[newDistrictId].copyOfDistrict = originalBpf.copyOfDistrict;
                }
                // remove original as it will reference the old copied district which was created for a different scenairo
                delete buildingPermitsFees[originalDistrictId];

                return createdScenario.save(); // save here or just resolve later?
              };

              var getCustomCostRevs = function getCustomCostRevs() {
                return copiedDistrict.get('customCostRevenues');
              };

              var copyCustomCostRevenues = function copyCustomCostRevenues(customCostRevenues) {
                var promiseArray = customCostRevenues.map(function (oldCostRev) {
                  var costRevToCreate = oldCostRev.toJSON();
                  delete costRevToCreate.created;
                  return self.store.createRecord('customCostRevenue', costRevToCreate).save();
                });

                return Ember.RSVP.all(promiseArray);
              };

              var getDirectCostRevs = function getDirectCostRevs(createdCustomCostRevs) {
                createdDistrict.get('customCostRevenues').pushObjects(createdCustomCostRevs);
                return copiedDistrict.get('directCostRevenues');
              };

              var copyDirectCostRevs = function copyDirectCostRevs(directCostRevenues) {
                var promiseArray = directCostRevenues.map(function (oldCostRev) {
                  var costRevToCreate = oldCostRev.toJSON();
                  delete costRevToCreate.created;
                  costRevToCreate.customDirectCopied = oldCostRev.id;

                  // if this property already exists, we want to keep it referencing the original district
                  if (!costRevToCreate.copyOfDirect) {
                    // if property doesn't exist, it probably means this direct cost/rev was created for the modified district we're copying
                    costRevToCreate.copyOfDirect = oldCostRev.id;
                  }

                  return self.store.createRecord('directCostRevenue', costRevToCreate).save();
                });

                return Ember.RSVP.all(promiseArray);
              };

              var associateDirectCostRevs = function associateDirectCostRevs(createdDirectCostRevs) {
                createdDistrict.set('directCostRevenues', createdDirectCostRevs);

                var scenarioDirects = createdScenario.get('directCostRevenues');

                createdDirectCostRevs.forEach(function (newCostRev) {
                  if (scenarioDirects && scenarioDirects.hasOwnProperty(newCostRev.get('copyOfDirect'))) {
                    var oldCostRevReference = scenarioDirects[newCostRev.get('copyOfDirect')];
                    scenarioDirects[newCostRev.get('id')] = {
                      annual: oldCostRevReference.annual,
                      copyOfDirect: newCostRev.get('copyOfDirect')
                    };
                    if (oldCostRevReference.increase) {
                      scenarioDirects[newCostRev.get('id')].increase = oldCostRevReference.increase;
                    }
                    // remove old direct cost/rev since it was referencing directs created for different scenario
                    delete scenarioDirects[newCostRev.get('customDirectCopied')];
                  }
                });

                return createdDistrict.save();
              };

              return handleBuildingPermitsFees().then(getCustomCostRevs).then(copyCustomCostRevenues).then(getDirectCostRevs).then(copyDirectCostRevs).then(associateDirectCostRevs).catch(associationError);
            };

            return Ember.RSVP.allSettled(createdDistricts.map(handleEachCreatedDistrict));
          };

          var lockScenario = function lockScenario() {
            createdScenario.isAdminUnlocked = false;
          };

          var saveScenario = function saveScenario() {
            // keeps any scenario lists updated
            createdScenario.get('project').notifyPropertyChange('indexedScenariosOnly');
            // @TODO: add a check here or in the API PUT route to see if scenario isDirty and needs saving.
            // Otherwise, return a resolved promise to prevent version errors being thrown
            return createdScenario.save();
          };

          // Transition to new scenario page after success
          var goToScenario = function goToScenario() {
            self.send('redirectToScenario', self.get('client.id'), createdScenario.get('project.id'), createdScenario.get('id'), createdScenario);
          };

          // Catch errors after trying to associate new assumption with new scenario
          var associationError = function associationError(err) {
            console.log('err ', err);
            notify.alert('Something went wrong duplicating this scenario.');
          };

          /**
          *
          * STEP 2
          *
          **/
          getIncentiveSchedules().then(copyIncentiveSchedules).then(associateScenario).then(setHotelDefaults).then(createNewDistricts).then(associateNewDistricts).then(afterDistrictsCreate).then(lockScenario).then(saveScenario).then(goToScenario).catch(associationError);
        };

        // On scenario create failure, delete new assumption model
        var onFailure = function onFailure(failure) {
          notify.alert('There was an error copying this scenario.');
          console.log('scenario create failure', failure);
          if (globalCreatedAssumption) {
            globalCreatedAssumption.deleteRecord().save();
          }
        };

        // Catch generic errors
        var catchError = function catchError(err) {
          console.log('scenario create generic failure', err);
          notify.alert('There was an error creating your new scenario.');
        };

        /**
        *
        * START HERE
        *
        **/
        if (self.get('readOnlyAccess')) {
          return notify.alert('You currently have read only access.');
        }

        getAssumption().then(cloneAssumption).then(getTifDistrict).then(copyTifDistrict).then(copyScenario).then(afterScenarioCreate, onFailure).catch(catchError);
      },
      removeScenario: function removeScenario() {
        var _this2 = this;

        var scenario = this.get('scenarioToRemove');

        if (!scenario) {
          return;
        }

        var project = scenario.get('project');
        scenario.destroyRecord().then(function (scenario) {
          project.get('scenarios').removeObject(scenario);
          _this2.setProperties({
            isRemoveScenarioModalHidden: true,
            scenarioToRemove: null
          });
        });
      },
      openRemoveScenario: function openRemoveScenario(scenario) {
        if (this.get('readOnlyAccess')) {
          return this.get('notify').alert('You currently have read only access.');
        }
        this.setProperties({
          scenarioToRemove: scenario,
          isRemoveScenarioModalHidden: false
        });
      },
      openCopyScenario: function openCopyScenario(scenario) {
        if (this.get('readOnlyAccess')) {
          return this.get('notify').alert('You currently have read only access.');
        }

        if (scenario.get('districtsTransitioned')) {
          this.setProperties({
            scenarioToCopy: scenario,
            isCopyScenarioModalHidden: false
          });
        } else {
          this.send('copyScenario', scenario, true);
        }
      },


      // action sent from scenario-copy-modal component
      copyScenarioAction: function copyScenarioAction(withDistricts) {
        var scenario = this.get('scenarioToCopy'); // set in action above
        this.send('copyScenario', scenario, withDistricts);
        this.set('isCopyScenarioModalHidden', true);
      },
      redirectToScenario: function redirectToScenario(clientId, projectId, scenarioId, scenario) {
        var route = 'scenario';
        if (scenario && scenario.get('essentialsScenario')) {
          route = 'scenario.report.fact-sheet';
        }

        this.transitionToRoute(route, clientId, projectId, scenarioId);
      },


      // Handles follow-up actions after assigning data form to project OR creating a new project with a submitted data form
      requestMoveFinal: function requestMoveFinal(project, scenario, newScenario) {
        // copy the data form into a new scenario if applicable - uses action in ScenarioActions mixin
        if (newScenario) {
          this.send('copyScenario', scenario);
        } else {
          // otherwise just go directly to the new project
          this.transitionToRoute('project', this.get('client.id'), project.get('id'));
        }
      }
    }
  });
});