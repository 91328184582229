define('dashboard/mirage/factories/scenario', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    assumption: (0, _emberCliMirage.association)(),
    requestTemplate: (0, _emberCliMirage.association)(),

    averageAnnualSalaries: function averageAnnualSalaries() {
      return ["100000"];
    },
    taxablePurchases: function taxablePurchases() {
      return ["250000"];
    },
    taxableSales: function taxableSales() {
      return ["1000"];
    },
    buildingPermitsFees: function buildingPermitsFees() {
      return ["1"];
    },

    jobsAddedEachYear: function jobsAddedEachYear() {
      return [_faker.default.random.number(), _faker.default.random.number()];
    },

    walkthroughCompleted: true
  });
});