define('dashboard/components/tables/tif-distributions-detailed-table', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    otherDistrictLabel: Ember.computed('otherEngines.@each.tifContributionTotal', function () {
      // if the tif contributions within OtherDistricts only come from one district, show that district name as table heading
      var participatingOtherEngines = this.get('otherEngines').filterBy('tifContributionTotal');

      return participatingOtherEngines.length === 1 ? participatingOtherEngines.get('firstObject.district.name') : 'Other Districts';
    })

  });
});