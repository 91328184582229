define('dashboard/utils/scenario-inputs/company', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    reviews: {
      companyInformation: {
        if: 'requestTemplate.companyInfo',
        editScreenPath: 'company.companyInfo',
        fragments: [{ type: 'text', text: "The Company's information is as follows:" }, { type: 'list-items', items: [{ type: 'var', dataProperty: 'companyName', if: 'requestTemplate.companyName' }, { type: 'var', dataProperty: 'companyAddress', if: 'requestTemplate.companyAddress' }, { type: 'var', dataProperty: 'parentCompanyName', if: 'requestTemplate.parentCompanyName' }, { type: 'var', dataProperty: 'companyWebsite', if: 'requestTemplate.companyWebsite' }] }]
      },
      companyBackground: {
        if: 'requestTemplate.companyBackground',
        editScreenPath: 'company.companyBackground',
        fragments: [{ type: 'text', text: "The Company's background is as follows:" }, { type: 'list-items', items: [{ type: 'var', dataProperty: 'yearEstablished', label: 'Year Established: ', if: 'requestTemplate.yearEstablished' }, { type: 'var', dataProperty: 'companyState', label: 'State of Formation: ', if: 'requestTemplate.stateOfFormation' }, { type: 'var', dataProperty: 'businessStructure', label: 'Business Structure: ', if: 'requestTemplate.businessStructure' }, { type: 'var', dataProperty: 'ownershipType', label: 'Ownership: ', if: 'requestTemplate.companyOwnership' }] }]
      },
      contactInformation: {
        if: 'requestTemplate.pointOfContact',
        editScreenPath: 'company.pointOfContact',
        fragments: [{ type: 'text', text: 'Questions regarding the Project will be directed to:' }, { type: 'list-items', items: [{ type: 'var', dataProperty: 'contactName', if: 'requestTemplate.contactName' }, { type: 'var', dataProperty: 'contactTitle', if: 'requestTemplate.contactTitle' },
          //TODO: format it so it looks more like a phone number
          { type: 'var', dataProperty: 'contactPhone', if: 'requestTemplate.contactPhoneNumber' }, { type: 'var', dataProperty: 'contactEmail', if: 'requestTemplate.contactEmail' }] }]
      }
    },
    companyInfo: {
      sectionBoolean: "companyInfo",
      name: "Company Info",
      wizardText: "Tell us about the Company.",
      content: [{ name: "companyName", inputType: "text", dataProperty: 'companyName', label: "Company Name" }, { name: "confidentialIdentity", inputType: "radioBoolean", dataProperty: 'confidentialIdentity', label: "Is Company Name Confidential?" }, { name: "companyAddress", inputType: "address", dataProperty: 'companyAddress', label: "Company Headquarters Address" }, { name: "parentCompanyName", inputType: "text", dataProperty: 'parentCompanyName', notRequired: true, label: "Name of Parent Company, if applicable" }, { name: "companyWebsite", inputType: "text", dataProperty: 'companyWebsite', label: "Company Website" }]
    },
    companyBackground: {
      sectionBoolean: "companyBackground",
      name: "Company Background",
      wizardText: "Tell us about the Company.",
      content: [{ name: "yearEstablished", inputType: "text", dataProperty: 'yearEstablished', label: "Year Established" }, { name: "stateOfFormation", inputType: "text", dataProperty: 'companyState', label: "State of Formation" }, { name: "businessStructure", inputType: "select", dataProperty: 'businessStructure', label: "Business Structure",
        clientExplanation: { message: "(C-Corp, S-Corp, LLC, etc.)" },
        options: ["C Corp", "S Corp", "LLC", "LLP", "Partnership", "Sole Proprietor", "Non-Profit"] }, { name: "companyOwnership", inputType: "radio", dataProperty: 'ownershipType', label: "Ownership",
        clientExplanation: { message: "(Public or Private)" },
        options: ["Public", "Private"] }]
    },
    employmentDistribution: {
      sectionBoolean: "employmentDistribution",
      name: "Employment Distribution",
      wizardText: "Help us understand the distribution of the Company's current worldwide employment.",
      content: [{ name: "stateEmployees", inputType: "whole", dataProperty: 'stateEmployees', label: "Workers employed in the state" }, //TODO: display state based on previous answers
      { name: "usaEmployees", inputType: "whole", dataProperty: 'usaEmployees', label: "Workers employed in the USA" }, { name: "worldEmployees", inputType: "whole", dataProperty: 'worldEmployees', label: "Workers employed worldwide" }]
    },
    pointOfContact: {
      sectionBoolean: "pointOfContact",
      name: "Point of Contact",
      wizardText: "Who should we contact regarding questions about the Project?",
      content: [{ name: "contactName", inputType: "text", dataProperty: 'contactName', label: "Name" }, { name: "contactTitle", inputType: "text", dataProperty: 'contactTitle', label: "Title" }, { name: "contactPhoneNumber", inputType: "text", dataProperty: 'contactPhone', label: "Phone Number" }, { name: "contactEmail", inputType: "text", dataProperty: 'contactEmail', label: "Email",
        validations: { isEmail: true } }]
    }
  };
});