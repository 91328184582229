define('dashboard/components/project-tile', ['exports', 'dashboard/mixins/tagging', 'dashboard/mixins/project-actions'], function (exports, _tagging, _projectActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_tagging.default, _projectActions.default, {
    classNames: ['col-md-6', 'col-lg-4', 'col-xl-3', 'mb-3'],

    editing: false,

    lastVisibleTagIndex: null,
    numberOfHiddenTags: 0,

    client: Ember.computed.alias('project.client'),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var projectTags = this.get('projectTags').toArray().sortBy('name');
      var element = this.$('.hidden-tag-container');
      var cumulativeWidth = 0,
          lastVisibleTagIndex = projectTags.length - 1,
          i = void 0;

      for (i = 0; i < projectTags.length; i++) {
        var text = projectTags[i].get('name'),
            elementWidth = void 0;
        element.css({ fontSize: 13 });
        element.text(text);
        elementWidth = element.width();
        elementWidth += 38; //extra space within label element
        cumulativeWidth += elementWidth;

        if (cumulativeWidth > 325) {
          lastVisibleTagIndex = i - 1;
          break;
        }
      }
      this.set('lastVisibleTagIndex', lastVisibleTagIndex);
    },


    actions: {
      transitionToProject: function transitionToProject(projectId) {
        this.sendAction('transitionToProject', projectId);
      },
      toggleExpand: function toggleExpand() {
        this.toggleProperty('isExpanded');
      },
      updateTitle: function updateTitle() {
        this.sendAction('updateTitleAction', this.get('project'));
        this.set('editing', false);
      },
      deleteProjectHandler: function deleteProjectHandler() {
        this.sendAction('openDeleteProjectModal', this.get('project'));
      },
      restoreProjectHandler: function restoreProjectHandler() {
        this.sendAction('restoreProject', this.get('project'));
      },
      addTagToFilterTagsList: function addTagToFilterTagsList(tag) {
        this.sendAction('addTagToFilterTagsList', tag);
      }
    }
  });
});