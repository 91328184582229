define('dashboard/components/button-group/button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    id: null,
    expanded: false,

    actions: {
      buttonAction: function buttonAction() {
        if (this.get('expandable')) {
          this.toggleProperty('expanded');
        } else if (this.buttonAction) {
          this.buttonAction();
        }
      },
      closeMenu: function closeMenu() {
        this.set('expanded', false);
      }
    }
  });
});