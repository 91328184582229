define('dashboard/components/modals/data-request-move-projects-modal', ['exports', 'dashboard/components/modals/modal-confirm'], function (exports, _modalConfirm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _modalConfirm.default.extend({
    router: Ember.inject.service(),
    selectedProject: null,
    createScenarioWithProject: true,

    confirmText: Ember.computed('createScenarioWithProject', function () {
      return this.get('createScenarioWithProject') ? 'Assign & Create Scenario' : 'Assign';
    }),

    sortedProjects: Ember.computed('projects.@each.{active,won,lost,archived}', function () {
      var projects = this.get('projects'),
          statuses = ['active', 'won', 'lost', 'archived'];
      var output = [];

      statuses.forEach(function (status) {
        var groupName = status.capitalize();
        var options = projects.filterBy(status).sortBy('lastUpdatedScenario').reverse();
        if (options.get('length')) {
          output.push({ groupName: groupName, options: options });
        }
      });

      return output;
    }),

    actions: {
      selectProject: function selectProject(project) {
        this.set('selectedProject', project);
      },
      assign: function assign() {
        var _this = this;

        var request = this.get('scenario');
        var project = this.get('selectedProject');
        var newScenario = this.get('createScenarioWithProject');
        request.set('project', project);
        request.save().then(function (request) {
          _this.set('isHidden', true);
          _this.sendAction('afterRequestMoveAction', project, request, newScenario);
        });
      },
      cancel: function cancel() {
        this.set('selectedProject', null);
        this.set('createScenarioWithProject', true);
        this.send('slideOut');
      }
    }
  });
});