define('dashboard/routes/scenario/report/fact-sheet', ['exports', 'ember-local-storage', 'dashboard/mixins/reset-scroll-position'], function (exports, _emberLocalStorage, _resetScrollPosition) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_resetScrollPosition.default, {
    controllerName: 'scenario.report',

    sessionData: (0, _emberLocalStorage.storageFor)('session-data'),

    beforeModel: function beforeModel() {
      this.set('sessionData.reportSelection', 'fact-sheet');
    },
    model: function model() {
      return this.modelFor('scenario').scenario;
    }
  });
});