define('dashboard/helpers/random-string', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.randomString = randomString;
  function randomString() /*params, hash*/{
    return Math.random();
  }

  exports.default = Ember.Helper.helper(randomString);
});