define('dashboard/components/xls-file-upload/component', ['exports', 'ember-uploader'], function (exports, _emberUploader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberUploader.default.FileField.extend({
    classNames: ['upload-component', 'form-control-file'],
    accept: '.xls,.xlsx,.xlsb',
    filesDidChange: function filesDidChange(files) {
      if (!Ember.isEmpty(files)) {
        this.get('fileChanged')(files[0]);
      }
    }
  });
});