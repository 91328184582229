define('dashboard/routes/data-forms/request-templates/request-template/pdf', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var requestTemplate = this.modelFor('data-forms.request-templates.request-template');
      var scenarioPromise = this.store.createRecord('scenario', { requestTemplate: requestTemplate }); // fake scenario for preveiw - not to save

      return Ember.RSVP.hash({
        requestTemplate: requestTemplate,
        scenario: scenarioPromise
      });
    },
    setupController: function setupController(controller, models) {
      this._super(controller, models.requestTemplate);
      controller.set('scenario', models.scenario);
    }
  });
});