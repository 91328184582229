define('dashboard/components/modals/scenario-naics-modal', ['exports', 'dashboard/components/modals/modal-confirm'], function (exports, _modalConfirm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _modalConfirm.default.extend({
    classNames: ['modal-overlay-fixed'],

    industryHelp: 'Indicate the NAICS corresponding to the activities to be conducted in the community. If an auto manufacturer is locating its headquarters, select the code for headquarters operations instead of auto manufacturing. Check out the <a target="_blank" href="https://www.census.gov/naics/" class="underline">US Census Bureau</a> if you aren’t sure which NAICS code to use.',

    isWalkthrough: Ember.computed.equal('scenario.walkthroughCompleted', false),

    showCurrentOperations: Ember.computed('scenario.{isExistingBusiness,multipliersAreMatched,willExpand}', function () {
      var existingNotMatched = this.get('scenario.isExistingBusiness') && !this.get('scenario.multipliersAreMatched');
      var existingNoExpansion = this.get('scenario.isExistingBusiness') && !this.get('scenario.willExpand');
      return existingNotMatched || existingNoExpansion;
    }),

    actions: {
      showPreviousModal: function showPreviousModal() {
        this.set('isHidden', true);
        this.sendAction('showPreviousModal');
      },
      matchMultipliersAndSave: function matchMultipliersAndSave() {
        this.get('scenario').updateMatchingMultipliers();
        this.save();
      },
      toggleIncludeSpinoffImpacts: function toggleIncludeSpinoffImpacts() {
        var scenario = this.get('scenario');
        if (scenario.get('includeSpinoffImpacts')) {
          scenario.setProperties({
            'employmentMultiplier': scenario.get('multiplier.employment') || 1,
            'earningsMultiplier': scenario.get('multiplier.earnings') || 1,
            'currentEmploymentMultiplier': scenario.get('currentMultiplier.employment') || 1,
            'currentEarningsMultiplier': scenario.get('currentMultiplier.earnings') || 1
          });
        } else {
          scenario.setProperties({
            'employmentMultiplier': 1,
            'earningsMultiplier': 1,
            'currentEmploymentMultiplier': 1,
            'currentEarningsMultiplier': 1,
            'usesCustomMultipliers': false
          });
        }

        this.save();
      },
      submit: function submit() {
        if (this.get('isWalkthrough')) {
          this.set('scenario.walkthroughCompleted', true);
          this.set('scenario.isCopyingScenario', false);
          this.save();
        }
        this.send('cancel');
      }
    }
  });
});