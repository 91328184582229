define('dashboard/controllers/clients', ['exports', 'dashboard/utils/copy-to-clipboard'], function (exports, _copyToClipboard) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    store: Ember.inject.service(),
    notify: Ember.inject.service(),

    isActivateClientModalHidden: true,
    newClientName: null,
    activationUser: null,
    activationUrl: null,

    activationMessage: Ember.computed('activationUser.{firstName,email,username}', 'activationUrl', function () {
      var user = this.get('activationUser');
      var url = this.get('activationUrl');
      if (!user || !url) return '';

      var formatCredentials = function formatCredentials() {
        var _user$getProperties = user.getProperties('email', 'username'),
            email = _user$getProperties.email,
            username = _user$getProperties.username;

        var credentialParts = ['Email: ' + email];
        if (username) credentialParts.push('Username: ' + username);
        return credentialParts.join('\n');
      };

      var formattedInfoList = ["Use Google Chrome as your web browser when accessing Impact DashBoard.", "Schedule a one-on-one training here.", "In the future you may access Impact DashBoard by going here: https://dashboard.impactdatasource.com", "You can log in using the email address associated with the account or the username but there is only one password.  You may share the login credentials with other users in your organization and everyone will have access to projects that your organization has created. There is no restriction on the number of people that can be logged in at one time.", "Review the My Account section of the app to change/add your logo, change the color scheme, review your user guide, and configure other customizations. If you need me to tweak the organization's name, address or anything else just let me know."].map(function (text, index) {
        return '  ' + (index + 1) + '. ' + text;
      }).join('\n');

      return 'Hi ' + user.get('firstName') + ' -\n\nI created an Impact DashBoard account for your organization with the following details:\n\n' + formatCredentials() + '\n\nYou may create a password for the account using this link: ' + url + '\n\nHere are the top 5 things you need to know right now:\n' + formattedInfoList + '\n\nWe have a number of resources for clients on our Support page but feel free to reach out if you have any questions or run into any problems using the model.\n\nThanks so much!';
    }),

    activationMessageHTMLSafe: Ember.computed('activationMessage', function () {
      var message = this.get('activationMessage');
      return Ember.String.htmlSafe(message.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br />$2'));
    }),

    breadcrumbItems: Ember.computed('currentClient.name', function () {
      var items = [{ label: 'Clients', params: ['clients'] }];
      var currentClient = this.get('currentClient');
      if (currentClient) {
        items.push({ label: currentClient.get('name'), active: true });
      }
      return items;
    }),

    actions: {
      copyEmail: function copyEmail() {
        this.send('copyText', this.get('activationUser.email'), 'email');
      },
      copyUrl: function copyUrl() {
        this.send('copyText', this.get('activationUrl'), 'URL');
      },
      copyMessage: function copyMessage() {
        this.send('copyText', this.get('activationMessage'), 'message');
      },
      copyText: function copyText(textString, textType) {
        (0, _copyToClipboard.default)(textString);
        this.get('notify').success('Successfully copied ' + textType + ' to clipboard.');
      },
      hideActivateClientModal: function hideActivateClientModal() {
        this.setProperties({ activationUser: null, activationUrl: null });
      }
    }
  });
});