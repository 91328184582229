define('dashboard/mixins/tagging', ['exports', 'lodash/array', 'ember-cli-pagination/computed/paged-array', 'lodash'], function (exports, _array2, _pagedArray, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    store: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('projectsToUpdate', []);
    },


    allProjectTags: Ember.computed('projectList', 'projectList.@each.tags', function () {
      var projects = this.get('projectList') || [];
      var allTags = projects.mapBy('tags');

      var output = allTags.map(function (tags) {
        return tags.toArray().filter(function (t) {
          return t.get('category') == 'tag';
        });
      });
      return output ? _array2.default.flatten(output) : [];
    }),

    allProjectTagNames: Ember.computed('allProjectTags', function () {
      return this.get('allProjectTags').mapBy('name');
    }),

    uniqueProjectTagNames: Ember.computed.uniq('allProjectTagNames'),
    projectIndustries: Ember.computed('projectList.[]', function () {
      var projects = this.get('projectList') || [];
      return projects.mapBy('industry').mapBy('name').uniq().compact();
    }),

    projectProjectTypes: Ember.computed('projectList.[]', function () {
      var projects = this.get('projectList') || [];
      return projects.mapBy('projectType').mapBy('name').uniq().compact();
    }),

    filteredTagList: Ember.computed('uniqueProjectTagNames', 'projectIndustries', 'projectProjectTypes', 'selectedTags.[]', function () {
      var tags = [];
      if (this.get('projectProjectTypes.length')) {
        tags.push({ groupName: 'Project Types', options: this.get('projectProjectTypes') });
      }
      if (this.get('projectIndustries.length')) {
        tags.push({ groupName: 'Industries', options: this.get('projectIndustries') });
      }
      if (this.get('uniqueProjectTagNames.length')) {
        tags.push({ groupName: 'Tags', options: this.get('uniqueProjectTagNames') });
      }
      return tags;
    }),

    projectListFiltered: Ember.computed('projectList', 'selectedTags.[]', function () {
      var selectedTags = this.get('selectedTags');
      var filteredList = this.get('projectList');

      if (!selectedTags.length) {
        return filteredList;
      }

      var selectedProjectTags = _lodash.default.intersection(selectedTags, this.get("uniqueProjectTagNames"));
      var selectedIndustries = _lodash.default.intersection(selectedTags, this.get('projectIndustries'));
      var selectedProjectTypes = _lodash.default.intersection(selectedTags, this.get('projectProjectTypes'));

      return filteredList.filter(function (project) {
        var projectTags = project.get('tags').mapBy('name');
        var matchesTags = selectedProjectTags.every(function (tag) {
          return projectTags.includes(tag);
        });

        var industry = project.get('industry.name');
        var matchesIndustries = selectedIndustries.length == 0 || selectedIndustries.includes(industry);

        var projectType = project.get('projectType.name');
        var matchesProjectTypes = selectedProjectTypes.length == 0 || selectedProjectTypes.includes(projectType);

        return matchesTags && matchesIndustries && matchesProjectTypes;
      });
    }),
    pagedContent: (0, _pagedArray.default)('projectListFiltered', { infinite: 'unpaged', perPage: 20 }),

    thereAreHiddenTags: Ember.computed('lastVisibleTagIndex', 'projectTags.length', function () {
      return this.get('lastVisibleTagIndex') !== this.get('projectTags.length') - 1 && this.get('projectTags.length');
    }),

    countHiddenTags: Ember.observer('lastVisibleTagIndex', 'projectTags.length', function () {
      if (!this.get('thereAreHiddenTags')) {
        this.set('numberOfHiddenTags', 0);
      } else {
        var diff = this.get('projectTags.length') - this.get('lastVisibleTagIndex') - 1;
        this.set('numberOfHiddenTags', diff);
      }
    }),

    modifiedTagList: Ember.computed('lastVisibleTagIndex', 'projectTags.[]', function () {
      var lastVisibleTagIndex = this.get('lastVisibleTagIndex');
      var projectTags = this.get('projectTags').sortBy('name');

      if (lastVisibleTagIndex + 1 === projectTags.get('length')) {
        return projectTags;
      }

      return projectTags.toArray().slice(0, lastVisibleTagIndex + 1).sortBy('name');
    }),

    actions: {
      showAllTags: function showAllTags() {
        var numberOfTags = this.get('projectTags.length');

        this.set('lastVisibleTagIndex', numberOfTags - 1);
      },
      addTagToFilterTagsList: function addTagToFilterTagsList(tag) {
        var selectedTags = this.get('selectedTags');

        if (!selectedTags.includes(tag)) {
          selectedTags.pushObject(tag);
        }
      }
    }

  });
});