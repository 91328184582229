define('dashboard/components/cascading-input', ['exports', 'lodash/collection', 'dashboard/components/advanced-input', 'dashboard/utils/percent-increase'], function (exports, _collection2, _advancedInput, _percentIncrease) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _advancedInput.default.extend({

    firstIndex: 0,
    expanded: false,
    increaseDisabled: true,

    years: Ember.computed.reads('displayedColumns.firstObject.content.length'),

    enteredAdvanced: Ember.computed(function () {
      var column = this.get('singleColumn'),
          defaultSchedule = (0, _percentIncrease.default)(column.get('cellValues')[0], column.get('increase.value'), column.get('cellValues.length'));

      return _collection2.default.some(column.get('cellValues'), function (value, index) {
        return value !== defaultSchedule[index];
      });
    }),

    displayedColumns: Ember.computed('columns.[]', 'column', function () {
      return this.get('columns') || [this.get('column')];
    }),

    didInsertElement: function didInsertElement() {
      if (this.get('disabled')) {
        this.$('input').prop('disabled', this.get('disabled'));
      }

      // add bottom border radius to last inputs
      this.$('.multiple-table').each(function () {
        Ember.$(this).find('input').last().addClass('bottom-radius');
      });
    },

    increaseInputChanged: function increaseInputChanged(column) {
      if (!this.get('increaseDisabled')) {
        setTimeout(function () {
          this.$('.increase-disabled').focus();
          if (this.$('.increase-disabled')[0]) {
            this.$('.increase-disabled')[0].setSelectionRange(0, column.get('increase.value').toString().length);
          }
        }, 50);
      }
    },

    singleColumn: Ember.computed('column', function () {
      return this.get('column') || this.get('columns.firstObject');
    }),

    increasePercentType: Ember.computed('negativeAllowed', function () {
      return this.get('negativeAllowed') ? 'negativePercent' : 'percent';
    }),

    actions: {
      update: function update(column, rawValue, startingIndex) {
        var content = column.get('content'),
            increase = column.get('increase.value'),
            newValue;

        content[startingIndex].set('value', rawValue);
        for (var index = startingIndex; index < content.length - 1; index++) {
          newValue = parseFloat(content[index].get('value'));
          newValue = newValue * (1 + increase);
          content[index + 1].set('value', newValue);
        }

        this.sendAction('action', column); // probably 'updateColumn' action on scenario controller
      },

      updateIncrease: function updateIncrease(column /*, rawValue*/) {
        this.increaseInputChanged(column);
        var increaseValue = column.get('increase.value'),
            startingValue = column.get('content.0.value');

        if (typeof increaseValue === 'number') {
          this.sendAction('saveIncrease', column);
          this.set('increaseDisabled', true);
          this.send('update', column, startingValue, 0);
        }
      },

      toggleIncreaseDisabled: function toggleIncreaseDisabled() {
        this.set('increaseDisabled', false);
      },
      toggleExpanded: function toggleExpanded() {
        this.toggleProperty('expanded');
      }
    }
  });
});