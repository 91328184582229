define('dashboard/mixins/reset-scroll-position', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    renderTemplate: function renderTemplate() {
      var innerContent = document.getElementsByClassName('inner-content')[0];

      this._super.apply(this, arguments);
      innerContent.scrollTop = 0;
    }
  });
});