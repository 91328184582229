define('dashboard/objects/array-compute', ['exports', 'dashboard/utils/cumulative-sum', 'dashboard/utils/sum-arrays'], function (exports, _cumulativeSum, _sumArrays) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({

    total: Ember.computed('direct', 'indirect', function () {
      var _this = this;

      return this.get('direct').map(function (value, index) {
        return value + _this.get('indirect')[index];
      });
    }),

    directCumulative: Ember.computed('direct', function () {

      var direct = this.get('direct'),
          output = (0, _cumulativeSum.default)(direct);

      // this.set('directCumulativeOutput', output); // Is this being used anywhere?

      return output;
    }),

    indirectCumulative: Ember.computed('indirect', function () {

      var indirect = this.get('indirect');

      return (0, _cumulativeSum.default)(indirect);
    }),

    totalCumulative: Ember.computed('directCumulative', 'indirectCumulative', function () {

      var directCumulative = this.get('directCumulative'),
          indirectCumulative = this.get('indirectCumulative');

      return (0, _sumArrays.default)(directCumulative, indirectCumulative);
    }),

    firstDirect: Ember.computed.reads('direct.firstObject'),

    firstIndirect: Ember.computed.reads('indirect.firstObject'),

    firstTotal: Ember.computed.reads('total.firstObject'),

    lastDirectCumulative: Ember.computed.reads('directCumulative.lastObject'),

    lastIndirectCumulative: Ember.computed.reads('indirectCumulative.lastObject'),

    lastTotalCumulative: Ember.computed.reads('totalCumulative.lastObject'),

    lastDirect: Ember.computed.reads('direct.lastObject'),

    lastIndirect: Ember.computed.reads('indirect.lastObject'),

    lastTotal: Ember.computed.reads('total.lastObject')

  });
});