define('dashboard/objects/fi-tax/school-reduction', ['exports', 'dashboard/utils/sum-many-arrays', 'dashboard/utils/percent-each', 'dashboard/utils/array-of-length', 'dashboard/utils/float-map', 'dashboard/objects/fi-tax/base'], function (exports, _sumManyArrays, _percentEach, _arrayOfLength, _floatMap, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    costPerWorker: Ember.computed('propertyTaxesWorkers', 'percentPropertyTaxLost', 'doesApply', 'years', function () {
      var propertyTaxes = this.get('propertyTaxesWorkers'),
          percentPropertyTaxLost = (0, _percentEach.percentify)(this.get('percentPropertyTaxLost')),
          doesApply = this.get('doesApply'),
          years = this.get('years');

      if (!doesApply) {
        return (0, _floatMap.default)((0, _arrayOfLength.default)(years));
      }

      return (0, _percentEach.percentEach)(propertyTaxes, percentPropertyTaxLost * -1);
    }),

    costPerProject: Ember.computed('propertyTaxesProject', 'percentPropertyTaxLost', 'doesApply', 'years', function () {
      var propertyTaxes = this.get('propertyTaxesProject'),
          percentPropertyTaxLost = (0, _percentEach.percentify)(this.get('percentPropertyTaxLost')),
          summedTaxes = (0, _sumManyArrays.default)(propertyTaxes),
          doesApply = this.get('doesApply'),
          years = this.get('years');

      if (!doesApply) {
        return (0, _floatMap.default)((0, _arrayOfLength.default)(years));
      }

      return (0, _percentEach.percentEach)(summedTaxes, percentPropertyTaxLost * -1);
    }),

    workers: Ember.computed.sum('costPerWorker'),

    project: Ember.computed.sum('costPerProject')
  });
});