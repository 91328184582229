define('dashboard/controllers/demo', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    notify: Ember.inject.service(),
    session: Ember.inject.service(),

    actions: {
      submitDemo: function submitDemo() {
        var _getProperties = this.getProperties('firstName', 'lastName', 'organization', 'email'),
            firstName = _getProperties.firstName,
            lastName = _getProperties.lastName,
            organization = _getProperties.organization,
            email = _getProperties.email;

        var self = this,
            notify = this.get('notify');

        if (!firstName || !lastName || !organization || !email) {
          return notify.alert('Fields can not be blank');
        }

        this.get('session').authenticate('authenticator:demo', {
          firstName: firstName,
          lastName: lastName,
          organization: organization,
          email: email
        }).then(function () {
          self.transitionToRoute('authenticated');
        }, function () /* err */{
          notify.alert('Something went wrong...');
        });
      }
    }
  });
});