define('dashboard/controllers/reset', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    ajax: Ember.inject.service(),
    notify: Ember.inject.service(),
    session: Ember.inject.service(),

    actions: {
      resetPassword: function resetPassword() {
        var _this = this;

        var _getProperties = this.getProperties('password', 'confirmPassword', 'notify'),
            password = _getProperties.password,
            confirmPassword = _getProperties.confirmPassword,
            notify = _getProperties.notify;

        if (!password || !confirmPassword) {
          return notify.alert('Passwords can not be blank');
        }

        if (password !== confirmPassword) {
          return notify.alert('Passwords do not match');
        }

        if (!password.match('^\\S*$')) {
          return notify.alert('Passwords can not contain spaces');
        }

        var successMessage = this.get('model.user.activated') ? 'Password Reset' : 'Password Saved';
        var errorMessage = this.get('model.user.activated') ? 'Password Reset Failed' : 'Password Save Failed';

        this.get('ajax').put('resetPassword', {
          data: JSON.stringify({
            password: password,
            confirmPassword: confirmPassword,
            token: this.get('model.token')
          }),
          contentType: 'application/json'
        }).then(function (response) {
          if (!response.user) {
            return notify.alert('There was a problem saving your new password.');
          }

          // Eventually try to get notification to appear after transition
          notify.info(successMessage);
          Ember.run.later(_this, function () {
            // Login happens automatically with this authenticator
            _this.get('session').authenticate('authenticator:custom', {
              identification: response.user.email,
              password: password
            });
          }, 1000);
        }).catch(function (err) {
          console.log('error ', err);
          notify.alert(errorMessage);
        });
      }
    }

  });
});