define('dashboard/components/table-input', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    allowableRows: Ember.computed('rows.@each.if', function () {
      var _this = this;

      if (this.get('showAllOptions')) {
        return this.get('rows');
      } else {
        return this.get('rows') && this.get('rows').reject(function (r) {
          return r.if && !_this.get('requestTemplate.' + r.if);
        });
      }
    }),

    actions: {
      update: function update() {
        this.calculateTotal();
        this.save(this.value);
      }
    },
    sum: 0,
    calculateTotal: function calculateTotal() {
      var _this2 = this;

      if (this.get('showTotal')) {
        var keys = this.get("rows") || [];
        var total = 0;
        keys.forEach(function (key) {
          var isShowing = _this2.get('showAllOptions') || _this2.get('requestTemplate.' + key.name);
          var rowValue = isShowing ? _this2.get('value.' + key.dataProperty) : 0;
          rowValue = Array.isArray(rowValue) ? rowValue[0] : rowValue;
          if (rowValue) {
            total = total + rowValue;
          }
        });
        this.set('total', total);
      }
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.calculateTotal();
    }
  });
});