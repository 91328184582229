define('dashboard/adapters/application', ['exports', 'ember-data', 'ember-simple-auth/mixins/data-adapter-mixin', 'dashboard/config/environment', 'dashboard/utils/ajax-error-mapper'], function (exports, _emberData, _dataAdapterMixin, _environment, _ajaxErrorMapper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTAdapter.extend(_dataAdapterMixin.default, {
    host: _environment.default.APP.api_host,
    namespace: _environment.default.APP.api_namespace,
    normalizeErrorResponse: function normalizeErrorResponse(status, headers, payload) {
      // this will result in adapterError in catch clauses of save from ember data to have errors property containing an array of error messages
      return (0, _ajaxErrorMapper.default)(payload);
    },
    authorize: function authorize(xhr) {
      var _get = this.get('session.data.authenticated'),
          token = _get.token;

      xhr.setRequestHeader('token', token);
    }
  });
});