define('dashboard/fixtures/taxable-inventories-label', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    long: 'Taxable Inventories',
    short: 'Inventories',
    longSingular: 'Taxable Inventory',
    shortSingular: 'Inventory',
    lineItem: 'Inventory Property Taxes'
  };
});