define('dashboard/components/data-request-card', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    tabSelected: 'summary',
    isConfirmSubmittModalHidden: true,
    isLinkSentModalHidden: true,
    isRequirementsMessageModalHidden: true,
    isThankyouModalHidden: true,
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),

    rawDataSections: Ember.computed('sectionsToDisplay.[]', function () {
      return this.get('sectionsToDisplay').filter(function (s) {
        return s.division !== 'welcome' && s.division !== 'review' && s.sectionBoolean !== 'expansionPlans';
      });
    }),

    lastStep: Ember.computed('sectionsToDisplay.[]', 'currentSectionIndex', function () {
      return this.get('sectionsToDisplay.length') - 1 <= this.get('currentSectionIndex');
    }),

    firstStep: Ember.computed.equal('currentSectionIndex', 0),

    nextButtonDisabled: Ember.computed('currentSection.navbarTitle', 'disabledBecauseAlreadySubmitted', function () {
      var blockedBySubmitted = this.get('disabledBecauseAlreadySubmitted') && this.get('currentSection.sectionBoolean') == 'reviewAndSubmit';
      return blockedBySubmitted;
    }),

    disabledWithValidation: Ember.computed('currentSection.{mustBeValidToContinue,navbarTitle}', 'validationObject.isValid', function () {
      var blockedByValiditation = this.get('currentSection.mustBeValidToContinue') && !this.get('validationObject.isValid');
      return blockedByValiditation;
    }),

    disabledForAcknowledgement: Ember.computed('currentSection.sectionBoolean', 'requestTemplate.acknowledgement', 'scenario.requestAcknowledgementChecked', function () {
      var blockedByAcknowledgement = this.get('currentSection.sectionBoolean') === 'welcome' && this.get('requestTemplate.acknowledgement') && !this.get('scenario.requestAcknowledgementChecked');
      return blockedByAcknowledgement;
    }),

    validationObject: Ember.computed('currentSection', 'scenario.validatedSections', function () {
      return this.get('scenario.validatedSections.' + this.get('currentSection.sectionBoolean'));
    }),

    validationText: 'Please make sure to acknowledge the statement by checking the box. This is required to continue.',

    fillPrefillProperties: function fillPrefillProperties(dataRequest) {
      this.get('currentSection.content').forEach(function (question) {
        var prefillProperty = question.prefillProperty;
        if (prefillProperty && !dataRequest.get(prefillProperty)) {
          var value = dataRequest.get(question.dataProperty);
          dataRequest.set(prefillProperty, value);
        }
      });
    },


    bestGuessContact: Ember.computed.or('scenario.contact.contactInfo', 'requestTemplate.contactInfo'),

    actions: {
      previousStep: function previousStep() {
        this.set('goingForward', false);
        this.decrementProperty('currentSectionIndex');
      },
      nextStep: function nextStep() {
        this.set('goingForward', true);
        this.incrementProperty('currentSectionIndex');
      },
      previousAndUpdateMultipliers: function previousAndUpdateMultipliers() {
        this.get('scenario').prefillMultipliers();
        this.send('previousStep');
      },
      nextAndUpdateMultipliers: function nextAndUpdateMultipliers() {
        this.get('scenario').prefillMultipliers();
        this.send('nextStep');
      },
      showErrorMessage: function showErrorMessage(type) {
        if (type === 'email') {
          this.set('validationText', 'Please complete all fields and be sure to enter a valid email address.');
        } else if (type === 'acknowledgement') {
          this.set('validationText', 'Please make sure to acknowledge the statement by checking the box. This is required to continue.');
        }
        this.set('isRequirementsMessageModalHidden', false);
      },
      submitDataForm: function submitDataForm() {
        var _this = this;

        var dataRequest = this.get('scenario');
        var self = this;

        return new Ember.RSVP.Promise(function (resolve) {

          dataRequest.get('assumption').then(function (assumption) {
            // if we're not revising a submitted data form, set the scenario length to the template's length
            // This keeps lengths in sync in case template length was changed after the data form was originally started
            var revising = dataRequest.get('revisionOfScenario') && dataRequest.get('revisionOfScenario.content');
            // if data form length was manually changed by user, don't sync length to template length
            var dataFormLengthChanged = dataRequest.get('dataFormLengthChanged');
            if (!revising && !dataFormLengthChanged) {
              assumption.set('scenarioLength', _this.get('requestTemplate.dataFormLength'));
            }
            return assumption.save();
          }).then(function () /*assumption*/{
            // copy request template
            return _this.get('requestTemplate.content').copy(true);
          }).then(function (copy) {
            // lock copy of template to scenario
            copy.set('lockedToScenario', true);
            return copy.save();
          }).then(function (copy) {
            // updated dataRequest submitted properties
            dataRequest.set('submittedOn', new Date());
            dataRequest.set('requestTemplate', copy);
            return dataRequest.save();
          }).then(function (dataRequest) {
            // copy this data request into a completely new model just to save a snapshot for internal records
            return _copyDataRequest(dataRequest, self.get('store'));
          }).then(function () /*copiedDataRequest*/{
            // send emails and other actions on api
            _this.get('ajax').post('/scenario/' + dataRequest.id + '/notifySubmitted').then(function () {
              resolve(dataRequest);
            });
          });

          function _copyDataRequest(oldScenario, store) {
            var newScenario = oldScenario.toJSON();

            for (var key in newScenario) {
              if (newScenario[key] !== oldScenario.get(key)) {
                newScenario[key] = oldScenario.get(key);
              }
            }

            // clear out any scenario properties that might make this snapshot scenario show up in any other scenario query
            newScenario.id = null;
            delete newScenario.districts;
            delete newScenario.assumption;
            delete newScenario.client;
            newScenario.created = new Date();
            delete newScenario.finalized;
            delete newScenario.isAdminUnlocked;
            delete newScenario.isRequest;

            newScenario.isRequestBackupOf = oldScenario.get('id');

            return store.createRecord('scenario', newScenario).save();
          }
        });
      },
      submit: function submit() {
        if (this.get('isPreview')) {
          return;
        }

        var dataRequest = this.get('scenario');

        this.get('requestTemplate.content').copy(true).then(function (copy) {
          copy.set('lockedToScenario', true);
          return copy.save();
        }).then(function (copy) {
          dataRequest.set('submittedOn', new Date());
          dataRequest.set('requestTemplate', copy);
          return dataRequest.save();
        });
      },
      sendLinkEmail: function sendLinkEmail() {
        var _this2 = this;

        var dataRequest = this.get('scenario');

        this.fillPrefillProperties(dataRequest);

        if (dataRequest.get('isLinkEmailSent') || this.get('isPreview')) {
          this.send('nextStep');
        } else {
          dataRequest.set('startedOn', new Date());
          dataRequest.save();

          this.get('ajax').post('/scenario/' + dataRequest.id + '/sendLink').then(function () {
            _this2.send('nextStep');
            _this2.set('isLinkSentModalHidden', false);
          });
        }
      },
      openSubmitModal: function openSubmitModal() {
        window.scrollTo(0, 0);
        this.set('isConfirmSubmittModalHidden', false);
      },
      showThanksModal: function showThanksModal() {
        this.set('isThankyouModalHidden', false);
      }
    }
  });
});