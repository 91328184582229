define('dashboard/components/form-select', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['input-component'],
    classNameBindings: ['labelSize', 'inputSize'],

    init: function init() {
      this._super.apply(this, arguments);
      this.set('options', []);
    },


    computedId: Ember.computed(function () {
      return this.elementId + 'select';
    }),

    hasSelected: Ember.observer('inputValue', function () {
      this.sendAction();
    }),

    processedOptions: Ember.computed('options', function () {

      var options = this.get('options'),
          defaultSelect = this.get('defaultSelect'),
          labelPath = this.get('labelPath'),
          valuePath = this.get('valuePath'),
          defaultOption = Ember.Object.create(),
          output = [];

      if (defaultSelect) {
        defaultOption.set(labelPath, defaultSelect);
        defaultOption.set(valuePath, '0');

        output.pushObject(defaultOption);

        options.forEach(function (option) {

          output.pushObject(option);
        });

        return output;
      }

      return options;
    }),

    optionValuePath: Ember.computed('valuePath', function () {
      return this.get('valuePath') ? 'content.' + this.get('valuePath') : 'content';
    }),

    optionLabelPath: Ember.computed('labelPath', function () {
      return this.get('labelPath') ? 'content.' + this.get('labelPath') : 'content';
    })

  });
});