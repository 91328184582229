define('dashboard/components/radio-button-custom', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    // custom: true,
    // customValue: 0,
    // isCustomSelected: false,

    // didInsertElement: function() {
    //   var selectedValue = this.get('selectedValue'),
    //       $input;

    //   if (!selectedValue) {
    //     this.$('input:first').click();
    //   } else {
    //     $input = this.$('input[value="' + selectedValue + '"]');

    //     if ($input.length === 0) {
    //       this.set('isCustomSelected', true);
    //       this.set('customValue', selectedValue);
    //       this.$('.custom').click();
    //     } else {
    //       $input.click();
    //     }

    //   }
    // },

    // click: function() {
    //   let checkedValue = this.$(':checked').val();
    //   console.log('checkedValue', checkedValue);

    //   if (!Ember.isEmpty(checkedValue)) {
    //     this.set('selectedValue', checkedValue);
    //     this.set("isCustomSelected", this.$(":checked").hasClass("custom"));
    //     this.notifyPropertyChange("isCustomSelected");
    //     Ember.run.later(() => {
    //       this.sendAction();
    //     }, 100);
    //   }
    // },

    actions: {
      // updateCustomValue() {
      //   this.$('.custom').click();
      // },

      select: function select() /*value*/{
        this.sendAction();
      }
    }

  });
});