define('dashboard/components/dynamic-table-rows', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    dynamicRows: Ember.computed('requestTemplate.title', 'dynamicRowsProperty', function () {
      var property = this.get('dynamicRowsProperty');
      var rows = this.get('requestTemplate.' + property) || this.get(property);
      return rows && rows.map(function (row) {
        return {
          dataProperty: row,
          label: row
        };
      });
    })
  });
});