define('dashboard/components/file-uploader', ['exports', 'dashboard/config/environment', 'dashboard/mixins/sorting'], function (exports, _environment, _sorting) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_sorting.default, {
    isDeleteModalHidden: true,
    polymorphicType: 'scenario', //can be project, user, etc.

    buttonSize: 'xs',

    canModifyFiles: true,

    session: Ember.inject.service(),
    store: Ember.inject.service(),
    notify: Ember.inject.service(),
    ajax: Ember.inject.service(),
    fileQueue: Ember.inject.service(),

    sortable: true,
    init: function init() {
      this._super.apply(this, arguments);
      this.setProperties({
        sortColumns: [{
          title: 'File Name',
          sortField: 'displayName'
        }, {
          title: 'Modified',
          sortField: 'created',
          textAlign: 'right',
          width: '30%'
        }]
      });
    },

    sortField: 'created',
    sortDir: 'desc',
    sortedList: Ember.computed.sort('files', 'sortedValue'),
    files: Ember.computed('polymorphicObject', 'attachmentPath', function () {
      return this.get('polymorphicObject.' + this.get('attachmentPath'));
    }),

    buttonSizeClass: Ember.computed('buttonSize', function () {
      var buttonSize = this.get('buttonSize');
      if (buttonSize) return 'btn-' + buttonSize;
      return '';
    }),

    hasFiles: Ember.computed.or('files.length', 'fileQueue.files.length'),
    disabled: Ember.computed.alias('readOnlyAccess'),

    _addFileUploadToStore: function _addFileUploadToStore(res) {
      this.get('store').pushPayload('file-upload', res.body);
      return this.get('store').peekRecord('file-upload', res.body.fileUpload.id);
    },

    // Assign the new file-upload model to the specified fileOwner
    _assignFileUploadToModel: function _assignFileUploadToModel(fileModel) {
      var _getProperties = this.getProperties('polymorphicObject'),
          model = _getProperties.polymorphicObject;

      model.get('fileUploads').pushObject(fileModel);
      return model.save().then(function () {
        return fileModel;
      });
    },

    // Display notification that file upload succeeded
    _notifyFileUploadSuccess: function _notifyFileUploadSuccess(fileModel) {
      this.get('notify').info('File ' + fileModel.get('displayName') + ' uploaded successfully');
    },

    // Display notification that file upload failed
    _notifyFileUploadError: function _notifyFileUploadError(errors) {
      var errorMessage = Ember.get(errors, 'body.error') ? Ember.get(errors, 'body.error') : 'File upload could not be completed.';

      var error = Ember.get(errors, 'errors') ? Ember.get(errors, 'errors')[0] : errors;
      console.log('error:', error);
      this.get('notify').alert(errorMessage);
    },

    // Cleanup file queue after upload is finished (either succeeds or fails)
    _uploadCleanup: function _uploadCleanup(file) {
      this.get('fileQueue').remove(file);
    },

    actions: {
      uploadFile: function uploadFile(file) {
        var _this = this;

        var model = this.get('polymorphicObject');
        var polymorphicType = this.get('polymorphicType');
        var polymorphicId = model.get('id');

        var _get = this.get('session.data.authenticated'),
            token = _get.token;

        var headers = { token: token };
        var data = { polymorphicId: polymorphicId, polymorphicType: polymorphicType };

        file.upload(_environment.default.APP.api_host + '/api/fileUploads', { headers: headers, data: data }).then(function (res) {
          return _this._addFileUploadToStore(res);
        }).then(function (fileModel) {
          return _this._assignFileUploadToModel(fileModel);
        }).then(function (fileModel) {
          return _this._notifyFileUploadSuccess(fileModel);
        }).catch(function (errors) {
          return _this._notifyFileUploadError(errors);
        }).finally(function () {
          return _this._uploadCleanup(file);
        });
      },
      showDelete: function showDelete(upload) {
        this.set('selectedUpload', upload);
        this.set('isDeleteModalHidden', false);
      },
      deleteUpload: function deleteUpload() {
        var _this2 = this;

        var upload = this.get('selectedUpload');
        var polymorphicObject = this.get('polymorphicObject');
        polymorphicObject.get(this.get('attachmentPath')).removeObject(upload); // so deletion is reflected in the list; actual removal from array is in upload destroyRecord call
        upload.destroyRecord().then(function () {
          _this2.get('notify').info('File deleted.');
          _this2.set('isDeleteModalHidden', true);
        });
      },
      preview: function preview(fileUpload) {
        var opts = { contentType: 'application/json; charset=utf-8' };

        this.get('ajax').request('fileUploads/' + fileUpload.get('id') + '/getFile', opts).then(function (res) {
          return window.open(res.signed_request);
        });
      }
    }
  });
});