define("dashboard/utils/multiply-each", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  /**
   * Multiply Each
   *
   * @param  {Array} inputArray Array of values
   * @param  {Number} multiplier Value to multiply
   * @return {Array}            Original array multipled by the multiplier
   *
   * @method  multiplyEach
   */
  var multiplyEach = function multiplyEach(inputArray, multiplier) {

    var reducedMultiplier = reduceMultiplier(multiplier);

    return inputArray.map(function (value) {
      return value * reducedMultiplier;
    });
  };

  var reduceMultiplier = function reduceMultiplier(multiplier) {

    if (multiplier instanceof Array) {

      return multiplier.reduce(function (result, num) {
        return result * num;
      }, 1);
    } else {
      return multiplier;
    }
  };

  exports.default = multiplyEach;
});