define("dashboard/utils/depreciation-schedule", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  /**
   * Depreciation Schedule
   *
   * @param  {Array} array      Array of values
   * @param  {Array} adjustment Array of depreciating adjustments in percent
   * @return {Array}            Original array depreciated by adjustment schedule
   *
   * @method  depreciationSchedule
   */
  var depreciationSchedule = function depreciationSchedule(array, adjustment) {

    var schedule = _scheduler(array, adjustment);

    return array.map(function (value, index, array) {

      var year = index;

      value = parseFloat(value);

      if (value > 0) {
        value = parseFloat(array[year]) * parseFloat(schedule[index - year]);
      }

      while (year > 0) {
        value += parseFloat(array[year - 1]) * parseFloat(schedule[index - year + 1]);
        year -= 1;
      }

      return value;
    });
  };

  /**
   * Scheduler
   * (INTERNAL)
   *
   * Creates an adjustment schedule by ensuring that the schedule
   * array is the same length as the input array. If the adjustment
   * array is smaller than the input array, then the schedule is padded
   * with the last value in the adjustment array.
   *
   * @param  {Array} array      Array of values
   * @param  {Array} adjustment Array of depreciating adjustments in percent
   * @return {Array}            Depreciation schedule
   */
  var _scheduler = function _scheduler(array, adjustment) {

    var inputDimension, adjustmentDimension, last, count, index;

    inputDimension = array.length;
    adjustmentDimension = adjustment.length;

    adjustment = adjustment.map(function (item) {
      return parseFloat(item);
    });

    if (adjustmentDimension < inputDimension) {

      last = adjustment[adjustmentDimension - 1];
      count = inputDimension - adjustmentDimension;

      for (index = 0; index < count; index++) {
        adjustment.push(last);
      }
    }

    return adjustment;
  };

  exports.default = depreciationSchedule;
});