define('dashboard/components/public-support-overview-table', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    // Only include community total if it is not allocated to districts
    communityTotal: Ember.computed('communityNoAllocation', 'communityIncentiveTotal', function () {
      if (this.get('communityNoAllocation') && this.get('communityIncentiveTotal')) {
        return this.get('communityIncentiveTotal');
      }

      return 0;
    }),

    anyIncentives: Ember.computed.or('totals.incentives', 'communityIncentiveTotal'),

    incentivesTotal: Ember.computed('totals.incentives', 'communityTotal', function () {
      return this.get('totals.incentives') + this.get('communityTotal');
    }),

    grandTotal: Ember.computed('totals.total', 'communityTotal', function () {
      return this.get('totals.total') + this.get('communityTotal');
    })

  });
});