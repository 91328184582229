define('dashboard/routes/data-requests/edit', ['exports', 'ember-local-storage', 'dashboard/utils/copy-data-request'], function (exports, _emberLocalStorage, _copyDataRequest) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    queryParams: {
      revising: {
        refreshModel: true
      }
    },

    title: 'Data Request Wizard | Impact DashBoard',

    dataRequests: (0, _emberLocalStorage.storageFor)('data-requests'),

    model: function model(params) {
      return this.store.queryRecord('scenario', { id: params.id, token: params.token, singleRecord: true });
    },
    afterModel: function afterModel(model, transition) {
      var _this = this;

      var scenario = model;

      if (transition.queryParams.revising) {
        // make a copy of the above scenario
        (0, _copyDataRequest.default)(scenario, this.store).then(function (newDataRequest) {
          _this.transitionToDataRequest(newDataRequest);
        });
      }
    },
    transitionToDataRequest: function transitionToDataRequest(dataRequest) {
      var queryParams = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      this.transitionTo('data-requests.edit', dataRequest.get('id'), dataRequest.get('token'), { queryParams: queryParams });
    },


    actions: {
      error: function error(_error) {
        var errors = _error.errors || [];
        if (errors[0] === "Scenario not found") {
          this.transitionTo('data-requests.invalid');
        }
      }
    }

  });
});