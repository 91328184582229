define('dashboard/objects/ei-salaries', ['exports', 'dashboard/objects/array-compute', 'dashboard/utils/multiply-each'], function (exports, _arrayCompute, _multiplyEach) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var EiSalariesObject = _arrayCompute.default.extend({

    direct: Ember.computed('jobs', 'salaries', function () {

      var jobs = this.get('jobs'),
          salaries = this.get('salaries');

      return jobs.map(function (job, index) {
        return job * (salaries[index] || 0);
      });
    }),

    indirect: Ember.computed('direct', 'earningsMultiplier', function () {

      var direct = this.get('direct'),
          adjustedEarningsMultiplier = this.get('earningsMultiplier') || 1;

      return (0, _multiplyEach.default)(direct, adjustedEarningsMultiplier - 1);
    })

  });

  exports.default = EiSalariesObject;
});