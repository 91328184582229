define('dashboard/objects/abatement-schedule-land', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var abatementScheduleLand = Ember.Object.extend({

    output: Ember.computed('input', 'doesApplyAbatement', function () {
      var input = this.get('input') || [];
      return input;
    })

  });

  exports.default = abatementScheduleLand;
});