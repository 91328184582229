define('dashboard/routes/projects/lost', ['exports', 'dashboard/mixins/projects-page'], function (exports, _projectsPage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_projectsPage.default, {
    titleToken: 'Lost Projects',

    afterModel: function afterModel() {
      this.setProjectBucket('lost');
    }
  });
});