define('dashboard/helpers/object-breakdown', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.objectBreakdown = objectBreakdown;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function objectBreakdown(_ref) /*, hash*/{
    var _ref2 = _slicedToArray(_ref, 1),
        data = _ref2[0];

    var keys = Object.keys(data);
    var keyValueArray = keys.map(function (key) {
      var value = data[key];
      if (value > 0) {
        return { key: key, value: value };
      }
    }).filter(function (x) {
      return x;
    });

    var values = keyValueArray.map(function (i) {
      return i.value;
    });

    return {
      keys: keyValueArray.map(function (i) {
        return i.key;
      }),
      values: values,
      totalValue: _lodash.default.sum(values)
    };
  }

  exports.default = Ember.Helper.helper(objectBreakdown);
});