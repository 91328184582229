define('dashboard/components/simple-select-option', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Component.extend({

    tagName: '',

    computedValue: Ember.computed('option', 'optionValuePath', function () {
      var optionValuePath = this.get('optionValuePath');
      var option = this.get('option');

      // Default to id if option is object
      if (!optionValuePath && (typeof option === 'undefined' ? 'undefined' : _typeof(option)) === 'object') {
        optionValuePath = 'id';
      }

      if (optionValuePath) {
        return Ember.get(option, optionValuePath);
      }

      return this.get('option');
    }),

    computedLabel: Ember.computed('option', 'optionLabelPath', function () {
      var optionLabelPath = this.get('optionLabelPath');
      var option = this.get('option');

      // Default to name if option is object
      if (!optionLabelPath && (typeof option === 'undefined' ? 'undefined' : _typeof(option)) === 'object') {
        optionLabelPath = 'name';
      }

      if (optionLabelPath) {
        return Ember.get(option, optionLabelPath);
      }

      return this.get('option');
    })

  });
});