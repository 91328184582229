define('dashboard/components/modals/district-upload-status-modal', ['exports', 'dashboard/components/modals/modal-confirm'], function (exports, _modalConfirm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _modalConfirm.default.extend({

    uploadedCount: Ember.computed.readOnly('uploadedTaxDistricts.length'),
    oneUploaded: Ember.computed.equal('uploadedCount', 1),

    properlyPluralDistrict: Ember.computed('oneUploaded', function () {
      return this.get('oneUploaded') ? 'district' : 'districts';
    })

  });
});