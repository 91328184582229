define('dashboard/components/switch/case-statement', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: ''
  }).reopenClass({
    positionalParams: 'acceptableValues'
  });
});