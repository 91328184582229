define('dashboard/routes/scenario/public-support/index', ['exports', 'dashboard/mixins/reset-scroll-position'], function (exports, _resetScrollPosition) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_resetScrollPosition.default, {
    redirect: function redirect(model) {

      // if there are no schedules available and no possibility for tif incentives, go to error
      if (!model.get('activeSchedules.length') && !model.get('usesTifDistrict')) {
        return this.transitionTo('scenario.public-support.error');
      }

      var firstGroup = model.get('activeSchedules.firstObject.id'),
          lastVisitedGroup = this.controllerFor('scenario').get('lastVisitedGroup'),
          hasPublicSupport = this.controllerFor('scenario').get('reportHasPublicSupport');

      // if we are using tif incentives but have no other schedules
      if (!firstGroup && model.get('tifIncentive') && model.get('usesTifDistrict')) {
        firstGroup = model.get('tifIncentive');
      }

      if ((!lastVisitedGroup || lastVisitedGroup === 'overview') && !hasPublicSupport) {
        return this.transitionTo('scenario.public-support.group', firstGroup);
      }

      if ((!lastVisitedGroup || lastVisitedGroup === 'overview') && hasPublicSupport) {
        return this.transitionTo('scenario.public-support.overview');
      }

      if (lastVisitedGroup && lastVisitedGroup.get('isActive')) {
        lastVisitedGroup = lastVisitedGroup.get('id');
      } else {
        lastVisitedGroup = null;
      }
      this.transitionTo('scenario.public-support.group', lastVisitedGroup || firstGroup);
    }
  });
});