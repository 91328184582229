define('dashboard/mixins/s3', ['exports', 'ember-file-upload/file', 'dashboard/config/environment'], function (exports, _file, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    notify: Ember.inject.service(),
    session: Ember.inject.service(),
    uploader: Ember.inject.service('fileQueue'),

    init: function init() {
      this._super.apply(this, arguments);
      this._setupFileQueue('s3');
    },


    // Find or create the a file queue in the uploader service,
    // since queue names must be unique
    _setupFileQueue: function _setupFileQueue(name) {
      var uploader = this.get('uploader');
      var fileQueue = uploader.find(name) || uploader.create(name);

      this.set('fileQueue', fileQueue);
    },

    _addFileUploadToStore: function _addFileUploadToStore(res) {
      this.get('store').pushPayload('file-upload', res.body);
      return this.get('store').peekRecord('file-upload', res.body.fileUpload.id);
    },

    // Assign the new file-upload model to the specified fileOwner
    _assignFileUploadToModel: function _assignFileUploadToModel(fileModel) {
      var _getProperties = this.getProperties('fileOwner', 'fileProperty'),
          fileOwner = _getProperties.fileOwner,
          fileProperty = _getProperties.fileProperty;

      fileOwner.set(fileProperty, fileModel);
      return fileOwner.save().then(function () {
        return fileModel;
      });
    },

    // Display notification that file upload succeeded
    _notifyFileUploadSuccess: function _notifyFileUploadSuccess(fileModel) {
      this.get('notify').info('File ' + fileModel.get('displayName') + ' uploaded successfully');
    },

    // Display notification that file upload failed
    _notifyFileUploadError: function _notifyFileUploadError(errors) {
      var errorMessage = Ember.get(errors, 'body.error') ? Ember.get(errors, 'body.error') : 'File upload could not be completed.';

      var error = Ember.get(errors, 'errors') ? Ember.get(errors, 'errors')[0] : errors;
      console.log('error:', error);
      this.get('notify').alert(errorMessage);
    },

    // Cleanup file queue after upload is finished (either succeeds or fails)
    _uploadCleanup: function _uploadCleanup(file) {
      this.get('fileQueue').remove(file);
    },

    actions: {
      // Add selected files to the file queue to prepare for upload
      handleSelect: function handleSelect(e) {
        var fileQueue = this.get('fileQueue');
        Array.from(e.target.files).forEach(function (file) {
          return fileQueue.push(_file.default.fromBlob(file));
        });

        this.send('uploadFiles');
      },


      // Start upload on each files in fileQueue
      uploadFiles: function uploadFiles() {
        var _this = this;

        this.get('fileQueue.files').forEach(function (file) {
          return _this.send('_uploadFile', file);
        });
      },


      // Handle process for uploading a single file
      _uploadFile: function _uploadFile(file) {
        var _this2 = this;

        var _file$blob = file.blob,
            displayName = _file$blob.name,
            size = _file$blob.size,
            fileType = _file$blob.type;

        var model = this.get('fileOwner');
        var polymorphicId = model.get('id');
        var polymorphicType = model.constructor.modelName;

        var _get = this.get('session.data.authenticated'),
            token = _get.token;

        var headers = { token: token };
        var data = { polymorphicId: polymorphicId, polymorphicType: polymorphicType, displayName: displayName, size: size, fileType: fileType };

        file.upload(_environment.default.APP.api_host + '/api/fileUploads', { headers: headers, data: data }).then(function (res) {
          return _this2._addFileUploadToStore(res);
        }).then(function (fileModel) {
          return _this2._assignFileUploadToModel(fileModel);
        }).then(function (fileModel) {
          return _this2._notifyFileUploadSuccess(fileModel);
        }).catch(function (errors) {
          return _this2._notifyFileUploadError(errors);
        }).finally(function () {
          return _this2._uploadCleanup(file);
        });
      }
    }
  });
});