define('dashboard/utils/create-new-request', ['exports', 'dashboard/utils/copy-assumption'], function (exports, _copyAssumption) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var createNewRequest = function createNewRequest(assumption, client, requestTemplate, store) {

    return (0, _copyAssumption.default)(assumption, store).then(function (assumption) {
      var newScenario = store.createRecord('scenario', {
        client: client,
        assumption: assumption,
        requestTemplate: requestTemplate,
        fromAnonymousLink: true,
        isRequest: true,
        contact: requestTemplate.get('contact')
      });

      return Ember.RSVP.hash({
        scenario: newScenario.save(),
        assumption: assumption
      });
    }).then(function (_ref) {
      var scenario = _ref.scenario,
          assumption = _ref.assumption;

      assumption.set('scenario', scenario);
      assumption.set('scenarioLength', requestTemplate.get('dataFormLength') || '10');
      assumption.save();
      return scenario;
    });
  };

  exports.default = createNewRequest;
});