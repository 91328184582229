define('dashboard/mixins/tif-district-actions', ['exports', 'lodash/object'], function (exports, _object2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({

    notify: Ember.inject.service(),

    creatingTifHidden: true,
    deletingTifHidden: true,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('tifActionOptions', {});
      this.set('tifDistrictsSort', ['name']);
      this.set('tifInputTypeOptions', [Ember.Object.create({ value: false, name: 'Percent of Tax Rate' }), Ember.Object.create({ value: true, name: 'Amount of Tax Rate' })]);
    },


    nameInputDisabled: Ember.computed.alias('tifInputsDisabled'),

    tifDistrictAction: 'createTifDistrict',

    tifDistrictsPage: Ember.computed.equal('target.currentRouteName', 'scenario.settings.tif-districts'),

    filteredTifDistricts: Ember.computed.filterBy('tifDistricts', 'deleted', false),

    sortedTifDistricts: Ember.computed.sort('filteredTifDistricts', 'tifDistrictsSort'),

    scheduleMaskType: Ember.computed('selectedTifDistrict.enterAsDecimal', function () {
      return this.get('selectedTifDistrict.enterAsDecimal') ? 'longNumeral' : 'percent';
    }),

    sortedSchedules: Ember.computed('selectedTifDistrict.{sortedSchedules,enterAsDecimal}', function () {
      var selectedTifDistrict = this.get('selectedTifDistrict');

      if (!selectedTifDistrict) {
        return this.get('selectedTifDistrict.sortedSchedules'); // probably null but that's ok
      }

      var enterAsDecimal = selectedTifDistrict.get('enterAsDecimal');
      return selectedTifDistrict.get('sortedSchedules').map(function (schedule) {
        return Ember.Object.create({
          schedule: schedule,
          maxLand: enterAsDecimal ? Infinity : '1',
          maxBuildings: enterAsDecimal ? Infinity : '1',
          maxFFE: enterAsDecimal ? Infinity : '1',
          maxInventories: enterAsDecimal ? Infinity : '1',
          maxTaxableSales: enterAsDecimal ? Infinity : '1'
        });
      });
    }),

    anyDistrictsApplyProperty: Ember.computed(function () {
      return Ember.Object.create({
        land: true,
        buildings: true,
        furniture: true,
        inventories: true,
        taxableSales: true
      });
    }),

    scheduleInputs: Ember.computed('selectedTifDistrict.enterAsDecimal', function () {

      var enterAsDecimal = this.get('selectedTifDistrict.enterAsDecimal');

      return Ember.Object.create({
        land: enterAsDecimal ? 'landContributedDecimal' : 'landContributed',
        buildings: enterAsDecimal ? 'buildingsContributedDecimal' : 'buildingsContributed',
        furniture: enterAsDecimal ? 'ffeContributedDecimal' : 'ffeContributed',
        inventories: enterAsDecimal ? 'inventoriesContributedDecimal' : 'inventoriesContributed',
        taxableSales: enterAsDecimal ? 'taxableSalesContributedDecimal' : 'taxableSalesContributed'
      });
    }),

    selectedTifDistrictInputMethod: Ember.computed('selectedTifDistrict.enterAsDecimal', function () {
      return this.get('tifInputTypeOptions').findBy('value', this.get('selectedTifDistrict.enterAsDecimal'));
    }),

    actions: {
      selectTifDistrict: function selectTifDistrict(selectedTifDistrictId) {
        var tifDistrict = this.get('filteredTifDistricts').findBy('id', selectedTifDistrictId);
        this.set('selectedTifDistrict', tifDistrict);
      },
      saveTifDistrict: function saveTifDistrict() {
        console.log('saveTifDistrict BAD');
        this.get('selectedTifDistrict').save();
      },
      saveTifSchedule: function saveTifSchedule(schedule) {
        if (this.get('tifInputsDisabled')) {
          return;
        }

        schedule.save();
      },
      updateTifDistrict: function updateTifDistrict() {
        var tifDistrict = this.get('selectedTifDistrict'),
            tifProfiles = this.get('filteredTifDistricts').without(this.get('selectedTifDistrict')),
            // exclude selectedTifDistrict when checking uniqueness
        name = tifDistrict.get('name').trim(),
            error = void 0;

        if (this.get('readOnlyAccess') || this.get('tifInputsDisabled')) {
          return Ember.RSVP.resolve({});
        }

        if (!name) {
          error = 'TIF Profile must have a name';
        }

        if (tifProfiles.findBy('name', name)) {
          error = 'TIF Profile name must be unique';
        }

        if (error) {
          tifDistrict.set('name', tifDistrict.changedAttributes().name[0]);
          this.get('notify').alert(error);
          return Ember.RSVP.resolve({ error: error });
        }

        return this.get('selectedTifDistrict').save();
      },
      selectTifDistrictInputMethod: function selectTifDistrictInputMethod(inputMethod) {
        this.set('selectedTifDistrict.enterAsDecimal', inputMethod.get('value'));
        this.send('saveTifDistrict');
      },
      openCreateTifModal: function openCreateTifModal() {
        if (this.get('readOnlyAccess')) {
          return this.get('notify').alert('You currently have read only access.');
        }

        this.setProperties({
          tifActionTitle: 'Create TIF District Profile',
          tifDistrictAction: 'createTifDistrict',
          creatingTifHidden: false,
          tifToCopy: null
        });
      },
      openCopyTifModal: function openCopyTifModal(tifDistrict) {
        if (this.get('readOnlyAccess')) {
          return this.get('notify').alert('You currently have read only access.');
        }

        this.setProperties({
          tifActionTitle: 'Copy TIF District',
          tifDistrictAction: 'copyTifDistrict',
          creatingTifHidden: false,
          tifToCopy: tifDistrict
        });
      },
      openDeleteTifModal: function openDeleteTifModal(tifDistrict) {
        if (this.get('readOnlyAccess')) {
          return this.get('notify').alert('You currently have read only access.');
        }

        this.set('deletingTifHidden', false);
        if (tifDistrict) {
          this.set('tifToDelete', tifDistrict);
        }
      },
      openEditTifDistrictModal: function openEditTifDistrictModal(tif) {
        this.setProperties({
          selectedTifDistrict: tif,
          editingTifModalHidden: false
        });
      },
      createTifDistrict: function createTifDistrict(name) {
        var _this = this;

        var client = this.get('client'),
            groups = client.get('districtGroups'),
            self = this;

        function createTifSchedules() {
          var tifSchedules = groups.map(function (group) {
            return self.store.createRecord('tifSchedule', { group: group }).save();
          });

          return Ember.RSVP.all(tifSchedules);
        }

        function createTifDistrict(tifSchedules) {
          var options = self.get('tifActionOptions');
          options.name = name || 'TIF District';

          return self.store.createRecord('tifDistrict', _object2.default.assign(options, { client: client, tifSchedules: tifSchedules })).save();
        }

        function setActiveTifDistrict(tifDistrict) {
          self.get('filteredTifDistricts').pushObject(tifDistrict);
          self.set('selectedTifDistrictId', tifDistrict.get('id'));
          self.send('selectTifDistrict', tifDistrict.get('id'));
        }

        return createTifSchedules().then(createTifDistrict).then(setActiveTifDistrict).catch(function (reason) {
          console.log('tif district creation failed ', reason);
          _this.get('notify').alert('Something went wrong creating new TIF District');
        });
      },
      copyTifDistrict: function copyTifDistrict(name, tifDistrict) {
        var _this2 = this;

        var self = this;

        function createTifSchedule(oldSchedule) {
          var oldScheduleProperties = oldSchedule.toJSON(),
              newSchedule = void 0;

          delete oldScheduleProperties.created;
          delete oldScheduleProperties.group;

          newSchedule = self.store.createRecord('tifSchedule', oldScheduleProperties);
          newSchedule.set('group', oldSchedule.get('group'));
          return newSchedule.save();
        }

        function cloneTifDistrict(tifSchedules) {
          var client = self.get('client');
          var options = self.get('tifActionOptions');

          options.name = name ? name : tifDistrict.get('name');
          options.enterAsDecimal = tifDistrict.get('enterAsDecimal');
          return self.store.createRecord('tifDistrict', _object2.default.assign(options, { client: client, tifSchedules: tifSchedules })).save();
        }

        function setActiveTifDistrict(newTifDistrict) {
          self.get('filteredTifDistricts').pushObject(newTifDistrict);
          self.set('selectedTifDistrictId', newTifDistrict.get('id'));
          self.send('selectTifDistrict', newTifDistrict.get('id'));
        }

        return Ember.RSVP.all(tifDistrict.get('tifSchedules').map(createTifSchedule)).then(cloneTifDistrict).then(setActiveTifDistrict).catch(function (reason) {
          console.log('copy tif district failed', reason);
          _this2.get('notify').alert('Something went wrong copying this TIF District');
        });
      },
      deleteTifDistrict: function deleteTifDistrict(tifDistrict) {
        var _this3 = this;

        this.get('filteredTifDistricts').removeObject(tifDistrict);
        this.get('tifDistricts').removeObject(tifDistrict);

        tifDistrict.set('deleted', true);

        tifDistrict.save().then(function () {
          _this3.setProperties({
            tifDistrict: null,
            deletingTifHidden: true
          });
        });
      }
    }

  });
});