define('dashboard/models/guest', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    firstName: (0, _attr.default)('string'),
    lastName: (0, _attr.default)('string'),
    organization: (0, _attr.default)('string'),
    email: (0, _attr.default)('string'),
    lastUpdated: (0, _attr.default)('date')
  });
});