define('dashboard/controllers/data-forms/request-templates/index', ['exports', 'dashboard/mixins/sorting', 'dashboard/mixins/read-only-restrictions', 'dashboard/mixins/current-user-mixin'], function (exports, _sorting, _readOnlyRestrictions, _currentUserMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_sorting.default, _readOnlyRestrictions.default, _currentUserMixin.default, {

    hideCreateRequestTemplateModal: true,
    isDeleteScenarioModalHidden: true,
    isShareModalHidden: true,
    searchText: '',
    projectToDelete: null,

    notify: Ember.inject.service(),
    adapterErrorNotifier: Ember.inject.service(),
    session: Ember.inject.service(),

    client: Ember.computed.alias('model.client'),
    currentUser: Ember.computed.alias('session.currentUser'),
    tileView: Ember.computed.alias('currentUser.requestTemplatesTileView'),

    requestTemplates: Ember.computed('model.client.requestTemplates.[]', 'model.client.requestTemplates.@each.{lockedToScenario,deleted}', function () {
      return this.get("model.client.requestTemplates").filter(function (rt) {
        return !rt.get('lockedToScenario') && !rt.get('deleted');
      });
    }),

    newRequestTemplateTitle: '',
    newRequestTemplateInputDisabled: Ember.computed('newRequestTemplateTitle', function () {
      return this.get('newRequestTemplateTitle').trim().length <= 0;
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('sortColumns', [{
        title: 'Template Title',
        sortField: 'title',
        width: '35%'
      }, {
        title: 'Questions',
        sortField: 'indexedScenariosOnly.length',
        width: '20%'
      }, {
        title: 'Last Updated',
        sortField: 'lastUpdated',
        width: '20%'
      }, {
        title: '',
        width: '10%'
      }]);

      this.set('breadcrumbItems', [{
        label: 'Data Forms',
        params: ['data-forms.inbox']
      }, {
        label: 'Templates',
        active: true
      }]);
    },


    sortField: 'lastUpdated',
    sortDir: 'desc',

    sortedList: Ember.computed.sort('requestTemplates', 'sortedValue'),

    actions: {
      setTileView: function setTileView(isTileView) {
        var currentUser = this.get('currentUser');
        currentUser.set('requestTemplatesTileView', isTileView);
        currentUser.save();
      },
      openCreateRequestTemplateModal: function openCreateRequestTemplateModal() {
        if (this.checkReadOnly()) {
          return;
        }
        this.set('newRequestTemplateTitle', '');
        this.set('hideCreateRequestTemplateModal', false);
      },
      createRequestTemplate: function createRequestTemplate(newTitle) {
        var _this = this;

        if (this.checkReadOnly()) {
          return;
        }

        var title = newTitle.trim(),
            client = this.get('model.client'),
            contact = this.get('currentUserNotSuperAdmin'),
            template = void 0;

        template = this.store.createRecord('requestTemplate', { title: title, client: client, contact: contact });
        template.save().then(function (template) {
          _this.set('hideCreateRequestTemplateModal', true);
          _this.send('transitionToTemplate', template);
        }, this.get('adapterErrorNotifier').createErrorsAlerter());
      },
      deleteRequestTemplate: function deleteRequestTemplate(requestTemplate) {
        if (this.checkReadOnly()) {
          return;
        }
        // actually deleting these breaks any scenarios which were referencing them
        // requestTemplate.destroyRecord();
        requestTemplate.set('deleted', true);
        requestTemplate.save();
      },
      openDeleteModal: function openDeleteModal(requestTemplate) {
        if (this.checkReadOnly()) {
          return;
        }
        this.set('selectedRequestTemplate', requestTemplate);
        this.set('isDeleteScenarioModalHidden', false);
      },
      openShareModal: function openShareModal(requestTemplate) {
        if (this.checkReadOnly()) {
          return;
        }
        this.set('selectedRequestTemplate', requestTemplate);
        this.set('isShareModalHidden', false);
      },
      transitionToTemplate: function transitionToTemplate(id) {
        this.transitionToRoute('data-forms.request-templates.request-template.edit', id);
      },
      transitionToPreview: function transitionToPreview(id) {
        this.transitionToRoute('data-forms.request-templates.request-template.preview', id);
      }
    }
  });
});