define('dashboard/router', ['exports', 'dashboard/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('authentication', { path: '/' }, function () {
      this.route('login', { resetNamespace: true });
      this.route('request-password', { resetNamespace: true });
      this.route('reset', { resetNamespace: true, path: '/reset/:token' });
      this.route('demo', { resetNamespace: true });
    });

    this.route('logout');

    this.route('authenticated', { path: '' }, function () {

      this.route('layout', { resetNamespace: true, path: '' }, function () {

        this.route('user', { resetNamespace: true, path: '/account' }, function () {
          this.route('users');
          this.route('customization');
          this.route('tifs');
        });

        this.route('insights', { resetNamespace: true, path: '/clients/:client_id/insights' });

        this.route('projects', { resetNamespace: true, path: '/clients/:client_id/projects' }, function () {
          this.route('won');
          this.route('lost');
          this.route('archived');
          this.route('all');

          this.route('project', { resetNamespace: true, path: '/:project_id' }, function () {

            this.route('scenario', { resetNamespace: true, path: '/scenarios/:scenario_id' }, function () {
              this.route('raw-data-preview', { resetNamespace: true, path: 'raw-data-preview' });

              this.route('scenario.public-support', { resetNamespace: true, path: 'public-support' }, function () {
                this.route('overview');
                this.route('group', { path: '/:schedule_id' });
                this.route('error');
              });

              this.route('scenario.report', { resetNamespace: true, path: 'report' }, function () {
                this.route('summary');
                this.route('detailed');
                this.route('community');
                this.route('fact-sheet');
              });

              this.route('scenario.raw-data', { resetNamespace: true, path: 'raw-data' });

              this.route('scenario.settings', { resetNamespace: true, path: 'settings' }, function () {
                this.route('general');
                this.route('district', { path: '/:tax_district_id' });
                this.route('tif-districts');
              });
            });
          });
        });

        this.route('help', { resetNamespace: true, path: '/help' }, function () {
          this.route('markdown');
        });

        this.route('admin', { resetNamespace: true, path: '/admin' }, function () {

          this.route('clients', { resetNamespace: true, path: '/clients' }, function () {
            this.route('client', { resetNamespace: true, path: '/:client_id' }, function () {
              this.route('users');
              this.route('customization');
              this.route('tifs');
            });
          });

          this.route('tax-districts', { resetNamespace: true, path: '/tax-districts' }, function () {
            this.route('tax-district', { resetNamespace: true, path: '/:tax_district_id' });
            this.route('inactive');
          });

          this.route('multiplier-sets', { resetNamespace: true, path: '/multiplier-sets' }, function () {
            this.route('multiplier-set', { resetNamespace: true, path: '/:multiplier_set_id' });
            this.route('archived');
          });

          this.route('district-area-sets', { resetNamespace: true, path: '/district-area-sets' }, function () {
            this.route('district-area-set', { resetNamespace: true, path: '/:district_area_set_id' });
          });

          this.route('dependent-tax-district-sets', { resetNamespace: true, path: '/dependent-tax-district-sets' }, function () {
            this.route('dependent-tax-district-set', { resetNamespace: true, path: '/:dependent_tax_district_set_id' });
          });

          this.route('guests', { resetNamespace: true, path: '/guests' });
        });

        this.route('data-forms', { resetNamespace: true, path: '/clients/:client_id/data-forms/' }, function () {
          this.route('inbox', function () {
            this.route('data-request', { path: '/data-request/:id' });
          });
          this.route('request-templates', function () {
            this.route('request-template', { path: '/:template_id' }, function () {
              this.route('edit');
              this.route('preview');
              this.route('wizard');
              this.route('pdf');
            });
          });
        });
      });
    });

    this.route('data-requests', function () {
      this.route('new', { path: '/new/:request_template_id' });
      this.route('edit', { path: '/:id/:token' });
      this.route('preview', { path: '/preview/:request_template_id' });
      this.route('invalid');
    });

    this.route('404', { path: '/*path' });
  });

  exports.default = Router;
});