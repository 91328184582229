define('dashboard/routes/scenario/settings/index', ['exports', 'ember-local-storage', 'dashboard/mixins/reset-scroll-position'], function (exports, _emberLocalStorage, _resetScrollPosition) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_resetScrollPosition.default, {

    sessionData: (0, _emberLocalStorage.storageFor)('session-data'),

    redirect: function redirect() {
      var params = this.get('sessionData.settingsSelection') || 'general',
          districtId = this.get('sessionData.districtId'),
          scenario = this.modelFor('scenario').scenario,
          districtIds = scenario.get('districts').mapBy('id');

      // make sure district we're trying to go to still belongs to scenario
      if (params === 'district' && !districtIds.includes(districtId)) {
        params = 'general';
        this.set('sessionData.settingsSelection', 'general');
      }

      if (params === 'district') {
        this.transitionTo('scenario.settings.district', districtId);
      } else {
        this.transitionTo('scenario.settings.' + params);
      }
    }
  });
});