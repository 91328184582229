define("dashboard/utils/not-null", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isEmptyObject = isEmptyObject;
  exports.arraysEqual = arraysEqual;


  var notNull = function notNull(data) {
    var isEmptyArray = Ember.isArray(data) && data.length == 0;
    var isDefaultArray = Ember.isArray(data) && arraysEqual(data, ["0", "0", "0", "0", "0", "0", "0", "0", "0", "0"]);
    var isNotBasicNull = data !== '' && data !== null && data !== undefined;
    return isNotBasicNull && !isEmptyArray && !isDefaultArray && !isEmptyObject(data);
  };

  function isEmptyObject(data) {
    var basicEmptyObject = data.constructor == Object && !Object.keys(data).length;
    var emptyRelationship = data.content && data.content.length && data.content.length == 0;

    return basicEmptyObject || emptyRelationship;
  }

  function arraysEqual(array1, array2) {
    if (array1.length != array2.length) return false;

    for (var i = 0; i < array1.length; i++) {
      if (array1[i] != array2[i]) {
        return false;
      }
    }

    return true;
  }

  exports.default = notNull;
});