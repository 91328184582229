define('dashboard/routes/project/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    ajax: Ember.inject.service(),

    afterModel: function afterModel(model) {
      var project = model.project;
      var user = this.modelFor('authenticated');

      // keep sorted scenario list updated
      project.notifyPropertyChange('indexedScenariosOnly');

      // automatically set an active scenario if one isn't selected
      if (!user.get('isSuperAdmin')) {
        var lastUpdatedScenario = project.get('indexedScenariosOnly').sortBy('lastUpdated').get('lastObject.id');
        var activeScenario = project.get('activeScenarioId');

        if (lastUpdatedScenario && lastUpdatedScenario !== activeScenario) {
          project.set('activeScenarioId', lastUpdatedScenario);
          project.save();
        }
      }
    }
  });
});