define('dashboard/components/modals/wizard-review-modal', ['exports', 'dashboard/components/modals/modal-confirm'], function (exports, _modalConfirm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _modalConfirm.default.extend({
    classNames: ['modal-overlay-fixed'],

    actions: {
      cancel: function cancel() {
        this.send('close');
        this.send('slideOut');
      },
      close: function close() {
        this.set('sectionToEdit', null);
      }
    }
  });
});