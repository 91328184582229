define('dashboard/utils/computed/section-boolean', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (questionNames) {
    return Ember.computed.apply(undefined, _toConsumableArray(questionNames).concat([{
      get: function get() /*key*/{
        var _this = this;

        return questionNames.any(function (boolKey) {
          return _this.get(boolKey);
        });
      },
      set: function set(key, value) {
        var _this2 = this;

        questionNames.forEach(function (question) {
          _this2.set(question, value);
        });
        return value;
      }
    }]));
  };

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }
});