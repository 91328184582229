define('dashboard/utils/array-of-length', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  /**
   * Utility - Array of Length
   *
   * Returns an array of a given length filled with given data
   *
   * @param {length} length of the array
   * @return {character} character with which to populate the array (default '0')
   *
   * @method arrayOfLength
   */
  var arrayOfLength = function arrayOfLength(length, character) {

    var arr = [];

    if (typeof character === 'undefined') {
      character = '0';
    }

    for (var i = 0; i < length; i++) {
      arr.push(character);
    }

    return arr;
  };

  exports.default = arrayOfLength;
});