define('dashboard/components/property-tax-rate', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    doesApplyTaxRate: true,

    classNames: ['property-tax-rate'],

    appliedRates: Ember.computed('title', function () {
      return [{
        name: this.get('title') + '100',
        value: '100',
        label: '/ $100',
        computedId: this.get('elementId') + '100'
      }, {
        name: this.get('title') + '1000',
        value: '1000',
        label: '/ $1,000',
        computedId: this.get('elementId') + '1000'
      }];
    }),

    rateDisabled: Ember.computed('doesApplyTaxRate', 'disabled', function () {
      return !this.get('doesApplyTaxRate') || this.get('disabled');
    }),

    abateableRateDisabled: Ember.computed('doesApplyAbateableTaxRate', 'disabled', function () {
      return !this.get('doesApplyAbateableTaxRate') || this.get('disabled');
    }),

    rateLabel: Ember.computed('title', function () {
      return 'Property Tax Rate - ' + this.get('title');
    }),

    abateableRateLabel: Ember.computed('title', function () {
      var title = this.get('title');

      return 'Abatable Property Tax Rate - ' + title;
    }),

    assessmentRatioTitle: Ember.computed('title', function () {
      var title = this.get('title');
      return 'Assessment Ratio for ' + title;
    }),

    appliedModified: Ember.computed('modified.[]', function () {
      var modifiedProperties = this.get('modified') || {};
      var inputKey = this.get('modifiedBindingApplied');

      return Ember.Object.create({
        isTrue: modifiedProperties.hasOwnProperty(inputKey),
        original: modifiedProperties[inputKey]
      });
    }),

    maskedOriginal: Ember.computed('appliedModified.original', function () {
      var original = this.get('appliedModified.original') || '';
      return numeral(original).format('($0,0)');
    }),

    actions: {
      update: function update() {
        this.notifyPropertyChange('modified');
        this.sendAction();
      }
    }

  });
});