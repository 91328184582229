define('dashboard/routes/multiplier-set', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    titleToken: function titleToken(models) {
      return ['Multiplier Sets', models.multiplierSet.get('name')];
    },

    model: function model(params) {
      var multiplierSetPromise = this.store.findRecord('multiplierSet', params.multiplier_set_id),
          multipliersPromise = this.store.query('multiplier', { multiplierSet: params.multiplier_set_id, limit: 10 }),
          districtsPromise = this.store.query('taxDistrict', { multiplierSet: params.multiplier_set_id, adminCreated: true, createdInTransition: { $ne: true } });

      return Ember.RSVP.hash({
        multiplierSet: multiplierSetPromise,
        allMultipliers: multipliersPromise,
        districts: districtsPromise
      });
    },
    afterModel: function afterModel(models) {
      this.controllerFor('multiplierSets').set('currentMultiplierSet', models.multiplierSet);
    },
    setupController: function setupController(controller, models) {

      controller.setProperties({
        districts: models.districts,
        multipliers: models.allMultipliers,
        model: models.multiplierSet
      });
    }
  });
});