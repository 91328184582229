define('dashboard/components/tag-component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    // focusOut is incidentally fired upon clicking the down carrot, which triggers this action before toggleShowTagList and leaves the user unable to close the list.
    focusOut: function focusOut() {
      var _this = this;

      this.$('.emberTagInput-input').val('');
      this.sendAction('clearSearchText');
      Ember.run.later(function () {
        if (_this.get('showTagList')) {
          _this.set('showTagList', false);
        }
      }, 250);
    },
    focusIn: function focusIn() {
      this.set('showTagList', true);
    },


    showTagList: false,

    actions: {
      addTag: function addTag(tagName) {
        this.sendAction('addTagAction', tagName);
      },
      removeTagAtIndex: function removeTagAtIndex(index) {
        this.sendAction('removeTagAction', index);
      },
      onKeyUp: function onKeyUp(value) {
        this.sendAction('onSearchAction', value);
      },
      onTagSelect: function onTagSelect(tagName) {
        this.sendAction('onTagSelect', tagName);
      }
    }
  });
});