define('dashboard/tests/mirage/mirage.lint-test', [], function () {
  'use strict';

  QUnit.module('ESLint | mirage');

  QUnit.test('mirage/config.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/config.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/client.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/client.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/district-group.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/district-group.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/guest.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/guest.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/project.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/project.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/request-template.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/request-template.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/scenario.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/scenario.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/tax-district.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/tax-district.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/user.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/user.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/assumption.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/assumption.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/client.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/client.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/custom-cost-revenue.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/custom-cost-revenue.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/default-assumption.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/default-assumption.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/direct-cost-revenue.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/direct-cost-revenue.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/district-area-set.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/district-area-set.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/district-area.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/district-area.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/district-group.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/district-group.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/guest.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/guest.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/incentive-schedule.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/incentive-schedule.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/multiplier-set.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/multiplier-set.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/multiplier.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/multiplier.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/project.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/project.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/request-template.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/request-template.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/scenario.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/scenario.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/subscription.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/subscription.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/tax-district.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/tax-district.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/user.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/user.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/application.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/application.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/client.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/client.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/district-group.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/district-group.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/project.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/project.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/request-template.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/request-template.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/scenario.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/scenario.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/user.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/user.js should pass ESLint\n\n');
  });
});