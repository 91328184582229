define('dashboard/controllers/guests', ['exports', 'dashboard/mixins/sorting'], function (exports, _sorting) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_sorting.default, {
    init: function init() {
      this._super.apply(this, arguments);
      this.set('sortColumns', [{
        title: '',
        width: '50'
      }, {
        title: 'Name',
        sortField: 'firstName'
      }, {
        title: 'Organization',
        sortField: 'organization'
      }, {
        title: 'Email',
        sortField: 'email'
      }, {
        title: 'Date',
        sortField: 'lastUpdated'
      }]);
      this.set('guestsLoaded', 50);
    },


    faSearch: '\uF002',

    notify: Ember.inject.service(),

    sortField: 'lastUpdated',
    sortDir: 'desc',

    isRemoveGuestHidden: true,
    guestToRemove: null,

    actions: {
      openRemoveGuestModal: function openRemoveGuestModal(guest) {
        this.setProperties({
          isRemoveGuestHidden: false,
          guestToRemove: guest
        });
      },
      removeGuest: function removeGuest() {
        var _this = this;

        this.get('guestToRemove').destroyRecord().then(function () {
          _this.setProperties({
            isRemoveGuestHidden: true,
            guestToRemove: null
          });
          _this.get('notify').info('Guest Removed');
        });
      },
      loadMore: function loadMore() {
        var _this2 = this;

        var guestsLoaded = this.get('guestsLoaded');

        this.store.query('guest', { limit: 50, skip: guestsLoaded }).then(function (guests) {
          _this2.get('model').pushObjects(guests.get('content'));
        });

        this.set('guestsLoaded', guestsLoaded + 50);
      }
    }
  });
});