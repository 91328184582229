define('dashboard/objects/fi-tax/direct-revenue-per-household-worker', ['exports', 'dashboard/utils/appreciated-annual-array', 'dashboard/objects/fi-tax/base'], function (exports, _appreciatedAnnualArray, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    revenuePerWorker: Ember.computed('cumulativeDirectJobs', 'revenue', 'annualIncrease', 'doesApply', function () {
      var cumulativeDirectJobs = this.get('cumulativeDirectJobs') || [],
          revenue = this.get('workerRevenue') || 0,
          annualIncrease = this.get('annualIncrease') || 0,
          doesApply = this.get('doesApply');

      return (0, _appreciatedAnnualArray.default)(cumulativeDirectJobs, revenue, annualIncrease, doesApply);
    }),

    revenuePerHousehold: Ember.computed('cumulativeMovingWorkers', 'householdRevenue', 'annualIncrease', 'doesApply', function () {
      var cumulativeMovingWorkers = this.get('cumulativeMovingWorkers') || [],
          householdRevenue = this.get('householdRevenue') || 0,
          annualIncrease = this.get('annualIncrease') || 0,
          doesApply = this.get('doesApply');

      return (0, _appreciatedAnnualArray.default)(cumulativeMovingWorkers, householdRevenue, annualIncrease, doesApply);
    }),

    project: Ember.computed.sum('revenuePerWorker'),

    workers: Ember.computed.sum('revenuePerHousehold')
  });
});