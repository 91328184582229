define('dashboard/components/scenario-input-cascading', ['exports', 'dashboard/components/scenario-input-base', 'dashboard/objects/column', 'dashboard/utils/make-inputs', 'dashboard/utils/make-increase'], function (exports, _scenarioInputBase, _column, _makeInputs, _makeIncrease) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _scenarioInputBase.default.extend({

    increasingYearlyInputs: Ember.computed('years', function () {
      var dataPropertyName = this.get('dataPropertyName'),
          increaseDataPropertyName = this.get('increaseDataPropertyName'),
          scenario = this.get('scenario'),
          rows = scenario.get(dataPropertyName),
          increase = scenario.get(increaseDataPropertyName),
          years = this.get('years');

      return _column.default.create({
        title: this.get('useLongLabel') && this.get('question.longTableHeader') || this.get('question.tableHeader'),
        columnName: dataPropertyName,
        inputType: 'dollars',
        isCascading: true,
        increase: (0, _makeIncrease.default)('scenario.' + increaseDataPropertyName, 'Percent Annual Increase', increase, 'salaryIncreaseDisabled'),
        content: (0, _makeInputs.default)(rows, years)
      });
    }),

    actions: {
      updateData: function updateData(value) {
        var data = value.content.mapBy('value');
        var increase = value.increase.value;
        var scenario = this.get('scenario');
        scenario.set(this.get('dataPropertyName'), data);
        scenario.set(this.get('increaseDataPropertyName'), increase);
        this.save();
      }
    }
  });
});