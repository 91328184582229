define("dashboard/utils/array-compare", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (array1, array2) {

    // if the other array is a falsy value, return
    if (!array1 || !array2) {
      return false;
    }

    // compare lengths - can save a lot of time
    if (array1.length !== array2.length) {
      return false;
    }

    array1 = convertArrayByType(array1);
    array2 = convertArrayByType(array2);

    for (var i = 0, l = array1.length; i < l; i++) {
      var val1 = array1[i],
          val2 = array2[i];

      // Check if we have nested arrays
      if (val1 instanceof Array && val2 instanceof Array) {
        // recurse into the nested arrays
        if (!val1.equals(val2)) {
          return false;
        }
      } else if (val1 !== val2) {
        // Warning - two different object instances will never be equal: {x:20} != {x:20}
        return false;
      }
    }
    return true;
  };

  function convertArrayByType(array) {
    return array.map(function (item) {
      return !isNaN(parseFloat(item)) ? parseFloat(item) : item;
    });
  }
});