define('dashboard/objects/ei-taxable-sales-equipment', ['exports', 'dashboard/objects/array-compute', 'dashboard/utils/percent-each'], function (exports, _arrayCompute, _percentEach) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var EiTaxableSalesEquipmentObject = _arrayCompute.default.extend({

    output: Ember.computed('investment', 'percent', function () {

      var investment = this.get('investment'),
          percent = this.get('percent');

      return (0, _percentEach.default)(investment, percent);
    })

  });

  exports.default = EiTaxableSalesEquipmentObject;
});