define('dashboard/components/scroll/indexed-scrollable-page', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    currentScrollPosition: 0,
    idString: "id",
    extraOffset: 0,

    scrollService: Ember.inject.service(),
    scrollPositions: Ember.computed.alias('scrollService.scrollPositions'),
    activeSectionId: Ember.computed.alias('scrollService.activeSectionId'),

    didInsertElement: function didInsertElement() {
      var _this = this;

      this.set('scrollPositions', []);
      // this is a bit hacky, but the observer doesn't correctly observe scrollPositions, 
      // and we need to run it after the scrollPositions load
      Ember.run.later(function () {
        _this.activeSectionWatcher();
      }, 500);
    },


    recalculateAllPositions: Ember.observer('sections.[]', function () {
      var _this2 = this;

      // The core of this function is adapted from the scroll-to component
      // But we've set it to an external trigger property (showAll in the first use case)
      // And we've looped it over every section
      Ember.run.later(function () {
        var sections = _this2.get('sections');
        var positions = sections.map(function (section) {
          var id = section.id;
          var element = document.getElementById(id);
          if (element) {
            var offsetTop = element.offsetTop;

            if (id && offsetTop !== null && offsetTop !== undefined) {
              return { id: id, offsetTop: offsetTop };
            }
          }
        }).filter(function (x) {
          return x;
        });

        _this2.set('scrollPositions', positions);

        _this2.activeSectionWatcher();
      }, 300);
    }),

    activeSectionWatcher: Ember.observer('currentScrollPosition', function () {
      var extraOffset = this.get('extraOffset') || 0;
      var currentScrollPosition = this.get('currentScrollPosition') + extraOffset * 2 + 15;
      var scrollPositions = this.get('scrollPositions').mapBy('offsetTop');
      var numberOfSections = scrollPositions.length;
      var i = 0;

      var idString = this.get("idString");

      while (i < numberOfSections) {

        if (currentScrollPosition <= scrollPositions[0]) {
          var currentSection = this.get('sections.firstObject');
          this.set('activeSectionId', Ember.get(currentSection, idString));
          break;
        }

        // if user has scrolled past the last section, then make the last section active
        if (currentScrollPosition >= scrollPositions[numberOfSections - 1]) {
          var _currentSection = this.get('sections.lastObject');
          this.set('activeSectionId', Ember.get(_currentSection, idString));

          break;
        }

        if (currentScrollPosition < scrollPositions[i]) {
          var _currentSection2 = this.get('sections').objectAt(i - 1);
          this.set('activeSectionId', Ember.get(_currentSection2, idString));

          break;
        }

        i++;
      }
      // console.log("activeSectionId", this.get('activeSectionId'))
    }),

    setScrollPosition: function setScrollPosition(offsetTop, id) {
      var scrollPositions = this.get('scrollPositions');

      if (id === scrollPositions.get('firstObject.id')) {
        this.set('scrollPositions.length', 0);
      }

      scrollPositions.push({ id: id, offsetTop: offsetTop });
    },


    actions: {
      setCurrentScrollPosition: function setCurrentScrollPosition(position) {
        this.set('currentScrollPosition', position);
      }
    }
  });
});