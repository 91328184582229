define('dashboard/controllers/tax-districts/inactive', ['exports', 'dashboard/mixins/sorting'], function (exports, _sorting) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_sorting.default, {
    init: function init() {
      this._super.apply(this, arguments);
      this.set('sortColumns', [{
        title: '',
        width: '50'
      }, {
        title: 'District Name',
        sortField: 'name'
      }, {
        title: 'Tax Year or Note',
        sortField: 'note'
      }, {
        title: 'State',
        sortField: 'state'
      }, {
        title: 'Created',
        sortField: 'created'
      }]);
      this.set('confirmDistrict', {});
    },


    sortField: 'created',
    sortDir: 'asc',

    districtConfirmHidden: true,

    actions: {
      openDistrictConfirmModal: function openDistrictConfirmModal(district) {
        this.set('districtConfirmHidden', false);
        this.set('confirmDistrict', district);
      }
    }

  });
});