define('dashboard/components/graphs/net-benefits-graph', ['exports', 'dashboard/components/graphs/d3-chart'], function (exports, _d3Chart) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _d3Chart.default.extend({

    dataWithoutIncentives: Ember.computed('data.@each.value', function () {
      var data = this.get('data'),
          output = [];

      data.forEach(function (d) {
        if (d.type !== 'incentive') {
          output.push(d);
        }
      });

      return output;
    }),

    sumDataWithoutIncentives: Ember.computed('dataWithoutIncentives.@each.value', function () {
      var data = this.get('dataWithoutIncentives'),
          sumData = [],
          values = [],
          sum = void 0,
          i = void 0,
          years = parseInt(this.get('years'));

      function _sumValues(sum, item) {
        return sum + item.value;
      }

      if (data) {
        for (i = 1; i <= years; i++) {
          values = data.filterBy('year', i);

          sum = values.reduce(_sumValues, 0);

          sumData.push({
            year: i,
            value: sum
          });
        }
      }

      return sumData;
    }),

    sumData: Ember.computed('data.@each.origValue', function () {
      var data = this.get('data'),
          sumData = [],
          values = [],
          sum,
          i,
          years = parseInt(this.get('years'));

      function _sumValues(sum, item) {
        return sum + item.origValue || 0;
      }

      if (data) {
        for (i = 1; i <= years; i++) {
          values = data.filterBy('year', i);

          sum = values.reduce(_sumValues, 0);

          sumData.push({
            year: i,
            value: sum
          });
        }
      }

      return sumData;
    }),

    dataExists: Ember.computed('data', 'sumData', function () {
      return this.get('data') && this.get('sumData.length') > 0;
    }),

    svg: Ember.computed(function () {
      return d3.select('.' + this.get('className'));
    }),

    drawChart: function drawChart() {

      var data = this.get('data'),
          dataWithoutIncentives = this.get('dataWithoutIncentives'),
          sumData = this.get('sumData'),
          sumDataWithoutIncentives = this.get('sumDataWithoutIncentives'),
          x = this.get('x'),
          yScale = this.get('yScale'),
          canvasMarginTop = this.get('canvasMarginTop'),
          canvasMarginLeft = this.get('canvasMarginLeft'),
          zeroOffset = yScale(0) + canvasMarginTop,
          y = function y(d) {
        return yScale(d.value) + canvasMarginTop;
      },
          h = function h(d) {
        return d.value < 0 ? yScale(d.value) - zeroOffset : zeroOffset - yScale(d.value);
      },
          svg = this.get('svg'),
          line = d3.line().x(function (d) {
        return canvasMarginLeft + x(d.year) + x.bandwidth() / 2;
      }).y(function (d) {
        return y(d);
      }),
          bars = svg.select('g.bars').selectAll('.bar').data(data),
          path = svg.select('g.lines').selectAll('.line').data([dataWithoutIncentives]),
          pathNoIncentives = svg.select('g.lines').selectAll('.lineNoIncentives').data([data]),
          pointsNoIncentives = svg.select('g.points.diamonds').selectAll('.diamond').data(sumData),
          points = svg.select('g.points.circles').selectAll('circle').data(sumDataWithoutIncentives),
          yearsToolTip = this.get('yearsToolTip'),
          forceExit = this.get('forceExit'),
          calendarYearDisplay = this.get('calendarYearDisplay'),
          startingYear = calendarYearDisplay ? parseInt(this.get('startingCalendarYear')) : 1;

      // Necessary when we are redrawing graph elements with varying array lengths
      // e.g. on the project/index template where the user can shuffle between scenarios of different year lengths
      if (forceExit) {
        bars.exit().remove();
        points.exit().remove();
      }

      // Bars Enter
      bars.enter().append('rect').attr('class', function (d) {
        if (d.value < 0 && d.type === 'cost') {
          return 'bar cost';
        }
        if (d.value < 0 && d.type === 'incentive') {
          return 'bar cost incentive';
        }
        return 'bar benefit';
      }).attr('title', function (d) {
        var type = d.value < 0 ? 'Cost' : 'Benefit';
        return type + ': ' + numeral(d.value).format('($0a)');
      }).attr('x', function (d) {
        return canvasMarginLeft + x(d.year);
      }).attr('width', x.bandwidth()).attr('y', function (d) {
        return d.value < 0 ? zeroOffset + canvasMarginTop : y(d);
      }).attr('height', h);

      // Bars Change
      bars.transition().duration(1000).attr('class', function (d) {
        if (d.value < 0 && d.type === 'cost') {
          return 'bar cost';
        }
        if (d.value < 0 && d.type === 'incentive') {
          return 'bar cost incentive';
        }
        return 'bar benefit';
      }).attr('x', function (d) {
        return canvasMarginLeft + x(d.year);
      }).attr('width', x.bandwidth()).attr('y', function (d) {
        return d.value < 0 ? zeroOffset + canvasMarginTop : y(d);
      }).attr('height', h);

      if (this.get('abatementsAsCosts') && this.get('hasIncentives')) {

        // points.remove(); // do this in order for circles to be painted on top of diamonds

        // Path less incentives enter | yellow line
        pathNoIncentives.enter().append('path').attr('class', 'lineNoIncentives').attr('d', line(sumData));

        // Path Change
        pathNoIncentives.transition().duration(1000).attr('d', line(sumData));

        // Points less incentives enter | yellow diamonds
        pointsNoIncentives.enter().append("path").attr("class", "diamond").attr("d", d3.symbol().size(85).type(d3.symbolDiamond)).attr("transform", function (d) {
          var translateX = canvasMarginLeft + x(d.year) + x.bandwidth() / 2;
          return "translate(" + translateX + "," + y(d) + ")";
        });

        // Points Change
        pointsNoIncentives.transition().duration(1000).attr("d", d3.symbol().size(85).type(d3.symbolDiamond)).attr("transform", function (d) {
          var translateX = canvasMarginLeft + x(d.year) + x.bandwidth() / 2;
          return "translate(" + translateX + "," + y(d) + ")";
        });

        // yellow diamond tooltips
        this.$('.diamond').tipsy({
          gravity: 's',
          html: true,
          title: function title() {
            var d = this.__data__;
            if (d.value >= 1000000 || d.value <= -1000000) {
              return '' + yearsToolTip + d.year + '<br />Net: ' + numeral(d.value).format('($0.00a)');
            }
            return '' + yearsToolTip + d.year + '<br />Net: ' + numeral(d.value).format('($0,0)');
          },
          opacity: 1
        });
      } else {
        pointsNoIncentives.remove();
        pathNoIncentives.remove();
        bars.exit().remove();
      }

      // Path Enter | orange line
      path.enter().append('path').attr('class', 'line').attr('d', line(sumDataWithoutIncentives));

      // Path Change
      path.transition().duration(1000).attr('d', line(sumDataWithoutIncentives));

      // Points Enter | orange points
      points.enter().append('circle').attr('class', 'point').attr('r', 5).attr('cx', function (d) {
        return canvasMarginLeft + x(d.year) + x.bandwidth() / 2;
      }).attr('cy', y);

      // Points Change
      points.transition().duration(1000).attr('class', 'point').attr('cx', function (d) {
        return canvasMarginLeft + x(d.year) + x.bandwidth() / 2;
      }).attr('cy', y);

      // bar tooltips
      this.$('rect.bar').tipsy({
        gravity: 's',
        html: true,
        title: function title() {
          var d = this.__data__,
              type = d.value < 0 ? d.type.capitalize() : 'Benefit',
              value = d.value < 0 ? d.origValue : d.value,
              year = d.year + startingYear - 1;
          if (d.value >= 1000000 || d.value <= -1000000) {
            return '' + yearsToolTip + year + '<br />' + type + ': ' + numeral(value).format('($0.00a)');
          }
          return '' + yearsToolTip + year + '<br />' + type + ': ' + numeral(value).format('($0,0)');
        },
        opacity: 1
      });

      // orange circel tooltips
      this.$('circle').tipsy({
        gravity: 's',
        html: true,
        title: function title() {
          var d = this.__data__,
              year = d.year + startingYear - 1;

          if (d.value >= 1000000 || d.value <= -1000000) {
            return '' + yearsToolTip + year + '<br />Net: ' + numeral(d.value).format('($0.00a)');
          }
          return '' + yearsToolTip + year + '<br />Net: ' + numeral(d.value).format('($0,0)');
        },
        opacity: 1
      });
    }

  });
});