define('dashboard/controllers/multiplier-sets/archived', ['exports', 'dashboard/mixins/sorting', 'dashboard/mixins/multiplier-set-actions', 'ember-cli-pagination/computed/paged-array'], function (exports, _sorting, _multiplierSetActions, _pagedArray) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_sorting.default, _multiplierSetActions.default, {

    multiplierSetsController: Ember.inject.controller('multiplierSets'),

    archivedList: Ember.computed('sortedList.@each.archived', 'filteredList', function () {
      return this.get('filteredList').filter(function (set) {
        return set.get('archived');
      });
    }),

    pagedContent: (0, _pagedArray.default)('archivedList', { infinite: "unpaged", perPage: 25 })

  });
});