define('dashboard/mixins/reusable-scenario-properties', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({

    activeDirectCostRevenues: Ember.computed('sortedDistricts.@each.directCostRevenues', function () {
      var active = [];
      // make one array of all active direct cost revs for this scenario's districts
      this.get('sortedDistricts').forEach(function (district) {
        active.pushObjects(district.get('directCostRevenues').filterBy('doesApplyDirectCostRevenue', true));
      });

      return active;
    })

  });
});