define('dashboard/utils/percent-increase', ['exports', 'dashboard/utils/percent-each', 'lodash/util', 'lodash/array'], function (exports, _percentEach, _util2, _array2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var percentIncrease = function percentIncrease(startingValue, increase, size) {

    var newArray = [startingValue];

    _util2.default.times(size - 1, function () {
      newArray.push(_array2.default.last(newArray) * (1 + (0, _percentEach.percentify)(increase)));
    });

    return newArray;
  };

  exports.default = percentIncrease;
});