define('dashboard/objects/fi-tax/sales-taxes', ['exports', 'dashboard/utils/percent-each', 'dashboard/utils/sum-many-arrays', 'dashboard/objects/fi-tax/base'], function (exports, _percentEach, _sumManyArrays, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    constructionMaterialsTaxPreRebates: Ember.computed('constructionMaterialsPreRebates', 'salesTax', 'doesApplyConstructionMaterialsTax', function () {
      var constructionMaterialsPreRebates = this.get('constructionMaterialsPreRebates'),
          salesTax = this.get('salesTax'),
          doesApply = this.get('doesApplyConstructionMaterialsTax');

      return doesApply ? (0, _percentEach.default)(constructionMaterialsPreRebates, salesTax) : [];
    }),

    constructionMaterialsTax: Ember.computed('constructionMaterials', 'salesTax', 'doesApplyConstructionMaterialsTax', function () {
      var constructionMaterials = this.get('constructionMaterials'),
          salesTax = this.get('salesTax'),
          doesApply = this.get('doesApplyConstructionMaterialsTax');

      return doesApply ? (0, _percentEach.default)(constructionMaterials, salesTax) : [];
    }),

    constructionWorkerSpendingTax: Ember.computed('constructionWorkerSpending', 'salesTax', 'doesApplyConstructionWorkerSpendingTax', function () {
      var constructionWorkerSpending = this.get('constructionWorkerSpending'),
          salesTax = this.get('salesTax'),
          doesApply = this.get('doesApplyConstructionWorkerSpendingTax');

      return doesApply ? (0, _percentEach.default)(constructionWorkerSpending, salesTax) : [];
    }),

    equipmentSpendingTaxPreRebates: Ember.computed('equipmentSpendingPreRebates', 'salesTax', 'doesApplyEquipmentTax', function () {
      var equipmentSpendingPreRebates = this.get('equipmentSpendingPreRebates'),
          salesTax = this.get('salesTax'),
          doesApply = this.get('doesApplyEquipmentTax');

      return doesApply ? (0, _percentEach.default)(equipmentSpendingPreRebates, salesTax) : [];
    }),

    equipmentSpendingTax: Ember.computed('equipmentSpending', 'salesTax', 'doesApplyEquipmentTax', function () {
      var equipmentSpending = this.get('equipmentSpending'),
          salesTax = this.get('salesTax'),
          doesApply = this.get('doesApplyEquipmentTax');

      return doesApply ? (0, _percentEach.default)(equipmentSpending, salesTax) : [];
    }),

    directWorkerSpendingTax: Ember.computed('directWorkerSpending', 'salesTax', 'doesApplyDirectWorkerSpendingTax', function () {
      var directWorkerSpending = this.get('directWorkerSpending'),
          salesTax = this.get('salesTax'),
          doesApply = this.get('doesApplyDirectWorkerSpendingTax');

      return doesApply ? (0, _percentEach.default)(directWorkerSpending, salesTax) : [];
    }),

    indirectWorkerSpendingTax: Ember.computed('indirectWorkerSpending', 'salesTax', 'doesApplyIndirectWorkerSpendingTax', function () {
      var indirectWorkerSpending = this.get('indirectWorkerSpending'),
          salesTax = this.get('salesTax'),
          doesApply = this.get('doesApplyIndirectWorkerSpendingTax');

      return doesApply ? (0, _percentEach.default)(indirectWorkerSpending, salesTax) : [];
    }),

    taxableSalesTaxPreRebates: Ember.computed('taxableSalesPreRebates', 'salesTax', 'doesApplyTaxableSalesTax', function () {
      var taxableSalesPreRebates = this.get('taxableSalesPreRebates'),
          taxRate = this.get('salesTax'),
          doesApply = this.get('doesApplyTaxableSalesTax');

      return taxableSalesPreRebates.map(function (value, index) {
        var rate = Ember.isArray(taxRate) ? parseFloat(taxRate[index]) : parseFloat(taxRate);
        return doesApply ? parseFloat(value) * rate : 0;
      });
    }),

    taxableSalesTax: Ember.computed('taxableSales', 'salesTax', 'doesApplyTaxableSalesTax', function () {
      var taxableSales = this.get('taxableSales'),
          taxRate = this.get('salesTax'),
          doesApply = this.get('doesApplyTaxableSalesTax');

      return taxableSales.map(function (value, index) {
        var rate = Ember.isArray(taxRate) ? parseFloat(taxRate[index]) : parseFloat(taxRate);
        return doesApply ? parseFloat(value) * rate : 0;
      });
    }),

    taxablePurchasesTax: Ember.computed('taxablePurchases', 'salesTax', 'doesApplyTaxablePurchasesTax', function () {
      var taxablePurchases = this.get('taxablePurchases'),
          salesTax = this.get('salesTax'),
          doesApply = this.get('doesApplyTaxablePurchasesTax');

      return doesApply ? (0, _percentEach.default)(taxablePurchases, salesTax) : [];
    }),

    hotelSpendingTax: Ember.computed('hotelSpending', 'salesTax', 'doesApplyHotelSpendingTax', function () {
      var hotelSpending = this.get('hotelSpending'),
          salesTax = this.get('salesTax'),
          doesApply = this.get('doesApplyHotelSpendingTax');

      return doesApply ? (0, _percentEach.default)(hotelSpending, salesTax) : [];
    }),

    visitorSpendingTax: Ember.computed('visitorSpending', 'salesTax', 'doesApplyVisitorSpendingTax', function () {
      var visitorSpending = this.get('visitorSpending'),
          salesTax = this.get('salesTax'),
          doesApply = this.get('doesApplyVisitorSpendingTax');

      return doesApply ? (0, _percentEach.default)(visitorSpending, salesTax) : [];
    }),

    newWorkerTaxes: Ember.computed('directWorkerSpendingTax', 'indirectWorkerSpendingTax', function () {
      var directWorkerSpendingTax = this.get('directWorkerSpendingTax'),
          indirectWorkerSpendingTax = this.get('indirectWorkerSpendingTax'),
          taxes = [directWorkerSpendingTax, indirectWorkerSpendingTax];

      return (0, _sumManyArrays.default)(taxes);
    })
  });
});