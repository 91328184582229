define('dashboard/components/modals/district-confirm-modal', ['exports', 'dashboard/components/modals/modal-confirm'], function (exports, _modalConfirm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _modalConfirm.default.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.set('district', {});
    },


    actions: {
      activateDeactivateDistrict: function activateDeactivateDistrict(district) {
        district.set('isActive', !district.get('isActive'));
        district.save();
        this.send('cancel');
      }
    }

  });
});