define('dashboard/models/incentive-schedule', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    scenario: (0, _relationships.belongsTo)('scenario'),
    districtGroup: (0, _relationships.belongsTo)('districtGroup'),

    isActive: (0, _attr.default)('boolean', { defaultValue: true }),

    doesApplyAbatementsLand: (0, _attr.default)('boolean', { defaultValue: true }),
    doesApplyAbatementsBuildings: (0, _attr.default)('boolean', { defaultValue: true }),
    doesApplyAbatementsFurniture: (0, _attr.default)('boolean', { defaultValue: true }),
    doesApplyAbatementsInventories: (0, _attr.default)('boolean', { defaultValue: true }),
    doesApplyMaterialsTaxRebates: (0, _attr.default)('boolean', { defaultValue: true }),
    doesApplyEquipmentTaxRebates: (0, _attr.default)('boolean', { defaultValue: true }),
    doesApplySalesTaxRebates: (0, _attr.default)('boolean', { defaultValue: true }),
    doesApplyHotelTaxRebates: (0, _attr.default)('boolean', { defaultValue: true }),

    abatementScheduleLand: (0, _attr.default)({ defaultValue: function defaultValue() {
        return ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0'];
      } }),
    abatementScheduleBuildings: (0, _attr.default)({ defaultValue: function defaultValue() {
        return ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0'];
      } }),
    abatementScheduleFurniture: (0, _attr.default)({ defaultValue: function defaultValue() {
        return ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0'];
      } }),
    abatementScheduleInventories: (0, _attr.default)({ defaultValue: function defaultValue() {
        return ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0'];
      } }),

    // construction materials sales tax rebate
    materialsRebateSchedule: (0, _attr.default)({ defaultValue: function defaultValue() {
        return ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0'];
      } }),

    // ffe purchases sales tax rebate
    equipmentRebateSchedule: (0, _attr.default)({ defaultValue: function defaultValue() {
        return ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0'];
      } }),

    // sales tax rebates
    rebateSchedule: (0, _attr.default)({ defaultValue: function defaultValue() {
        return ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0'];
      } }),

    // hotel tax on hotel spending rebates
    hotelTaxRebateSchedule: (0, _attr.default)({ defaultValue: function defaultValue() {
        return ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0'];
      } }),

    incentives: (0, _attr.default)({ defaultValue: function defaultValue() {
        return ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0'];
      } }),

    forTifDistrict: (0, _attr.default)('boolean', { defaultValue: false }),
    percentOfTif: (0, _attr.default)({ defaultValue: function defaultValue() {
        return ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0'];
      } }),

    propertyTaxIncentiveTerm: (0, _attr.default)('string'),
    materialsEquipmentTaxIncentiveTerm: (0, _attr.default)('string'),
    salesTaxIncentiveTerm: (0, _attr.default)('string'),
    hotelTaxIncentiveTerm: (0, _attr.default)('string'),

    incentiveApproach: (0, _attr.default)('string', { defaultValue: 'amount' }),

    forCommunityIncentive: (0, _attr.default)('boolean', { defaultValue: false }),
    attributionApproach: (0, _attr.default)('string', { defaultValue: 'equal' }),
    communityAttributionPercent: (0, _attr.default)('string')
  });
});