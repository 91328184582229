define('dashboard/components/dependent-district-select', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      // these will get set whenever the primary district is changed
      this.set('dependentDistricts', this.get('dependentTaxDistricts'));
      this.set('groupSortOrder', ['groupOrder:asc']);
    },


    primaryGroup: Ember.computed.alias('scenarioController.sortedDistrictGroups.firstObject'),

    dependentGroups: Ember.computed('scenarioController.sortedDistrictGroups', 'dependentDistricts.{each,[]}', 'scenario.districts.{each,[]}', function () {
      var _this = this;

      var scenarioGroups = this.get('scenarioController.sortedDistrictGroups'),
          dependentGroups = scenarioGroups.slice(1, scenarioGroups.get('length'));

      var groups = dependentGroups.map(function (group) {
        var groupOptions = _this.getDistrictsForGroup(group);
        return Ember.Object.create({
          model: group,
          options: groupOptions,
          selectedDistrict: null
        });
      });

      // we now need to go through all groups and make sure selected districts only show up (at most) once across all groups
      var selectedDistrictsCopy = this.get('scenario.districts').toArray();
      var districtsToFilterOut = [];

      return groups.map(function (group) {

        // console.log('GROUP ', group.get('model.label'), '\n');
        var options = group.get('options');

        // remove all districts marked as duplicates to delete
        // console.log('districtsToFilter ', districtsToFilterOut.mapBy('name'));

        districtsToFilterOut.forEach(function (district) {
          return options.removeObject(district);
        });

        // console.log('options after ', options.mapBy('name'));

        // find selected district for group
        var selectedDistrict = selectedDistrictsCopy.find(function (district) {
          return options.findBy('id', district.get('id'));
        });

        if (selectedDistrict) {
          // group.selectedDistrict = selectedDistrict;
          districtsToFilterOut.push(selectedDistrict);
          selectedDistrictsCopy.removeObject(selectedDistrict);
        }

        return Ember.Object.create({ model: group.model, options: group.options });
      });
    }),

    getDistrictsForGroup: function getDistrictsForGroup(group) {

      var dependentDistricts = this.get('dependentDistricts') || [];

      // simple - get districts who's group property directly matches the district group's label property
      var baseFilteredDistricts = dependentDistricts.filterBy('group', group.get('label'));

      // more complicated - also get districts which should be in this group because of an existing district group link
      var groupLinks = this.get('filteredDependentDistrictGroupLinks') || [];

      // for this group, find any group links which reference this group - could be multiple but probably arent
      var filteredLinks = groupLinks.filter(function (link) {
        return link.get('districtGroups').includes(group);
      });

      // filter possible districts where their "group" property matches the link's "matchingText"
      var linkFilteredDistricts = filteredLinks.flatMap(function (link) {
        return dependentDistricts.filterBy('group', link.get('matchingText'));
      });

      return baseFilteredDistricts.concat(linkFilteredDistricts);
    },


    /* OPTIONAL GROUP PROPERTIES */

    optionalGroups: Ember.computed.alias('client.optionalGroups'),
    includedOptionalGroups: Ember.computed.alias('scenario.includedOptionalGroups'),
    sortedIncludedOptionalGroups: Ember.computed.sort('includedOptionalGroups', 'groupSortOrder'),

    // helper property making it easier to filter optional/nonOptional groups
    dependentGroupsByOptional: Ember.computed('dependentGroups.{[],each}', 'optionalGroups', function () {
      var dependentGroups = this.get('dependentGroups');
      var optionalGroupIds = this.get('optionalGroups').mapBy('id');

      return Ember.Object.create({
        nonOptional: dependentGroups.filter(function (group) {
          return optionalGroupIds.indexOf(group.get('model.id')) === -1;
        }),
        optional: dependentGroups.filter(function (group) {
          return optionalGroupIds.indexOf(group.get('model.id')) > -1;
        })
      });
    }),

    availableOptionalGroups: Ember.computed('dependentGroupsByOptional.optional', 'sortedIncludedOptionalGroups.[]', function () {
      var includedOptionalGroups = this.get('sortedIncludedOptionalGroups') || [];
      // return all of the groups which are in optional but aren't in scenario includedOptional
      return this.get('dependentGroupsByOptional.optional').filter(function (group) {
        return !includedOptionalGroups.findBy('id', group.get('model.id'));
      });
    }),

    filteredDependentGroups: Ember.computed('dependentGroupsByOptional.{nonOptional,optional}', 'sortedIncludedOptionalGroups.[]', function () {
      var nonOptional = this.get('dependentGroupsByOptional.nonOptional');

      var includedOptionalGroups = this.get('sortedIncludedOptionalGroups') || [];
      var includedOptional = this.get('dependentGroupsByOptional.optional').filter(function (group) {
        return includedOptionalGroups.findBy('id', group.get('model.id'));
      });

      return nonOptional.concat(includedOptional);
    }),

    finalGroupHasSelection: Ember.computed('filteredDependentGroups.lastObject.options.{[]}', 'scenario.districts.{@each,[]}', function () {
      var finalDependentGroup = this.get('filteredDependentGroups.lastObject');
      var scenarioDistricts = this.get('scenario.districts');

      return finalDependentGroup.get('options').find(function (district) {
        return scenarioDistricts.includes(district);
      });
    }),

    finalGroupIsOptional: Ember.computed('filteredDependentGroups.lastObject.options.{[]}', 'sortedIncludedOptionalGroups', function () {
      var finalDependentGroup = this.get('filteredDependentGroups.lastObject.model');
      var includedOptionalGroups = this.get('sortedIncludedOptionalGroups') || [];

      return includedOptionalGroups.includes(finalDependentGroup);
    }),

    // make sure a district is selected in the final district group and that there are optional groups to add
    canAddOptionalGroup: Ember.computed('availableOptionalGroups', function () {
      return this.get('availableOptionalGroups.length') && this.get('finalGroupHasSelection');
    }),

    // make sure this group has no selection and that it is an optional group
    canRemoveOptionalGroup: Ember.computed('finalGroupHasSelection', 'finalGroupIsOptional', function () {
      return !this.get('finalGroupHasSelection') && this.get('finalGroupIsOptional');
    }),

    /* DEPENDENT GROUP LINK PROPERTIES */

    // returns group links filtered by the selected dependent tax district set for this client
    filteredDependentDistrictGroupLinks: Ember.computed('dependentDistrictGroupLinks.@each', 'dependentTaxDistrictSet.id', function () {
      var links = this.get('dependentDistrictGroupLinks') || [];
      return links.filterBy('dependentTaxDistrictSet.id', this.get('dependentTaxDistrictSet.id'));
    }),

    dependentsDisabled: Ember.computed('disabled', 'scenario.districts.{each,[],length}', function () {
      var controllerDisabled = this.get('disabled');
      var noDistrictsSelected = !this.get('scenario.districts.length');
      return controllerDisabled || noDistrictsSelected;
    }),

    savable: Ember.computed.alias('scenarioController.isSavable'),

    actions: {

      // action will get bubbled up from selectDistrict action in district-select-for-primary-district component
      selectPrimaryDistrict: function selectPrimaryDistrict(newDistrict, group) {
        var _this2 = this;

        // all that has happened so far before bubbling is the old district was removed from this group and new one was added

        var store = this.get('scenarioController').store,
            scenarioModel = this.get('scenario'),
            self = this;

        // clear out all dependent district groups
        removeOldDistricts();

        // set scenario property to save primary district reference
        this.set('scenario.primaryDependentDistrict', newDistrict);

        // when N/A is selected, clear out all dependent district options and remove scenario.primaryDependentDistrict
        if (!newDistrict) {
          setDependentDistricts([]).then(this.saveScenario());
          return;
        }

        findDependentDistricts(newDistrict).then(setDependentDistricts).then(this.addDistrictToGroup(newDistrict, group)).then(setTimeout(function () {
          _this2.setDefaultDependentDistricts();
        }, 750));
        // .then(saveScenario);

        // just clear out all old districts - needed whenever primary changes
        function removeOldDistricts() {
          return Ember.RSVP.resolve(scenarioModel.set('districts', []));
        }

        // query for dependent districts matching new primary district and dependent tax district set
        function findDependentDistricts(newDistrict) {
          // if primary district was modified, need to query these based on the original district, not modified or else nothing is found
          var primaryId = newDistrict.get('copyOfDistrict') ? newDistrict.get('copyOfDistrict') : newDistrict.get('id');
          return store.query('taxDistrict', {
            primaryDependency: primaryId,
            dependentTaxDistrictSet: self.get('dependentTaxDistrictSet.id'),
            isPrimary: false
          });
        }

        // set dependentDistricts to trigger change in dependentGroups property
        function setDependentDistricts(newDependentDistricts) {
          return Ember.RSVP.resolve(self.set('dependentDistricts', newDependentDistricts));
        }
      },
      selectDependentDistrict: function selectDependentDistrict(newDistrict, group) {
        // if N/A is selected, bubble action up which should just save scenario
        if (!newDistrict) {
          this.sendAction('action', newDistrict, group);
          return;
        }

        return this.addDistrictToGroup(newDistrict, group);
      },
      addOptionalGroup: function addOptionalGroup() {
        var groupToAdd = this.get('availableOptionalGroups.firstObject.model');
        this.get('scenario.includedOptionalGroups').pushObject(groupToAdd);
        this.saveScenario();
      },
      removeOptionalGroup: function removeOptionalGroup() {
        var groupToRemove = this.get('filteredDependentGroups.lastObject.model');
        this.get('scenario.includedOptionalGroups').removeObject(groupToRemove);
        this.saveScenario();
      }
    },

    // looping groups defined above and setting district if only one option is available
    // group format = { model, options }
    setDefaultDependentDistricts: function setDefaultDependentDistricts() {
      var _this3 = this;

      // issue: dependentGroups doesn't always have all options populated by the time we get here, even though we just updated dependent districts, so wait?
      Ember.RSVP.all(this.get('dependentGroups').filter(function (group) {
        return group.get('options.length') === 1;
      }).map(function (group) {
        return _this3.addDistrictToGroup(group.get('options.firstObject'), group.get('model'));
      }));
    },

    addDistrictToGroup: function addDistrictToGroup(newDistrict, group) {
      var needsSave = group.get('districts').indexOf(newDistrict) < 0;

      newDistrict.set('parentGroup', group);

      // no need to add district to group if it's already there
      if (needsSave) {
        group.get('districts').pushObject(newDistrict);
      }
      this.sendAction('action', newDistrict, group);

      if (this.get('savable') && needsSave) {
        return group.save();
      }

      return Ember.RSVP.resolve();
    },

    saveScenario: function saveScenario() {
      if (this.get('savable')) {
        this.get('scenario').save();
      }
    }

  });
});