define('dashboard/components/changed-array-input', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    arrayModified: Ember.computed('modified.[]', function () {
      var modified = this.get('modified') || {},
          modifiedKey = this.get('modifiedKey'),
          modifiedArray = [];

      if (modified.hasOwnProperty(modifiedKey) && Array.isArray(modified[modifiedKey])) {
        modifiedArray = modified[modifiedKey];
      }

      return modifiedArray;
    }),

    actions: {
      update: function update() {
        this.notifyPropertyChange('modified');
        this.sendAction();
      }
    }
  });
});