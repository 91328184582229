define('dashboard/utils/scenario-inputs/index', ['exports', 'dashboard/utils/scenario-inputs/welcome', 'dashboard/utils/scenario-inputs/current', 'dashboard/utils/scenario-inputs/future', 'dashboard/utils/scenario-inputs/company', 'dashboard/utils/scenario-inputs/support'], function (exports, _welcome, _current, _future, _company, _support) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = {
    welcome: _extends({}, _welcome.default),
    current: _extends({}, _current.default),
    future: _extends({}, _future.default),
    support: _extends({}, _support.default),
    company: _extends({}, _company.default)
  };
});