define("dashboard/controllers/help/markdown", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    markdownExampleText: "# Largest header\n### Medium header\n###### Smallest header\n\n**Economic Development Objectives**\n1. Create/Retain Jobs\n1. Expand Tax Base\n1. Improve Quality of Life\n\n__Economic Development Objectives__\n* Create/Retain Jobs\n* Expand Tax Base\n* Improve Quality of Life\n\n*Economic Development Objectives*\n1. Create/Retain Jobs\n2. Expand Tax Base\n3. Improve Quality of Life\n\n***Economic Development Objectives***\n- Create/Retain Jobs\n- Expand Tax Base\n- Improve Quality of Life"

  });
});