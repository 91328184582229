define('dashboard/components/panel-selection', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      select: function select(value) {
        if (!this.get('disabled')) {
          this.set('selection', value);
          this.save(value);
        }
      }
    }
  });
});