define('dashboard/components/fa-custom', ['exports', 'dashboard/mixins/hover', 'dashboard/utils/create-popover-id'], function (exports, _hover, _createPopoverId) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_hover.default, {

    classNameBindings: ['hover:toolTip', 'faSize', 'active'],
    classNames: ['d-inline-flex', 'flex-column', 'align-items-center', 'fa-custom'],

    init: function init() {
      this._super.apply(this, arguments);
      this.set('localTargetId', (0, _createPopoverId.default)());
    },


    isShowingOptions: false,

    toolTip: false,

    width: '70',

    computedWidth: Ember.computed('width', function () {
      return Ember.String.htmlSafe('width: ' + this.get('width') + 'px');
    }),

    click: function click(e) {
      this.set('hover', false);
      this.sendAction('action');
      if (!this.get('bubbles')) {
        e.stopPropagation();
      }

      if (this.get('additionalOptions')) {
        this.toggleProperty('isShowingOptions');
      }
    },


    targetId: Ember.computed('id', function () {
      var customTargetId = this.get('id');
      var localTargetId = this.get('localTargetId');
      return customTargetId || localTargetId;
    }),

    actions: {
      selectOption: function selectOption(option) {
        this.set('isShowingOptions', false);
        this.selectOption(option);
      }
    }
  });
});