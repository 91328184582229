define('dashboard/components/changed-masked-input', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['input-box'],
    classNameBindings: ['isModified.isTrue:yellow-input'],

    focusOut: function focusOut() {
      this.notifyPropertyChange('modified');
      this.sendAction('action', this.get('toSend'));
    },

    isModified: Ember.computed('modified.[]', function () {
      var modifiedProperties = this.get('modified') || {},
          inputKey = this.get('modifiedKey');

      return Ember.Object.create({
        isTrue: modifiedProperties.hasOwnProperty(inputKey),
        original: modifiedProperties[inputKey]
      });
    }),

    showDefaults: Ember.computed('isModified.isTrue', 'defaults', function () {
      return this.get('isModified.isTrue') && this.get('defaults') !== false;
    }),

    maskedOriginal: Ember.computed('isModified.original', 'maskType', function () {
      var rawModified = this.get('isModified.original'),
          maskType = this.get('maskType');

      switch (maskType) {
        case 'percent':
          return percentMask(rawModified);
        case 'dollars':
          return numeral(rawModified).format('($0,0)');
        default:
          return rawModified;
      }
    })

  });


  function percentMask(maskedValue, medium) {
    var format = medium ? '0.00[00]' : '0.00';
    //multiply by 100
    maskedValue = isNaN(maskedValue) ? 0 : maskedValue * 100;
    var isWhole = maskedValue % 1 === 0;
    return isWhole ? maskedValue + '.0%' : numeral(maskedValue).format(format) + '%';
  }
});