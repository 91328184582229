define('dashboard/components/modals/scenario-basics-modal', ['exports', 'dashboard/components/modals/modal-confirm'], function (exports, _modalConfirm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _modalConfirm.default.extend({
    classNames: ['modal-overlay-fixed'],
    isWalkthrough: Ember.computed.equal('scenario.walkthroughCompleted', false),
    project: Ember.computed.alias('scenario.project.content'),

    init: function init() {
      this._super.apply(this, arguments);

      this.set('businessTypeOptions', [{ value: 'new', label: 'New Business' }, { value: 'existing', label: 'Existing Business' }]);

      this.set('willExpandOptions', [{ value: true, label: 'Expansion' }, { value: false, label: 'No Expansion' }]);
    },


    businessTypeTooltip: 'Choose "New Business" if the company does not currently operate in your community. Choose "Existing Business" if the company has an existing presence in the community.',

    helpProjectType: 'Edit or add Project Type choices in <a href="/account/customization" class="link underline nowrap">My Account.</a>',
    helpIndustry: 'Edit or add Industry choices in <a href="/account/customization" class="link underline nowrap">My Account.</a>',

    actions: {
      toLocationModal: function toLocationModal() {
        this.set('isHidden', true);
        this.sendAction('showLocationModal');
      },
      updateScenarioTitle: function updateScenarioTitle(title) {
        this.sendAction('updateScenarioTitle', title);
      },
      saveProjectProperty: function saveProjectProperty(propertyName, propertyValue) {
        var project = this.get('project');
        project.set(propertyName, propertyValue);
        project.save();
      },
      saveBusinessType: function saveBusinessType() {
        this.sendAction('saveBusinessTypeAction');
      },
      saveScenario: function saveScenario() {
        this.save();
      }
    }
  });
});