define('dashboard/objects/impact-engine-current', ['exports', 'dashboard/utils/array-of-length', 'dashboard/utils/float-map', 'dashboard/utils/match-length', 'dashboard/objects/fi-tax/base', 'dashboard/objects/impact-engine'], function (exports, _arrayOfLength, _floatMap, _matchLength, _base, _impactEngine) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // Objects
  var CurrentImpactEngine = _impactEngine.default.extend({
    // add or override properties in the Impact-Engine to fit the needs of current operations

    name: 'currentEngine',

    // assumption model overrides
    percentMarketValueFFE: Ember.computed('assumption.scenarioLength', function () {
      return (0, _arrayOfLength.default)(this.get('assumption.scenarioLength'), '1.0');
    }),
    commercialRealPropertyAppreciation: '0.0',
    buildingAndImprovementsAssociatedWithConstruction: false,

    // tax district model overrides
    percentNewDirectWorkersMoving: 0,
    percentNewIndirectWorkersMoving: 0,
    percentEquipmentSalesTax: 0,
    doesApplyPercentPropertyTaxLost: false,

    employmentMultiplier: Ember.computed.alias('scenario.model.currentEmploymentMultiplier'),
    earningsMultiplier: Ember.computed.alias('scenario.model.currentEarningsMultiplier'),
    multiplierCode: Ember.computed.alias('scenario.model.currentMultiplierCode'),
    usesCustomMultipliers: Ember.computed.alias('scenario.model.currentUsesCustomMultipliers'),

    // public support overrides
    materialsRebateSchedule: Ember.computed('assumption.scenarioLength', function () {
      return (0, _arrayOfLength.default)(this.get('assumption.scenarioLength'));
    }),
    equipmentRebateSchedule: Ember.computed('assumption.scenarioLength', function () {
      return (0, _arrayOfLength.default)(this.get('assumption.scenarioLength'));
    }),
    rebateSchedule: Ember.computed('assumption.scenarioLength', function () {
      return (0, _arrayOfLength.default)(this.get('assumption.scenarioLength'));
    }),
    abatementScheduleLand: Ember.computed('assumption.scenarioLength', function () {
      return (0, _arrayOfLength.default)(this.get('assumption.scenarioLength'));
    }),
    abatementScheduleBuildings: Ember.computed('assumption.scenarioLength', function () {
      return (0, _arrayOfLength.default)(this.get('assumption.scenarioLength'));
    }),
    abatementScheduleFurniture: Ember.computed('assumption.scenarioLength', function () {
      return (0, _arrayOfLength.default)(this.get('assumption.scenarioLength'));
    }),
    abatementScheduleInventories: Ember.computed('assumption.scenarioLength', function () {
      return (0, _arrayOfLength.default)(this.get('assumption.scenarioLength'));
    }),
    nonTaxIncentives: Ember.computed('assumption.scenarioLength', function () {
      return (0, _arrayOfLength.default)(this.get('assumption.scenarioLength'));
    }),
    hotelRebateSchedule: Ember.computed('assumption.scenarioLength', function () {
      return (0, _arrayOfLength.default)(this.get('assumption.scenarioLength'));
    }),

    // tif schedule override - return fake tifSchedule model with all contribution rates at 0
    engineTifSchedule: Ember.computed('scenario.model.{tifDistrict,usesTifDistrict,tifDistrict.tifSchedules}', 'group', function () {
      var tifDistrict = this.get('scenario.model.tifDistrict'),
          scenarioUsesTif = this.get('scenario.model.usesTifDistrict');

      if (!tifDistrict || !tifDistrict.get('content') || !scenarioUsesTif) {
        return;
      }

      return Ember.Object.create({
        landContributed: 0,
        buildingsContributed: 0,
        ffeContributed: 0,
        inventoriesContributed: 0,
        taxableSalesContributed: 0,
        landContributedDecimal: 0,
        buildingsContributedDecimal: 0,
        ffeContributedDecimal: 0,
        inventoriesContributedDecimal: 0,
        taxableSalesContributedDecimal: 0
      });
    }),

    // scenario model overrrides (current operations properties on scenario model)
    slicedJobs: Ember.computed('scenario.model.currentJobs', 'assumption.scenarioLength', function () {
      var jobsAddedEachYear = this.get('scenario.model.currentJobs') || 0;
      return [jobsAddedEachYear].concat((0, _arrayOfLength.default)(this.get('assumption.scenarioLength') - 1, 0));
    }),

    averageAnnualSalaries: Ember.computed('scenario.model.currentAverageSalary', 'assumption.scenarioLength', function () {
      var currentAverageSalary = this.get('scenario.model.currentAverageSalary') || 0;
      var currentSalaryArray = [currentAverageSalary].concat((0, _arrayOfLength.default)(this.get('assumption.scenarioLength') - 1, 0));

      // hard coded 2% assumed salaries rise year over year
      for (var i = 0; i < currentSalaryArray.length - 1; i++) {
        var newValue = parseFloat(currentSalaryArray[i]);
        newValue = newValue * 1.02;
        currentSalaryArray[i + 1] = newValue;
      }
      return currentSalaryArray;
    }),

    capInvestmentLand: Ember.computed('scenario.model.currentCapitalInvestmentLand', 'assumption.scenarioLength', function () {
      var land = this.get('scenario.model.currentCapitalInvestmentLand') || 0;
      return (0, _floatMap.default)((0, _matchLength.default)(land, this.get('assumption.scenarioLength')));
    }),
    capInvestmentBuildings: Ember.computed('scenario.model.currentCapitalInvestmentBuildings', 'assumption.scenarioLength', function () {
      var buildings = this.get('scenario.model.currentCapitalInvestmentBuildings') || 0;
      return (0, _floatMap.default)((0, _matchLength.default)(buildings, this.get('assumption.scenarioLength')));
    }),
    capInvestmentFurniture: Ember.computed('scenario.model.currentCapitalInvestmentFurniture', 'assumption.scenarioLength', function () {
      var furniture = this.get('scenario.model.currentCapitalInvestmentFurniture') || 0;
      return (0, _floatMap.default)((0, _matchLength.default)(furniture, this.get('assumption.scenarioLength')));
    }),

    // note: this property works differently from those above in that it is cascading and represents a percent increase of 0% over the scenario length
    taxableInventories: Ember.computed('scenario.model.currentCapitalInvestmentInventory', 'assumption.scenarioLength', function () {
      var inventory = this.get('scenario.model.currentCapitalInvestmentInventory') || 0;
      return (0, _floatMap.default)((0, _matchLength.default)(inventory, this.get('assumption.scenarioLength'), true));
    }),

    taxableSales: Ember.computed('scenario.model.currentTaxableSales', 'assumption.scenarioLength', function () {
      var inventory = this.get('scenario.model.currentTaxableSales') || 0;
      return (0, _floatMap.default)((0, _matchLength.default)(inventory, this.get('assumption.scenarioLength'), true));
    }),

    taxablePurchases: Ember.computed('scenario.model.currentTaxablePurchases', 'assumption.scenarioLength', function () {
      var inventory = this.get('scenario.model.currentTaxablePurchases') || 0;
      return (0, _floatMap.default)((0, _matchLength.default)(inventory, this.get('assumption.scenarioLength'), true));
    }),

    hotelNights: Ember.computed('scenario.model.currentHotelNights', 'assumption.scenarioLength', function () {
      var inventory = this.get('scenario.model.currentHotelNights') || 0;
      return (0, _floatMap.default)((0, _matchLength.default)(inventory, this.get('assumption.scenarioLength'), true));
    }),

    // none present for current operations
    fiBuildingPermitsFees: Ember.computed(function () {
      return _base.default.create({
        project: 0,
        workers: 0
      });
    }),

    // also no direct cost/revs
    directDistrictCostRevenues: Ember.computed('scenario.{activeDirectCostRevenues.@each,model.directCostRevenues,directCostRevenuesChanged}', 'district.directCostRevenues.@each', function () {
      var activeDirectCostRevenues = this.get('scenario.activeDirectCostRevenues') || [],
          districtCostRevenues = this.get('district.directCostRevenues') || [],
          years = this.get('assumption.scenarioLength'),
          filteredCostRevenues = void 0;

      // find only cost revs for this district
      filteredCostRevenues = activeDirectCostRevenues.filter(function (costRev) {
        return districtCostRevenues.findBy('id', costRev.get('id'));
      });

      // create fi tax object for each cost and revenue
      filteredCostRevenues = filteredCostRevenues.map(function (costRev) {
        var annual = (0, _arrayOfLength.default)(years);
        return Ember.Object.create({
          name: costRev.get('name') || 'Untitled Direct ' + Ember.String.capitalize(costRev.get('type')),
          directCostRevenue: (0, _floatMap.default)(annual),
          doesApply: costRev.get('doesApplyDirectCostRevenue'),
          type: costRev.get('type'),
          project: 0,
          workers: 0
        });
      });

      return Ember.Object.create({
        revenues: filteredCostRevenues.filterBy('type', 'revenue'),
        costs: filteredCostRevenues.filterBy('type', 'cost')
      });
    })

  });

  /* Utils */
  exports.default = CurrentImpactEngine;
});