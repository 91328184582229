define('dashboard/authenticators/demo', ['exports', 'dashboard/authenticators/custom'], function (exports, _custom) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _custom.default.extend({

    ajax: Ember.inject.service(),

    authenticate: function authenticate(creds) {

      var ajax = this.get('ajax');

      return new Ember.RSVP.Promise(function (resolve, reject) {
        ajax.post('/demo', {
          data: JSON.stringify({
            firstName: creds.firstName,
            lastName: creds.lastName,
            organization: creds.organization,
            email: creds.email
          }),
          contentType: 'application/json'
        }).then(function (response) {
          Ember.run(function () {
            resolve({ token: response.login.token });
          });
        }).catch(function (error) {
          // Return error as XHR object
          Ember.run(function () {
            reject(error);
          });
        });
      });
    }

  });
});