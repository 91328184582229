define('dashboard/utils/match-length', ['exports', 'dashboard/utils/array-of-length'], function (exports, _arrayOfLength) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var matchLength = function matchLength(property, length, cascading) {

    var arr = [];
    property = Array.isArray(property) ? property : [property];

    // given array is too long
    if (property.get('length') > length) {
      return property.slice(0, length);
    }

    // given array is too short
    var fillerValue = cascading ? property[0] : 0;
    // fill array to scenario length starting with given value, then either inserting
    // zeros or the give value repeating if cascading
    arr = property.concat((0, _arrayOfLength.default)(length - property.length, fillerValue));

    return arr;
  }; /**
      * Utility - Match (Scenario) Length
      *
      * Returns an array of a specific length by either slicing or adding zero values
      *
      * @param {property} single value or array to be placed at start of new array
      * @param {length} length of array to match, either by slicing of filling
      * @param {cascading} boolean telling us whether to fill with zeros or with same value over array length
      *
      * @method matchLength
      */

  exports.default = matchLength;
});