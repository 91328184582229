define('dashboard/components/forms/optional-district-group-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    saveDisabled: Ember.computed('client.optionalGroups.length', function () {
      return !this.get('client.optionalGroups.length');
    }),

    actions: {
      selectOptionalGroups: function selectOptionalGroups(selectedGroups) {
        this.set('client.optionalGroups', selectedGroups);
      }
    }
  });
});