define('dashboard/objects/ei-taxable-spending-workers', ['exports', 'dashboard/objects/array-compute', 'dashboard/utils/percent-each'], function (exports, _arrayCompute, _percentEach) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var EiTaxableSpendingWorkersObject = _arrayCompute.default.extend({

    direct: Ember.computed('directInput', 'percentOne', 'percentTwo', function () {

      var input = this.get('directInput') || [],
          percentOne = this.get('percentOne') || 0,
          percentTwo = this.get('percentTwo') || 0;

      return (0, _percentEach.default)(input, [percentOne, percentTwo]);
    }),

    indirect: Ember.computed('indirectInput', 'percentOne', 'percentTwo', function () {

      var input = this.get('indirectInput') || [],
          percentOne = this.get('percentOne') || 0,
          percentTwo = this.get('percentTwo') || 0;

      return (0, _percentEach.default)(input, [percentOne, percentTwo]);
    })

  });

  exports.default = EiTaxableSpendingWorkersObject;
});