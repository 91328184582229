define('dashboard/mixins/state-taxes', ['exports', 'dashboard/fixtures/state-tax-profiles'], function (exports, _stateTaxProfiles) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({

    // TODO: deal with salesTax
    stateTaxes: Ember.computed('client.state', function () {
      var clientState = this.get('client.state');
      if (clientState) {
        return _stateTaxProfiles.default[clientState];
      } else {
        return {
          code: 'DEFAULT',
          land: true,
          buildings: true,
          ffe: true,
          inventory: true,
          salesTaxForLocalClient: true,
          salesTaxForStateClient: true
        };
      }
    }),

    showSalesTax: Ember.computed('stateTaxes.{salesTaxForLocalClient,salesTaxForStateClient}', 'client.assumption.salesTaxLocalOnly', function () {
      var localOnly = this.get('client.assumption.salesTaxLocalOnly');
      var localTax = this.get('stateTaxes.salesTaxForLocalClient');
      var stateTax = this.get('stateTaxes.salesTaxForStateClient');
      return localOnly ? localTax : localTax || stateTax;
    })

  });
});