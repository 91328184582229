define('dashboard/components/ids-popover', ['exports', 'dashboard/utils/create-popover-id'], function (exports, _createPopoverId) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: ['classes', 'indentOptions'],

    init: function init() {
      this._super.apply(this, arguments);
      this.set('localTargetId', (0, _createPopoverId.default)());
    },


    classes: '',
    hoverText: '',
    faSize: 'lg',
    iconClass: '',
    width: 70,

    renderIcon: Ember.computed.bool('iconClass'),

    targetId: Ember.computed('id', function () {
      var customTargetId = this.get('id');
      var localTargetId = this.get('localTargetId');
      return customTargetId || localTargetId;
    }),

    indentOptions: Ember.computed('hoverText', 'options.length', function () {
      return Boolean(this.get('hoverText') && this.get('options.length'));
    }),

    formattedHoverText: Ember.computed('indentOptions', function () {
      var hoverText = this.get('hoverText');
      var suffix = this.get('options.length') ? ':' : '';
      return '' + hoverText + suffix;
    }),

    actions: {
      sendParentAction: function sendParentAction(actionName) {
        this.sendAction('actionHandler', actionName);
      }
    }
  });
});