define('dashboard/objects/fi-tax/new-residential-property-taxes', ['exports', 'dashboard/objects/fi-tax/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    residentialPropertyTax: Ember.computed('newResidentialPropertyValue', 'residentialPropertyTaxRate', 'residentialPropertyTaxRateApplied', function () {
      var newResidentialPropertyValue = this.get('newResidentialPropertyValue'),
          residentialPropertyTaxRate = this.get('residentialPropertyTaxRate'),
          residentialPropertyTaxRateApplied = this.get('residentialPropertyTaxRateApplied'),
          doesApply = this.get('doesApplyTax');

      return newResidentialPropertyValue.map(function (value) {

        if (doesApply) {
          return value * residentialPropertyTaxRate / residentialPropertyTaxRateApplied;
        } else {
          return 0;
        }
      });
    }),

    annual: Ember.computed.alias('residentialPropertyTax'),

    workers: Ember.computed('residentialPropertyTax', function () {
      var residentialPropertyTax = this.get('residentialPropertyTax');

      return residentialPropertyTax.reduce(function (sum, num) {
        return sum + (isNaN(num) ? 0 : num);
      }, 0);
    })
  });
});