define('dashboard/models/scenario', ['exports', 'ember-data/model', 'ember-data/attr', 'dashboard/utils/sum-array', 'ember-data/relationships', 'lodash', 'dashboard/utils/not-null', 'dashboard/utils/validations', 'dashboard/utils/appreciation-schedule', 'dashboard/utils/array-of-length', 'dashboard/utils/float-map'], function (exports, _model, _attr, _sumArray, _relationships, _lodash, _notNull, _validations, _appreciationSchedule, _arrayOfLength, _floatMap) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  exports.default = _model.default.extend({
    districts: (0, _relationships.hasMany)('taxDistrict'),
    project: (0, _relationships.belongsTo)('project'),
    requestTemplate: (0, _relationships.belongsTo)(),
    client: (0, _relationships.belongsTo)(),
    multiplier: (0, _relationships.belongsTo)('multiplier'),
    incentiveSchedules: (0, _relationships.hasMany)('incentiveSchedule'),
    assumption: (0, _relationships.belongsTo)('assumption'),
    tifDistrict: (0, _relationships.belongsTo)('tifDistrict'),
    scenarioDataRequest: (0, _relationships.belongsTo)('scenarioDataRequest'), // legacy but still used
    fileUploads: (0, _relationships.hasMany)(),
    includedOptionalGroups: (0, _relationships.hasMany)('districtGroup', { inverse: null }),
    contact: (0, _relationships.belongsTo)('user'),
    createdBy: (0, _relationships.belongsTo)('user'),
    cc: (0, _relationships.hasMany)("user"),

    createdFromId: (0, _attr.default)('string'),
    // used when copying data forms for revision
    revisionOfScenario: (0, _relationships.belongsTo)('scenario', { inverse: null }),

    created: (0, _attr.default)('date'),
    lastUpdated: (0, _attr.default)('date'),
    subtitle: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    internalNotes: (0, _attr.default)('string'),
    businessType: (0, _attr.default)('string', { defaultValue: 'new' }),
    willExpand: (0, _attr.default)('boolean', { defaultValue: true }),

    dueDiligence: (0, _attr.default)('string'),
    communityFit: (0, _attr.default)('string'),
    communityFitLabel: (0, _attr.default)('string'),
    recommendations: (0, _attr.default)('string'),
    recommendationsLabel: (0, _attr.default)('string'),
    companyNotes: (0, _attr.default)('string'),
    companyNotesLabel: (0, _attr.default)('string'),

    prospectName: (0, _attr.default)('string'),
    prospectEmail: (0, _attr.default)('string'),
    prospectTitle: (0, _attr.default)('string'),

    includeSpinoffImpacts: (0, _attr.default)('boolean', { defaultValue: true }),
    multipliersAreMatched: (0, _attr.default)('boolean', { defaultValue: true }),

    employmentMultiplier: (0, _attr.default)('string', { defaultValue: '1' }),
    earningsMultiplier: (0, _attr.default)('string', { defaultValue: '1' }),
    usesCustomMultipliers: (0, _attr.default)('boolean', { defaultValue: false }),

    projectPlans: (0, _attr.default)({ defaultValue: function defaultValue() {
        return [];
      } }),
    leaseTermInYears: (0, _attr.default)('string'),

    localService: (0, _attr.default)('string'),
    mainService: (0, _attr.default)('string'),
    multiplierCode: (0, _attr.default)('string'),

    beginRenovationsDate: (0, _attr.default)('string'),
    beginLocalOperationsDate: (0, _attr.default)('string'),

    siteRequirements: (0, _attr.default)('string'),
    siteSquareFootage: (0, _attr.default)({ defaultValue: function defaultValue() {
        return {};
      } }),

    benefitTypes: (0, _attr.default)({ defaultValue: function defaultValue() {
        return [];
      } }),
    benefitsAvgAnnualValue: (0, _attr.default)('number'),
    benefitsAdditionalNotes: (0, _attr.default)('string'),

    localEmployees: (0, _attr.default)('number'),
    stateEmployees: (0, _attr.default)('number'),
    usaEmployees: (0, _attr.default)('number'),
    worldEmployees: (0, _attr.default)('number'),

    usesModifiedAssumptions: (0, _attr.default)('boolean', { defaultValue: false }),

    employmentDetails: (0, _attr.default)({ defaultValue: function defaultValue() {
        return [{}, {}, {}, {}, {}].map(function () {
          return {
            position: '',
            numberEmployees: null,
            averageSalary: null
          };
        });
      } }),
    jobsAddedEachYear: (0, _attr.default)({ defaultValue: function defaultValue() {
        return ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0'];
      } }),
    averageAnnualSalaries: (0, _attr.default)({ defaultValue: function defaultValue() {
        return ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0'];
      } }),
    averageAnnualSalariesIncrease: (0, _attr.default)('string', { defaultValue: '0.02' }),

    percentNewDirectWorkersMoving: (0, _attr.default)('string'),
    usesCustomPercentNewDirectWorkersMoving: (0, _attr.default)('boolean', { defaultValue: false }),

    capitalInvestmentLand: (0, _attr.default)({ defaultValue: function defaultValue() {
        return [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      } }),
    capitalInvestmentBuildings: (0, _attr.default)({ defaultValue: function defaultValue() {
        return [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      } }),
    capitalInvestmentFurniture: (0, _attr.default)({ defaultValue: function defaultValue() {
        return [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      } }),
    capitalInvestmentInventory: (0, _attr.default)({ defaultValue: function defaultValue() {
        return [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      } }),

    buildingPermitsFees: (0, _attr.default)({ defaultValue: function defaultValue() {
        return {};
      } }),

    taxableInventories: (0, _attr.default)({ defaultValue: function defaultValue() {
        return ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0'];
      } }),
    taxableInventoriesAnnualIncrease: (0, _attr.default)('string', { defaultValue: '0.02' }),

    taxablePurchases: (0, _attr.default)({ defaultValue: function defaultValue() {
        return ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0'];
      } }),
    taxablePurchasesPercentIncrease: (0, _attr.default)('string', { defaultValue: '0.02' }),
    taxableSales: (0, _attr.default)({ defaultValue: function defaultValue() {
        return ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0'];
      } }),
    taxableSalesPercentIncrease: (0, _attr.default)('string', { defaultValue: '0.02' }),
    firstYearTaxableSalesAndPurchases: Ember.computed('taxableSales.0', 'taxablePurchases.0', function () {
      return parseFloat(this.get('taxableSales.0')) + parseFloat(this.get('taxablePurchases.0'));
    }),

    expectedHotelNights: (0, _attr.default)('number'),
    hotelNights: (0, _attr.default)({ defaultValue: function defaultValue() {
        return [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      } }),
    hotelNightsIncrease: (0, _attr.default)('string', { defaultValue: '0.00' }),
    hotelDailyRate: (0, _attr.default)({ defaultValue: function defaultValue() {
        return [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      } }),
    hotelDailyRateIncrease: (0, _attr.default)('string', { defaultValue: '0.02' }),
    spendingPerHotelNight: (0, _attr.default)({ defaultValue: function defaultValue() {
        return [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      } }),
    spendingPerHotelNightIncrease: (0, _attr.default)('string', { defaultValue: '0.02' }),

    // District Areas
    districtAreaGroup: (0, _attr.default)('string'),
    districtArea: (0, _relationships.belongsTo)('districtArea'),

    // Dependent Districts
    primaryDependentDistrict: (0, _relationships.belongsTo)('taxDistrict', { inverse: null }),

    getRightPrimaryDependentDistrictId: function getRightPrimaryDependentDistrictId() {
      // if primary dependent district is modified, we want to return the original district's id so this can be used when getting all dependent districts,
      // which won't have reference to the modified copy of this district
      var primaryDistrict = this.get('primaryDependentDistrict');
      var copyOfDistrictId = primaryDistrict.get('copyOfDistrict'); // if this is populated, then this district is modified as this is a reference back to original
      return copyOfDistrictId ? copyOfDistrictId : primaryDistrict.get('id');
    },


    directCostRevenues: (0, _attr.default)({ defaultValue: function defaultValue() {
        return {};
      } }),

    finalized: (0, _attr.default)('boolean'),
    isAdminUnlocked: (0, _attr.default)('boolean', { defaultValue: true }),
    isEconomistReviewed: (0, _attr.default)('boolean', { defaultValue: false }),
    walkthroughCompleted: (0, _attr.default)('boolean'),
    districtsTransitioned: (0, _attr.default)('date'),

    hasRequestedReview: (0, _attr.default)('boolean'),
    lastRequestReview: (0, _attr.default)('date'),

    summarySelectedDistrict: (0, _attr.default)('string'), // district id

    usesTifDistrict: (0, _attr.default)('boolean', { defaultValue: false }),
    tifDuration: (0, _attr.default)('string'),

    isCopyingScenario: (0, _attr.default)('boolean', { defaultValue: false }),

    communityIncentiveGroupIds: (0, _attr.default)({ defaultValue: function defaultValue() {
        return [];
      } }),
    communityIncentiveIncludesTif: (0, _attr.default)('boolean', { defaultValue: false }),

    communityReportDistrictIds: (0, _attr.default)({ defaultValue: function defaultValue() {
        return [];
      } }),
    visitedCommunityReport: (0, _attr.default)('boolean', { defaultValue: false }),
    communityReportIncludesTif: (0, _attr.default)('boolean', { defaultValue: false }),

    publicSupportOverview: (0, _attr.default)('string'),
    submitterEmail: (0, _attr.default)('string'),
    signature: (0, _attr.default)('string'),
    subject: (0, _attr.default)('string'),
    message: (0, _attr.default)('string'),
    submittedOn: (0, _attr.default)('date'),
    note: (0, _attr.default)('string'), // additional background
    noNotes: Ember.computed.not('note'),

    fromAnonymousLink: (0, _attr.default)('boolean'),
    token: (0, _attr.default)('string'),

    requestAcknowledgementChecked: (0, _attr.default)('boolean'),
    // these fields belong to the person filling out a data form
    // they are unrelated to the contact property
    contactName: (0, _attr.default)('string'),
    contactTitle: (0, _attr.default)('string'),
    contactPhone: (0, _attr.default)('string'),
    contactEmail: (0, _attr.default)('string'),

    companyName: (0, _attr.default)('string'),
    confidentialIdentity: (0, _attr.default)('boolean', { defaultValue: true }),
    companyAddress: (0, _attr.default)('string'),
    companyCity: (0, _attr.default)('string'),
    companyState: (0, _attr.default)('string'),
    companyZip: (0, _attr.default)('string'),
    companyWebsite: (0, _attr.default)('string'),
    yearEstablished: (0, _attr.default)('string'),
    ownershipType: (0, _attr.default)('string'),
    businessStructure: (0, _attr.default)('string'),
    parentCompanyName: (0, _attr.default)('string'),

    projectLocation: (0, _attr.default)('string'),
    background: (0, _attr.default)('string'),

    currentSiteAddress: (0, _attr.default)('string'),
    currentSiteOwnership: (0, _attr.default)({ defaultValue: function defaultValue() {
        return [];
      } }),
    currentLeaseEnds: (0, _attr.default)('string'),

    currentSiteSquareFootage: (0, _attr.default)({ defaultValue: function defaultValue() {
        return {};
      } }),
    currentLocalService: (0, _attr.default)('string'),
    currentMultiplier: (0, _relationships.belongsTo)('multiplier'),
    currentMultiplierCode: (0, _attr.default)('string'),
    currentMultiplierCodeNumberOnly: Ember.computed('currentMultiplierCode', function () {
      var code = this.get('currentMultiplierCode') || this.get('currentMultiplier.code');
      return code && code.split(' ')[0];
    }),
    currentEmploymentMultiplier: (0, _attr.default)('string', { defaultValue: '1' }),
    currentEarningsMultiplier: (0, _attr.default)('string', { defaultValue: '1' }),
    currentUsesCustomMultipliers: (0, _attr.default)('boolean', { defaultValue: false }),

    currentJobs: (0, _attr.default)('number', { defaultValue: 0 }),
    currentAverageSalary: (0, _attr.default)('number', { defaultValue: 0 }),

    currentCapitalInvestmentLand: (0, _attr.default)('number', { defaultValue: 0 }),
    currentCapitalInvestmentBuildings: (0, _attr.default)('number', { defaultValue: 0 }),
    currentCapitalInvestmentFurniture: (0, _attr.default)('number', { defaultValue: 0 }),
    currentCapitalInvestmentInventory: (0, _attr.default)('number', { defaultValue: 0 }),

    currentTaxableSales: (0, _attr.default)('number', { defaultValue: 0 }),
    currentTaxablePurchases: (0, _attr.default)('number', { defaultValue: 0 }),
    currentHotelNights: (0, _attr.default)('number', { defaultValue: 0 }),

    // can be current, expansion, total
    currentVsExpansion: (0, _attr.default)('string', { defaultValue: 'expansion' }),
    isTotalSelected: Ember.computed.equal('currentVsExpansion', 'total'),

    isRequest: (0, _attr.default)('boolean'),
    isRequestBackupOf: (0, _attr.default)('string'), // means this scenario is snapshot of submitted data form only to be used internally
    visitedSections: (0, _attr.default)({ defaultValue: function defaultValue() {
        return {};
      } }),
    visitedDataForm: (0, _attr.default)('boolean', { defaultValue: false }),
    requestVersion: (0, _attr.default)('number', { defaultValue: 0 }),
    // shows data form as deleted but it's still possible to delete scenarios in other places
    deletedDataForm: (0, _attr.default)('boolean', { defaultValue: false }),
    // if data form length is manually changed, always use assumption.scenarioLength instead of template dataFormLength
    dataFormLengthChanged: (0, _attr.default)('boolean', { defaultValue: false }),

    startedOn: (0, _attr.default)('date'),
    isLinkEmailSent: Ember.computed.bool('startedOn'),

    essentialsScenario: (0, _attr.default)('boolean', { defaultValue: false }),

    notReviewed: Ember.computed.not('isEconomistReviewed'),
    isFinalizedButNotReviewed: Ember.computed.and('finalized', 'notReviewed'),

    isEmailedDone: Ember.computed.reads('submittedOn'),

    emailNotDone: Ember.computed.not('isEmailedDone'),
    isEmailedInProgress: Ember.computed.and('isRequest', 'emailNotDone'),

    showSalesTax: Ember.computed.alias('requestTemplate.showSalesTax'),

    displayAsUnread: Ember.computed('submittedOn', 'project.id', 'visitedDataForm', function () {
      if (!this.get('submittedOn')) return false; // in progress should never show as new
      if (this.get('deletedDataForm')) return false; // deleted/archived data forms should never show as new
      if (this.get('project.id')) return false; // assigned should never show as new

      return !this.get('visitedDataForm'); // new if never visited data form
    }),

    formattedUpdatedOn: Ember.computed('lastUpdated', function () {
      var lastUpdated = this.get('lastUpdated');
      return lastUpdated ? moment(lastUpdated).format('ll') : '';
    }),

    activeBreadcrumbText: Ember.computed('inboxScreen', 'submittedOn', 'created', function () {
      var verb = 'Submitted';
      var date = moment(this.get('submittedOn')).format('ll');

      if (this.get('inboxScreen') === 'in-progress') {
        verb = 'Started';
        date = moment(this.get('created')).format('ll');
      }
      return 'Data Form ' + verb + ' ' + date;
    }),

    activeSchedules: Ember.computed('incentiveSchedules.@each.{isActive,districtGroup,forTifDistrict,forCommunityIncentive}', function () {
      return this.get('incentiveSchedules').filter(function (schedule) {
        return schedule.get('isActive') && schedule.get('forTifDistrict') == false && schedule.get('forCommunityIncentive') == false;
      }).sortBy('districtGroup.groupOrder');
    }),

    tifIncentive: Ember.computed('incentiveSchedules.@each.forTifDistrict', function () {
      return this.get('incentiveSchedules').findBy('forTifDistrict');
    }),

    communityIncentiveSchedule: Ember.computed('incentiveSchedules.@each.forCommunityIncentive', function () {
      return this.get('incentiveSchedules').findBy('forCommunityIncentive', true);
    }),

    summaryDistrict: Ember.computed('summarySelectedDistrict', 'districts', function () {
      return this.get('districts').findBy('id', this.get('summarySelectedDistrict'));
    }),

    propertyTaxRateTotal: Ember.computed('districts.@each.{doesApplyPropertyTaxRateLand,doesApplyPropertyTaxRateBuildings,doesApplyPropertyTaxRateFFE,doesApplyPropertyTaxRateResidential,propertyTaxRateLand,propertyTaxRateBuildings,propertyTaxRateFFE,propertyTaxRateResidential}', function () {
      var districts = this.get('districts'),
          districtRates = void 0;

      districtRates = districts.map(function (district) {
        if (district.get('doesApplyPropertyTaxRateLand')) {
          return parseFloat(district.get('propertyTaxRateLand'));
        } else if (district.get('doesApplyPropertyTaxRateBuildings')) {
          return parseFloat(district.get('propertyTaxRateBuildings'));
        } else if (district.get('doesApplyPropertyTaxRateFFE')) {
          return parseFloat(district.get('propertyTaxRateFFE'));
        } else if (district.get('doesApplyPropertyTaxRateResidential')) {
          return parseFloat(district.get('propertyTaxRateResidential'));
        } else {
          return 0;
        }
      });

      return (0, _sumArray.default)(districtRates);
    }),

    getScheduleForDistrict: function getScheduleForDistrict(group) {
      if (!group) {
        // if there is no group here, something is probably wrong but at least returning null will keep the app from freezing
        return null;
      }

      return this.get('incentiveSchedules').find(function (schedule) {
        return schedule.get('districtGroup.id') === group.get('id');
      });
    },

    getDistrictForSchedule: function getDistrictForSchedule(schedule) {
      if (!schedule) {
        return null;
      }

      var foundDistrict = this.get('districts').find(function (district) {
        return schedule.get('districtGroup.districts').findBy('id', district.get('id'));
      });

      if (!foundDistrict) {
        foundDistrict = this.get('districts').find(function (district) {
          return schedule.get('districtGroup.previousDistricts').findBy('id', district.get('id'));
        });
      }

      // if still no found district here, scearnio is likely using transitioned districts or modidified districts
      if (!foundDistrict) {
        foundDistrict = this.get('districts').find(function (district) {
          return district.get('districtGroups').findBy('id', schedule.get('districtGroup.id'));
        });
      }

      return foundDistrict;
    },

    getDistrictForGroup: function getDistrictForGroup(group) {
      if (!group) {
        return null;
      }
      return this.get('districts').find(function (scenarioDistrict) {
        return group.get('districts').find(function (groupDistrict) {
          return scenarioDistrict.get('id') === groupDistrict.get('id');
        });
      });
    },

    getPrimarySchedule: function getPrimarySchedule() {
      return this.get('activeSchedules').sortBy('districtGroup.groupOrder').get('firstObject') || this.get('activeSchedules.firstObject');
    },

    getSelectedSchedule: function getSelectedSchedule(groups) {
      var _this = this;

      var districtId = this.get('summarySelectedDistrict'); // district id
      var district = this.get('districts').findBy('id', districtId);

      if (!district) {
        return this.getPrimarySchedule();
      }

      var group = groups.find(function (group) {
        var byGroupRelation = group.get('districts').findBy('id', districtId);
        var byDistrictRelation = district.get('districtGroups').findBy('id', group.get('id'));
        return byGroupRelation || byDistrictRelation;
      });

      if (!group) {
        return this.getPrimarySchedule();
      }

      // return a single incentve schedule from activeSchedules array
      return this.get('activeSchedules').find(function (schedule) {
        return schedule.get('scenario.id') === _this.get('id') && schedule.get('districtGroup.id') === group.get('id');
      });
    },

    setCurrentMultipliersFromExpansion: function setCurrentMultipliersFromExpansion() {
      this.set('currentMultiplier', this.get('multiplier'));
      this.set('currentEmploymentMultiplier', this.get('employmentMultiplier'));
      this.set('currentEarningsMultiplier', this.get('earningsMultiplier'));
      this.set('currentUsesCustomMultiplier', this.get('usesCustomMultipliers'));
      this.set('currentMultiplierCode', this.get('multiplierCode'));
    },
    setExpansionMultiplierFromCurrent: function setExpansionMultiplierFromCurrent() {
      this.set('multiplier', this.get('currentMultiplier'));
      this.set('employmentMultiplier', this.get('currentEmploymentMultiplier'));
      this.set('earningsMultiplier', this.get('currentEarningsMultiplier'));
      this.set('usesCustomMultipliers', this.get('currentUsesCustomMultiplier'));
      this.set('multiplierCode', this.get('currentMultiplierCode'));
    },
    updateMatchingMultipliers: function updateMatchingMultipliers() {
      if (!this.get('multipliersAreMatched')) {
        return;
      }

      // if we have expansion multiplier, keep current multiplier in sync
      if (this.get('multiplier.content')) {
        this.setCurrentMultipliersFromExpansion();
      }

      // if we have current multiplier with no expansion multiplier
      if (this.get('currentMultiplier.content') && !this.get('multiplier.content')) {
        this.setExpansionMultiplierFromCurrent();
      }
    },
    prefillMultipliers: function prefillMultipliers() {
      if (!this.get('multiplier.content')) {
        this.set('multiplier', this.get('currentMultiplier'));
      }

      this.set('employmentMultiplier', this.get('employmentMultiplier') || this.get("currentEmploymentMultiplier"));
      this.set('earningsMultiplier', this.get('earningsMultiplier') || this.get("currentEarningsMultiplier"));
      this.set('usesCustomMultipliers', this.get('usesCustomMultipliers') || this.get("currentUsesCustomMultiplier"));
      this.set('multiplierCode', this.get('multiplierCode') || this.get("currentMultiplierCode"));

      this.save();
    },


    setDefaultHotelProperties: function setDefaultHotelProperties(scenario, assumption) {
      //set default hotel properties
      var scenarioLength = assumption.get('scenarioLength');
      var rateIncrease = scenario.get('hotelDailyRateIncrease') || 0.02;
      var roomRate = assumption.get('hotelRoomRate') || 0;
      var hotelDailyRate = [roomRate].concat((0, _arrayOfLength.default)(scenarioLength - 1, 0));
      hotelDailyRate = (0, _appreciationSchedule.default)(hotelDailyRate, rateIncrease);
      scenario.set('hotelDailyRate', hotelDailyRate);
      var spendingIncrease = scenario.get('spendingPerHotelNightIncrease') || 0.02;
      var visitorSpending = assumption.get('dailyTaxableVisitorSpending') || 0;
      var spendingPerHotelNight = [visitorSpending].concat((0, _arrayOfLength.default)(scenarioLength - 1, 0));
      spendingPerHotelNight = (0, _appreciationSchedule.default)(spendingPerHotelNight, spendingIncrease);
      scenario.set('spendingPerHotelNight', spendingPerHotelNight);
    },

    // This is computed based on every single property
    // For organization and to save space, they're arrange with one section per line
    // Once we have tracked properties this may get simpler
    fieldsComplete: Ember.computed('requestTemplate.fieldsRequested.[]', 'prospectName', 'prospectEmail', 'prospectTitle', 'contactName', 'contactEmail', 'contactTitle', 'description', 'businessType', 'projectPlans', 'leaseTermInYears', 'beginRenovationsDate', 'beginLocalOperationsDate', 'projectLocation', 'siteRequirements', 'siteSquareFootage', 'localService', 'mainService', 'multiplier', 'employmentDetails', 'jobsAddedEachYear', 'averageAnnualSalaries', 'benefitTypes', 'benefitsAvgAnnualValue', 'benefitsAdditionalNotes', 'capitalInvestmentLand', 'capitalInvestmentBuildings', 'capitalInvestmentFurniture', 'capitalInvestmentInventory', 'taxableInventories', 'taxableSales', 'taxablePurchase', 'expectedHotelNights', 'hotelNights', 'hotelDailyRate', 'spendingPerHotelNight', 'companyName', 'confidentialIdentity', 'companyAddress', 'companyWebsite', 'yearEstablished', 'businessStructure', 'ownershipType', 'parentCompanyName', 'localEmployees', 'stateEmployees', 'usaEmployees', 'worldEmployees', 'note', 'fileUploads.[]', 'contactPhone', 'currentSiteAddress', 'currentSiteOwnership', 'currentLeaseEnds', 'currentSiteSquareFootage', 'currentLocalService', 'currentMultiplier', 'currentJobs', 'currentAverageSalary', 'currentCapitalInvestmentLand', 'currentCapitalInvestmentBuildings', 'currentCapitalInvestmentFurniture', 'currentCapitalInvestmentInventory', 'currentTaxableSales', 'currentTaxablePurchases', 'currentHotelNights', function () {
      var _this2 = this;

      var fieldsDisplayed = this.get('requestTemplate.fieldsRequested');
      return fieldsDisplayed.filter(function (field) {
        return _this2.dataPropertyNotNull(field);
      });
    }),
    numberFieldsComplete: Ember.computed.alias('fieldsComplete.length'),
    numberFieldsRequested: Ember.computed.alias('requestTemplate.numberFieldsRequested'),

    isExistingBusiness: Ember.computed.equal('businessType', 'existing'),
    isNewBusiness: Ember.computed.equal('businessType', 'new'),
    businessTypeLong: Ember.computed('businessType', function () {
      return this.get('isNewBusiness') ? "A <strong>NEW</strong> Business or Development to your Community" : "An <strong>EXISTING</strong> Business Currently Operating in your Community.";
    }),

    percentComplete: Ember.computed('numberFieldsComplete', 'numberFieldsRequested', function () {
      return this.get("numberFieldsComplete") / this.get("numberFieldsRequested");
    }),

    router: Ember.inject.service(),
    dataRequestLink: Ember.computed('id', 'token', function () {
      var _window$location = window.location,
          protocol = _window$location.protocol,
          host = _window$location.host;

      var route = this.get('router').urlFor('data-requests.edit', this.get('id'), this.get('token'));
      return protocol + '//' + host + route;
    }),

    sectionsToDisplay: Ember.computed('requestTemplate.sectionsToDisplay.[]', 'divisions.[]', 'isNewBusiness', function () {
      var sections = this.get("requestTemplate.sectionsToDisplay");
      var divisions = this.get('divisions');
      var keys = Object.keys(divisions);
      var allowedKeys = keys.filter(function (k) {
        return divisions[k] && !divisions[k].locked;
      });
      return sections.filter(function (s) {
        return allowedKeys.includes(s.division);
      });
    }),

    validatedSections: Ember.computed('fieldsComplete.[]', 'requestTemplate.sectionsToDisplay', function () {
      var _this3 = this;

      this.get('fieldsComplete'); //hack to trigger recompute.  Probably won't be needed once we get tracked properties

      var sections = {};
      this.get('requestTemplate.sectionsToDisplay').forEach(function (section) {
        var itemValidityArray = section.content.map(function (item) {
          var itemIsValid = _this3.fieldNotRequired(item) || _this3.dataPropertyNotNull(item) && _this3.dataPropertyValid(item) === true;

          return itemIsValid;
        }).reduce(function (acc, val) {
          return acc.concat(val);
        }, []);
        sections[section.sectionBoolean] = {
          isValid: itemValidityArray.every(function (x) {
            return x;
          }),
          isStarted: itemValidityArray.any(function (x) {
            return x;
          })
        };
      });
      sections['reviewAndSubmit'] = this.get('submittedOn');
      return sections;
    }),

    divisions: Ember.computed('requestTemplate.prospectDivisions.[]', 'willExpand', 'businessType', function () {
      var divisions = this.get('requestTemplate.prospectDivisions');

      var shownDivisions = {};
      var useDivision = function useDivision(key, division) {
        if (division && division.sectionList && division.sectionList.length > 0) {
          shownDivisions[key] = division;
        }
      };

      useDivision('welcome', divisions.welcome);

      if (this.get("isNewBusiness")) {
        useDivision('plans', divisions.plans);
      } else if (this.get("isExistingBusiness")) {
        useDivision('current', divisions.current);
        useDivision('expansion', _extends({}, divisions.expansion, { locked: !this.get('willExpand') }));
      }

      useDivision('support', divisions.support);
      useDivision('company', divisions.company);
      useDivision('review', divisions.review);

      return shownDivisions;
    }),

    validatedDivisions: Ember.computed('validatedSections.[]', 'divisions.[]', function () {
      var _this4 = this;

      var validatedDivisions = {};
      var divisions = this.get("requestTemplate.divisions");
      for (var divisionKey in divisions) {
        var sectionList = divisions[divisionKey].sectionList;
        var validatedSectionList = sectionList && sectionList.map(function (s) {
          return _this4.get('validatedSections.' + s.sectionBoolean);
        });
        if (validatedSectionList) {
          validatedDivisions[divisionKey] = {
            isValid: validatedSectionList.every(function (x) {
              return x && x.isValid;
            }),
            isStarted: validatedSectionList.any(function (x) {
              return x && x.isValid;
            })
          };
        }
      }

      return validatedDivisions;
    }),

    fieldNotRequired: function fieldNotRequired(field) {
      return !this.get('requestTemplate.' + field.name) || field.notRequired;
    },
    dataPropertyValid: function dataPropertyValid(field) {
      if (field.validations) {
        var data = this.get(field.dataProperty);
        if (field.validations.isEmail) {
          return (0, _validations.emailValidation)(data);
        }
      }
      return true;
    },
    dataPropertyNotNull: function dataPropertyNotNull(field) {
      var _this5 = this;

      if (field.columns) {
        return field.columns.any(function (column) {
          return _this5.dataPropertyNotNull(column);
        });
      }

      if (field.rows) {
        return field.rows.any(function (row) {
          return _this5.dataPropertyNotNull(row);
        });
      }

      if (field.dataProperty) {
        var data = this.get(field.dataProperty);
        if (field.dataProperty === 'employmentDetails') {
          return data.any(function (position) {
            return (0, _notNull.default)(position.averageSalary) && (0, _notNull.default)(position.numberEmployees) && (0, _notNull.default)(position.position);
          });
        } else {
          return (0, _notNull.default)(data);
        }
      }
    },


    hasLeasePlans: Ember.computed('projectPlans.[]', function () {
      var acceptableResults = ["Lease space with renovations", "Lease space - build to suit"];
      var plans = this.get("projectPlans");

      return _lodash.default.intersection(plans, acceptableResults);
    }),
    isRenovating: Ember.computed('projectPlans.[]', function () {
      var acceptableResults = ["Lease space with renovations", "Purchase of existing building(s) with renovations"];
      var plans = this.get("projectPlans");

      return _lodash.default.intersection(plans, acceptableResults);
    }),
    hasCurrentLease: Ember.computed('currentSiteOwnership.[]', function () {
      var acceptableResults = ["Lease"];
      var ownerships = this.get("currentSiteOwnership");

      return _lodash.default.intersection(ownerships, acceptableResults);
    }),
    hasBenefits: Ember.computed('benefitTypes.[]', function () {
      return this.get('benefitTypes.length') > 0;
    }),
    notHasBenefits: Ember.computed.not('hasBenefits'),
    capitalInvestmentTotal: Ember.computed('capitalInvestmentBuildings.[]', 'capitalInvestmentFurniture.[]', 'capitalInvestmentLand.[]', 'capitalInvestmentInventory', function () {
      var total = 0;

      if (this.get('requestTemplate.buildingExpenditures')) {
        total += _lodash.default.sum((0, _floatMap.default)(this.get('capitalInvestmentBuildings')));
      }
      if (this.get('requestTemplate.equipmentExpenditures')) {
        total += _lodash.default.sum((0, _floatMap.default)(this.get('capitalInvestmentFurniture')));
      }
      if (this.get('requestTemplate.landExpenditures')) {
        total += _lodash.default.sum((0, _floatMap.default)(this.get('capitalInvestmentLand')));
      }
      if (this.get('requestTemplate.inventoryExpenditures')) {
        total += _lodash.default.sum((0, _floatMap.default)(this.get('capitalInvestmentInventory')));
      }

      return total;
    }),

    inboxScreen: Ember.computed('submittedOn', 'project.title', 'deletedDataForm', function () {
      if (this.get("project.title")) {
        return 'assigned';
      } else if (this.get('deletedDataForm')) {
        return 'archived';
      } else if (this.get('submittedOn')) {
        return 'inbox';
      } else if (this.get('scenarioDataRequest.id')) {
        return 'hidden';
      } else {
        return 'in-progress';
      }
    }),

    currentExpansionHeading: Ember.computed('currentVsExpansion', 'isExistingBusiness', function () {
      if (!this.get('isExistingBusiness')) {
        return '';
      }

      var prefix = '- ';
      var suffix = '';

      switch (this.get('currentVsExpansion')) {
        case 'current':
          suffix = 'Current Only';
          break;
        case 'total':
          suffix = 'Current & Expansion';
          break;
        default:
          suffix = 'Expansion Only';
      }

      suffix = '<span class="nowrap">' + suffix + '</span>';
      return Ember.String.htmlSafe(prefix + suffix);
    }),

    // used when displaying data form emails where we don't have a contact email until user enters
    bestGuessEmail: Ember.computed('contactEmail', 'prospectEmail', 'submitterEmail', function () {
      return this.get('contactEmail') || this.get('prospectEmail') || this.get('submitterEmail');
    }),

    scenarioTitle: Ember.computed('subtitle', 'index', function () {
      var title = this.get('subtitle');
      var prefix = this.get('isRequest') ? 'Request ' : 'Scenario ';

      return title || prefix + (this.get('index') || '');
    })

  });
});