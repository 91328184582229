define("dashboard/utils/negative-each", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  /**
   * Negative Each
   *
   * @param  {Array} inputArray Array of values
   * @return {Array}            Original array with all positive numbers now negative
   *
   * @method  negativeEach
   */
  var negativeEach = function negativeEach(inputArray) {

    return inputArray.map(function (item) {
      item = item <= 0 ? item : item * -1;
      return parseFloat(item);
    });
  };

  exports.default = negativeEach;
});