define('dashboard/components/data-presenter', ['exports', 'dashboard/utils/float-map', 'dashboard/utils/sum-array', 'dashboard/utils/sum-arrays', 'dashboard/utils/sum-many-arrays', 'dashboard/utils/cumulative-sum'], function (exports, _floatMap, _sumArray, _sumArrays, _sumManyArrays, _cumulativeSum) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    propertyName: null,
    tagName: '',

    annualNetBenefitsGraphData: Ember.computed('engine.{annualBenefits,annualCosts}', function () {
      var engine = this.get('engine'),
          costsBenefitsData = [],
          benefits = engine.get('annualBenefits'),
          costs = engine.get('annualCosts');

      benefits.forEach(function (benefit, index) {
        costsBenefitsData.push({
          year: index + 1,
          type: 'benefit',
          value: isNaN(benefit) ? 0 : benefit
        });
      });

      costs.forEach(function (cost, index) {
        costsBenefitsData.push({
          year: index + 1,
          type: 'cost',
          value: isNaN(cost) ? 0 : cost,
          origValue: isNaN(cost) ? 0 : cost
        });
      });

      return costsBenefitsData;
    }),

    annualNetBenefitsLessIncentivesGraphData: Ember.computed('engine.{annualBenefits,annualCosts,incentives,assumption.abatementsAsCosts,incentiveEngine.totalIncentiveSummaryReport}', function () {

      var engine = this.get('engine'),
          benefits = engine.get('annualBenefits'),
          costs = engine.get('annualCosts'),
          incentivesData = [],
          graphData = [],
          incentives = engine.get('incentives').map(function (value) {
        value = parseFloat(value);
        return value === 0 ? 0 : Math.abs(value) * -1;
      });

      benefits.forEach(function (benefit, index) {
        graphData.push({
          year: index + 1,
          type: 'benefit',
          value: isNaN(benefit) ? 0 : benefit,
          origValue: isNaN(benefit) ? 0 : benefit
        });
      });

      // need this because this prop is always being fed to summary report graph
      if (engine.get('assumption.abatementsAsCosts')) {
        incentivesData = (0, _sumArrays.default)(costs, incentives);
        incentivesData.forEach(function (incentive, index) {
          graphData.push({
            year: index + 1,
            type: 'incentive',
            value: isNaN(incentive) ? 0 : Math.abs(incentive) * -1,
            origValue: incentives[index]
          });
        });
      }

      costs.forEach(function (cost, index) {
        graphData.push({
          year: index + 1,
          type: 'cost',
          value: isNaN(cost) ? 0 : cost,
          origValue: costs[index]
        });
      });

      return graphData;
    }),

    annualNetBenefitsTableData: Ember.computed('engine.{annualBenefits,annualCosts}', 'engine.assumption.{startingYear,calendarYearDisplay}', function () {

      var engine = this.get('engine'),
          calendarYearDisplay = engine.get('assumption.calendarYearDisplay'),
          startingYear = calendarYearDisplay ? parseInt(engine.get('assumption.startingYear')) : 1,
          years = parseInt(engine.get('assumption.scenarioLength')),
          benefits = engine.get('annualBenefits'),
          costs = engine.get('annualCosts');

      var output = [],
          i = 0;

      while (i < years) {
        var benefit = benefits[i],
            cost = costs[i],
            net = benefit + cost,
            year = startingYear + i;

        output.push({ year: year, benefit: benefit, cost: cost, net: net });

        i++;
      }

      return output;
    }),

    abatementScheduleTableData: Ember.computed('engine.schedule.{abatementScheduleLand,abatementScheduleBuildings,abatementScheduleFurniture,abatementScheduleInventories,doesApplyAbatementsLand,doesApplyAbatementsBuildings,doesApplyAbatementsFurniture,doesApplyAbatementsInventories}', 'engine.assumption.{scenarioLength,startingYear,calendarYearDisplay}', function () {

      var engine = this.get("engine"),
          landAbatements = engine.get('schedule.abatementScheduleLand'),
          buildingsAbatements = engine.get('schedule.abatementScheduleBuildings'),
          furnitureAbatements = engine.get('schedule.abatementScheduleFurniture'),
          inventoryAbatements = engine.get('schedule.abatementScheduleInventories'),
          doesApplyLand = engine.get('schedule.doesApplyAbatementsLand'),
          doesApplyBuildings = engine.get('schedule.doesApplyAbatementsBuildings'),
          doesApplyFurniture = engine.get('schedule.doesApplyAbatementsFurniture'),
          doesApplyInventories = engine.get('schedule.doesApplyAbatementsInventories'),
          calendarYearDisplay = engine.get('assumption.calendarYearDisplay'),
          startingYear = calendarYearDisplay ? parseInt(engine.get('assumption.startingYear')) : 1;

      return engine.get('propertyTaxAbated.annual').map(function (item, index) {
        return Ember.Object.create({
          land: doesApplyLand ? landAbatements[index] : 0,
          buildings: doesApplyBuildings ? buildingsAbatements[index] : 0,
          furniture: doesApplyFurniture ? furnitureAbatements[index] : 0,
          inventories: doesApplyInventories ? inventoryAbatements[index] : 0,
          year: startingYear + index
        });
      });
    }),

    // Sales Tax Rebates Table Data
    salesTaxRebatesTableData: Ember.computed('engine.{salesTaxRebates.annual,fiSalesTaxTaxableSalesPreRebates}', 'engine.assumption.{startingYear,calendarYearDisplay}', function () {

      var engine = this.get("engine"),
          data = [],
          salesTaxes = (0, _floatMap.default)(engine.get('fiSalesTaxTaxableSalesPreRebates.annual')),
          rebates = (0, _floatMap.default)(engine.get('salesTaxRebates.annual')),
          calendarYearDisplay = engine.get('assumption.calendarYearDisplay'),
          startingYear = calendarYearDisplay ? parseInt(engine.get('assumption.startingYear')) : 1;

      salesTaxes.forEach(function (amount, i) {
        data.push({
          salesTax: isNaN(amount) ? 0 : amount,
          rebate: isNaN(rebates[i]) ? 0 : Math.abs(rebates[i]) * -1,
          net: isNaN(amount - rebates[i]) ? 0 : amount - rebates[i],
          year: startingYear + i
        });
      });

      return data;
    }),

    // Sales Tax Rebates Graph Data
    salesTaxRebatesData: Ember.computed('engine.{salesTaxRebates.annual,fiSalesTaxTaxableSalesPreRebates,scenario.salesTaxIncentivePhrases.singular}', function () {

      var engine = this.get('engine'),
          salesTaxes = (0, _floatMap.default)(engine.get('fiSalesTaxTaxableSalesPreRebates.annual')),
          rebates = (0, _floatMap.default)(engine.get('salesTaxRebates.annual'));

      var rebatesData = [];

      salesTaxes.forEach(function (amount, index) {
        rebatesData.push({
          year: index + 1,
          value: isNaN(amount) ? 0 : amount,
          type: 'sales-tax'
        });
      });

      rebates.forEach(function (rebate, index) {
        rebatesData.push({
          year: index + 1,
          value: isNaN(rebate) ? 0 : Math.abs(rebate) * -1,
          type: 'rebated',
          phrase: engine.get('salesTaxIncentivePhrases.singular'),
          origValue: rebates[index]
        });
      });

      return rebatesData;
    }),

    // Property Tax Abatements Table Data
    abatementsTableData: Ember.computed('engine.propertyTaxLiability.annual', 'engine.propertyTaxAbated.annual', 'engine.propertyTaxNet.annual', 'engine.propertyTaxLandAbated.annual', 'engine.propertyTaxBuildingsAbated.annual', 'engine.propertyTaxFurnitureAbated.annual', 'engine.propertyTaxInventoriesAbated.annual', 'engine.annualPropertyTaxAbated.total', 'engine.assumption.{startingYear,calendarYearDisplay}', function () {

      var engine = this.get('engine'),
          propertyTaxLiability = (0, _floatMap.default)(engine.get('propertyTaxLiability.annual')),
          landAbated = (0, _floatMap.default)(engine.get('propertyTaxLandAbated.annual')),
          buildingsAbated = (0, _floatMap.default)(engine.get('propertyTaxBuildingsAbated.annual')),
          furnitureAbated = (0, _floatMap.default)(engine.get('propertyTaxFurnitureAbated.annual')),
          inventoryAbated = (0, _floatMap.default)(engine.get('propertyTaxInventoriesAbated.annual')),
          totalAbated = (0, _floatMap.default)(engine.get('annualPropertyTaxAbated.total')),
          net = (0, _floatMap.default)(engine.get('propertyTaxNet.annual')),
          calendarYearDisplay = engine.get('assumption.calendarYearDisplay'),
          startingYear = calendarYearDisplay ? parseInt(engine.get('assumption.startingYear')) : 1,
          tableData = [];

      propertyTaxLiability.forEach(function (liability, index) {
        tableData.push({
          liability: isNaN(propertyTaxLiability[index]) ? 0 : propertyTaxLiability[index],
          landAbated: isNaN(landAbated[index]) ? 0 : Math.abs(landAbated[index]) * -1,
          buildingsAbated: isNaN(buildingsAbated[index]) ? 0 : Math.abs(buildingsAbated[index]) * -1,
          furnitureAbated: isNaN(furnitureAbated[index]) ? 0 : Math.abs(furnitureAbated[index]) * -1,
          inventoryAbated: isNaN(inventoryAbated[index]) ? 0 : Math.abs(inventoryAbated[index]) * -1,
          totalAbated: totalAbated[index],
          net: net[index],
          year: startingYear + index
        });
      });

      return tableData;
    }),

    // Property Tax Abatements Graph Data Specifically for Summary Report
    abatementsGraphDataSummary: Ember.computed('engine.propertyTaxLiability.annual', 'engine.propertyTaxAbated.annual', 'engine.propertyTaxNet.annual', 'engine.propertyTaxLandAbated.annual', 'engine.propertyTaxBuildingsAbated.annual', 'engine.propertyTaxFurnitureAbated.annual', 'engine.propertyTaxInventoriesAbated.annual', function () {

      var engine = this.get('engine'),
          propertyTaxLiability = (0, _floatMap.default)(engine.get('propertyTaxLiability.annual')),
          landAbated = (0, _floatMap.default)(engine.get('propertyTaxLandAbated.annual')),
          buildingsAbated = (0, _floatMap.default)(engine.get('propertyTaxBuildingsAbated.annual')),
          furnitureAbated = (0, _floatMap.default)(engine.get('propertyTaxFurnitureAbated.annual')),
          inventoryAbated = (0, _floatMap.default)(engine.get('propertyTaxInventoriesAbated.annual')),
          incentives = (0, _sumManyArrays.default)([landAbated, buildingsAbated, furnitureAbated, inventoryAbated]),
          abatementsData = [];

      propertyTaxLiability.forEach(function (liab, index) {
        abatementsData.push({
          year: index + 1,
          value: isNaN(liab) ? 0 : liab,
          type: 'liability'
        });
      });

      incentives.forEach(function (incentive, index) {
        abatementsData.push({
          year: index + 1,
          value: isNaN(incentive) ? 0 : Math.abs(incentive) * -1,
          type: 'incentive',
          phrase: 'Incentive'
        });
      });

      return abatementsData;
    }),

    // Property Tax Abatements Graph Data
    abatementsGraphData: Ember.computed('engine.propertyTaxLiability.annual', 'engine.propertyTaxAbated.annual', 'engine.propertyTaxNet.annual', 'engine.propertyTaxLandAbated.annual', 'engine.propertyTaxBuildingsAbated.annual', 'engine.propertyTaxFurnitureAbated.annual', 'engine.propertyTaxInventoriesAbated.annual', function () {

      var engine = this.get('engine'),
          propertyTaxLiability = (0, _floatMap.default)(engine.get('propertyTaxLiability.annual')),
          landAbated = (0, _floatMap.default)(engine.get('propertyTaxLandAbated.annual')),
          buildingsAbated = (0, _floatMap.default)(engine.get('propertyTaxBuildingsAbated.annual')),
          furnitureAbated = (0, _floatMap.default)(engine.get('propertyTaxFurnitureAbated.annual')),
          inventoryAbated = (0, _floatMap.default)(engine.get('propertyTaxInventoriesAbated.annual'));

      var abatementsData = [],
          buildings = [],
          furniture = [],
          inventory = [];

      propertyTaxLiability.forEach(function (liab, index) {
        abatementsData.push({
          year: index + 1,
          value: isNaN(liab) ? 0 : liab,
          type: 'liability'
        });
      });

      // This order is important as it will ensure proper z-indices for abatement graph data
      inventory = (0, _sumManyArrays.default)([landAbated, buildingsAbated, furnitureAbated, inventoryAbated]);
      inventory.forEach(function (abatement, index) {
        abatementsData.push({
          year: index + 1,
          value: isNaN(abatement) ? 0 : Math.abs(abatement) * -1,
          type: 'inventory',
          phrase: 'Inventory',
          origValue: inventoryAbated[index]
        });
      });

      furniture = (0, _sumManyArrays.default)([landAbated, buildingsAbated, furnitureAbated]);
      furniture.forEach(function (abatement, index) {
        abatementsData.push({
          year: index + 1,
          value: isNaN(abatement) ? 0 : Math.abs(abatement) * -1,
          type: 'furniture',
          phrase: 'FF&E',
          origValue: furnitureAbated[index]
        });
      });

      buildings = (0, _sumManyArrays.default)([landAbated, buildingsAbated]);
      buildings.forEach(function (abatement, index) {
        abatementsData.push({
          year: index + 1,
          value: isNaN(abatement) ? 0 : Math.abs(abatement) * -1,
          type: 'buildings',
          phrase: 'Buildings',
          origValue: buildingsAbated[index]
        });
      });

      landAbated.forEach(function (abatement, index) {
        abatementsData.push({
          year: index + 1,
          value: isNaN(abatement) ? 0 : Math.abs(abatement) * -1,
          type: 'land',
          phrase: 'Land',
          origValue: landAbated[index]
        });
      });

      return abatementsData;
    }),

    rebatesScheduleTableData: Ember.computed('engine.schedule.rebateSchedule', 'engine.assumption.{startingYear,calendarYearDisplay}', function () {

      var engine = this.get('engine'),
          rebates = engine.get('schedule.rebateSchedule'),
          calendarYearDisplay = engine.get('assumption.calendarYearDisplay'),
          startingYear = calendarYearDisplay ? parseInt(engine.get('assumption.startingYear')) : 1;

      return rebates.map(function (rebate, index) {
        return Ember.Object.create({
          year: startingYear + index,
          percent: rebate
        });
      });
    }),

    rebatesDetailedTableData: Ember.computed('engine.salesTaxRebates.annual', 'engine.assumption.{startingYear,calendarYearDisplay}', function () {

      var engine = this.get('engine'),
          rebates = engine.get('salesTaxRebates.annual'),
          calendarYearDisplay = engine.get('assumption.calendarYearDisplay'),
          startingYear = calendarYearDisplay ? parseInt(engine.get('assumption.startingYear')) : 1;

      return rebates.map(function (rebate, index) {
        return Ember.Object.create({
          year: startingYear + index,
          amount: rebate
        });
      });
    }),

    // Material FFE Tax Rebates Graph Data Specifically for Summary Report
    materialsEquipmentRebatesGraphDataSummary: Ember.computed('engine.fiSalesTaxConstructionMaterialsPreRebates.annual', 'engine.fiSalesTaxEquipmentPreRebates.annual', 'engine.materialsTaxRebates.annual', 'engine.equipmentTaxRebates.annual', function () {

      var engine = this.get('engine');
      var materialsSalesTax = engine.get('fiSalesTaxConstructionMaterialsPreRebates.annual'),
          equipmentSalesTax = engine.get('fiSalesTaxEquipmentPreRebates.annual'),
          materialsRebated = (0, _floatMap.default)(engine.get('materialsTaxRebates.annual')),
          equipmentRebated = (0, _floatMap.default)(engine.get('equipmentTaxRebates.annual')),
          incentives = (0, _sumManyArrays.default)([materialsRebated, equipmentRebated]),
          materialsAndEquipmentSalesTaxes = [],
          rebatesData = [];

      materialsAndEquipmentSalesTaxes = (0, _floatMap.default)((0, _sumArrays.default)(materialsSalesTax, equipmentSalesTax));

      materialsAndEquipmentSalesTaxes.forEach(function (tax, index) {
        rebatesData.push({
          year: index + 1,
          value: isNaN(tax) ? 0 : tax,
          type: 'sales-tax'
        });
      });

      incentives.forEach(function (incentive, index) {
        rebatesData.push({
          year: index + 1,
          value: isNaN(incentive) ? 0 : Math.abs(incentive) * -1,
          type: 'incentive',
          phrase: 'Incentive'
        });
      });

      return rebatesData;
    }),

    // Construction Materials and FF&E Equipment Sales Tax Rebates Graph Data
    materialsEquipmentRebatesGraphData: Ember.computed('engine.fiSalesTaxConstructionMaterialsPreRebates.annual', 'engine.fiSalesTaxEquipmentPreRebates.annual', 'engine.materialsTaxRebates.annual', 'engine.equipmentTaxRebates.annual', function () {

      var engine = this.get('engine');

      var materialsSalesTax = engine.get('fiSalesTaxConstructionMaterialsPreRebates.annual'),
          equipmentSalesTax = engine.get('fiSalesTaxEquipmentPreRebates.annual'),
          materialsRebated = (0, _floatMap.default)(engine.get('materialsTaxRebates.annual')),
          equipmentRebated = (0, _floatMap.default)(engine.get('equipmentTaxRebates.annual')),
          materialsAndEquipmentSalesTaxes = [],
          equipment = [],
          rebatesData = [];

      // total materials and equipment sales taxes pre rebates
      materialsAndEquipmentSalesTaxes = (0, _floatMap.default)((0, _sumArrays.default)(materialsSalesTax, equipmentSalesTax));

      materialsAndEquipmentSalesTaxes.forEach(function (tax, index) {
        rebatesData.push({
          year: index + 1,
          value: isNaN(tax) ? 0 : tax,
          type: 'sales-tax'
        });
      });

      // This order is important as it will ensure proper z-indices for graph data
      equipment = (0, _sumArrays.default)(materialsRebated, equipmentRebated);
      equipment.forEach(function (rebate, index) {
        rebatesData.push({
          year: index + 1,
          value: isNaN(rebate) ? 0 : Math.abs(rebate) * -1,
          type: 'furniture',
          phrase: 'FF&E Purchases',
          origValue: equipmentRebated[index]
        });
      });

      materialsRebated.forEach(function (rebate, index) {
        rebatesData.push({
          year: index + 1,
          value: isNaN(rebate) ? 0 : Math.abs(rebate) * -1,
          type: 'buildings',
          phrase: 'Construction Materials',
          origValue: materialsRebated[index]
        });
      });

      return rebatesData;
    }),

    // used for appendix table on detailed report
    materialsEquipmentScheduleTableData: Ember.computed('engine.schedule.{materialsRebateSchedule,equipmentRebateSchedule}', 'engine.schedule.{doesApplyMaterialsTaxRebates,doesApplyEquipmentTaxRebates}', 'engine.materialsAndEquipmentRebates.annual', 'engine.assumption.scenarioLength', function () {

      var engine = this.get('engine'),
          materialsRebate = engine.get('schedule.materialsRebateSchedule'),
          equipmentRebate = engine.get('schedule.equipmentRebateSchedule'),
          doesApplyMaterials = engine.get('schedule.doesApplyMaterialsTaxRebates'),
          doesApplyEquipment = engine.get('schedule.doesApplyEquipmentTaxRebates');

      return this.get('engine.materialsAndEquipmentRebates.annual').map(function (item, i) {
        return Ember.Object.create({
          materials: doesApplyMaterials ? materialsRebate[i] : 0,
          equipment: doesApplyEquipment ? equipmentRebate[i] : 0
        });
      });
    }),

    // Hotel Tax Rebates Graph Data
    hotelTaxRebatesData: Ember.computed('engine.{hotelTaxRebates.annual,fiHotelTaxPreRebates,hotelTaxIncentivePhrases.singular}', function () {

      var engine = this.get('engine'),
          hotelTaxes = (0, _floatMap.default)(engine.get('fiHotelTaxPreRebates.annual')),
          rebates = (0, _floatMap.default)(engine.get('hotelTaxRebates.annual'));

      var rebatesData = [];

      hotelTaxes.forEach(function (amount, index) {
        rebatesData.push({
          year: index + 1,
          value: isNaN(amount) ? 0 : amount,
          type: 'hotel-tax'
        });
      });

      rebates.forEach(function (rebate, index) {
        rebatesData.push({
          year: index + 1,
          value: isNaN(rebate) ? 0 : Math.abs(rebate) * -1,
          type: 'rebated',
          phrase: engine.get('hotelTaxIncentivePhrases.singular'),
          origValue: rebates[index]
        });
      });

      return rebatesData;
    }),

    // Hotel Tax Rebates Graph Data
    hotelTaxRebatesTableData: Ember.computed('engine.{hotelTaxRebates.annual,fiHotelTaxPreRebates}', 'engine.assumption.{startingYear,calendarYearDisplay}', function () {

      var engine = this.get("engine"),
          data = [],
          hotelTaxes = (0, _floatMap.default)(engine.get('fiHotelTaxPreRebates.annual')),
          rebates = (0, _floatMap.default)(engine.get('hotelTaxRebates.annual')),
          calendarYearDisplay = engine.get('assumption.calendarYearDisplay'),
          startingYear = calendarYearDisplay ? parseInt(engine.get('assumption.startingYear')) : 1;

      hotelTaxes.forEach(function (amount, i) {
        data.push({
          hotelTax: isNaN(amount) ? 0 : amount,
          rebate: isNaN(rebates[i]) ? 0 : Math.abs(rebates[i]) * -1,
          net: isNaN(amount - rebates[i]) ? 0 : amount - rebates[i],
          year: startingYear + i
        });
      });

      return data;
    }),

    hotelRebatesDetailedTableData: Ember.computed('engine.hotelTaxRebates.annual', 'engine.assumption.{startingYear,calendarYearDisplay}', function () {

      var engine = this.get('engine'),
          rebates = engine.get('hotelTaxRebates.annual'),
          calendarYearDisplay = engine.get('assumption.calendarYearDisplay'),
          startingYear = calendarYearDisplay ? parseInt(engine.get('assumption.startingYear')) : 1;

      return rebates.map(function (rebate, index) {
        return Ember.Object.create({
          year: startingYear + index,
          amount: rebate
        });
      });
    }),

    hotelRebatesScheduleTableData: Ember.computed('engine.schedule.hotelTaxRebateSchedule', 'engine.assumption.{startingYear,calendarYearDisplay}', function () {

      var engine = this.get('engine'),
          rebates = engine.get('schedule.hotelTaxRebateSchedule'),
          calendarYearDisplay = engine.get('assumption.calendarYearDisplay'),
          startingYear = calendarYearDisplay ? parseInt(engine.get('assumption.startingYear')) : 1;

      return rebates.map(function (rebate, index) {
        return Ember.Object.create({
          year: startingYear + index,
          percent: rebate
        });
      });
    }),

    tifScheduleTableData: Ember.computed('engine.landRates.annualSchedule', 'engine.buildingRates.annualSchedule', 'engine.ffeRates.annualSchedule', 'engine.inventoryRates.annualSchedule', 'engine.taxableSalesRates.annualSchedule', 'engine.tifContributionAnnual', 'engine.assumption.{startingYear,calendarYearDisplay}', function () {

      var engine = this.get('engine'),
          calendarYearDisplay = engine.get('assumption.calendarYearDisplay'),
          startingYear = calendarYearDisplay ? parseInt(engine.get('assumption.startingYear')) : 1;

      return engine.get('tifContributionAnnual').map(function (item, index) {
        return Ember.Object.create({
          year: startingYear + index,
          land: engine.get('landRates.annualSchedule')[index],
          buildings: engine.get('buildingRates.annualSchedule')[index],
          ffe: engine.get('ffeRates.annualSchedule')[index],
          inventory: engine.get('inventoryRates.annualSchedule')[index],
          taxableSales: engine.get('taxableSalesRates.annualSchedule')[index]
        });
      });
    }),

    applicableIncentives: Ember.computed('engine.incentives', 'engine.scenario.incentiveComparisonInput', 'engine.assumption.{abatementsAsCosts,scenarioLength}', 'engine.schedule.incentives', function () {

      var engine = this.get('engine'),
          abatementsAsCosts = engine.get('assumption.abatementsAsCosts'),
          incentiveComparison = engine.get('scenario.incentiveComparisonInput');

      if (abatementsAsCosts && incentiveComparison === 'nonTax') {
        return engine.get('schedule.incentives').slice(0, engine.get('assumption.scenarioLength'));
      }

      return engine.get('incentives');
    }),

    incentivesGraphData: Ember.computed('applicableIncentives', function () {
      return this.get('applicableIncentives').map(function (value, index) {
        return {
          year: index + 1,
          value: parseFloat(value)
        };
      });
    }),

    incentivesTableData: Ember.computed('engine', function () {
      var engine = this.get('engine'),
          incentives = this.get('applicableIncentives'),
          cumulativeNetBenefits = engine.get('annualNetBenefits.directCumulative');

      return incentives.map(function (incentive, index) {
        return Ember.Object.create({
          incentive: incentive,
          netBenefit: isNaN(cumulativeNetBenefits[index]) ? 0 : cumulativeNetBenefits[index]
        });
      });
    }),

    communityIncentivesTableData: Ember.computed('engine', function () {
      var incentiveEngine = this.get('engine'),
          incentives = incentiveEngine.get('incentives'),
          cumulativeNetBenefits = (0, _cumulativeSum.default)(incentiveEngine.get('annualNetBenefits'));

      return incentives.map(function (incentive, index) {
        return Ember.Object.create({
          incentive: incentive,
          netBenefit: isNaN(cumulativeNetBenefits[index]) ? 0 : cumulativeNetBenefits[index]
        });
      });
    }),

    summaryIncentivesGraphData: Ember.computed('engine.displayedIncentiveType', function () {
      var incentiveType = this.get('engine.displayedIncentiveType');

      if (incentiveType === 'propertyTax') {
        return this.get('abatementsGraphDataSummary');
      } else if (incentiveType === 'salesTax') {
        return this.get('salesTaxRebatesData');
      } else if (incentiveType === 'materialsTax') {
        return this.get('materialsEquipmentRebatesGraphDataSummary');
      } else if (incentiveType === 'hotelTax') {
        return this.get('hotelTaxRebatesData');
      }
    }),

    fiCurrentVsExpandedTableData: Ember.computed('currentEngines.@each.netBenefitsTotal', 'expansionEngines.@each.netBenefitsTotal', 'totalEngines.@each.netBenefitsTotal', 'engine.assumption.abatementsAsCosts', function () {

      var currentEngines = this.get('currentEngines'),
          expansionEngines = this.get('expansionEngines'),
          totalEngines = this.get('totalEngines');
      var netBenefitsProp = this.get('expansionEngines.firstObject.assumption.abatementsAsCosts') ? 'netBenefitsLessIncentivesTotal' : 'netBenefitsTotal';

      return currentEngines.map(function (engine, index) {
        return Ember.Object.create({
          engine: engine,
          current: engine.get(netBenefitsProp),
          expansion: expansionEngines.objectAt(index).get(netBenefitsProp),
          total: totalEngines.objectAt(index) ? totalEngines.objectAt(index).get(netBenefitsProp) : 0
        });
      });
    }),

    salesTaxBreakdownTableData: Ember.computed('engine.salesTaxes.newWorkerTaxes', 'engine.{fiSalesTaxDirectWorkerSpending,fiSalesTaxIndirectWorkerSpending,fiSalesTaxTaxablePurchases,fiSalesTaxTaxableSales,fiSalesTaxHotelSpending}', 'engine.{fiSalesTaxVisitorSpending,fiSalesTaxConstructionMaterials,fiSalesTaxConstructionWorkerSpending,fiSalesTaxEquipment,fiSalesTaxes}', function () {

      return [{ label: 'Construction Materials', project: (0, _sumArray.default)(this.get('engine.fiSalesTaxConstructionMaterials')), workers: 0 }, { label: 'Construction Worker Spending', project: (0, _sumArray.default)(this.get('engine.fiSalesTaxConstructionWorkerSpending')), workers: 0 }, { label: 'Purchases of FF&E', project: (0, _sumArray.default)(this.get('engine.fiSalesTaxEquipment')), workers: 0 }, { label: 'Direct Worker Spending', project: 0, workers: (0, _sumArray.default)(this.get('engine.fiSalesTaxDirectWorkerSpending')) }, { label: 'Spin-off Worker Spending', project: 0, workers: (0, _sumArray.default)(this.get('engine.fiSalesTaxIndirectWorkerSpending')) }, { label: 'Visitor Spending', project: (0, _sumArray.default)(this.get('engine.fiSalesTaxVisitorSpending')), workers: 0 }, { label: 'Hotel Spending', project: (0, _sumArray.default)(this.get('engine.fiSalesTaxHotelSpending')), workers: 0 }, { label: 'Taxable Purchases', project: (0, _sumArray.default)(this.get('engine.fiSalesTaxTaxablePurchases')), workers: 0 }, { label: 'Taxable Sales', project: (0, _sumArray.default)(this.get('engine.fiSalesTaxTaxableSales')), workers: 0 }];
    }),

    realPropertyTaxBreakdownTableData: Ember.computed('engine.{fiPropertyTaxLand,fiPropertyTaxBuildings}', function () {
      return [{ label: 'Land', project: (0, _sumArray.default)(this.get('engine.fiPropertyTaxLand')), workers: 0 }, { label: 'Buildings', project: (0, _sumArray.default)(this.get('engine.fiPropertyTaxBuildings')), workers: 0 }];
    }),

    incomeTaxBreakdownTableData: Ember.computed('engine.{fiResidentialIncomeTaxes,fiNonResidentialIncomeTaxes}', function () {
      return [{ label: 'Resident Income Taxes', project: (0, _sumArray.default)(this.get('engine.fiResidentialIncomeTaxes')), workers: 0 }, { label: 'Non-Resident Income Taxes', project: (0, _sumArray.default)(this.get('engine.fiNonResidentialIncomeTaxes')), workers: 0 }];
    })

  });
});