define('dashboard/components/dropdown-buttons', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['inline-block'],

    isShown: false,

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      var event = 'click.' + this.elementId,
          button = this.$().find('.dropdown>button');

      //for handling the case when bubbles false and hide this dropdown when clicking on others
      Ember.$('body').on(event, '.dropdown button, .dropdown-menu a', function (e) {
        Ember.run(function () {
          _this.hideDropdownIfNotButton.apply(_this, [e, button]);
        });
      });
      Ember.$('body').on(event, function (e) {
        Ember.run(function () {
          _this.hideDropdownIfNotButton.apply(_this, [e, button]);
        });
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      var event = 'click.' + this.elementId;
      Ember.$('body').off(event);
    },


    hideDropdownIfNotButton: function hideDropdownIfNotButton(e, button) {
      var _this2 = this;

      var el = Ember.$(e.target);

      if (!el.is(button) && !el.closest(button).length) {
        Ember.run(function () {
          if (_this2.get('isShown') && !_this2.get('isDestroyed')) {
            _this2.set('isShown', false);
          }
        });
      }
    },

    click: function click() {
      this.set('isShown', false);
    },


    isRight: Ember.computed.equal('position', 'right'),
    isTop: Ember.computed.equal('align', 'top'),

    actions: {
      toggle: function toggle() {
        this.toggleProperty('isShown');
      }
    }
  });
});