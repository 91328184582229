define('dashboard/components/scenario-header', ['exports', 'dashboard/mixins/essentials-actions'], function (exports, _essentialsActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_essentialsActions.default, {

    tagName: '',

    centerLinks: Ember.computed('model.essentialsScenario', function () {
      var essentialsLinks = [{ label: 'Scenario Details', route: 'scenario.raw-data' }, { label: 'Fact Sheet', route: 'scenario.report.fact-sheet' }];
      var standardLinks = [{ label: 'Scenario Info', route: 'scenario.index' }, { label: 'Public Support', route: 'scenario.public-support' }, { label: 'Reports', route: 'scenario.report' }];
      return this.get('model.essentialsScenario') ? essentialsLinks : standardLinks;
    }),

    currentVsExpansionOptions: Ember.computed('currentUser.isSuperAdmin', function () {
      var options = [];
      // if (this.get('currentUser.isSuperAdmin')) {
      //   options.push({ value: 'current', label: 'Current' });
      // }
      options.push({ value: 'expansion', label: 'Expansion Only' }, { value: 'total', label: 'Total Combined' });
      return options;
    }),

    isSettingsRoute: Ember.computed('currentRoute', function () {
      return this.get('currentRoute').includes('scenario.settings');
    }),

    isRawDataRoute: Ember.computed('currentRoute', function () {
      return this.get('currentRoute').includes('scenario.raw-data');
    }),

    actions: {
      updateProjectTitle: function updateProjectTitle(newTitle) {
        var project = this.get('project');
        if (!project || !newTitle) {
          return;
        }
        project.set('title', newTitle);
        project.save();
      },
      updateScenarioTitle: function updateScenarioTitle(subtitle) {
        this.sendAction('updateScenarioTitle', subtitle);
      },
      toggleLockStatus: function toggleLockStatus() {
        var model = this.get('model');
        model.toggleProperty('isAdminUnlocked');
        model.save();
      },
      goToRoute: function goToRoute(route) {
        this.goToRoute(route);
      }
    }

  });
});