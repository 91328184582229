define('dashboard/components/wizard-review-print-header', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['print-only'],
    init: function init() {
      this._super.apply(this, arguments);
      this.set('today', new Date());
    }
  });
});