define('dashboard/utils/make-increase', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (name, title, value) {
    var increaseDisabled = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;

    var increase = isNaN(parseFloat(value)) ? 0 : parseFloat(value);

    var increaseObject = Ember.Object.create({
      name: name,
      title: title,
      value: increase
    });

    if (increaseDisabled) {
      increaseObject['disabledProperty'] = true;
    }

    return increaseObject;
  };
});