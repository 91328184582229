define('dashboard/routes/multiplier-sets/archived', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.store.findAll('multiplierSet');
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.controllerFor('multiplierSets').set('currentMultiplierSet', null);
    }
  });
});