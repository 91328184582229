define("dashboard/utils/create-popover-id", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    return "popover-id-" + Math.random().toString(36).substr(2, 9);
  };
});