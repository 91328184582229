define('dashboard/controllers/scenario/settings/district', ['exports', 'dashboard/fixtures/states'], function (exports, _states) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Controller.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.set('directCostRevTypes', [Ember.Object.create({ label: 'No Percent Increase', value: 'advanced' }), Ember.Object.create({ label: 'Percent Increase', value: 'cascading' })]);
    },


    notify: Ember.inject.service(),
    layout: Ember.inject.controller(),
    currentUser: Ember.computed.alias('layout.model'),

    stateOptions: _states.default,
    isUnlocked: true,
    districtConfirmHidden: true,

    modalTitleResetDistrict: Ember.computed('model.name', function () {
      var districtName = this.get('model.name');
      return 'Reset ' + (districtName ? districtName : 'District') + '?';
    }),

    notEditable: Ember.computed('model.{modifiedForScenario,adminCreated}', 'scenario.isScenarioDisabled', function () {
      return !this.get('model.modifiedForScenario') || this.get('model.adminCreated') || this.get('scenario.isScenarioDisabled');
    }),

    rebateableSalesTaxRateUneditable: Ember.computed('notEditable', 'model.doesApplyRebateableSalesTaxRate', function () {
      return this.get('notEditable') || !this.get('model.doesApplyRebateableSalesTaxRate');
    }),

    // we're no longer keeping modified districts in a client's district groups
    // instead need to change "district modification" behavior to make the district keep a reference to the group in it's districtGroups property
    parentGroup: Ember.computed('model.id', 'scenario.client.districtGroups.@each.{districts,previousDistricts}', 'scenario.districts.@each.districtGroups', function () {
      var _this = this;

      return this.get('scenario.client.districtGroups').find(function (group) {
        var groupDistricts = [];
        group.get('districts').forEach(function (district) {
          return groupDistricts.push(district);
        });
        group.get('previousDistricts').forEach(function (district) {
          return groupDistricts.push(district);
        });

        var foundDistrict = groupDistricts.findBy('id', _this.get('model.id'));

        if (!foundDistrict) {
          foundDistrict = _this.get('model.districtGroups').findBy('id', group.get('id'));
        }

        return foundDistrict;
      }) || null;
    }),

    filteredCustomCostRevenues: Ember.computed('model.customCostRevenues', function () {

      var activeRevenues = [],
          deletedRevenues = [],
          activeCosts = [],
          deletedCosts = [];

      this.get('model.customCostRevenues').forEach(function (costRev) {
        costRev.set('label', costRev.get('name') || 'Untitled');
        if (costRev.get('type') === 'revenue') {
          if (costRev.get('deleted')) {
            deletedRevenues.push(costRev);
          } else {
            activeRevenues.push(costRev);
          }
        } else {
          if (costRev.get('deleted')) {
            deletedCosts.push(costRev);
          } else {
            activeCosts.push(costRev);
          }
        }
      });

      return Ember.Object.create({
        activeRevenues: activeRevenues,
        activeCosts: activeCosts,
        deletedRevenues: deletedRevenues,
        deletedCosts: deletedCosts
      });
    }),

    filteredDirectCostRevenues: Ember.computed('model.directCostRevenues', function () {

      var activeRevenues = [],
          deletedRevenues = [],
          activeCosts = [],
          deletedCosts = [];

      this.get('model.directCostRevenues').forEach(function (costRev) {
        costRev.set('label', costRev.get('name') || 'Untitled');
        if (costRev.get('type') === 'revenue') {
          if (costRev.get('deleted')) {
            deletedRevenues.push(costRev);
          } else {
            activeRevenues.push(costRev);
          }
        } else {
          if (costRev.get('deleted')) {
            deletedCosts.push(costRev);
          } else {
            activeCosts.push(costRev);
          }
        }
      });

      return Ember.Object.create({
        activeRevenues: activeRevenues,
        activeCosts: activeCosts,
        deletedRevenues: deletedRevenues,
        deletedCosts: deletedCosts
      });
    }),

    usesAbateableRate: Ember.computed('model.{doesApplyRebateableSalesTaxRate,doesApplyAbateableTaxRateLand,doesApplyAbateableTaxRateFFE,doesApplyAbateableTaxRateInventories,doesApplyAbateableTaxRateBuildings,copyOfDistrict}', function () {
      var model = this.get('model');
      var allDistricts = this.get('store').peekAll('taxDistrict').toArray();
      var copyOfDistrict = model.get('copyOfDistrict');
      var copiedDistrict = copyOfDistrict ? allDistricts.find(function (district) {
        return district.get('id') === copyOfDistrict;
      }) : null;

      var displaysProperty = function displaysProperty(propertyName) {
        var propertyValue = model.get(propertyName);
        var copiedDistrictProperty = copiedDistrict ? copiedDistrict.get(propertyName) : false;

        return copiedDistrict ? copiedDistrictProperty : propertyValue;
      };

      return Ember.Object.create({
        land: displaysProperty('doesApplyAbateableTaxRateLand'),
        buildings: displaysProperty('doesApplyAbateableTaxRateBuildings'),
        ffe: displaysProperty('doesApplyAbateableTaxRateFFE'),
        inventories: displaysProperty('doesApplyAbateableTaxRateInventories'),
        salesTax: displaysProperty('doesApplyRebateableSalesTaxRate')
      });
    }),

    actions: {
      openDistrictConfirmModal: function openDistrictConfirmModal() {

        if (this.get('currentUser.isSuperAdmin') && !this.get('scenario.model.isAdminUnlocked')) {
          this.get('notify').alert('Admins must unlock scenario before resetting districts.');
          return;
        }

        this.set('districtConfirmHidden', false);
      },
      resetDistrictDefaults: function resetDistrictDefaults() {

        var scenarioController = this.get('scenario'),
            scenario = scenarioController.get('model'),

        // parentGroup = this.get('parentGroup'),
        districtToReset = this.get('model'),
            notify = this.get('notify'),
            self = this;

        function findDefaultDistrict() {
          return self.store.find('taxDistrict', districtToReset.get('copyOfDistrict'));
        }

        function handleDefaultDistrict(defaultDistrict) {

          // This doesn't work if we don't do this first
          function removeOldDistrictAssociation() {
            scenario.get('districts').removeObject(districtToReset);
            return scenario.save();
          }

          function resetScenarioAssociations(scenario) {
            var scenarioDistricts = scenario.get('districts');
            scenarioDistricts.pushObject(defaultDistrict);

            // for dependent districts, need to set primaryDependentDistrict back to default
            if (scenario.get('primaryDependentDistrict.content') && defaultDistrict.get('isPrimary')) {
              scenario.set('primaryDependentDistrict', defaultDistrict);
            }
            return scenario.save();
          }

          function deleteModifiedDistrict() {
            districtToReset.deleteRecord();
            return districtToReset.save();
          }

          function goToDefaultDistrict() {
            self.transitionToRoute('scenario.settings.district', defaultDistrict.get('id')).then(function () {
              self.set('districtConfirmHidden', true);
              defaultDistrict.set('modifiedProperties', {});
              notify.info('Default assumptions have been reset for ' + defaultDistrict.get('name') + '.');
            });
          }

          removeOldDistrictAssociation().then(resetScenarioAssociations).then(deleteModifiedDistrict).then(goToDefaultDistrict).catch(onFailure);
        }

        function onFailure(err) {
          console.log('ERROR ', err);
          scenarioController.set('cloningDistrict', false);
          notify.alert('Something went wrong when resetting defaults for this district.');
        }

        scenarioController.set('cloningDistrict', true);

        findDefaultDistrict().then(handleDefaultDistrict).catch(onFailure);
      },
      cloneScenarioDistrict: function cloneScenarioDistrict() {

        if (this.get('currentUser.isSuperAdmin') && !this.get('scenario.model.isAdminUnlocked')) {
          this.get('notify').alert('Admins must unlock scenario before editing districts');
          return;
        }

        var oldDistrict = this.get('model'),
            parentGroup = this.get('parentGroup'),
            scenarioController = this.get('scenario'),
            scenario = scenarioController.get('model'),
            notify = this.get('notify'),
            createdDistrict = void 0,
            self = this;

        function copyDistrict() {
          var districtToCreate = oldDistrict.toJSON();

          // Remove read only properties that can not be set on new district
          delete districtToCreate.customCostRevenues;
          delete districtToCreate.directCostRevenues;
          delete districtToCreate.created;

          // Copy oldDistrict properties
          for (var key in districtToCreate) {
            if (districtToCreate[key] !== oldDistrict.get(key)) {
              districtToCreate[key] = oldDistrict.get(key);
            }
          }

          districtToCreate.name = oldDistrict.get('name');
          districtToCreate.adminCreated = false;
          districtToCreate.modifiedForScenario = scenario.get('id');
          districtToCreate.copyOfDistrict = oldDistrict.get('id');

          districtToCreate.districtGroups = [parentGroup];

          var newDistrict = self.store.createRecord('taxDistrict', districtToCreate);
          return newDistrict.save();
        }

        // Replace this district in array of scenario.districts
        function setScenarioDistrictAssociations(newDistrict) {
          createdDistrict = newDistrict;
          var scenarioDistricts = scenario.get('districts');
          scenarioDistricts.removeObject(oldDistrict);
          scenarioDistricts.pushObject(createdDistrict);

          // Update scenario building permits and fees to reference new district
          var buildingPermitsFees = scenario.get('buildingPermitsFees') || {};
          if (buildingPermitsFees.hasOwnProperty(oldDistrict.get('id'))) {
            buildingPermitsFees[createdDistrict.get('id')] = { annual: buildingPermitsFees[oldDistrict.get('id')].annual };
            buildingPermitsFees[createdDistrict.get('id')].copyOfDistrict = oldDistrict.get('id');
          }

          // For Dependent Districts - update primaryDependentDistrict when modifying primary
          if (scenario.get('primaryDependentDistrict.content') && newDistrict.get('isPrimary')) {
            scenario.set('primaryDependentDistrict', newDistrict);
          }

          return scenario.save();
        }

        function handleCustomCostRevenues() /* savedScenario */{

          // Creates array of promises to be resolved in RSVP.all
          function copyCustomCostRevs(customCostRevenues) {
            return customCostRevenues.map(function (oldCostRev) {
              var costRevToCreate = oldCostRev.toJSON();
              delete costRevToCreate.created;

              return self.store.createRecord('customCostRevenue', costRevToCreate).save();
            });
          }

          // Resolves copied custom cost revs promise array
          function createCustomCostRevs(customCostRevsToCreate) {
            return Ember.RSVP.all(customCostRevsToCreate);
          }

          function onCustomSuccess(createdCustomCostRevs) {

            // Creates array of promises to be resolved in RSVP.all
            function copyDirectCostRevs(directCostRevenues) {
              return directCostRevenues.map(function (oldCostRev) {
                var costRevToCreate = oldCostRev.toJSON();
                delete costRevToCreate.created;
                costRevToCreate.copyOfDirect = oldCostRev.id;

                return self.store.createRecord('directCostRevenue', costRevToCreate).save();
              });
            }

            // Resolves copied direct cost revs promise array
            function createDirectCostRevs(directCostRevsToCreate) {
              return Ember.RSVP.all(directCostRevsToCreate);
            }

            createdCustomCostRevs.forEach(function (newCostRev) {
              createdDistrict.get('customCostRevenues').pushObject(newCostRev);
            });

            return createDirectCostRevs(copyDirectCostRevs(oldDistrict.get('directCostRevenues')));
          }

          function onDirectSuccess(createdDirectCostRevs) {

            function copyDirectReferences() {

              var scenarioDirects = scenario.get('directCostRevenues');
              // loop through createdDirectCostRevs
              createdDirectCostRevs.forEach(function (newCostRev) {
                createdDistrict.get('directCostRevenues').pushObject(newCostRev);

                // for each if there is a reference to costRev.copyOfDirect in scenario.model.directCostRevenues
                if (scenarioDirects && scenarioDirects.hasOwnProperty(newCostRev.get('copyOfDirect'))) {
                  var oldCostRevReference = scenarioDirects[newCostRev.get('copyOfDirect')];
                  // create duplicate of this scenairo reference (including new property for "copyOfDirect")
                  scenarioDirects[newCostRev.get('id')] = {
                    annual: oldCostRevReference.annual,
                    copyOfDirect: newCostRev.get('copyOfDirect')
                  };
                  if (oldCostRevReference.increase) {
                    scenarioDirects[newCostRev.get('id')].increase = oldCostRevReference.increase;
                  }
                }
              });

              return createdDistrict.save().then(scenario.save());
            }

            function goToDistrict() {
              self.transitionToRoute('scenario.settings.district', createdDistrict.get('id')).then(function () {
                scenarioController.set('cloningDistrict', false);
                notify.info('You may now edit the default assumptions.');
              });
            }

            copyDirectReferences().then(goToDistrict).catch(onFailure);
          }

          // Duplicate any custom costs/revs that exist on the old district
          // Resolve custom cost/rev promises and save other models
          createCustomCostRevs(copyCustomCostRevs(oldDistrict.get('customCostRevenues'))).then(onCustomSuccess, onFailure).then(onDirectSuccess, onFailure).catch(onFailure);
        }

        function onFailure(error) {
          console.log('ERROR ', error);
          scenarioController.set('cloningDistrict', false);
          notify.alert('There was a problem editing this district');
        }

        // Set global progress status to stop certain bindings from firing
        scenarioController.set('cloningDistrict', true);

        // Copy the district we're looking at
        copyDistrict().then(setScenarioDistrictAssociations).then(handleCustomCostRevenues).catch(onFailure);
      },
      save: function save() {
        var taxDistrict = this.get('model'),
            self = this;

        Ember.run.once(null, function () {
          taxDistrict.save().then(function () {
            self.set('isUnlocked', true);
          });
        });
      },
      updateModel: function updateModel(model) {

        var modifiedProperties = void 0,
            changedAttributes = void 0;

        // This is only used for the current district model or a customCostRevenue model
        // So if no cost/rev is passed, assume it's this district
        model = model ? model : this.get('model');

        modifiedProperties = model.get('modifiedProperties');
        changedAttributes = model.changedAttributes();

        for (var attr in changedAttributes) {

          if (!modifiedProperties.hasOwnProperty(attr)) {
            addValueToModifiedList(attr);
          } else {
            removeValueFromModifiedList(attr);
          }
        }

        model.save();

        // Save original attribute value
        function addValueToModifiedList(attr) {
          var initial = changedAttributes[attr][0],
              changedTo = changedAttributes[attr][1];

          switch (typeof changedTo === 'undefined' ? 'undefined' : _typeof(changedTo)) {
            case 'number':
              initial = parseFloat(initial);
              break;
            case 'string':
              initial = initial ? initial.toString().trim() : '';
              changedTo = changedTo.trim();
              break;
          }

          if (initial !== changedTo) {
            modifiedProperties[attr] = initial;
          }
        }

        // remove values matching original values
        function removeValueFromModifiedList(attr) {
          var changedTo = changedAttributes[attr][1];

          if (modifiedProperties[attr] === changedTo.toString() || parseFloat(modifiedProperties[attr]) === parseFloat(changedTo) || modifiedProperties[attr] === changedTo || changedTo.toString() === '' && !modifiedProperties[attr]) {
            delete modifiedProperties[attr];
          }
        }
      },
      createCustomCostRevenue: function createCustomCostRevenue(type) {

        var district = this.get('model'),
            customCostRev = {
          type: type,
          adminCreated: false
        };

        this.store.createRecord('customCostRevenue', customCostRev).save().then(function (customCostRev) {
          district.get('customCostRevenues').pushObject(customCostRev);
          district.save();
        });
      },
      createDirectCostRevenue: function createDirectCostRevenue(type) {

        var district = this.get('model'),
            scenarioController = this.get('scenario'),
            directCostRev = {
          type: type,
          adminCreated: false
        };

        this.store.createRecord('directCostRevenue', directCostRev).save().then(function (directCostRev) {
          scenarioController.notifyPropertyChange('unboundDirectCostRevenueInputs');
          district.get('directCostRevenues').pushObject(directCostRev);
          district.save();
        });
      },
      deleteCustomCostRevenue: function deleteCustomCostRevenue(costRev) {

        var district = this.get('model');

        if (costRev.get('adminCreated') !== false) {
          costRev.setProperties({
            deleted: true,
            doesApplyCustomCostRevenue: false
          });
        } else {
          district.get('customCostRevenues').removeObject(costRev);
          district.save();

          costRev.deleteRecord();
        }

        this.notifyPropertyChange('model.customCostRevenues');
        costRev.save();
      },
      deleteDirectCostRevenue: function deleteDirectCostRevenue(costRev) {

        var district = this.get('model');

        if (costRev.get('adminCreated') !== false) {
          costRev.setProperties({
            deleted: true,
            doesApplyDirectCostRevenue: false
          });
        } else {
          district.get('directCostRevenues').removeObject(costRev);
          district.save();

          costRev.deleteRecord();
        }

        this.notifyPropertyChange('model.directCostRevenues');
        this.get('scenario').notifyPropertyChange('unboundDirectCostRevenueInputs');
        costRev.save();
      },
      restoreCustomCostRevenue: function restoreCustomCostRevenue(costRev) {

        costRev.setProperties({
          deleted: false,
          doesApplyCustomCostRevenue: true
        });

        this.notifyPropertyChange('model.customCostRevenues');
        costRev.save();
      },
      restoreDirectCostRevenue: function restoreDirectCostRevenue(costRev) {

        costRev.setProperties({
          deleted: false,
          doesApplyDirectCostRevenue: true
        });

        this.get('scenario').notifyPropertyChange('unboundDirectCostRevenueInputs');
        this.notifyPropertyChange('model.directCostRevenues');
        costRev.save();
      }
    }
  });
});