define('dashboard/components/district-select-for-dependent-districts', ['exports', 'dashboard/components/district-select'], function (exports, _districtSelect) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _districtSelect.default.extend({

    // selectedDistrict: computed('scenario.districts.{@each,[]}', 'options.{@each,[]}', function() {
    selectedDistrict: Ember.computed(function () {
      return this.getSelectedDistrict();
    }),

    getSelectedDistrict: function getSelectedDistrict() {

      var options = this.get('options'),
          selectedDistricts = this.get('scenario.districts'),
          defaultOption = Ember.Object.create({ id: null, name: 'N/A' }),
          selected = void 0;

      // let allPossibleOptions = options.filter(option => {
      //   return selectedDistricts.findBy('id', option.get('id'));
      // });

      // let group = this.get('group'),
      // console.log('group ', group.get('label'));
      // console.log('all selectedOptions ', allPossibleOptions.mapBy('name'));

      if (selectedDistricts) {
        selected = selectedDistricts.find(function (district) {
          return options.findBy('id', district.get('id'));
        });
      }

      return selected ? selected : defaultOption;
    },


    previousSelectedDistrict: null,

    actions: {

      setPreviousDistrict: function setPreviousDistrict(dropdown) {
        var oldDistrict = dropdown.selected;

        if (oldDistrict && oldDistrict.get('id')) {
          this.set('previousSelectedDistrict', oldDistrict);
        }
      },

      // we only want to remove districts from this exact group, hence we set previous district on dropdown open
      selectDistrict: function selectDistrict(selectedDistrict) {

        var selectedDistricts = this.get('selectedDistricts'),
            group = this.get('group');

        var oldDistrict = this.get('previousSelectedDistrict');

        if (oldDistrict && oldDistrict.get('id')) {
          selectedDistricts.removeObject(oldDistrict);
        }

        var newDistrict = selectedDistrict.get('id') ? selectedDistrict : null;

        // this.notifyPropertyChange('selectedDistrict');
        this.sendAction('action', newDistrict, group);
      }

    }

  });
});