define('dashboard/helpers/format-percent', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatPercent = formatPercent;
  function formatPercent(params) {

    var number = params[0],
        medium = params[1];

    if (number === undefined || isNaN(number)) {
      number = 0;
    }

    var format = medium ? '0.0[000]%' : '0,0.0%';
    return numeral(number).format(format);
  }

  exports.default = Ember.Helper.helper(formatPercent);
});