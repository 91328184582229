define('dashboard/controllers/scenario/public-support/group', ['exports', 'dashboard/mixins/community-incentives', 'dashboard/mixins/alias-properties', 'dashboard/objects/column', 'dashboard/utils/stringify', 'dashboard/utils/array-of-length', 'dashboard/utils/array-compare', 'lodash/array', 'dashboard/utils/sum-array', 'dashboard/utils/float-map', 'dashboard/utils/make-inputs'], function (exports, _communityIncentives, _aliasProperties, _column, _stringify, _arrayOfLength, _arrayCompare, _array2, _sumArray, _floatMap, _makeInputs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_communityIncentives.default, _aliasProperties.default, {
    scenarioController: Ember.inject.controller('scenario'),
    notify: Ember.inject.service(),

    init: function init() {
      this.set('aliasedProperties', ['demoDataLoaded', 'years', 'isScenarioDisabled', 'isScenarioLengthLessThanEleven', 'sortedDistrictGroups', 'tifIncentives', 'tifTotals', 'tifAnnual', 'tifDistrict', 'tifCumulativeAnnual', 'sortedDistricts', 'currentUser', 'isSavable', 'primaryEngine', 'engines', 'reportHasPublicSupport', 'tifImpactEngine', 'expansionEngines']);

      this.set('propertyTaxincentiveTermOptions', ['Property Tax Abatement', 'Property Tax Exemption', 'Property Tax Rebate', 'Property Tax Reimbursement']);
      this.set('salesTaxincentiveTermOptions', ['Sales Tax Abatement', 'Sales Tax Exemption', 'Sales Tax Rebate', 'Sales Tax Reimbursement']);
      this.set('hotelTaxincentiveTermOptions', ['Hotel Tax Abatement', 'Hotel Tax Exemption', 'Hotel Tax Rebate', 'Hotel Tax Reimbursement']);
      this.set('pendingCommunityDistricts', []);
      this.set('oldNetBenefitsTotals', []); // keeps track of net benefits for watchCommunityNetBenefits to determine if an update is necessary
      this.set('attributionOptions', [Ember.Object.create({
        val: 'equal',
        text: 'Equal Share'
      }), Ember.Object.create({
        val: 'proportional',
        text: 'Proportional to Net Benefits'
      }), Ember.Object.create({
        val: 'custom',
        text: 'Custom'
      })]);

      this._super.apply(this, arguments);
    },


    aliasedClass: 'scenarioController',

    scenario: Ember.computed.alias('model'),

    incentivesUpdated: false,

    isConfirmCommunityDistrictHidden: true,
    includeCommunityDistrictAction: 'includeCommunityDistrict',

    abatementsTableView: false,
    materialsEquipmentTableView: false,
    rebatesTableView: false,
    hotelRebatesTableView: false,

    willExpand: Ember.computed.alias('scenario.willExpand'),

    appliesAbatements: Ember.computed.gt('abatementScheduleInputs.length', 0),
    appliesBuildingMaterialsAndFFE: Ember.computed.gt('materialsFFEScheduleInputs.length', 0),
    appliesSalesTaxRebates: Ember.computed.gt('rebateScheduleInputs.length', 0),
    appliesHotelTaxRebates: Ember.computed.gt('hotelScheduleInputs.length', 0),

    propertyTaxIncentiveTerm: Ember.computed.alias('engineForSelectedSchedule.propertyTaxIncentiveTerm'),
    propertyTaxIncentivePhrases: Ember.computed.alias('engineForSelectedSchedule.propertyTaxIncentivePhrases'),
    salesTaxIncentiveTerm: Ember.computed.alias('engineForSelectedSchedule.salesTaxIncentiveTerm'),
    salesTaxIncentivePhrases: Ember.computed.alias('engineForSelectedSchedule.salesTaxIncentivePhrases'),
    materialsEquipmentTaxIncentiveTerm: Ember.computed.alias('engineForSelectedSchedule.materialsEquipmentTaxIncentiveTerm'),
    materialsEquipmentIncentivePhrases: Ember.computed.alias('engineForSelectedSchedule.materialsEquipmentIncentivePhrases'),
    hotelTaxIncentiveTerm: Ember.computed.alias('engineForSelectedSchedule.hotelTaxIncentiveTerm'),
    hotelTaxIncentivePhrases: Ember.computed.alias('engineForSelectedSchedule.hotelTaxIncentivePhrases'),

    hotelTaxLabel: Ember.computed.alias('districtForSelectedSchedule.hotelTaxLabel'),

    districtForSelectedSchedule: Ember.computed('selectedSchedule', 'model', function () {
      var schedule = this.get('selectedSchedule');
      return this.get('scenario').getDistrictForSchedule(schedule);
    }),

    abatementScheduleInputs: Ember.computed('selectedSchedule.{abatementScheduleLand,abatementScheduleBuildings,abatementScheduleFurniture,abatementScheduleInventories,doesApplyAbatementsLand,doesApplyAbatementsBuildings,doesApplyAbatementsFurniture,doesApplyAbatementsInventories}', 'districtForSelectedSchedule.{doesApplyPropertyTaxRateLand,doesApplyPropertyTaxRateBuildings,doesApplyPropertyTaxRateFFE,doesApplyPropertyTaxRateInventories}', 'years', function () {
      var abatementScheduleLand = this.get('selectedSchedule.abatementScheduleLand'),
          abatementScheduleBuildings = this.get('selectedSchedule.abatementScheduleBuildings'),
          abatementScheduleFurniture = this.get('selectedSchedule.abatementScheduleFurniture'),
          abatementScheduleInventories = this.get('selectedSchedule.abatementScheduleInventories'),
          doesApplyAbatementsLand = this.get('selectedSchedule.doesApplyAbatementsLand'),
          doesApplyAbatementsBuildings = this.get('selectedSchedule.doesApplyAbatementsBuildings'),
          doesApplyAbatementsFurniture = this.get('selectedSchedule.doesApplyAbatementsFurniture'),
          doesApplyAbatementsInventories = this.get('selectedSchedule.doesApplyAbatementsInventories'),
          doesApplyPropertyTaxRateLand = this.get('districtForSelectedSchedule.doesApplyPropertyTaxRateLand'),
          doesApplyPropertyTaxRateBuildings = this.get('districtForSelectedSchedule.doesApplyPropertyTaxRateBuildings'),
          doesApplyPropertyTaxRateFFE = this.get('districtForSelectedSchedule.doesApplyPropertyTaxRateFFE'),
          doesApplyPropertyTaxRateInventories = this.get('districtForSelectedSchedule.doesApplyPropertyTaxRateInventories'),
          years = this.get('years'),
          output = [],
          landColumn = void 0,
          buildingsColumn = void 0,
          furnitureColumn = void 0,
          inventoryColumn = void 0;

      if (doesApplyPropertyTaxRateLand) {
        landColumn = _column.default.create({
          title: 'Land',
          columnName: 'abatementScheduleLand',
          inputType: 'percent',
          content: (0, _makeInputs.default)(abatementScheduleLand, years),
          checkbox: 'true',
          doesApplyName: 'doesApplyAbatementsLand',
          doesApply: doesApplyAbatementsLand,
          blockLabel: true
        });

        output.push(landColumn);
      }

      if (doesApplyPropertyTaxRateBuildings) {
        buildingsColumn = _column.default.create({
          title: 'Buildings...',
          columnName: 'abatementScheduleBuildings',
          inputType: 'percent',
          content: (0, _makeInputs.default)(abatementScheduleBuildings, years),
          checkbox: 'true',
          doesApplyName: 'doesApplyAbatementsBuildings',
          doesApply: doesApplyAbatementsBuildings,
          blockLabel: true
        });

        output.push(buildingsColumn);
      }

      if (doesApplyPropertyTaxRateFFE) {
        furnitureColumn = _column.default.create({
          title: 'FF&E',
          columnName: 'abatementScheduleFurniture',
          inputType: 'percent',
          content: (0, _makeInputs.default)(abatementScheduleFurniture, years),
          checkbox: 'true',
          doesApplyName: 'doesApplyAbatementsFurniture',
          doesApply: doesApplyAbatementsFurniture,
          blockLabel: true
        });

        output.push(furnitureColumn);
      }

      if (doesApplyPropertyTaxRateInventories) {
        inventoryColumn = _column.default.create({
          title: 'Inventories',
          columnName: 'abatementScheduleInventories',
          inputType: 'percent',
          content: (0, _makeInputs.default)(abatementScheduleInventories, years),
          checkbox: 'true',
          doesApplyName: 'doesApplyAbatementsInventories',
          doesApply: doesApplyAbatementsInventories,
          blockLabel: true
        });

        output.push(inventoryColumn);
      }

      return output;
    }),

    unboundAbatementScheduleInputs: Ember.computed('selectedSchedule', 'years', function () {
      return this.get('abatementScheduleInputs');
    }),

    rebateScheduleInputs: Ember.computed('years', 'districtForSelectedSchedule.doesApplySalesTax', 'engineForSelectedSchedule.fiSalesTaxTaxableSalesPreRebates.total', function () {
      var doesApplySalesTax = this.get('districtForSelectedSchedule.doesApplySalesTax'),
          years = this.get('years'),
          totalSalesTax = this.get('engineForSelectedSchedule.fiSalesTaxTaxableSalesPreRebates.total'),
          rebateSchedule = this.get('selectedSchedule.rebateSchedule'),
          doesApplySalesTaxRebates = this.get('selectedSchedule.doesApplySalesTaxRebates'),
          output = [];

      if (doesApplySalesTax && totalSalesTax > 0) {
        var rebateColumn = _column.default.create({
          title: 'Taxable Sales',
          columnName: 'rebateSchedule',
          inputType: 'percent',
          content: (0, _makeInputs.default)(rebateSchedule, years),
          checkbox: 'true',
          doesApplyName: 'doesApplySalesTaxRebates',
          doesApply: doesApplySalesTaxRebates
        });
        output.push(rebateColumn);
      }

      return output;
    }),
    unboundRebateScheduleInputs: Ember.computed('years', 'selectedSchedule', function () {
      return this.get('rebateScheduleInputs');
    }),

    materialsFFEScheduleInputs: Ember.computed('years', 'districtForSelectedSchedule.{doesApplySalesTax,doesApplyConstructionMaterialsSalesTax,doesApplyEquipmentSalesTax}', 'selectedSchedule.{materialsRebateSchedule,equipmentRebateSchedule,doesApplyMaterialsTaxRebates,doesApplyEquipmentTaxRebates}', 'scenario.assumption.buildingAndImprovementsAssociatedWithConstruction', 'engineForSelectedSchedule.fiSalesTaxTaxableSalesPreRebates.total', function () {
      var doesApplyConstructionMaterialsSalesTax = this.get('districtForSelectedSchedule.doesApplyConstructionMaterialsSalesTax'),
          doesApplySalesTax = this.get('districtForSelectedSchedule.doesApplySalesTax'),
          doesApplyEquipmentSalesTax = this.get('districtForSelectedSchedule.doesApplyEquipmentSalesTax'),
          years = this.get('years'),
          materialsRebateSchedule = this.get('selectedSchedule.materialsRebateSchedule'),
          equipmentRebateSchedule = this.get('selectedSchedule.equipmentRebateSchedule'),
          doesApplyMaterialsTaxRebates = this.get('selectedSchedule.doesApplyMaterialsTaxRebates'),
          associatedWithConstructionMaterials = this.get('scenario.assumption.buildingAndImprovementsAssociatedWithConstruction'),
          doesApplyEquipmentTaxRebates = this.get('selectedSchedule.doesApplyEquipmentTaxRebates'),
          output = [];

      if (doesApplySalesTax && doesApplyConstructionMaterialsSalesTax && associatedWithConstructionMaterials) {
        var materialsColumn = _column.default.create({
          title: 'Construction Materials',
          columnName: 'materialsRebateSchedule',
          inputType: 'percent',
          content: (0, _makeInputs.default)(materialsRebateSchedule, years),
          checkbox: 'true',
          doesApplyName: 'doesApplyMaterialsTaxRebates',
          doesApply: doesApplyMaterialsTaxRebates,
          class: 'realrealbigheader',
          blockLabel: true
        });
        output.push(materialsColumn);
      }

      if (doesApplySalesTax && doesApplyEquipmentSalesTax) {
        var equipmentColumn = _column.default.create({
          title: 'FF&E Purchases',
          columnName: 'equipmentRebateSchedule',
          inputType: 'percent',
          content: (0, _makeInputs.default)(equipmentRebateSchedule, years),
          checkbox: 'true',
          doesApplyName: 'doesApplyEquipmentTaxRebates',
          doesApply: doesApplyEquipmentTaxRebates,
          class: 'realrealbigheader',
          blockLabel: true
        });
        output.push(equipmentColumn);
      }

      return output;
    }),
    unboundMaterialsFFEScheduleInputs: Ember.computed('years', 'selectedSchedule', function () {
      return this.get('materialsFFEScheduleInputs');
    }),

    hotelScheduleInputs: Ember.computed('years', 'districtForSelectedSchedule.doesApplyHotelTax', 'engineForSelectedSchedule.fiHotelTaxPreRebates.total', function () {
      var doesApplyHotelTax = this.get('districtForSelectedSchedule.doesApplyHotelTax'),
          years = this.get('years'),
          totalHotelTax = this.get('engineForSelectedSchedule.fiHotelTaxPreRebates.total'),
          rebateSchedule = this.get('selectedSchedule.hotelTaxRebateSchedule'),
          doesApplyHotelTaxRebates = this.get('selectedSchedule.doesApplyHotelTaxRebates'),
          output = [];

      if (doesApplyHotelTax && totalHotelTax > 0) {
        var rebateColumn = _column.default.create({
          title: 'Hotel Taxes',
          columnName: 'hotelTaxRebateSchedule',
          inputType: 'percent',
          content: (0, _makeInputs.default)(rebateSchedule, years),
          checkbox: 'true',
          doesApplyName: 'doesApplyHotelTaxRebates',
          doesApply: doesApplyHotelTaxRebates
        });
        output.push(rebateColumn);
      }

      return output;
    }),
    unboundHotelScheduleInputs: Ember.computed('years', 'selectedSchedule', function () {
      return this.get('hotelScheduleInputs');
    }),

    // show notification if percent totals don't match what's displayed on the template
    attributionPercentNotWhole: Ember.computed('attributionTotals.percent', function () {
      return numeral(this.get('attributionTotals.percent')).format('0,0.0%') === '100.0%' ? '' : '*';
    }),

    // support
    communityIncentivesDisabled: Ember.computed('isScenarioDisabled', 'scenario.communityIncentiveGroupIds.length', 'communityNetBenefitsTotal', 'participatingTifDistrict', function () {
      return (this.get('isScenarioDisabled') || !this.get('scenario.communityIncentiveGroupIds.length') || this.get('communityNetBenefitsTotal') <= 0) && !this.get('participatingTifDistrict');
    }),

    // support
    attributionInputMethod: Ember.computed('scenario.communityIncentiveSchedule.attributionApproach', 'attributionOptions', function () {
      var approach = this.get('scenario.communityIncentiveSchedule.attributionApproach');
      var options = this.get('attributionOptions');

      return options.findBy('val', approach);
    }),

    // array of group models participating in community incentive
    selectedCommunityGroups: Ember.computed('scenario.communityIncentiveGroupIds.[]', 'sortedDistrictGroups.[]', function () {
      var _this = this;

      return this.get('scenario.communityIncentiveGroupIds').map(function (groupId) {
        return _this.get('sortedDistrictGroups').findBy('id', groupId);
      }).sortBy('groupOrder');
    }),

    communityNetBenefitsTotal: Ember.computed.sum('communityNetBenefits'),

    // update allocation percents when net benefits for participating districts change
    watchCommunityNetBenefits: Ember.observer('communityImpactEngines.@each.netBenefitsTotal', function () {
      var communityImpactEngines = this.get('communityImpactEngines'),
          communityEngineTotals = communityImpactEngines.mapBy('netBenefitsTotal'),
          oldNetBenefits = this.get('oldNetBenefitsTotals');
      var needsUpdate = false;

      if (!oldNetBenefits.length) {
        this.set('oldNetBenefitsTotals', communityEngineTotals);
      }

      needsUpdate = !(0, _arrayCompare.default)(oldNetBenefits, communityEngineTotals);

      if (needsUpdate && this.get('scenario.communityIncentiveSchedule.attributionApproach') === 'proportional') {
        // debounce to give communityNetBenefitsTotal a chance to update
        Ember.run.debounce(this, this.sendUpdateCommunityAttributionAction, 1000);
        this.set('oldNetBenefitsTotals', communityEngineTotals);
      }
    }),

    // intermediate action to allow us to debounce action
    sendUpdateCommunityAttributionAction: function sendUpdateCommunityAttributionAction() {
      this.send('updateCommunityAttributionPercents');
    },


    // I suspect that many of these will endu up directly on here or on the settings route
    actions: {
      updateDoesApply: function updateDoesApply(doesApplyName, doesApply) {
        var schedule = this.get('selectedSchedule');
        schedule.set(doesApplyName, doesApply);
        this.send('saveSchedule', schedule);
      },


      // triggered when editing tif incentives by year
      updateTifSchedule: function updateTifSchedule(columns) {
        var schedule = this.get('selectedSchedule'),
            tifIncentives = this.get('tifIncentives'),
            tifTotal = this.get('tifTotals.net');

        columns.forEach(function (column) {
          if (column.get('columnName')) {
            var values = (0, _stringify.default)(column.get('cellValues'));
            schedule.set(column.get('columnName'), values);
          }
        });

        if (tifIncentives.get('tifDuration') && tifIncentives.get('totalIncentive') > tifTotal + 1) {
          this.get('notify').alert('Incentive amount should not be greater than total TIF revenue.', { closeAfter: 4000 });
        }

        this.send('saveSchedule', schedule);
      },


      // action handler for when tif incentives are entered as percent of tif total
      updateIncentivesBasedOnTifPercentages: function updateIncentivesBasedOnTifPercentages(percents) {
        var schedule = this.get('selectedSchedule');
        schedule.set('percentOfTif', percents);
        this.send('saveSchedule', schedule);
      },
      saveIncentives: function saveIncentives() {
        this.send('saveSchedule', this.get('selectedSchedule'));
      },
      loadDemoData: function loadDemoData() {
        this.get('scenarioController').send('loadDemoData');
      },
      clearDemoData: function clearDemoData() {
        this.get('scenarioController').send('clearDemoData');
      },
      saveSchedule: function saveSchedule(schedule) {
        if (!this.get('currentUser.isDemo') && this.get('isSavable') && schedule) {
          schedule.save();
        }
      },
      updateSchedule: function updateSchedule(columns) {
        var schedule = this.get('selectedSchedule');
        columns.forEach(function (column) {
          if (column.get('columnName')) {
            var values = (0, _stringify.default)(column.get('cellValues'));
            schedule.set(column.get('columnName'), values);
          }
        });

        this.send('saveSchedule', schedule);
      },
      updateDistrictIncentiveTerm: function updateDistrictIncentiveTerm(incentiveType, selected) {
        var chosenTerm = selected.split(' ').pop();
        var schedule = this.get('selectedSchedule');
        schedule.set(incentiveType, chosenTerm);

        this.send('saveSchedule', schedule);
      },


      // when districts are added or removed from participating districts
      updateCommunityAttributionPercents: function updateCommunityAttributionPercents() {
        var _this2 = this;

        var communityImpactEngines = this.get('communityImpactEngines'),
            communityDistrictsLength = communityImpactEngines.get('length'),
            attributionApproach = this.get('scenario.communityIncentiveSchedule.attributionApproach'),
            canSave = !this.get('currentUser.isDemo') && this.get('isSavable');

        // don't do anything if custom percents are used
        if (communityDistrictsLength === 0) {
          return;
        }

        var relevantCommunityEngines = communityImpactEngines.filter(function (engine) {
          return engine.get('netBenefitsTotal') >= 0;
        });
        var relevantNetBenefitsTotal = (0, _sumArray.default)(relevantCommunityEngines.mapBy('netBenefitsTotal'));

        var percent = void 0;
        // loop through each participating incentive-schedule and assign percentage
        var promises = communityImpactEngines.map(function (engine) {
          var schedule = engine.get('schedule');

          // equal percents
          if (attributionApproach === 'equal') {
            percent = 1 / relevantCommunityEngines.get('length');
          }

          // proportional percents
          if (attributionApproach === 'proportional') {
            percent = engine.get('netBenefitsTotal') / relevantNetBenefitsTotal;
          }

          if (attributionApproach === 'custom') {
            percent = schedule.get('communityAttributionPercent') || 0;
          }

          if (engine.get('netBenefitsTotal') < 0) {
            percent = 0;
          }

          schedule.set('communityAttributionPercent', percent.toString());
          return canSave ? schedule.save() : Ember.RSVP.resolve();
        });

        // this needs to happen after incentives have been set
        Ember.RSVP.all(promises).then(function () {
          _this2.send('updateCommunityIncentiveSchedules');
        });
      },


      // update this whenever new districts are added to participating, community incentive amounts change, attribution approach changes
      updateCommunityIncentiveSchedules: function updateCommunityIncentiveSchedules() {
        var communityIncentiveSchedule = this.get('scenario.communityIncentiveSchedule'),
            communityIncentives = (0, _floatMap.default)(communityIncentiveSchedule.get('incentives')),
            schedules = this.get('selectedCommunitySchedules'),
            canSave = !this.get('currentUser.isDemo') && this.get('isSavable');

        var promises = schedules.map(function (schedule) {
          var attribution = parseFloat(schedule.get('communityAttributionPercent')),
              incentives = [];

          for (var i = communityIncentives.length - 1; i >= 0; i--) {
            incentives[i] = (communityIncentives[i] * attribution).toFixed(4);
          }
          schedule.set('incentives', incentives);
          return canSave ? schedule.save() : Ember.RSVP.resolve();
        });

        Ember.RSVP.all(promises);
        this.toggleProperty('incentivesUpdated'); // update unboundIncentiveInputs
      },


      // distinguish between group ids and tif district, since both are selectable
      precheckParticipatingDistricts: function precheckParticipatingDistricts(selectedDistricts) {
        var _this3 = this;

        var taxDistricts = selectedDistricts.filter(function (district) {
          return _this3.get('scenario.districts').includes(district);
        });
        var tifSelected = selectedDistricts.includes(this.get('tifDistrict'));
        var tifPreviouslyIncluded = this.get('scenario.communityIncentiveIncludesTif');
        // if tif district was added
        if (tifSelected && !tifPreviouslyIncluded) {
          this.send('checkIncludeTifDistrict');
        }

        // if tif district was removed
        if (!tifSelected && tifPreviouslyIncluded) {
          this.send('removeTifDistrict');
        }

        this.send('checkParticipatingDistricts', taxDistricts);
      },


      // action called when district is selected or deselected from participating community districts dropdown
      checkParticipatingDistricts: function checkParticipatingDistricts(selectedDistricts) {
        var _this4 = this;

        var currentDistricts = this.get('selectedCommunityDistricts');

        // if we remove a group here, we need to clear out their incentives
        currentDistricts.forEach(function (district) {
          if (!selectedDistricts.includes(district)) {
            var schedule = _this4.get('scenario').getScheduleForDistrict(district.get('parentGroup'));
            if (schedule) {
              // won't be a schedule for tif district (this is handled elsewhere)
              schedule.set('incentives', (0, _arrayOfLength.default)(_this4.get('years')));
              _this4.send('saveSchedule', schedule);
            }
          }
        });

        // if we add a district, check if it already has incentives
        var newDistrict = _array2.default.differenceBy(selectedDistricts, currentDistricts, 'id');
        if (newDistrict.length) {
          newDistrict = newDistrict[0];
          var schedule = this.get('scenario').getScheduleForDistrict(newDistrict.get('parentGroup')),
              scheduleIncentives = _array2.default.compact((0, _floatMap.default)(schedule.get('incentives')));

          // if this district has existing incentives, open confirmation modal
          if (scheduleIncentives.length) {
            this.set('pendingCommunityDistricts', selectedDistricts);
            this.set('includeCommunityDistrictAction', 'includeCommunityDistrict');
            this.set('districtToCheck', newDistrict);
            this.set('isConfirmCommunityDistrictHidden', false);
            return;
          }
        }

        this.send('updateParticipatingDistricts', selectedDistricts);
      },


      // action called after confirming a district with existing incentive should be included
      includeCommunityDistrict: function includeCommunityDistrict() {
        this.set('isConfirmCommunityDistrictHidden', true);
        this.send('updateParticipatingDistricts', this.get('pendingCommunityDistricts'));
      },


      // action called after confirming a tif with existing incentive should be included
      includeCommunityTifDistrict: function includeCommunityTifDistrict() {
        this.set('isConfirmCommunityDistrictHidden', true);
        this.set('scenario.communityIncentiveIncludesTif', true);
        this.get('scenarioController').send('save');
        this.send('updateCommunityAttributionPercents');
      },
      checkIncludeTifDistrict: function checkIncludeTifDistrict() {
        var tifIncentives = _array2.default.compact((0, _floatMap.default)(this.get('scenario.tifIncentive.incentives'))),
            tifToCheck = Ember.Object.create({ name: this.get('tifDistrict.name') });

        // if this district has existing incentives, open confirmation modal
        if (tifIncentives.length) {
          this.set('includeCommunityDistrictAction', 'includeCommunityTifDistrict');
          this.set('districtToCheck', tifToCheck);
          this.set('isConfirmCommunityDistrictHidden', false);
          return;
        }
        this.send('includeCommunityTifDistrict');
      },
      removeTifDistrict: function removeTifDistrict() {
        // clear out tif incentives
        var schedule = this.get('scenario.tifIncentive');
        if (schedule) {
          schedule.set('incentives', (0, _arrayOfLength.default)(this.get('years')));
          this.send('saveSchedule', schedule);
        }
        this.set('scenario.communityIncentiveIncludesTif', false);
        this.get('scenarioController').send('save');
        this.send('updateCommunityAttributionPercents');
      },
      updateParticipatingDistricts: function updateParticipatingDistricts(selectedDistricts) {
        // save array of group ids even though we're selecting districts
        this.set('scenario.communityIncentiveGroupIds', selectedDistricts.mapBy('parentGroup.id'));
        this.get('scenarioController').send('save');
        this.send('updateCommunityAttributionPercents');
      },
      updateCommunitySchedule: function updateCommunitySchedule(columns) {
        this.send('updateSchedule', columns);
        this.send('updateCommunityIncentiveSchedules');
      },
      saveCommunityIncentives: function saveCommunityIncentives() {
        this.send('saveIncentives');
        this.send('updateCommunityIncentiveSchedules');
      },
      selectAttributionApproach: function selectAttributionApproach(selectedApproach) {
        var selectedSchedule = this.get('selectedSchedule');

        selectedSchedule.set('attributionApproach', selectedApproach.get('val'));
        this.send('saveSchedule', selectedSchedule);
        this.send('updateCommunityAttributionPercents');
      }
    }
  });
});