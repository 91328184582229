define('dashboard/models/tax-districts-upload', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    taxDistricts: (0, _relationships.hasMany)('taxDistrict'),
    // TODO customCostRevenue ?
    // TODO directCostRevenue ?
    file: (0, _attr.default)('file')
  });
});