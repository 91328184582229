define('dashboard/routes/raw-data-preview', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    titleToken: 'Submitted Data Form',

    model: function model() {
      var scenario = this.modelFor('scenario').scenario;
      var client = this.modelFor('scenario').client;
      var project = this.modelFor('scenario').project;
      var requestTemplate = scenario.get('requestTemplate.content');
      if (!requestTemplate) {
        requestTemplate = this.store.createRecord('requestTemplate');
        scenario.set('requestTemplate', requestTemplate);
      }

      return {
        scenario: scenario,
        requestTemplate: requestTemplate,
        client: client,
        project: project
      };
    }
  });
});