define('dashboard/models/district-area', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    districtAreaSet: (0, _relationships.belongsTo)('districtAreaSet'),

    primaryGroup: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    map: (0, _attr.default)(),
    file: (0, _attr.default)(),
    created: (0, _attr.default)('date'),
    modifiedForScenario: (0, _attr.default)('string'),
    createdInTransition: (0, _attr.default)('boolean', { defaultValue: false })

  });
});