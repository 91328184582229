define('dashboard/utils/sum-many-arrays', ['exports', 'dashboard/utils/sum-arrays', 'dashboard/utils/float-map'], function (exports, _sumArrays, _floatMap) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var sumManyArrays = function sumManyArrays(arrays) {
    var result = arrays.length > 0 ? (0, _floatMap.default)(arrays.pop()) : [];

    while (arrays.length > 0) {
      result = (0, _sumArrays.default)(result, (0, _floatMap.default)(arrays.pop()));
    }

    return result;
  };

  exports.default = sumManyArrays;
});