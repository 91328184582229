define('dashboard/components/button-panel', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    actions: {
      updateProperty: function updateProperty(option) {
        this.set('model.' + this.get('property'), option);
        this.save();
      }
    }
  });
});