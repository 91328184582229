define('dashboard/mixins/input-sizes', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({

    classNameBindings: ['labelSize', 'inputSize'],

    labelClass: Ember.computed('labelSize', function () {
      switch (this.get('labelSize')) {
        case 'label-auto':
          return 'col-sm-auto';
        case 'label-xs':
          return 'col-lg-2';
        case 'label-sm':
          return 'col-lg-3';
        case 'label-md':
          return 'col-lg-6';
        case 'label-lg':
          return 'col-sm-12';
        case 'label-fill':
          return 'col';
        default:
          return '';
      }
    }),

    inputClass: Ember.computed('inputSize', function () {
      switch (this.get('inputSize')) {
        case 'input-auto':
          return 'col-sm-auto';
        case 'input-xs':
          return 'col-md-2 col-sm-4';
        case 'input-sm':
          return 'col-lg-3';
        case 'input-md':
          return 'col-md-6';
        case 'input-lg':
          return 'col-sm-12';
        case 'input-full':
          return 'col-sm-12';
        case 'input-fill':
          return 'col';
        default:
          return '';
      }
    })

  });
});