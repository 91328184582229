define('dashboard/models/direct-cost-revenue', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    name: (0, _attr.default)('string'),
    type: (0, _attr.default)('string'),
    increaseType: (0, _attr.default)('string', { defaultValue: 'advanced' }),
    annualPercentIncrease: (0, _attr.default)('string', { defaultValue: '0.02' }),
    doesApplyDirectCostRevenue: (0, _attr.default)('boolean', { defaultValue: true }),

    modifiedProperties: (0, _attr.default)({ defaultValue: function defaultValue() {
        return {};
      } }),

    adminCreated: (0, _attr.default)('boolean', { defaultValue: true }),
    deleted: (0, _attr.default)('boolean', { defaultValue: false }),
    copyOfDirect: (0, _attr.default)('string'),

    isCascading: Ember.computed.equal('increaseType', 'cascading')
  });
});