define('dashboard/utils/copy-data-request', ['exports', 'dashboard/utils/copy-assumption'], function (exports, _copyAssumption) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var copyDataRequest = function copyDataRequest(scenario, store) {

    return scenario.get('client').then(function (client) {

      // if the scenario we're revising has an assumption model, copy that one
      // otherwise copy client's default assumptions
      var assumptionModel = scenario.get('assumption.id') ? scenario.get('assumption') : client.get('assumption');

      // get default assumptions from client model
      return assumptionModel.then(function (assumption) {
        // copy the assumption
        return (0, _copyAssumption.default)(assumption, store).then(function (assumption) {
          return Ember.RSVP.hash({
            oldScenario: scenario,
            assumption: assumption
          });
        });
      });
    }).then(function (_ref) {
      var oldScenario = _ref.oldScenario,
          assumption = _ref.assumption;


      // here's where we copy the scenario
      var newScenario = oldScenario.toJSON();

      for (var key in newScenario) {
        if (newScenario[key] !== oldScenario.get(key)) {
          newScenario[key] = oldScenario.get(key);
        }
      }

      newScenario.id = null;
      delete newScenario.districts; // shouldn't have these anyway
      delete newScenario.created;
      newScenario.created = new Date();

      newScenario.isCopyingScenario = true;

      // data request specific properties
      delete newScenario.submittedOn;
      delete newScenario.project; // if request was already assigned, unassign so it goes to "in progress" tab
      delete newScenario.startedOn; // show bold on inbox screen
      delete newScenario.visitedDataForm; // show bold on inbox screen
      newScenario.revisionOfScenario = oldScenario; // keep reference to original data form or latest revised version - whichever data form is directly copied from
      newScenario.walkthroughCompleted = false;
      newScenario.requestVersion = oldScenario.get('requestVersion') || 1; // bump version # if revisions of revisions are shared

      newScenario.assumption = assumption;
      var newScenarioModel = store.createRecord('scenario', newScenario);

      return Ember.RSVP.hash({
        scenario: newScenarioModel.save(),
        assumption: assumption
      });
    }).then(function (_ref2) {
      var scenario = _ref2.scenario,
          assumption = _ref2.assumption;

      // update assumption - scenario relationship
      assumption.set('scenario', scenario);
      assumption.save();
      return scenario;
    });
  };

  exports.default = copyDataRequest;
});