define('dashboard/objects/impact-engine-total', ['exports', 'dashboard/utils/get-aggregate-engine-prop', 'dashboard/objects/array-compute', 'dashboard/objects/fi-tax/base-aggregate', 'dashboard/objects/impact-engine'], function (exports, _getAggregateEngineProp, _arrayCompute, _baseAggregate, _impactEngine) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /* Utils */
  var TotalImpactEngine = _impactEngine.default.extend({
    // add or override properties in the Impact-Engine to fit the needs of current + expansion operations

    name: 'totalEngine',

    // this property will allow us to better aggregate engine properties our current + expansion
    engines: Ember.computed('currentEngine', 'expansionEngine', function () {
      return [this.get('currentEngine'), this.get('expansionEngine')];
    }),

    getDirectIndirectBasics: function getDirectIndirectBasics(property) {
      var engines = this.get('engines');
      return Ember.Object.create({
        direct: (0, _getAggregateEngineProp.getAggregateProperty)(engines, property + '.direct'),
        indirect: (0, _getAggregateEngineProp.getAggregateProperty)(engines, property + '.indirect'),
        total: (0, _getAggregateEngineProp.getAggregateProperty)(engines, property + '.total')
      });
    },

    getArrayComputeBasics: function getArrayComputeBasics(property) {
      var engines = this.get('engines');
      return _arrayCompute.default.create({
        direct: (0, _getAggregateEngineProp.getAggregateArrayProperty)(engines, property + '.direct'),
        indirect: (0, _getAggregateEngineProp.getAggregateArrayProperty)(engines, property + '.indirect')
      });
    },

    /* JOBS */
    eiJobs: Ember.computed('engines.@each.{eiJobs,eiJobsLastTotalCumulative}', function () {
      return this.getArrayComputeBasics('eiJobs');
    }),

    /* SALARIES */
    eiSalaries: Ember.computed('engines.@each.eiSalaries', function () {
      return this.getArrayComputeBasics('eiSalaries');
    }),

    /* SALARIES AT FULL OPS */
    eiSalariesAtFullOps: Ember.computed('engines.@each.eiSalariesAtFullOps', function () {
      return this.getDirectIndirectBasics('eiSalariesAtFullOps');
    }),

    /* TAXABLE SALES */
    eiTaxableSalesAndPurchases: Ember.computed('engines.@each.eiTaxableSalesAndPurchases', function () {
      return this.getDirectIndirectBasics('eiTaxableSalesAndPurchases');
    }),

    /* PROPERTY OVERRIDES */
    // no new workers moving to area in current operations, so just use expansion here
    eiWorkersMovingToArea: Ember.computed('engines.@each.eiWorkersMovingToArea', function () {
      return this.get('expansionEngine.eiWorkersMovingToArea');
    }),

    eiValueOfTaxableProperty: Ember.computed('engines.@each.eiValueOfTaxableProperty', function () {
      var engines = this.get('engines');

      var _addPropertyAtIndex = function _addPropertyAtIndex(prop, index) {
        return engines.reduce(function (sum, engine) {
          return sum + engine.get('eiValueOfTaxableProperty')[index].get(prop);
        }, 0);
      };

      return engines.get('firstObject.eiValueOfTaxableProperty').map(function (item, index) {
        return Ember.Object.create({
          year: item.year,
          residential: _addPropertyAtIndex('residential', index),
          land: _addPropertyAtIndex('land', index),
          buildings: _addPropertyAtIndex('buildings', index),
          furniture: _addPropertyAtIndex('furniture', index),
          inventory: _addPropertyAtIndex('inventory', index),
          subtotal: _addPropertyAtIndex('subtotal', index),
          total: _addPropertyAtIndex('total', index)
        });
      });
    }),

    getProjectWorkerBasics: function getProjectWorkerBasics(property) {
      var engines = this.get('engines');
      return _baseAggregate.default.create({ engines: engines, property: property });
    },

    setProjectWorkerProperties: Ember.observer('engines.@each.eiJobsLastTotalCumulative', function () {
      var _this = this;

      this.get('projectWorkerProperties').forEach(function (prop) {
        _this.set(prop, _this.getProjectWorkerBasics(prop));
      });
    }),

    // need this otherwise values aren't updated in real time after initial page load
    recalculateFIProps: Ember.observer('engines.@each.fiSalesTaxes', 'engines.@each.fiLocalIncomeTaxes', 'engines.@each.fiRealPropertyTaxes', 'engines.@each.fiPropertyTaxesFFE', 'engines.@each.fiPropertyTaxesInventories', 'engines.@each.fiPropertyTaxesResidential', 'engines.@each.fiHotelTaxes', 'engines.@each.fiUtility', 'engines.@each.fiUtilityFranchiseFees', 'engines.@each.fiMiscellaneousFees', 'engines.@each.fiStateFederalSchoolFunding', 'engines.@each.costUtilityServices', 'engines.@each.costGovernmentServices', 'engines.@each.costEducateNewStudents', 'engines.@each.costReductionInStateSchoolFunding', function () {
      var _this2 = this;

      this.get('projectWorkerProperties').forEach(function (prop) {
        _this2.set(prop, _this2.getProjectWorkerBasics(prop));
      });
    }),

    recalculateArrayProps: Ember.observer('engines.@each.fiPropertyTaxLand', 'engines.@each.fiPropertyTaxBuildings', 'engines.@each.fiSalesTaxTaxableSales', 'engines.@each.fiSalesTaxConstructionMaterials', 'engines.@each.fiSalesTaxConstructionWorkerSpending', 'engines.@each.fiSalesTaxEquipment', 'engines.@each.fiSalesTaxDirectWorkerSpending', 'engines.@each.fiSalesTaxIndirectWorkerSpending', 'engines.@each.fiSalesTaxVisitorSpending', 'engines.@each.fiSalesTaxHotelSpending', 'engines.@each.fiSalesTaxTaxablePurchases', 'engines.@each.fiSalesTaxTaxableSales', function () {
      var _this3 = this;

      this.get('arrayTotalProperties').forEach(function (prop) {
        _this3.set(prop, (0, _getAggregateEngineProp.getAggregateArrayProperty)(_this3.get('engines'), prop));
      });
    }),

    init: function init() {
      var _this4 = this;

      this._super.apply(this, arguments);
      this.set('projectWorkerProperties', ['fiSalesTaxes', 'fiLocalIncomeTaxes', 'fiRealPropertyTaxes', 'fiPropertyTaxesFFE', 'fiPropertyTaxesInventories', 'fiPropertyTaxesResidential', 'fiHotelTaxes', 'fiUtility', 'fiUtilityFranchiseFees', 'fiMiscellaneousFees', 'fiStateFederalSchoolFunding', 'costUtilityServices', 'costGovernmentServices', 'costEducateNewStudents', 'costReductionInStateSchoolFunding']);
      this.get('projectWorkerProperties').forEach(function (prop) {
        _this4.set(prop, _this4.getProjectWorkerBasics(prop));
      });

      // list of array properties which need to be totaled in this engine
      this.set('arrayTotalProperties', ['fiPropertyTaxLand', 'fiPropertyTaxBuildings', 'fiSalesTaxTaxableSales', 'fiSalesTaxConstructionMaterials', 'fiSalesTaxConstructionWorkerSpending', 'fiSalesTaxEquipment', 'fiSalesTaxDirectWorkerSpending', 'fiSalesTaxIndirectWorkerSpending', 'fiSalesTaxVisitorSpending', 'fiSalesTaxHotelSpending', 'fiSalesTaxTaxablePurchases', 'fiSalesTaxTaxableSales']);
      this.get('arrayTotalProperties').forEach(function (prop) {
        _this4.set(prop, (0, _getAggregateEngineProp.getAggregateArrayProperty)(_this4.get('engines'), prop));
      });
    }
  });

  /* Objects */
  exports.default = TotalImpactEngine;
});