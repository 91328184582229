define('dashboard/routes/data-forms/inbox/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    titleToken: 'Inbox',

    model: function model(params) {
      this.setTitleToken(params.selectedTab);
      var client = this.modelFor('data-forms').client;
      var scenarios = this.store.query('scenario', { client: client.id });
      return Ember.RSVP.hash({
        client: client,
        scenarios: scenarios
      });
    },


    setTitleToken: function setTitleToken(selectedTab) {
      var title = '';
      switch (selectedTab) {
        case 'in-progress':
          title = 'In Progress';
          break;
        case 'assigned':
          title = 'Assigned';
          break;
        case 'archived':
          title = 'Deleted';
          break;
        default:
          title = 'Inbox';
          break;
      }
      this.set('titleToken', title);
    },

    actions: {
      queryParamsDidChange: function queryParamsDidChange(params) {
        this.setTitleToken(params.selectedTab);
      }
    }
  });
});