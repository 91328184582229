define('dashboard/components/modals/multiplier-upload-modal', ['exports', 'dashboard/components/modals/modal-confirm'], function (exports, _modalConfirm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _modalConfirm.default.extend({

    notify: Ember.inject.service(),

    uploadingProgress: false,

    disableButton: Ember.computed('newMultiplierSetName', 'uploadingProgress', 'multiplierSetUpload', function () {
      var noNameEntered = this.get('newMultiplierSetName').trim().length <= 0,
          uploadingProgress = this.get('uploadingProgress'),
          fileSelected = this.get('multiplierSetUpload');

      return noNameEntered || uploadingProgress || fileSelected === null;
    }),

    cleanFileName: function cleanFileName(name) {
      return name.split('.xl')[0];
    },


    actions: {
      submit: function submit() {
        var _this = this;

        this.set('uploadingProgress', true);

        this.onSubmit().then(function () /*createdMultiplierSet*/{
          resetUploadField();
          _this.get('notify').info('Multiplier Set Created Successfully.');
          _this.send('cancel');
        }, function (failure) {
          console.log('multiplier upload fail ', failure);
          _this.set('uploadingProgress', false);
          _this.get('notify').alert('There was an error uploading this file');
        });
      },
      cancel: function cancel() {
        var _this2 = this;

        resetUploadField();
        this.set('animationOut', true);
        setTimeout(function () {
          _this2.setProperties({
            'newMultiplierSetName': '',
            'multiplierSetUpload': null,
            'isHidden': true,
            'uploadingProgress': false,
            'animationOut': false
          });
        }, 200);
      },
      multiplierSetUploaded: function multiplierSetUploaded(file) {
        this.set('multiplierSetUpload', file);
        if (!this.get('newMultiplierSetName')) {
          this.set('newMultiplierSetName', this.cleanFileName(file.name));
        }
      }
    }

  });


  function resetUploadField() {
    Ember.$('.upload-component').wrap('<form>').closest('form').get(0).reset();
    Ember.$('.upload-component').unwrap();
  }
});