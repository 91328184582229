define('dashboard/components/community-incentives', ['exports', 'dashboard/utils/array-of-length', 'dashboard/components/district-incentives'], function (exports, _arrayOfLength, _districtIncentives) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _districtIncentives.default.extend({

    incentivesToUse: Ember.computed('currentVsExpansion', 'schedule.incentives', 'years', function () {
      if (this.get('currentVsExpansion') === 'current') {
        return (0, _arrayOfLength.default)(this.get('years'));
      }
      return this.get('schedule.incentives');
    }),

    communityDistrictNames: Ember.computed('communityDistricts.@each.name', function () {
      return this.get('communityDistricts').mapBy('name').join(', ');
    }),

    actions: {
      checkParticipatingDistricts: function checkParticipatingDistricts(selectedDistricts) {
        this.sendAction('checkParticipatingDistrictsHandler', selectedDistricts);
      },
      updateCommunitySchedule: function updateCommunitySchedule(columns) {
        this.sendAction('updateScheduleHandler', columns);
      },
      saveIncentives: function saveIncentives() {
        this.sendAction('saveIncentivesHandler');
      }
    }
  });
});