define('dashboard/routes/layout/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    ajax: Ember.inject.service(),

    redirect: function redirect() {
      var _this = this;

      var user = this.modelFor('authenticated');

      if (user.get('isSuperAdmin')) return this.transitionTo('clients');

      if (user.get('isDemo')) {
        this.transitionTo('loading'); // Prevents disappearence of loading screen for a second
        return this.get('ajax').request('/demo/info').then(function (data) {
          _this.transitionTo('scenario', data.info.client, data.info.project, data.info.scenario);
        });
      }

      if (user.get('isClient')) return this.transitionTo('insights', user.get('client.id'));
    }
  });
});