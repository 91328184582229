define('dashboard/objects/incentive-engine', ['exports', 'lodash/array', 'dashboard/utils/float-map', 'dashboard/utils/array-of-length', 'dashboard/utils/sum-array', 'dashboard/utils/sum-arrays', 'dashboard/utils/cumulative-sum', 'dashboard/utils/stringify'], function (exports, _array2, _floatMap, _arrayOfLength, _sumArray, _sumArrays, _cumulativeSum, _stringify) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({

    notify: Ember.computed.alias('scenario.notify'),

    extendedYears: 10,

    netBenefits: Ember.computed.sum('annualNetBenefits'),

    incentivesToUse: Ember.computed('engineIncentives', 'schedule.incentives', 'assumption.scenarioLength', function () {
      var incentives = this.get('engineIncentives') ? (0, _floatMap.default)(this.get('engineIncentives')) : (0, _floatMap.default)(this.get('schedule.incentives'));
      return incentives.length ? incentives : (0, _arrayOfLength.default)(this.get('assumption.scenarioLength'));
    }),

    nonTaxIncentives: Ember.computed('incentivesToUse', function () {
      var incentives = this.get('incentivesToUse');
      return Ember.Object.create({
        annual: incentives,
        total: (0, _sumArray.default)(incentives)
      });
    }),

    incentives: Ember.computed('nonTaxIncentives.annual', 'assumption.{abatementsAsCosts,scenarioLength}', 'otherIncentives', function () {

      var incentives = this.get('nonTaxIncentives.annual'),
          abatementsAsCosts = this.get('assumption.abatementsAsCosts');

      if (abatementsAsCosts && this.get('incentiveComparison') === 'total') {
        var otherIncentives = this.get('otherIncentives') || [];
        return (0, _stringify.default)((0, _sumArrays.default)(incentives, otherIncentives));
      }

      return incentives.slice(0, this.get('assumption.scenarioLength'));
    }),

    indexOfLastYearWithIncentive: Ember.computed('incentives', function () {
      var incentives = (0, _floatMap.default)(this.get('incentives')),
          lastIndex = incentives.length - 1,
          i = void 0;

      for (i = lastIndex; i >= 0; i--) {
        if (incentives[i] && i > 0) {
          return i;
        }
      }
      return 0;
    }),

    totalIncentiveSummaryReport: Ember.computed('incentives', function () {
      var incentives = (0, _floatMap.default)(this.get('incentives'));
      return (0, _sumArray.default)(incentives) * -1;
    }),

    incentivesGraphLegend: Ember.computed('incentives', 'incentivesToUse', 'incentiveComparison', 'assumption.scenarioLength', function () {
      if (this.get('incentiveComparison') === 'total') {
        return this.get('incentives');
      }
      return this.get('incentivesToUse').slice(0, this.get('assumption.scenarioLength'));
    }),

    totalIncentive: Ember.computed('incentives', {
      get: function get() {
        var incentives = (0, _floatMap.default)(this.get('incentives'));
        return (0, _sumArray.default)(incentives);
      },
      set: function set(key, value) {
        var incentives = (0, _floatMap.default)(this.get('incentivesToUse')); // why?
        incentives = [value].concat((0, _arrayOfLength.default)(this.get('assumption.scenarioLength') - 1, 0));
        this.set('schedule.incentives', (0, _stringify.default)(incentives));
        var newTotal = (0, _sumArray.default)(incentives);
        return newTotal;
      }
    }),

    incentiveByYear: Ember.computed('incentivesGraphLegend', function () {
      var incentives = this.get('incentivesGraphLegend'),
          incentivesByYear = [];

      for (var i = 0; i < this.get('assumption.scenarioLength'); i++) {
        incentivesByYear[i] = incentives[i] || 0;
      }

      return incentivesByYear;
    }),

    hasPositiveNetBenefits: Ember.computed.gt('netBenefits', 0),

    hasIncentives: Ember.computed('incentives', 'hasPositiveNetBenefits', function () {
      var totalIncentive = (0, _sumArray.default)((0, _floatMap.default)(this.get('incentives')));
      return totalIncentive !== 0;
    }),

    incentivePerJob: Ember.computed('totalIncentive', 'scenario.model.jobsAddedEachYear', {
      get: function get() {
        var totalIncentive = this.get('totalIncentive'),
            totalJobs = (0, _sumArray.default)((0, _floatMap.default)(this.get('scenario.model.jobsAddedEachYear')));

        return totalJobs > 0 ? totalIncentive / totalJobs : 0;
      },
      set: function set(key, value) {
        // always use jobs from scenario model (expansion jobs)
        // we don't want to apply incentives to total jobs in scenarios with current & expansion
        var newJobs = (0, _floatMap.default)(this.get('scenario.model.jobsAddedEachYear')),
            incentives = [];

        for (var i = 0; i < this.get('assumption.scenarioLength'); i++) {
          incentives[i] = newJobs[i] ? newJobs[i] * value : 0;
        }

        this.set('schedule.incentives', (0, _stringify.default)(incentives));
        return value;
      }
    }),

    incentiveRateOfReturn: Ember.computed('totalIncentive', 'netBenefits', {
      get: function get() {
        var totalIncentive = this.get('totalIncentive'),
            totalBenefits = this.get('netBenefits'),
            years = this.get('assumption.scenarioLength'),
            averageNetBenefitsPerYear = totalBenefits / years,
            output;

        output = parseInt(totalIncentive.toFixed(3)) > 0 ? Math.round(averageNetBenefitsPerYear / totalIncentive * 1000) / 1000 : 0;
        return output.toString();
      },
      set: function set(key, value) {

        var totalIncentive = this.get('totalIncentive'),
            totalBenefits = this.get('netBenefits'),
            years = this.get('assumption.scenarioLength'),
            averageNetBenefitsPerYear = totalBenefits / years,
            output;

        output = parseInt(totalIncentive) > 0 ? Math.round(averageNetBenefitsPerYear / totalIncentive * 1000) / 1000 : 0;

        if (output !== parseFloat(value.toFixed(3))) {
          var abatementsAndRebatesIncentives = 0;

          if (this.get('incentiveComparison') === 'total') {
            abatementsAndRebatesIncentives = (0, _sumArray.default)(this.get('otherIncentives')) * -1 || 0;
          }

          if (value !== 0) {
            totalIncentive = averageNetBenefitsPerYear / value;
          } else {
            totalIncentive = 0;
          }
          totalIncentive += abatementsAndRebatesIncentives;
          this.set('totalIncentive', totalIncentive);
          return value;
        }
      }
    }),

    incentivePaybackPeriod: Ember.computed('totalIncentive', 'extendedNetBenefits', 'extendedCumulativeNetBenefits', {
      get: function get() {
        var incentive = parseFloat(this.get('totalIncentive')),
            netBenefits = this.get('extendedNetBenefits'),
            cumulativeNetBenefits = this.get('extendedCumulativeNetBenefits'),
            initialBenefit,
            lastCumulativeBenefit;

        if (netBenefits && cumulativeNetBenefits) {
          initialBenefit = netBenefits.get('firstObject');
          lastCumulativeBenefit = cumulativeNetBenefits.get('lastObject');
        }
        if (!netBenefits || incentive === 0) {
          return 0;
        }
        if (incentive < initialBenefit) {
          return incentive / initialBenefit;
        }
        if (incentive > lastCumulativeBenefit) {
          this.extendCumulativeBenefits();
        }
        return this.paybackPeriodGivenIncentive(incentive);
      },
      set: function set(key, value) {
        var output = this.get('paybackPeriodOutput');
        if (value !== Math.round(output * 10) / 10 || output <= 0) {
          var newIncentiveTotal = this.incentiveGivenPaybackPeriod(value);
          this.set('totalIncentive', newIncentiveTotal);
          return value;
        }
      }
    }),

    paybackPeriodOutput: Ember.computed('totalIncentive', 'extendedNetBenefits', 'extendedCumulativeNetBenefits', function () {
      var incentive = parseFloat(this.get('totalIncentive')),
          // including abatements and rebates
      netBenefits = this.get('extendedNetBenefits'),
          cumulativeNetBenefits = this.get('extendedCumulativeNetBenefits'),
          initialBenefit,
          lastCumulativeBenefit;

      if (netBenefits && cumulativeNetBenefits) {
        initialBenefit = netBenefits.get('firstObject');
        lastCumulativeBenefit = cumulativeNetBenefits.get('lastObject');
      }
      if (!netBenefits || incentive === 0) {
        return 0;
      }
      if (incentive < initialBenefit) {
        return incentive / initialBenefit;
      }
      if (incentive > lastCumulativeBenefit) {
        this.extendCumulativeBenefits();
      }
      return this.paybackPeriodGivenIncentive(incentive);
    }),

    graphHeader: Ember.computed('incentiveComparison', function () {
      return this.get('incentiveComparison') === 'total' ? 'Total' : 'Non-Tax';
    }),

    showNonTaxIncentivesByYearTable: Ember.computed.gt('indexOfLastYearWithIncentive', 0),

    extendedNetBenefits: Ember.computed('annualNetBenefits', 'extendedYears', function () {
      var netBenefits = this.get('annualNetBenefits') || [],
          extendedYears = this.get('extendedYears'),
          // hardcoded 10
      extendedBenefits = [],
          previousBenefit;
      if (netBenefits) {
        previousBenefit = netBenefits.get('lastObject');
        for (var i = 0; i < extendedYears; i++) {
          var currentValue = previousBenefit + previousBenefit * 0.03;
          extendedBenefits.push(currentValue);
          previousBenefit = currentValue;
        }
      }
      return netBenefits.concat(extendedBenefits);
    }),

    extendedCumulativeNetBenefits: Ember.computed('extendedNetBenefits', function () {
      var extendedNetBenefits = this.get('extendedNetBenefits');
      if (extendedNetBenefits) {
        return (0, _cumulativeSum.default)(extendedNetBenefits);
      }
      return [];
    }),

    incentiveGivenPaybackPeriod: function incentiveGivenPaybackPeriod(value) {
      var incentive = 0,
          netBenefits = this.get('extendedNetBenefits'),
          cumulativeNetBenefits = this.get('extendedCumulativeNetBenefits'),
          netBenefitsLength = this.get('annualNetBenefits.length') || 10,

      // This is easier than floating point modulus in javascript.
      numberParts = value.toString().split('.'),
          partialYear = parseFloat('0.' + numberParts[1]),
          wholeYear = parseInt(numberParts[0]),
          fractionalBenefit;

      if (isNaN(wholeYear)) {
        return 0;
      }
      partialYear = isNaN(partialYear) ? 0 : partialYear;
      if (wholeYear > 0) {
        if (wholeYear >= netBenefitsLength + this.get('extendedYears')) {
          this.set('extendedYears', wholeYear);
          netBenefits = this.get('extendedNetBenefits');
          cumulativeNetBenefits = this.get('extendedCumulativeNetBenefits');
        }
        incentive = cumulativeNetBenefits[wholeYear - 1];
        if (this.get('incentiveComparison') === 'total') {
          var otherIncentives = (0, _sumArray.default)(this.get('otherIncentives')) || 0;
          incentive -= otherIncentives;
        }
      }
      fractionalBenefit = netBenefits[wholeYear] * partialYear;
      incentive += fractionalBenefit;
      return incentive;
    },

    paybackPeriodGivenIncentive: function paybackPeriodGivenIncentive(incentive) {
      var netBenefits = this.get('extendedNetBenefits'),
          cumulativeNetBenefits = this.get('extendedCumulativeNetBenefits'),
          wholeYear,
          partialYear;

      wholeYear = _array2.default.findIndex(cumulativeNetBenefits, function (benefit) {
        return incentive <= benefit + 1;
      });
      if (wholeYear === -1) {
        return netBenefits.length + '+ Yrs';
        // return "N/A";
      }
      if (wholeYear === 0) {
        // if first index, this breaks below where we do [wholeYear - 1]
        wholeYear = 1;
      }
      partialYear = (incentive - cumulativeNetBenefits[wholeYear - 1]) / netBenefits[wholeYear];
      return wholeYear + partialYear;
    },

    extendCumulativeBenefits: function extendCumulativeBenefits() {
      while (this.get('extendedYears') < 90 && this.get('extendedCumulativeNetBenefits.lastObject') !== 0) {
        this.set('extendedYears', this.get('extendedYears') + 10);
      }
    }

  });
});