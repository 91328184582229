define('dashboard/components/form-group-select', ['exports', 'dashboard/mixins/input-sizes'], function (exports, _inputSizes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_inputSizes.default, {

    classNames: ['form-group'],
    classNameBindings: ['formDefault:form-group-default:form-group-inline']

  });
});