define('dashboard/components/scenario-row', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['tile-section-collapsable'],

    scenarioCopyModalHidden: true,

    actions: {
      openCopyScenarioModal: function openCopyScenarioModal() {
        var scenario = this.get('scenario');
        if (scenario.get('districtsTransitioned')) {
          this.set('scenarioCopyModalHidden', false);
        } else {
          this.sendAction('copyScenarioHandler', scenario, true);
        }
      },
      removeScenarioAction: function removeScenarioAction() {
        this.sendAction('removeScenarioHandler', this.get('scenario'));
      },
      copyScenarioAction: function copyScenarioAction(withDistricts) {
        this.sendAction('copyScenarioHandler', this.get('scenario'), withDistricts);
      }
    }
  });
});