define('dashboard/routes/client/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    controllerName: 'client',

    ajax: Ember.inject.service(),

    model: function model() {
      var clientModel = this.modelFor('client'),
          clientId = clientModel.client.get('id'),
          tifDistrictsPromise = this.store.query('tifDistrict', { client: clientId, deleted: false }),
          districtAreaSetsPromise = this.store.findAll('districtAreaSet'),
          dependentTaxDistrictSetsPromise = this.store.findAll('dependentTaxDistrictSet'),
          dependentDistrictGroupLinksPromise = this.store.query('dependentDistrictGroupLink', { client: clientId }).then(function (links) {
        return links.toArray();
      }),
          assumptionPromise = this.store.query('defaultAssumption', { client: clientId }).then(function (arr) {
        return arr.get('firstObject');
      }),
          projectsPromise = this.store.query('project', { client: clientId }).then(function (projects) {
        return projects.toArray();
      }),
          tagsPromise = this.store.query('tag', { client: clientId }).then(function (tags) {
        return tags.toArray();
      });

      return Ember.RSVP.hash({
        projects: projectsPromise,
        tifDistricts: tifDistrictsPromise,
        districtAreaSets: districtAreaSetsPromise,
        dependentTaxDistrictSets: dependentTaxDistrictSetsPromise,
        dependentDistrictGroupLinks: dependentDistrictGroupLinksPromise,
        defaultAssumption: assumptionPromise,
        tags: tagsPromise
      });
    },
    setupController: function setupController(controller, models) {
      var clientModel = this.modelFor('client');
      var client = clientModel.client;
      var user = clientModel.user;

      controller.setProperties({
        client: client,
        user: user,
        assumption: models.defaultAssumption,
        tifDistricts: models.tifDistricts,
        districtAreaSets: models.districtAreaSets,
        dependentTaxDistrictSets: models.dependentTaxDistrictSets,
        dependentDistrictGroupLinks: models.dependentDistrictGroupLinks,
        tags: models.tags,
        s3UserFiles: []
      });

      this._super(controller, client);
    }
  });
});