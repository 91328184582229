define('dashboard/mixins/incentive-terms', ['exports', 'dashboard/utils/conjugate-incentive-term'], function (exports, _conjugateIncentiveTerm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({

    propertyTaxIncentiveTerm: Ember.computed('schedule.propertyTaxIncentiveTerm', 'scenario.model.assumption.propertyTaxIncentiveTerm', function () {
      var districtTerm = this.get('schedule.propertyTaxIncentiveTerm');
      var defaultAssumptionTerm = this.get('scenario.model.assumption.propertyTaxIncentiveTerm');

      return districtTerm ? districtTerm : defaultAssumptionTerm;
    }),

    materialsEquipmentTaxIncentiveTerm: Ember.computed('schedule.materialsEquipmentTaxIncentiveTerm', 'scenario.model.assumption.materialsEquipmentTaxIncentiveTerm', function () {
      var districtTerm = this.get('schedule.materialsEquipmentTaxIncentiveTerm');
      var defaultAssumptionTerm = this.get('scenario.model.assumption.materialsEquipmentTaxIncentiveTerm');

      return districtTerm ? districtTerm : defaultAssumptionTerm;
    }),

    salesTaxIncentiveTerm: Ember.computed('schedule.salesTaxIncentiveTerm', 'scenario.model.assumption.salesTaxIncentiveTerm', function () {
      var districtTerm = this.get('schedule.salesTaxIncentiveTerm');
      var defaultAssumptionTerm = this.get('scenario.model.assumption.salesTaxIncentiveTerm');

      return districtTerm ? districtTerm : defaultAssumptionTerm;
    }),

    hotelTaxIncentiveTerm: Ember.computed('schedule.hotelTaxIncentiveTerm', 'scenario.model.assumption.hotelTaxIncentiveTerm', function () {
      var districtTerm = this.get('schedule.hotelTaxIncentiveTerm');
      var defaultAssumptionTerm = this.get('scenario.model.assumption.hotelTaxIncentiveTerm');

      return districtTerm ? districtTerm : defaultAssumptionTerm;
    }),

    propertyTaxIncentivePhrases: Ember.computed('propertyTaxIncentiveTerm', function () {
      return (0, _conjugateIncentiveTerm.default)(this.get('propertyTaxIncentiveTerm'));
    }),

    materialsEquipmentIncentivePhrases: Ember.computed('materialsEquipmentTaxIncentiveTerm', function () {
      return (0, _conjugateIncentiveTerm.default)(this.get('materialsEquipmentTaxIncentiveTerm'));
    }),

    salesTaxIncentivePhrases: Ember.computed('salesTaxIncentiveTerm', function () {
      return (0, _conjugateIncentiveTerm.default)(this.get('salesTaxIncentiveTerm'));
    }),

    hotelTaxIncentivePhrases: Ember.computed('hotelTaxIncentiveTerm', function () {
      return (0, _conjugateIncentiveTerm.default)(this.get('hotelTaxIncentiveTerm'));
    })

  });
});