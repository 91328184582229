define('dashboard/components/modals/scenario-copy-modal', ['exports', 'dashboard/components/modals/modal-confirm'], function (exports, _modalConfirm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _modalConfirm.default.extend({

    actions: {
      copyScenarioAction: function copyScenarioAction(withDistricts) {
        this.sendAction('action', withDistricts);
      }
    }

  });
});