define("dashboard/utils/percent-each", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  /**
   * Percent Each
   *
   * @param  {Array} inputArray Array of values
   * @param  {Number or Array} percentage Percentage multiplier(s)
   * @return {Array}            Original array multiplied by a percentage
   *
   * @method  percentEach
   */
  var percentEach = function percentEach(inputArray, percentage) {

    percentage = reducePercentage(percentage);

    return inputArray.map(function (value) {

      return parseFloat(value) * percentage;
    });
  };

  var reducePercentage = function reducePercentage(percentage) {

    if (percentage instanceof Array) {

      // Combine percentages into a single value.
      return percentage.reduce(function (total, item) {
        return total * percentify(item);
      }, 1);
    } else {
      return percentify(percentage);
    }
  };

  // Account for a whole number or decimal percentage value.
  // This logic is not applicable if percantages can be more than 100
  // use multiplyEach instead to treat 110% as 1.1 multiplier
  var percentify = function percentify(value) {
    var cleanedValue = parseFloat(value);
    return cleanedValue > 1 ? cleanedValue / 100 : cleanedValue;
  };

  exports.percentify = percentify;
  exports.percentEach = percentEach;
  exports.default = percentEach;
});