define('dashboard/controllers/multiplier-sets', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    breadcrumbItems: Ember.computed('currentMultiplierSet.{name,archived}', function () {
      var currentMultiplierSet = this.get('currentMultiplierSet');

      var route = 'multiplier-sets';
      if (currentMultiplierSet.get('archived')) {
        route = 'multiplier-sets.inactive';
      }

      var items = [{ label: 'Multiplier Sets', params: [route] }];

      if (currentMultiplierSet) {
        items.push({ label: currentMultiplierSet.get('name'), active: true });
      }

      return items;
    })
  });
});