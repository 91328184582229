define('dashboard/components/district-select', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['district-select'],

    options: Ember.computed('group.{districts.[],id}', 'selectedDistricts.{@each.districtGroups,[]}', function () {
      var _this = this;

      var scenarioDistricts = this.get('selectedDistricts'),
          groupDistricts = this.get('groupDistricts') || [],
          previousDistricts = this.get('group.previousDistricts') || [],
          // need to override or alias too?
      defaultOption = Ember.Object.create({
        id: null,
        name: 'N/A'
      }),
          activeDistricts = [],
          inactiveDistricts = [],
          inactivesToAdd = [];

      activeDistricts.push(defaultOption);

      groupDistricts.sortBy('name').forEach(function (district) {
        var isActive = district.get('isActive'),
            modifiedForScenario = district.get('modifiedForScenario');

        // Active if isActive is true OR the district was created for this scenario
        // Modified for scenario if it was edited in settings OR it was created in transition
        if ((isActive === undefined || isActive) && (!modifiedForScenario || modifiedForScenario === _this.get('scenario.id'))) {
          activeDistricts.push(district);
        } else if (scenarioDistricts.indexOf(district) > -1) {
          activeDistricts.push(district);
        } else {
          inactiveDistricts.push(district);
        }
      });

      // There is a chance a district being used by this scenario is not referenced in the districtGroups districts or previousDistricts arrays
      // This probably means this scenario is using transitioned districts or modified districts
      var foundDistrict = scenarioDistricts.find(function (district) {
        return district.get('districtGroups').findBy('id', _this.get('group.id'));
      });
      if (foundDistrict && activeDistricts.indexOf(foundDistrict) === -1) {
        activeDistricts.push(foundDistrict);
      }

      // At this point, we assume all districts in activeDistricts are available for selection OR are already selected for this scenario.
      // We now need to check if any of our inactive districts are districts which were copied and put in activeDistricts.
      // This is so that we can remove duplicate districts and include districts only if they're selected for this scenario.
      activeDistricts.forEach(function (district) {
        var inactive = inactiveDistricts.findBy('id', district.get('copyOfDistrict'));
        if (district.get('copyOfDistrict') && inactive) {
          inactivesToAdd.push(inactive);
        }
      });

      // Push all copies of districts into activeDistricts, at which time we assume there are duplicates in our options
      activeDistricts.pushObjects(inactivesToAdd);

      // This case will be relevant if districts were removed from a district group but are currently selected for this scenario.
      // This will later trigger the flag that this scenario usesInactiveDistricts
      previousDistricts.forEach(function (district) {
        if (scenarioDistricts.indexOf(district) > -1) {
          activeDistricts.push(district);
        }
      });

      // Find all modified districts within activeDistricts
      // This is partially why we pushed certain inactive districts into activeDistricts
      var modifiedDistricts = activeDistricts.filter(function (district) {
        return district.get('adminCreated') === false || district.get('createdInTransition');
      });

      // Hide original districts if scenario is using modified version of that district
      modifiedDistricts.forEach(function (modifiedDistrict) {
        var districtToRemove = activeDistricts.findBy('id', modifiedDistrict.get('copyOfDistrict'));
        activeDistricts.removeObject(districtToRemove);
      });

      return activeDistricts;
    }),

    selectedDistrict: Ember.computed('options', 'selectedDistricts', function () {
      var selectedDistricts = this.get('selectedDistricts'),
          options = this.get('options'),
          defaultOption = Ember.Object.create({
        id: null,
        name: 'N/A'
      }),
          selected = void 0;

      if (selectedDistricts) {
        selected = selectedDistricts.find(function (district) {
          return options.findBy('id', district.get('id'));
        });
      }

      return selected ? selected : defaultOption;
    }),

    disableSelect: Ember.computed('disabled', 'selectedDistrict', 'group.previousDistricts', 'selectedDistricts', function () {

      var selectedDistrict = this.get('selectedDistrict'),
          scenarioDistricts = this.get('selectedDistricts') || [],
          previousDistricts = this.get('group.previousDistricts') || [];

      // if no selected district for this group, return disabled property from the scenario/parent
      if (!selectedDistrict) {
        return this.get('disabled');
      }

      // if the selected district for this group is inactive
      if (selectedDistrict.get('isActive') === false && scenarioDistricts.indexOf(selectedDistrict) > -1) {
        return true;
      }

      // if the selected district for this group belongs to the group's previousDistricts array
      // meaning the district was removed from the group from the client page
      if (previousDistricts.indexOf(selectedDistrict) > -1) {
        return true;
      }

      // add a condition to check if the selected district was created in transition and its original version is now in previous districts
      if (selectedDistrict.get('createdInTransition') && previousDistricts.findBy('id', selectedDistrict.get('copyOfDistrict'))) {
        return true;
      }

      // if none of the above apply, just return scenario/parent disabled prop
      return this.get('disabled');
    }),

    actions: {

      selectDistrict: function selectDistrict(selectedDistrict) {

        var selectedDistricts = this.get('selectedDistricts'),
            options = this.get('options'),
            group = this.get('group');

        var oldDistrict = selectedDistricts.find(function (district) {
          return options.findBy('id', district.get('id'));
        });

        if (oldDistrict) {
          selectedDistricts.removeObject(oldDistrict);
        }

        var newDistrict = selectedDistrict.get('id') ? selectedDistrict : null;

        this.notifyPropertyChange('selectedDistrict');
        this.sendAction('action', newDistrict, group);
      }
    }

  });
});