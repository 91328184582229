define('dashboard/components/graphs/distribution-graph-summary', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['distribution-graph-summary', 'd-flex', 'flex-column'],

    didRender: function didRender() {
      var dataLength = this.get('graphData.length');
      var i = void 0;

      for (i = 0; i < dataLength; i++) {
        var barWidth = this.$('#' + i).width();
        var nameWidth = this.$('#' + i).find('.name').width();
        var valueWidth = this.$('#' + i).find('.value').width();
        var contentWidth = nameWidth + valueWidth;

        this.$('#' + i).find('div').addClass('text-white'); // necessary for printing styling
        this.$('#' + i).find('div.name').css('margin-left', '0px'); // move label and value outside of bar if

        if (contentWidth > barWidth - 20) {
          // 20 to account for pixel space between label and value
          this.$('#' + i).find('div.name').css('margin-left', barWidth + 'px'); // move label and value outside of bar if
          this.$('#' + i).find('div').removeClass('text-white'); // necessary for printing styling
          this.$('#' + i).find('div').css('color', '#5C6670'); // change font color for labels outside of bar
        }
      }
    },


    values: Ember.computed('graphData.@each.value', function () {
      return this.get('graphData').map(function (data) {
        return data.value;
      });
    }),

    maxDistributionValue: Ember.computed.max('values'),

    widthsInPixels: Ember.computed('graphData.@each.value', 'maxDistributionValue', function () {
      var values = this.get('graphData').mapBy('value');
      var max = this.get('maxDistributionValue');
      // const divWidth = this.$().width();
      var percent = void 0;
      // let pixels;

      return values.map(function (value) {
        if (value <= 0) {
          value = 0;
        }

        if (max <= 0) {
          percent = '0';
        } else {
          percent = parseFloat(Math.round(value / max * 10000) / 10000 * 100).toString();
          // percent = parseFloat(value / max);
          // pixels = (percent * divWidth).toString();
        }

        if (parseFloat(percent) < 0.1 && parseFloat(percent) > 0) {
          return Ember.String.htmlSafe('width: 1px');
        }
        return Ember.String.htmlSafe('width: ' + percent + '%');
      });
    }),

    init: function init() {
      this._super.apply(this, arguments);
      Ember.$(window).on('resize', this.handleResize.bind(this));
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      Ember.$(window).off('resize');
    },
    handleResize: function handleResize() /* event */{
      this.didRender();
      this.notifyPropertyChange('widthsInPixels');
    }
  });
});