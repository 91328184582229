define('dashboard/routes/user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    titleToken: 'My Account',

    notify: Ember.inject.service(),

    model: function model() {
      var user = this.modelFor('authenticated');
      var client = user.get('client');

      return Ember.RSVP.hash({
        user: user,
        client: client
      });
    },


    actions: {
      willTransition: function willTransition(transition) {
        var _controller$get = this.controller.get('model'),
            client = _controller$get.client,
            user = _controller$get.user;

        if (user.get('isSuperAdmin')) {
          return true;
        }

        if (client.get('isValid') && user.get('isValid')) {
          this.getWithDefault('notify.target.messages', []).forEach(function (message) {
            return message.set('visible', false);
          });
          this.controller.set('previousTransition', null);
          return true;
        } else {
          this.get('notify').error('Please fix form errors before trying to navigate away from this page.');
        }

        this.controller.set('previousTransition', transition);
        transition.abort();
      }
    }
  });
});