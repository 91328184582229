define('dashboard/controllers/scenario/settings/tif-districts', ['exports', 'dashboard/mixins/tif-district-actions', 'lodash/object', 'ember-local-storage'], function (exports, _tifDistrictActions, _object2, _emberLocalStorage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_tifDistrictActions.default, {
    init: function init() {
      this._super.apply(this, arguments);
      this.set('tifActionOptions', { adminCreated: false, forScenario: true });
    },


    sessionData: (0, _emberLocalStorage.storageFor)('session-data'),

    tifPrefillModalHidden: true,
    enableTifHidden: true,
    saveTIFConfirmHidden: true,

    tifPrefillAction: "useTifPrefills",

    scenarioSavable: Ember.computed.alias('scenarioController.isSavable'),

    tifInputsDisabled: Ember.computed('scenarioController.currentUser.client.readOnlyAccess', function () {
      return !!this.get('scenarioController.currentUser.client.readOnlyAccess');
    }),

    anyDistrictsApplyProperty: Ember.computed('scenarioController.sortedDistricts.@each.{doesApplyPropertyTaxRateLand,doesApplyPropertyTaxRateBuildings,doesApplyPropertyTaxRateFFE,doesApplyPropertyTaxRateInventories}', function () {

      var sortedDistricts = this.get('scenarioController.sortedDistricts'),
          land = void 0,
          buildings = void 0,
          furniture = void 0,
          inventories = void 0,
          taxableSales = void 0;

      land = !!sortedDistricts.findBy('doesApplyPropertyTaxRateLand', true);
      buildings = !!sortedDistricts.findBy('doesApplyPropertyTaxRateBuildings', true);
      furniture = !!sortedDistricts.findBy('doesApplyPropertyTaxRateFFE', true);
      inventories = !!sortedDistricts.findBy('doesApplyPropertyTaxRateInventories', true);
      taxableSales = !!sortedDistricts.findBy('doesApplySalesTax', true);

      return Ember.Object.create({ land: land, buildings: buildings, furniture: furniture, inventories: inventories, taxableSales: taxableSales });
    }),

    // main difference between this and mixin property is possibility of having a district
    sortedSchedules: Ember.computed('selectedTifDistrict.{sortedSchedules,enterAsDecimal}', 'scenarioController.sortedDistricts.@each.{propertyTaxRateLand,propertyTaxRateBuildings,propertyTaxRateFFE,propertyTaxRateInventories}', function () {
      var schedules = this.get('selectedTifDistrict.sortedSchedules'),
          enterAsDecimal = this.get('selectedTifDistrict.enterAsDecimal'),
          sortedDistricts = this.get('scenarioController.sortedDistricts');

      return schedules.map(function (schedule) {
        var groupId = schedule.get('group.id');
        var district = sortedDistricts.findBy('parentGroup.id', groupId);
        var maxLand = void 0,
            maxBuildings = void 0,
            maxFFE = void 0,
            maxInventories = void 0,
            maxTaxableSales = void 0;
        if (district) {
          maxLand = enterAsDecimal ? district.get('propertyTaxRateLand') : '1';
          maxBuildings = enterAsDecimal ? district.get('propertyTaxRateBuildings') : '1';
          maxFFE = enterAsDecimal ? district.get('propertyTaxRateFFE') : '1';
          maxInventories = enterAsDecimal ? district.get('propertyTaxRateInventories') : '1';
          maxTaxableSales = enterAsDecimal ? district.get('salesTaxRate') : '1';
        }
        return Ember.Object.create({
          schedule: schedule,
          district: district,
          maxLand: maxLand,
          maxBuildings: maxBuildings,
          maxFFE: maxFFE,
          maxInventories: maxInventories,
          maxTaxableSales: maxTaxableSales
        });
      });
    }),

    tifDuration: Ember.computed('scenario.{assumption.scenarioLength,tifDuration}', {
      get: function get() {
        var duration = this.get('scenario.tifDuration');
        return duration ? parseInt(duration) : parseInt(this.get('scenario.assumption.scenarioLength'));
      },
      set: function set(key, value) {
        // handles when input is edited directly
        var duration = value,
            years = parseInt(this.get('scenario.assumption.scenarioLength'));

        if (duration >= years) {
          return years;
        }

        return value;
      }
    }),

    // handles when years changes
    watchTifDuration: Ember.observer('scenario.assumption.scenarioLength', function () {
      var years = parseInt(this.get('scenario.assumption.scenarioLength')),
          selected = this.get('selectedTifDistrict'),
          scenario = this.get('scenario');

      if (!selected) {
        return;
      }

      var duration = scenario.get('tifDuration') ? parseInt(scenario.get('tifDuration')) : null;

      if (duration >= years) {
        scenario.set('tifDuration', null);
        this.send('saveScenario');
      }
    }),

    showPresetOption: Ember.computed('selectedTifDistrict', 'filteredTifDistricts.@each.forScenario', 'tifInputsDisabled', function () {
      var filteredTifs = this.get('filteredTifDistricts').filterBy('forScenario', false);
      return this.get('selectedTifDistrict') && filteredTifs.get('length') && !this.get('tifInputsDisabled');
    }),

    showSaveForLater: Ember.computed('selectedTifDistrict', 'tifInputsDisabled', function () {
      return this.get('selectedTifDistrict') && !this.get('tifInputsDisabled');
    }),

    actions: {
      enableTifs: function enableTifs() {
        var tifDistrict = this.get('selectedTifDistrict'),
            scenario = this.get('scenario');

        // if there is no tif for this scenario and no preset tif options, go to create tif
        var presetTifOptions = this.get('filteredTifDistricts').filterBy('forScenario', false);
        if (!tifDistrict && scenario.get('usesTifDistrict') && !presetTifOptions.get('length')) {
          this.send('createTifDistrict');
          return;
        }

        // if checking box and no tif exists for this scenario, give option to create new TIF
        if (!tifDistrict && scenario.get('usesTifDistrict')) {
          this.send('openTifEnableModal');
          return;
        }

        this.send('saveScenario');
      },
      cancelTifCreate: function cancelTifCreate() {
        if (!this.get('selectedTifDistrict') && this.get('scenario.usesTifDistrict')) {
          this.set('scenario.usesTifDistrict', false);
        }
      },
      openTifEnableModal: function openTifEnableModal() {
        this.set('enableTifHidden', false);
      },
      goToManageTifs: function goToManageTifs() {
        // @TODO keep track of which scenario we're leaving before transitioning
        this.get('sessionData').set('lastVisited', {
          project: this.get('scenarioController.project.id'),
          scenario: this.get('scenario.id'),
          name: this.get('scenarioController.scenarioSubtitle')
        });
        this.transitionToRoute('user.tifs');
      },
      saveScenario: function saveScenario() {
        if (this.get('scenarioSavable')) {
          this.get('scenarioController').send('save');
        }
      },


      // overwrites mixin action to take admin locking into account
      saveTifDistrict: function saveTifDistrict() {
        if (this.get('scenarioSavable')) {
          this.get('selectedTifDistrict').save();
        }
      },


      // overwrites mixin action to take admin locking into account
      saveTifSchedule: function saveTifSchedule(schedule) {
        if (this.get('scenarioSavable')) {
          schedule.save();
        }
      },


      // overwrites mixin action to include scenario saving
      selectTifDistrict: function selectTifDistrict(selectedTifDistrictId) {
        var tifDistrict = this.get('filteredTifDistricts').findBy('id', selectedTifDistrictId);
        this.set('selectedTifDistrict', tifDistrict);

        this.set('scenario.tifDistrict', tifDistrict);
        this.set('scenarioController.tifDistrict', tifDistrict);

        this.send('updateTifIncentives');

        if (this.get('scenarioSavable')) {
          this.get('scenarioController').send('save');
        }
      },


      // overwriting mixin action to have LESS validation
      updateTifDistrict: function updateTifDistrict() {
        var tifDistrict = this.get('selectedTifDistrict');

        if (this.get('tifInputsDisabled')) {
          return;
        }

        if (!tifDistrict.get('name').trim()) {
          var originalName = tifDistrict.changedAttributes().name[0];
          tifDistrict.set('name', originalName);
          return this.get('notify').alert('TIF District must have a name');
        }
        this.send('saveTifDistrict');
      },
      updateTifIncentives: function updateTifIncentives() {
        // if there isn't an incentive schedule for this scenario containing a schedule where forTifDistrict is true, create one
        var existingTifIncentives = this.get('scenario.tifIncentive');

        if (!existingTifIncentives) {
          var tifIncentive = this.store.createRecord('incentiveSchedule', {
            forTifDistrict: true,
            isActive: true
          });
          this.get('scenario.incentiveSchedules').pushObject(tifIncentive);
          tifIncentive.save();
        }
      },
      updateTifDuration: function updateTifDuration() {
        var duration = this.get('tifDuration') ? parseInt(this.get('tifDuration')) : null,
            years = parseInt(this.get('scenario.assumption.scenarioLength'));

        // only set duration if it is less than scenario length
        if (duration >= years) {
          duration = null;
        }

        this.set('scenario.tifDuration', duration);
        this.send('saveScenario');
      },
      openTifPrefillModal: function openTifPrefillModal() {
        this.setProperties({
          tifPrefillAction: "useTifPrefills",
          tifPrefillModalHidden: false
        });
      },


      // used when scenario selecting TIF for the first time
      openFreshPrefillModal: function openFreshPrefillModal() {
        this.setProperties({
          tifPrefillAction: "copyTifDistrict",
          tifPrefillModalHidden: false
        });
      },
      openSaveTIFConfirmModal: function openSaveTIFConfirmModal() {
        this.set('saveTIFConfirmHidden', false);
      },


      // copy tif schedules and properties from tif for use in this scenario
      useTifPrefills: function useTifPrefills(name, tifDistrict) {
        var _this = this;

        var currentTif = this.get('selectedTifDistrict'),
            sortedDistricts = this.get('scenarioController.sortedDistricts'),
            currentSchedules = void 0,
            promiseArray = [],
            reviseNotify = false; // do we need to alert the user that amounts were adjusted?

        if (currentTif.get('sortedSchedules')) {
          currentSchedules = currentTif.get('sortedSchedules');
        }

        // set TIF name, enterAsDecimal
        currentTif.setProperties({
          name: tifDistrict.get('name'),
          enterAsDecimal: tifDistrict.get('enterAsDecimal')
        });
        if (this.get('scenarioSavable')) {
          promiseArray.push(currentTif.save());
        }

        // loop through each schedule in selected tif and set all values in current tif to be the same
        tifDistrict.get('sortedSchedules').forEach(function (schedule) {
          var currentSchedule = currentSchedules.findBy('group.id', schedule.get('group.id')),
              district = sortedDistricts.findBy('parentGroup.id', schedule.get('group.id'));

          if (!currentSchedule) {
            return;
          }

          if (!district || !tifDistrict.get('enterAsDecimal')) {
            var newScheduleProps = schedule.toJSON();

            delete newScheduleProps.created;
            delete newScheduleProps.landContributedDecimal;
            delete newScheduleProps.buildingsContributedDecimal;
            delete newScheduleProps.ffeContributedDecimal;
            delete newScheduleProps.inventoriesContributedDecimal;
            for (var property in newScheduleProps) {
              currentSchedule.set(property, schedule.get(property));
            }
            if (_this.get('scenarioSavable')) {
              promiseArray.push(currentSchedule.save());
            }
            return;
          }

          // if district, we need to check new contrib amounts against district tax rates
          var districtTaxRates = ['propertyTaxRateLand', 'propertyTaxRateBuildings', 'propertyTaxRateFFE', 'propertyTaxRateInventories'];
          var tifContribProps = ['landContributedDecimal', 'buildingsContributedDecimal', 'ffeContributedDecimal', 'inventoriesContributedDecimal'];

          districtTaxRates.forEach(function (prop, index) {
            var rate = parseFloat(district.get(prop)),
                tifContrib = parseFloat(schedule.get(tifContribProps[index])),
                contribGreaterThanTax = tifContrib > rate,
                newRate = contribGreaterThanTax ? rate : tifContrib;

            // set contribution amount to be lesser rate
            currentSchedule.set(tifContribProps[index], newRate.toString());

            if (contribGreaterThanTax) {
              reviseNotify = true;
            }
          });
          if (_this.get('scenarioSavable')) {
            promiseArray.push(currentSchedule.save());
          }
        });

        if (reviseNotify) {
          this.get('notify').alert('Some contribution amounts were adjusted because they were higher than the district tax rate.', {
            closeAfter: 10000
          });
        }

        // console.log(this.get('scenarioSavable'), promiseArray);
        return Ember.RSVP.all(promiseArray);
      },
      saveForFuture: function saveForFuture() {
        var _this2 = this;

        var tifDistrict = this.get('selectedTifDistrict'),
            self = this;

        return new Ember.RSVP.Promise(function (resolve, reject) {
          var tifProfiles = _this2.get('filteredTifDistricts').filterBy('forScenario', false);
          var name = tifDistrict.get('name').trim(),
              error = void 0;

          // no name error
          if (!name) {
            error = "TIF District must have a name";
            console.log('new name ', tifDistrict.changedAttributes().name[0]);
            tifDistrict.set('name', tifDistrict.changedAttributes().name[0]);
          }

          // non unique name error
          if (tifProfiles.findBy('name', name)) {
            error = "TIF Profile name must be unique";
          }

          if (error) {
            reject({ error: error });
          } else {
            var success = Ember.RSVP.all(tifDistrict.get('tifSchedules').map(createTifSchedule)).then(cloneTifDistrict).then(pushNewTif).catch(function (reason) {
              console.log('copy tif district failed', reason);
              _this2.get('notify').alert('Something went wrong saving this TIF District');
            });
            resolve(success);
          }
        });

        function createTifSchedule(oldSchedule) {
          var oldScheduleProperties = oldSchedule.toJSON(),
              newSchedule = void 0;

          delete oldScheduleProperties.created;
          delete oldScheduleProperties.group;

          newSchedule = self.store.createRecord('tifSchedule', oldScheduleProperties);
          newSchedule.set('group', oldSchedule.get('group'));
          return newSchedule.save();
        }

        function cloneTifDistrict(tifSchedules) {
          var client = self.get('client');
          var options = tifDistrict.toJSON();

          delete options.id;
          delete options.created;
          options.forScenario = false;

          return self.store.createRecord('tifDistrict', _object2.default.assign(options, { client: client, tifSchedules: tifSchedules })).save();
        }

        function pushNewTif(newTifDistrict) {
          self.get('filteredTifDistricts').pushObject(newTifDistrict);
        }
      }
    }

  });
});