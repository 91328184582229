define('dashboard/components/changed-checkbox', ['exports', 'dashboard/mixins/input-sizes'], function (exports, _inputSizes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_inputSizes.default, {

    classNames: ['form-group', 'changable-checkbox', 'checkbox-custom-1'],

    computedId: Ember.computed(function () {
      return this.elementId + 'input' + Math.random();
    }),

    change: function change() {
      var _this = this;

      Ember.run.later(function () {
        _this.notifyPropertyChange('modified');
        _this.sendAction('action', _this.get('toSend'));
      }, 100);
    },

    isModified: Ember.computed('modified.[]', function () {
      var modifiedProperties = this.get('modified') || {},
          inputKey = this.get('modifiedKey');

      return Ember.Object.create({
        isTrue: modifiedProperties.hasOwnProperty(inputKey),
        original: modifiedProperties[inputKey]
      });
    })

  });
});