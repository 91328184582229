define('dashboard/components/district-area-select', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    primaryGroupName: Ember.computed('scenarioController.districtAreaSet.primaryGroupName', function () {
      return this.get('scenarioController.districtAreaSet.primaryGroupName') || 'Primary Area';
    }),

    primaryGroupOptions: Ember.computed('scenarioController.uniqueDistrictAreas.[]', function () {
      var districtAreaOptions = this.get('scenarioController.uniqueDistrictAreas').sortBy('primaryGroup');
      return districtAreaOptions.map(function (area) {
        return area.get('primaryGroup');
      });
    }),

    districtAreaOptions: Ember.computed('scenarioController.districtAreaOptions.[]', 'scenario.districtArea', function () {
      var _this = this;

      // If there is no area selected, we can just return a default option
      var selectedArea = this.get('scenario.districtArea');
      if (!selectedArea.get('id')) {
        return [Ember.Object.create({
          name: 'Select a ' + this.get('primaryGroupName') + ' from the list above'
        })];
      }

      var allOptions = this.get('scenarioController.districtAreaOptions');

      // Include district areas which have been modified for a scenario only if they were modified for THIS scenario
      allOptions = allOptions.filter(function (districtArea) {
        var modifiedForScenario = districtArea.get('modifiedForScenario');
        return !modifiedForScenario || modifiedForScenario === _this.get('scenario.id') || districtArea.get('id') === selectedArea.get('id');
      });

      // Remove original option if there is a duplicate which has been created in transition
      // We only have to look for one of these since scenarios can only be transitioned once
      var transitionedArea = allOptions.findBy('createdInTransition', true);
      if (transitionedArea) {
        var duplicateArea = allOptions.find(function (area) {
          return !area.get('createdInTransition') && area.get('name') === transitionedArea.get('name');
        });

        if (duplicateArea) {
          allOptions.removeObject(duplicateArea);
        }
      }

      return allOptions;
    }),

    actions: {
      save: function save() {
        this.sendAction();
      },
      districtAreaGroupChanged: function districtAreaGroupChanged(selectedGroup) {
        var _this2 = this;

        var scenarioController = this.get('scenarioController'),
            scenarioModel = this.get('scenario'),
            store = scenarioController.store;

        scenarioModel.set('districtAreaGroup', selectedGroup);

        store.query('districtArea', { districtAreaSet: scenarioController.get('districtAreaSet.id'), primaryGroup: selectedGroup }).then(function (districtAreas) {

          districtAreas = districtAreas.filter(function (districtArea) {
            return !districtArea.get('modifiedForScenario') || districtArea.get('modifiedForScenario') === _this2.get('scenario.id');
          });

          var transitionedArea = districtAreas.find(function (districtArea) {
            return districtArea.get('createdInTransition') && districtArea.get('modifiedForScenario') === _this2.get('scenario.id');
          });

          if (transitionedArea) {
            var duplicateArea = districtAreas.find(function (area) {
              return !area.get('createdInTransition') && area.get('name') === transitionedArea.get('name');
            });
            if (duplicateArea) {
              districtAreas.removeObject(duplicateArea);
            }
          }

          scenarioController.set('districtAreaOptions', districtAreas);
          _this2.send('districtAreaChanged', districtAreas.get('firstObject'));
        });
      },
      districtAreaChanged: function districtAreaChanged(selectedArea) {

        var store = this.get('scenarioController').store,
            groups = this.get('scenarioController.sortedDistrictGroups'),
            scenarioModel = this.get('scenario'),
            scenarioDistricts = scenarioModel.get('districts'),
            savable = !this.get('scenarioController.currentUser.isDemo') && this.get('scenarioController.isSavable'),
            self = this;

        if (!selectedArea.get('id')) {
          return;
        }

        scenarioModel.set('districtArea', selectedArea);

        // Sets districtAreasWorking or other property which will disable select dropdowns
        this.set('disabled', true);

        removeOldDistricts().then(findTaxDistricts).then(addDistrictsToGroup).then(saveScenario);

        // Clear out all old data where necessary
        function removeOldDistricts() {
          return new Ember.RSVP.Promise(function (resolve /*, reject*/) {
            var copiedDistricts = scenarioDistricts.toArray();
            copiedDistricts.forEach(function (district) {
              scenarioDistricts.removeObject(district);
            });

            if (savable) {
              resolve(scenarioModel.save());
            } else {
              resolve();
            }
          });
        }

        // Find tax districts from district area set map
        function findTaxDistricts() {
          return store.query('taxDistrict', { districtArea: selectedArea.get('id') });
        }

        // Set group associations and new districts
        function addDistrictsToGroup(newDistricts) {
          var promiseArray = [];

          groups.forEach(function (group) {
            var matchingDistrictMapObject = selectedArea.get('map').findBy('type', group.get('label')),
                matchingDistrict = newDistricts.findBy('id', matchingDistrictMapObject.district),
                needsSave = group.get('districts').indexOf(matchingDistrict) < 0;

            if (matchingDistrict) {
              matchingDistrict.set('parentGroup', group);
              // no need to add district to group if it's already there
              if (needsSave) {
                group.get('districts').pushObject(matchingDistrict);
              }
              self.sendAction('action', matchingDistrict, group);
            }

            if (savable && needsSave) {
              promiseArray.push(group.save());
            }
          });

          return Ember.RSVP.all(promiseArray);
        }

        function saveScenario() /* savedGroup */{
          self.set('disabled', false);
          if (savable) {
            scenarioModel.save();
          }
        }
      }
    }
  });
});