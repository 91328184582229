define('dashboard/models/default-assumption', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    client: (0, _attr.default)('string'),

    percentMarketValueFFE: (0, _attr.default)({ defaultValue: function defaultValue() {
        return ['1.0', '0.9', '0.8', '0.7', '0.6', '0.5', '0.4', '0.3', '0.2', '0.2'];
      }
    }),
    percentBuildingImprovementCosts: (0, _attr.default)('string', { defaultValue: '1' }),
    residentialRealPropertyAppreciation: (0, _attr.default)('string', { defaultValue: '0.02' }),
    commercialRealPropertyAppreciation: (0, _attr.default)('string', { defaultValue: '0.02' }),
    buildingAndImprovementsAssociatedWithConstruction: (0, _attr.default)('boolean', { defaultValue: true }),
    percentConstructionExpenditureMaterials: (0, _attr.default)('string', { defaultValue: '0.5' }),
    percentConstructionExpenditureLabor: (0, _attr.default)('string', { defaultValue: '0.5' }),

    dailyTaxableVisitorSpending: (0, _attr.default)('string', { defaultValue: '50' }),
    hotelRoomRate: (0, _attr.default)('string', { defaultValue: '125' }),
    percentWorkerSpendingGoodsServices: (0, _attr.default)('string', { defaultValue: '0.25' }),
    percentConstructionWorkerSpendingGoodsServices: (0, _attr.default)('string', { defaultValue: '0.25' }),

    studentsPerHousehold: (0, _attr.default)('string', { defaultValue: '0.5' }),
    residentsPerHousehold: (0, _attr.default)('string', { defaultValue: '2.6' }),
    annualPercentIncreaseInVisitors: (0, _attr.default)('string'),
    discountForCalculatingPresentValueCostsBenefits: (0, _attr.default)('string', { defaultValue: '0.05' }),
    expectedAnnualInflationRate: (0, _attr.default)('string', { defaultValue: '0.03' }),
    percentSalarySubjectToIncomeTax: (0, _attr.default)('string', { defaultValue: '0.0' }),

    doesApplyTaxCaps: (0, _attr.default)('boolean', { defaultValue: false }),
    commercialTaxCap: (0, _attr.default)('string', { defaultValue: '0.0' }),
    residentialTaxCap: (0, _attr.default)('string', { defaultValue: '0.0' }),
    scenarioLength: (0, _attr.default)('string', { defaultValue: '10' }),
    abatementsAsCosts: (0, _attr.default)('boolean', { defaultValue: false }),

    propertyTaxIncentiveTerm: (0, _attr.default)('string', { defaultValue: 'Abatement' }),
    materialsEquipmentTaxIncentiveTerm: (0, _attr.default)('string', { defaultValue: 'Rebate' }),
    salesTaxIncentiveTerm: (0, _attr.default)('string', { defaultValue: 'Rebate' }),
    hotelTaxIncentiveTerm: (0, _attr.default)('string', { defaultValue: 'Rebate' }),
    salesTaxLocalOnly: (0, _attr.default)('boolean', { defaultValue: true }),

    calendarYearDisplay: (0, _attr.default)('boolean', { defaultValue: false })

  });
});