define('dashboard/components/graphs/pie-chart', ['exports', 'dashboard/templates/components/graphs/pie-chart', 'dashboard/utils/format-chart-label'], function (exports, _pieChart, _formatChartLabel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _pieChart.default,

    height: '356px',
    width: Ember.computed('showPlaceholder', 'height', function () {
      return this.get('showPlaceholder') ? this.get('height') : null;
    }),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.set('options', {
        responsive: true,
        maintainAspectRatio: false,
        legend: { display: false },
        tooltips: { enabled: false },
        plugins: {
          datalabels: {
            anchor: 'end',
            align: 'start',
            // color: 'white',
            color: function color(data) {
              var rgb = _this.hexToRgb(data.dataset.backgroundColor[data.dataIndex]);
              if (!rgb) return 'white';
              var threshold = 185;
              var luminance = 0.299 * rgb.r + 0.587 * rgb.g + 0.114 * rgb.b;
              return luminance > threshold ? '#35393D' : 'white';
            },
            formatter: function formatter(value, context) {
              var label = context.dataset.title[context.dataIndex];
              var splitLabel = (0, _formatChartLabel.default)(label, 18);
              var lastPart = splitLabel[splitLabel.length - 1];
              splitLabel[splitLabel.length - 1] = lastPart + ', ' + value;

              return splitLabel;
            },
            offset: 10,
            textStrokeColor: '#35393D',
            // textShadowBlur: 4,
            textShadowColor: 'black'
          }
        }
      });
    },
    hexToRgb: function hexToRgb(hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null;
    }
  });
});