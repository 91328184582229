define('dashboard/utils/copy-to-clipboard', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = copyTextToClipboard;
  // copied from https://gist.github.com/balinterdi/72a678b42f9fc4d6258734c350e2631d
  function copyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();

    try {
      // Now that we've selected the anchor text, execute the copy command
      var successful = document.execCommand('copy');
      var message = successful ? 'successful' : 'unsuccessful';
      console.log('Copy was ' + message);
      document.body.removeChild(textArea);
      return true;
    } catch (error) {
      console.error('Oops, unable to copy: ' + error);
      document.body.removeChild(textArea);
      return false;
    }
  }
});