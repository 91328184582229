define('dashboard/components/ids-tooltip', ['exports', 'ember-tooltips/components/ember-popover'], function (exports, _emberPopover) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberPopover.default.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.set('popperOptions', {
        // modifiers: {
        //   preventOverflow: { escapeWithReference: false },
        // }
      });
      this.set('popperContainer', 'body');
    },


    event: 'hover',
    effect: 'none',
    side: 'bottom',
    text: '',
    animationDuration: 0,
    popoverHideDelay: 0

  });
});