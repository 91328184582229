define('dashboard/objects/fi-tax/base-aggregate', ['exports', 'dashboard/utils/get-aggregate-engine-prop'], function (exports, _getAggregateEngineProp) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var FiTaxAggregate = Ember.Object.extend({

    project: Ember.computed('engines', 'property', function () {
      return (0, _getAggregateEngineProp.getAggregateProperty)(this.get('engines'), this.get('property') + '.project');
    }),

    workers: Ember.computed('engines', 'property', function () {
      return (0, _getAggregateEngineProp.getAggregateProperty)(this.get('engines'), this.get('property') + '.workers');
    }),

    total: Ember.computed('engines', 'property', function () {
      return (0, _getAggregateEngineProp.getAggregateProperty)(this.get('engines'), this.get('property') + '.total');
    }),

    annual: Ember.computed('engines', 'property', function () {
      return (0, _getAggregateEngineProp.getAggregateArrayProperty)(this.get('engines'), this.get('property') + '.annual');
    }),

    // used for fiUtility properties
    revenuePerWorker: Ember.computed('engines', 'property', function () {
      return (0, _getAggregateEngineProp.getAggregateArrayProperty)(this.get('engines'), this.get('property') + '.revenuePerWorker');
    }),

    revenuePerHousehold: Ember.computed('engines', 'property', function () {
      return (0, _getAggregateEngineProp.getAggregateArrayProperty)(this.get('engines'), this.get('property') + '.revenuePerHousehold');
    }),

    // only used for fiStateFederalSchoolFunding
    revenuePerStudent: Ember.computed('engines', 'property', function () {
      return (0, _getAggregateEngineProp.getAggregateArrayProperty)(this.get('engines'), this.get('property') + '.revenuePerStudent');
    }),

    // only used for costEducateNewStudents
    costPerStudent: Ember.computed('engines', 'property', function () {
      return (0, _getAggregateEngineProp.getAggregateArrayProperty)(this.get('engines'), this.get('property') + '.costPerStudent');
    }),

    // used for costReductionInStateSchoolFunding
    costPerProject: Ember.computed('engines', 'property', function () {
      return (0, _getAggregateEngineProp.getAggregateArrayProperty)(this.get('engines'), this.get('property') + '.costPerProject');
    }),

    // used for costReductionInStateSchoolFunding
    costPerWorker: Ember.computed('engines', 'property', function () {
      return (0, _getAggregateEngineProp.getAggregateArrayProperty)(this.get('engines'), this.get('property') + '.costPerWorker');
    }),

    // used in costUtilityServices, costGovernmentServices
    totalAnnual: Ember.computed('engines', 'property', function () {
      return (0, _getAggregateEngineProp.getAggregateArrayProperty)(this.get('engines'), this.get('property') + '.totalAnnual');
    })

  });

  exports.default = FiTaxAggregate;
});