define('dashboard/controllers/district-area-sets/index', ['exports', 'dashboard/mixins/sorting', 'dashboard/fixtures/states'], function (exports, _sorting, _states) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_sorting.default, {

    hideCreateDistrictAreaSetModal: true,
    stateOptions: _states.default,
    name: '',

    notify: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('sortColumns', [{
        title: '',
        width: '50'
      }, {
        title: 'State',
        sortField: 'state'
      }, {
        title: 'Set Name',
        sortField: 'name'
      }, {
        title: 'Primary Group Name',
        sortField: 'primaryGroupName'
      }, {
        title: 'Created',
        sortField: 'created'
      }]);
      this.set('newDistrictAreaSet', { name: '', state: '' });
    },


    sortField: 'created',
    sortDir: 'asc',

    disableButton: Ember.computed('newDistrictAreaSet.{name,state}', function () {
      return this.get('newDistrictAreaSet.name').trim().length <= 0 || !this.get('newDistrictAreaSet.state');
    }),

    actions: {
      toggleCreating: function toggleCreating() {
        this.send('resetDistrictAreaSetForm');
        this.set('hideCreateDistrictAreaSetModal', false);
      },
      create: function create() {
        var _this = this;

        var setToCreate = this.get('newDistrictAreaSet');

        if (!setToCreate.name) {
          return this.get('notify').alert('District Area Set must have a name.');
        }

        if (!setToCreate.state) {
          return this.get('notify').alert('District Area Set must have a state.');
        }

        this.store.createRecord('districtAreaSet', setToCreate).save().then(function (districtAreaSet) {
          if (!districtAreaSet) {
            return _this.get('notify').alert('Something went wrong creating this district area set.');
          }
          _this.send('resetDistrictAreaSetForm');
          _this.set('hideCreateDistrictAreaSetModal', true);
          _this.send('transitionToDistrictAreaSet', districtAreaSet);
        });
      },
      resetDistrictAreaSetForm: function resetDistrictAreaSetForm() {
        this.set('newDistrictAreaSet', { name: '', state: '', primaryGroupName: '' });
      },
      transitionToDistrictAreaSet: function transitionToDistrictAreaSet(districtAreaSet) {
        this.transitionToRoute('district-area-set', districtAreaSet.get('id'));
      }
    }
  });
});