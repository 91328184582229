define('dashboard/components/forms/dependent-district-group-link-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    saveDisabled: Ember.computed('link.{matchingText,districtGroups.length}', function () {
      return !this.get('link.matchingText') || this.get('link.districtGroups.length') === 0;
    }),

    actions: {
      linkDependentGroup: function linkDependentGroup(selectedGroups) {
        this.set('link.districtGroups', selectedGroups);
      }
    }
  });
});