define('dashboard/models/tax-district', ['exports', 'lodash/lang', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _lang2, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({

    customCostRevenues: (0, _relationships.hasMany)('customCostRevenue'),
    directCostRevenues: (0, _relationships.hasMany)('directCostRevenue'),
    multiplierSet: (0, _relationships.belongsTo)('multiplierSet'),

    districtGroups: (0, _relationships.hasMany)('districtGroup', { inverse: null }),

    // only applies when uploading tax districts through dependent tax district set page
    dependentTaxDistrictSet: (0, _relationships.belongsTo)('dependentTaxDistrictSet', { inverse: null }),
    primaryDependency: (0, _relationships.belongsTo)('taxDistrict', { inverse: null }),
    isPrimary: (0, _attr.default)('boolean', { defaultValue: false }),
    group: (0, _attr.default)('string'),

    parentDistrict: (0, _attr.default)('string'),

    modifiedForScenario: (0, _attr.default)('string'),
    copyOfDistrict: (0, _attr.default)('string'),
    createdInTransition: (0, _attr.default)('boolean'),

    name: (0, _attr.default)('string'),
    note: (0, _attr.default)('string'),

    title: Ember.computed('name', 'note', function () {
      var name = this.get('name'),
          note = this.get('note');

      if (Ember.isEmpty(note)) {
        return name;
      }

      return name + ' (' + note + ')';
    }),

    state: (0, _attr.default)('string'),
    adminCreated: (0, _attr.default)('boolean', { defaultValue: true }),
    created: (0, _attr.default)('date'),
    lastUpdated: (0, _attr.default)('date'),

    employmentPercentOfRegion: (0, _attr.default)('string', { defaultValue: '0' }),
    percentNewDirectWorkersMoving: (0, _attr.default)('string', { defaultValue: '0' }),
    percentNewIndirectWorkersMoving: (0, _attr.default)('string', { defaultValue: '0' }),

    doesApplyPropertyTaxRateLand: (0, _attr.default)('boolean', { defaultValue: true }),
    propertyTaxRateLand: (0, _attr.default)('string', { defaultValue: '0.0' }),
    appliedPropertyTaxRateLand: (0, _attr.default)('string', { defaultValue: '100' }),
    assessmentRatioLand: (0, _attr.default)('string', { defaultValue: '1' }),
    doesApplyAbateableTaxRateLand: (0, _attr.default)('boolean', { defaultValue: false }),
    abateableTaxRateLand: (0, _attr.default)('string', { defaultValue: '0.0' }),

    doesApplyPropertyTaxRateFFE: (0, _attr.default)('boolean', { defaultValue: true }),
    propertyTaxRateFFE: (0, _attr.default)('string', { defaultValue: '0.0' }),
    appliedPropertyTaxRateFFE: (0, _attr.default)('string', { defaultValue: '100' }),
    assessmentRatioFFE: (0, _attr.default)('string', { defaultValue: '1' }),
    doesApplyAbateableTaxRateFFE: (0, _attr.default)('boolean', { defaultValue: false }),
    abateableTaxRateFFE: (0, _attr.default)('string', { defaultValue: '0.0' }),

    doesApplyPropertyTaxRateInventories: (0, _attr.default)('boolean', { defaultValue: true }),
    propertyTaxRateInventories: (0, _attr.default)('string', { defaultValue: '0.0' }),
    appliedPropertyTaxRateInventories: (0, _attr.default)('string', { defaultValue: '100' }),
    assessmentRatioInventories: (0, _attr.default)('string', { defaultValue: '1' }),
    doesApplyAbateableTaxRateInventories: (0, _attr.default)('boolean', { defaultValue: false }),
    abateableTaxRateInventories: (0, _attr.default)('string', { defaultValue: '0.0' }),

    doesApplyPropertyTaxRateBuildings: (0, _attr.default)('boolean', { defaultValue: true }),
    propertyTaxRateBuildings: (0, _attr.default)('string', { defaultValue: '0.0' }),
    appliedPropertyTaxRateBuildings: (0, _attr.default)('string', { defaultValue: '100' }),
    assessmentRatioBuildings: (0, _attr.default)('string', { defaultValue: '1' }),
    doesApplyAbateableTaxRateBuildings: (0, _attr.default)('boolean', { defaultValue: false }),
    abateableTaxRateBuildings: (0, _attr.default)('string', { defaultValue: '0.0' }),

    doesApplyPropertyTaxRateResidential: (0, _attr.default)('boolean', { defaultValue: true }),
    propertyTaxRateResidential: (0, _attr.default)('string', { defaultValue: '0.0' }),
    appliedPropertyTaxRateResidential: (0, _attr.default)('string', { defaultValue: '100' }),
    assessmentRatioResidential: (0, _attr.default)('string', { defaultValue: '1' }),

    valueNewResidentialProperty: (0, _attr.default)('string', { defaultValue: '0' }),
    percentMovingWorkersConstructingProperty: (0, _attr.default)('string', { defaultValue: '0' }),
    doesApplyPercentPropertyTaxLost: (0, _attr.default)('boolean', { defaultValue: false }),
    percentPropertyTaxLost: (0, _attr.default)('string', { defaultValue: '0' }),

    doesApplySalesTax: (0, _attr.default)('boolean', { defaultValue: true }),
    salesTaxRate: (0, _attr.default)('string', { defaultValue: '0.0' }),
    doesApplyRebateableSalesTaxRate: (0, _attr.default)('boolean', { defaultValue: false }),
    rebateableSalesTaxRate: (0, _attr.default)('string', { defaultValue: '0.0' }),

    doesApplyConstructionMaterialsSalesTax: (0, _attr.default)('boolean', { defaultValue: true }),
    percentConstructionMaterialsPurchasedSalesTax: (0, _attr.default)('string', { defaultValue: '0' }),

    doesApplyConstructionWorkerSpendingSalesTax: (0, _attr.default)('boolean', { defaultValue: true }),
    percentConstructionWorkerSpendingSalesTax: (0, _attr.default)('string', { defaultValue: '0' }),

    doesApplyEquipmentSalesTax: (0, _attr.default)('boolean', { defaultValue: true }),
    percentEquipmentSalesTax: (0, _attr.default)('string', { defaultValue: '0' }),

    doesApplyTaxableSalesTax: (0, _attr.default)('boolean', { defaultValue: true }),
    doesApplyTaxablePurchasesSalesTax: (0, _attr.default)('boolean', { defaultValue: true }),
    doesApplyHotelSpendingSalesTax: (0, _attr.default)('boolean', { defaultValue: false }),
    doesApplyVisitorSpendingSalesTax: (0, _attr.default)('boolean', { defaultValue: true }),
    doesApplyDirectWorkerSpendingSalesTax: (0, _attr.default)('boolean', { defaultValue: true }),
    doesApplyIndirectWorkerSpendingSalesTax: (0, _attr.default)('boolean', { defaultValue: true }),

    percentWorkerSpendingInDistrict: (0, _attr.default)('string', { defaultValue: '0' }),

    doesApplyHotelTax: (0, _attr.default)('boolean', { defaultValue: false }),
    hotelTaxRate: (0, _attr.default)('string', { defaultValue: '0.0' }),
    doesApplyRebateableHotelTaxRate: (0, _attr.default)('boolean', { defaultValue: false }),
    rebateableHotelTaxRate: (0, _attr.default)('string', { defaultValue: '0.0' }),

    hotelTaxDefault: Ember.computed('state', function () {
      switch (this.get('state')) {
        case 'Texas':
          return 'Hotel Occupancy Tax';
        case 'Florida':
          return 'Tourist Development Tax';
        case 'California':
          return 'Transient Occupancy Tax';
        default:
          return 'Hotel Taxes';
      }
    }),

    // used in templates when displaying name
    hotelTaxLabel: Ember.computed('hotelTaxLabelSet', 'hotelTaxDefault', {
      // this seems needlessly complicated but we always want to have a value to show for this label
      // so we will always either show the state default or a custom label
      get: function get() {
        if (!this.get('hotelTaxLabelSet')) {
          return this.get('hotelTaxDefault');
        }
        return this.get('hotelTaxLabelSet');
      },
      set: function set(key, value) {
        if (value) {
          this.set('hotelTaxLabelSet', value);
        } else {
          value = this.get('hotelTaxDefault');
        }
        return value;
      }
    }),

    // canonical hotel tax label if user changed (used for hotel tax label inputs)
    hotelTaxLabelSet: (0, _attr.default)('string', { defaultValue: 'Hotel Taxes' }),

    // doesApplyHotelOccupancyTaxRate: attr('boolean', {defaultValue: false}), // unpublished hotel section
    // hotelOccupancyTaxRate: attr('string'), // maybe renamed

    doesApplyBuildingPermitsAndFees: (0, _attr.default)('boolean', { defaultValue: false }),

    costGovernmentServicesHousehold: (0, _attr.default)('string', { defaultValue: '0' }),
    costGovernmentServicesWorker: (0, _attr.default)('string', { defaultValue: '0' }),
    costGovernmentServicesIncrease: (0, _attr.default)('string', { defaultValue: '0.02' }),
    doesApplyCostGovernmentServices: (0, _attr.default)('boolean', { defaultValue: true }),

    costUtilityServicesHousehold: (0, _attr.default)('string', { defaultValue: '0' }),
    costUtilityServicesWorker: (0, _attr.default)('string', { defaultValue: '0' }),
    costUtilityServicesIncrease: (0, _attr.default)('string', { defaultValue: '0.02' }),
    doesApplyCostUtilityServices: (0, _attr.default)('boolean', { defaultValue: true }),

    costEducateNewStudentsPerStudent: (0, _attr.default)('string', { defaultValue: '0' }),
    costEducateNewStudentsIncrease: (0, _attr.default)('string', { defaultValue: '0.02' }),
    doesApplyCostEducateNewStudents: (0, _attr.default)('boolean', { defaultValue: true }),

    revenueAdditionalFundingPerStudent: (0, _attr.default)('string', { defaultValue: '0' }),
    revenueAdditionalFundingIncrease: (0, _attr.default)('string', { defaultValue: '0.02' }),
    doesApplyRevenueAdditionalFunding: (0, _attr.default)('boolean', { defaultValue: true }),

    revenueMiscellaneousTaxesPerHousehold: (0, _attr.default)('string', { defaultValue: '0' }),
    revenueMiscellaneousTaxesPerWorker: (0, _attr.default)('string', { defaultValue: '0' }),
    revenueMiscellaneousTaxesIncrease: (0, _attr.default)('string', { defaultValue: '0.02' }),
    doesApplyRevenueMiscellaneousTaxes: (0, _attr.default)('boolean', { defaultValue: true }),

    revenueUtilityFranchiseFeesPerHousehold: (0, _attr.default)('string', { defaultValue: '0' }),
    revenueUtilityFranchiseFeesPerWorker: (0, _attr.default)('string', { defaultValue: '0' }),
    revenueUtilityFranchiseFeesIncrease: (0, _attr.default)('string', { defaultValue: '0.02' }),
    doesApplyRevenueUtilityFranchiseFees: (0, _attr.default)('boolean', { defaultValue: true }),

    revenueUtilityPerHousehold: (0, _attr.default)('string', { defaultValue: '0' }),
    revenueUtilityPerWorker: (0, _attr.default)('string', { defaultValue: '0' }),
    revenueUtilityIncrease: (0, _attr.default)('string', { defaultValue: '0.02' }),
    doesApplyRevenueUtility: (0, _attr.default)('boolean', { defaultValue: true }),

    doesApplyLocalIncomeTax: (0, _attr.default)('boolean', { defaultValue: false }),
    incomeTaxRateResidents: (0, _attr.default)('string', { defaultValue: '0.0' }),
    incomeTaxRateNonResidents: (0, _attr.default)('string', { defaultValue: '0.0' }),
    percentDirectSubjectResidents: (0, _attr.default)('string', { defaultValue: '0.0' }),
    percentIndirectSubjectResidents: (0, _attr.default)('string', { defaultValue: '0.0' }),

    adminNotes: (0, _attr.default)('string'),
    methodologyText: (0, _attr.default)('string'),
    isActive: (0, _attr.default)('boolean', { defaultValue: true }),

    modifiedProperties: (0, _attr.default)({ defaultValue: function defaultValue() {
        return {};
      } }),

    isModified: Ember.computed('modifiedProperties', 'customCostRevenues.@each.modifiedProperties', 'directCostRevenues.@each.modifiedProperties', function () {

      var districtPropertiesModified = checkModified(this.get('modifiedProperties'));

      var customCostRevenuesModified = this.get('customCostRevenues').any(function (costRev) {
        return checkModified(costRev.get('modifiedProperties')) || costRev.get('deleted');
      });

      var directCostRevenuesModified = this.get('directCostRevenues').any(function (costRev) {
        return checkModified(costRev.get('modifiedProperties')) || costRev.get('deleted');
      });

      return districtPropertiesModified || customCostRevenuesModified || directCostRevenuesModified;
    })
  });


  function checkModified(properties) {
    return properties !== undefined && !_lang2.default.isEqual(properties, {}) && !_lang2.default.isEqual(properties, { test: 1 });
  }
});