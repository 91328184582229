define('dashboard/controllers/scenario/public-support', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    scenarioController: Ember.inject.controller('scenario'),

    actions: {
      goToGeneralInfo: function goToGeneralInfo() {
        var _this = this;

        this.transitionToRoute('scenario').then(function () {
          _this.get('scenarioController').set('generalInfoModalHidden', false);
        });
      }
    }
  });
});