define('dashboard/serializers/tax-districts-upload', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    normalize: function normalize(modelClass, resourceHash) {
      resourceHash.id = 'dummy-' + Date.now();
      return this._super.apply(this, arguments);
    },

    attrs: {
      taxDistricts: { deserialize: 'records', serialize: false }
    }
  });
});