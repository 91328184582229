define('dashboard/routes/scenario/report', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var scenario = this.modelFor('scenario').scenario;

      // need request template for editing fact sheet sections
      var requestTemplate = scenario.get('requestTemplate.content');
      if (!requestTemplate) {
        requestTemplate = this.store.createRecord('requestTemplate');
        scenario.set('requestTemplate', requestTemplate);
      }

      return Ember.RSVP.hash({ scenario: scenario, requestTemplate: requestTemplate });
    },
    setupController: function setupController(controller, models) {
      this._super(controller, models.scenario);
      var scenario = this.modelFor('scenario').scenario;
      var groups = this.controllerFor('scenario').get('sortedDistrictGroups');
      controller.set('selectedSchedule', scenario.getSelectedSchedule(groups));

      controller.set('requestTemplate', models.requestTemplate);

      // needed to show district options in side nav
      controller.notifyPropertyChange('detailedReportSections');
    }
  });
});