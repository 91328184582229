define('dashboard/models/reset-password', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    user: (0, _relationships.belongsTo)('user'),
    token: (0, _attr.default)('string'),
    isComplete: (0, _attr.default)('boolean')
  });
});