define('dashboard/models/user', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'ember-cp-validations'], function (exports, _model, _attr, _relationships, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    firstName: [(0, _emberCpValidations.validator)('presence', { description: 'First Name', presence: true })],
    lastName: [(0, _emberCpValidations.validator)('presence', { description: 'Last Name', presence: true })],
    email: [(0, _emberCpValidations.validator)('presence', { description: 'Email', presence: true, ignoreBlank: true }), (0, _emberCpValidations.validator)('format', { description: 'Email', type: 'email' })]
  });

  exports.default = _model.default.extend(Validations, {

    client: (0, _relationships.belongsTo)('client'),

    email: (0, _attr.default)('string'),
    username: (0, _attr.default)('string'),
    firstName: (0, _attr.default)('string', { defaultValue: ' ' }),
    lastName: (0, _attr.default)('string', { defaultValue: ' ' }),
    jobTitle: (0, _attr.default)('string'),
    phone: (0, _attr.default)('string'),
    role: (0, _attr.default)('string', { defaultValue: 'user' }),
    notificationLevel: (0, _attr.default)('string', { defaultValue: 'all_notifications' }),
    lastActive: (0, _attr.default)('date'),
    tileView: (0, _attr.default)('boolean', { defaultValue: true }), // this is for the project tileview, named when we only had one tileview/list option
    requestTemplatesTileView: (0, _attr.default)('boolean', { defaultValue: true }),
    archived: (0, _attr.default)('boolean', { defaultValue: false }),
    created: (0, _attr.default)('date'),

    fullName: Ember.computed('firstName', 'lastName', function () {
      var firstName = this.getWithDefault('firstName', '');
      var lastName = this.getWithDefault('lastName', '');

      return (firstName + ' ' + lastName).trim();
    }),

    roleDisplayName: Ember.computed('role', function () {
      if (this.get('isSuperAdmin')) return 'Super Admin';
      if (this.get('isAdmin')) return 'Admin';
      return 'Standard User';
    }),

    roleDisplayNameWithStatus: Ember.computed('roleDisplayName', 'archived', function () {
      var archived = this.get('archived');
      var roleDisplayName = this.get('roleDisplayName');
      return archived ? roleDisplayName + ' <i>(Archived)</i>' : roleDisplayName;
    }),

    formattedNotificationLevel: Ember.computed('notificationLevel', function () {
      var level = this.get('notificationLevel');
      if (level === "none") return "No Notifications";
      if (level === "all_data_forms") return "All Submitted Data Forms";
      if (level === "my_data_forms") return "My Data Forms Only";
      return "All Notifications";
    }),

    formattedPhone: Ember.computed('phone', function () {
      var phoneNumber = this.get('phone');
      var cleaned = ('' + phoneNumber).replace(/\D/g, '');
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      return match ? '(' + match[1] + ') ' + match[2] + '-' + match[3] : phoneNumber;
    }),

    canManageUsers: Ember.computed('isSuperAdmin', 'isAdmin', 'isDemo', function () {
      return this.get('isSuperAdmin') || this.get('isAdmin') && !this.get('isDemo');
    }),

    isDemo: Ember.computed.equal('role', 'demo'),

    isNotDemo: Ember.computed.not('isDemo'),

    isSuperAdmin: Ember.computed.equal('role', 'super_admin'),

    isAdmin: Ember.computed.equal('role', 'admin'),
    isUser: Ember.computed.equal('role', 'user'),
    isClient: Ember.computed.or('isAdmin', 'isUser'),

    isValid: Ember.computed.alias('validations.isValid'),

    isInvalid: Ember.computed.alias('validations.isInvalid'),

    assignedRequestTemplates: Ember.computed('client.requestTemplates.@each.contact', function () {
      var _this = this;

      var templates = this.getWithDefault('client.requestTemplates', []);
      var assignedTemplates = templates.filter(function (template) {
        return template.get('contact.id') === _this.get('id');
      });
      return assignedTemplates;
    }),

    hasAssignedRequestTemplates: Ember.computed.notEmpty('assignedRequestTemplates'),

    selectLabel: Ember.computed('fullName', 'roleDisplayName', function () {
      var name = this.get('fullName');
      var role = this.get('roleDisplayName');
      return Ember.String.htmlSafe(name + ' (<i>' + role + '</i>)');
    }),

    contactInfoParts: Ember.computed('fullName', 'client.name', 'jobTitle', 'formattedPhone', 'email', function () {
      var email = this.getWithDefault('email', '');
      var emailLink = email ? '<a href="mailto:' + email + '">' + email + '</a>' : null;

      return ['<b>' + this.getWithDefault('fullName', '') + '</b>', this.getWithDefault('client.name', ''), this.getWithDefault('jobTitle', ''), this.getWithDefault('formattedPhone', ''), emailLink].filter(Boolean);
    }),

    contactInfo: Ember.computed('contactInfoParts.[]', function () {
      return this.get('contactInfoParts').join('\n');
    })
  });
});