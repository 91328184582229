define('dashboard/helpers/add-index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.addIndex = addIndex;
  function addIndex(params) {
    var index = params[0] || 0;
    return index + 1;
  }

  exports.default = Ember.Helper.helper(addIndex);
});