define('dashboard/components/graphs/tif-incentives-graph-header', ['exports', 'dashboard/components/graphs/incentives-graph-header', 'dashboard/helpers/format-money', 'dashboard/helpers/format-percent', 'dashboard/templates/components/graphs/incentives-graph-header'], function (exports, _incentivesGraphHeader, _formatMoney, _formatPercent, _incentivesGraphHeader2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _incentivesGraphHeader.default.extend({
    layout: _incentivesGraphHeader2.default,

    paybackPeriodHelpContent: '<p class="mb-0">The <b>Payback Period</b> identifies the point at which the project will generate enough TIF contributions to pay back the value of the incentive.</p>',

    rateOfReturnHelpContent: Ember.computed('years', 'incentiveEngine.{netBenefits,totalIncentive,incentiveRateOfReturn}', function () {
      var netBenefits = (0, _formatMoney.formatMoney)([this.get('incentiveEngine.netBenefits')]),
          years = this.get('years'),
          totalIncentive = (0, _formatMoney.formatMoney)([this.get("incentiveEngine.totalIncentive")]),
          rateOfReturn = (0, _formatPercent.formatPercent)([this.get('incentiveEngine.incentiveRateOfReturn')]);

      return '<p class="mb-0">The <b>Rate of Return</b> is the average annual TIF contribution divided by the incentive amount. <br>' + ('<span class="nowrap">(' + netBenefits + ' / ' + years + ' yrs) / ' + totalIncentive + ' = ' + rateOfReturn + '</span></p>');
    })

  });
});