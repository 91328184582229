define('dashboard/components/login-hero', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      var placeholder = this.$('.placeholder').first(),
          imgLarge = new Image();

      imgLarge.src = placeholder[0].dataset.large;
      imgLarge.onload = function () {
        Ember.$(imgLarge).addClass('loaded');
      };
      placeholder.append(imgLarge);
    }
  });
});