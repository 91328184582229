define('dashboard/routes/dependent-tax-district-sets', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    titleToken: 'Dependent Tax District Sets',

    model: function model() {
      return this.store.findAll('dependentTaxDistrictSet');
    }
  });
});