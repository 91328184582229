define('dashboard/mirage/factories/district-group', ['exports', 'ember-cli-mirage', 'faker'], function (exports, _emberCliMirage, _faker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    label: _faker.default.lorem.word,

    isPrimary: function isPrimary() /*i*/{
      return parseInt(this.groupOrder, 10) === 0;
    },


    withAdminDistrict: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(districtGroup, server) {
        server.create('tax-district', { districtGroup: districtGroup, adminCreated: true });
      }
    })
  });
});