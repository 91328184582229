define('dashboard/mixins/project-actions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({

    faSearch: '\uF002',

    notify: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    adapterErrorNotifier: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('moveProjectOptions', {
        active: Ember.Object.create({
          label: 'Active',
          action: 'markProjectActive'
        }),
        won: Ember.Object.create({
          label: 'Won',
          action: 'markProjectWon'
        }),
        lost: Ember.Object.create({
          label: 'Lost',
          action: 'markProjectLost'
        }),
        archived: Ember.Object.create({
          label: 'Archived',
          action: 'archiveProject'
        })
      });
    },


    popoverOpen: false,

    newProjectTitle: '',

    newProjectInputDisabled: Ember.computed('newProjectTitle', function () {
      return this.get('newProjectTitle').trim().length <= 0;
    }),

    currentUser: Ember.computed.alias('session.currentUser'),
    tileView: Ember.computed.alias('currentUser.tileView'),

    readOnlyAccess: Ember.computed('client.readOnlyAccess', 'currentUser.isSuperAdmin', function () {
      return this.get('client.readOnlyAccess') && !this.get('currentUser.isSuperAdmin');
    }),

    projectStatus: Ember.computed('project.{won,lost,archived,active}', function () {
      var projectWon = this.get('project.won');
      var projectLost = this.get('project.lost');
      var projectArchived = this.get('project.archived');
      var projectActive = this.get('project.active');

      if (projectWon) {
        return 'won';
      }

      if (projectLost) {
        return 'lost';
      }

      if (projectArchived) {
        return 'archived';
      }

      if (projectActive) {
        return 'active';
      }
    }),

    statusIcon: Ember.computed('projectStatus', function () {
      switch (this.get('projectStatus')) {
        case 'won':
          return 'trophy';
        case 'lost':
          return 'times-circle';
        case 'archived':
          return 'ghost';
        case 'active':
          return 'bolt';
        default:
          return;
      }
    }),

    filteredMoveProjectOptions: Ember.computed('projectStatus', function () {
      var projectStatus = this.get('projectStatus'),
          moveProjectOptions = this.get('moveProjectOptions');

      switch (projectStatus) {
        case 'won':
          return [moveProjectOptions.active, moveProjectOptions.lost, moveProjectOptions.archived];
        case 'lost':
          return [moveProjectOptions.active, moveProjectOptions.won, moveProjectOptions.archived];
        case 'archived':
          return [moveProjectOptions.active, moveProjectOptions.won, moveProjectOptions.lost];
        case 'active':
          return [moveProjectOptions.won, moveProjectOptions.lost, moveProjectOptions.archived];
        default:
          return;
      }
    }),

    createProject: function createProject(newProjectTitle) {
      var title = newProjectTitle.trim(),
          client = this.get('client');

      if (this.get('readOnlyAccess')) {
        return this.get('notify').alert('You currently have read only access');
      }

      if (!title) {
        return this.get('notify').alert('Project must have a title.');
      }

      var project = this.get('store').createRecord('project', { title: title, client: client });

      project.set('isHidden', false);
      this.set('hideCreateProjectModal', true);

      return project.save();
    },


    actions: {
      setTileView: function setTileView(isTileView) {
        var currentUser = this.get('currentUser');
        currentUser.set('tileView', isTileView);
        currentUser.save();
      },
      openCreateProjectModal: function openCreateProjectModal() {
        if (this.get('readOnlyAccess')) {
          return this.get('notify').alert('You currently have read only access');
        }

        this.set('newProjectTitle', '');
        this.set('hideCreateProjectModal', false);
      },
      createProject: function createProject(newProjectTitle) {
        var _this = this;

        this.createProject(newProjectTitle).then(function (project) {
          _this.get('model').unshiftObject(project);
          _this.send('transitionToProject', project.get('id'));
        }, this.get('adapterErrorNotifier').createErrorsAlerter());
      },
      createProjectWithDataForm: function createProjectWithDataForm(newProjectTitle, dataForm) {
        var _this2 = this;

        // newProjectTitle: String, dataForm: Scenario model instance
        this.createProject(newProjectTitle).then(function (project) {
          dataForm.set('project', project);
          dataForm.save().then(function (dataForm) {
            _this2.send('afterProjectCreate', project, dataForm);
          });
        });
      },
      toggleRenameProject: function toggleRenameProject() {
        if (this.get('readOnlyAccess')) {
          return this.get('notify').alert('You currently have read only access');
        }
        this.toggleProperty('editing');
      },
      markProjectWon: function markProjectWon() {
        var project = this.get('project');
        project.setProperties({ isWon: true, isHidden: false, isLost: false });
        this.send('saveProject', project);
      },
      markProjectLost: function markProjectLost() {
        var project = this.get('project');
        project.setProperties({ isHidden: false, isWon: false, isLost: true });
        this.send('saveProject', project);
      },
      archiveProject: function archiveProject() {
        var project = this.get('project');
        project.setProperties({ isLost: false, isWon: false, isHidden: true });
        this.send('saveProject', project);
      },
      markProjectActive: function markProjectActive() {
        var project = this.get('project');
        project.setProperties({ isLost: false, isWon: false, isHidden: false });
        this.send('saveProject', project);
      },
      saveProject: function saveProject(project) {
        var _this3 = this;

        if (this.get('readOnlyAccess')) {
          return this.get('notify').alert('You currently have read only access.');
        }

        project.save().then(function () {}, function (adapterError) {
          _this3.get('adapterErrorNotifier').alertAllErrors(adapterError);
          project.rollbackAttributes();
        });
      },
      openDeleteProject: function openDeleteProject(project) {
        if (this.get('readOnlyAccess')) {
          return this.get('notify').alert('You currently have read only access.');
        }

        var scenariosToDelete = project.get('scenarios').toArray();
        this.set('scenariosToDelete', scenariosToDelete.filterBy('isRequest', false).sortBy('created'));
        this.set('requestsToDelete', scenariosToDelete.filterBy('isRequest').sortBy('created'));

        this.set('projectToDelete', project); //* check this
        this.set('isDeleteProjectModalHidden', false);
      },
      deleteProject: function deleteProject() {
        var _this4 = this;

        var project = this.get('projectToDelete'),
            scenariosToDelete = this.get('scenariosToDelete') || [],
            requestsToDelete = this.get('requestsToDelete') || [],
            client = this.get('client'),
            projects = this.get('model');

        // deletes project's scenarios
        // use toArray and objectAt(index) because deleting things from the current array gets weird
        var scenarioPromises = scenariosToDelete.toArray().map(function (scenario, index) {
          return scenariosToDelete.objectAt(index).destroyRecord();
        });

        // archives projects data requests
        var requestPromises = requestsToDelete.map(function (request) {
          request.set('deletedDataForm', true);
          request.set('project', null);
          return request.save();
        });

        // delete project and remove project reference from client
        Ember.RSVP.all(scenarioPromises.concat(requestPromises)).then(function () {
          return client.get('projects');
        }).then(function (projects) {
          projects.removeObject(project);
          return client.save();
        }).then(function () {
          return project.destroyRecord();
        }).then(function () {
          projects.removeObject(project);
          _this4.get('notify').info('Project Deleted.');
          _this4.setProperties({
            projectToDelete: null,
            scenariosToDelete: null,
            requestsToDelete: null,
            isDeleteProjectModalHidden: true
          });
        }).catch(function (err) {
          console.log('Error deleting project', err);
        });
      },
      restoreProject: function restoreProject(project) {
        var _this5 = this;

        project.set('isHidden', false);
        project.save().then(function () {
          _this5.get('notify').info('Project Restored.');
        }, this.get('adapterErrorNotifier').createErrorsAlerter());
      },
      transitionToProject: function transitionToProject(id) {
        this.get('router').transitionTo('project', this.get('client.id'), id);
      },
      popoverActionHandler: function popoverActionHandler(action) {
        this.send(action);
      }
    }
  });
});