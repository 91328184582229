define('dashboard/components/customizable-list-card', ['exports', 'lodash/string'], function (exports, _string2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    newName: '',
    toDelete: null,
    toDeleteName: '',
    toDeleteIndex: null,
    isCreateModalHidden: true,
    isDeleteModalHidden: true,

    title: Ember.computed('type', 'plural', function () {
      return this.get('plural') ? this.get('plural') : this.get('type') + 's';
    }),

    titleUpper: Ember.computed('type', function () {
      return _string2.default.startCase(this.get('type'));
    }),

    newButtonDisabled: Ember.computed('newName', function () {
      return this.get('newName').trim().length <= 0;
    }),

    actions: {
      openCreateModal: function openCreateModal() {
        if (this.get('readOnlyAccess')) {
          return this.get('notify').alert('You currently have read only access.');
        }

        this.set('isCreateModalHidden', false);
      },
      createNew: function createNew(newName) {
        var _this = this;

        var client = this.get('client');
        var propertyName = this.get('propertyName');

        var property = client.get(propertyName);
        property = property.addObject(newName);
        client.set(propertyName, property.sort());
        client.save().then(function () {
          _this.set("newType", "");
          _this.set('isCreatingNewType', false);
        });
      },
      openDeleteModal: function openDeleteModal(index) {
        var propertyName = this.get("propertyName");
        var typeToDelete = this.get('client.' + propertyName)[index];

        this.setProperties({
          toDelete: typeToDelete,
          toDeleteName: typeToDelete,
          toDeleteIndex: index,
          isDeleteModalHidden: false
        });
      },
      delete: function _delete() {
        var client = this.get('client');
        var propertyName = this.get('propertyName');
        client.get(propertyName).splice(this.get('toDeleteIndex'), 1);
        client.save();
        this.setProperties({
          isDeleteModalHidden: true,
          toDelete: null,
          toDeleteName: '',
          toDeleteIndex: null
        });
      }
    }
  });
});