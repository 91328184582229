define('dashboard/storages/data-requests', ['exports', 'ember-local-storage/local/array'], function (exports, _array) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Storage = _array.default.extend();

  // Uncomment if you would like to set initialState
  Storage.reopenClass({
    initialState: function initialState() {
      return [];
    }
  });

  exports.default = Storage;
});