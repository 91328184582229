define('dashboard/components/advanced-input', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['clearfix'],
    classNameBindings: ['centered:textCenter'],
    centered: true,

    positionTitle: 'Year',
    expanded: false,

    didInsertElement: function didInsertElement() {
      //why?
      this.$('input').prop('disabled', this.get('disabled'));

      if (this.get('focusFirst')) {
        this.$('input').first().focus();
      }

      // add bottom border radius to last inputs
      this.$('.multiple-table').each(function () {
        Ember.$(this).find('input').last().addClass('bottom-radius');
      });
    },

    // not used?
    isDisabled: Ember.observer('disabled', function () {
      this.$('input').prop('disabled', this.get('disabled'));
    }),

    extraSpaceForCollapse: Ember.computed('hideTotal', 'hideExpandableButton', function () {
      return this.get('hideTotal') && !this.get('hideExpandableButton');
    }),

    years: Ember.computed.reads('columns.0.content.length'),

    yearCount: Ember.computed('years', 'calendarYearDisplay', 'startingCalendarYear', function () {
      var years = this.get('years'),
          calendarYearDisplay = this.get('calendarYearDisplay'),
          startingCalendarYear = parseInt(this.get('startingCalendarYear')),
          yearArray = [],
          startingYear = calendarYearDisplay ? startingCalendarYear : 1;

      for (var year = startingYear; year < startingYear + years; year++) {
        yearArray.push(year);
      }

      return yearArray;
    }),

    isMultiColumn: Ember.computed.gt('columns.length', 1),

    rowTotals: Ember.computed('totalsHaveUpdated', function () {
      var columns = this.get('columns'),
          totals = [];

      columns.forEach(function (column) {
        column.get('content').forEach(function (cell, index) {
          var value = cleanInput(cell.get('value')),
              currentTotal = totals[index];

          totals[index] = currentTotal ? currentTotal + value : value;
        });
      });

      return totals;
    }),

    grandTotal: Ember.computed.sum('rowTotals'),

    actions: {
      update: function update() /*rawValue, startingIndex*/{
        this.sendAction('action', this.get('columns'));
        this.notifyPropertyChange('totalsHaveUpdated');
      },

      updateDoesApply: function updateDoesApply(column) {
        var doesApply = column.get('doesApply'),
            doesApplyName = column.get('doesApplyName');

        this.sendAction('doesApplyAction', doesApplyName, doesApply);
      },
      nextSectionHandler: function nextSectionHandler() {
        var sortedProp = this.get('sortedProp') || 'sortedSections';
        this.sendAction('nextSectionHandler', this.get('nextId'), sortedProp);
        Ember.$('html, body').animate({
          scrollTop: this.$().offset().top - 100
        }, 300);
      },
      toggleExpanded: function toggleExpanded() {
        this.toggleProperty('expanded');
      }
    }
  });


  function cleanInput(input) {
    if (typeof input === 'string') {
      input = parseFloat(input.replace(/[$%,]/, ''));
    }
    return input;
  }
});