define('dashboard/controllers/user/customization', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['returnTemplateId'],
    returnTemplateId: null
  });
});