define('dashboard/services/google-place-autocomplete', ['exports', 'ember-place-autocomplete/services/google-place-autocomplete'], function (exports, _googlePlaceAutocomplete) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _googlePlaceAutocomplete.default;
    }
  });
});