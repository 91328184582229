define("dashboard/utils/ordered-search", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (item, term) {
    return item.toLowerCase().slice(0, term.length) === term.toLowerCase() ? 1 : -1;
  };
});