define('dashboard/components/tif-row', ['exports', 'dashboard/components/tag-row'], function (exports, _tagRow) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _tagRow.default.extend({
    click: function click() {
      this.sendAction('openEditTifDistrictModal');
    },


    actions: {
      openCopyTifModal: function openCopyTifModal() {
        this.sendAction('openCopyTif');
      },
      openDeleteTifModal: function openDeleteTifModal() {
        this.sendAction('openDeleteTif');
      }
    }

  });
});