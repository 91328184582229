define('dashboard/routes/demo', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    routeIfAlreadyAuthenticated: '/',
    title: 'Demo | Impact Dashboard',

    redirect: function redirect() {
      this.transitionTo('login');
    }
  });
});