define('dashboard/objects/ei-residents', ['exports', 'dashboard/objects/array-compute'], function (exports, _arrayCompute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var EiResidents = _arrayCompute.default.extend({

    direct: Ember.computed('directInput', 'multiplier', function () {

      var newWorkersMovingIncremental = this.get('directInput'),
          residentsPerHousehold = this.get('multiplier');

      return newWorkersMovingIncremental.map(function (newWorkers) {
        return newWorkers * residentsPerHousehold;
      });
    }),

    indirect: Ember.computed('indirectInput', 'multiplier', function () {
      var newIndirectWorkersMovingIncremental = this.get('indirectInput'),
          residentsPerHousehold = this.get('multiplier');

      return newIndirectWorkersMovingIncremental.map(function (newWorkers) {
        return newWorkers * residentsPerHousehold;
      });
    })

  });

  exports.default = EiResidents;
});