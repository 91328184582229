define('dashboard/utils/copy-assumption', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (oldAssumption, store) {
    var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

    var oldAssumptionProperties = oldAssumption.toJSON();
    var newAssumption = store.createRecord('assumption');
    delete oldAssumptionProperties.client;
    delete oldAssumptionProperties.created;
    delete oldAssumptionProperties.id;

    for (var property in oldAssumptionProperties) {
      newAssumption.set(property, oldAssumption.get(property));
    }

    if (oldAssumption.get('calendarYearDisplay')) {
      var startingYear = new Date().getFullYear();

      newAssumption.set('startingYear', startingYear);
    }

    for (property in options) {
      newAssumption.set(property, options[property]);
    }

    return newAssumption.save();
  };
});