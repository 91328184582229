define('dashboard/controllers/data-forms', ['exports', 'dashboard/mixins/color-scheme'], function (exports, _colorScheme) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_colorScheme.default, {
    application: Ember.inject.controller(),

    listAllView: Ember.computed('application.currentPath', function () {
      var currentPath = this.get('application.currentPath');

      return currentPath === 'authenticated.layout.data-forms.request-templates.index' || currentPath === 'authenticated.layout.data-forms.inbox.index';
    }),

    client: Ember.computed.alias('model.user.client')
  });
});