define('dashboard/controllers/dependent-tax-district-set', ['exports', 'dashboard/mixins/sorting', 'dashboard/fixtures/states', 'dashboard/config/environment'], function (exports, _sorting, _states, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_sorting.default, {

    notify: Ember.inject.service(),
    ajax: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('uploadedTaxDistricts', {});
      this.set('withIdsOptions', [{
        name: 'no',
        value: false,
        label: 'No',
        computedId: 'no'
      }, {
        name: 'yes',
        value: true,
        label: 'Yes',
        computedId: 'yes'
      }]);
      this.set('sortColumns', [{
        title: 'Id',
        sortField: 'id'
      }, {
        title: 'Name',
        sortField: 'name'
      }, {
        title: 'Group',
        sortField: 'group'
      }, {
        title: 'Created',
        sortField: 'created'
      }]);
      this.set('dependentSortColumns', [{
        title: 'Name',
        sortField: 'name'
      }, {
        title: 'Group',
        sortField: 'group'
      }, {
        title: 'Parent',
        sortField: 'primaryDependency.name'
      }, {
        title: 'Created',
        sortField: 'created'
      }]);
      this.set('uploadModalTitle', 'Dependent Tax District Upload'); // will changed depending on what type of districts being uploaded
    },


    districtsLoaded: 100,

    sortField: 'name',
    sortDir: 'asc',

    stateOptions: _states.default,

    primaryTaxDistrictsUpload: null,
    dependentTaxDistrictsUpload: null,

    isDownloadTaxDistrictModalHidden: true,
    downloadingPrimary: false,

    isUploadPrimaryModalHidden: true,
    isUploadDependentModalHidden: true,

    primaryUploadingProgress: false,
    dependentUploadingProgress: false,

    districtUploadStatusHidden: true,

    breadcrumbItems: Ember.computed('dependentTaxDistrictSet', function () {
      return [{ label: 'Dependent Tax District Sets', params: ['dependent-tax-district-sets'] }, { label: this.get('dependentTaxDistrictSet.name'), active: true }];
    }),

    disablePrimaryButton: Ember.computed('dependentTaxDistrictSet', 'primaryUploadingProgress', 'primaryTaxDistrictsUpload', function () {
      var dependentTaxDistrictSet = !this.get('dependentTaxDistrictSet'),
          uploadingProgress = this.get('primaryUploadingProgress'),
          fileSelected = this.get('primaryTaxDistrictsUpload');

      return dependentTaxDistrictSet || uploadingProgress || fileSelected === null;
    }),

    disableDependentButton: Ember.computed('dependentTaxDistrictSet', 'uploadingProgress', 'dependentTaxDistrictsUpload', function () {
      var dependentTaxDistrictSet = !this.get('dependentTaxDistrictSet'),
          uploadingProgress = this.get('uploadingProgress'),
          fileSelected = this.get('dependentTaxDistrictsUpload');

      return dependentTaxDistrictSet || uploadingProgress || fileSelected === null;
    }),

    downloadLink: Ember.computed('downloadingPrimary', 'dependentTaxDistrictSet.id', function () {
      var baseLink = '/api/taxDistricts/downloads';

      var setId = this.get('dependentTaxDistrictSet.id');
      var setQuery = setId ? '?dependentTaxDistrictSet=' + setId : '';
      if (!setId) return;

      var primary = this.get('downloadingPrimary');
      var primaryQuery = primary ? '&isPrimary=true' : '&isPrimary=false';

      return baseLink + setQuery + primaryQuery;
    }),

    actions: {
      setState: function setState(state) {
        this.set('dependentTaxDistrictSet.state', state);
        this.send('updateDependentTaxDistrictSet');
      },
      loadMore: function loadMore() {
        var _this = this;

        var set = this.get('dependentTaxDistrictSet'),
            districtsLoaded = this.get('districtsLoaded');

        this.store.query('taxDistrict', { dependentTaxDistrictSet: set.id, limit: 100, skip: districtsLoaded }).then(function (districts) {
          _this.get('model').pushObjects(districts.get('content'));
        });

        this.set('districtsLoaded', districtsLoaded + 100);
      },
      cancelCreating: function cancelCreating() {
        resetUploadField();
        this.setProperties({
          'isUploadPrimaryModalHidden': true,
          'isUploadDepndentModalHidden': true,
          'primaryTaxDistrictsUpload': null,
          'dependentTaxDistrictsUpload': null,
          'primaryUploadingProgress': false,
          'dependentUploadingProgress': false
        });
      },
      cancelDownloading: function cancelDownloading() {
        this.setProperties({
          'isDownloadTaxDistrictModalHidden': true,
          'downloadingPrimary': false
        });
      },
      updateDependentTaxDistrictSet: function updateDependentTaxDistrictSet() {
        Ember.run.debounce(this.get('dependentTaxDistrictSet'), 'save', 100);
      },


      toggleDownloading: function toggleDownloading(primary) {
        this.set('downloadingPrimary', primary);
        this.set('isDownloadTaxDistrictModalHidden');
      },

      downloadTaxDistricts: function downloadTaxDistricts() {
        var _this2 = this;

        window.location.href = _environment.default.APP.api_host + this.get('downloadLink');
        setTimeout(function () {
          _this2.send('cancelDownloading');
        }, 1000);
      },

      /* PRIMARY / DEPENDENT DISTRICT UPLOAD */
      createDependentTaxDistrictsUpload: function createDependentTaxDistrictsUpload(fileToUpload, isPrimary) {
        var _this3 = this;

        // fileToUpload is either primaryTaxDistrictsUpload or dependentTaxDistrictsUpload
        // isPrimary is bool

        this.set('uploadingProgress', true);

        if (!fileToUpload) {
          return this.get('notify').alert('No file found');
        }

        var formData = new FormData();
        formData.append('dependentTaxDistrictsUpload', fileToUpload);
        formData.append('dependentTaxDistrictSetId', this.get('dependentTaxDistrictSet.id'));
        formData.append('isPrimary', isPrimary);

        this.get('ajax').post('/dependentTaxDistrictsUploads', {
          data: formData,
          dataType: 'json',
          contentType: false,
          processData: false
        }).then(function (uploadObject) {
          console.log('then', 'uploadObject:', uploadObject);
          resetUploadField();

          _this3.send('cancelCreating');
          _this3.set('uploadedTaxDistricts', uploadObject.taxDistrictsUpload.taxDistricts);

          var pushToArray = isPrimary ? 'primaryTaxDistricts' : 'dependentTaxDistricts';
          _this3.get(pushToArray).pushObjects(uploadObject.taxDistrictsUpload.taxDistricts);
          _this3.set('districtUploadStatusHidden', false);
        }).catch(function (fail) {
          console.log('catch', 'fail:', fail);
          _this3.setProperties({
            'primaryTaxDistrictsUpload': null,
            'dependentTaxDistrictsUpload': null,
            'uploadingProgress': false
          });
          _this3.get('notify').alert('Something went wrong when creating new districts');
        });
      }
    }

  });


  function resetUploadField() {
    Ember.$('.upload-component').wrap('<form>').closest('form').get(0).reset();
    Ember.$('.upload-component').unwrap();
  }
});