define('dashboard/components/data-form-row', ['exports', 'dashboard/mixins/hover'], function (exports, _hover) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_hover.default, {
    tagName: 'tr',
    classNames: ['clickable'],
    classNameBindings: ['isNew:bold'],
    attributeBindings: ['data-test'],

    init: function init() {
      this._super.apply(this, arguments);

      this.set('assignProjectOptions', [Ember.Object.create({
        label: 'Create New Project',
        action: 'openCreateProjectModal'
      }), Ember.Object.create({
        label: 'Assign to Existing Project',
        action: 'openMoveProjectsModal'
      })]);
    },


    isNew: Ember.computed.alias('scenario.displayAsUnread'),

    click: function click() {
      this.send('goToRequest', this.get('scenario'));
    },


    actions: {
      goToRequest: function goToRequest(dataRequest) {
        this.sendAction('goToRequest', dataRequest);
      },
      goToProject: function goToProject(client, project) {
        this.sendAction('goToProject', client, project);
      },
      openCreateProjectModal: function openCreateProjectModal() {
        var scenario = this.get('scenario');
        this.sendAction('openCreateProjectModal', scenario);
      },
      openMoveProjectsModal: function openMoveProjectsModal() {
        var scenario = this.get('scenario');
        this.sendAction('openMoveProjectsModal', scenario);
      },
      openShareRequestModal: function openShareRequestModal(scenario) {
        this.sendAction('openShareRequestModal', scenario);
      },
      openReviseRequestModal: function openReviseRequestModal(scenario) {
        this.sendAction('openReviseRequestModal', scenario);
      },
      showDeleteModal: function showDeleteModal(scenario) {
        this.sendAction('showDeleteModal', scenario);
      },
      showRestoreModal: function showRestoreModal(scenario) {
        this.sendAction('showRestoreModal', scenario);
      },
      popoverActionHandler: function popoverActionHandler(action) {
        this.send(action);
      }
    }
  });
});