define('dashboard/mirage/config', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    this.namespace = '/api';

    this.get('/clients');

    this.post('/clients', function (schema) {
      var client = schema.clients.create();
      return client;
    });

    this.put('/clients/:id', function () /*schema, fakeRequest*/{
      return {};
    });

    this.get('/clients/:id');

    this.get('/users', function (_ref, fakeRequest) {
      var users = _ref.users;

      var token = Ember.get(fakeRequest, 'queryParams.token');
      if (token) {
        return users.first(); // in case a token is passed in the query the api returns a single record
      } else {
        return users.all();
      }
    });

    this.get('/users/:id/misc', function () {
      return {};
    });

    this.post('/users', function (schema) {
      var user = schema.users.create();
      return user;
    });

    this.get('/users/:id');

    this.put('/users/:id', function () {
      return {};
    });

    this.get('/users/:id/s3Header', function () {
      return {};
    });

    this.get('/defaultAssumptions');
    this.post('/defaultAssumptions');
    this.put('/defaultAssumptions/:id');

    this.get('/projects');
    this.put('/projects/:id');
    this.get('/projects/:id');

    this.get('/districtAreas');

    this.get('/districtAreaSets');

    this.get('/customCostRevenues');
    this.get('/directCostRevenues');

    this.get('/taxDistricts', function (_ref2, fakeRequest) {
      var taxDistricts = _ref2.taxDistricts;

      var isParentDistrictRequest = Ember.get(fakeRequest, 'queryParams.childDistrict');
      var isActiveQueryParam = Ember.get(fakeRequest, 'queryParams.isActive');
      if (typeof isParentDistrictRequest !== 'undefined') {
        return {};
      } else if (typeof isActiveQueryParam !== 'undefined') {
        var isActive = isActiveQueryParam === "true"; // these are "true" or "false"
        return taxDistricts.all().filter(function (district) {
          return district.isActive === isActive;
        });
      } else {
        return taxDistricts.all();
      }
    });

    this.get('/taxDistrictsList', function (_ref3, fakeRequest) {
      var taxDistricts = _ref3.taxDistricts;

      var isActiveQueryParam = Ember.get(fakeRequest, 'queryParams.isActive');
      if (typeof isActiveQueryParam !== 'undefined') {
        var isActive = isActiveQueryParam === "true"; // these are "true" or "false"
        return taxDistricts.all().filter(function (district) {
          return district.isActive === isActive;
        });
      } else {
        return taxDistricts.all();
      }
    });

    this.post('/taxDistricts');
    this.put('/taxDistricts/:id');
    this.get('/taxDistricts/:id');

    this.get('/multipliers', function (schema) {
      var multipliers = [schema.multipliers.create({ code: 'Poultry Hatcheries' }), schema.multipliers.create({ code: 'Poultry Ranching' }), schema.multipliers.create({ code: 'Poultry Forestry' }), schema.multipliers.create({ code: 'Number Crunching' })];
      return { multipliers: multipliers };
    });

    this.get('/multiplierSets');
    this.get('/multiplierSets/:id');

    this.get('/guests');

    this.post('/scenarios', function (schema, request) {
      var scenario = JSON.parse(request.requestBody).scenario;
      scenario['id'] = Math.random().toString();
      scenario['token'] = Math.random().toString();
      scenario['requestTemplate'] = null;
      scenario['assumption'] = null;
      var createdScenario = schema.scenarios.create(scenario);
      return createdScenario;
    });
    this.get('/scenarios/:id');
    this.put('/scenarios/:id', function () {
      return {};
    });
    this.post('/scenario/:id/sendLink', function () {
      return {};
    });
    this.post('/scenario/:id/notifySubmitted', function () {
      return {};
    });
    this.get('/scenarios', function (_ref4, request) {
      var scenarios = _ref4.scenarios;

      if (request.queryParams.singleRecord) {
        var scenario = scenarios.findBy({ token: request.queryParams.token });
        return { scenario: scenario };
      } else {
        return scenarios.all();
      }
    });

    this.get('/assumptions');
    this.post('/assumptions');
    this.put('/assumptions/:id');

    this.post('/subscriptions');

    this.get('/districtGroups/:id');
    this.put('/districtGroups/:id');
    this.delete('/districtGroups/:id');

    this.post('/incentiveSchedules');

    this.get('/tifDistricts');

    this.get('/tags');

    this.get('/requestTemplates');

    this.put('/requestTemplates/:id', function () /*schema, request*/{
      //this is necessary because it gives a 500 error with the blank return
      // When upgrading to mirage 1.0, check to see if this is still necessary.
      return {};
    });

    this.get('/requestTemplates/:id', function (schema, request) {
      var requestTemplate = schema.requestTemplates.find(request.params.id);
      var scenarios = requestTemplate.scenarioIds.map(function (id) {
        return schema.scenarios.find(id);
      });
      var client = requestTemplate.client;
      return {
        requestTemplate: requestTemplate,
        scenarios: scenarios,
        client: client,
        assumption: schema.assumptions.create()
      };
    });
    this.delete('/requestTemplates/:id');
    this.post('/requestTemplates', function (schema) {
      var requestTemplate = schema.requestTemplates.create();
      return requestTemplate;
    });

    this.get('/dependentTaxDistrictSets');

    this.get('/dependentDistrictGroupLinks');
  };
});