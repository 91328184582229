define('dashboard/serializers/client', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
        attrs: {
            users: { serialize: 'ids' },
            projects: { serialize: 'ids' }
        }
    });
});