define("dashboard/utils/sum-arrays", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var sumArrays = function sumArrays(firstArray, secondArray) {

    firstArray = firstArray || [];
    secondArray = secondArray || [];

    var longestArray = firstArray.length > secondArray.length ? firstArray : secondArray,
        shortestArray = firstArray.length > secondArray.length ? secondArray : firstArray;

    return longestArray.map(function (value, index) {

      value = isNaN(value) ? 0 : value;
      var addTo = shortestArray[index] !== undefined && !isNaN(shortestArray[index]) ? shortestArray[index] : 0;

      return value + addTo;
    });
  };

  exports.default = sumArrays;
});