define('dashboard/controllers/scenario/raw-data', ['exports', 'dashboard/mixins/scenario-wizard-sections', 'dashboard/mixins/read-only-restrictions'], function (exports, _scenarioWizardSections, _readOnlyRestrictions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_scenarioWizardSections.default, _readOnlyRestrictions.default, {
    scenarioController: Ember.inject.controller('scenario'),
    store: Ember.inject.service(),
    notify: Ember.inject.service(),
    currentSectionIndex: 0,
    scenario: Ember.computed.alias('model.scenario'),
    client: Ember.computed.alias('model.client'),
    project: Ember.computed.alias('model.project'),
    requestTemplate: Ember.computed.alias('model.requestTemplate'),
    multiplierSetId: Ember.computed.alias('scenarioController.primaryDistrict.multiplierSet.id'),

    inputsCanExpand: Ember.computed.not('scenarioController.isScenarioLengthLessThanSix'),

    // we only want to show the link to view a submitted data form if a scenario was created from a data request
    // and that data request is still associated with this project
    showViewSubmittedButton: Ember.computed('scenario.createdFromId', 'project.dataRequests.@each.id', function () {
      var createdFromId = this.get('scenario.createdFromId'),
          dataRequestIds = this.get('project.dataRequests').mapBy('id');

      return createdFromId && dataRequestIds.includes(createdFromId);
    }),

    disableInputs: Ember.computed.alias('isReadOnly'),

    actions: {
      navigateToScreen: function navigateToScreen(index) {
        this.set('currentSectionIndex', index);
      }
    }
  });
});