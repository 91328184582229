define('dashboard/components/district-incentives', ['exports', 'dashboard/objects/column', 'dashboard/utils/make-inputs', 'dashboard/utils/array-of-length'], function (exports, _column, _makeInputs, _arrayOfLength) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    primaryGroup: true, // needed to show correct help tooltip in accordion-card
    notCommunityPage: true,

    incentivesTableView: false,

    // incentives only apply to new jobs, so if viewing a scenario as Total Combined, we want to clarify this
    perJobLabel: Ember.computed('currentVsExpansion', function () {
      var currentVsExpansion = this.get('currentVsExpansion') || 'expansion';
      return currentVsExpansion === 'total' ? 'Incentive Per New Job' : 'Incentive Per Job';
    }),

    incentiveSelectOptions: Ember.computed('perJobLabel', function () {
      return [Ember.Object.create({
        val: 'amount',
        text: 'Incentive Amount'
      }), Ember.Object.create({
        val: 'perJob',
        text: this.get('perJobLabel')
      }), Ember.Object.create({
        val: 'rateOfReturn',
        text: 'Rate of Return'
      }), Ember.Object.create({
        val: 'paybackPeriod',
        text: 'Payback Period (years)'
      })];
    }),

    cardTitle: Ember.computed('title', function () {
      return this.get('title') || 'Non-Tax Incentive';
    }),

    // get incentive input method object to show selected in dropdown
    incentiveInputMethod: Ember.computed('incentiveApproach', 'schedule.incentiveApproach', 'incentiveSelectOptions', function () {
      // prioritize incentiveApproach property if it's passed into the component
      var approach = this.get('incentiveApproach') || this.get('schedule.incentiveApproach');
      var options = this.get('incentiveSelectOptions');

      return options.findBy('val', approach);
    }),

    incentivesToUse: Ember.computed('schedule.incentives', 'engine.nonTaxIncentives', 'engine.assumption.scenarioLength', function () {
      if (this.get('engine.name') === 'currentEngine') {
        return (0, _arrayOfLength.default)(this.get('engine.assumption.scenarioLength'));
      }
      return this.get('schedule.incentives');
    }),

    incentiveInputs: Ember.computed('incentivesToUse', 'years', function () {
      var incentives = this.get('incentivesToUse'),
          years = this.get('years'),
          incentivesColumn = void 0;

      incentivesColumn = _column.default.create({
        title: 'Incentives Per Year',
        columnName: 'incentives',
        inputType: 'dollars',
        content: (0, _makeInputs.default)(incentives, years)
      });

      return [incentivesColumn];
    }),

    unboundIncentiveInputs: Ember.computed('schedule.id', 'incentiveInputMethod', 'years', 'incentivesUpdated', 'engine.name', 'currentVsExpansion', function () {
      return this.get('incentiveInputs');
    }),

    scheduleInCommunityIncentives: Ember.computed('schedule.id', 'communitySchedules.@each.id', function () {
      return !!this.get('communitySchedules').findBy('id', this.get('schedule.id'));
    }),

    isIncentiveDisabled: Ember.computed('scenarioDisabled', 'scheduleInCommunityIncentives', function () {
      var scheduleInCommunityIncentives = this.get('scheduleInCommunityIncentives');
      return this.get('scenarioDisabled') || scheduleInCommunityIncentives;
    }),

    showComponent: Ember.computed.or('engine.incentiveEngine.hasPositiveNetBenefits', 'scheduleInCommunityIncentives'),

    actions: {
      selectIncentiveApproach: function selectIncentiveApproach(selectedApproach) {
        var schedule = this.get('schedule');
        var incentiveApproachVal = selectedApproach.get('val');

        schedule.set('incentiveApproach', incentiveApproachVal);
        this.sendAction('action', schedule);
      },
      goToCommunityIncentive: function goToCommunityIncentive() {
        this.sendAction('goToCommunityIncentiveHandler');
      },
      updateSchedule: function updateSchedule(columns) {
        this.sendAction('updateScheduleHandler', columns);
      },
      saveIncentives: function saveIncentives() {
        this.sendAction('saveIncentivesHandler');
      }
    }

  });
});