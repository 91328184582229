define('dashboard/objects/ei-assessed-inventories', ['exports', 'dashboard/utils/multiply-each'], function (exports, _multiplyEach) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({

    output: Ember.computed('input', 'multiplier', function () {
      var input = this.get('input') || [],
          multiplier = this.get('multiplier') || 1;

      return (0, _multiplyEach.default)(input, multiplier);
    })

  });
});