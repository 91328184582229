define('dashboard/helpers/join-array', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.joinArray = joinArray;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function joinArray(_ref, _ref2) {
    var _ref3 = _slicedToArray(_ref, 1),
        dataArray = _ref3[0];

    var property = _ref2.property,
        joinWith = _ref2.joinWith;

    var list = dataArray || [];
    var joiner = joinWith ? joinWith + ' ' : ', ';

    if (property) {
      list = dataArray.mapBy(property);
    }

    return list.join(joiner);
  }

  exports.default = Ember.Helper.helper(joinArray);
});