define('dashboard/objects/fi-tax/inventory-property-taxes', ['exports', 'dashboard/objects/fi-tax/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    inventoryPropertyTax: Ember.computed('assessedValueOfTaxableInventories', 'inventoryPropertyTaxRate', 'InventoryPropertyTaxRateApplied', function () {
      var assessedValueOfTaxableInventories = this.get('assessedValueOfTaxableInventories'),
          inventoryPropertyTaxRate = this.get('inventoryPropertyTaxRate'),
          inventoryPropertyTaxRateApplied = this.get('inventoryPropertyTaxRateApplied'),
          doesApply = this.get('doesApplyTax');

      return assessedValueOfTaxableInventories.map(function (value, index) {
        var rate = Ember.isArray(inventoryPropertyTaxRate) ? inventoryPropertyTaxRate[index] : inventoryPropertyTaxRate;
        return doesApply ? value * rate / inventoryPropertyTaxRateApplied : 0;
      });
    }),

    project: Ember.computed.sum('inventoryPropertyTax')
  });
});