define('dashboard/routes/scenario/settings/general', ['exports', 'ember-local-storage', 'dashboard/mixins/reset-scroll-position'], function (exports, _emberLocalStorage, _resetScrollPosition) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_resetScrollPosition.default, {
    sessionData: (0, _emberLocalStorage.storageFor)('session-data'),

    model: function model() {
      return this.modelFor('scenario').scenario;
    },


    actions: {
      willTransition: function willTransition() {
        this.set('sessionData.settingsSelection', 'general');
      }
    }
  });
});