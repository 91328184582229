define('dashboard/components/focus-input', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.TextField.extend({
    classNames: ['focus-input'],

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.$().focus();
      var value = this.value || '';
      this.$()[0].setSelectionRange(0, value.length);
    },


    click: function click(e) {
      e.stopPropagation();
    }
  });
});