define('dashboard/components/file-upload-button', ['exports', 'dashboard/mixins/s3'], function (exports, _s) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_s.default, {

    actions: {
      openFinder: function openFinder() {
        this.$('input').click();
      }
    }

  });
});