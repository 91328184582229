define('dashboard/components/simple-select', ['exports', 'dashboard/mixins/input-sizes'], function (exports, _inputSizes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_inputSizes.default, {

    classNames: ['form-group', 'simple-select', 'no-border', 'm-0'],

    init: function init() {
      this._super.apply(this, arguments);
    },


    computedId: Ember.computed(function () {
      return this.elementId + 'select';
    }),

    actions: {

      // inputValue will be updated so you can send to regular save action or
      // custom action if more needs to be done
      updateValue: function updateValue(value) {
        this.set('inputValue', value);
        this.sendAction('action', value);
      }
    }
  });
});