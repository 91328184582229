define('dashboard/objects/ei-appraised-residential', ['exports', 'dashboard/objects/array-compute', 'dashboard/utils/appreciation-schedule', 'dashboard/utils/percent-each'], function (exports, _arrayCompute, _appreciationSchedule, _percentEach) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var EiAppraisedResidentialComponent = _arrayCompute.default.extend({

    appreciatedProperty: Ember.computed('multiplier', 'appreciation', 'directInput.length', function () {
      var valueNewResidentialProperty = this.get('multiplier') || 0,
          size = this.get('directInput.length'),
          appreciation = (0, _percentEach.percentify)(this.get('appreciation')),
          values = [valueNewResidentialProperty];

      for (var i = 1; i < size; i++) {
        values.push(0);
      }

      return (0, _appreciationSchedule.default)(values, appreciation);
    }),

    direct: Ember.computed('directInput', 'appreciatedProperty', 'appreciation', function () {

      var directInput = this.get('directInput') || [],
          valueNewResidentialProperty = this.get('appreciatedProperty');

      return valueNewResidentialProperty.map(function (value, index) {
        return value * directInput[index];
      });
    }),

    indirect: Ember.computed('indirectInput', 'appreciatedProperty', 'appreciation', function () {

      var indirectInput = this.get('indirectInput'),
          valueNewResidentialProperty = this.get('appreciatedProperty');

      return valueNewResidentialProperty.map(function (value, index) {
        return value * indirectInput[index];
      });
    })

  });

  exports.default = EiAppraisedResidentialComponent;
});