define('dashboard/components/selectable-bucket', ['exports', 'dashboard/components/selectable-engine'], function (exports, _selectableEngine) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _selectableEngine.default.extend({

    classNames: ['selectable-bucket'],

    click: function click() {
      this.sendAction('action', this.get('bucket'));
    },

    title: Ember.computed.reads('bucket.label'),

    isActive: Ember.computed('bucket', 'selectedBucket', function () {
      var currentBucket = this.get('bucket'),
          selectedBucket = this.get('selectedBucket');

      if (selectedBucket && currentBucket) {
        return currentBucket.type === selectedBucket.type;
      }
    })
  });
});