define('dashboard/components/modals/send-link-modal', ['exports', 'dashboard/components/modals/modal-confirm', 'dashboard/utils/validations'], function (exports, _modalConfirm, _validations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _modalConfirm.default.extend({
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    emailSent: false,
    copied: false,
    classNames: ['modal-overlay-fixed'],

    init: function init() {
      this._super.apply(this, arguments);
      this.set('email', this.get('scenario.contactEmail'));
    },


    emailIsValid: Ember.computed('email', function () {
      return (0, _validations.emailValidation)(this.get('email'));
    }),

    actions: {
      send: function send() {
        var _this = this;

        var scenarioId = this.get('scenario.id');
        var email = this.get('email');
        var directLink = this.get('router.currentURL');
        this.get('ajax').post('/scenario/' + scenarioId + '/sendLink?prospectOnly=true', {
          data: JSON.stringify({ email: email, directLink: directLink }),
          contentType: 'application/json'
        }).then(function () {
          _this.set('emailSent', true);
        });
      },
      cancel: function cancel() {
        this._super.apply(this, arguments);

        this.set('emailSent', false);
        this.set('copied', false);
      }
    }
  });
});